import React, { useEffect, useState } from "react";
import { Get, Post } from "utils/request";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { Pagination } from "@mui/material";
import { useParams } from "react-router";
import { Reorder, AnimatePresence } from "framer-motion";
import { useMutation, useQuery } from "react-query";

import Board from "./Board";
import SaveOrderButton from "./SaveOrderButton";

const Table = props => {

    const {
        search = ""
    } = props;

    const [[lessons, tempLessons], SetLessons] = useState([[], []]);
    const [page, SetPage] = useState(1);

    const dispatch = useDispatch();
    const { module_id } = useParams();

    const { data, isLoading, refetch } = useQuery(["moduleLessons", module_id, search, page], async () => await Get(`modules?id=${module_id}&lesson_search=${search}&page=${page}`), {
        onSuccess: (data) => {
            const { lessons } = data || {};
            SetLessons([lessons, lessons]);
        }
    });
    const {
        lessons_pagination: {
            last_page: maxPage,
            perPage
        } = {}
    } = data || {};

    const orderMutation = useMutation(async () => {
        const form = new FormData();
        form.append("module_id", module_id);
        form.append("perPage", perPage);
        form.append("currPage", page);
        for (let i = 0; i < tempLessons?.length; i++) form.append("lessonsIds[]", tempLessons[i]?.id);

        return await Post("lessons/order", form);
    }, {
        onSuccess: (data) => {
            const { message } = data || {};
            dispatch(Show({
                show: true,
                message: message,
                severity: "success"
            }));
        }
    });

    return (
        <div>
            <div className="table-responsive">
                <Reorder.Group
                    as={"table"}
                    className="table table-striped"
                    axis={"y"}
                    values={tempLessons}
                    onReorder={value => SetLessons([lessons, value])}
                    style={{
                        borderColor: "transparent",
                        overflow: "hidden"
                    }}
                >
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "3em"
                                }}
                            />
                            <th>Nome</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && tempLessons?.map((value, index) => (
                            <Board
                                draggable={search == ""}
                                value={value}
                                key={value?.id}
                                lesson_id={value?.id}
                                name={value?.title}
                                OnDelete={refetch}
                            />
                        ))}
                    </tbody>
                </Reorder.Group>
            </div>
            <div className="w100 flex jcc">
                {isLoading ? (
                    <CircularProgress/>
                ) : (
                    lessons?.length == 0 && <p>Nenhuma aula foi achada</p>
                )}
            </div>
            <Pagination
                className={(maxPage == 1 ? "hide" : "")}
                style={{display: "flex", justifyContent: "flex-end"}} 
                count={maxPage} 
                value={page}
                onChange={(_, value) => SetPage(value)}
            />
            <AnimatePresence>
                {JSON.stringify(lessons) != JSON.stringify(tempLessons) && (
                    <SaveOrderButton
                        onClick={() => {
                            orderMutation.mutate();
                            SetLessons([tempLessons, tempLessons]);
                        }}
                        onCancel={() => SetLessons([lessons, lessons])}
                    />
                )}
            </AnimatePresence>
        </div>
    );
}

export default Table;
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha, AppBar, IconButton, Toolbar, Typography, useTheme } from "@mui/material";

const TableToolbar = props => {

    const {
        numSelected,
        rightContent,
        title = "",
        onDelete = () => {}
    } = props;

    const theme = useTheme();

    return (
        <AppBar position="relative">
            <Toolbar
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: numSelected > 0 ? alpha(theme.palette.primary.main, 0.8) : "inherit"
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        variant="subtitle1"
                    >
                        {numSelected} selecionados
                    </Typography>
                ) : (
                    <Typography
                        variant="h6"
                    >
                        {title}
                    </Typography>
                )}
                {numSelected > 0 ? (
                    <IconButton
                        onClick={onDelete}
                    >
                        <DeleteIcon htmlColor="white"/>
                    </IconButton>
                ) : rightContent}
            </Toolbar>
        </AppBar>
    );
}

export default TableToolbar;
import {
  Box,
  Container,
  Typography,
  useTheme,
  Icon,
  Tooltip,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Get } from "utils/request";
import { DateFormat } from "utils/transformations";
import { useQuery } from "react-query";
import { THIS_URL } from "../../../../variables";

import Footer from "Home/Footer";
import { Header } from "modules/Header";
import { useMainPages } from "modules/Header/data";
import PrevNextContainer from "./PrevNextContainer";

// icons
import LinkIcon from "@mui/icons-material/Link";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

import rt_logo from "assets/images/rt/main_logo_bgtransparent.png";
import WhatsappButton from "components/WhatsappButton";

const SciencePlusRead = () => {
  const theme = useTheme();
  const [clipboard_button_icon, setClipboard_button_icon] = useState(LinkIcon);
  const [whatsapp_button_icon, setWhatsapp_button_icon] =
    useState(WhatsAppIcon);

  const { post_id } = useParams();
  const pages = useMainPages();

  const { isLoading, data, error } = useQuery(
    [`sciencepluspost${post_id}`],
    async () => await Get(`science-plus?postId=${post_id}`)
  );
  const { post } = data || {};

  const URL_TO_SHARE = `${THIS_URL}science-plus/${post_id}/view`;

  const HandleCopyClipboard = () => {
    var text = `${THIS_URL}science-plus/${post_id}/view`;
    navigator.clipboard.writeText(text).then(
      () => {
        setClipboard_button_icon(CheckRoundedIcon);
      },
      function (err) {
        console.error("Erro ao copiar o link. Erro: ", err);
        setClipboard_button_icon(ErrorOutlineRoundedIcon);
      }
    );

    setTimeout(() => {
      setClipboard_button_icon(LinkIcon);
    }, 1500);
  };

  if (isLoading)
    return (
      <Box>
        <Header pages={pages} />
        <Typography variant="h4">
          <Skeleton width={150} />
        </Typography>
        <Skeleton width={"80%"} height={500} />
      </Box>
    );
  return (
    <>
      <Container maxWidth="xl">
        <Box
          display="flex"
          flexDirection="column"
          minHeight="100vh"
          padding={2.5}
        >
          <Header pages={pages} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "4rem",
              alignContent: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{
                fontWeight: "bold",
                height: "max-content",
                alignSelf: "center",
              }}
            >
              {post?.title}
            </Typography>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: "bold" }}>
                Postado por{" "}
                <span
                  style={{
                    color: "#42379e",
                    width: "max-content",
                  }}
                >
                  {post?.created_by?.name}
                </span>
              </div>
              {/* {DateFormat(post?.created_at)} */}
              {new Date(post?.created_at).toLocaleDateString("pt-BR", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginBottom: "1rem",
              flexDirection: "column",
            }}
          >
            <div style={{ fontWeight: "bold" }}>
              Autor da referência{" "}
              <span
                style={{
                  color: "#42379e",
                  width: "max-content",
                }}
              >
                {post?.reference_post_author}
              </span>
            </div>
            {/* {DateFormat(post?.created_at)} */}
            <a href={post?.reference_link} target="_blank">
              Ir para referência
            </a>
          </div>

          <div
            style={{
              backgroundColor: "#f3f3f3",
              borderRadius: "0.4rem",
              width: "max-content",
              padding: "0.4rem",
              margin: "0 0 2.8rem 0",
            }}
          >
            <span>Compartilhar</span>
            <Tooltip title="Copiar link" arrow>
              <button
                style={{
                  cursor: "pointer",
                  border: "0",
                  backgroundColor: "transparent",
                }}
                onClick={HandleCopyClipboard}
              >
                <Icon
                  component={clipboard_button_icon}
                  sx={{
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              </button>
            </Tooltip>
            <Tooltip title="Whatsapp" arrow>
              <button
                style={{
                  cursor: "pointer",
                  border: "0",
                  backgroundColor: "transparent",
                }}
                onClick={() =>
                  window.open(
                    `https://web.whatsapp.com/send?text=${post?.title} ${URL_TO_SHARE}`,
                    "_blank"
                  )
                }
              >
                <Icon
                  component={whatsapp_button_icon}
                  sx={{
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              </button>
            </Tooltip>
            <Tooltip title="Twitter" arrow>
              <button
                style={{
                  cursor: "pointer",
                  border: "0",
                  backgroundColor: "transparent",
                }}
                onClick={() =>
                  window.open(
                    `https://twitter.com/share?url=${post?.title} ${URL_TO_SHARE}`,
                    "_blank"
                  )
                }
              >
                <Icon
                  component={TwitterIcon}
                  sx={{
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              </button>
            </Tooltip>
            <Tooltip title="Facebook" arrow>
              <button
                style={{
                  cursor: "pointer",
                  border: "0",
                  backgroundColor: "transparent",
                }}
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${URL_TO_SHARE}%3Futm_source%3Dfacebook%26utm_medium%3Dsocial%26utm_campaign%3Dcompfb`,
                    "_blank"
                  )
                }
              >
                <Icon
                  component={FacebookIcon}
                  sx={{
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              </button>
            </Tooltip>
          </div>

          <h3>{post?.description}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: post?.content }}
            style={{
              flex: 1,
              textAlign: "justify",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            <h3>Acesse:</h3>
            <br />
            {post?.links?.map((link, index) => (
              <a
                href={link.link}
                target="_blank"
                key={index}
                style={{
                  marginLeft: "1rem",
                  textDecoration: "none",
                  color: "#42379e",
                  fontSize: "1.1rem",
                }}
              >
                {index + 1} - {link.link}
              </a>
            ))}
          </div>
          <PrevNextContainer post={post} />
        </Box>
      </Container>
      <WhatsappButton />
      <Footer />
    </>
  );
};

export default SciencePlusRead;

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { useMainPages } from "modules/Header/data";
import { useEffect, useState } from "react";
import UserInfo from "./sections/UserInfo";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckoutHeader from "./CheckoutHeader";
import PaymentIcon from "@mui/icons-material/Payment";
import PaymentInfo from "./sections/PaymentInfo";
import AddressInfo from "./sections/AddressInfo";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ConfirmOrder from "./sections/ConfirmOrder";
import { Get } from "utils/request";
import CheckIcon from "@mui/icons-material/Check";
import { STORAGE_URL } from "variables";
import { useHistory } from "react-router";
import { Show } from "actions/SnackbarActions";
import { useDispatch, useSelector } from "react-redux";

const CheckoutPage = ({
  course_data,
  background_color,
  banner,
  card_color,
  fontColor,
  accent_color,
  cover_image,
  aside_banner,
  hidden
}) => {
  const pages = useMainPages();
  const dispatch = useDispatch();
  const history = useHistory();
  let user_id = null;

  const [is_loading, setIsLoading] = useState(true);
  const [is_logged, setIsLogged] = useState(undefined);
  const [course, SetCourse] = useState({});
  const [user, setUser] = useState({});
  const [selectedOpcells, setSelectedOpcells] = useState([]);
  const [opcells, setOpcells] = useState([]);

  // payment states
  const [cpf, setCpf] = useState("");
  const [number, setNumber] = useState("");
  const [brand, setBrand] = useState("");
  const [due_date, setDueDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [cvvLength, setCvvLength] = useState("");
  // user info
  const [user_name, setUserName] = useState(user?.name ? user.name : "");
  const [gender, setGender] = useState("");
  const [birth_date, setBirthDate] = useState("");
  const [phone, setPhone] = useState(user?.phone ? user.phone : "");
  const currentUser = useSelector((store) => store.AppReducer.user);

  const getCourse = async () => {
    SetCourse(course_data);

    let opcellsArray = [];
    if (course?.opcells) {
      course?.opcells.forEach((opcell) => {
        opcellsArray.push(opcell);
      });
    }
    setOpcells(opcellsArray);
    getUser();
    setIsLoading(false);
  };

  const getUser = async () => {
    if(currentUser?.role=='Admin' || !user_id){
      return;
    }
    if (user_id === null) {
      setIsLogged(false);
    }

    const response = await Get(`user?id=${user_id}`);
    if (response?.status) {
      setUser(response?.user);
    }
  };

  // adress
  const [form_edited, SetFormEdited] = useState(false);
  const [has_adress, SetHasAdress] = useState(false);

  const [adress, SetAdress] = useState({
    street: {
      value: "",
      error: false,
      helperText: "",
    },
    number: {
      value: "",
      error: false,
      helperText: "",
    },
    complement: {
      value: "",
      error: false,
      helperText: "",
    },
    neighborhood: {
      value: "",
      error: false,
      helperText: "",
    },
    city: {
      value: "",
      error: false,
      helperText: "",
    },
    zipCode: {
      value: "",
      error: false,
      helperText: "",
      handleChange: (cep) => searchAdressByCEP(cep),
    },
    state: {
      value: "",
      error: false,
      helperText: "",
    },
  });

  const searchAdressByCEP = async (cep) => {
    const api_fetch = await fetch(
      `https://viacep.com.br/ws/${cep.replace("-", "")}/json/`,
      { mode: "cors" }
    );
    const data = await api_fetch.json();

    if (data.hasOwnProperty("erro")) {
    } else {
      const { logradouro, uf, bairro, localidade } = data;
      SetAdress({
        ...adress,
        ["street"]: { ...adress["street"], value: logradouro },
        ["state"]: { ...adress["state"], value: uf },
        ["neighborhood"]: { ...adress["neighborhood"], value: bairro },
        ["city"]: { ...adress["city"], value: localidade },
        ["zipCode"]: { ...adress["zipCode"], value: cep },
      });
    }
  };

  useEffect(() => {
    if (is_loading) return;

    if (user?.role === undefined || user?.role === null) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
  }, [user]);

  useEffect(() => {
    try {
      user_id = JSON.parse(localStorage.getItem("user")).id;
    } catch {
      user_id = null;
    }
    getCourse();
  }, []);

  const [coverImageSrc, setCoverImageSrc] = useState("");
  const [bannerSrc, setBannerSrc] = useState("");
  const [asideBannerImg, setAsideBannerImg] = useState("");

  useEffect(() => {
    if (cover_image === "") return setCoverImageSrc("");
    if (cover_image === undefined || cover_image === null) return;
    if (cover_image.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setCoverImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(cover_image);
    } catch (error) {
      setCoverImageSrc(`${STORAGE_URL}${cover_image}`);
    }
  }, [cover_image]);

  useEffect(() => {
    if (banner === "") return setBannerSrc("");
    if (banner === undefined || banner === null) return;
    if (banner.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setBannerSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(banner);
    } catch (error) {
      setBannerSrc(`${STORAGE_URL}${banner}`);
    }
  }, [banner]);

  useEffect(() => {
    if (aside_banner === "") return setAsideBannerImg("");
    if (aside_banner === undefined || aside_banner === null) return;
    if (aside_banner.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setAsideBannerImg(e.target.result);
    };
    try {
      reader.readAsDataURL(aside_banner);
    } catch (error) {
      setAsideBannerImg(`${STORAGE_URL}${aside_banner}`);
    }
  }, [aside_banner]);

  if (is_loading)
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          height: "90vh",
          width: "100%",
        }}
      >
        <CircularProgress />
        <Typography variant="h5" style={{ marginLeft: "1rem" }}>
          Carregando...
        </Typography>
      </div>
    );

  if (!is_logged && is_logged !== undefined) {
    history.push("/courses/adquire/" + course?.id);

    setTimeout(() => {
      dispatch(
        Show({
          show: true,
          severity: "success",
          message:
            "Você ainda não tem uma conta! Se inscreva no curso para continuar.",
        })
      );
    }, 1000);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: background_color,
        position: "relative",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          position: "fixed",
          // width: "100vw",
          // height: "100vh",
          zIndex: 1,
          overflow: "hidden",
        }}
      >
        <img
          src={coverImageSrc}
          style={{
            objectFit: "cover",
            backgroundPosition: "center",
            width: "100vw",
            // height: "100vh",
            maskImage:
              " linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, transparent 76%)",
          }}
        />
      </div>

      <Box
        sx={{
          display: "flex",
          // minHeight: "100vh",
          height: "max-content",
          justifyContent: "space-between",
          "& .MuiFormControl-root": {
            borderRadius: "0.4rem",
            overflow: "hidden",
          },
          zIndex: 10,
          padding: "2rem",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "62%",
            },
            display: "flex",
            flexDirection: "column",
            backgroundColor: card_color,
          }}
        >
          {bannerSrc !== null && bannerSrc !== undefined && (
            <img
              src={bannerSrc}
              alt="Banner"
              style={{
                width: "100%",
                objectFit: "cover",
                aspectRatio: "22/9",
              }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: {
                xs: "0.6rem",
                sm: "1rem",
                md: "2rem",
              },
              gap: "1rem",
            }}
          >
            <CheckoutHeader
              icon={<AccountCircleIcon sx={{ fontSize: "2.2rem" }} />}
              title="Logado como:"
              color={accent_color}
            />
            <UserInfo
              {...{
                course,
                user,

                font_color: fontColor,
                accent_color,
              }}
            />

            

            <CheckoutHeader
              icon={<PaymentIcon sx={{ fontSize: "2.2rem" }} />}
              title="Endereço:"
              color={accent_color}
            />
            <AddressInfo
              {...{
                course,
                user_id: user?.id,
                adress: adress,
                SetAdress: SetAdress,
                has_adress,
                SetHasAdress,
                form_edited,
                SetFormEdited,

                font_color: fontColor,
                accent_color,
              }}
            />

<CheckoutHeader
              icon={<PaymentIcon sx={{ fontSize: "2.2rem" }} />}
              title="Pagamento:"
              color={accent_color}
            />
            <PaymentInfo
              {...{
                course,

                cpf,
                setCpf,
                number,
                setNumber,
                brand,
                setBrand,
                due_date,
                setDueDate,
                cvv,
                setCvv,
                cvvLength,
                setCvvLength,

                user_name,
                setUserName,
                gender,
                setGender,
                birth_date,
                setBirthDate,
                phone,
                setPhone,

                font_color: fontColor,
                accent_color,
              }}
            />

            <CheckoutHeader
              icon={<ShoppingCartIcon sx={{ fontSize: "2.2rem" }} />}
              title="Confirmação:"
              color={accent_color}
            />
            <ConfirmOrder
              {...{
                course,
                opcells,
                selectedOpcells,
                setSelectedOpcells,
                font_color: fontColor,
                accent_color,
              }}
            />

            <Button
              variant="contained"
              size="large"
              // onClick={() => verifyPage("next")}
              endIcon={<CheckIcon />}
              sx={{
                backgroundColor: accent_color,
                marginBottom: "4rem",
              }}
            >
              Confirmar Pedido
            </Button>
          </Box>

          {bannerSrc !== null && bannerSrc !== undefined && (
            <img
              src={bannerSrc}
              alt="Banner"
              style={{
                width: "100%",
                objectFit: "cover",
                aspectRatio: "22/9",
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            width: "38%",
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
            },
            height: "100%",
            flexDirection: "column",
            // backgroundColor: "blue",
            // position: "relative",
          }}
        >
          {asideBannerImg !== null && asideBannerImg !== undefined && (
            <img
              src={asideBannerImg}
              alt="Banner"
              style={{
                objectFit: "cover",
                aspectRatio: "9/22",
                width: "100%",
                position: "-webkit-sticky",
                position: "sticky",
                top: "0.2rem",
                borderRadius: 0,
              }}
            />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default CheckoutPage;

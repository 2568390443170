import { Typography } from "@mui/material";
import { Container } from "@mui/system";

import img_warranty from "assets/images/warranty.svg";

const Warranty = ({ course }) => {
  const {
    show_warranty,
    warranty_title,
    warranty_text,
    warranty_bgColor_1,
    warranty_bgColor_2,
  } = course;

  return warranty_title ? (
    <section
      style={{
        padding: "6rem 0",
        background: `linear-gradient(180deg, ${warranty_bgColor_1} 0%, ${warranty_bgColor_2} 100%)`,
        justifyContent: "center",
        alignItems: "center",
        display: show_warranty ? "flex" : "none",
        position: "relative",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "column", lg: "row" },
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          zIndex: 10,
          position: "relative",
        }}
      >
        <img src={img_warranty} style={{ width: "60%", minWidth: "14rem", maxWidth: '24rem' }} />
        <div>
          <Typography
            variant="h4"
            sx={{
              backdropFilter: "blur(1.4rem)",
              padding: "0.1rem 1rem",
              borderRadius: "0.8rem",
              textAlign: { xs: "column", md: "center", lg: "left" },
            }}
            dangerouslySetInnerHTML={{ __html: warranty_title }}
          />
          <Typography
            variant="h6"
            sx={{
              backdropFilter: "blur(1.4rem)",
              padding: "0.1rem 1rem",
              borderRadius: "0.8rem",
              textAlign: { xs: "column", md: "center", lg: "left" },
            }}
            dangerouslySetInnerHTML={{ __html: warranty_text }}
          ></Typography>
        </div>
      </Container>
    </section>
  ) : (
    <></>
  );
};

export default Warranty;

import { Box, Container, Typography } from "@mui/material";
import Image from "components/Image";
import { STORAGE_URL } from "variables";
import banner from "./integrative_banner.jpg";

const Banner = ({ title = "", subtitle = "", background_image = "" }) => {
  return (
    <Box>
      <Image
        src={STORAGE_URL + background_image}
        width={"100%"}
        position={"relative"}
        style={{
          aspectRatio: "3.42 / 1",
          backgroundSize: "cover",
          backgroundColor: "#ccc",
        }}
      >
        <Container maxWidth="xl">
          <Box
            position={"absolute"}
            display={"flex"}
            height={"100%"}
            justifyContent={"flex-end"}
            flexDirection={"column"}
            color={"white"}
            sx={{ fontWeight: "bold", justifyContent: "center" }}
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold" }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold" }}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </Box>
        </Container>
      </Image>
    </Box>
  );
};

export default Banner;

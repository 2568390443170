import React from "react";

import CourseUsers from "../CourseUsers";
import ModuleList from "../Modules/List";
import GroupsList from "../Groups/List";
import TabsContainer from "components/TabsContainer";
import Comments from "../Comments";
import Feedback from "../Feedback";

const Tabs = () => {

    return (
        <TabsContainer
            labels={[
                { label: "Módulos" },
                { label: "Alunos" },
                { label: "Turmas" },
                // { label: "Dados" },
                { label: "Comentários" },
                { label: "Feedback" },
                { label: "Certificado" },
            ]}
        >
            <ModuleList/>
            <CourseUsers/>
            <GroupsList/>
            <Comments/>
            <Feedback/>
        </TabsContainer>
    );
}

export default Tabs;
import { IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { RiCloseFill } from "react-icons/ri";

import Modal from "./Modal";

const VideoInputBox = params => {

    const {
        video, 
        index, 
        videoName,
        url = "", 
        newVideo = false, 
        index_file = 1, 
        onClose = () => {}
    } = params;

    const [name, SetName] = useState("Video "+(index_file+1));
    const [modal, SetModal] = useState(false);

    const thumb_ref = useRef();

    const OpenModal = () => SetModal(true);
    const CloseModal = () => SetModal(false);

    useEffect(() => {
        if (video === undefined) return;
        thumb_ref.current.src = URL.createObjectURL(video);
        SetName(video.name);
    }, [video]);

    useEffect(() => {
        if (videoName != "" && videoName !== undefined && videoName !== null) SetName(videoName);
    }, [name]);

    return (
        <div className={"video-input-box " + (newVideo ? "video-new" : "")}>
            <IconButton
                id="remove-button"
                size="small"
                onClick={() => (onClose && onClose(index))}
            >
                <RiCloseFill style={{
                    width: "0.7em",
                    color: "white"
                }} />
            </IconButton>
            <video
                autoPlay={false}
                controls={false}
                className="video-thumb"
                ref={thumb_ref}
                src={url}
                onClick={OpenModal}
            />
            <div className="video-name">
                {name ? name : "Video sem nome"}
            </div>
            <Modal
                open={modal}
                onClose={CloseModal}
            />
        </div>
    );
}

export default VideoInputBox;
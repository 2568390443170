import { FormLabel, FormControl as Input } from "react-bootstrap";
import DefaultButton from "components/DefaultButton";
import Label from "components/InfoLabel";
import InputCard from "../../InputCard";
import JoditEditor from "jodit-react";
import ColorInput from "components/Inputs/ColorInput";
import { Typography } from "@mui/material";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const Section4 = ({
  show_section4,
  SetShowSection4,
  recommendedTo,
  SetRecommendedTo,

  section4_title,
  SetSection4Title,

  section4_bgColor_1,
  SetSection4BgColor1,
  section4_bgColor_2,
  SetSection4BgColor2,

  JoditConfig,
}) => {
  // // recommendedTo
  // const addRecommendedTo = () => {
  //   SetRecommendedTo([...recommendedTo, { name: "" }]);
  // };

  // const removeRecommendedTo = (index) => {
  //   let temp = [...recommendedTo];
  //   temp.splice(index, 1);
  //   SetRecommendedTo(temp);
  // };

  // const handleRecommendedToChange = (index, name) => {
  //   let temp = recommendedTo;
  //   temp[index].name = name;
  //   SetRecommendedTo([...temp]);
  // };

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={show_section4}
              onChange={(e) => SetShowSection4(e.target.checked)}
            />
          }
          label="Ativar seção"
        />
      </FormGroup>
      <div
        style={{
          marginTop: "2rem",
          flexDirection: "column",
          display: "flex",
          gap: "1rem",
          marginTop: "2rem",
          position: "relative",
        }}
      >
        <div
          style={{
            display: show_section4 ? "none" : "flex",
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.5)",
            zIndex: 10,
          }}
        >
          <div
            style={{
              margin: "auto",
              backgroundColor: "white",
              color: "red",
              padding: "1rem",
              borderRadius: "0.4rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.6rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
              }}
            >
              Seção desativada
            </Typography>
            <Typography
              variant="subtitle"
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Por padrão, esta seção não sera exibida na página do curso. Clique
              em "Ativar seção" para ativá-la.
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={show_section4}
                    onChange={(e) => SetShowSection4(e.target.checked)}
                  />
                }
                sx={{ color: "black" }}
                label="Ativar seção"
              />
            </FormGroup>
          </div>
        </div>
        <FormLabel>
          <Label
            label={
              <span style={{ fontSize: "1.2rem" }}>Curso indicado para</span>
            }
            required
            title="Para quem o curso é indicado? Ex: Pessoas que querem aprender a programar, pessoas que querem aprender a tocar violão, etc."
          />
        </FormLabel>

        <FormLabel>
          Título da seção<span style={{ color: "red" }}>*</span>
          <JoditEditor
            config={JoditConfig}
            value={section4_title}
            onChange={(value) => SetSection4Title(value)}
          />
        </FormLabel>

        <FormLabel>
          <Label
            label={<span>Cor de fundo 1 </span>}
            required
            title="Esta cor será aplicada no fundo da seção."
          />
        </FormLabel>
        <ColorInput
          value={section4_bgColor_1}
          onChange={(color) =>
            color !== undefined && SetSection4BgColor1(color)
          }
        />

        <FormLabel>
          <Label
            label={<span>Cor de fundo 2 </span>}
            required
            title="Esta cor será aplicada no fundo da seção. Se for definida, um gradiente será aplicado baseado na cor 1."
          />
        </FormLabel>
        <ColorInput
          value={section4_bgColor_2}
          onChange={(color) =>
            color !== undefined && SetSection4BgColor2(color)
          }
        />

        <FormLabel>
          Texto
          <JoditEditor
            config={JoditConfig}
            value={recommendedTo}
            onChange={(value) => SetRecommendedTo(value)}
          />
        </FormLabel>

        {/* <div
          style={{
            dislay: "flex",
            gap: "0.6rem",
            flexWrap: "wrap",
            marginTop: "1rem",
          }}
        >
          {recommendedTo.map((user, index) => (
            <InputCard
              key={index}
              index={index}
              remove={removeRecommendedTo}
              handle={handleRecommendedToChange}
              JoditConfig={JoditConfig}
              text={user.name}
            />
          ))}
          {recommendedTo.length === 0 && (
            <Typography
              variant="subtitle"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              Nenhum item adicionado
            </Typography>
          )}
        </div>
        <DefaultButton
          bg="confirm"
          text="Adicionar opção"
          onClick={addRecommendedTo}
          sx={{ marginTop: "1rem" }}
        /> */}
      </div>
    </div>
  );
};

export default Section4;

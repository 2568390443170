import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useState } from "react";
import JoditEditor from "jodit-react";
import style from "./style.module.css";

const InputCard = ({ text, index, remove, handle, JoditConfig }) => {
  // const [deleted, setDeleted] = useState(false);

  const handleRemoveCard = () => {
    remove(index);
    // setDeleted(true);
    // setTimeout(() => {
    //   setDeleted(false);
    // }, 200);
  };

  return (
    <div className={style.recommendedCard}>
      <input
        type="text"
        value={text}
        onInput={(e) => handle(index, e.target.value)}
        placeholder="Nome"
        style={{
          border: "none",
          outline: "none",
          padding: "0.6rem 0.8rem",
          backgroundColor: "#ebebeb",
          borderRadius: "0.2rem",
        }}
      />
      <CancelRoundedIcon
        sx={{
          position: "absolute",
          color: "red",
          right: "-0.6rem",
          top: "0",
          opacity: 0.8,
          fontSize: "1.6rem",
          transform: "translateY(-50%)",
          cursor: "pointer",
          "&:hover": {
            opacity: 1,
          },
        }}
        onClick={handleRemoveCard}
      />
    </div>
  );
};

export default InputCard;

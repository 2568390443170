import { Box, Typography } from "@mui/material";

const Bottom = ({ cards }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {cards !== undefined &&
        cards?.map((card, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              width: "100%",
              gap: "1rem",
              background: "rgb(240, 244, 244)",
              borderRadius: "0.8rem",
              overflow: "hidden",
            }}
            >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "1rem",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold" }}
                dangerouslySetInnerHTML={{ __html: card?.title }}
              />
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold" }}
                dangerouslySetInnerHTML={{ __html: card?.text }}
              />
            </div>

            <img
              src={card?.image}
              alt="Cartao"
              style={{
                width: "33%",
                maxHeight: "20rem",
                objectFit: "cover",
                marginLeft: "1rem",
                display: card?.image ? "block" : "none",
              }}
            />
          </Box>
        ))}
    </Box>
  );
};

export default Bottom;

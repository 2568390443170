import { Box } from "@mui/material";
import { createContext } from "react";

import style from "./style.module.css";
import HeaderSection from "./Sections/HeaderSection";
import WhatWillLearn from "./Sections/WhatWillLearn";
import LeadsCourse from "./Sections/LeadsCourse";
import Responsibles from "./Sections/Responsibles";
import RecommendedTo from "./Sections/RecommendedTo";
import Rating from "./Sections/Rating";
import Pricing from "./Sections/Pricing";
import Warranty from "./Sections/Warranty";
import Questions from "./Sections/Questions";
import WhatWillGet from "./Sections/WhatWillGet";
// import CourseTestimonials from "./Sections/Testimonials";

export const AdquireCourseContext = createContext(null);

const AdquireCoursePreview = (props) => {
  return (
    <>
      <Box
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          position: "relative",
          maxWidth: "56vw",
          paddingTop: "0.1rem",
          width: "100%",
        }}
      >
        {/* section 1 */}
        <HeaderSection
          {...{ props, style }}
        />

        <WhatWillLearn {...{ props }} />

        <RecommendedTo {...{ props }} />

        <Responsibles {...{ props }} />

        <WhatWillGet {...{ props }}  />

        <Pricing {...{ props, style }} />
        
        {/* temp section 5 */}
        <Warranty {...{ props }}  />
        
        <Questions {...{ props }} />
      </Box>

      {/* <Footer /> */}
    </>
  );
};

export default AdquireCoursePreview;

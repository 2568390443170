import { useState } from "react";
import Formbody from "./Formbody";
import { Post } from "utils/request";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import SweetAlert from "react-bootstrap-sweetalert";

const AsideBannerEdit = ({ handleClose, OnDelete, value }) => {
  const [popup, SetPopup] = useState(false);
  const [popupMessage, SetPopupMessage] = useState("");

  const [loading, SetLoading] = useState(false);

  const dispatch = useDispatch();

  const Submit = async (data) => {
    let form = new FormData();
    form.append("id", data?.id);
    form.append("link", data?.link);
    form.append("name", data?.name);
    form.append("description", data?.description);

    SetLoading(true);
    let response = await Post("courses-main/free-courses/update", form);
    SetLoading(false);

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );
    handleClose();
    OnDelete();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <SweetAlert
        warning
        show={popup}
        title="Atenção!"
        confirmBtnText="Ok"
        onConfirm={() => SetPopup(false)}
      >
        {popupMessage}
      </SweetAlert>
      <div className="card-body">
        <h2 className="title">Editar aulas gratuitas</h2>
        <Formbody OnConfirm={(data) => Submit(data)} data={value} loading={loading} />
      </div>
    </div>
  );
};

export default AsideBannerEdit;

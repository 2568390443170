import { useEffect, useRef, useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const CollapseQuestions = (props) => {
  const { question, answer } = props;

  const ref = useRef(null);

  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        boxShadow: "0 0 0 0.1rem #e4dcec",
        borderRadius: "0.6rem",
        width: "100%",
        transition: `250ms ease`,
        opacity: 1,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          padding: "0.8rem",
          fontWeight: "bold",
          gap: '2rem'
        }}
        onClick={handleClick}
      >
        {question}

        <div
          style={{
            backgroundColor: "#f4f4f4",
            padding: "0.2rem 0.4rem 0.2rem 0.8rem",
            borderRadius: "0.4rem",
            display: 'flex'
          }}
        >
          <span style={{ color: "rgb(90, 90, 90)", fontWeight: "normal" }}>
            {open ? "esconder resposta" : "mostrar resposta"}
          </span>
          <ExpandLessIcon
            sx={{
              transition: `400ms ease`,
              ...(open && { transform: "rotate(180deg)" }),
            }}
          />
        </div>
      </div>

      <div
        ref={ref}
        style={{
          transition: "500ms ease",
          maxHeight: open ? height + 32 + 150 : 0,
          backgroundColor: "#ededed",
          overflow: "hidden",
          padding: open ? "16px" : "0 16px",
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontFamily: "var(--Raleway)" }}
        >
          {answer}
        </Typography>
      </div>
    </div>
  );
};

export default CollapseQuestions;

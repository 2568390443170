import { FormLabel, FormControl as Input } from "react-bootstrap";
import DefaultButton from "components/DefaultButton";
import JoditEditor from "jodit-react";
import ColorInput from "components/Inputs/ColorInput";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import Label from "components/InfoLabel";
import { useEffect } from "react";
import Card from "./card";

const Temp_section5 = ({
  temp_show_section5,
  SetTempShowSection5,

  whatWillGet,
  SetWhatWillGet,
  temp_section5_title,
  SetTempSection5Title,

  temp_section5_bgColor_1,
  SetTempSection5BgColor1,
  temp_section5_bgColor_2,
  SetTempSection5BgColor2,

  section5_cardBorderColor,
  SetSection5CardBorderColor,
  section5_cardBorderSize,
  SetSection5CardBorderSize,

  temp_section5_cardColor,
  SetTempSection5CardColor,

  JoditConfig,
}) => {
  // whatWillGet
  const addWhatWillGet = () => {
    SetWhatWillGet([...whatWillGet, { title: "", text: "", image: "" }]);
  };

  const removeWhatWillGet = (index) => {
    let temp = [...whatWillGet];
    temp.splice(index, 1);

    SetWhatWillGet(temp);
  };

  const handleWhatWillGetChange = (index, data) => {
    console.log("data", data);
    let temp = whatWillGet;
    // temp[index].title = data.title;
    // temp[index].text = data.text;
    // temp[index].image = data.image;

    temp[index] = { ...temp[index], ...data };
    SetWhatWillGet([...temp]);
  };

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={temp_show_section5}
              onChange={(e) => SetTempShowSection5(!temp_show_section5)}
            />
          }
          label="Ativar seção"
        />
      </FormGroup>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          gap: "1rem",
          marginTop: "2rem",
          position: "relative",
        }}
      >
        <div
          style={{
            display: temp_show_section5 ? "none" : "flex",
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.5)",
            zIndex: 10,
          }}
        >
          <div
            style={{
              margin: "auto",
              backgroundColor: "white",
              color: "red",
              padding: "1rem",
              borderRadius: "0.4rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.6rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
              }}
            >
              Seção desativada
            </Typography>
            <Typography
              variant="subtitle"
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Por padrão, esta seção não sera exibida na página do curso. Clique
              em "Ativar seção" para ativá-la.
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={temp_show_section5}
                    onChange={(e) => SetTempShowSection5(!temp_show_section5)}
                  />
                }
                sx={{ color: "black" }}
                label="Ativar seção"
              />
            </FormGroup>
          </div>
        </div>
        <FormLabel>
          <Label
            label={
              <span style={{ fontSize: "1.2rem" }}>O que você vai receber</span>
            }
            required
            title="Seção para adicionar os tópicos que o aluno vai receber com o curso."
          />
        </FormLabel>

        <FormLabel>
          Título da seção<span style={{ color: "red" }}>*</span>
          <JoditEditor
            config={JoditConfig}
            value={temp_section5_title}
            onChange={(value) => SetTempSection5Title(value)}
          />
        </FormLabel>

        <FormLabel>
          <Label
            label={<span>Cor de fundo 1 </span>}
            required
            title="Esta cor será aplicada no fundo da seção."
          />
        </FormLabel>
        <ColorInput
          value={temp_section5_bgColor_1}
          onChange={(color) =>
            color !== undefined && SetTempSection5BgColor1(color)
          }
        />

        <FormLabel>
          <Label
            label={<span>Cor de fundo 2 </span>}
            required
            title="Esta cor será aplicada no fundo da seção. Se for definida, um gradiente será aplicado baseado na cor 1."
          />
        </FormLabel>
        <ColorInput
          value={temp_section5_bgColor_2}
          onChange={(color) =>
            color !== undefined && SetTempSection5BgColor2(color)
          }
        />

        <div
          style={{
            display: "flex",
            gap: "2rem",
            flexWrap: "wrap",
            marginTop: "1rem",
            maxHeight: "60rem",
            overflowY: "auto",
            padding: "1rem 0",
          }}
        >
          {whatWillGet.map((item, index) => (
            <Card
              key={index}
              index={index}
              remove={removeWhatWillGet}
              handle={handleWhatWillGetChange}
              data={item}
              JoditConfig={JoditConfig}
            />
          ))}
          {whatWillGet.length === 0 && (
            <Typography
              variant="subtitle"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              Nenhum item adicionado
            </Typography>
          )}
        </div>

        <DefaultButton
          bg="confirm"
          text="Adicionar opção"
          onClick={addWhatWillGet}
          sx={{ margin: "1rem 0" }}
        />
      </div>

      <FormLabel>
        <Label
          label={<span>Cor dos cartões </span>}
          required
          title="Esta cor será aplicada nos cartões da seção."
        />
      </FormLabel>
      <ColorInput
        value={temp_section5_cardColor}
        onChange={(color) =>
          color !== undefined && SetTempSection5CardColor(color)
        }
      />

      <br />

      <FormLabel>
        <Label
          label={<span>Cor da moldura dos cartões </span>}
          required
          title="Esta cor será aplicada na moldura dos cartões da seção."
        />
      </FormLabel>
      <ColorInput
        value={section5_cardBorderColor}
        onChange={(color) =>
          color !== undefined && SetSection5CardBorderColor(color)
        }
      />

      <br />

      <FormLabel>
        <Label
          label={<span>Tamanho da moldura em pixels</span>}
          required
          title="Tamanho da moldura dos cartões da seção."
        />
      </FormLabel>
      <Input
        type="number"
        style={{
          maxWidth: "4rem",
        }}
        required
        value={section5_cardBorderSize}
        onChange={(e) => SetSection5CardBorderSize(e.target.value)}
      />
    </div>
  );
};

export default Temp_section5;

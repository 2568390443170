import ProgressLabel from "components/ProgressLabel";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Show } from "../../../../../actions/SnackbarActions";
import { PostProgress } from "utils/request";
import FormBody from "./FormBody";
import SweetAlert from "react-bootstrap-sweetalert";

const LessonEdit = () => {
  const [loading, SetLoading] = useState(false);
  const [progress, SetProgress] = useState(0);
  const [popup, SetPopup] = useState(false);
  const [popupMessage, SetPopupMessage] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const { module_id, course_id, lesson_id } = useParams();

  const Submit = async (data) => {
    let has_file = false;
    let has_video = false;

    if (!data?.title) {
      SetPopup(true);
      SetPopupMessage("Insira um título!");
      return;
    }

    // if (!data?.videos || data?.videos.videos.length==0) {
    //     SetPopup(true);
    //     SetPopupMessage("Insira um vídeo!");
    //     return;
    // }

    for (let i = 0; i < data?.docs.length; i++) {
        if (data?.docs[i] !== null) {
            has_file = true;
            break;
        }
    }

    for (let i = 0; i < data?.videos.videos.length; i++) {
      if (data?.videos.videos[i] !== null) {
        has_video = true;
        break;
      }
    }

    for (let i = 0; i < data?.existingVideos.length; i++) {
      if (data?.existingVideos[i] !== null) {
        has_video = true;
        break;
      }
    }

    for (let i = 0; i < data?.existingDocs.length; i++) {
      if (data?.existingDocs[i] !== null) {
        has_file = true;
        break;
      }
    }
    

    if (!has_file && !has_video) {
      SetPopup(true);
      SetPopupMessage("Insira um vídeo ou um arquivo!");
      return;
    }

    let temp_quest = JSON.parse(data.questions);
    console.log(temp_quest);
    if (temp_quest) {
      for (let i = 0; i < temp_quest.length; i++) {
        let item = temp_quest[i];
        if (item.utterance == null || item.utterance.trim().length == 0) {
          SetPopup(true);
          SetPopupMessage("Todas as questão devem ter enunciado");
          return;
        }
        if (item.options.length < 2) {
          SetPopup(true);
          SetPopupMessage(
            "Todas as questão devem ter pelo menos duas alternativas"
          );
          return;
        }
        let correct = false;
        for (let j = 0; j < item.options.length; j++) {
          let item2 = item.options[j];
          if (item2.text == null || item2.text.trim().length == 0) {
            SetPopup(true);
            SetPopupMessage("Alternativas de questões não podem ser vazias");
            return;
          }
          if (item2.correct == true) {
            correct = true;
          }
        }

        if (!correct) {
          SetPopup(true);
          SetPopupMessage(
            "Todas as questões devem ter uma alternativa marcada como correta"
          );
          return;
        }
      }
    }

    let form = new FormData();
    form.append("id", lesson_id);
    form.append("cover_image", data?.cover_image);
    form.append("title", data?.title);
    form.append("description", data?.description);
    form.append("questions", data?.questions);
    form.append("allow_answer_reveal", data?.answerReveal);
    form.append("min_percentage", data?.minCorrectPercentage);
    for (let i = 0; i < data?.docs.length; i++)
      form.append("files[]", data?.docs[i]);
    // for (let i = 0; i < data?.videosToDelete.length; i++) form.append("videos_to_delete[]", data?.videosToDelete[i]);
    for (let i = 0; i < data?.docsToDelete.length; i++)
      form.append("docs_to_delete[]", data?.docsToDelete[i]);

    const { general, thumbs, videos } = data?.videos;

    for (let i = 0; i < general.length; i++) {
      form.append(`video${general[i]?.id}`, videos[i]);
      form.append(`video_thumbnail${general[i]?.id}`, thumbs[i]);
      form.append("videos_json[]", JSON.stringify(general[i]));
    }

    SetLoading(true);
    let response = await PostProgress("lessons/update", form, {}, SetProgress);
    SetLoading(false);

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );
    history.push(`/modules/${module_id}/${course_id}`);
  };

  return (
    <div className="card">
      <SweetAlert
        warning
        show={popup}
        title="Atenção!"
        confirmBtnText="Ok"
        onConfirm={() => SetPopup(false)}
      >
        {popupMessage}
      </SweetAlert>
      <div className="card-body">
        <h2 className="title">Editar aula</h2>
        <p>Aqui são editadas as aulas do sistema</p>
        <br />
        <FormBody OnConfirm={(data) => Submit(data)} loading={loading} />
        <div hidden={!loading}>
          <br />
          <ProgressLabel
            variant="determinate"
            value={progress}
            label={progress.toFixed(0) + "%"}
          />
        </div>
      </div>
    </div>
  );
};

export default LessonEdit;

import { Box } from "@mui/material";
import { Skeleton } from "@mui/material";

const SkeletonList = props => {

    const {
        count = 4,
        ...others
    } = props;

    return (
        <Box
            {...others}
            width="100%"
        >
            {new Array(count).fill(undefined).map((_, key) => (
                <Skeleton
                    key={key}
                    width={"100%"}
                    height={"6em"}
                />
            ))}
        </Box>
    );
}

export default SkeletonList;
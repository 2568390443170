import React from "react";
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillYoutube, AiOutlineTwitter } from "react-icons/ai";
import { useSelector } from "react-redux";

const SocialMedias = props => {

    const {
        ...other
    } = props;

    const generalStyle = useSelector(store => store.AppReducer.generalStyle);

    return (
        <div
            className="flex align-center"
            {...other}
        >
            <a href={generalStyle?.facebook_link} hidden={!generalStyle?.facebook_link} target="_blank">
                <AiFillFacebook size={23} color="white"/>
            </a>
            <a href={generalStyle?.youtube_link} hidden={!generalStyle?.youtube_link} target="_blank">
                <AiFillYoutube size={23} color="white"/>
            </a>
            <a href={generalStyle?.instagram_link} hidden={!generalStyle?.instagram_link} target="_blank">
                <AiFillInstagram size={23} color="white"/>
            </a>
            <a href={generalStyle?.twitter_link} hidden={!generalStyle?.twitter_link} target="_blank">
                <AiOutlineTwitter size={23} color="white"/>
            </a>
            <a href={generalStyle?.linkedin_link} hidden={!generalStyle?.linkedin_link} target="_blank">
                <AiFillLinkedin size={23} color="white"/>
            </a>
        </div>
    );
}

export default SocialMedias;
import { Dialog, DialogContent, Modal, IconButton, Card, CardMedia, CardContent, Typography } from "@mui/material";
import { STORAGE_URL } from "variables";

import CloseIcon from '@mui/icons-material/Close';
import CollapseContainer from "components/CollapseContainer";

const ExpandableModal = props => {

    const {
        modules,
        image,
        name = "",
        description = "",
        onClose = () => {},
        ...other
    } = props;

    return (
        <Dialog
            {...other}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: "fit-content",
                    maxWidth: "70vw"
                }
            }}
        >
            <DialogContent 
                className="preview-modal" 
                sx={{
                    ...(image && {backgroundImage: `url(${STORAGE_URL + image})`}),
                    width: "100%",
                    minWidth: "50vw",
                    backgroundSize: "cover"
                }}
            >
                <div style={{float: "right"}}>
                    <IconButton 
                        onClick={onClose}
                        // style={{
                        //     backgroundColor: "#181818"
                        // }}
                    >
                        <CloseIcon style={{ color: "white" }}/>
                    </IconButton>
                </div>
            </DialogContent>
            <DialogContent
                style={{
                    minWidth: "40em"
                }}
            >
                <Typography
                    variant="h4"
                >
                    {name}
                </Typography>
                <Typography
                    variant="body2"
                >
                    {description}
                </Typography>
                <br/>
                <div className="preview-lessons">
                    <Typography 
                        variant="h5"
                        hidden={modules?.length == 0}
                    >
                        Módulos
                    </Typography>
                    <Typography
                        align="center"
                        variant="h6"
                        gutterBottom
                        hidden={modules?.length != 0}
                    >
                        Esse curso não possuí nenhuma aula até o momento
                    </Typography>
                    
                    {modules?.map((module, moduleKey) => module?.lessons?.length != 0 && (
                        <div key={moduleKey}>
                            <CollapseContainer title={module?.name}>
                                {module?.lessons?.map((lesson, lessonKey) => lesson?.files?.files?.length != 0 && (
                                    <div
                                        className="preview-lesson"
                                        key={lessonKey}
                                    >
                                        <Typography 
                                            id={lesson?.id}
                                        >
                                            {lesson?.title}
                                        </Typography>
                                        <div className="preview-files">
                                            {lesson?.files?.videos?.map((video, j) => (
                                                <Card
                                                    key={`video${j}`}
                                                    id={video?.id}
                                                >
                                                    <CardMedia
                                                        component="video"
                                                        alt="card image"
                                                        image={video?.path ? STORAGE_URL + video?.path : ""}
                                                        style={{
                                                            height: "10em",
                                                            background: "black"
                                                        }}
                                                    />
                                                    <CardContent>
                                                        {video?.name}
                                                    </CardContent>
                                                </Card>
                                            ))}
                                            {lesson.files.document?.map((document, j) => (
                                                <Card
                                                    key={`video${j}`}
                                                    id={document?.id}
                                                >
                                                    <CardMedia
                                                        component="img"
                                                        alt="card image"
                                                        image="https://occ-0-2063-1740.1.nflxso.net/dnm/api/v6/X194eJsgWBDE2aQbaNdmCXGUP-Y/AAAABSGfgNoxTh845X5kZbKd7wH9fFJzOzPg9Wgj3T_PgInoSOPj9Wt4ui3nCHbBbiSiDTPZbTVBdDm2M-n-uHxDAAlcb-M.webp?r=9f3"
                                                    />
                                                    <CardContent>
                                                        {document?.name}
                                                    </CardContent>
                                                </Card>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </CollapseContainer>
                        </div>
                    ))}
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ExpandableModal;
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ImageInput from "components/Inputs/ImageInput";
import { useState } from "react";

const Modal = props => {

    const {
        ...others
    } = props;

    const [image, SetImage] = useState("");

    return (
        <Dialog
            {...others}
        >
            <DialogTitle>víííídeo</DialogTitle>
            <DialogContent>
                <ImageInput
                    ImageChange={image => SetImage(image)}
                />
            </DialogContent>
        </Dialog>
    );

}

export default Modal;
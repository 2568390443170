import { useEffect } from "react";
import { useSelector } from "react-redux";

const RoleBased = props => {
    const {
        roles = ["Admin", "Colaborador"],
        also_not_logged,
        children
    } = props;

    const user = useSelector(store => store.AppReducer.user);
    const forcedRole = useSelector(store => store.AdminReducer.forcedRole);

    if ((user?.role == "Admin" && forcedRole !== null && roles.includes(forcedRole))) return children;
    else if (roles.includes(user?.role) && !forcedRole || user?.role === undefined && also_not_logged) return children;
    return null;
}

export default RoleBased;
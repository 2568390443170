import React, { useState } from "react";

import { MdModeEdit, MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from "../../../../components/DefaultButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router";
import { Post } from "../../../../utils/request";
import { Show } from "../../../../actions/SnackbarActions";
import { useDispatch } from "react-redux";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Portal from "components/Portal";
import default_avatar from "assets/images/default.png";
import { STORAGE_URL } from "variables";
import AvailabilityModal from "./AvailabilityModal";

const Board = (props) => {
  const { id, name, email, OnDelete, user } = props;

  console.log(user);

  const [popup, SetPopup] = useState(false);
  const [openAvailabilityModal, SetOpenAvailabilityModal] = useState(false);

  const [loading, SetLoading] = useState(false);

  const course_id = useParams()["id"];
  const dispatch = useDispatch();

  const Delete = async () => {
    let form = new FormData();
    form.append("course_id", course_id);
    form.append("user_id", id);

    let response = await Post("user-course/delete", form);

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );

    if (response?.status === true) OnDelete();
  };

  return (
    <tr>
      <td>
        <img
          src={
            user?.profile_image
              ? STORAGE_URL + user?.profile_image
              : default_avatar
          }
          alt="Foto do usuário"
          style={{
            width: "2.8rem",
            height: "2.8rem",
            borderRadius: "0.2rem",
            objectFit: "cover",
          }}
        />
      </td>
      <td>{name}</td>
      <td>{user?.phone || "Não informado"}</td>
      <td className="table-text-dots">{email}</td>
      <td>
        {popup && (
          <Portal id={"sweet-alert"}>
            <SweetAlert
              warning
              title="Atenção"
              show
              showCancel
              cancelBtnStyle={{ color: "white", textDecoration: "none" }}
              cancelBtnCssClass="btn-danger"
              cancelBtnText="Cancelar"
              confirmBtnText="Excluir"
              onConfirm={() => {
                SetPopup(false);
                Delete();
              }}
              onCancel={() => SetPopup(false)}
            >
              Deseja mesmo remover a conta com e-mail '{email}' do curso?
            </SweetAlert>
          </Portal>
        )}
        <AvailabilityModal
          {...{
            open: openAvailabilityModal,
            setOpen: SetOpenAvailabilityModal,
            user,
            course_id,
            OnDelete,
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <DefaultButton
            to={`/profile/view/${id}`}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Ver perfil`}
            loading={loading}
            bg="warning"
            icon={<BsFillEyeFill size={17} color="white" />}
          />
          <DefaultButton
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Alterar disponibilidade`}
            loading={loading}
            bg="primary"
            icon={<EventAvailableIcon size={17} color="white" />}
            onClick={() => SetOpenAvailabilityModal(true)}
          />
          <DefaultButton
            to={`/courses/exercises-statistics/${course_id}/${id}`}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Ver exercícios realizados`}
            loading={loading}
            bg="info"
            icon={<AssignmentIcon fontSize={"small"} />}
          />
        </div>
      </td>
    </tr>
  );
};

export default Board;

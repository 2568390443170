import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";

import { isAuthenticated } from "./services/auth";
import Home from "./Home/Home";
// import Login from "./Auth/Login";

import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";
import Register from "./pages/Register";

import { useSelector, useDispatch } from "react-redux";
import CourseLandingPage from "./pages/Courses/CourseLandingPage";

import { IconButton, Snackbar, Button } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { RiCloseFill } from "react-icons/ri";
import { Show } from "./actions/SnackbarActions";
import PreRegisterPage from "./pages/PreRegister";
import AboutUsPage from "pages/AboutUsPage";
import MissionPage from "pages/MissionPage";
import PublicRoutes from "Routes/Public";
import ActivateAccount from "pages/Users/ActivateAccount";
import SciencePlusBrowse from "pages/SciencePlus/View/Browse";
import SciencePlusRead from "pages/SciencePlus/View/Read";
import IntegrativeHealth from "pages/IntegrativeHealth";
import PolicyTerms from "pages/PolicyTerms";
import Terms from "pages/Terms";
import ClubeRT from "pages/ClubeRT";
import { setTitle } from "utils/setTitle";
import ScrollToTop from "components/Router/ScrollToTop";
import AdquireCourse from "pages/Courses/AdquireCourse";
import ChangePassword from "pages/ChangePassword";
import WhatsappButton from "components/WhatsappButton";
import Paymant from "pages/Courses/AdquireCourse/Paymant";
import { loggedPages, useMainPages } from "modules/Header/data";
import { Header } from "modules/Header";
import Payment from "pages/Courses/AdquireCourse/Paymant";
import RoleBased from "components/Role/RoleBased";
import ForgotPasswordPage from "pages/ForgotPassword";
import CategoriesCoursePage from "pages/CategoriesCourse";
import CheckoutPage from "pages/CheckoutPage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const stream = useSelector(state => state.AppReducer.stream);
  const token = useSelector((state) => state.AppReducer.token);
  return (
    <Route
      {...rest}
      render={(props) =>
        token != null ? (
          <Component {...props}></Component>
        ) : (
          <Redirect
            to={{ pathname: "/landing", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.AppReducer.token);
  return (
    <Route
      {...rest}
      render={(props) =>
        token === null ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    ></Route>
  );
};

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
    )}
  />
);

const Routes = () => {
  // const user = useSelector((store) => store.AppReducer.user);
  const pages = useMainPages();

  // setTitle("Integrative-se");
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <LoginRoute
            exact
            path="/login/:course_id"
            component={(props) => <Login {...props} />}
          />
          <LoginRoute
            exact
            path="/login"
            component={(props) => <Login {...props} />}
          />
          <LoginRoute
            exact
            path="/landing"
            component={(props) => <LandingPage {...props} />}
          />
          <LoginRoute
            exact
            path="/register/:course_id"
            component={(props) => <Register {...props} />}
          />

          <Route
            exact
            path="/forgot-password"
            render={(props) => <ForgotPasswordPage {...props} />}
          />

          <Route
            exact
            path="/change_password"
            render={(props) => <ChangePassword {...props} />}
          />

          <Route
            exact
            path="/nossos-cursos/:course_name"
            render={(props) => <CourseLandingPage {...props} />}
          />
          <Route
            exact
            path="/pre-inscricao"
            render={(props) => <PreRegisterPage />}
          />
          <Route
            exact
            path="/nossos-cursos/:course_name"
            render={(props) => <CourseLandingPage {...props} />}
          />
          <Route
            exact
            path="/pre-inscricao"
            render={(props) => <PreRegisterPage />}
          />
          <Route
            exact
            path="/about"
            render={(props) => <AboutUsPage />}
            teste={0}
          />
          <Route
            exact
            path="/categories"
            render={(props) => <CategoriesCoursePage />}
            teste={0}
          />
          <Route
            exact
            path="/integrative"
            render={(props) => <IntegrativeHealth />}
          />
          <Route exact path="/mission" render={(props) => <MissionPage />} />
          <Route
            exact
            path={`/user/:session_id/activate`}
            // render={(props2) => <ActivateAccount />}
            render={(props) => <ChangePassword {...props} lead={true} />}
          />
          <Route
            exact
            path={`/science-plus/:post_id/view`}
            render={(props2) => <SciencePlusRead />}
          />
          <Route
            exact
            path={`/science-plus`}
            render={() => <SciencePlusBrowse />}
          />
          <Route exact path={"/policy-terms"} render={() => <PolicyTerms />} />
          <Route exact path={"/terms"} render={() => <Terms />} />

          <Route exact path={"/clube-rt"} render={() => <ClubeRT />} />

          <Route
            exact
            path={`/courses/adquire/:course_id`}
            render={() => (
              <>
                <RoleBased roles={["Aluno"]}>
                  <Header pages={loggedPages} />
                </RoleBased>
                <RoleBased
                  roles={["Admin", "Professor", "Colaborador"]}
                  also_not_logged={true}
                >
                  <Header pages={pages} />
                </RoleBased>

                <AdquireCourse />
              </>
            )}
          />

          <Route
            exact
            path={`/courses/payment/:course_id`}
            render={(props2) => <CheckoutPage />}
          />

          {/* <Route
            exact
            path={`/courses/payment/:course_id`}
            render={(props2) => <Payment />}
          /> */}

          <Route
            exact
            path={`/courses/payment/*`}
            component={(props) => <Redirect to="/login" />}
          />

          <PrivateRoute path={"/"} component={(props) => <Home {...props} />} />

          {/* <OtherRoute path="*" path_name='/' /> */}
        </Switch>
      </ScrollToTop>
      {/* <PublicRoutes/> */}
    </BrowserRouter>
  );
};

export default Routes;

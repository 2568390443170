import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { Pagination } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Reorder, AnimatePresence } from "framer-motion";

import Board from "./Board";
import SaveOrderButton from "modules/SaveOrderButton";
import { Get, Post } from "utils/request";
import { Show } from "actions/SnackbarActions";
import DefaultButton from "components/DefaultButton";
import AsideBannerAdd from "./Add";
import { FormLabel } from "react-bootstrap";
import { Input } from "reactstrap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  maxWidth: "100%",
  minWidth: "36rem",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
  padding: "2rem 3rem",
  justifyContent: "center",
  alignItems: "center",
};

const BannerEdit = (props) => {
  const {
    search = "",
    section_1,
    handleOpenLoadingModal,
    handleCloseLoadingModal,
  } = props;

  const [[categories, tempCategories], SetCategories] = useState([[], []]);
  const [page, SetPage] = useState(1);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useQuery(
    ["banners", search, page],
    async () =>
      await Get(`site-rt/bannerPrincipal-images?search=${search}&page=${page}`),
    {
      onSuccess: (data) => {
        const { banners } = data || {};
        SetCategories([banners, banners]);
      },
    }
  );
  const { pagination: { last_page: maxPage = 1, perPage } = {} } = data || {};

  const orderMutation = useMutation(
    async () => {
      const form = new FormData();
      form.append("perPage", perPage);
      form.append("currPage", page);
      for (let i = 0; i < tempCategories?.length; i++)
        form.append("categoriesIds[]", tempCategories[i]?.id);

      return await Post("site-rt/bannerPrincipal-images/order", form);
    },
    {
      onSuccess: (data) => {
        const { message } = data || {};
        dispatch(
          Show({
            show: true,
            message: message,
            severity: "success",
          })
        );
      },
    }
  );

  const Submit2 = async () => {
    handleOpenLoadingModal();
    let form = new FormData();
    form.append("banner_transition_time", section_1.banner_transition_time);

    form.append("type", "firstContent");
    let response = await Post("site-rt/description/update", form);

    if (response.status === 200) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
        })
      );
    } else if (response.status !== 200) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
          buttonActionText: "Ver",
        })
      );
    }
    handleCloseLoadingModal();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <AsideBannerAdd {...{ handleClose, refetch }} />
        </Box>
      </Modal>

      <DefaultButton
        bg="confirm"
        text="Adicionar novo banner"
        onClick={handleOpen}
      />
      <div className="table-responsive">
        <Reorder.Group
          as={"table"}
          className="table table-striped"
          axis={"y"}
          values={tempCategories}
          onReorder={(value) => SetCategories([categories, value])}
          style={{
            borderColor: "transparent",
            overflow: "hidden",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "3em",
                }}
              />
              <th>Imagem</th>
              <th>Link</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              tempCategories?.map((value, index) => (
                <Board
                  draggable={search == ""}
                  value={value}
                  key={value?.id}
                  id={value?.id}
                  // name={value?.name}
                  OnDelete={refetch}
                />
              ))}
          </tbody>
        </Reorder.Group>
      </div>
      <div className="w100 flex jcc">
        <CircularProgress hidden={!isLoading} />
        <p hidden={categories?.length !== 0 || isLoading}>
          Nenhum banner foi achado
        </p>
      </div>
      <Pagination
        className={maxPage == 1 ? "hide" : ""}
        style={{ display: "flex", justifyContent: "flex-end" }}
        count={maxPage}
        value={page}
        onChange={(_, value) => SetPage(value)}
      />
      <AnimatePresence>
        {JSON.stringify(categories) != JSON.stringify(tempCategories) && (
          <SaveOrderButton
            onClick={() => {
              orderMutation.mutate();
              SetCategories([tempCategories, tempCategories]);
            }}
            onCancel={() => SetCategories([categories, categories])}
          />
        )}
      </AnimatePresence>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6rem",
        }}
      >
        <span
          style={{
            padding: "0.2rem 0.4rem",
            borderRadius: "0.4rem",
            backgroundColor: "#f1f1f1",
          }}
        >
          <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos recomendados:
          2560x731, 1920x548, 1360x388 e 1280x366.
        </span>
        <span
          style={{
            padding: "0.2rem 0.4rem",
            borderRadius: "0.4rem",
            backgroundColor: "#f1f1f1",
          }}
        >
          <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
          .jpeg, .png e .gif.
        </span>
        <span
          style={{
            padding: "0.2rem 0.4rem",
            borderRadius: "0.4rem",
            backgroundColor: "#f1f1f1",
          }}
        >
          <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de
          tamanho para o arquivo.
        </span>

        <FormLabel>
          Tempo de transição entre os banners (em milissegundos) (5000
          milissegundos = 5 segundos)
          <Input
            placeholder="Ex: 5000"
            type="number"
            value={section_1.banner_transition_time}
            onChange={(e) =>
              section_1.setBanner_transition_time(e.target.value)
            }
          />
        </FormLabel>

        <div>
          <Button variant="contained" color="success" onClick={() => Submit2()}>
            Salvar seção 1
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BannerEdit;

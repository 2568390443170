import React, { useState } from "react";

import { MdModeEdit, MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from "components/DefaultButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Post } from "utils/request";
import { Show } from "actions/SnackbarActions";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import Portal from "components/Portal";

const appRoot = document.querySelector(".root");

const Board = props => {

    const {
        id,
        name,
        description,
        onUpdate = () => {}
    } = props;

    const [popup, SetPopup] = useState(false);

    const [loading, SetLoading] = useState(false);

    const dispatch = useDispatch();
    const { coupon_id } = useParams();

    const deleteMutation = useMutation(async () => {
        const form = new FormData();
        form.append("coupon_id", coupon_id);
        form.append("user_id", id);

        return await Post("coupon/users/delete", form);
    }, {
        onSuccess: (data) => {
            ;
            onUpdate();
        }
    });

    return (
        <tr>
            <td>{name}</td>
            <td>
                {popup && (
                    <Portal id="sweet-alert">
                        <SweetAlert
                            type="warning"
                            warning
                            title={"Atenção"}
                            onConfirm={() => {
                                deleteMutation.mutate();
                                SetPopup(false);
                            }}
                            onCancel={() => SetPopup(false)}
                            show
                            confirmBtnText='Remover'
                            cancelBtnText='Cancelar'
                            confirmBtnBsStyle="primary"
                            cancelBtnBsStyle="danger"
                            showCancel={true}
                        >
                            Tem certeza que deseja remover o usuário da turma?
                        </SweetAlert>
                    </Portal>
                )}
                
                <RoleBased>
                    <DefaultButton
                        onClick={() => SetPopup(true)}
                        width="2.2em"
                        height="2.2em"
                        padding={0}
                        title={`Deletar turma`}
                        bg="danger"
                        loading={loading}
                        icon={!loading && <MdDelete size={17} color="white"/>}
                    />
                </RoleBased>
            </td>
        </tr>
    );
}

export default Board;
import React, { Component, useEffect, useState, useRef, useCallback } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// Destinos
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdPhoneLocked, MdSearch } from 'react-icons/md';
import { RiPagesFill } from "react-icons/ri";
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from '../../../components/DefaultButton';


const Cursos = () => {

    const token = useSelector(state => state.AppReducer.token);
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const [loading_screen, setLoading_screen] = useState(false);
    const [loading_search, setLoading_search] = useState(false);
    const [path, setPath] = useState('/');
    const [msgErro, setMsgErro] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [total_pages, setTotal_pages] = useState(1);
    const [cursos, setCursos] = useState([]);
    const [show_warning, setShow_warning] = useState(false);
    const [show_erro, setShow_erro] = useState(false);
    const [curso_to_delete, setCurso_to_delete] = useState('');
    const timeoutRef = useRef(null)




    const Get_data = (page, search = '', busca = false) => {
        if (busca == true) {
            if (loading_search == false) {
                setLoading_search(true);
            }
        }
        else if (loading_screen == false) {
            setLoading_screen(true);
        }
        fetch(`${URL}api/get_cursos?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    setRedirect(true);
                }
                else {
                    setLoading_search(false);
                    setLoading_screen(false);
                    setPage(resp.pagination.pag);
                    setTotal_pages(resp.pagination.last_page)
                    setCursos(resp.cursos);
                }

            } catch (err) {
                setRedirect(true);
                console.log(err);
            }
        })
            .catch((err) => {
                console.log(err);
                setLoading_screen(false);
                setRedirect(true);
            });

    }

    const Delete_curso = (id) => {

        fetch(`${URL}api/delete_curso/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    setMsgErro(erro);
                    setShow_warning(false);
                    setShow_erro(false);
                }
                else {
                    setShow_warning(false);
                    Get_data(page);
                }
            } catch (err) {
                setRedirect(true);
                console.log(err);
            }
        })
            .catch((err) => {
                setRedirect(true);
                console.log(err);
            });

    }

    // useEffect(() => {
    //     Get_data(page);
    // }, []);

    useEffect(() => {
        console.log('search')
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        setLoading_search(true);

        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;
            
            Get_data(1, search, true)
        }, 500);
    }, [search]);

    const handlePageClick = (e) => {

        console.log(e)
        const selectedPage = e.selected;
        setPage(selectedPage + 1)
        if (selectedPage + 1 != page) {
            setPage(selectedPage + 1);
            Get_data(selectedPage + 1, search, true);
        }
    };


    return (
        <div className="row">
            {redirect == true && <Redirect to={path} />}
            <div className="col-12">
                <div className="card">
                    <div className="card-body" id="card">

                        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Lista de cursos</h4>
                        <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os cursos do seu sistema</p>
                        <br />
                        <br />

                        {/* <hr /> */}
                        {loading_screen == false && <div>
                            <div className="row mb-2">
                                <div className="col-12 col-md-6">
                                    <form className="app-search mt-1" style={{ width: '100%' }}>
                                        <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
                                            <input value={search} name="search" placeholder="Pesquisar pelo Nome  ou Nível..." className="form-control" style={{ width: '100%', paddingRight: 40, borderRadius: 100 }} onChange={(e) => {
                                                setSearch(e.target.value)
                                            }} />
                                            <div style={{ width: 40, position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                                <MdSearch />

                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <div className="col-12 col-md-6 d-flex justify-content-end">
                                    <Link to="/cursos/criar" style={{ textDecoration: 'none' }}>
                                        <DefaultButton
                                            className="mt-1"
                                            bg="primary"
                                            text="Adicionar curso"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                    <table id="tech-companies-1" className="table table-striped" style={{ textAlign: 'center' }}>
                                        <thead>
                                            <tr>

                                                <th>Nome </th>
                                                <th>Nível</th>

                                                <th data-priority="3">Ações</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading_search == false && cursos.map((item, id) => (

                                                <tr key={id} style={{ textAlign: 'center' }}>

                                                    <td>{item.nome}</td>
                                                    <td>{item.nivel}</td>
                                                    <td>
                                                        <DefaultButton
                                                            to={`/nossos-cursos/${item?.nameKey}`}
                                                            className='me-1'
                                                            width="2.2em"
                                                            height="2.2em"
                                                            padding={0}
                                                            title={`Visualizar LP`}
                                                            bg="warning"
                                                            icon={<BsFillEyeFill size={17} color="white" />}
                                                        />
                                                        <DefaultButton
                                                            to={`/cursos/configuracao-lp/${item?.nameKey}/${item?.id}`}
                                                            className='me-1'
                                                            width="2.2em"
                                                            height="2.2em"
                                                            padding={0}
                                                            title={`Configurar página`}
                                                            bg="secondary"
                                                            icon={<RiPagesFill size={17} color="white" />}
                                                        />
                                                        <DefaultButton
                                                            to={'/cursos/editar/' + item.id}
                                                            className='me-1'
                                                            width="2.2em"
                                                            height="2.2em"
                                                            padding={0}
                                                            bg="info"
                                                            title={`Editar`}
                                                            icon={<MdModeEdit size={17} color="white" />}
                                                        />
                                                        <DefaultButton
                                                            onClick={() => { setShow_warning(true); setCurso_to_delete(item.id) }}
                                                            className='me-1'
                                                            width="2.2em"
                                                            height="2.2em"
                                                            padding={0}
                                                            title={`Excluir`}
                                                            bg="danger"
                                                            icon={<MdDelete size={17} color="white" />}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                    {loading_search == true &&
                                        <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                            <div className="spinner-border text-primary" role="status"  >
                                            </div>
                                        </div></div>
                                    }
                                </div>
                            </div>
                            {loading_search == false && cursos.length == 0 && <div className="row w-100">
                                <div className="col-12">
                                    <p style={{ textAlign: 'center' }}>Nenhum curso cadastrado</p>

                                </div>
                            </div>}
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <ReactPaginate
                                        previousLabel={'Anterior'}
                                        nextLabel={'Próxima'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={total_pages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={2}
                                        onPageChange={(e)=>handlePageClick(e)}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        initialPage={page - 1}
                                    />
                                </div>
                            </div>


                        </div>}

                        {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                            <div className="spinner-border text-primary" role="status"  >
                            </div>
                        </div></div>}
                        <SweetAlert
                            warning
                            title={"Atenção"}
                            onConfirm={() => { Delete_curso(curso_to_delete); }}
                            onCancel={() => { setShow_warning(false); }}
                            show={show_warning}
                            confirmBtnText='Sim, desejo apagar'
                            cancelBtnText='Cancelar'
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            showCancel={true}
                        >
                            Ao deletar este curso ele não estará mais disponível no sistema

                        </SweetAlert>
                        <SweetAlert
                            danger
                            title={"Erro"}
                            onConfirm={() => {
                                setShow_erro(false);
                            }}
                            show={show_erro}
                            confirmBtnText='OK'
                            confirmBtnBsStyle="primary"

                        >
                            {msgErro}

                        </SweetAlert>

                    </div>

                </div>
            </div>
        </div>
    );




}


export default Cursos;



import React from 'react'
import MethodsMapping from './MethodsMapping';
import Credit from '../../../../assets/images/credit.png';
import Multi from '../../../../assets/images/multi.png';
import Pix from '../../../../assets/images/pix.webp';




const Methods = ({ method, setMethod,color,payments_types,setInstallment }) => {

  return (
    <form className="d-flex flex-column">
      {/* -------------------------Pix-Radio------------------------- */}
      {(payments_types?.pix==true || payments_types==null) && <div className="row pointer my-2" onClick={() => { setMethod(MethodsMapping.pix);setInstallment(1) }}>
        <div className="col-12 d-flex align-items-center">
          <img style={{  height: 30,objectFit:'contain',marginRight:10 }} src={Pix} alt={'pix'} className="img-fluid ms-1" htmlFor={'pix'} />

          <input id={'pix'} type="radio" name="defaultRadio" checked={method === MethodsMapping.pix} onChange={() => ''} />
          <label style={{color:color}} className='ms-1 pointer' htmlFor={'pix'}>Pix</label>
        </div>
      </div>}

      {/* -------------------------Credit-Radio------------------------- */}
      {(payments_types?.credit_card==true || payments_types==null) &&  <div className="row pointer my-2" onClick={() => { setMethod(MethodsMapping.credit_card) }}>
        <div className="col-12 d-flex align-items-center">
        <img style={{height: 30,objectFit:'contain',marginRight:10   }} src={Credit} alt={'credit_card'} className="img-fluid ms-1" htmlFor={'credit_card'} />

          <input id={'credit_card'} type="radio" name="defaultRadio" checked={method === MethodsMapping.credit_card} onChange={() => ''} />
          <label style={{color:color}} className='ms-1 pointer' htmlFor={'credit_card'}>Crédito</label>
        </div>
      </div>}

      {/* -------------------------Multi-Payment-Radio------------------------- */}
      {(payments_types?.multi_payment==true || payments_types==null) &&  <div className="row pointer my-2" onClick={() => { setMethod(MethodsMapping.multi_payment) }}>
        
        <div className="col-12 d-flex align-items-center">
        <img style={{  height: 30,objectFit:'contain' ,marginRight:10  }} src={Multi} alt={'card_one'} className="img-fluid ms-1" htmlFor={'multi_payment'} />

          <input id={'multi_payment'} type="radio" name="defaultRadio" checked={method === MethodsMapping.multi_payment} onChange={() => ''} />
          <label style={{color:color}} className='ms-1 pointer' htmlFor={'multi_payment'}>Multi Pagamento</label>
        </div>
      </div>}
    </form>
  )
}

export default Methods
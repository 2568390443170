import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import TextCard from "../../TextCard";

const WhatWillLearn = ({ course }) => {
  const {
    show_section2,
    what_will_learn,
    section2_title,
    section2_bgColor_1,
    section2_bgColor_2,
    section2_cardTextSize,

    section2_cardBorderColor,
    section2_cardBorderSize,

    section2_cardColor,
    section2_cardTextColor,
  } = course;

  const [whatWillLearnParsed, setWhatWillLearnParsed] = useState([]);

  useEffect(() => {
    let temp_whatWillLearn = [];
    if (what_will_learn) {
      what_will_learn.forEach((item) => {
        temp_whatWillLearn.push(JSON.parse(item));
      });
    }
    setWhatWillLearnParsed(temp_whatWillLearn);
  }, [what_will_learn]);

  const [whatWillLearnCards, setWhatWillLearnCards] = useState([]);

  useEffect(() => {
    const temp = whatWillLearnParsed.slice();
    let result = [];
    for (let i = 3; i > 0; i--) {
      result.push(temp.splice(0, Math.ceil(temp.length / i)));
    }
    setWhatWillLearnCards(result || []);
  }, [whatWillLearnParsed]);

  return (
    whatWillLearnParsed !== undefined &&
    whatWillLearnParsed !== null && (
      <section
        style={{
          padding: "6rem 0",
          justifyContent: "center",
          alignItems: "center",
          // display: "flex",
          display: show_section2 ? "flex" : "none",
          flexDirection: "column",
          background: `linear-gradient(180deg, ${section2_bgColor_1} 0%, ${section2_bgColor_2} 100%)`,
        }}
      >
        <div
          style={{ height: "100%!important", textAlign: "center", width: "100%", }}
        >
          <Typography
            sx={{
              marginBottom: "1rem",
            }}
            dangerouslySetInnerHTML={{ __html: section2_title }}
          />

          <div
            style={{
              display: "flex",
              gap: "0.8rem",
              justifyContent: "center",
              // alignItems: "center",
              flexWrap: "wrap",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: "30rem",
                // flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "0.6rem",
                padding: "2rem 1.4rem",
                borderRadius: "0.8rem",
                backgroundColor: section2_cardColor,
                color: section2_cardTextColor,
                border: `${section2_cardBorderColor} solid ${section2_cardBorderSize}px`,
              }}
            >
              {whatWillLearnCards[0] !== undefined &&
                whatWillLearnCards[0] !== null &&
                whatWillLearnCards[0].map((user, index) => (
                  <TextCard
                    key={index}
                    text={user["text"]}
                    icon_type={1}
                    white_bg={true}
                    {...{ section2_cardTextSize }}
                  />
                ))}
            </div>

            {whatWillLearnCards[1] !== undefined &&
              whatWillLearnCards[1] !== null &&
              whatWillLearnCards[1].length > 0 && (
                <div
                  style={{
                    width: "100%",
                    maxWidth: "30rem",
                    // flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.6rem",
                    padding: "2rem 1.4rem",
                    borderRadius: "0.8rem",
                    backgroundColor: section2_cardColor,
                    color: section2_cardTextColor,
                  border: `${section2_cardBorderColor} solid ${section2_cardBorderSize}px`,
                  }}
                >
                  {what_will_learn !== undefined &&
                    what_will_learn !== null &&
                    whatWillLearnCards[1].length &&
                    whatWillLearnCards[1].map((user, index) => (
                      <TextCard
                        key={index}
                        text={user["text"]}
                        icon_type={1}
                        white_bg={true}
                        {...{ section2_cardTextSize }}
                      />
                    ))}
                </div>
              )}

            {whatWillLearnCards[2] !== undefined &&
              whatWillLearnCards[2] !== null &&
              whatWillLearnCards[2].length > 0 && (
                <div
                  style={{
                    width: "100%",
                    maxWidth: "30rem",
                    // flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.6rem",
                    padding: "2rem 1.4rem",
                    borderRadius: "0.8rem",
                    backgroundColor: section2_cardColor,
                    color: section2_cardTextColor,
                    border: `${section2_cardBorderColor} solid ${section2_cardBorderSize}px`,
                  }}
                >
                  {what_will_learn !== undefined &&
                    what_will_learn !== null &&
                    whatWillLearnCards[2].map((user, index) => (
                      <TextCard
                        key={index}
                        text={user["text"]}
                        icon_type={1}
                        white_bg={true}
                        {...{ section2_cardTextSize }}
                      />
                    ))}
                </div>
              )}
          </div>
        </div>
      </section>
    )
  );
};

export default WhatWillLearn;

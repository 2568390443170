import { useEffect, useState } from "react";

import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import module_style from "./index.module.css";
import rt_logo from "assets/images/rt/main_logo_bgtransparent.png";
import { Box, Button, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { STORAGE_URL } from "variables";
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link } from "react-router-dom";

const Register = (props) => {
  const {
    title,
    subtitle,
    text,
    section_main_color,
    section_left_color,
    section_right_color,
    inputColor,
    inputBgColor,
    accentColor,

    backgroundImage,
    fontColor,
  } = props;

  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [password, SetPassword] = useState("");
  const [coverImageSrc, setCoverImageSrc] = useState("");
  const [showPassword, SetShowPassword] = useState(false);

  const phoneMaskBrazil = (e) => {
    var element = e.target;
    var isAllowed = /\d|Backspace|Tab/;
    if (!isAllowed.test(e.nativeEvent.data)) e.preventDefault();

    var inputValue = element.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/(^\d{2})(\d)/, "($1) $2");
    inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/, "$1-$2");

    SetPhone(inputValue);
  };

  // foca o input da div
  const focusInput = (e) => {
    e.currentTarget.querySelector("input").focus();
  };

  useEffect(() => {
    if (backgroundImage === undefined || backgroundImage === null) return;
    if (backgroundImage === "" || backgroundImage.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setCoverImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(backgroundImage);
    } catch (error) {
      setCoverImageSrc(`${STORAGE_URL}${backgroundImage}`);
    }
  }, [backgroundImage]);

  return (
    <div
      className={module_style.LoginContainer}
      style={{
        backgroundColor: section_main_color,
      }}
    >
      <Box
        sx={{
          display: "flex",
          margin: "auto",
          borderRadius: "0.4rem",
          overflow: "hidden",
          boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.1)",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        {/* <Box
          sx={{
            backgroundColor: section_left_color,
            padding: "4rem",
            gap: "2rem",
            display: "flex",
            flexDirection: "column",
            width: "100%!important",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundImage: `url(${coverImageSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <img src={rt_logo} style={{ objectFit: "contain", width: "10rem" }} />
          <Typography
            variant="h6"
            sx={{ textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Box> */}

        <Box
          sx={{
            backgroundColor: section_right_color,
            padding: "3rem 4rem 4rem 4rem",
            width: "100%!important",
          }}
        >
          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: fontColor }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: fontColor }}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.4rem",
              marginTop: "2rem",
            }}
          >
            <div
              className={module_style.FormInputContainer}
              style={{
                borderBottom: `0.1rem solid ${inputColor}`,
                backgroundColor: inputBgColor,
              }}
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <PersonIcon sx={{ color: inputColor }} />
              <input
                type="text"
                placeholder="Nome"
                value={name}
                onChange={(e) => {
                  SetName(e.target.value);
                }}
                style={{
                  color: inputColor,
                }}
                // error={msgErro}
              />
            </div>
            <div
              className={module_style.FormInputContainer}
              style={{
                borderBottom: `0.1rem solid ${inputColor}`,
                backgroundColor: inputBgColor,
              }}
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <EmailIcon sx={{ color: inputColor }} />
              <input
                type="text"
                placeholder="E-mail"
                value={email}
                onChange={(e) => {
                  SetEmail(e.target.value);
                }}
                style={{
                  color: inputColor,
                }}
                // error={msgErro}
              />
            </div>

            <div
              className={module_style.FormInputContainer}
              style={{
                borderBottom: `0.1rem solid ${inputColor}`,
                backgroundColor: inputBgColor,
              }}
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <LocalPhoneIcon sx={{ color: inputColor }} />
              <input
                maxLength="15"
                type="text"
                placeholder="Telefone"
                value={phone}
                onChange={(e) => {
                  phoneMaskBrazil(e);
                }}
                style={{
                  color: inputColor,
                }}
                required
              />
            </div>

            {/* <div
              className={module_style.FormInputContainer}
              style={{
                borderBottom: `0.1rem solid ${inputColor}`,
                backgroundColor: inputBgColor,
              }}
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <HttpsIcon sx={{ color: inputColor }} />
              <input
                type={!showPassword ? "password" : "text"}
                placeholder="Senha"
                value={password}
                onChange={(e) => {
                  SetPassword(e.target.value);
                }}
                style={{
                  color: inputColor,
                }}
                // error={msgErro}
              />
              <IconButton
                onClick={() => SetShowPassword(!showPassword)}
                sx={{
                  padding: 0,
                  color: "#a1a1a1",
                }}
              >
                {!showPassword ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon sx={{ color: "#ffac1c" }} />
                )}
              </IconButton>
            </div> */}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              {/* <div className="flex fdrow align-center text-muted">
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: accentColor,
                        opacity: 0.8,
                        "&.Mui-checked": {
                          color: accentColor,
                          opacity: 1,
                        },
                      }}
                    />
                  }
                  style={{ color: fontColor }}
                  label="Lembre-se de mim"
                />
              </div> */}

              <Button
                variant="contained"
                sx={{
                  backgroundColor: accentColor,
                  color: 'black',
                  padding: "0.5rem 1.6rem",
                  "&:hover": {
                    backgroundColor: accentColor,
                    filter: "brightness(0.9)",
                  },
                }}
              >
                <SentimentVerySatisfiedIcon style={{ marginRight: "0.5rem" }} />
                Cadastrar
              </Button>
            </div>
            {/* <span style={{ color: fontColor }}>
            Já tem uma conta?{" "}
              <Link className="text-primary">Faça login</Link>
            </span> */}
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default Register;

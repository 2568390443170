import { Box } from "@mui/material";
import Banner from "./Banner";
import Bottom from "./Bottom";
import Intro from "./Intro";
import { Container } from "@mui/system";

const PreviewIntegrativeHealth = (props) => {
  const {
    title,

    title_color,

    subtitle,

    subtitle_color,

    text,

    background_image,

    cards,
  } = props;

  return (
    <Box
    sx={{
      overflowY: "auto",
      overflowX: "hidden",
      position: "relative",
      maxWidth: "56vw",
      width: "100%",
      paddingBottom: "4rem",
    }}
    >
      <Banner
        {...{title, title_color, subtitle, subtitle_color, background_image}}
      />
      <Container maxWidth="xl" sx={{marginTop: '4rem', gap: '4rem'}}>
        <Intro {...{text}} />
        {/* <Middle /> */}
        <br />
        <Bottom {...{cards}} />
      </Container>
    </Box>
  );
};

export default PreviewIntegrativeHealth;

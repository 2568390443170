import { Button, ThemeProvider, Tooltip, useTheme } from "@mui/material";
import { purpleTheme } from "utils/Themes";

import VisibilityIcon from '@mui/icons-material/Visibility';

const ViewButton = props => {

    const {
        title = "",
        onClick = () => { },
        ...others
    } = props;

    const theme = useTheme();

    return (
        <ThemeProvider
            theme={purpleTheme}
        >
            <Tooltip
                title={title}
                arrow
            >
                <Button
                    color="primary"
                    variant={"contained"}
                    onClick={onClick}
                    style={{
                        minWidth: "2.2em",
                        minHeight: "2.2em",
                        margin: theme.spacing(.5),
                        padding: 0
                    }}
                    {...others}
                >
                    <VisibilityIcon
                        fontSize={"small"}
                    />
                </Button>
            </Tooltip>
        </ThemeProvider>
    );
}

export default ViewButton;
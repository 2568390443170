import React, { useEffect, useState } from 'react';
import { Get, GetProgress, Post } from './utils/request';
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from '@mui/styles';
import { Box, createTheme, CssBaseline, LinearProgress, responsiveFontSizes, Typography, Zoom } from '@mui/material';
import { STORAGE_URL } from 'variables';

import Routes from './routes';
import DynamicSnackbar from './DynamicSnackbar';
import CookieAlert from 'modules/CookieAlert';
import WhatsappButton from 'components/WhatsappButton';
import logo from "assets/images/rt/icon_transparent.png";
import "./index.css";
import ForcedRoleAlert from 'modules/ForcedRoleAlert';
import useDBSettings from 'hooks/useDBSettings';
import { AnimatePresence, motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const App = () => {
    
    const {
        theme,
        title,
        tabIcon,
        isLoading: themeIsLoading
    } = useDBSettings();

    const dispatch = useDispatch();
    
    const registerActivity = async (user) => {
        if (!user?.id) return;
        const form = new FormData();
        form.append("user_id", user?.id);
        await Post("user-activity", form);
    }

    const HandleUserSession = () => {
        let token = localStorage.getItem('token');
        let user = localStorage.getItem('user');
        
        if (user === null || user === undefined || user == "undefined" || user == "null") {
            user = {};
            localStorage.removeItem("token");
            localStorage.removeItem("user");
        } else user = JSON.parse(user);

        registerActivity(user);

        dispatch({
            type: "login",
            payload: {
                token: token,
                user: user
            }
        });
    }

    useEffect(HandleUserSession, []);

    return (
        <>
            <AnimatePresence>
                {themeIsLoading && (
                    <Box
                        component={motion.div}
                        initial={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        sx={{
                            position: "fixed",
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "#fff",
                            zIndex: 9999
                        }}
                    >
                        <ThemeProvider theme={theme}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                height="100vh"
                            >
                                <img
                                    src={logo}
                                    width="100"
                                    height="100"
                                    className='infiniteRotateAnim'
                                />
                                <br/>
                                <Typography>
                                    Carregando configurações
                                </Typography>
                            </Box>
                        </ThemeProvider>
                    </Box>
                )}
            </AnimatePresence>
            {!themeIsLoading && (
                <ThemeProvider theme={theme}>
                    <Helmet>
                        {title && <title>{title}</title>}
                        <link rel="icon" href={`${STORAGE_URL}${tabIcon}`} sizes="16x16"/>
                        <link rel="apple-touch-icon" href={`${STORAGE_URL}${tabIcon}`} sizes="16x16"/>
                    </Helmet>
                    <CssBaseline/>
                    <Routes/>
                    <CookieAlert/>
                    {/* <WhatsappButton/> */}
                    <DynamicSnackbar/>
                </ThemeProvider>
            )}
        </>
    );
}

export default App;

import { useSelector } from "react-redux";

const RoleBasedStyle = props => {

    const {
        roles = [],
        hasRoleStyle = {},
        hasNotRoleStyle = {},
        defaultStyle = {},
        children,
        ...other
    } = props;

    const user = useSelector(store => store.AppReducer.user);
    const forcedRole = useSelector(store => store.AdminReducer.forcedRole);

    const role = user?.role;

    let style = {};

    if ((user?.role == "Admin" && forcedRole !== null && roles.includes(forcedRole))) style = Object.assign(defaultStyle, roles.includes(role) ? hasRoleStyle : hasNotRoleStyle);
    else if (roles.includes(user?.role) && !forcedRole) style = Object.assign(defaultStyle, roles.includes(role) ? hasRoleStyle : hasNotRoleStyle);
    else style = defaultStyle;
    return (
        <div
            id="role-based-feature-style"
            style={{ ...style, paddingBottom: 0}} // <- esse negocio aqui ta dando pau na interface (vou ficar de olho pra entender qq ta acontecendo)
            {...other}
        >
            {children}
        </div>
    );
}

export default RoleBasedStyle;
import { Box } from "@mui/material";
import PhoneInput from "components/Inputs/PhoneInput";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import InputMask from "react-input-mask";

const ContactData = (props) => {

    const {
        register
    } = props;

    return (
        <Box>
            <FormLabel>Whatsapp</FormLabel>
            <InputMask
                {...register("whatsapp_number")}
                mask={"(99) 99999-9999"}
                placeholder={"(99) 99999-9999"}
                className={"form-control"}
            />
            <br/>

            <FormLabel>Telefone</FormLabel>
            <InputMask
                {...register("phone_number")}
                mask={"(99) 9999-9999"}
                placeholder={"(99) 9999-9999"}
                className={"form-control"}
            />
            <br/>

            <FormLabel>E-mail da empresa</FormLabel>
            <Input
                {...register("email")}
                placeholder="email@exemplo.com"
            />
            <br/>

            <FormLabel>Facebook</FormLabel>
            <Input
                placeholder="Link do perfil do Facebook"
                {...register("facebook_link")}
            />
            <br/>

            <FormLabel>Youtube</FormLabel>
            <Input
                placeholder="Link do canal do Youtube"
                {...register("youtube_link")}
            />
            <br/>

            <FormLabel>Instagram</FormLabel>
            <Input
                placeholder="Link do perfil do Instagram"
                {...register("instagram_link")}
            />
            <br/>

            <FormLabel>Twitter</FormLabel>
            <Input
                placeholder="Link do perfil do Twitter"
                {...register("twitter_link")}
            />
            <br/>

            <FormLabel>Linkedin</FormLabel>
            <Input
                placeholder="Link do perfil do Linkedin"
                {...register("linkedin_link")}
            />
            <br/>
        </Box>
    );
}

export default ContactData;
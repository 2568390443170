import { Box, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography } from "@mui/material";
import { Pagination } from "@mui/material";
import Image from "components/Image";
import ModalAlert from "components/ModalAlert";
import TableToolbar from "modules/TableToolbar";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useParams } from "react-router";
import { Get, Post } from "utils/request";

import Board from "./Board";
import GuideHelper from "./GuideHelper";
import styles from "./index.module.css";
import emptyState from "assets/images/emptystate2.svg";

const Annotations = forwardRef((props, ref) => {

    const {
        onChangeTime = () => {}
    } = props;

    const [annotations, SetAnnotations] = useState([]);
    const [itemsToDelete, SetItemsToDelete] = useState([]);
    const [deleteModal, SetDeleteModal] = useState(false);

    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const { file_id } = useParams();

    useImperativeHandle(ref, () => ({
        GetData: GetData
    }));

    const GetData = async () => {
        if (!file_id) return;
        let response = await Get(`lessons/annotations?fileId=${file_id}&page=${page}`);
        if (response?.status === true) {
            SetAnnotations(response?.annotations);
            SetMaxPage(response?.pagination?.lastPage);
        }
    }

    const HandleItemsToDeleteSelect = id => SetItemsToDelete([...itemsToDelete, id]);
    const HandleItemsToDeleteUnselect = id => SetItemsToDelete([...itemsToDelete.filter(x => x !== id)]);
    const DeleteItems = async () => {
        let form = new FormData();
        for (let i = 0; i < itemsToDelete?.length; i++) form.append("id[]", itemsToDelete[i]);

        let response = await Post("lessons/annotations/delete", form);
        if (response?.status === true) {
            SetItemsToDelete([]);
            GetData();
        }
    }

    useEffect(GetData, [page, file_id]);
    
    return (
        <Box>
            <TableToolbar
                title="Anotações"
                numSelected={itemsToDelete.length}
                rightContent={<GuideHelper/>}
                onDelete={() => SetDeleteModal(true)}
            />
            <ModalAlert
                open={deleteModal}
                onClose={() => SetDeleteModal(false)}
                onConfirm={DeleteItems}
                showCancelBtn
                confirmBtnText="Deletar"
                title={"Deletar anotações"}
            >
                Deseja mesmo deletar as anotações selecionadas?
            </ModalAlert>
            <TableContainer
                component={Paper}
                className={styles.table}
            >
                <Table>
                    {annotations?.length != 0 && (
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Tempo</TableCell>
                                <TableCell>
                                    Anotação
                                </TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {annotations?.map((annotation, annotationKey) => (
                            <Board
                                key={annotation?.id}
                                id={annotation?.id}
                                time={annotation?.video_time}
                                message={annotation?.message}
                                onSelect={HandleItemsToDeleteSelect}
                                onUnselect={HandleItemsToDeleteUnselect}
                                onUpdate={GetData}
                                onChangeTime={onChangeTime}
                            />
                        ))}
                    </TableBody>
                </Table>
                {annotations?.length == 0 && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems={"center"}
                        padding={3}
                    >
                        <Image
                            src={emptyState}
                            width={320}
                            height={300}
                            style={{
                                backgroundSize: "cover"
                            }}
                        />
                        <Typography
                            align="center"
                        >
                            Sem anotações
                        </Typography>
                    </Box>
                )}
            </TableContainer>
            <br/>
            <Pagination
                className={maxPage == 1 ? "hide": ""}
                style={{ display: "flex", justifyContent: "flex-end" }}
                count={maxPage}
                value={page}
                onChange={(_, value)=> SetPage(value)}
            />
        </Box>
    );
});

export default Annotations;
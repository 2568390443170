
import React, { Component, useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import SweetAlert from 'react-bootstrap-sweetalert';

// Destinos
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdPhoneLocked, MdReply, MdSearch } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { maskCep, maskPrice } from '../../../Auxiliar/Masks';
import DefaultButton from '../../../components/DefaultButton';

const EditarCurso = () => {
    const {id} = useParams();
    const token  = useSelector(state => state.AppReducer.token);
    const dispatch = useDispatch();
    const [curso, setCurso] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [loading_screen, setLoading_screen] = useState(false);
    const [loading_save, setLoading_save] = useState(false);
    const [loading_search, setLoading_search] = useState(false);
    const [path, setPath] = useState('/cursos');
    const [msgErro, setMsgErro] = useState('');
    const [nome, setNome] = useState('');
    const [carga, setCarga] = useState('');
    const [modulos, setModulos] = useState('');
    const [nivel, setNivel] = useState('');
    const [modalidade, setModalidade] = useState('');
    const [show_warning, setShow_warning] = useState(false);
    const [show_erro, setShow_erro] = useState(false);
    const [LPLink, SetLPLink] = useState("");

    const Salvar = () => {
        console.log(token)
        setLoading_save(true);
        setMsgErro('');
        fetch(`${URL}api/update_cursos`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                curso_id: curso.id,
                nome: nome,
                carga_horaria: carga,
                modulos: modulos,
                nivel: nivel,
                modalidade: modalidade,
                landing_page_link: LPLink
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    setLoading_save(false);
                    setMsgErro(erro);
                }
                else {
                    setLoading_save(false);
                    setRedirect(true);
                }
            } catch (err) {
                console.log(err);
                setLoading_save(false);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                setMsgErro('Erro ao pegar resposta do servidor. Você está conectado a internet?');
            });
    }

    const GetData = (id) => {
        setLoading_screen(true);
        fetch(`${URL}api/get_data_curso/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);        
                    setRedirect(true);
                }
                else {
                    setLoading_search(false);
                    setLoading_screen(false);
                    setCurso(resp.curso);  
                    setNome(resp.curso.nome);
                    setCarga(resp.curso.carga_horaria);
                    setModulos(resp.curso.modulos);
                    setNivel(resp.curso.nivel);
                    setModalidade(resp.curso.modalidade);
                    SetLPLink(resp?.curso?.landing_page_link);
                }
   
            } catch (err) {
                setRedirect(true);
                console.log(err);        
            }
        })
            .catch((err) => {
                console.log(err);
                setLoading_screen(false);
                setRedirect(true);
            });
    }


    useEffect(() => {
        GetData(id);
    }, [])

    return (
        <div className="row">
            {redirect == true && <Redirect to={path} />}
            <div className="col-12">
                <div className="card">
                    <div className="card-body" id="card">


                        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar curso</h4>
                        <p style={{ fontSize: '1rem', color: 'black' }}>Edite os cursos do seu sistema</p>
                        <br />

                        {/* <hr /> */}
                        {loading_screen == false &&
                            <div>{msgErro != '' && <div>
                                <div className="alert alert-danger alert-dismissible mb-0" role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMsgErro('')}></button>
                                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{msgErro}</p>
                                </div>
                                <br />
                            </div>}

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome do curso" value={nome} type="text" onChange={(e) => { setNome(e.target.value) }} />
                                            </div>
                                        </div>


                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Carga horária <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" id="carga" placeholder="Quantidade de horas do curso" type="text" onChange={(e) => setCarga(e.target.value)} value={carga} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Módulos </label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" id="modulos" placeholder="Número de módulos do curso" type="text" onChange={(e) => setModulos(e.target.value)} value={modulos} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Link da Landing Page</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Link da Landing Page do curso" value={LPLink} type="text" onChange={(e) => SetLPLink(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Nível <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <select value={nivel} className="form-select" onChange={(e) => { setNivel(e.target.value) }}>
                                                                <option value={''}>Selecione um nível</option>
                                                                <option value={'Graduação'}>Graduação</option>
                                                                <option value={'Técnico'}>Técnico</option>
                                                                <option value={'Capacitações'}>Capacitação</option>
                                                                <option value={'Curso livre'}>Curso livre</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Modalidade</label>
                                                        <div className="col-sm-12">
                                                            <select value={modalidade} className="form-select" onChange={(e) => { setModalidade(e.target.value) }}>
                                                                <option value={''}>Selecione uma modalidade</option>
                                                                <option value={'Presencial'}>Presencial</option>
                                                                <option value={'Digital'}>EAD</option>
                                                                <option value={'Flex'}>Virtual</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/*-------------- loadings -------------*/}
                                {loading_save == false &&
                                    <div className="row mt-3">
                                        <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start" style={{ marginBottom: '0.5rem' }}>
                                            <Link to="/cursos" style={{ textDecoration: 'none' }}>
                                                <DefaultButton
                                                    type="submit"
                                                    bg="secondary"
                                                    text="Voltar"
                                                    icon={<MdReply  color="white" />}
                                                />
                                            </Link>
                                        </div>
                                        <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end" style={{ marginBottom: '0.5rem' }}>
                                            <DefaultButton
                                                type="submit"
                                                bg="confirm"
                                                text="Salvar curso"
                                                loadingtext="Salvando cursos"
                                                onClick={() => Salvar() }
                                            />
                                        </div>
                                       
                                    </div>}

                                {loading_save == true &&
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                            <div className="spinner-border text-primary" role="status" ></div>
                                        </div>
                                    </div>}
                            </div>}
                        {loading_screen == true &&
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status" ></div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default EditarCurso;



import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { motion } from "framer-motion";
import { useQueryClient } from "react-query";

import Actions from "./Actions";
import Description from "./Description";
import styles from "./index.module.css";

const containerVariants = {
  closed: {
    height: 0,
  },
  open: {
    height: "fit-content",
    transition: {
      duration: 0.3,
    },
  },
};

const InfoContainer = (props) => {
  const { id, isUserIn, listed, ...others } = props;

  const queryClient = useQueryClient();

  const onUpdate = async () =>
    await queryClient.refetchQueries("categories", { active: true });

  return (
    <Box
      component={motion.div}
      width={"100%"}
      bgcolor={grey[900]}
      color={"white"}
      className={styles.container}
    >
      <Actions
        onUpdate={onUpdate}
        id={id}
        isUserIn={isUserIn}
        listed={listed}
        {...props}
      />
      <Description {...props} />
    </Box>
  );
};

export default InfoContainer;

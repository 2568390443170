import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DateFormat } from "utils/transformations";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import styles from "./index.module.css";

const Board = (props) => {
  const { id, image, title, description, createdAt, createdBy } = props;

  const history = useHistory();

  const matches = useMediaQuery("(min-width: 650px)");

  return (
    <Card
      raised
      className={styles.card}
      onClick={() => history.push(`/science-plus/${id}/view`)}
      component={motion.div}
      whileTap={{ scale: 0.96 }}
      sx={{
        backgroundColor: "rgb(240, 244, 244)",
        borderRadius: "1rem",
        boxShadow: "none",
      }}
    >
      <Box display="flex" flexDirection={matches ? "row" : "column"}>
        <CardMedia
          component="img"
          image={image}
          alt={"Blog post thumbnail"}
          style={{
            width: matches ? "272px" : "100%",
            height: "141px",
          }}
        />
        <CardContent style={{ flex: 1, paddingTop: 0, paddingBottom: 0 }}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
              <Typography variant="p">{description}</Typography>
            </Box>
            <Box
              display="inline-flex"
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="body2">{DateFormat(createdAt)}</Typography>
              <Typography align="right" variant="body2">
                postado por <b>{createdBy}</b>
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default Board;

import { Box, Fab, IconButton, Tooltip, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Post } from "utils/request";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ListButtonIcon from "./ListButtonIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Show } from "actions/SnackbarActions";
import { useContext } from "react";
import { CardContext } from "../..";

const Actions = (props) => {
  const {
    id,
    isUserIn,
    listed,
    available,
    usercourse,
    first_file_id: firstFileId,
    lastSeenLesson,
    onUpdate = () => {},
    ...others
  } = props;

  const history = useHistory();
  const userId = useSelector((store) => store.AppReducer.user.id);
  const cardContext = useContext(CardContext);
  const theme = useTheme();
  const dispatch = useDispatch();

  const { mutate: addMutate } = useMutation(
    async () => {
      const form = new FormData();
      form.append("course_id", id);
      form.append("user_id", userId);

      return await Post("user-list-course/create", form);
    },
    {
      onSuccess: (data) => {
        onUpdate();
        dispatch(
          Show({
            show: true,
            message: data?.message,
            severity: "success",
          })
        );
      },
      onError: () =>
        dispatch(
          Show({
            show: true,
            message: "Falha ao remover o curso da lista",
            severity: "error",
          })
        ),
    }
  );

  const { mutate: removeMutate } = useMutation(
    async () => {
      const form = new FormData();
      form.append("course_id", id);
      form.append("user_id", userId);

      return await Post("user-list-course/delete", form);
    },
    {
      onSuccess: (data) => {
        onUpdate();
        dispatch(
          Show({
            show: true,
            message: data?.message,
            severity: "success",
          })
        );
      },
      onError: () =>
        dispatch(
          Show({
            show: true,
            message: "Falha ao remover o curso da lista",
            severity: "error",
          })
        ),
    }
  );

  const HandlePlayClick = (e) => {
    e.stopPropagation();
    if (usercourse !== null) {
      if (available) {
        if (lastSeenLesson)
          history.push(`/courses/${id}/${lastSeenLesson?.file_id}`);
        else if (firstFileId) history.push(`/courses/${id}/${firstFileId}`);
        else history.push(`/courses/${id}`);
      } else {
        // history.push(`/courses/home/${id}`);
        // jogar para player
        // history.push(`/courses/adquire/${id}`);
      }
    } else {
      history.push(`/courses/adquire/${id}`);
    }
  };
  const HandleListClick = (e) => {
    e.stopPropagation();
    listed ? removeMutate() : addMutate();
  };

  return (
    <Box
      display={"flex"}
      width={"100%"}
      padding={2}
      justifyContent={"space-between"}
    >
      <Box>
        <Fab
          variant="extended"
          //   onClick={HandlePlayClick}
          style={{
            color: "black",
            // width: 50,
            height: 50,
            marginRight: 10,
          }}
        >
          <PlayArrowIcon
            style={{
              marginRight: theme.spacing(1),
            }}
          />
          Assistir
        </Fab>
        <Tooltip
          title={listed ? "Remover da Minha lista" : "Adicionar à Minha lista"}
          placement={"top"}
          arrow
        >
          <IconButton
            variant={motion.button}
            // onClick={HandleListClick}
            style={{
              width: 50,
              height: 50,
              color: "white",
              border: "1px solid white",
            }}
          >
            <ListButtonIcon listed={listed} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        {/* <Tooltip
                    title={"Módulos, aulas e arquivos"}
                    placement={"top"}
                    arrow
                >
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            cardContext?.OpenExpandableModal()
                        }}
                        style={{
                            color: "white",
                            border: "1px solid white"
                        }}
                    >
                        <ExpandMoreIcon/>
                    </IconButton>
                </Tooltip> */}
      </Box>
    </Box>
  );
};

export default Actions;

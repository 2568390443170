import { Box, Typography } from "@mui/material";
import { paragraphs } from "./data";

const DEFAULT_VARIANT = "body1";
const DEFAULT_TEXT_INDENT = "60px";

const Intro = ({ text }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box dangerouslySetInnerHTML={{ __html: text }}></Box>
    </Box>
  );
};

export default Intro;

import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Get } from "utils/request";
// import Section100vh from "../../../components/Section100vh";

// owl carousel
import React from "react";
import OwlCarousel from "react-owl-carousel";

import TestimonyCard from "./TestimonyCard";

const Testimonials = () => {
  const [testimonies, setTestimonies] = useState([]);
  const [testimonials_title, setTestimonials_title] = useState("");

  const { data, isLoading, refetch } = useQuery(
    "testimonials",
    async () => await Get(`site-rt/testimonial`),
    {
      onSuccess: (data) => {
        const { testimonials } = data || {};
        setTestimonies(testimonials);
      },
    }
  );

  const landing_description_data = useQuery("landingDescription", () =>
    Get("site-rt/description?type=firstContent")
  );

  useEffect(() => {
    if (landing_description_data) {
      setTestimonials_title(
        landing_description_data?.data?.page?.testimonial_section_title
      );
    }
  }, [landing_description_data]);

  const responsive = {
    200: {
      items: 1,
    },
    500: {
      items: 1,
    },
    800: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  };

  return (
    <div style={{ height: "max-content" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1.4rem",
          margin: "auto",
          padding: "8rem 0",
        }}
      >
        <Typography
          variant="h3"
          component="div"
          sx={{
            alignSelf: "center",
          }}
          dangerouslySetInnerHTML={{
            __html: testimonials_title || "Depoimentos",
          }}
        />

        <OwlCarousel
          className="owl-theme"
          loop
          items={5}
          center
          autoplay={true}
          autoplayTimeout={5000}
          autoplayHoverPause
          responsive={responsive}
          margin={20}
          dots={false}
        >
          {testimonies.map((testimony, index) => (
            <TestimonyCard key={index} testimony={testimony} />
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Testimonials;

import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Get } from "utils/request";
import { STORAGE_URL } from "variables";
import { useQuery } from "react-query";
import styles from "./index.module.css";
import { Typography } from "@mui/material";

const Banner = ({ banner, setBanner, transition_time }) => {
  // const { data } = useQuery(
  //   "landingBanners",
  //   async () => await Get(`site-rt/bannerPrincipal-images`),
  //   {
  //     refetchOnWindowFocus: false,
  //     retry: 0,
  //   }
  // );

  // useEffect(() => {
  //   let temp = [];
  //   data?.banners?.forEach((banner) => {
  //     temp.push({
  //       id: banner.id,
  //       image_url: banner.image,
  //       file: null,
  //     });
  //   });
  //   setBanner(temp);
  // }, [data]);

  const checkIfisLocalImage = (image_url) => {
    if (image_url.includes('data:image')) {
      return image_url;
    } else {
      return `${STORAGE_URL}${image_url}`;
    }
  };

  return (
    banner !== undefined && (
      <div>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Banner (seção 1)
        </Typography>
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          renderIndicator={false}
          centerMode={false}
          showThumbs={false}
          transitionTime={1000}
          interval={transition_time}
          // maxWidth=""
          showStatus={false}
        >
          {banner.map((banner, index) => (
            <a key={index} href={banner.link} target="_blank">
              <div
                style={{
                  width: "100%",
                  // aspectRatio: "10/3",
                  // aspectRatio: "21/6",
                  height: "50vh",
                  backgroundSize: "contain",
                  backgroundImage: `url(${checkIfisLocalImage(banner.image_url)})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: banner?.bgcolor ?? "#fff",
                }}
              />
            </a>
          ))}
        </Carousel>
      </div>
    )
  );
};
export default Banner;

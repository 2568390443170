import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, DialogActions, useTheme } from "@mui/material";

const ModalAlert = props => {

    const {
        children,
        open = true,
        title = "",
        confirmBtnText = "Confirmar",
        confirmBtnStyle = {},
        showCancelBtn = false,
        cancelBtnText = "Cancelar",
        cancelBtnStyle = {},
        onClose = () => {},
        onCancel = () => {},
        onConfirm = () => {}
    } = props;
    
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={() => {
                        onCancel();
                        onClose();
                    }} 
                    style={{
                        color: theme.palette.default.info
                    }}
                    hidden={!showCancelBtn}
                >
                    {cancelBtnText}
                </Button>
                <Button
                    onClick={() => {
                        onConfirm();
                        onClose();
                    }}
                    style={{
                        color: theme.palette.default.error
                    }} 
                >
                    {confirmBtnText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ModalAlert;
import { useEffect, useState } from "react"
import { Get } from "utils/request";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import DefaultButton from 'components/DefaultButton';
const FormBody = props => {
  
  const {
    OnConfirm,
    loading
  } = props;

  const [description, setDescription] = useState('')
  const [title, setTitle] = useState('')
  const [sub, setSub] = useState('');
  const type = "firstContent";
  const GetData = async ()=>{
    let response = await Get(`site-rt/description?type=${type}`);
    if(response?.status === true){
      setTitle(response?.page?.title)
      setDescription(response?.page?.description)
      setSub(response?.page?.sub);
    }
  }
  useEffect(()=> GetData(),[])
  return(
    <div className="form-course">
      <form>
        <FormLabel>Título
        
        </FormLabel>
        <Input
          placeholder="Título"
          value={title}
          onChange={e => setTitle(e.target.value)}
          required
        />
        <br/>
        <FormLabel>Conteúdo da primeira seção<span style={{color: "red"}}>*</span></FormLabel>
        <Input
          placeholder="Conteúdo"
          value={description}
          onChange={e => setDescription(e.target.value)}
          as="textarea"
          required
        />
        <br/>
        <FormLabel>Texto secundário
        
        </FormLabel>
        <Input
          placeholder="Texto secundário"
          value={sub}
          onChange={e => setSub(e.target.value)}
          required
        />
      </form>
      <br/>
      <div className="w100 inline-flex jsend mt-2">
                <DefaultButton
                    bg="confirm"
                    text="Salvar alterações"
                    onClick={() => {
                        let data = {            
                            title: title,
                            description: description,
                            sub: sub,
                            type: type,
                            
                        };
                        OnConfirm(data);
                    }}
                    loading={loading}
                />
            </div>
    </div>
  )
}
export default FormBody
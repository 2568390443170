import { Box, Card, createTheme, Typography } from "@mui/material";
import { useMemo, useState } from "react";

import styles from "./index.module.css";
import ThreeDots from "./ThreeDots";

const Board = (props) => {
  const [hover, setHover] = useState(false);

  const {
    icon,
    color,
    links,
    content: { title, text, quantity } = {},
    link: { url, linkName } = {},
    ...others
  } = props;

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: color["500"],
          },
        },
      }),
    [color]
  );

  return (
    <Box
      component={Card}
      maxWidth={"50%"}
      minWidth={"20em"}
      flex={1}
      margin={1}
      padding={2}
      //   bgcolor={theme.palette?.primary?.main}
      //   color={theme.palette?.primary?.contrastText}
      sx={{
        borderRadius: "0.6rem!important",
        overflow: "hidden",
        color: theme.palette?.primary?.contrastText,
        backgroundColor: theme.palette?.primary?.main,
        boxShadow: "none",
        transition: "200ms ease",
        opacity: hover ? 1 : 0.8,
      }}
      overflow="hidden"
      position={"relative"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box
        width="100%"
        display="inline-flex"
        flexDirection="row"
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <Box
          width={"fit-content"}
          padding={1}
          borderRadius={5}
          marginBottom={1.5}
          bgcolor={theme.palette?.primary?.dark}
        >
          {icon}
        </Box>
        {links.length !== 0 && (
          <ThreeDots theme={theme} links={links} hover={hover} />
        )}
      </Box>
      <Box component={Typography} variant={"h5"} fontWeight={900}>
        {quantity}
      </Box>
      <Box
        component={Typography}
        variant={"h6"}
        color={color["200"]}
        fontWeight={900}
      >
        {title}
      </Box>
      <Box
        position="absolute"
        bgcolor={color["700"]}
        width={210}
        height={210}
        right={5}
        top={-150}
        borderRadius={"100%"}
        sx={{
          transform: hover ? "translate(-8%, 12%)" : "translate(0, 0)",
          transition: "all 500ms cubic-bezier(0,.23,.12,1.86)",
        }}
      />
      <Box
        position="absolute"
        bgcolor={theme.palette?.primary?.dark}
        width={210}
        height={210}
        right={-100}
        top={-100}
        borderRadius={"100%"}
        sx={{
          transform: hover ? "translate(-8%, 20%)" : "translate(0, 0)",
          transition: "all 700ms cubic-bezier(0,.23,.12,1.86)",
        }}
      />
    </Box>
  );
};

export default Board;

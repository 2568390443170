import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  useTheme,
} from "@mui/material";

import styles from "./index.module.css";

const CoursesAd = (props) => {
  const section2_cards = props.cards;
  const { title, title_color } = props;
  const { ...others } = props;

  return (
    <Container maxWidth="xl" sx={{ position: "relative" }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: "black",
          position: "absolute",
          top: "2rem",
          width: "100%",
        }}
      >
        (seção 2)
      </Typography>
      <Box className={styles.container} {...others}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{
            fontWeight: "bold",
            // marginBottom: '2rem'
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Box
          className={styles.cardsGroup}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {section2_cards &&
            section2_cards.map((courseAd, index) => (
              <Card
                key={index}
                style={{
                  background: courseAd?.bg_color,
                  color: courseAd?.font_color,
                  padding: "2rem",
                  flexBasis: "20rem",
                  boxShadow: "none",
                  borderRadius: "0.8rem",
                }}
                raised
              >
                <CardContent className={styles.cardContent}>
                  {/* {courseAd?.icon} */}
                  <Typography
                    variant="h5"
                    align="center"
                    className={styles.cardsTitle}
                    sx={{
                      color: courseAd?.font_color,
                    }}
                  >
                    {courseAd?.title}
                  </Typography>
                  <Typography align="center">{courseAd?.text}</Typography>
                </CardContent>
              </Card>
            ))}
        </Box>
      </Box>
    </Container>
  );
};

export default CoursesAd;

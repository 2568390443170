import { lazy, Suspense, useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useParams, useHistory } from "react-router";
import { Get } from "utils/request";
import { Secs2Minutes } from "utils/transformations";

import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import Video from "components/Video";
import Tabs from "./Tabs";
import { STORAGE_URL } from "variables";
import { Skeleton } from "@mui/material";
import { useQuery } from "react-query";
import Evaluation from "./Evaluation";
import useHomeCourse from "./useHomeCourse";
import { Container } from "@mui/system";

import style from "./style.module.css";
import CourseContent from "./Tabs/CourseContent";
import Reviews from "modules/Courses/Home/Reviews";
import RecommendedTo from "./RecommendedTo";
import Footer from "Home/Footer";

const RecommendedToTEMP = [
  {
    user_image: "https://i.imgur.com/aocxWqz.gif",
    text: "Gatos que fazem pizza. Pizza é uma delicia.",
  },
  {
    user_image: "https://i.imgur.com/QCPb5nn.png",
    text: "I am a very simple card. I am good at containing small bits of information.",
  },
  {
    user_image:
      "https://i.imgur.com/Qm7G1Vo_d.webp?maxwidth=760&fidelity=grand",
    text: "I am a very simple card. I am good at containing small bits of information.",
  },
  {
    user_image:
      "https://i.imgur.com/tII66Gc_d.webp?maxwidth=760&fidelity=grand",
    text: "I am a very simple card. I am good at containing small bits of information.",
  },
];

const HomeCourse = () => {
  const { course_id } = useParams();
  const history = useHistory();

  const { course, isLoading } = useHomeCourse();

  return (
    !isLoading && (
      <>
        <Box>
          {/* section 1 */}
          <section
            style={{
              background:
                "linear-gradient(180deg, rgba(43,88,118,1) 0%, rgba(78,67,118,1) 100%)",
              minHeight: "100vh",
              padding: "6rem 0",
              position: "relative",
            }}
          >
            {/* ghost image cover */}
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 1,
                opacity: "0.5",
                top: "0",
                overflow: "hidden",
              }}
            >
              <img
                className={style.ghostImage}
                src={STORAGE_URL + course?.cover_image}
              />
            </div>

            <Container
              maxWidth="xl"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                zIndex: 10,
                position: "relative",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  backdropFilter: "blur(1.4rem)",
                  padding: "0.1rem 1rem",
                  borderRadius: "0.8rem",
                  textAlign: "center",
                }}
              >
                {course?.name}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "rgb(220, 220, 220)",
                  textAlign: "justify",
                  backdropFilter: "blur(1.4rem)",
                  padding: "0.1rem 1rem",
                  borderRadius: "0.8rem",
                }}
              >
                Mais do que um curso: seu novo propósito. uma experiência de
                aprendizado.
              </Typography>

              <Box
                sx={{
                  marginTop: "4rem",
                  width: {
                    md: "100%",
                    lg: "80%",
                    background:
                      "linear-gradient(212deg, rgb(31, 162, 255) 0%, rgb(18, 216, 250) 50%, rgb(166, 255, 203) 100%)",
                    padding: "0.2rem",
                    borderRadius: '0.89rem'
                  },
                }}
              >
                {course?.video_trailer && course?.video_trailer != "undefined" && (
                  <Video
                    containerProps={{
                      style: {
                        borderRadius: "0.8rem",
                        width: "100%",
                      },
                    }}
                    src={
                      course?.video_trailer
                        ? STORAGE_URL + course?.video_trailer
                        : null
                    }
                  />
                )}
                {course?.video_trailer == null && (
                  <img
                    style={{
                      borderRadius: "0.8rem",
                      width: "100%",
                    }}
                    src={STORAGE_URL + course?.image}
                  />
                )}
              </Box>

              <div style={{ marginTop: "4rem" }}>
                {course?.avaiable ? (
                  <button
                    className={style.getCourseButton}
                    onClick={() =>
                      history.push(
                        `/courses/${course_id}/${course?.goToFileId || ""}`
                      )
                    }
                  >
                    SIM! Eu quero [BENEFÍCIO/DESEJO]
                  </button>
                ) : (
                  <button
                    style={{
                      padding: "1rem 4rem",
                      borderRadius: "0.8rem",
                      outline: "none",
                      border: "none",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "rgb(55, 55, 55)",
                    }}
                    disabled
                  >
                    CURSO INDISPONÍVEL :(
                  </button>
                )}
              </div>
            </Container>
          </section>

          {/* section 2 */}
          <section
            style={{
              padding: "6rem 0",
              background:
                "linear-gradient(180deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 100%)",
              minHeight: "100vh",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Container
              maxWidth="lg"
              sx={{ height: "100%!important", textAlign: "center" }}
            >
              <Typography
                variant="h2"
                sx={{ fontWeight: "bold", marginBottom: "1rem" }}
              >
                O que você vai aprender
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: "3rem" }}
              >
                Veja os benefícios que você irá ter
              </Typography>

              {/* 
            <Typography variant="subtitle1" sx={{ textAlign: "justify", fontSize: '1rem', marginBottom: '0.6rem' }}>
              {course?.description}
            </Typography> */}

              <div
                dangerouslySetInnerHTML={{ __html: course?.what_will_learn }}
                style={{ textAlign: "justify" }}
              />
            </Container>
          </section>

          {/* section 3 */}
          <section
            style={{
              background:
                "linear-gradient(180deg, rgba(43,88,118,1) 0%, rgba(78,67,118,1) 100%)",
              padding: "6rem 0",
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: "4rem",
              }}
            >
              <Typography
                variant="h2"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                Professores
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "4rem",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {course?.responsibles.map((responsible, index) => (
                  <div
                    style={{
                      flexBasis: "max-content",
                      backgroundColor: "rgba(255,255,255,0.08)",
                      boxShadow: "inset 0 0 4rem rgba(255,255,255,0.2)",
                      border: "0.1rem solid rgba(255,255,255,0.25)",
                      display: "flex",
                      flexDirection: "column",
                      padding: "2.6rem 4rem",
                      borderRadius: "0.8rem",
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "10rem",
                        height: "10rem",
                        padding: "0.2rem",
                        borderRadius: "50%",
                        overflow: "hidden",
                        background:
                          "linear-gradient(212deg, rgb(31, 162, 255) 0%, rgb(18, 216, 250) 50%, rgb(166, 255, 203) 100%)",
                      }}
                    >
                      <img
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          borderRadius: "50%",
                          height: "100%",
                        }}
                        src={STORAGE_URL + responsible?.profile_image}
                        alt={responsible.name}
                      />
                    </div>

                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        marginBottom: "1rem",
                        textAlign: "center",
                        marginTop: "2rem",
                        color: "white",
                      }}
                    >
                      {responsible?.name}
                    </Typography>
                  </div>
                ))}
              </div>
            </Container>
          </section>

          {/* section 4 */}
          <section
            style={{
              padding: "6rem 0",
              background:
                "linear-gradient(180deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 100%)",
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h2"
                sx={{ fontWeight: "bold", marginBottom: "1rem" }}
              >
                O que você encontrará
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: "3rem" }}
              >
                Principais módulos e bônus
              </Typography>

              <div style={{ width: "100%" }}>
                <CourseContent modules={course?.modules} />
              </div>
            </Container>
          </section>

          {/* section 5 */}
          <section
            style={{
              background:
                "linear-gradient(180deg, rgba(43,88,118,1) 0%, rgba(78,67,118,1) 100%)",
              minHeight: "100vh",
              padding: "6rem 0",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "4rem",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  color: "white",
                }}
              >
                Para quem o curso é indicado
              </Typography>

              {/* teste [foco em criar uma interface molde] */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {RecommendedToTEMP.map((data, index) => (
                  <RecommendedTo key={index} {...data} />
                ))}
              </div>

              {/* teste [foco em criar uma interface molde] */}
            </Container>
          </section>

          {/* section 6 */}
          <section
            style={{
              padding: "6rem 0",
              background:
                "linear-gradient(180deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 100%)",
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  textAlign: "center",
                }}
              >
                Veja o que nossos alunos falam sobre o curso
              </Typography>

              <div style={{ maxWidth: "60rem", width: "100%" }}>
                <Reviews />
              </div>
            </Container>
          </section>
        </Box>
        <Footer />
      </>

      // <Box>
      //   {course?.cover_image && course?.cover_image != "null" && (
      //     <div>
      //       <Box
      //         width="100%"
      //         style={{
      //           backgroundImage: `url("${STORAGE_URL + course?.cover_image}")`,
      //           backgroundSize: "cover",
      //           backgroundPosition: "center",
      //           aspectRatio: "16/3",
      //         }}
      //       >
      //         {isLoading && (
      //           <Skeleton
      //             style={{
      //               height: "100%",
      //               transform: "scale(1)",
      //             }}
      //           />
      //         )}
      //       </Box>
      //     </div>
      //   )}
      //   <div style={{ backgroundColor: "#e9eaf2", padding: "2rem 0" }}>
      //     <Container maxWidth="xl">
      //       <Box
      //         display="flex"
      //         alignItems="stretch"
      //         width="100%"
      //         gap='2rem'
      //         sx={{
      //           flexDirection: {xs: "column", md: "row"},
      //         }}
      //       >
      //         <Box
      //           flex={1}
      //           display="flex"
      //           flexDirection="column"
      //           justifyContent="space-between"
      //           style={{ flexGrow: 1, width: "100%",gap: '2rem' }}
      //         >
      //           <Box flex={1} sx={{gap: '0.4rem', display: 'flex', flexDirection: 'column'}}>
      //             <Typography
      //               variant="h4"
      //               sx={{ fontWeight: "bold" }}
      //               gutterBottom
      //             >
      //               {course?.name}
      //             </Typography>
      //             <Typography>
      //               {isLoading ? (
      //                 <Skeleton />
      //               ) : (
      //                 <div
      //                   style={{
      //                     textAlign: "justify",
      //                     maxHeight: 210,
      //                     textOverflow: "ellipsis",
      //                     overflow: "hidden",
      //                   }}
      //                 >
      //                   <p
      //                     style={{
      //                       whiteSpace: "pre-line",
      //                       fontSize: 15,
      //                       fontWeight: "normal",
      //                     }}
      //                   >
      //                     {course?.description}
      //                   </p>
      //                 </div>
      //               )}
      //             </Typography>
      //             <Typography variant="body2">
      //               {isLoading ? (
      //                 <Skeleton />
      //               ) : (
      //                 <>
      //                   <OndemandVideoIcon />
      //                   &nbsp;{Secs2Minutes(course?.duration)}{" "}
      //                   {course?.duration > 120 ? "minutos" : "minuto"} de
      //                   conteúdo de vídeo
      //                 </>
      //               )}
      //             </Typography>
      //             <Evaluation />
      //             {course?.available ? (
      //               <Button
      //                 variant="contained"
      //                 color="secondary"
      //                 sx={{width: 'max-content', marginTop: '0.6rem'}}
      //                 onClick={() =>
      //                   history.push(
      //                     `/courses/${course_id}/${course?.goToFileId || ""}`
      //                   )
      //                 }
      //               >
      //                 Ir para o curso
      //               </Button>
      //             ) : (
      //               <Button
      //                 variant="contained"
      //                 color="secondary"
      //                 sx={{width: 'max-content', marginTop: '0.6rem'}}
      //                 disabled
      //               >
      //                 Curso não disponível
      //               </Button>
      //             )}
      //           </Box>
      //         </Box>
      //         <div style={{margin: '0 auto'}}>
      //           {course?.video_trailer && course?.video_trailer != "undefined" && (
      //             <Video
      //               containerProps={{
      //                 style: {
      //                   maxHeight: 300,
      //                   borderRadius: '0.8rem'
      //                 },
      //               }}
      //               src={
      //                 course?.video_trailer
      //                   ? STORAGE_URL + course?.video_trailer
      //                   : null
      //               }
      //             />
      //           )}
      //           {course?.video_trailer == null && (
      //             <img
      //               style={{
      //                 // flex: 1,
      //                 maxHeight: 300,
      //                 borderRadius: '0.8rem',
      //                 width: '100%',
      //               }}
      //               src={STORAGE_URL + course?.image}
      //             />
      //           )}
      //         </div>
      //       </Box>
      //     </Container>
      //   </div>
      //   <Box>
      //     <Container maxWidth="xl">
      //       <div style={{maxWidth:"960px"}}>
      //         <Tabs />
      //       </div>
      //     </Container>
      //   </Box>
      // </Box>
    )
  );
};

export default HomeCourse;

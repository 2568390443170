import { Box } from "@mui/material";
import { Skeleton } from "@mui/material";
import logo from "assets/images/rt/icon_transparent.png";

const SkeletonCardList = props => {

  const {
    listCount = 2,
    cardsCount = 5,
    ...others
  } = props;

  return (
    <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          width="100"
          height="100"
          className="infiniteRotateAnim"
        />
        <span>Carregando cursos...</span>
      </div>
  );
}

export default SkeletonCardList;
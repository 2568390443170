import { alpha, Checkbox, IconButton, TableCell, TableRow, useTheme } from "@mui/material";
import { Create } from "@mui/icons-material";
import { useState } from "react";
import { Secs2Time } from "utils/transformations";
import EditModal from "./EditModal";

const Board = props => {

    const {
        id,
        time,
        message,
        onSelect = () => {},
        onUnselect = () => {},
        onUpdate = () => {},
        onChangeTime = () => {}
    } = props;

    const [checked, SetChecked] = useState(false);
    const [modal, SetModal] = useState(false);

    const theme = useTheme();

    const HandleSelection = e => {
        SetChecked(e.target.checked);
        e.target.checked ? onSelect(id) : onUnselect(id);
    }

    return (
        <TableRow
            style={{
                backgroundColor: checked ? alpha(theme.palette.secondary.light, 0.08) : "inherit"
            }}
        >
            <TableCell>
                <Checkbox
                    checked={checked}
                    onChange={HandleSelection}
                />
            </TableCell>
            <TableCell>
                <a
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth"
                        });
                        onChangeTime(time);
                    }}
                    style={{
                        cursor: "pointer"
                    }}
                >
                    {Secs2Time(time)}
                </a>
            </TableCell>
            <TableCell>{message}</TableCell>
            <TableCell>
                <IconButton
                    onClick={() => SetModal(true)}
                    style={{ color: theme.palette.info.main }}
                >
                    <Create/>
                </IconButton>
                <EditModal
                    open={modal}
                    initialMessage={message}
                    id={id}
                    onModalClose={() => SetModal(false)}
                    onSubmit={onUpdate}
                />
            </TableCell>
        </TableRow>
    );
}

export default Board;
import { Box } from "@mui/material";
import { Header } from "modules/Header";
import { useMainPages } from "modules/Header/data";
import Banner from "./Banner";
import Bottom from "./Bottom";
import Intro from "./Intro";
import Middle from "./Middle";
import Footer from "Home/Footer";
import { Container } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Get } from "utils/request";
import WhatsappButton from "components/WhatsappButton";

const IntegrativeHealth = () => {
  const pages = useMainPages();

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [text, setText] = useState("");
  const [background_image, setBackground_image] = useState(null);
  const [cards, setCards] = useState([]);

  const { data } = useQuery(
    "saudeIntegrativaPage",
    async () => await Get(`site-rt/saude-integrativa-page`)
  );

  useEffect(() => {
    setTitle(data?.saudeIntegrativaPage?.title);
    setSubtitle(data?.saudeIntegrativaPage?.subtitle);
    setText(data?.saudeIntegrativaPage?.text);
    setBackground_image(data?.saudeIntegrativaPage?.background_image);
    setCards(data?.saudeIntegrativaPage?.cards);
  }, [data]);

  return (
    <Box>
      <Header pages={pages} />
      <Banner
        {...{title, subtitle, background_image}}
      />
      <Container maxWidth="xl" sx={{marginTop: '4rem', gap: '4rem'}}>
        <Intro {...{text}} />
        {/* <Middle /> */}
        <Bottom {...{cards}} />
      </Container>
      <WhatsappButton />
      <Footer />
    </Box>
  );
};

export default IntegrativeHealth;

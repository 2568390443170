import React, { useEffect, useState } from "react";
import { Get, Post } from "../../../utils/request";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { Show } from "../../../actions/SnackbarActions";
import { Pagination } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Reorder, AnimatePresence } from "framer-motion";

import Board from "./Board";
import SaveOrderButton from "modules/SaveOrderButton";

const Table = (props) => {
  const { search = "" } = props;

  const [[categories, tempCategories], SetCategories] = useState([[], []]);
  const [page, SetPage] = useState(1);

  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useQuery(
    ["courses", search, page],
    async () =>
      await Get(`courses-main/free-courses?search=${search}&page=${page}`),
    {
      onSuccess: (data) => {
        const { courses } = data || {};
        SetCategories([courses, courses]);
      },
    }
  );
  const { pagination: { last_page: maxPage = 1, perPage } = {} } = data || {};

  const orderMutation = useMutation(
    async () => {
      const form = new FormData();
      form.append("perPage", perPage);
      form.append("currPage", page);
      for (let i = 0; i < tempCategories?.length; i++)
        form.append("freeCoursesIds[]", tempCategories[i]?.id);

      return await Post("courses-main/free-courses/order", form);
    },
    {
      onSuccess: (data) => {
        const { message } = data || {};
        dispatch(
          Show({
            show: true,
            message: message,
            severity: "success",
          })
        );
      },
    }
  );

  return (
    <div>
      <div className="table-responsive">
        <Reorder.Group
          as={"table"}
          className="table table-striped"
          axis={"y"}
          values={tempCategories}
          onReorder={(value) => SetCategories([categories, value])}
          style={{
            borderColor: "transparent",
            overflow: "hidden",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "3em",
                }}
              />
              <th>Nome</th>
              <th>Link</th>
              <th>Descrição</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              tempCategories?.map((value, index) => (
                <Board
                  draggable={search == ""}
                  value={value}
                  key={value?.id}
                  id={value?.id}
                  name={value?.name}
                  link={value?.link}
                  description={value?.description}
                  OnDelete={refetch}
                />
              ))}
          </tbody>
        </Reorder.Group>
      </div>
      <div className="w100 flex jcc">
        <CircularProgress hidden={!isLoading} />
        <p hidden={categories?.length !== 0 || isLoading}>
          Nenhum curso foi achado
        </p>
      </div>
      <Pagination
        className={maxPage == 1 ? "hide" : ""}
        style={{ display: "flex", justifyContent: "flex-end" }}
        count={maxPage}
        value={page}
        onChange={(_, value) => SetPage(value)}
      />
      <AnimatePresence>
        {JSON.stringify(categories) != JSON.stringify(tempCategories) && (
          <SaveOrderButton
            onClick={() => {
              orderMutation.mutate();
              SetCategories([tempCategories, tempCategories]);
            }}
            onCancel={() => SetCategories([categories, categories])}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Table;

/**
 *
 * Component of the bottom of the page inviting the user to create an account
 * Used in landing page
 *
 */

import { Box, Container, Typography, useTheme } from "@mui/material";
import Formbody from "./Formbody";

import styles from "./index.module.css";

const BottomLead = (props) => {
  const {
    bottomLeadTitle,
    bottomLeadSubtitle,
    bottomLeadText1,
    bottomLeadText2,
  } = props;

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        padding: "8rem 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        gap: "1rem",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontFamily: "var(--Freight)",
          fontWeight: "bold",
          textAlign: "center",
          color: "black",
          position: "absolute",
          top: "1rem",
          width: '100%'
        }}
      >
        (seção 6)
      </Typography>
      <div className={styles.text}>
        <Typography variant="h4" 
          dangerouslySetInnerHTML={{ __html: bottomLeadTitle }}
        />
        <br />
        <Typography variant="h5" 
          dangerouslySetInnerHTML={{ __html: bottomLeadSubtitle }}
        />
        <Typography variant="body1"
          dangerouslySetInnerHTML={{ __html: bottomLeadText1 }}
        />
      </div>
      <Formbody {...{bottomLeadText2}} />
    </Container>
  );
};

export default BottomLead;

import { Button } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';

import styles from "./index.module.css";

const ReloadButton = props => {

    const {
        children,
        ...other
    } = props;

    return <Button {...other} className={styles.button}><ReplayIcon className={styles.icon}/> {children || "Recarregar"}</Button>
}

export default ReloadButton;
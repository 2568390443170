import { Box, Button, CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { green } from "@mui/material/colors";

import MultiTabBox from "components/Forms/MultiTabBox";
import OthersData from "./OthersData";
import ContactData from "./ContactData";
import ColorsData from "./ColorsData";
import useFormbody from "./useFormbody";

const buttonTheme = createTheme({
    palette: {
        primary: {
            main: green[800]
        }
    }
});

const FormBody = props => {

    const {
        register,
        onSubmit,
        watch,
        control,
        isLoading
    } = useFormbody(props);

    return (
        <Box
            component={"form"}
            onSubmit={onSubmit}
        >
            {!isLoading ? (
                <>
                    <MultiTabBox
                        width={`100%`}
                        padding={3}
                    >
                        <OthersData
                            label={"Dados gerais"}
                            register={register}
                            control={control}
                        />
                        <ContactData
                            label={"Dados de contato"}
                            register={register}
                        />
                        <ColorsData
                            label={"Cores do sistema"}
                            register={register}
                            watch={watch}
                            control={control}
                        />
                    </MultiTabBox>
                    <ThemeProvider
                        theme={buttonTheme}
                    >
                        <Button
                            variant={"contained"}
                            type={"submit"}
                        >
                            Salvar alterações
                        </Button>
                    </ThemeProvider>
                </>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 10
                    }}
                >
                    <CircularProgress/>
                </Box>
            )}
        </Box>
    );
}

export default FormBody;
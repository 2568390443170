import { Box, Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

const Image = props => {

    const {
        src,
        children,
        style = {},
        width = 0,
        height = "auto",
        zoomable = false,
        ...others
    } = props;

    const [modal, SetModal] = useState(false);

    const containerRef = useRef();
    const modalBoxRef = useRef();

    const OpenModal = () => SetModal(true);
    const CloseModal = () => SetModal(false);

    const GenerateImage = () => {
        if (!containerRef?.current) return;
        if (src instanceof File) {
            const image = src;
            if (image && FileReader) {
                const filereader = new FileReader();
                filereader.onload = () => {
                    containerRef.current.style.backgroundImage = `url(${filereader.result})`;
                    if (modalBoxRef?.current) modalBoxRef.current.style.backgroundImage = `url(${filereader.result})`;
                };
                filereader.readAsDataURL(image);
            }
        } else {
            containerRef.current.style.backgroundImage = `url(${src})`;
            if (modalBoxRef?.current) modalBoxRef.current.style.backgroundImage = `url(${src})`;
        }
    }

    useEffect(GenerateImage, [src, containerRef, modalBoxRef?.current]);

    return (
        <>
            <Box
                {...others}
                width={width}
                height={height}
                ref={containerRef}
                onClick={OpenModal}
                style={{
                    cursor: zoomable ? "zoom-in" : "inherit",
                    ...style
                }}
            >
                {children}
            </Box>
            <Modal
                keepMounted
                open={modal && zoomable}
                onClose={CloseModal}
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}
            >
                <Box
                    width={width}
                    height={height}
                    component={motion.div}
                    ref={modalBoxRef}
                    animate={modal ? "open" : "closed"}
                    variants={{
                        closed: {
                            scale: 0
                        },
                        open: {
                            scale: 1.1
                        }
                    }}
                    {...others}
                    style={style}
                />
            </Modal>
        </>
    );
}

export default Image;
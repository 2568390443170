import { Box } from "@mui/material";
import { Get } from "utils/request";
import { useQuery } from "react-query";
import { createContext } from "react";
import RoleBased from "components/Role/RoleBased";

import Cards from "./Cards";
import QuickActions from "./QuickActions";
import UserActivity from "./UserActivity";

export const DashboardContext = createContext();

const Dashboard = () => {
  const { isLoading, data } = useQuery(
    "dashboard",
    async () => await Get("dashboard")
  );

  return (
    <Box>
      {isLoading ? (
        <div
          className="row"
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {" "}
          <div className="col-12 d-flex justify-content-center align-items-center gap-4">
            <div className="spinner-border text-primary" role="status"></div>
            <h4>Carregando informações..</h4>
          </div>
        </div>
      ) : (
        <DashboardContext.Provider
          value={{
            isLoading,
            ...data,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem",
            }}
          >
            <Cards />
            <QuickActions />
            <RoleBased roles={["Admin", "Colaborador"]}>
              <UserActivity />
            </RoleBased>
          </div>
        </DashboardContext.Provider>
      )}
    </Box>
  );
};

export default Dashboard;

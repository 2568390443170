import React, { useEffect, useRef, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";
import DefaultButton from "components/DefaultButton";
import { Tooltip } from "@mui/material";
import { maskNumber } from "Auxiliar/Masks";

const FormCard = props => {

    const {
        onChange,
        initialCards
    } = props;

    const [link, SetLink] = useState("");
    const [cardList, SetCardList] = useState([...initialCards]);

    const firstInputRef = useRef(null);

    const HandleCardAdd = () => {
        if (!link) {
            firstInputRef.current.focus();
            return;
        }

        let tempCardList = cardList;
        let cardData = {
            link: link
        };
        tempCardList.push(cardData);

        SetCardList([...tempCardList]);
        SetLink("");
        onChange(tempCardList);
    }

    const HandleCardRemove = index => {
        let tempCardList = cardList;

        tempCardList.splice(index, 1);
        SetCardList([...tempCardList]);
        onChange(tempCardList);
    }

    useEffect(() => SetCardList(initialCards), [initialCards]);

    return (
        <div className="card-add-form">
            <FormLabel>Seção para adicionar links</FormLabel>
            <Input
                placeholder="Link"
                value={link}
                onChange={e => SetLink(e.target.value)}
                as="textarea"
                ref={firstInputRef}
            />
            <br/>
            
            <DefaultButton
                text="Adicionar link"
                style={{
                    width: "fit-content"
                }}
                onClick={HandleCardAdd}
            />
            
            <div className="table-responsive" hidden={cardList.length == 0}>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Link</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cardList.map((value, index) => (
                            <tr key={index}>
                                <td className="link-truncate" style={{maxWidth: "150px"}}>{value.link}</td>
                                <td>
                                    <DefaultButton
                                        onClick={() => HandleCardRemove(index)}
                                        width="2.2em"
                                        height="2.2em"
                                        padding={0}
                                        number={`Remover card`}
                                        bg="danger"
                                        icon={<MdDelete size={17} color="white"/>}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FormCard;
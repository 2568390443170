import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Get } from "utils/request";
import { STORAGE_URL } from "variables";
import { useQuery } from "react-query";
import styles from "./index.module.css";
import { Box } from "@mui/material";

const Banner = ({description}) => {
  const { data } = useQuery(
    "landingBanners",
    async () => await Get(`site-rt/bannerPrincipal-images`)
  );
  const { banners } = data || [];

  return (
    banners !== undefined && (
      <div>
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          renderIndicator={false}
          centerMode={false}
          showThumbs={false}
          transitionTime={500}
          interval={description?.banner_transition_time ?? 5000}
          // maxWidth=""
          showStatus={false}
        >
          {banners && banners.map((banner, index) => (
            <a key={banner.id} href={banner.link} target="_blank">
              <Box
                sx={{
                  width: "100%",
                  height: {
                    xs: "30vh",
                    sm: "40vh",
                    md: "60vh",
                  },
                  // aspectRatio: "10/3",
                  // aspectRatio: "16/9",
                  backgroundSize: "contain",
                  backgroundImage: `url(${STORAGE_URL + banner.image})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: banner?.bgcolor ?? "#fff",
                }}
              />
            </a>
          ))}
        </Carousel>
      </div>
    )
  );
};
export default Banner;

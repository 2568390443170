import { Button, CircularProgress, IconButton, Pagination, TextField, Typography } from '@mui/material'
import DefaultButton from 'components/DefaultButton'
import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { MdAdd, MdClose, MdSave } from 'react-icons/md'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { useHistory, useParams } from 'react-router-dom'
import { Get, Post } from 'utils/request'
import './styles.css'
import { maskCpf } from 'Auxiliar/Masks'
import { ToastContent, renderToast } from 'utils/Alerts'

const CourseFinancial = () => {
  const [data, setData] = React.useState([])
  const [split, setSplit] = React.useState([])
  const [pendent, setPendent] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [error, setError] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [loadingSave, setLoadingSave] = React.useState(false)
  const [pagination, setPagination] = React.useState({
    totalItems: '', pageNumber: 0, perPage: 10
  })

  const params = useParams()
  const history = useHistory()

  React.useEffect(() => {
    getData()
  }, [pagination.pageNumber, search])

  const getData = async () => {
    setLoading(true)

    const response = await Get(`splits/${params.id}?page=${pagination.pageNumber + 1}&search=${search}`)
    if (response.splits) {
      let pendents = response.recipient_data.recipients.filter(item => response.splits.some(split => split.recipient_id === item.recipient.id))

      setEdit(true)
      setPendent(pendents)
      setSplit(response.splits)
      setPagination({ ...pagination, totalItems: response.recipient_data.pagination.total_pages });
    }

    setData(response.recipient_data.recipients)
    setLoading(false)
  }

  // const renderPaymentMode = (item) => {
  //   switch (item) {
  //     case 'bank_transfer':
  //       return 'Transferência Bancária'
  //     default:
  //       break
  //   }
  // }

  const handleAdd = (item) => {
    setSplit([...split, { recipient_id: item.recipient.id, amount: 0, type: 'percentage' }])
    setPendent([...pendent, item])
  }

  const handleDelete = (id) => {
    const newSplit = split.filter(item => item.recipient_id !== id)
    const newPendent = pendent.filter(item => item.id !== id)
    setSplit(newSplit)
    setPendent(newPendent)
  }

  const handleTotalChange = (value, id) => {
    let splitObj = [...split]
    let total = 0
    splitObj.forEach(item => {
      if (item.recipient_id === id) total = Number(total) + Number(value);
      else total = Number(total) + Number(item.amount)
    })

    if (value >= 0 && value <= 100 && total <= 100) {
      splitObj.map(item => {
        if (item.recipient_id === id) item.amount = value; item.error = false
        return splitObj
      })

      setSplit(splitObj)
    } else {
      splitObj.map(item => {
        if (item.recipient_id === id) item.error = true
        return splitObj
      })
      setSplit(splitObj)
    }
  }

  const handleSave = async () => {
    if (getTotal() !== 100) {
      setError(true)
      return
    }

    setLoadingSave(true)
    const response = await Post('splits/update', JSON.stringify({ splits: split, course_id: params.id }), { "Content-Type": "application/json" })
    console.log('response', response)
    if (response.httpCode === 200) {
      renderToast({ type: 'success', msg: 'Dados salvos com sucesso!' })
      setTimeout(() => history.goBack(), 1000)
    } else {
      renderToast({ type: 'error', msg: 'Falha ao atualizar dados' })
    }

    setLoadingSave(false)
  }

  const getTotal = () => {
    let splitObj = [...split]
    let total = 0
    splitObj.forEach(item => {
      total = Number(total) + Number(item.amount)
    })
    return total
  }

  let timer
  const handleSearch = ({ target }) => {
    clearTimeout(timer)
    timer = setTimeout(() => { setSearch(target.value); setPagination({ ...pagination, pageNumber: 0 }) }, 750)
  }

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="title">Recebedores</h3>
        <p className="text-muted">Aqui são cadastrados os recebedores do curso</p>
        <div className="col-lg-4 col-md-6 col-12 mt-2">
          <TextField
            fullWidth
            label='Pesquisar...'
            onChange={handleSearch}
          />
        </div>
        <hr />
        <table className='table table-striped table-hover'>
          <thead>
            <tr>
              <td>Nome</td>
              <td>Email</td>
              <td>Meio de Pagamento</td>
              <td>Ações</td>
            </tr>
          </thead>
          <tbody>
            {loading
              ?
              <tr>
                <td colSpan={4}>
                  <CircularProgress />
                </td>
              </tr>
              :
              <>
                {data.length > 0 ? data.map((item, index) => {
                  const recipient = pendent ? pendent.filter(item2 => item2.id === item.id)[0] : null

                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>Transferência Bancária</td>
                      <td>
                        <DefaultButton
                          onClick={() => { recipient ? handleDelete(item.id) : handleAdd(item) }}
                          width="2.2em"
                          height="2.2em"
                          padding={0}
                          title={`${recipient ? 'Remover Recebedor' : 'Adicionar Recebedor'}`}
                          bg={`${recipient ? 'danger' : 'primary'}`}
                          // loading={loading}
                          icon={recipient ? <MdClose size={17} color="white" /> : <MdAdd size={17} color="white" />}
                        />
                      </td>
                    </tr>
                  )
                }) :
                  <tr>
                    <td colSpan={4}>
                      <p className="text-muted">Nenhum contribuidor encontrado</p>
                    </td>
                  </tr>}
              </>}
          </tbody>
        </table>
        {console.log('pag', pagination)}
        {pagination.totalItems !== 0 &&
          <div className='d-flex justify-content-end mt-3 mb-5'>
            <Pagination sx={{ webkitFlexWrap: 'nowrap' }} shape="rounded" color='primary' count={Math.ceil(pagination.totalItems / pagination.perPage)} page={pagination.pageNumber + 1} onChange={(e, page) => {
              window.scrollTo(0, 0); setPagination({ ...pagination, pageNumber: page - 1 });
            }} />
          </div>}

        {!loading && pendent.length > 0 && split.length > 0 && pendent.map(item => {
          let splitObj = split.find(item2 => item2.recipient_id === item.recipient.id)

          return (
            <div className="row my-4 mx-4 m-auto recipient-card" key={item.id}>
              <div className="col-10">
                <Typography variant='h6'>{item.name}</Typography>
                <div className="row my-2">
                  <div className="col-6">
                    <Typography>E-mail: {item.email}</Typography>
                    <Typography>Documento: {maskCpf(item.document ?? '')}</Typography>
                    <Typography>Número da Conta: {item.recipient.account_number}</Typography>
                    <Typography>Código da Conta: {item.recipient.account_check_digit}</Typography>
                  </div>
                  <div className='col-6'>
                    <Typography>Modo de Pagamento: Transferência Bancária</Typography>
                    <Typography>Número da Agência: {item.recipient.branch_number}</Typography>
                    <Typography>Código da Agência: {item.recipient.branch_check_digit}</Typography>
                    <Typography>Código do Banco: {item.recipient.bank}</Typography>
                  </div>
                </div>
              </div>
              <div className="col-2 d-flex flex-column" style={{ borderLeft: '3px solid #ffffff' }}>
                <div className="ms-auto">
                  <IconButton onClick={() => handleDelete(splitObj.recipient_id)}><MdClose /></IconButton>
                </div>
                <div className="input-group mt-2">
                  <input className='form-control' type='number' id='rate' onChange={(e) => handleTotalChange(e.target.value, item.recipient.id)} value={splitObj.amount} placeholder='...' />
                  <span class="input-group-text" id="rate">%</span>
                </div>
                <div>{splitObj.error && <b className='small'>A soma dos totais tem que ser 100%</b>}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="d-flex m-3">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to="/courses/list"
        />
        <div className="ms-auto">
          <Button variant='contained' disabled={loadingSave} onClick={handleSave} endIcon={loadingSave ? <CircularProgress size={22} /> : <MdSave />}>Salvar</Button>
        </div>
      </div>
      <SweetAlert
        warning
        title={"Atenção"}
        onConfirm={() => setError(false)}
        show={error}
        confirmBtnText='OK'
        confirmBtnBsStyle="success"
      >
        A soma total dos recebedores tem que ser igual a 100%!

      </SweetAlert>
      <ToastContent />
    </div>
  )
}

export default CourseFinancial
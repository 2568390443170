import { Box, Typography } from "@mui/material";
import TabsContainer from "components/TabsContainer";
import LinkedGroups from "./Groups/LinkedGroups";
import UnlinkedGroups from "./Groups/UnlinkedGroups";
import ReverseList from "./Users/ReverseUsers";
import ToAddList from "./Users/ToAdd";
import LinkedCourses from "./Courses/LinkedCourses";
import UnlinkedCourses from "./Courses/UnlinkedCourses";

const CouponUserList = () => {
    return (
        <Box
            className="card"
        >
            <Box
                className="card-body"
            >
                <Typography
                    variant="h4"
                >
                    Cupons
                </Typography>
                <br/>
                <TabsContainer
                    labels={[
                        { label: "Usuários vínculados" },
                        { label: "Usuários não vínculados" },
                        { label: "Turmas vínculados" },
                        { label: "Turmas não vínculados" },
                        { label: "Cursos vínculados" },
                        { label: "Cursos não vínculados" }
                    ]}
                    variant={"scrollable"}
                >
                    <ToAddList/>
                    <ReverseList/>
                    <LinkedGroups/>
                    <UnlinkedGroups/>
                    <LinkedCourses />
                    <UnlinkedCourses />
                </TabsContainer>
            </Box>
        </Box>
    );
}

export default CouponUserList;
import { Avatar, Box, Typography, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router";
import { Post } from "utils/request";
import { DateFormat, GetLiteralTimePassed } from "utils/transformations";
import { STORAGE_URL } from "variables";

const Board = props => {
    
    const {
        id,
        comment,
        user,
        replies,
        createdAt,
        onSubmit = () => {},
        need_answer=true
    } = props;

    const [reply, SetReply] = useState("");
    const [showInput, SetShowInput] = useState(false);

    const { file_id } = useParams();

    const Submit = async () => {
        const form = new FormData();
        form.append("file_id", file_id);
        form.append("comment", reply);
        form.append("reply_to", id);

        let response = await Post("lessons/files/comments/create", form);

        if (response?.status === true) {
            onSubmit();
            SetReply("");
            SetShowInput(false);
        }
        // dispatch(Show({
        //     show: true,
        //     message: response?.message,
        //     severity: response?.severity
        // }));
    }

    return (
        <Box
            display="flex"
            justifyContent="row"
            width="100%"
        >
            <Box
                padding={1}
            >
                <Avatar
                    src={user?.profile_image ? STORAGE_URL + user?.profile_image : ""}
                />
            </Box>
            <Box
                display="inline-flex"
                alignItems="center"
                flex={1}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    flex={1}
                >
                    <Box
                        display="inline-flex"
                        alignItems={"center"}
                    >
                        <Typography>
                            <b>{user?.name}&nbsp;</b>
                        </Typography>
                        <Typography
                            variant="body2"
                        >
                            {GetLiteralTimePassed(Date.parse(createdAt))} atrás
                        </Typography>
                    </Box>
                    <Typography>
                        {comment}
                    </Typography>
                    {need_answer==true && <Box>
                        <Button
                            onClick={() => SetShowInput(true)}
                            size={"small"}
                        >
                            Responder
                        </Button>
                    </Box>}
                    {need_answer==true && <Box hidden={!showInput}>
                        <TextField
                            placeholder="Adicionar resposta"
                            value={reply}
                            fullWidth
                            onChange={e => SetReply(e.target.value)}
                        />
                        <Box
                            marginTop=".5em"
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                        >
                            <Button
                                onClick={() => {
                                    SetShowInput(false);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!reply}
                                onClick={Submit}
                                disableRipple
                                disableElevation
                            >
                                Comentar
                            </Button>
                        </Box>
                    </Box>}
                    <Box>
                        {replies?.map(reply => (
                            <Board
                                id={reply?.id}
                                key={reply?.id}
                                comment={reply?.comment}
                                user={reply?.user}
                                createdAt={reply?.created_at}
                                replies={reply?.replies}
                                need_answer={false}
                                onSubmit={onSubmit}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Board;
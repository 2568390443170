import Card from "./Card";

export default {
  pix: 0,
  credit_card: 1,
  multi_payment: 2,

  nameMapping: {
    0: 'Pix',
    1: 'Cartão de Crédito',
    2: 'Multi Cartão'
  },

  componentMapping: {
    0: null,
    1: <Card />,
    2: <Card />
  },

  getComponent: function (value) {
    return <Card />
  },

  getName: function (value) {
    return (typeof this.nameMapping[value] !== undefined) ?
      this.nameMapping[value] : false;
  },

}
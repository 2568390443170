import { useHistory } from "react-router-dom";
import Actions from "./Actions";

const Board = props => {

    const {
        id,
        lesson,
        user,
        comment,
        accepted,
        course,
        file,
        onUpdate = () => {}
    } = props;

    const history = useHistory();

    return (
        <tr>
            <td>{lesson?.title}</td>
            <td>
                <a
                    onClick={() => history.push(`/profile/view/${user?.id}`)}
                    style={{
                        cursor: "pointer"
                    }}
                >
                    {user?.name}
                </a>
            </td>
            <td className={"table-text-dots"}>{comment}</td>
            <Actions
                id={id}
                comment={comment}
                onUpdate={onUpdate}
                accepted={accepted}
                courseId={course?.id}
                fileId={file?.id}
            />
        </tr>
    );
}

export default Board;
import { Box, Container, ThemeProvider, Typography } from "@mui/material";
import Video from "components/Video";
import { useEffect, useState } from "react";
import { fileToBase64 } from "utils/images/fileToBase64";
import Form from "../../Form";

const HeaderSection = ({ props, style }) => {
  const {
    name,

    subtitle,

    coverImage = "",
    videoTrailer = null,
    image = "",

    section1_bgColor_1,
    section1_bgColor_2,

    submit_button_bgColor,
    submit_button_textColor,
    submit_button_text,

    submit_wait_text,
    submit_wait_helptext,
  } = props;

  const [imageSrc, setImageSrc] = useState("");
  const [coverImageSrc, setCoverImageSrc] = useState("");

  const [videoBase64, setVideoBase64] = useState(null);

  const renderVideo = async () => {
    if (videoTrailer[0]) {
      await fileToBase64(videoTrailer[0]).then((res) => {
        setVideoBase64(res);
      });
    }
  };

  useEffect(() => {
    renderVideo();
  }, [videoTrailer]);

  useEffect(() => {
    if (image === "") return setImageSrc("");

    if (image === undefined || image === null) return;
    if (image.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setImageSrc(e.target.result);
    };
    reader.readAsDataURL(image);
  }, [image]);

  useEffect(() => {
    if (coverImage === "") return setCoverImageSrc("");

    if (coverImage === undefined || coverImage === null) return;
    if (coverImage.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setCoverImageSrc(e.target.result);
    };
    reader.readAsDataURL(coverImage);
  }, [coverImage]);

  return (
    <section
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        padding: "2rem 0",
        // overflow: "hidden",
        position: "relative",
        background: `linear-gradient(180deg, ${section1_bgColor_1} 0%, ${section1_bgColor_2} 100%)`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem 0",
          position: "relative",
          zIndex: 10,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Seção 1
        </Typography>
        <Typography
          variant="h2"
          sx={{
            backdropFilter: "blur(1.4rem)",
            padding: "0.1rem 1rem",
            borderRadius: "0.8rem",
            textAlign: "center",
          }}
          dangerouslySetInnerHTML={{ __html: name }}
        />
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            backdropFilter: "blur(1.4rem)",
            padding: "0.1rem 1rem",
            borderRadius: "0.8rem",
          }}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />

        <Box
          sx={{
            maxHeight: "60vh",
            marginTop: "2rem",
            maxWidth: "80%",
            width: "100%",
            width: "max-content",
          }}
        >
          {videoBase64 !== null &&
          videoBase64 !== undefined &&
          videoBase64 !== "" ? (
            <Video
              containerProps={{
                style: {
                  borderRadius: "0.8rem",
                  height: "100%",
                },
              }}
              src={videoBase64}
            />
          ) : (
            <img
              style={{
                borderRadius: "0.8rem",
                height: "100%",
              }}
              src={imageSrc}
            />
          )}
        </Box>

        <div style={{ display: "flex", flexDirection: "column", marginTop: '2rem' }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "1rem",
              textAlign: "center",
            }}
            dangerouslySetInnerHTML={{ __html: submit_wait_helptext }}
          />
          <button
            style={{
              padding: "1rem 4rem",
              borderRadius: "0.8rem",
              outline: "none",
              border: "none",
              fontSize: "1.2rem",
              fontWeight: "bold",
              color: submit_button_textColor,
              margin: "0 auto",
              backgroundColor: submit_button_bgColor,
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{ __html: submit_wait_text }}
              sx={{
                color: submit_button_textColor,
                fontWeight: "bold",
              }}
            />
          </button>
        </div>
      </div>

      {/* ghost image cover */}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 1,
          opacity: "0.5",
          top: "0",
          overflow: "hidden",
        }}
      >
        <img className={style.ghostImage} src={coverImageSrc} />
      </div>
    </section>
    // <section
    //   style={{
    //     height: "100vh",
    //     overflow: "hidden",
    //     padding: "6rem 0",
    //     position: "relative",
    //     background: `linear-gradient(180deg, ${section1_bgColor_1} 0%, ${section1_bgColor_2} 100%)`,
    //   }}
    // >
    //   <Typography
    //     variant="h5"
    //     sx={{
    //       fontWeight: "bold",
    //       textAlign: "center",
    //     }}
    //   >
    //     Seção 1
    //   </Typography>
    //   {/* ghost image cover */}
    //   <div
    //     style={{
    //       position: "absolute",
    //       width: "100%",
    //       height: "100%",
    //       zIndex: 1,
    //       opacity: "0.5",
    //       top: "0",
    //       overflow: "hidden",
    //     }}
    //   >
    //     <img className={style.ghostImage} src={coverImageSrc} />
    //   </div>

    //   <Container
    //     maxWidth="xl"
    //     sx={{
    //       justifyContent: "center",
    //       alignItems: "center",
    //       display: "flex",
    //       flexDirection: "column",
    //       zIndex: 10,
    //       position: "relative",
    //     }}
    //   >
    //     <Typography
    //       variant="h2"
    //       sx={{
    //         backdropFilter: "blur(1.4rem)",
    //         padding: "0.1rem 1rem",
    //         borderRadius: "0.8rem",
    //         textAlign: "center",
    //       }}
    //       dangerouslySetInnerHTML={{ __html: name }}
    //     />
    //     <Typography
    //       variant="h6"
    //       sx={{
    //         textAlign: "center",
    //         backdropFilter: "blur(1.4rem)",
    //         padding: "0.1rem 1rem",
    //         borderRadius: "0.8rem",
    //       }}
    //       dangerouslySetInnerHTML={{ __html: subtitle }}
    //     />

    //     <Box
    //       sx={{
    //         marginTop: "4rem",
    //         background: section1_accentColor,
    //         padding: "0.2rem",
    //         borderRadius: "0.89rem",
    //         height: "100%",
    //         width: "max-content",
    //       }}
    //     >
    //       {videoBase64 !== null &&
    //       videoBase64 !== undefined &&
    //       videoBase64 !== "" ? (
    //         <Video
    //           containerProps={{
    //             style: {
    //               borderRadius: "0.8rem",
    //               width: "100%",
    //             },
    //           }}
    //           src={videoBase64}
    //         />
    //       ) : (
    //         <img
    //           style={{
    //             borderRadius: "0.8rem",
    //             width: "auto",
    //             height: "100%",
    //           }}
    //           src={imageSrc}
    //         />
    //       )}
    //       {/* {videoTrailer !== "" ||
    //         (videoTrailer.length !== 0 && (

    //         ))}
    //       {videoTrailer === "" ||
    //         (videoTrailer.length === 0 && (
    //           <img
    //             style={{
    //               borderRadius: "0.8rem",
    //               width: "100%",
    //             }}
    //             src={imageSrc}
    //           />
    //         ))} */}
    //     </Box>

    //     {/* <div style={{ marginTop: "4rem" }}>
    //       <Form
    //         image={image}
    //         price={price}
    //       // inDateRange={inDateRange}
    //       // {...course}
    //       />
    //     </div> */}
    //   </Container>
    // </section>
  );
};

export default HeaderSection;

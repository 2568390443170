import { Box, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Popover } from "@mui/material";
import useFilterButton from "../useFilterButton";

const Modal = props => {

    const {
        hook = {}
    } = props;

    const {
        anchorEl = null,
        closePopover = () => {},
        handleCheckbox = () => {},
        clearFilter = () => {},
        hasValue = () => {}
    } = hook;

    return (
        <Popover
            PaperProps={{
                style: {
                    width: "40%",
                    minWidth: "20em"
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closePopover}
        >
            <Box
                component={Card}
            >
                <CardContent>
                    <FormControl component="fieldset">
                        <Box
                            display={"inline-flex"}
                            justifyContent={"space-between"}
                            flexWrap={"wrap"}
                        >
                            <FormLabel
                                component="legend"
                                style={{
                                    flex: 1
                                }}
                            >
                                Ação
                            </FormLabel>
                            <a
                                onClick={clearFilter}
                                style={{
                                    flex: 1,
                                    textAlign: "right",
                                    cursor: "pointer"
                                }}
                            >
                                Limpar filtro
                            </a>
                        </Box>
                        <FormGroup
                            row
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasValue("create")}
                                        onChange={handleCheckbox("create")}
                                    />
                                }
                                label={"Registros"}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasValue("alter")}
                                        onChange={handleCheckbox("alter")}
                                    />
                                }
                                label={"Edições"}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasValue("delete")}
                                        onChange={handleCheckbox("delete")}
                                    />
                                }
                                label={"Exclusões"}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasValue("adquire")}
                                        onChange={handleCheckbox("adquire")}
                                    />
                                }
                                label={"Compras"}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasValue("view")}
                                        onChange={handleCheckbox("view")}
                                    />
                                }
                                label={"Visualizações"}
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Tabela</FormLabel>
                        <FormGroup
                            row
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasValue("courses_main")}
                                        onChange={handleCheckbox("courses_main", "table")}
                                    />
                                }
                                label={"Cursos"}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasValue("users")}
                                        onChange={handleCheckbox("users", "table")}
                                    />
                                }
                                label={"Usuários"}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasValue("groups")}
                                        onChange={handleCheckbox("groups", "table")}
                                    />
                                }
                                label={"Turmas"}
                            />
                        </FormGroup>
                    </FormControl>
                </CardContent>
            </Box>
        </Popover>
    );
}

export default Modal;
import React, { useEffect, useState } from "react";
import { Chip, Autocomplete, TextField } from "@mui/material";

import { FormControl as Input } from "react-bootstrap";

import { Get } from "utils/request";

const Responsibles = (props) => {
  const { onChange } = props;

  const [responsibles, SetResponsibles] = useState([]);
  const [toAddResponsibles, SetToAddResponsibles] = useState([]);
  const [search, SetSearch] = useState("");

  useEffect(() => {
    SetToAddResponsibles(props.responsibles)
  }, [props.responsibles])

  const GetData = async () => {
    let response = await Get(`user/privileged?search=${search}`);
    if (response?.status === true) {
      SetResponsibles(response?.users);
    }
  };

  const UpdateList = (category) => {
    let toAddResponsiblesTemp = toAddResponsibles;
    if (!toAddResponsiblesTemp.find((x) => x.id == category.id))
      toAddResponsiblesTemp.push(category);

    SetToAddResponsibles([...toAddResponsiblesTemp]);
    SetSearch("");
    onChange(toAddResponsiblesTemp);
  };

  const RemoveFromList = (index) => {
    let toAddResponsiblesTemp = toAddResponsibles;
    toAddResponsiblesTemp.splice(index, 1);
    SetToAddResponsibles([...toAddResponsiblesTemp]);
    onChange(toAddResponsiblesTemp);
  };

  useEffect(() => GetData(), [search]);

  return (
    <div className="responsibles">
      <Autocomplete
        disablePortal
        options={responsibles.filter(
          (category) =>
            !toAddResponsibles.map((x) => x.id).includes(category.id)
        )}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(_, newValue) => UpdateList(newValue)}
        noOptionsText={<p>Nenhum usuário encontrado</p>}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <Input
              {...params.inputProps}
              placeholder="Procurar por usuários"
              value={search}
              onChange={(e) => SetSearch(e.target.value)}
            />
          </div>
        )}
      />
      <div className="categories-chips">
        {toAddResponsibles?.map((value, index) => (
          <Chip
            key={index}
            label={value?.name}
            onDelete={() => RemoveFromList(index)}
            style={{
              marginTop: "10px",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Responsibles;

import React, { useState } from "react";
import FormBody from "./FormBody/index";
import { useDispatch } from "react-redux";
import style from "./style.module.css";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import { useEffect } from "react";
import logo_loading from "assets/images/rt/icon_transparent.png";
import { Show } from "actions/SnackbarActions";
import { Get, Post } from "utils/request";
import CheckoutPage from "./CheckoutPage";

const ConfigCheckout = ({ course, handleCloseModal,hidden }) => {
  const dispatch = useDispatch();
  const [loading, SetLoading] = useState(false);
  const [hidePreview, setHidePreview] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [save_button_status, setSaveButtonStatus] = useState({
    status: "Salvar tudo",
    icon: <SaveIcon />,
  });
  const [preview_course, setPreviewCourse] = useState(null);

  const [background_color, SetBackgroundColor] = useState("#fff");
  const [banner, SetBanner] = useState(null);
  const [card_color, SetCardColor] = useState("#fff");
  const [fontColor, SetFontColor] = useState("#000");
  const [accent_color, SetAccentColor] = useState("#000");
  const [cover_image, SetCoverImage] = useState(null);
  const [aside_banner, SetAsideBanner] = useState(null);

  const Submit = async (data) => {
    let form = new FormData();
    form.append("id", course.id);
    form.append("checkout_bg_color", background_color);
    form.append("checkout_banner", banner);
    form.append("checkout_aside_banner", aside_banner);
    form.append("checkout_card_color", card_color);
    form.append("checkout_font_color", fontColor);
    form.append("checkout_accent_color", accent_color);
    form.append("checkout_cover_image", cover_image);
    form.append("show_mural", course.show_mural);

    // form.append("categories", course?.categories);
    // console.log(course?.categories);
    form.append("free_course_changed_to_false", false);

    for (let i = 0; i < course.categories.length; i++)
      form.append("category_id[]", course.categories[i]?.id);

    SetLoading(true);
    setSaveButtonStatus({
      status: "Salvando...",
      icon: <HourglassBottomIcon />,
    });
    let response = await Post("courses-main/update", form);
    SetLoading(false);

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );

    if (response?.status) {
      setSaveButtonStatus({
        status: "Salvo!",
        icon: <CheckIcon />,
      });
    } else if (response.httpCode === 413) {
      dispatch(
        Show({
          show: true,
          message:
            "Arquivo(s) inseridos muito grandes. Tente inserir imagens mais leves.",
          severity: "error",
        })
      );
    } else {
      setSaveButtonStatus({
        status: "Erro!",
        icon: <HourglassBottomIcon />,
      });
    }
  };

  useEffect(() => {
    SetBackgroundColor(course.checkout_bg_color);
    SetBanner(course.checkout_banner);
    SetCardColor(course.checkout_card_color);
    SetFontColor(course.checkout_font_color);
    SetAccentColor(course.checkout_accent_color);
    SetCoverImage(course.checkout_cover_image);
    SetAsideBanner(course.checkout_aside_banner);
    setIsLoading(false);
    setPreviewCourse(course);
  }, [course]);

  return isLoading ? (
    <div style={{ width: "100%", aspectRatio: "16/9", display: "flex" }}>
      <img
        src={logo_loading}
        alt="Logotipo RT"
        className={"infiniteRotateAnim"}
        style={{
          width: "12rem",
          height: "12rem",
          margin: "auto",
        }}
      />
    </div>
  ) : (
    <div
      className={style.gridContainer}
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: hidePreview ? "100%" : `30%`,
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <h2 className="title">Configurações da página "Checkout"</h2>
          </div>

          <FormBody
            handleSubmit={Submit}
            loading={loading}
            {...{
              save_button_status,
              background_color,
              SetBackgroundColor,
              banner,
              SetBanner,
              aside_banner,
              SetAsideBanner,
              fontColor,
              SetFontColor,
              card_color,
              SetCardColor,
              accent_color,
              SetAccentColor,
              cover_image,
              SetCoverImage,
              handleCloseModal,
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: hidePreview ? "0" : `70%`,
          height: "100vh",
          display: "flex",

          borderLeft: "0.2rem dashed black",
        }}
      >
        {/* <PaymantPreview
          {...{
            course,
            background_color,
            banner,
            card_color,
            fontColor,
            accent_color,
            cover_image,
          }}
        /> */}
        {Object.keys(course).length !== 0 && (
          <CheckoutPage
            {...{
              course_data: course,
              background_color,
              banner,
              card_color,
              fontColor,
              accent_color,
              cover_image,
              aside_banner,
            }}
            hidden={hidden}
          />
        )}
      </div>
    </div>
  );
};

export default ConfigCheckout;

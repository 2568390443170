import { Button, CircularProgress, ThemeProvider, Tooltip, useTheme } from "@mui/material";
import { indigoTheme } from "utils/Themes";

import AutorenewIcon from '@mui/icons-material/Autorenew';

const RefreshButton = props => {

    const {
        title = "",
        isLoading = false,
        ...others
    } = props;

    const theme = useTheme();

    return (
        <ThemeProvider
            theme={indigoTheme}
        >
            <Tooltip
                title={title}
                arrow
            >
                <Button
                    color="primary"
                    variant={"contained"}
                    style={{
                        minWidth: "2.2em",
                        minHeight: "2.2em",
                        margin: theme.spacing(.5),
                        padding: 0
                    }}
                    {...others}
                >
                    {isLoading ? (
                        <CircularProgress
                            size={20}
                            style={{
                                color: "white"
                            }}
                        />
                    ) : (
                        <AutorenewIcon
                            fontSize={"small"}
                        />
                    )}
                </Button>
            </Tooltip>
        </ThemeProvider>
    );
}

export default RefreshButton;
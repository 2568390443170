import React, { useState } from "react";

import { MdModeEdit, MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useDragControls, Reorder } from "framer-motion";

import SweetAlert from "react-bootstrap-sweetalert";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { IconButton } from "@mui/material";
import { Post } from "utils/request";
import DefaultButton from "components/DefaultButton";
import { Show } from "actions/SnackbarActions";
import { STORAGE_URL } from "variables";

const Board = (props) => {
  const { value, id, draggable = true, OnDelete = () => {} } = props;

  const [popup, SetPopup] = useState(false);

  const [loading, SetLoading] = useState(false);

  const dispatch = useDispatch();
  const dragControls = useDragControls();

  const Delete = async () => {
    let form = new FormData();
    form.append("id", id);

    SetLoading(true);
    let response = await Post("site-rt/bannerPrincipal-images/delete", form);
    SetLoading(false);

    if (response?.status) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
        })
      );
      OnDelete();
    }
  };

  return (
    <Reorder.Item
      value={value}
      dragListener={false}
      dragControls={dragControls}
      as="tr"
    >
      <IconButton
        component="td"
        onPointerDown={(e) => draggable && dragControls.start(e)}
        style={{
          cursor: draggable ? "pointer" : "not-allowed",
          touchAction: "pan-x",
        }}
      >
        <DragHandleIcon />
      </IconButton>
      <td>
        <img
          src={STORAGE_URL + value.image}
          alt={value.title}
          style={{ width: "12rem", height: "6rem", objectFit: 'cover' }}
        />
      </td>
      <td
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "14rem",
        }}
      >
        {value.link}
      </td>
      <td>
        <SweetAlert
          warning
          title={"Atenção"}
          onConfirm={() => {
            Delete();
            SetPopup(false);
          }}
          onCancel={() => SetPopup(false)}
          show={popup}
          confirmBtnText="Deletar"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          showCancel={true}
        >
          Tem certeza que deseja deletar essa categoria?
        </SweetAlert>
        {/* <DefaultButton
          to={`/categories/${id}/view`}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Ver sobre categoria`}
          bg="warning"
          icon={<BsFillEyeFill size={17} color="white" />}
        />
        <DefaultButton
          to={`/categorias/editar/${id}`}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Editar categoria`}
          bg="info"
          icon={<MdModeEdit color="white" size={17} />}
          style={{
            margin: "0 2px",
          }}
        /> */}
        <DefaultButton
          onClick={() => SetPopup(true)}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Deletar categoria`}
          bg="danger"
          loading={loading}
          icon={!loading && <MdDelete size={17} color="white" />}
        />
      </td>
    </Reorder.Item>
  );
};

export default Board;

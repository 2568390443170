import { createTheme, ThemeProvider, Box } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";

import Footer from "modules/Previews/Footer";
import CarouselSlider from "components/CarouselSlider";
import placeholder from "assets/images/image_placeholder.png";
import Welcome from "./Welcome";
import VideoCarousel from "./VideoCarousel";
import AppAd from "./AppAd";
import Testimonials from "./Testimonials";
import BottomLead from "./BottomLead";
import Header from "./Header";
import usePreviewPage from "./usePreviewPage";

const PreviewPage = (props) => {
  const {
    primaryColor,
    // backgroundImage,
    phone,
    email,
    whatsapp,
    footerBackgroundColor,
  } = props;

  const { innerTheme, logo, smallLogo } = usePreviewPage(props);

  // const background = useRef(null);

  // useEffect(() => {
  //     if (backgroundImage instanceof File && FileReader) {
  //         let fileReader = new FileReader();
  //         fileReader.onload = () => background.current.style.backgroundImage = `url(${fileReader.result})`;
  //         fileReader.readAsDataURL(backgroundImage);
  //     }
  // }, [backgroundImage]);

  return (
    <Box
      style={{
        aspectRatio: "16/9",
        backgroundColor: "black",
        overflowY: "scroll",
      }}
    >
      <ThemeProvider theme={innerTheme}>
        <Header logo={logo} smallLogo={smallLogo} />
        <CarouselSlider
          arrows={false}
          swiperProps={{
            breakpoints: null,
            loop: true,
            autoplay: {
              delay: 7000,
              disableOnInteraction: false,
            },
          }}
          style={{
            marginTop: "-10px",
          }}
        >
          {new Array(3).fill(undefined).map((banner, key) => (
            <a key={key}>
              <Box
                style={{
                  width: "100%",
                  // aspectRatio: "10/3",
                  aspectRatio: "21/6",
                  backgroundSize: "contain",
                  backgroundImage: `url(${placeholder})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: banner?.bgcolor ?? "#fff",
                }}
              />
            </a>
          ))}
        </CarouselSlider>
        <Welcome />
        <VideoCarousel bgcolor={primaryColor} />
        {/* <AppAd/> */}
        <Testimonials bgcolor={primaryColor} />
        <BottomLead />
        <Footer
          bgcolor={footerBackgroundColor}
          phone={phone}
          email={email}
          whatsapp={whatsapp}
        />
      </ThemeProvider>
    </Box>
  );
};

export default PreviewPage;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Show } from "actions/SnackbarActions";
import { Post } from "utils/request";
import Formbody from "./Formbody";
import SweetAlert from "react-bootstrap-sweetalert";

const SciencePlusEdit = () => {

    const [loading, SetLoading] = useState(false);
    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const { post_id } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();

    const Submit = async (data) => {
        if (!data?.image) {
            SetPopup(true);
            SetPopupMessage("Insira uma imagem!");
            return;
        } else if (!data?.title) {
            SetPopup(true);
            SetPopupMessage("Insira um título!");
            return;
        } else if (!data?.content) {
            SetPopup(true);
            SetPopupMessage("Insira algum conteúdo na postagem!");
            return;
        }

        let form = new FormData();
        form.append("id", post_id);
        form.append("title", data?.title || "Não informado");
        form.append("content", data?.content || "Não informado");

        try {
            if (data?.image.includes("base64")) {}
        } catch {
            form.append("image", data?.image);
        }
        form.append("description", data?.description || "Não informado");
        form.append("reference_link", data?.reference_link || "Não informado");
        form.append("reference_post_author", data?.reference_post_author || "Não informado");
        for (let i = 0; i < data?.links?.length; i++) form.append("links[]", JSON.stringify(data?.links[i]));

        SetLoading(true);
        let response = await Post("science-plus/alter", form);
        SetLoading(false);
        
         
        dispatch(Show({
            show: true,
            message: response?.message,
            severity: response?.severity
        }));

        if (response?.status === true) history.push(`/science-plus/list`);
    }

    return (
        <div className="card">
            <SweetAlert
                warning
                show={popup}
                title="Atenção!"
                confirmBtnText="Ok"
                onConfirm={() => SetPopup(false)}
            >
                {popupMessage}
            </SweetAlert>
            <div className="card-body">
                <h2 className="title">Editar postagem</h2>
                <p>Aqui são editadas as postagens do +Ciência</p>
                <br/>
                <Formbody
                    OnConfirm={data => Submit(data)}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default SciencePlusEdit;
import { Box, Container, Typography, useTheme } from "@mui/material";
import { transform } from "framer-motion";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Carousel } from "react-responsive-carousel";
import { Get } from "utils/request";

import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import { STORAGE_URL } from "variables";

const FreeCourses = (props) => {
  const theme = useTheme();

  const {
    free_lessons,
    setFree_lessons,
    free_lessons_title,
    free_lessons_two_views,
    free_lessons_transition_time,
    aside_transition_time
  } = props;

  const [halfwayThrough, setHalfwayThrough] = useState(0);
  const [aside_banners, setAside_banners] = useState([]);

  const aside_banners_data = useQuery(["banners"], () =>
    Get(`site-rt/aside-banner`)
  );

  useEffect(() => {
    if (aside_banners_data !== undefined) {
      setAside_banners(aside_banners_data?.data?.banners?.data);
    }
  }, [aside_banners_data]);

  useEffect(() => {
    if (free_lessons && free_lessons.length > 0) {
      setHalfwayThrough(Math.floor(free_lessons.length / 2));
    }
  }, [free_lessons]);

  const { data, isLoading, refetch } = useQuery(
    "courses",
    async () => await Get(`courses-main/free-courses`),
    {
      onSuccess: (data) => {
        const { courses } = data || {};
        setFree_lessons(courses);
      },
    }
  );
  useEffect(() => {
    refetch();
  }, [free_lessons]);

  const getVideoThumbnail = (link) => {
    try {
      const videoId = link.split("=")[1];
      return `https://i3.ytimg.com/vi/${videoId}/sddefault.jpg`;
    } catch {
      return "";
    }
  };

  return (
    <section
      style={{
        padding: "4rem 0",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: "black",
          position: "absolute",
          top: "1rem",
        }}
      >
        (seção 3)
      </Typography>
      <Typography
        variant="body"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: "black",
          position: "absolute",
          top: "-2rem",
        }}
      >
        Esta visualização é apenas para fins de demonstração. O resultado final
        poderá ser um pouco diferente
      </Typography>
      <section
        style={{
          // padding: "4rem 0",
          backgroundColor: "rgba(122, 93, 180, 0.1)",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              marginBottom: "2rem",
            }}
            dangerouslySetInnerHTML={{ __html: free_lessons_title || "" }}
          />
          <div
            style={{
              width: !free_lessons_two_views ? "26rem" : "100%",
              borderRadius: "0.4rem",
              overflow: "hidden",
              display: "flex",
              flexWrap: "wrap",
              gap: free_lessons_two_views ? "1rem" : "3rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!free_lessons_two_views && (
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                renderIndicator={false}
                centerMode={false}
                showThumbs={false}
                transitionTime={500}
                interval={free_lessons_transition_time}
                // maxWidth=""
                showStatus={false}
              >
                {free_lessons &&
                  free_lessons.map((course, index) => (
                    <CourseCard
                      key={index}
                      description={course.description}
                      image={getVideoThumbnail(course.link)}
                      link={course.link}
                      title={course.name}
                    />
                  ))}
              </Carousel>
            )}

            {free_lessons_two_views && (
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  minWidth: "20rem",
                }}
              >
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  renderIndicator={false}
                  centerMode={false}
                  showThumbs={false}
                  transitionTime={500}
                  interval={free_lessons_transition_time}
                  // width="400px"
                  showStatus={false}
                >
                  {/* dividir em dois arrays */}
                  {free_lessons &&
                    free_lessons_two_views &&
                    free_lessons
                      .slice(0, halfwayThrough)
                      .map((course, index) => (
                        <CourseCard
                          key={index}
                          description={course.description}
                          image={getVideoThumbnail(course.link)}
                          link={course.link}
                          title={course.name}
                          half
                        />
                      ))}
                </Carousel>
              </div>
            )}

            {free_lessons_two_views && (
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  minWidth: "20rem",
                }}
              >
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  renderIndicator={false}
                  centerMode={false}
                  showThumbs={false}
                  transitionTime={500}
                  interval={free_lessons_transition_time}
                  // width="400px"
                  showStatus={false}
                >
                  {/* dividir em dois arrays */}
                  {free_lessons &&
                    free_lessons_two_views &&
                    free_lessons
                      .slice(halfwayThrough, free_lessons.length)
                      .map((course, index) => (
                        <CourseCard
                          key={index}
                          description={course.description}
                          image={getVideoThumbnail(course.link)}
                          link={course.link}
                          title={course.name}
                          half
                        />
                      ))}
                </Carousel>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            maxWidth: "25.4rem",
            overflow: "hidden",
          }}
        >
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            renderIndicator={false}
            centerMode={false}
            showThumbs={false}
            transitionTime={500}
            interval={aside_transition_time}
            showStatus={false}
          >
            {aside_banners &&
              aside_banners.map((banner, index) => (
                <a key={index} href={banner?.link} target="_blank">
                  <div
                    style={{
                      width: "25.4rem",
                      height: "50rem",
                      // aspectRatio: "10/3",
                      // aspectRatio: "16/9",
                      backgroundSize: "contain",
                      backgroundImage: `url(${STORAGE_URL + banner?.banner})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </a>
              ))}
          </Carousel>
        </div>
      </section>
    </section>

    // <section
    //   style={{
    //     // padding: "4rem 0",
    //     backgroundColor: "rgba(122, 93, 180, 0.1)",
    //     display: "flex",
    //     justifyContent: "space-between",
    //   }}
    // >
    //   <div
    //     style={{
    //       width: "100%",
    //       alignItems: "center",
    //       justifyContent: "center",
    //       flexDirection: "column",
    //       display: "flex",
    //     }}
    //   >
    //     <Typography
    //       variant="h3"
    //       sx={{
    //         textAlign: "center",
    //         marginBottom: "2rem",
    //       }}
    //       dangerouslySetInnerHTML={{ __html: free_lessons_title || "teste" }}
    //     />
    //     <div
    //       style={{
    //         maxWidth: "40rem",
    //         borderRadius: "0.4rem",
    //         overflow: "hidden",
    //       }}
    //     >
    //       <Carousel
    //         autoPlay={true}
    //         infiniteLoop={true}
    //         renderIndicator={false}
    //         centerMode={false}
    //         showThumbs={false}
    //         transitionTime={1000}
    //         interval={6000}
    //         // maxWidth=""
    //         showStatus={false}
    //       >
    //         {free_lessons.map((course, index) => (
    //           <CourseCard
    //             key={index}
    //             description={course.description}
    //             image={getVideoThumbnail(course.link)}
    //             link={course.link}
    //             title={course.name}
    //           />
    //         ))}
    //       </Carousel>
    //     </div>
    //   </div>
    //   <div
    //     style={{
    //       maxWidth: "20rem",
    //       overflow: "hidden",
    //     }}
    //   >
    //     <Carousel
    //       autoPlay={true}
    //       infiniteLoop={true}
    //       renderIndicator={false}
    //       centerMode={false}
    //       showThumbs={false}
    //       transitionTime={1000}
    //       interval={6000}
    //       showStatus={false}
    //     >
    //       {aside_banners &&
    //         aside_banners.map((banner, index) => (
    //           <a key={index} href={banner?.link} target="_blank">
    //             <div
    //               style={{
    //                 width: "20rem",
    //                 height: "40rem",
    //                 // aspectRatio: "10/3",
    //                 // aspectRatio: "16/9",
    //                 backgroundSize: "contain",
    //                 backgroundImage: `url(${STORAGE_URL + banner?.banner})`,
    //                 backgroundPosition: "center",
    //                 backgroundRepeat: "no-repeat",
    //               }}
    //             />
    //           </a>
    //         ))}
    //     </Carousel>
    //   </div>
    // </section>
  );
};

const CourseCard = ({ title, description, image, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      style={{ textDecoration: "none", color: "black" }}
    >
      <Box
        sx={{
          height: "max-content",
          position: "relative",
          padding: "0.4rem",
          backgroundColor: "#e3e3e3",
          // backgroundColor: "black",
          // maxWidth: "40rem",

          transition: "all 250ms ease",
          "&:hover": {
            filter: "brightness(0.8)",
          },
          "&:hover > .free-course-card-open-url": {
            opacity: 1,
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{ marginBottom: "0.4rem" }}
          dangerouslySetInnerHTML={{ __html: title || "" }}
        />
        <Box
          className="free-course-card-open-url"
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: "1rem",
            opacity: 0,
            transition: "all 250ms ease",
            display: "flex",
            flexDirection: "column",
            paddingBottom: "0.5rem",
          }}
        >
          <LaunchRoundedIcon
            sx={{
              fontSize: "10rem",
              color: "#42b3f5",
            }}
          />
          <Typography
            variant="body1"
            sx={{ color: "white", textAlign: "center" }}
          >
            Abrir link da aula
          </Typography>
        </Box>

        <img
          src={image}
          style={{
            objectFit: "cover",
            aspectRatio: "16/9",
            maxHeight: "30rem",
            borderRadius: "0.8rem",
          }}
        />

        {/* <div
          style={{
            // backgroundColor: "#9585ff",
            // backgroundColor: "black",
            position: "absolute",
            bottom: "0",
            width: "100%",
            padding: "0.6rem",
            // backdropFilter: "blur(1.8rem)",
          }}
        >
        </div> */}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", marginTop: "0.8rem" }}
          dangerouslySetInnerHTML={{ __html: description || "" }}
        />
      </Box>
    </a>
  );
};

export default FreeCourses;

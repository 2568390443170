import { Typography } from "@mui/material";
import { Container } from "@mui/system";

import img_warranty from "assets/images/warranty.svg";

const Warranty = ({ props }) => {
  const {
    show_warranty,
    warranty_title,
    warranty_text,
    warranty_bgColor_1,
    warranty_bgColor_2,
  } = props;

  return (
    show_warranty && (
      <section
        style={{
          padding: "6rem 0",
          background: `linear-gradient(180deg, ${warranty_bgColor_1} 0%, ${warranty_bgColor_2} 100%)`,
          // minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          position: "relative",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "column", lg: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            zIndex: 10,
            position: "relative",
          }}
        >
          <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            color: "black",
            position: "absolute",
            top: "-4rem",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Garantia
        </Typography>
          <img src={img_warranty} style={{ width: "70%", minWidth: "14rem" }} />
          <div>
            <Typography
              variant="h4"
              sx={{
                padding: "0.1rem 1rem",
              }}
              dangerouslySetInnerHTML={{ __html: warranty_title }}
            />
            <Typography
              variant="h6"
              sx={{
                padding: "0.1rem 1rem",
              }}
              dangerouslySetInnerHTML={{ __html: warranty_text }}
            ></Typography>
          </div>
        </Container>
      </section>
    )
  );
};

export default Warranty;

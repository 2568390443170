import { Typography } from "@mui/material";
import CountUp from "components/CountUp";
import { useRef } from "react";
import styles from "./style.module.css";

const StatisticCard = ({ card }) => {
  const card_ref = useRef(null);

  return (
    <div className={styles.cardContainer}>
      <div ref={card_ref} className={styles.cardContUp}>
        <CountUp to={parseInt(card.number)} duration={2} />
      </div>
      <Typography
        style={{
          fontWeight: "bold",
          fontSize: "1.1rem",
          marginLeft: "0.4rem",
        }}
      >
        {card?.text}
      </Typography>
    </div>
  );
};

export default StatisticCard;

import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button, Box } from "@mui/material";
import DynamicDialog from "components/DynamicDialog";
import ImageInput from "components/Inputs/ImageInput";
import { useContext, useEffect, useState } from "react";
import { VideosContext } from "../..";

import styles from "./index.module.css";

const VideoModal = props => {

    const {
        id,
        name,
        thumbnail,
        onClose = () => {},
        ...others
    } = props;

    const [inputThumbnail, SetThumbnail] = useState(thumbnail || "");
    const [inputName, SetName] = useState(name);

    const { videos, SetVideos } = useContext(VideosContext);

    const Submit = () => {
        const tempVideo = [...videos];
        const index = videos.findIndex((obj => obj?.id === id));

        tempVideo[index] = {
            ...tempVideo[index],
            name: inputName,
            thumbnail: inputThumbnail
        };
        SetVideos([...tempVideo]);
    }

    useEffect(()=>{
        SetName(name)
    },[name])

    return (
        <DynamicDialog
            {...others}
            id={id}
            style={{zIndex:2}}
            title={"Modificar vídeo"}
            onClose={onClose}
            onCancel={onClose}
            onConfirm={() => {
                Submit();
                onClose();
            }}
        >
            <Box
                marginBottom={2}
            >
                <ImageInput
                    path={inputThumbnail}
                    storageUrl={false}
                    ImageChange={image => SetThumbnail(image)}
                />
            </Box>
            <Box>
                <TextField
                    label={"Nome"}
                    placeholder={"Nome"}
                    value={inputName}
                    onChange={e => SetName(e.target.value)}
                    fullWidth
                />
            </Box>
        </DynamicDialog>
    );
}

export default VideoModal;
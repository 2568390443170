import { Box, Typography } from "@mui/material";
import Paginate from "components/Paginate";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Get } from "utils/request";
import Board from "./Board";

const Feedback = () => {

    const [page, setPage] = useState(1);
    const { id } = useParams();

    const { data, isLoading, refetch } = useQuery(["evaluations"], async () => await Get(`courses-main/evaluations?courseId=${id}&page=${page}`),{

    });
    const {
        evaluations = [],
        pagination: {
            lastPage: maxPage = 1
        } = {}
    } = data || {};

    useEffect(() => {
        console.log('data', data)
    }, [data])

    return (
        <>
         <h5 className="title">Feedback</h5>
            <p className="text-muted" >Aqui são listados todos os feedbacks deste curso</p>
            <div style={{marginBottom:15}}/>
        <Box
            className="table-responsive"
        >
            <table
                className="table table-striped"
            >
                <thead>
                    <tr>
                        <th>Autor</th>
                        <th>Feedback</th>
                        <th>Pontuação</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {evaluations?.map((evaluation, evaluationKey) => (
                        <Board
                            key={evaluationKey}
                            onUpdate={refetch}
                            {...evaluation}
                        />
                    ))}
                </tbody>
            </table>
            <Paginate
                value={page}
                onChange={setPage}
                maxPage={maxPage}
            />
        </Box>
        {evaluations?.length == 0 && (
            <Typography
                align={"center"}
            >
                Nenhum registro de feedback
            </Typography>
        )}
        </>
    );
}

export default Feedback;
import { Button, useTheme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import Modal from './Modal';
import useFilterButton from './useFilterButton';

const FilterButton = props => {

    const hook = useFilterButton();
    const {
        anchorEl,
        openPopover,
        closePopover
    } = hook;

    const theme = useTheme();

    return (
        <>
            <Button
                variant={"contained"}
                onClick={Boolean(anchorEl) ? closePopover : openPopover}
            >
                <FilterListIcon
                    style={{
                        margin: theme.spacing(0, 1)
                    }}
                />
                Filtrar
            </Button>
            <Modal hook={hook}/>
        </>
    );
}

export default FilterButton;
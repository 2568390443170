import Section1Edit from "./Section1";
import Section2Edit from "./Section2";
import Section3Edit from "./Section3";
import Section4Edit from "./Section4";
import Section5Edit from "./Section5";

const JoditConfig = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarInlineForSelection: true,
  showPlaceholder: false,
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "font",
    "fontsize",
    "brush",
  ],
  height: 100,
  defaultLanguage: "pt_br",
  language: "pt_br",
  minHeight: 100,
  readonly: false,
  toolbarAdaptive: false,
};

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  maxWidth: "100%",
  minWidth: "36rem",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
};


const text_container = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  position: "relative",
};

const text_style = {
  fontSize: "1.5rem",
  fontWeight: "bold",
  position: "absolute",
  margin: 0,
  padding: "0 1rem",
  backgroundColor: "white",
  zIndex: 10,
};

const text_comment = {
  fontSize: "1rem",
  textAlign: "center",
};


function RenderSectionHeader({ title, comment }) {
  // render a header for the section
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      marginTop: "2rem",
    }}>
      <div style={text_container}>
        <h4 style={text_style}>{title}</h4>
        <hr style={{ width: "100%" }} />
      </div>
        <span style={text_comment}>{comment}</span>
    </div>
  );
}


const FormPaginaInicial = (props) => {
  const { section2, section3, section5, section4 } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {RenderSectionHeader({
        title: "Seção 1",
        // comment: "Texto da seção 1",
      })}
      <Section1Edit {...{...props, JoditConfig}} />

      {RenderSectionHeader({
        title: "Seção 2",
        // comment: "Texto da seção 2",
      })}
      <Section2Edit
        section2={section2}
        modal_style={modal_style}
        JoditConfig={JoditConfig}
      />

      {RenderSectionHeader({
        title: "Seção 3",
        // comment: "Texto da seção 3",
      })}
      <Section3Edit
        section3={section3}
        {...{...props, JoditConfig, modal_style}}
      />

      {RenderSectionHeader({
        title: "Seção 4",
        // comment: "Texto da seção 4",
      })}
      <Section4Edit section4={section4} JoditConfig={JoditConfig} />

      {RenderSectionHeader({
        title: "Seção 5",
        // comment: "Texto da seção 5",
      })}
      <Section5Edit
        section5={section5}
        modal_style={modal_style}
        JoditConfig={JoditConfig}
      />
    </div>
  );
};

export default FormPaginaInicial;

import { Typography } from "@mui/material";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Container } from "reactstrap";

import Image from "components/Image";
// import { brands } from "../Brands/data";
import { Get } from "utils/request";
import { useQuery } from "react-query";
import { STORAGE_URL } from "variables";

class Clientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { brands: [] };
  }

  async getBrands() {
    const response = await Get(`science-plus/references/list`);
    if (response?.status === true) {
      this.setState({ brands: response?.references });
    }
  }

  componentDidMount() {
    this.getBrands();
  }

  render() {
    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
          margin: "auto",
          padding: "4rem 0",
        }}
      >
        <Typography
          variant="h3"
          component="div"
          sx={{
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
            alignSelf: "center",
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "40px",
              borderTop: "2px solid #444",
              margin: "30px auto 0",
              marginBottom: "2rem",
            },
          }}
        >
          +Ciência
        </Typography>

        {/* <Typography
          variant="h5"
          component="div"
          sx={{
            fontFamily: "var(--Raleway)",
            alignSelf: "center",
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "40px",
              borderTop: "2px solid #444",
              margin: "30px auto 0",
              marginBottom: "2rem",
            },
          }}
        >
          A ciência é a arte de explicar o que é e como se comporta.
        </Typography> */}

        <OwlCarousel
          className="owl-theme"
          loop
          items={8}
          dots={false}
          autoplay={true}
          autoplayTimeout={2000}
        >
          {this.state.brands?.map((brand, key) => (
            <a className="item" key={key} href={brand?.link} target="_blank">
              <Image
                width={100}
                height={100}
                src={STORAGE_URL + brand?.image}
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}
              />
            </a>
          ))}
        </OwlCarousel>
        {this.state.brands?.length === 0 && (
          <Typography variant="h5">Nenhuma marca encontrada</Typography>
        )}
      </Container>
    );
  }
}

export default Clientes;

// import { Card } from "@mui/material";
// import CarouselSlider from "components/CarouselSlider";
// import Image from "components/Image";
// import { brands } from "../Brands/data";

// const TopBrandsCarousel = (props) => {
//   return (
//     <CarouselSlider
//       loop={true}
//       swiperProps={{
//         loop: true,
//         autoplay: {
//           delay: 1000,
//           disableOnInteraction: false,
//         },
//         slidesPerView: 4,
//         breakpoints: {
//           1000: {
//             slidesPerView: 8,
//           },
//         },
//         pagination: false,
//         style: {
//           width: "100%",
//         },
//       }}
//       arrows={false}
//     >
//       {brands?.map((brand, key) => (
//         <a key={key} href={brand?.link} target="_blank">
//           <Image
//             width={100}
//             height={100}
//             src={brand?.image}
//             style={{
//               backgroundSize: "contain",
//               backgroundRepeat: "no-repeat",
//               backgroundPosition: "center center",
//             }}
//           />
//         </a>
//       ))}
//     </CarouselSlider>
//   );
// };

// export default TopBrandsCarousel;

import {
  Snackbar,
  Box,
  alpha,
  Toolbar,
  Typography,
  Button,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import { motion, AnimatePresence } from "framer-motion";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Portal from "components/Portal";
import { setForcedRole } from "actions/admin.actions";
import { useHistory } from "react-router";

const innerTheme = createTheme({
  palette: {
    primary: {
      main: grey["900"],
    },
  },
});

const ForcedRoleAlert = () => {
  const forcedRole = useSelector((store) => store.AdminReducer.forcedRole);

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <AnimatePresence>
      {forcedRole && (
        <Portal id={"forced-role-alert"}>
          <ThemeProvider theme={innerTheme}>
            <Box
              position={"fixed"}
              top={'0.8rem'}
              right={0}
            //   width={"100%"}
              zIndex={1499}
            >
              <Toolbar />
              <Box
                width={"100%"}
                bgcolor={alpha("rgb(0, 0, 0)", 0.8)}
                color="white"
                display={"flex"}
                gap={'0.8rem'}
                alignItems={"center"}
                justifyContent={"space-between"}
                padding={"0.2rem 0.2rem 0.2rem 0.8rem"}
                borderRadius={"0.4rem"}
                overflow={"hidden"}
                component={motion.div}
              >
                <Typography variant={"p"}>Vendo como <b>{forcedRole}</b></Typography>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    dispatch(setForcedRole(null));
                    history.push("/dashboard");
                  }}
                  sx={{
                    display: 'flex',
                    gap: '0.4rem',
                    backgroundColor: '#b34840'
                  }}
                >
                  Sair <ExitToAppIcon />
                </Button>
              </Box>
            </Box>
          </ThemeProvider>
        </Portal>
      )}
    </AnimatePresence>
  );
};

export default ForcedRoleAlert;

import { getContrastText } from "utils/Themes/getContrastText";
import { isColor } from "utils/Themes/isColor";
import { STORAGE_URL } from "variables";

export const palette = (data) => ({
  type: "palette",
  generalStyle: data?.generalStyle,
  pagesStyle: data?.pagesStyle,
});

export const generalStyleAction = (data) => ({
  type: "palette",
  payload: {
    primary: {
      main: data?.primary_color,
    },
    secondary: {
      main: data?.secondary_color,
    },
    tertiary: {
      main: data?.tertiary_color,
    },
  },
});

export const siteSettingsAction = (data) => ({
  type: "siteSettings",
  settings: {
    title: data?.title,
    icon: data?.icon,
    headerIcon: data?.header_icon,
    smallerHeaderIcon: data?.header_icon_small,
  },
});

export const pagesStyleAction = (data) => ({
  type: "pagesThemes",
  payload: {
    header: {
      data: {
        background_color_1: data?.header?.background_color_1 || "#3884be",
        background_color_2: data?.header?.background_color_2  || "#7a5db4",
        font_color: data?.header?.font_color  || "#ffffff",
        header_logo: data?.header?.header_logo  || "",
      },
    },
    login: {
      data: {
        title: data?.login?.title,
        subtitle: data?.login?.subtitle,
        text: data?.login?.text,
        section_main_color: data?.login?.section_main_color,
        section_left_color: data?.login?.section_left_color,
        section_right_color: data?.login?.section_right_color,
        input_color: data?.login?.input_color,
        input_bg_color: data?.login?.input_bg_color,
        accent_color: data?.login?.accent_color,
        background_image:
          data?.login?.background_image !== null &&
          `url(${STORAGE_URL}${data?.login?.background_image})`,
        font_color: data?.login?.font_color,
      },
    },
    register: {
      data: {
        title: data?.register?.title,
        subtitle: data?.register?.subtitle,
        text: data?.register?.text,
        section_main_color: data?.register?.section_main_color,
        section_left_color: data?.register?.section_left_color,
        section_right_color: data?.register?.section_right_color,
        input_color: data?.register?.input_color,
        input_bg_color: data?.register?.input_bg_color,
        accent_color: data?.register?.accent_color,
        background_image:
          data?.register?.background_image !== null &&
          `url(${STORAGE_URL}${data?.register?.background_image})`,
        font_color: data?.register?.font_color,
      },
    },
    mural_cursos: {
      data: {
        background_color: data?.mural_cursos?.background_color,
        font_color: data?.mural_cursos?.font_color,
      },
    },
  },
});


import React, { Component, useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// Destinos
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdPhoneLocked, MdReply, MdSearch } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { maskCep, maskPrice } from '../../../Auxiliar/Masks';
import DefaultButton from '../../../components/DefaultButton';

const CriarPolo = () => {

    const token = useSelector(state => state.AppReducer.token);
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const [loading_screen, setLoading_screen] = useState(false);
    const [loading_save, setLoading_save] = useState(false);
    const [loading_search, setLoading_search] = useState(false);
    const [path, setPath] = useState('/polos');
    const [msgErro, setMsgErro] = useState('');
    const [search, setSearch] = useState('');
    const [instituicao, setInstituicao] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [numero, setNumero] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [curso, setCurso] = useState('');
    const [cursos_inseridos, setCursos_inseridos] = useState([]);
    const [curso_selecionado, setCurso_selecionado] = useState({});
    const [cursos, setCursos] = useState([]);
    const [preco, setPreco] = useState('');
    const [turnos, setTurnos] = useState([]);
    const [turno_select, setTurnoSelect] = useState('');

    const [bolsistas, setBolsistas] = useState('');
    const [show_warning, setShow_warning] = useState(false);
    const [show_erro, setShow_erro] = useState(false);


    const GetCep = (cep) => {
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                setLogradouro(resp.logradouro);
                setBairro(resp.bairro);
                setCidade(resp.localidade);
                setEstado(resp.uf);
                if (resp.logradouro == '') {
                    document.getElementById('rua').focus();
                } else {
                    document.getElementById('numero').focus();
                }
                if (resp.hasOwnProperty("erro")) {
                    setMsgErro('O CEP digitado não foi encontrado, tente novamente.');
                    setCep('');
                    document.getElementById('cep').focus();
                }
            } catch (err) {
                console.log(err);
                setMsgErro('Algo deu errado. Seu CEP está correto?');
            }
        })
            .catch((err) => {
                console.log(err);
                setMsgErro('Erro ao pegar resposta do servidor. Você está conectado a internet?');
            });
    }
    const Salvar = () => {

        console.log(token)
        setLoading_save(true);
        setMsgErro('');
        let cursos = [];
        for (let i = 0; i < cursos_inseridos.length; i++) {
            let curso = { ...cursos_inseridos[i] };
            curso.turnos = curso.turnos.join(',');
            cursos.push(curso);
        }
        fetch(`${URL}api/store_polo`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                instituicao: instituicao,
                cep: cep,
                logradouro: logradouro,
                numero: numero,
                bairro: bairro,
                complemento: complemento,
                cidade: cidade,
                estado: estado,
                cursos: cursos,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    setLoading_save(false);
                    setMsgErro(erro);
                }
                else {
                    setLoading_save(false);
                    setRedirect(true);
                }
            } catch (err) {
                console.log(err);
                setLoading_save(false);
                setMsgErro('Erro ao pegar resposta do servidor');

            }

        })
            .catch((err) => {
                console.log(err);
                setMsgErro('Erro ao pegar resposta do servidor. Você está conectado a internet?');
            });
    }
    const Get_data = () => {
        setLoading_screen(true);
        fetch(`${URL}api/get_data_polo`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    setRedirect(true);
                }
                else {
                    setLoading_search(false);
                    setLoading_screen(false);
                    setCursos(resp.cursos);
                }

            } catch (err) {
                setRedirect(true);
                console.log(err);
            }
        })
            .catch((err) => {
                console.log(err);
                setLoading_screen(false);
                setRedirect(true);
            });
    }

    useEffect(() => {
        Get_data();
    }, [])

    return (
        <div className="row">
            {redirect == true && <Redirect to={path} />}
            <div className="col-12">
                <div className="card">
                    <div className="card-body" id="card">


                        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar polo</h4>
                        <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre os polos do seu sistema</p>
                        <br />

                        {/* <hr /> */}
                        {loading_screen == false &&
                            <div>{msgErro != '' && <div>
                                <div className="alert alert-danger alert-dismissible mb-0" role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMsgErro('')}></button>
                                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{msgErro}</p>
                                </div>
                                <br />
                            </div>}

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Instituição <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome da instituição de ensino" value={instituicao} type="text" onChange={(e) => { setInstituicao(e.target.value) }} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">CEP <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" id="cep" placeholder="CEP da instituição" value={cep} type="text" onChange={(e) => {
                                                    setCep(maskCep(e.target.value));
                                                    let text = e.target.value.replace('-', '');
                                                    if (text.length == 8) {
                                                        GetCep(text);
                                                    }
                                                }} />
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Logradouro <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" id="rua" placeholder="Logradouro da instituição" type="text" onChange={(e) => setLogradouro(e.target.value)} value={logradouro} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Número </label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" id="numero" placeholder="Número do endereço da instituição" type="text" onChange={(e) => setNumero(e.target.value)} value={numero} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Bairro <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Nome do bairro" type="text" onChange={(e) => setBairro(e.target.value)} value={bairro} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Complemento</label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Complemento" type="text" onChange={(e) => setComplemento(e.target.value)} value={complemento} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-10 col-12">
                                                        <label className="col-sm-12 col-form-label">Cidade <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" disabled placeholder="Nome da cidade" type="text" onChange={(e) => setCidade(e.target.value)} value={cidade} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 col-12">
                                                        <label className="col-sm-12 col-form-label">Estado<b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" disabled placeholder="UF" type="text" onChange={(e) => setEstado(e.target.value)} value={estado} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="col-md-12 col-12">
                                                    <label className="col-sm-12 col-form-label">Cursos <b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-12">
                                                        <select value={curso} className="form-select" onChange={(e) => {
                                                            setCurso(e.target.value);
                                                            if (e.target.value != '') {
                                                                let curso = cursos.filter((item) => item.id == e.target.value);
                                                                let verifica_curso = false;
                                                                if (cursos_inseridos.length > 0) {
                                                                    for (let i = 0; i < cursos_inseridos.length; i++) {

                                                                        if (e.target.value == cursos_inseridos[i].curso_id) {
                                                                            verifica_curso = true;
                                                                            break;
                                                                        }
                                                                    }
                                                                }
                                                                if (verifica_curso == false) {
                                                                    console.log(curso);
                                                                    setCurso_selecionado(...curso);
                                                                } else { setCurso(''); }
                                                            }

                                                        }}>
                                                            <option value={''}>Selecione um ou mais cursos</option>
                                                            {cursos.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {curso_selecionado.nome != undefined && <>

                                            <div className="form-group  row">
                                                <div className=" col-sm-12">
                                                    {/* <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Nível<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="col-sm-12">
                                                            <select value={cidade} className="form-select" onChange={(e) => { setCidade(e.target.value) }}>
                                                                <option value={'Graduação'}>Graduação</option>
                                                                <option value={'Técnico'}>Técnico</option>
                                                            </select>                                                              </div>
                                                        </div> */}
                                                    <div className="col-md-12 col-12">
                                                        <label className="col-sm-12 col-form-label">Preço de inscrição <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Valor do curso" value={maskPrice(preco)} type="text" onChange={(e) => { setPreco(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" col-sm-12">
                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Turnos <b style={{ color: 'red' }}>*</b></label>
                                                            <div className="col-sm-12">
                                                                <select value={turno_select} className="form-select" onChange={(e) => {
                                                                    console.log(e);
                                                                    console.log(turnos);
                                                                    if (!turnos.includes(e.target.value) && e.target.value != '') {
                                                                        setTurnos([...turnos, e.target.value]);
                                                                    }
                                                                    setTurnoSelect('')
                                                                }}>
                                                                    <option value={''}>Selecione um turno</option>
                                                                    <option value={'Manhã'}>Manhã</option>
                                                                    <option value={'Tarde'}>Tarde</option>
                                                                    <option value={'Noite'}>Noite</option>
                                                                </select>
                                                            </div>
                                                            <div className="row" style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                                                                {turnos.map((item, id) => (
                                                                    <div key={id} style={{ background: '#f0f0f0', borderRadius: 10, padding: 10, width: 'fit-content', display: 'flex', alignItems: "center", marginRight: 10 }}>
                                                                        <p style={{ marginBottom: 0, color: 'black' }}>{item}</p>
                                                                        <button type="button" style={{ width: 10, height: 10, padding: 0, boxSizing: 'unset', marginLeft: 10 }} className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => {
                                                                            setTurnos(turnos.filter((item2, id2) => item != item2));
                                                                        }}></button>

                                                                    </div>
                                                                ))}
                                                            </div>

                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Dísponivel para bolsistas <b style={{ color: 'red' }}>*</b></label>
                                                            <div className="col-sm-12">
                                                                <select value={bolsistas} className="form-select" onChange={(e) => { setBolsistas(e.target.value) }}>
                                                                    <option value={''}>Selecione uma opção</option>
                                                                    <option value={'Sim'}>Sim</option>
                                                                    <option value={'Não'}>Não</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <DefaultButton
                                                    type="button"
                                                    bg="confirm"
                                                    text="Inserir"
                                                    onClick={() => {
                                                        let curso = cursos.filter((item) => item.id == curso_selecionado.id);
                                                        console.log(curso)
                                                        let new_array = {
                                                            curso_id: curso[0].id,
                                                            curso: curso[0],
                                                            nome: curso[0].nome,
                                                            nivel: curso[0].nivel,
                                                            preco: preco.replace('R$ ', '').replace(/\./g, '').replace(',', '.'),
                                                            turnos: turnos,
                                                            bolsistas: bolsistas,
                                                        }
                                                        let inseridos = cursos_inseridos;
                                                        inseridos.push(new_array);
                                                        console.log(inseridos);
                                                        setCurso_selecionado({});
                                                        setCurso('');
                                                        setBolsistas('');
                                                        setTurnos([]);
                                                        setPreco('');
                                                    }}
                                                />
                                            </div>
                                        </>
                                        }

                                        {cursos_inseridos.length > 0 && <>
                                            <hr />
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Cursos selecionados:</label>
                                                <table id="tabela" className="table table-striped" >
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Nível</th>
                                                            <th>Valor</th>
                                                            <th>Turnos</th>


                                                            <th data-priority="3">Excluir</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cursos_inseridos.map((item, id) => (
                                                            <tr key={id}>
                                                                <td>{item.nome}</td>
                                                                <td>{item.nivel}</td>
                                                                <td>{maskPrice(item.preco)}</td>

                                                                <td>{item.turnos.join(', ')}</td>


                                                                <td>
                                                                    <DefaultButton
                                                                        onClick={() => {
                                                                            let edit = cursos_inseridos.filter((item2) => item2.curso_id == item.curso_id)[0];

                                                                            setBolsistas(edit.bolsistas);
                                                                            setTurnos(edit.turnos);
                                                                            setPreco(maskPrice(edit.preco));
                                                                            setCurso_selecionado(edit.curso);
                                                                            setCurso(edit.curso.id);

                                                                            let new_array = cursos_inseridos.filter((item2) => item2.curso_id != item.curso_id);
                                                                            setCursos_inseridos(new_array);
                                                                        }}
                                                                        className='me-1'

                                                                        width="2.2em"
                                                                        height="2.2em"
                                                                        padding={0}
                                                                        title={`Editar`}
                                                                        bg="info"
                                                                        icon={<MdModeEdit size={17} olor="white" />}
                                                                    />
                                                                    <DefaultButton
                                                                        onClick={() => {
                                                                            let new_array = cursos_inseridos.filter((item2) => item2.curso_id != item.curso_id);
                                                                            setCursos_inseridos(new_array);
                                                                        }}
                                                                        style={{ border: "1px solid red" }}
                                                                        className='btn btn-danger btn-outline btn-circle'
                                                                        width="2.2em"
                                                                        height="2.2em"
                                                                        padding={0}
                                                                        title={`Excluir`}
                                                                        bg="white"
                                                                        icon={<FaTrash size={17} color="red" />}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        }

                                    </div>
                                </div>


                                {/*-------------- loadings -------------*/}
                                {loading_save == false &&
                                    <div className="row mt-3">
                                        <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start" style={{ marginBottom: '0.5rem' }}>
                                            <Link to="/polos" style={{ textDecoration: 'none' }}>
                                                <DefaultButton
                                                    type="submit"
                                                    bg="secondary"
                                                    text="Voltar"
                                                    icon={<MdReply color="white" />}
                                                />
                                            </Link>
                                        </div>
                                        <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end" style={{ marginBottom: '0.5rem' }}>
                                            <DefaultButton
                                                type="submit"
                                                bg="confirm"
                                                text="Salvar polo"
                                                loadingtext="Salvando polo"
                                                onClick={() => Salvar()}
                                            />
                                        </div>

                                    </div>}

                                {loading_save == true &&
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                            <div className="spinner-border text-primary" role="status" ></div>
                                        </div>
                                    </div>}
                            </div>}
                        {loading_screen == true &&
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status" ></div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default CriarPolo;



import BemVindoEdit from "./BemVindo";
import AulasGratuitas from "./AulasGratuitas/List/Table";
import BannerEdit from "./Banner/List/Table";
import DepoimentosEdit from "./Depoimentos";
import ColorInput from "components/Inputs/ColorInput";
import { Button } from "@mui/material";
import { Post } from "utils/request";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import AppAd from "./AppAd";
import { useLocation } from "react-router";
import BottomLead from "./BottomLead";
import AsideBannerList from "./AulasGratuitas/AsideBanner/List";
import { PAGE_KEYS } from "variables";
import { FormLabel } from "react-bootstrap";
import { useEffect } from "react";
import ImageInput from "components/Inputs/ImageInput";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const JoditConfig = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarInlineForSelection: true,
  showPlaceholder: false,
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "font",
    "fontsize",
    "brush",
  ],
  height: 100,
  minHeight: 100,
  readonly: false,
  defaultLanguage: "pt_br",
  language: "pt_br",
  toolbarAdaptive: false,
};

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  maxWidth: "100%",
  minWidth: "36rem",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
};

const text_container = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  position: "relative",
};

const text_style = {
  fontSize: "1.5rem",
  fontWeight: "bold",
  position: "absolute",
  margin: 0,
  padding: "0 1rem",
  backgroundColor: "white",
  zIndex: 10,
};

const text_comment = {
  fontSize: "1rem",
  textAlign: "center",
};

function RenderSectionHeader({ title, comment }) {
  // render a header for the section
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "2rem",
      }}
    >
      <div style={text_container}>
        <h4 style={text_style}>{title}</h4>
        <hr style={{ width: "100%" }} />
      </div>
      <span style={text_comment}>{comment}</span>
    </div>
  );
}

const FormPaginaInicial = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    setSaveButton,
    headerColor1,
    headerColor2,
    headerFontColor,
    headerLogo,
    setHeaderColor1,
    setHeaderColor2,
    setHeaderFontColor,
    setHeaderLogo,
    section_1,
    section_4,
    handleOpenLoadingModal,
    handleCloseLoadingModal,
  } = props;

  const Submit = async () => {
    handleOpenLoadingModal();
    let form = new FormData();
    form.append("page_key", PAGE_KEYS.header);
    form.append("page_path", location.pathname);
    form.append("type", "firstContent");
    form.append("background_color_1", headerColor1);
    form.append("background_color_2", headerColor2);
    form.append("font_color", headerFontColor);
    form.append("header_logo", headerLogo);
    let response = await Post("styles/pages/update", form);
    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );
    handleCloseLoadingModal();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {RenderSectionHeader({
        title: "Cores da barra de navegação",
        comment: "As cores que serão utilizadas na barra de navegação.",
      })}
      <FormLabel>
        Cor do lado esquerdo
        <ColorInput
          value={headerColor1}
          onChange={(color) => color !== undefined && setHeaderColor1(color)}
        />
      </FormLabel>
      <FormLabel>
        Cor do lado direito
        <ColorInput
          value={headerColor2}
          onChange={(color) => color !== undefined && setHeaderColor2(color)}
        />
      </FormLabel>
      <FormLabel>
        Cor da fonte da barra de navegação
        <ColorInput
          value={headerFontColor}
          onChange={(color) => color !== undefined && setHeaderFontColor(color)}
        />
      </FormLabel>

      <FormLabel>
        Logotipo
        <ImageInput
          path={headerLogo}
          ImageChange={(image) => setHeaderLogo(image)}
        />
      </FormLabel>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanho recomendado:
        800x380
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
        .jpeg, .png e .gif.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo.
      </span>

      <Button
        variant="contained"
        color="success"
        onClick={Submit}
        sx={{ width: "max-content" }}
      >
        Salvar alterações
      </Button>

      {RenderSectionHeader({
        title: "Banner - Seção 1",
        comment: "Banner da primeira seção da página inicial.",
      })}
      <BannerEdit
        {...{
          section_1,
          modal_style,
          setSaveButton,
          handleOpenLoadingModal,
          handleCloseLoadingModal,
        }}
      />

      {RenderSectionHeader({
        title: "Bem-vindo - Seção 2",
        comment:
          "As informações da seção 2 são as informações que aparecem na página inicial.",
      })}
      <BemVindoEdit {...{ ...props, JoditConfig, modal_style }} />

      {RenderSectionHeader({
        title: "Cursos gratuitos - Seção 3",
        comment:
          "As aulas gratuitas são as aulas que serão exibidas na seção 3 da página inicial.",
      })}
      <AulasGratuitas
        {...{
          ...props,
          JoditConfig,
          modal_style,
          handleOpenLoadingModal,
          handleCloseLoadingModal,
        }}
      />

      {RenderSectionHeader({
        title: "Banner lateral - Seção 3",
        comment: "Banners que serão exibidos ao lado das audições gratuitas.",
      })}
      <AsideBannerList
        {...{ props, handleOpenLoadingModal, handleCloseLoadingModal }}
      />

      {RenderSectionHeader({
        title: "Depoimentos - Seção 4",
        comment: "Os depoimentos são exibidos na seção 4 da página inicial.",
      })}
      <DepoimentosEdit {...{ props, section_4, JoditConfig, modal_style }} />

      {/* {RenderSectionHeader({
        title: "Aplicativo - Seção 5",
        comment:
          "As informações da seção 5 são as informações que aparecem no aplicativo da página inicial.",
      })}
      <AppAd {...{ ...props, JoditConfig, modal_style }} /> */}

      {RenderSectionHeader({
        title: "Lead - Seção 6",
        comment:
          "As informações da seção 5 são as informações que aparecem nos leads.",
      })}
      <BottomLead {...{ ...props, JoditConfig, modal_style }} />
    </div>
  );
};

export default FormPaginaInicial;

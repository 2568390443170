import { useState } from "react";
import { FormControl as Input } from "react-bootstrap";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";

import Table from "./Table";
import DefaultButton from "components/DefaultButton";

const AsideBannerList = () => {
  const [search, SetSearch] = useState("");

  const dispatch = useDispatch();

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="title">Banners da lateral</h3>
        <p className="text-muted">
          Aqui são listados todos os banners da lateral
        </p>
        <hr />
        <div className="flex jcsb flex-wrap margin-bottom">
          <Input
            placeholder="Pesquisar pelo Nome"
            value={search}
            onChange={(e) => SetSearch(e.target.value)}
            style={{ width: "50%" }}
          />
          <RoleBased>
            <DefaultButton
              bg="confirm"
              text="Adicionar novo banner"
              to="/site-rt/aside-banner/add"
            />
          </RoleBased>
        </div>
        <Table search={search} />
      </div>
    </div>
  );
};

export default AsideBannerList;

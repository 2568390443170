import { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { STORAGE_URL } from "variables";
import { fileToBase64 } from "utils/helpers";
import { useEffect } from "react";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Post } from "utils/request";

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  maxWidth: "100%",
  minWidth: "36rem",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
};

const TestimonialsCard = (props) => {
  const { index, testimonial, removeTestimonial, handleTestimonial } = props;

  // card info
  const [testimonial_image, setTestimonial_image] = useState({
    image_url: testimonial.image_url,
    image_file: null,
  });


  const [testimonial_name, setTestimonial_name] = useState(testimonial.name);
  const [testimonial_profession, setTestimonial_profession] = useState(
    testimonial.profession
  );
  const [testimonial_testimonial, setTestimonial_testimonial] = useState(
    testimonial.testimonial
  );

  const dispatch = useDispatch();

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditTestimonial = () => {
    handleTestimonial(index, {
      image_url: testimonial_image.image_url,
      image_file: testimonial_image.image_file,
      name: testimonial_name,
      profession: testimonial_profession,
      testimonial: testimonial_testimonial,
    });

    Edit();

    dispatch(
      Show({
        show: true,
        severity: "success",
        message: "Editado com sucesso",
      })
    );
    handleClose();
  };

  const handleAddImage = (file) => {
    fileToBase64(file).then((base64) => {
      setTestimonial_image({
        image_url: base64,
        image_file: file,
      });
    });
  };

  const Edit = async (data) => {
    let form = new FormData();
    if (testimonial_image.image_file) {
      form.append("image", testimonial_image.image_file);
    }
    form.append("id", testimonial.id);
    form.append("name", testimonial_name);
    form.append("name", testimonial_name);
    form.append("testimonial", testimonial_testimonial);
    form.append("profession", testimonial_profession);

    let response = await Post("site-rt/testimonial/alter", form);
    dispatch(Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
        buttonActionText: "Ver",
        // ButtonAction: () => history.push(`/courses/view/${response?.courseId}`)
    }));
}


  const checkIfisLocalImage = (image_url) => {
    if (image_url.includes("data:image")) {
      return image_url;
    } else {
      return `${STORAGE_URL}${image_url}`;
    }
  };

  const Delete = async () => {
    let form = new FormData();
    form.append("id", testimonial.id);

    let response = await Post("site-rt/testimonial/delete", form);
}

  const handleRemoveTestimonial = () => {
    if (testimonial.id) {
      Delete();
    }
    removeTestimonial(index);
  }

  return (
    <div
      style={{
        // flexBasis: "10rem",

        flexGrow: 1,
        maxWidth: "28rem",
        gap: "0.4rem",
        display: "flex",
        flexDirection: "column",
        background: "#f3f6f6",
        padding: "1rem",
        borderRadius: "1rem",
        position: "relative",
        overflow: "hidden",
        margin: "0.2rem 0",
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modal_style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              padding: "3rem 4rem",
            }}
          >
            <CloseIcon
              sx={{
                position: "absolute",
                top: "0.8rem",
                right: "0.8rem",
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <Typography variant="h5" style={{ marginBottom: "1rem" }}>
              Editar depoimento
            </Typography>

            <div
              style={{
                display: "flex",
                gap: "1rem",
                marginBottom: "1rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <label
                htmlFor="inputo-file"
                style={{
                  height: "6rem",
                  width: "6rem",
                  borderRadius: "1rem",
                  border: "0.2rem dashed #ccc",
                  cursor: "pointer",
                  display: "flex",
                  backgroundImage: `url(${checkIfisLocalImage(
                    testimonial_image.image_url
                  )})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <input
                  hidden
                  type="file"
                  id="inputo-file"
                  onChange={(e) => {
                    handleAddImage(e.target.files[0]);
                  }}
                />
                <AddPhotoAlternateIcon
                  sx={{
                    width: "100%",
                    height: "100%",
                    padding: "1.4rem",
                    margin: "auto",
                    color: "white",
                    opacity: 0.4,
                    filter: "drop-shadow(0 0 0.2rem black)",
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </label>

              <FormLabel>
                Nome <span style={{ color: "red" }}>*</span>
                <Input
                  value={testimonial_name}
                  onChange={(e) => setTestimonial_name(e.target.value)}
                  required
                />
              </FormLabel>
            </div>
            <span>Proporção da imagem: 1:1</span>
            <span style={{marginBottom: '0.8rem'}}>A imagem será centralizada automaticamente.</span>
            <FormLabel>
              Profissão <span style={{ color: "red" }}>*</span>
              <Input
                value={testimonial_profession}
                onChange={(e) => setTestimonial_profession(e.target.value)}
                required
              />
            </FormLabel>
            <FormLabel sx={{ width: "24rem" }}>
              Depoimento <span style={{ color: "red" }}>*</span>
              <Input
                value={testimonial_testimonial}
                onChange={(e) => setTestimonial_testimonial(e.target.value)}
                sx={{ width: "24rem" }}
                required
                as={"textarea"}
              />
            </FormLabel>
            <Button
              variant="contained"
              onClick={handleEditTestimonial}
              sx={{
                width: "max-content",
                alignSelf: "flex-end",
                marginTop: "1rem",
              }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            display: "flex",
            gap: "0.4rem",
          }}
        >
          <SettingsIcon
            sx={{
              padding: "0.2rem",
              fontSize: "1.6rem",
              backgroundColor: "white",
              borderRadius: "50%",
              cursor: "pointer",
              backgroundColor: "rgb(170, 170, 255)",
            }}
            onClick={handleOpen}
          />
          <CloseIcon
            sx={{
              padding: "0.2rem",
              fontSize: "1.6rem",
              backgroundColor: "white",
              borderRadius: "50%",
              cursor: "pointer",
              backgroundColor: "rgb(255, 170, 170)",
            }}
            onClick={() => {
              if (
                window.confirm(
                  "Tem certeza que deseja remover esse depoimento?"
                )
              ) {
                handleRemoveTestimonial()
              }
            }}
          />
        </div>

        <div>
          <img
            style={{
              width: "2.8rem",
              height: "2.8rem",
              borderRadius: "50%",
              backgroundPosition: "center",
              backgroundSize: "cover",
              boxShadow: "0 0 0 0.1rem rgba(105, 59, 159, 1)",
              objectFit: "cover",
            }}
            src={checkIfisLocalImage(testimonial.image_url)}
            alt=""
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{
              fontSize: "1.1rem",
              fontFamily: "var(--Freight)",
              fontWeight: "bold",
            }}
          >
            {testimonial.name}
          </span>
        </div>
      </div>

      <Typography
        variant="subtitle1"
        component="div"
        sx={{
          color: "black",
          whiteSpace: "wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        "{testimonial.testimonial}"
      </Typography>
    </div>
  );
};

export default TestimonialsCard;

import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import TextCard from "../../TextCard";

const WhatWillGet = ({ course }) => {
  const {
    temp_show_section5,
    whatWillGet,
    temp_section5_title,
    temp_section5_bgColor_1,
    temp_section5_bgColor_2,
    temp_section5_cardColor,

    section5_cardBorderColor,
    section5_cardBorderSize,
  } = course;

  const [whatWillGetParsed, setWhatWillGetParsed] = useState([]);

  useEffect(() => {
    let temp_whatWillGet = [];
    if (whatWillGet) {
      try {
        whatWillGet.forEach((item) => {
          temp_whatWillGet.push(JSON.parse(item));
        });
      } catch (error) {
        console.log(error)
      }
    }
    setWhatWillGetParsed(temp_whatWillGet);
  }, [whatWillGet]);
  

  return (
    <section
      style={{
        padding: "6rem 0",
        // minHeight: "100vh",
        justifyContent: "center",
        display: temp_show_section5 ? "flex" : "none",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        background: `linear-gradient(180deg, ${temp_section5_bgColor_1} 0%, ${temp_section5_bgColor_2} 100%)`,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ height: "100%!important", textAlign: "center" }}
      >
        <Typography
          sx={{
            marginBottom: "1rem",
          }}
          dangerouslySetInnerHTML={{ __html: temp_section5_title }}
        />

        <div
          style={{
            display: "flex",
            gap: "2rem",
            justifyContent: "center",
            // alignItems: "center",
            // flexWrap: "wrap",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {whatWillGetParsed !== undefined &&
            whatWillGetParsed !== null &&
            whatWillGetParsed.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  height: "min-content",
                  display: "flex",
                  flexDirection: {
                    xs: "column-reverse",
                    sm: "column-reverse",
                    md: index % 2 === 0 ? "row" : "row-reverse",
                  },
                  gap: "1rem",
                  borderRadius: "0.8rem",
                  overflow: "hidden",
                  backgroundColor: temp_section5_cardColor,
                  border: `${section5_cardBorderColor} solid ${section5_cardBorderSize}px`,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.8rem",
                    width: "100%",
                    padding: "1rem",
                    paddingLeft: "2rem",
                    // alignItems: index % 2 === 0 ? "flex-end" : "flex-start",
                    alignItems: {
                      xs: "center",
                      sm: "center",
                      md: index % 2 === 0 ? "flex-end" : "flex-start",
                    },
                    textAlign: "justify",
                    // direction: index % 2 === 0 ? "rtl" : "ltr",
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      width: "max-content",
                    }}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <Typography
                    component="div"
                    sx={
                      {
                        // width: "max-content",
                      }
                    }
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </Box>
                <div
                  style={{
                    height: "auto",
                    minHeight: "14rem",
                    backgroundImage: `url(${item.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                  }}
                />
              </Box>
            ))}
        </div>
      </Container>
    </section>
  );
};

export default WhatWillGet;

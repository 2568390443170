import { useRef, useState } from "react";
import { FormControl as Input } from "react-bootstrap";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";

import Table from "./Table";
import Modal from "./Modal";
import DefaultButton from "components/DefaultButton";

const EmailActivitiesList = () => {
  const [search, SetSearch] = useState("");
  const [modal, SetModal] = useState(false);

  const tableRef = useRef();

  const dispatch = useDispatch();

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="title">Registros de e-mail</h3>
        <p className="text-muted">
          Aqui são listados todos os registros feitos em um e-mail
        </p>
        <hr />
        <div className="flex jcsb flex-wrap margin-bottom">
          <Input
            placeholder="Pesquisar pelo Nome"
            value={search}
            onChange={(e) => SetSearch(e.target.value)}
            style={{ width: "50%" }}
          />
          <RoleBased>
            <DefaultButton
              bg="confirm"
              text="Adicionar novo registro"
              onClick={() => SetModal(true)}
            />
          </RoleBased>
        </div>
        <Table search={search} ref={tableRef} />
        <Modal
          open={modal}
          onSubmit={() => tableRef.current.GetData()}
          onClose={() => SetModal(false)}
        />
      </div>
    </div>
  );
};

export default EmailActivitiesList;

import { FormLabel, FormControl as Input } from "react-bootstrap";
import { useEffect, useRef } from "react";
import { useState } from "react";
import JoditEditor from "jodit-react";
import ColorInput from "components/Inputs/ColorInput";
import { moneyMask } from "utils/moneyMask";
import Label from "components/InfoLabel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import ImageInput from "components/Inputs/ImageInput";

const Section5 = ({
  isFree,
  SetIsFree,

  price,
  SetPrice,
  description,
  SetDescription,
  description_image,
  SetDescriptionImage,

  installments_quantity,
  SetInstallmentsQuantity,

  installments_price,
  SetInstallmentsPrice,

  installments_details,
  SetInstallmentsDetails,

  customer_discount_price,
  SetCustomerDiscountPrice,

  section5_text1,
  SetSection5Text1,
  section5_text2,
  SetSection5Text2,

  section5_bgColor_1,
  SetSection5BgColor1,
  section5_bgColor_2,
  SetSection5BgColor2,

  section5_accentColor,
  SetSection5AccentColor,

  section5_textColor,
  SetSection5TextColor,

  JoditConfig,
}) => {
  const [temp_price, SetTempPrice] = useState(null);
  const priceFormRef = useRef(null);

  const handlePriceChange = (e) => {
    SetTempPrice(moneyMask(e.target.value));
    SetPrice(moneyMask(e.target.value));
  };

  const [temp_installmentsPrice, SetTempInstallmentsPrice] = useState(null);

  const handleInstallmentsPriceChange = (e) => {
    SetTempInstallmentsPrice(moneyMask(e.target.value));
    SetInstallmentsPrice(moneyMask(e.target.value));
  };

  const [temp_customer_discount_price, SetCustomer_discount_price] =
    useState(null);

  const handleCustomerDiscoutPriceChange = (e) => {
    SetCustomer_discount_price(moneyMask(e.target.value));
    SetCustomerDiscountPrice(moneyMask(e.target.value));
  };

  useEffect(() => {
    SetTempPrice(price);
    SetTempInstallmentsPrice(installments_price);
    SetCustomer_discount_price(customer_discount_price);
  }, [price, installments_price, customer_discount_price]);

  return (
    <div
      style={{
        marginTop: "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <FormLabel>
        Descrição do curso <span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={description}
          onChange={(value) => SetDescription(value)}
        />
      </FormLabel>

      <FormLabel>
        Imagem de descrição
        <ImageInput
          path={description_image}
          ImageChange={(image) => SetDescriptionImage(image)}
        />
      </FormLabel>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos recomendados:
        1920x1080 (16:9), 1600x900 (16:9) ou 1280x720 (16:9).
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
        .jpeg, .png e .gif.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo.
      </span>

      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={isFree}
              onClick={() => {
                SetIsFree(!isFree);
              }}
            />
          }
          label="Curso gratuito"
        />
      </FormGroup>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Ao ativar essa opção, a
        seção de preço será ocultada e o curso será gratuito.
      </span>

      <div
        ref={priceFormRef}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6rem",
          overflow: "hidden",
          maxHeight: isFree ? "0" : priceFormRef.current?.scrollHeight + "px",
          transition: "1s ease",
        }}
      >
        <FormLabel>
          <Input
            value={section5_text1}
            onChange={(e) => SetSection5Text1(e.target.value)}
            required
          />
        </FormLabel>

        <FormLabel>
          Parcelamento<span style={{ color: "red" }}>*</span>
          <div style={{ display: "flex" }}>
            <Input
              type="number"
              placeholder="Parcelas"
              required
              value={installments_quantity}
              onChange={(e) => SetInstallmentsQuantity(e.target.value)}
            />

            <span style={{ fontSize: "1.6rem", margin: "0 0.6rem" }}>X</span>

            <Input
              placeholder="Preço"
              value={temp_installmentsPrice}
              onChange={handleInstallmentsPriceChange}
              required
            />
          </div>
        </FormLabel>

        <FormLabel>
          <Input
            value={section5_text2}
            onChange={(e) => SetSection5Text2(e.target.value)}
            required
          />
        </FormLabel>

        <FormLabel>
          Preço à vista<span style={{ color: "red" }}>*</span>
          <Input
            placeholder="Preço"
            value={temp_price}
            onChange={handlePriceChange}
            required
          />
        </FormLabel>

        <FormLabel>
          Fórmula de cálculo das parcelas{" "}
          <span style={{ color: "red" }}>*</span>
          <JoditEditor
            config={JoditConfig}
            value={installments_details}
            onChange={(value) => SetInstallmentsDetails(value)}
          />
        </FormLabel>

        <FormLabel>
          <Label
            label={<span>Preço para alunos</span>}
            title="Preço com desconto para alunos."
          />
          <Input
            placeholder="Preço"
            value={temp_customer_discount_price}
            onChange={handleCustomerDiscoutPriceChange}
            required
          />
        </FormLabel>

        <FormLabel>
          <Label
            label={<span>Cor de fundo 1 </span>}
            required
            title="Esta cor será aplicada no fundo da seção."
          />
        </FormLabel>
        <ColorInput
          value={section5_bgColor_1}
          onChange={(color) =>
            color !== undefined && SetSection5BgColor1(color)
          }
        />

        <FormLabel>
          <Label
            label={<span>Cor de fundo 2 </span>}
            required
            title="Esta cor será aplicada no fundo da seção. Se for definida, um gradiente será aplicado baseado na cor 1."
          />
        </FormLabel>
        <ColorInput
          value={section5_bgColor_2}
          onChange={(color) =>
            color !== undefined && SetSection5BgColor2(color)
          }
        />

        <FormLabel>
          <Label
            label={<span>Cor de destaque</span>}
            required
            title="Esta cor será aplicada no texto de destaque da seção."
          />
        </FormLabel>
        <ColorInput
          value={section5_accentColor}
          onChange={(color) =>
            color !== undefined && SetSection5AccentColor(color)
          }
        />

        <FormLabel>
          <Label
            label={<span>Cor do texto</span>}
            required
            title="Esta cor será aplicada no texto da seção."
          />
        </FormLabel>
        <ColorInput
          value={section5_textColor}
          onChange={(color) =>
            color !== undefined && SetSection5TextColor(color)
          }
        />
      </div>
    </div>
  );
};

export default Section5;

import React, { useEffect, useState } from "react";
import { Get } from "../../../../utils/request";
import { useSelector } from "react-redux";
import Board from "./Card";
import SkeletonCardList from "modules/LoadingPlaceholders/Skeletons/SkeletonCardList";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { Carousel } from "react-responsive-carousel";

// mui icons
import { Box, Container, Typography } from "@mui/material";
import { STORAGE_URL } from "variables";

const DisplayCoursesPreview = ({ banners }) => {
  const [sliderRef] = useKeenSlider(
    {
      breakpoints: {
        "(min-width: 0px)": {
          slides: { perView: 1 },
        },
        "(min-width: 940px)": {
          slides: { perView: 2 },
        },
        "(min-width: 1000px)": {
          slides: { perView: 2 },
        },
        "(min-width: 1380px)": {
          slides: { perView: 3 },
        },
        "(min-width: 1700px)": {
          slides: { perView: 4 },
        },
        "(min-width: 2200px)": {
          slides: { perView: 5 },
        },
      },
      slides: {
        origin: "center",
        // perView: 1,
        // spacing: 10,
      },
      loop: true,
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 5000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );
  const [mui_theme, setMuiTheme] = useState({});
  const [listed_courses, setListedCourses] = useState([]);
  const [categories, setCategories] = useState([]);

  const userId = useSelector((store) => store.AppReducer.user.id);
  const loginTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.mural_cursos
  );

  useEffect(() => {
    setMuiTheme(loginTheme?.palette?.data);
  }, [loginTheme]);

  const [isLoading, setLoading] = useState(true);

  const getCategories = async () => {
    await Get(`courses-main/category?userId=${userId}`).then((res) => {
      setCategories(res.categories);
      setLoading(false);
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getMyList = async () => {
    await Get(`user-list-course?userId=${userId}`).then((res) => {
      console.log(res);
      let temp_list = [];
      res?.list.map((course) => {
        temp_list.push(course.course_id);
      });
      setListedCourses(temp_list);
      console.log(temp_list);
    });
  };

  useEffect(() => {
    getMyList();
  }, []);

  if (isLoading)
    return (
      <Container maxWidth="xl" sx={{ padding: "0", paddingTop: "2rem" }}>
        <SkeletonCardList />
      </Container>
    );

  return (
    !isLoading && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          backgroundColor: mui_theme.background_color,
          minHeight: "100vh",
          paddingTop: "2rem",
        }}
      >
        <div
          style={{
            width: "47%",
            position: "relative",
            zIndex: 20,
          }}
        >
          {banners?.length > 0 && (
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              renderIndicator={false}
              centerMode={false}
              showThumbs={false}
              transitionTime={500}
              interval={5000}
              showStatus={false}
            >
              {banners &&
                banners.map((banner, index) => (
                  <a
                    key={banner.id}
                    href={banner.link}
                    target="_blank"
                    style={{
                      zIndex: 20,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "30vh",
                        backgroundSize: "contain",
                        backgroundImage: `url(${STORAGE_URL + banner.image})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  </a>
                ))}
            </Carousel>
          )}
        </div>

        {categories?.map((category, index) => {
          return (
            category?.courses?.length !== 0 && (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0.8rem",
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    display: "flex",
                    gap: "0.2rem",
                    alignItems: "center",
                    fontWeight: "bold",
                    paddingLeft: "2rem",
                    color: mui_theme.font_color,
                  }}
                >
                  {category.name}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    // justifyContent: "space-between",
                  }}
                >
                  <div
                    ref={sliderRef}
                    className="keen-slider"
                    style={{
                      overflowY: "visible",
                      padding: "1.4rem 0",
                    }}
                  >
                    {category?.courses?.map((course, index2) => {
                      if (course?.lessons?.length !== 0)
                        return (
                          <div
                            className="keen-slider__slide"
                            style={{
                              overflow: "visible",
                            }}
                            key={index2}
                          >
                            <Board
                              {...course}
                              isUserIn={course?.usercourse !== null}
                              // GetData={refetch}
                              fontColor={mui_theme.font_color}
                              listed_courses={listed_courses}
                            />
                          </div>
                        );
                    })}
                  </div>
                </div>
              </div>
            )
          );
        })}
      </div>
    )
  );
};

export default DisplayCoursesPreview;

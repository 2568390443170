import React, { forwardRef, useState } from "react";
import { ColorPicker, ColorInput as Cp } from "mui-color";

import "./index.css";

const ColorInput = forwardRef((props, ref) => {
  const { value, onChange = () => {}, ...others } = props;

  const [color_obj, setColorObj] = useState(null);

  return (
    <div className="flex fdrow" style={{ position: "relative" }}>
      <ColorPicker
        value={color_obj ? color_obj : value}
        onChange={(value) => {
          setColorObj(value);
          onChange(value.css.backgroundColor);
        }}
        style={{
          backgroundColor: "#e1e1e1",
        }}
      />
    </div>
  );
});

export default ColorInput;

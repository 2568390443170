
import React, { Component, useEffect, useState, useRef } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// Destinos
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdPhoneLocked, MdSearch, MdVisibility } from 'react-icons/md';
import DefaultButton from '../../../components/DefaultButton';
import Registrations from './Registrations';
import TabPanel from "../../../components/TabPanel";
import { CircularProgress, Snackbar, Tab, Tabs } from "@mui/material";
import { useHistory, useLocation } from "react-router";

const RegistrationIndex = () => {
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        let tab = new URLSearchParams(location.search).get('tab');
        if (tab !== undefined && tab !== null) setActiveTab(parseInt(tab));
        else if (tab === null) setActiveTab(parseInt(0));
    }, []);

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body" id="card">

                        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Lista de inscrições</h4>
                        <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os inscrições do seu sistema</p>
                        <br />
                        <br />

                        {/* <hr /> */}
                        <Tabs
                            value={activeTab}
                            onChange={(_, value) => {
                                setActiveTab(value);
                                history.push({ search: `?tab=${value}` });
                            }}
                            style={{ backgroundColor: "#ececf1" }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant={"scrollable"}
                            scrollButtons={"on"}

                        >
                            <Tab label="Todas Inscrições" />
                            <Tab label="Inscrições Pendentes" />
                            <Tab label="Inscrições Pagas" />
                        </Tabs>
                        <TabPanel value={activeTab} index={0}>
                            <Registrations filter={'all'} tab={0} />
                        </TabPanel>
                        <TabPanel value={activeTab} index={1}>
                            <Registrations filter={'pending'} tab={1} />
                        </TabPanel>
                        <TabPanel value={activeTab} index={2}>
                            <Registrations filter={'paid'} tab={2} />
                        </TabPanel>

                    </div>

                </div>
            </div>
        </div>
    );
}


export default RegistrationIndex;



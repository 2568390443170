import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import TextCard from "../../TextCard";

const RecommendedTo = ({ props }) => {
  const {
    show_section4,
    recommendedTo,
    section4_title,
    section4_bgColor_1,
    section4_bgColor_2,
  } = props;

  return (
    show_section4 && (
      <section
        style={{
          padding: "6rem 0",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          background: `linear-gradient(180deg, ${section4_bgColor_1} 0%, ${section4_bgColor_2} 100%)`,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Seção 3
        </Typography>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "4rem",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginBottom: "1rem",
            }}
            dangerouslySetInnerHTML={{ __html: section4_title }}
          />

          <Typography
            variant="h2"
            sx={{
              marginBottom: "1rem",
            }}
            dangerouslySetInnerHTML={{ __html: recommendedTo }}
          />

          {/* <div
            style={{
              display: "flex",
              gap: "2rem",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {recommendedTo !== undefined &&
              recommendedTo !== null &&
              recommendedTo.map((user, index) => (
                <TextCard
                  key={index}
                  text={user["name"]}
                />
              ))}
          </div> */}
        </Container>
      </section>
    )
  );
};

export default RecommendedTo;

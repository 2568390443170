import { Avatar, Card, CardContent, CardHeader, Typography } from "@mui/material";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { STORAGE_URL } from "variables";

import styles from "./index.module.css";

const TestimonialCard = props => {

    const {
        text = "",
        image,
        profession = "",
        ...other
    } = props;

    return (
        <Card
            className={styles.card}
            {...other}
        >
            <Avatar
                className={styles.avatar}
                src={image ? `${STORAGE_URL + image}` : null}
            />
            <CardHeader></CardHeader>
            <CardContent>
                <FormatQuoteIcon
                    className={styles.quotes}
                />
                <Typography
                    align="center"
                    className={styles.text}
                >
                    {text}
                </Typography>
                <hr/>
                <Typography
                    align="center"
                    className={styles.text}
                >
                    {profession}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default TestimonialCard;
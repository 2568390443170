import { ButtonBase, Fab, Typography } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Question from "./Question";

const FormMaker = forwardRef((props, ref) => {

    const {
        prevQuestions,
        children,
        onChange = () => {}
    } = props;

    const [questions, SetQuestions] = useState([]);
    const [options, SetOptions] = useState([]);
    const [lastId, SetLastId] = useState(0);

    useImperativeHandle(ref, () => ({
        QuestionIncrement() {HandleQuestionIncrement()}
    }));

    const HandlePrevQuestions = () => {
        let tempQuestions = [];
        let tempOptions = [];
        for (let i = 0; i < prevQuestions?.length; i++) {
            let options = prevQuestions[i]?.options;
            let questionId = prevQuestions[i]?.questionId;
            let utterance = prevQuestions[i]?.utterance;
            tempQuestions.push(
                <Question
                    prevOptions={options}
                    questionId={questionId}
                    prevUtterance={utterance}
                />
            );
            tempOptions.push({questionId: questionId, utterance: utterance, options: [...options]});
        }

        SetQuestions([...tempQuestions]);
        SetOptions([...tempOptions]);
        onChange(tempOptions);
    }

    const HandleQuestionIncrement = () => {
        let tempQuestions = questions;

        tempQuestions.push(
            <Question
                questionId={lastId}
            />
        );

        SetQuestions([...tempQuestions]);
    }

    
    // const HandleQuestionDelete = questionId => {
    //     // console.log(questionId);
    //     let tempQuestions = questions;
    //     let tempOptions = options;
        
    //     for (let i = 0; i < tempOptions?.length; i++) {
    //         if (questionId == tempOptions[i]["questionId"]) {
    //             tempOptions.splice(i, 1);
    //             break;
    //         }
    //     }

    //     for (let i = 0; i < tempQuestions?.length; i++) {
    //         if (questionId == tempQuestions[i]?.props?.questionId) {
    //             tempQuestions?.splice(i, 1);
    //         }
    //     }
        
    //     SetOptions([...tempOptions]);
    //     console.log('HandleQuestionDelete', [...tempOptions]);
    //     SetQuestions([...tempQuestions]);
    // }

    const HandleQuestionDelete = questionId => {
        // console.log(questionId);
        // let tempQuestions = [...questions];
        // let tempOptions = [...options];
      
        // for (let i = 0; i < tempOptions?.length; i++) {
        //   if (questionId === tempOptions[i]["questionId"]) {
        //     tempOptions.splice(i, 1);
        //     break;
        //   }
        // }
      
        // for (let i = 0; i < tempQuestions?.length; i++) {
        //   if (questionId === tempQuestions[i]?.props?.questionId) {
        //     tempQuestions.splice(i, 1);
        //     break;
        //   }
        // }
      
        // SetOptions(tempOptions);
        // console.log('HandleQuestionDelete', tempOptions);
        // SetQuestions(tempQuestions);


        
        
        // questionId === id of the question to be deleted in the array
        let temp_questions = [...questions];
        const new_questions = temp_questions.filter((item) => {
            return item.props.questionId !== questionId;
        });
        // console.log('temp_questions', temp_questions);
        // console.log('new_questions', new_questions);
        SetQuestions(new_questions);

        let temp_options = [...options];
        const new_options = temp_options.filter((item) => {
            return item.questionId !== questionId;
        });
        // console.log('temp_options', temp_options);
        // console.log('new_options', new_options);
        SetOptions(new_options);
      }
      
    
    const HandleOptionAdd = (id, value) => {
        let tempOptions = [...options];

        let optionExistsFlag = false;
        for (let i = 0; i < tempOptions?.length; i++) {
            if (id != tempOptions[i]["questionId"]) continue;
            tempOptions[i] = value;
            optionExistsFlag = true;
            break;
        } 
        
        if (!optionExistsFlag) tempOptions.push(value);

        SetOptions([...tempOptions]);
    }

    const HandleOptionChange = (id, value) => {
        let tempOptions = options;
        for (let i = 0; i < tempOptions?.length; i++) {
            if (id != tempOptions[i]["questionId"]) continue;
            tempOptions[i] = value;
            break;
        }
        SetOptions([...tempOptions]);
    }

    useEffect(() => {
        onChange(options);
    }, [options]);

    useEffect(() => SetLastId(lastId + 1), [questions]);
    useEffect(()=> {
        HandlePrevQuestions();
    }, [prevQuestions]);

    return (
        <div style={{backgroundColor:'#f2f2f2',padding:10}}>   
            <div hidden={questions?.length == 0}>
                {children}
                {questions?.map((question, key) => (
                    <Question
                        questionId={key}
                        question_item={question?.props?.questionId}
                        prevOptions={question?.props?.prevOptions}
                        prevUtterance={question?.props?.prevUtterance}
                        key={key}
                        onChange={HandleOptionChange}
                        onAdd={HandleOptionAdd}
                        onQuestionDelete={HandleQuestionDelete}
                    />
                ))}
            </div>
            <Typography 
                hidden={questions?.length > 0}
                align="center"
                variant="h6"
                style={{
                    padding: "50px"
                }}
            >
                Sem questões nessa aula!
            </Typography>
        </div>
    );
});

export default FormMaker;
import { useContext, useEffect, useState } from "react";
import { Box, Card } from "@mui/material";
import { DashboardContext } from "..";
import { blue, purple, red } from "@mui/material/colors";

import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SchoolIcon from "@mui/icons-material/School";
import Board from "./Board";
import { useSelector } from "react-redux";

const Cards = (props) => {
  const dashboard = useContext(DashboardContext);
  const user = useSelector(store => store.AppReducer.user);

  const [role, setRole] = useState('');

  useEffect(()=> {
    setRole(user?.role)
  }, [])


  return (
    <Box
      component={Card}
      display="flex"
      width="100%"
      flexWrap={"wrap"}
      padding={1.5}
      marginBottom={5}
      elevation={5}
      sx={{
        borderRadius: "1rem",
        overflow: "hidden",
        boxShadow: "0 0 0 0.1rem rgb(43 43 43 / 20%)",
        backgroundColor: "rgb(240, 244, 244)",
      }}
    >
      <Board
        icon={<LocalLibraryIcon />}
        color={purple}
        content={{
          title: "Cursos",
          quantity: dashboard?.courses?.length,
        }}
        links={[
          { label: "Adicionar curso", link: "/courses/add" },
          { label: "Ver cursos", link: "/courses/list" },
        ]}
      />
      <Board
        icon={<PeopleAltIcon />}
        color={red}
        content={{
          title: "Alunos",
          quantity: dashboard?.users?.all?.length,
        }}
        links={
          role !== 'Professor' ?
          [
          { label: "Adicionar aluno", link: "/accounts/create" },
          { label: "Ver alunos", link: "/log/accounts?filter=students" },
        ] : []}
      />
      {
        console.log(role)
      }
      <Board
        icon={<SchoolIcon />}
        color={blue}
        content={{
          title: "Professores",
          quantity: dashboard?.users?.professors?.length,
        }}
        links={
          role !== 'Professor' ?
          [
          {
            label: "Adicionar professor",
            link: "/accounts/create?role=professor",
          },
          { label: "Ver professores", link: "/log/accounts?filter=professors" },
        ] : []}
      />
    </Box>
  );
};

export default Cards;

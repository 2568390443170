import React, { useEffect, useState } from "react";

// import ColorPicker from 'material-ui-color-picker'
import { FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import { FormControl as Input } from "react-bootstrap";

import ImageInput from "../../../../components/Inputs/ImageInput";
import { FormLabel } from "react-bootstrap";
import DefaultButton from "../../../../components/DefaultButton";
import { Get } from "../../../../utils/request";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_KEYS } from "../../../../variables";
import { RiArrowGoBackLine } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import FormCard from "./FormCard";
import Label from "../../../../components/InfoLabel";
import { BsInfoCircle } from "react-icons/bs";
import ColorInput from "../../../../components/Inputs/ColorInput";
import PreviewPage from "./PreviewPage";
import { maskPrice } from "../../../../Auxiliar/Masks";

const FormBody = props => {

    const {
        onConfirm,
        loading
    } = props;

    const [title, SetTitle] = useState("");
    const [subtitle, SetSubtitle] = useState("");
    const [text, SetText] = useState("");
    const [catchPhrase, SetCatchPhrase] = useState("");
    const [description, SetDescription] = useState("");
    const [cards, SetCards] = useState([]);
    const [entryWays, SetEntryWays] = useState("");
    const [entryDate, SetEntryDate] = useState("");
    const [entryPrice, SetEntryPrice] = useState("");
    const [fontColor, SetFontColor] = useState("#000");
    const [backgroundImage, SetBackgroundImage] = useState("");
    const [backgroundColor, SetBackgroundColor] = useState("#000");
    const [secondaryBackgroundColor, SetSecondaryBackgroundColor] = useState("#000");
    const [primaryColor, SetPrimaryColor] = useState("#000");
    const [secondaryColor, SetSecondaryColor] = useState("#000");
    const [videoLink, SetVideoLink] = useState("");
    
    const [firstLoading, SetFirstLoading] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();

    const { course_name, course_id } = useParams();

    const GetData = async () => {
        let response = await Get(`styles/courses-lp/one/${course_id}`);
         
        if (response === undefined) {
            SetFirstLoading(true);
            return;
        }
        if (response?.config !== null) {
            SetTitle(response?.config.title ?? "");
            SetSubtitle(response?.config.subtitle ?? "");
            SetText(response?.config.text ?? "");
            SetCatchPhrase(response?.config.catch_phrase ?? "");
            SetDescription(response?.config.description ?? "");
            SetCards(response?.config.cards ?? "");
            SetEntryWays(response?.config.entryways ?? "");
            SetEntryDate(response?.config.entrydate ?? "");
            SetEntryPrice(response?.config.entryprice ?? "");
            SetFontColor(response?.config.font_color ?? "");
            SetBackgroundImage(response?.config.background_image ?? "");
            SetBackgroundColor(response?.config.background_color ?? "");
            SetSecondaryBackgroundColor(response?.config.secondary_background_color ?? "");
            SetPrimaryColor(response?.config.primary_color ?? "");
            SetSecondaryColor(response?.config.secondary_color ?? "");
            SetVideoLink(response?.config.video_link ?? "");
        }
        SetFirstLoading(true);
    }

    useEffect(() => {
        GetData();
    }, []);

    if (!firstLoading)return <Skeleton/>
    return (
        <div>
            <form>
                <FormGroup>
                    <Label
                        label="Título"
                        required
                        title="O título fica logo abaixo do cabeçalho e terá a 'cor primária' escolhida como cor de fonte"
                    />
                    <Input
                        placeholder="Título da landing page"
                        variant="filled"
                        value={title}
                        onChange={e => SetTitle(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Subtítulo"
                        title="O subtítulo aparece logo abaixo do título e terá a 'cor da fonte' escolhida como cor de fonte"
                    />

                    <Input
                        placeholder="Subtítulo da landing page"
                        value={subtitle}
                        onChange={e => SetSubtitle(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Texto de apoio"
                        title="O texto de apoio aparece logo acima do formulário de matrícula e apresenta a cor colocada em 'Cor da fonte'"
                    />
                    <Input
                        placeholder="Texto de apoio"
                        value={text}
                        onChange={e => SetText(e.target.value)}
                        as="textarea"
                    />
                    <br/>
                    
                    <Label
                        label="Link do vídeo"
                        title="Coloque um link do youtube completo, por exemplo: https://www.youtube.com/watch?v=..."
                    />
                    <Input
                        placeholder="Link do vídeo"
                        variant="filled"
                        value={videoLink}
                        onChange={e => SetVideoLink(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Frase de efeito"
                        title="Escreva alguma frase de efeito para chamar a atenção do usuário! Ex: 'Aqui você tem liberdade para realizar o seu sonho'"
                    />
                    <Input
                        placeholder="Frase de efeito"
                        value={catchPhrase}
                        onChange={e => SetCatchPhrase(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Descrição do curso"
                        title="Descreva o que é passado neste curso. O texto será mostrado no meio da página"
                    />
                    <Input
                        placeholder="Descrição do curso"
                        value={description}
                        onChange={e => SetDescription(e.target.value)}
                        as="textarea"
                    />
                    <br/>

                    <hr/>
                    <FormCard 
                        onChange={cards => SetCards([...cards])}
                        initialCards={cards}
                    />
                    <hr/>

                    <Label
                        label="Formas de ingresso"
                        title="Digite as formas de ingressão no curso. Ex: Vestibular, ENEM, etc"
                    />
                    <Input
                        value={entryWays}
                        onChange={e => SetEntryWays(e.target.value)}
                        placeholder="Formas de ingresso"
                    />
                    <br/>

                    <Label
                        label="Data de convocação"
                        title="Digite a data de convocação do curso. Ex: 1ª Chamada: 30/10"
                    />
                    <Input
                        value={entryDate}
                        onChange={e => SetEntryDate(e.target.value)}
                        placeholder="Data de convocação"
                    />
                    <br/>

                    <Label
                        label="Preço de inscrição"
                        title="Digite o preço de inscrição do curso"
                    />
                    <Input
                        value={entryPrice}
                        onChange={e => SetEntryPrice(maskPrice(e.target.value))}
                        placeholder="Preço de inscrição"
                    />
                    <br/>

                    <Label
                        label="Cor da fonte"
                        title="A cor de fonte será a cor atribuida ao Subtítulo, Texto de apoio, Texto do card de divulgação, Descrição do curso e do Certificado"
                    />
                    <ColorInput
                        value={fontColor}
                        onChange={color => color !== undefined && SetFontColor(color)}
                    />
                    <br/>

                    <Label
                        label="Cor de fundo"
                        title="A cor de fundo será a padrão que aparecerá no fundo da landing page"
                        required
                    />
                    <ColorInput
                        // hintText={backgroundColor}
                        value={backgroundColor}
                        onChange={color => color !== undefined && SetBackgroundColor(color)}
                        // disabled
                    />
                    <br/>

                    <Label
                        label="Cor de fundo secundária"
                        title="A cor de fundo secundária aparecerá como fundo de diferentes blocos da landing page"
                    />
                    <ColorInput
                        // hintText={secondaryBackgroundColor}
                        value={secondaryBackgroundColor}
                        onChange={color => color !== undefined && SetSecondaryBackgroundColor(color)}
                        // disabled
                    />
                    <br/>

                    <Label
                        label="Cor primária"
                        title="A cor primária será atribuida ao Título e à todos os Botões"
                        required
                    />
                    <ColorInput
                        value={primaryColor}
                        onChange={color => color !== undefined && SetPrimaryColor(color)}
                    />
                    <br/>

                    <Label
                        label="Cor secundária"
                        title="A cor secundária será atribuida à cor de fundo do cabeçalho, aos Títulos dos cards, Nome do curso, Formas de ingresso e ao título 'Certificado'"
                    />
                    <ColorInput
                        value={secondaryColor}
                        onChange={color => color !== undefined && SetSecondaryColor(color)}
                    />
                </FormGroup>
            </form>
            <hr/>
            <Label
                label="Pré-visualização"
            />
            <PreviewPage
                title={title}
                subtitle={subtitle}
                text={text}
                catchPhrase={catchPhrase}
                description={description}
                cards={cards}
                entryWays={entryWays}
                entryDate={entryDate}
                entryPrice={entryPrice}
                fontColor={fontColor}
                backgroundColor={backgroundColor}
                secondaryBackgroundColor={secondaryBackgroundColor}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                videoLink={videoLink}
                courseName={course_name}
            />
            <br/>
            <div className="w100 inline-flex jcsb margin-top-1">
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to="/cursos"
                />
                <div>
                    <DefaultButton
                        bg="info"
                        text="Visualizar LP"
                        to={`/nossos-cursos/${course_name}`}
                        style={{
                            marginRight: "2px"
                        }}
                    />
                    <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar configurações"
                        loadingtext="Salvando configurações"
                        loading={loading}
                        onClick={() => {
                            const data = {
                                title: title,
                                subtitle: subtitle,
                                text: text,
                                catchPhrase: catchPhrase,
                                description: description,
                                cards: cards,
                                entryWays, entryWays,
                                entryDate: entryDate,
                                entryPrice: entryPrice,
                                fontColor: fontColor,
                                backgroundImage: backgroundImage,
                                backgroundColor: backgroundColor,
                                secondaryBackgroundColor: secondaryBackgroundColor,
                                primaryColor: primaryColor,
                                secondaryColor: secondaryColor,
                                videoLink: videoLink
                            }

                            onConfirm(data);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
        </div>
    );
}

export default FormBody;
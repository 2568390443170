import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import themes from "./themes";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import gif from "assets/images/demo_text_color.gif";

const ColorPresets = (props) => {
  const {
    // section 1
    section1_bgColor_1,
    section1_bgColor_2,
    section1_accentColor,

    SetSection1BgColor1,
    SetSection1BgColor2,
    SetSection1AccentColor,

    // section 2
    section2_bgColor_1,
    section2_bgColor_2,

    SetSection2BgColor1,
    SetSection2BgColor2,

    // section 3
    section3_bgColor_1,
    section3_bgColor_2,

    SetSection3BgColor1,
    SetSection3BgColor2,

    // section 4
    section4_bgColor_1,
    section4_bgColor_2,

    SetSection4BgColor1,
    SetSection4BgColor2,

    // section 5
    section5_bgColor_1,
    section5_bgColor_2,
    section5_accentColor,

    SetSection5BgColor1,
    SetSection5BgColor2,
    SetSection5AccentColor,

    // warranty
    warranty_bgColor_1,
    warranty_bgColor_2,

    SetWarrantyBgColor1,
    SetWarrantyBgColor2,
  } = props;

  const [theme, setTheme] = useState({});

  const changeTheme = (id) => {
    let theme = themes[id];
    if (theme) {
      setTheme(theme);
    } else {
      setTheme({
        sec_1_1: "white",
        sec_1_2: "white",
        sec_1_3: "white",

        sec_2_1: "white",
        sec_2_2: "white",

        sec_3_1: "white",
        sec_3_2: "white",

        sec_4_1: "white",
        sec_4_2: "white",

        sec_5_1: "white",
        sec_5_2: "white",
        sec_5_3: "white",

        warranty_1: "white",
        warranty_2: "white",
      });
    }
  };

  useEffect(() => {
    // section 1
    SetSection1BgColor1(theme.sec_1_1);
    SetSection1BgColor2(theme.sec_1_2);
    SetSection1AccentColor(theme.sec_1_3);

    // section 2
    SetSection2BgColor1(theme.sec_2_1);
    SetSection2BgColor2(theme.sec_2_2);

    // section 3
    SetSection3BgColor1(theme.sec_3_1);
    SetSection3BgColor2(theme.sec_3_2);

    // section 4
    SetSection4BgColor1(theme.sec_4_1);
    SetSection4BgColor2(theme.sec_4_2);

    // section 5
    SetSection5BgColor1(theme.sec_5_1);
    SetSection5BgColor2(theme.sec_5_2);
    SetSection5AccentColor(theme.sec_5_3);

    // warranty
    SetWarrantyBgColor1(theme.warranty_1);
    SetWarrantyBgColor2(theme.warranty_2);
  }, [theme]);

  useEffect(() => {
    setTheme({
      sec_1_1: section1_bgColor_1,
      sec_1_2: section1_bgColor_2,
      sec_1_3: section1_accentColor,

      sec_2_1: section2_bgColor_1,
      sec_2_2: section2_bgColor_2,

      sec_3_1: section3_bgColor_1,
      sec_3_2: section3_bgColor_2,

      sec_4_1: section4_bgColor_1,
      sec_4_2: section4_bgColor_2,

      sec_5_1: section5_bgColor_1,
      sec_5_2: section5_bgColor_2,
      sec_5_3: section5_accentColor,

      warranty_1: warranty_bgColor_1,
      warranty_2: warranty_bgColor_2,
    });
  }, []);

  // generate the clickables for the theme selector
  const GenPaletteItem = (id, color1, color2) => {
    return (
      <Box
        key={id}
        sx={{
          flexGrow: 1,
          flexBasis: "4rem",
          borderRadius: "0.5rem",
          background: `linear-gradient(90deg, ${color1} 0%, ${color2} 100%)`,
          transition: "500ms ease",
          boxShadow: "0 0 0 0.2rem white",
          "&:hover": {
            cursor: "pointer",
            transform: "scale(1.1)",
            boxShadow: "0 0 0 0.2rem red",
          },
        }}
        onClick={() => {
          changeTheme(id);
        }}
      ></Box>
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          height: "4rem",
          gap: "0.4rem",
        }}
      >
        {/* id , 1st color, 2st color */}
        {themes.map((theme, index) => {
          return GenPaletteItem(index, theme.sec_1_1, theme.sec_1_2);
        })}
      </div>

      <div
        style={{
          margin: "1rem 0",
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
        }}
      >
        <span
          style={{
            padding: "0.2rem 0.4rem",
            borderRadius: "0.4rem",
            backgroundColor: "#f1f1f1",
          }}
        >
          <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> <b>Atenção!</b> É
          possível alterar a coloração dos textos individualmente.
        </span>

        <img
          src={gif}
          alt="gif"
          style={{ width: "100%", border: "0.1rem solid gray" }}
        />
      </div>
    </div>
  );
};

export default ColorPresets;

import React, { useEffect, useState } from "react";
import FormBody from "./FormBody/index";
import { Get } from "../../../utils/request";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import style from "./style.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useQuery } from "react-query";
import SaveIcon from "@mui/icons-material/Save";
import logo_loading from "assets/images/rt/icon_transparent.png";
import DisplayCoursesPreview from "./Preview";

const ConfigMuralCursosNextflix = () => {
  const [hidePreview, setHidePreview] = useState(false);

  // const [background_color, SetBackgroundColor] = useState("#000");
  // const [fontColor, SetFontColor] = useState("#000");
  const [banners, SetBanners] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    "data",
    async () => await Get("styles/pages/course_wall_netflix")
  );

  useEffect(() => {
    if (data) {
      SetBanners(data?.data);
    }
  }, [data]);

  return isLoading ? (
    <div style={{ width: "100%", aspectRatio: "16/9", display: "flex" }}>
      <img
        src={logo_loading}
        alt="Logotipo RT"
        className={"infiniteRotateAnim"}
        style={{
          width: "12rem",
          height: "12rem",
          margin: "auto",
        }}
      />
    </div>
  ) : (
    <div className={style.gridContainer}>
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "14.2rem",
          zIndex: 50,
          display: "flex",
          gap: "1rem",
        }}
      >
        <div
          className={style.hidePreviewButton}
          style={{
            background: hidePreview ? "#accef2" : "#ffc3b5",
          }}
          onClick={() => setHidePreview(!hidePreview)}
        >
          {hidePreview ? <VisibilityIcon /> : <VisibilityOffIcon />}{" "}
          <span>{hidePreview ? "Mostrar" : "Esconder"} preview</span>
        </div>
      </div>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <div
          style={{
            width: hidePreview ? "100%" : `45%`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginBottom: "1rem",
            }}
          >
            <h2 className="title">
              Configurações da página "Mural de cursos Nextflix"
            </h2>
            <p>Apenas usuários logados poderão ver esse painel</p>
          </div>

          <FormBody
            {...{
              banners,
              isLoading,
              refetch,
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: hidePreview ? "0" : `45%`,
          display: "flex",
          height: "100vh",
          transition: "width 300ms",
          position: "fixed",
          right: "0",
          background: "#fff",
          borderLeft: "0.2rem dashed black",
        }}
      >
        <DisplayCoursesPreview
          {...{
            banners,
          }}
        />
      </div>
    </div>
  );
};

export default ConfigMuralCursosNextflix;

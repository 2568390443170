import React, { useEffect, useRef, useState } from "react";
import { AiFillFacebook, AiFillInstagram, AiFillYoutube, AiOutlineWhatsApp } from "react-icons/ai";

import LogoHeader from "../../../components/LogoHeader";
import SocialMedias from "../../../components/SocialMedias";
import DefaultButton from "../../../components/DefaultButton";
import { Get } from "../../../utils/request";
import ReactPlayer from "react-player/youtube";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import Footer from "../../../Home/Footer";

import "./index.css";
import FormBody from "./FormBody";
import WhatsappButton from "components/WhatsappButton";

const CourseLandingPage = () => {

    const [config, SetConfig] = useState();
    const [course, SetCourse] = useState();
    const [firstLoading, SetFirstLoading] = useState(false);

    const formSection = useRef(null);
    const firstInputRef = useRef(null);

    const history = useHistory();
    const generalStyle = useSelector(store => store.AppReducer.generalStyle);

    const { course_name } = useParams();

    const GetData = async () => {
        if (!course_name) {
            history.push("/landing");
            return;
        }

        let response = await Get(`styles/courses-lp/one-name/${course_name}`);
        SetFirstLoading(true);

        if (response?.status === true) {
            SetConfig(response?.config);
            SetCourse(response?.course);
        }

         
    }

    useEffect(() => GetData(), []);

    if (!firstLoading) return <Skeleton/>
    return (
        <div 
            className="course-landing-page"
            style={{
                height: "fit-content",
                ...(config?.background_color && {backgroundColor: config?.background_color})
            }}
        >
            <div 
                className="course-landing-page-header"
                style={{
                    ...(config?.secondary_color && {backgroundColor: config?.secondary_color})
                }}
            >
                <div className="flex align-center fd-row">
                    <LogoHeader style={{marginRight: ".5em"}}/>
                    <div className="flex align-center fd-row" hidden={!generalStyle.whatsapp_number}>
                        <AiOutlineWhatsApp color="white" size={23} style={{marginRight: ".5em"}}/>
                        <span className="text-muted">{generalStyle.whatsapp_number}</span>
                    </div>
                </div>
                <SocialMedias/>
            </div>
            <div className="course-landing-page-body">
                <div id="block1" style={{marginBottom: "40px"}}>
                    <div className="course-landing-page-title">
                        <h1 
                            id="title teste"
                            style={{
                                ...(config?.primary_color && {color: config?.primary_color})
                            }}
                        >{config?.title}</h1>
                        <h2 
                            id="subtitle"
                            style={{
                                ...(config?.font_color && {color: config?.font_color})
                            }}
                        >{config?.subtitle}</h2>
                    </div>
                    <div className="course-landing-page-content">
                        <div id="left" className="col-lg flex1" style={{padding: "15px"}}>
                            <h4
                                style={{
                                    ...(config?.font_color && {color: config?.font_color}),
                                    textAlign: "center",
                                    wordBreak: "break-word"
                                }}
                            >{config?.text}</h4>
                            <FormBody
                                config={config}
                                ref={{
                                    ref: formSection,
                                    firstInputRef: firstInputRef
                                }}
                            />
                        </div>
                        <div id="right" className="col-lg flex1" hidden={!config?.video_link}>
                            <ReactPlayer
                                url={config?.video_link ? config?.video_link : ""}
                                controls={true}
                                width="50vw"
                            />
                        </div>
                    </div>
                </div>
                
                <div 
                    id="block2" 
                    className="w100 flex jcc" 
                    style={{
                        padding: "40px",
                        ...(config?.secondary_background_color && {backgroundColor: config?.secondary_background_color})
                    }}
                    hidden={!config?.catch_phrase}
                >
                    <h1
                        className="title text-center"
                        style={{
                            ...(config?.secondary_color && {color: config?.secondary_color})
                        }}
                    >{config?.catch_phrase}</h1>
                </div>

                <div 
                    id="block3" 
                    className="flex fdrow flex-wrap"
                    style={{
                        ...(config?.secondary_background_color && {backgroundColor: config?.secondary_background_color})
                    }}
                >
                    {config?.cards && config?.cards?.map((value, index) => (
                        <div 
                            className="col-lg text-center w100"
                            style={{
                                padding: "25px"
                            }}
                            key={index}
                            hidden={!config?.cards}
                        >
                            <h3
                                className="title"
                                style={{
                                    ...(config?.secondary_color && {color: config?.secondary_color})
                                }}
                                >{value?.title}</h3>
                            <p
                                style={{
                                    ...(config?.font_color && {color: config?.font_color})
                                }}
                                >{value?.text}</p>
                        </div>
                    ))}
                </div>
                <div id="block4" className="text-center" style={{padding: "40px"}} hidden={!config?.description}>
                    <h1
                        className="title"
                        style={{
                            ...(config?.secondary_color && {color: config?.secondary_color})
                        }}
                    >{course?.nome}</h1>
                    <p
                        style={{
                            ...(config?.font_color && {color: config?.font_color})
                        }}
                    >{config?.description}</p>
                </div>
                <div id="block5" className="w100 flex jcc" style={{marginBottom: "60px"}} hidden={!config?.description}>
                    <DefaultButton
                        style={{
                            fontSize: "18px"
                        }}
                        onClick={() => {
                            window.scrollTo({
                                top: formSection.current.offsetTop - formSection.current.offsetHeight,
                                behavior: "smooth"
                            });
                            firstInputRef.current.focus();
                        }}
                        text="Fazer Matrícula Agora!"
                        bg={config?.primary_color}
                    />
                </div>
                <div 
                    id="block6" 
                    className="text-center" 
                    style={{
                        padding: "60px",
                        ...(config?.secondary_background_color && {backgroundColor: config?.secondary_background_color})
                    }}
                >
                    <h4
                        className="title"
                        style={{
                            ...(config?.secondary_color && {color: config?.secondary_color}),
                            marginBottom: "20px"
                        }}
                    >Formas de Ingresso</h4>
                    <p
                        style={{
                            ...(config?.font_color && {color: config?.font_color})
                        }}
                    >{config?.entryways}</p>
                    <p
                        style={{
                            ...(config?.font_color && {color: config?.font_color})
                        }}
                    >{config?.entrydate}</p>
                    <p
                        style={{
                            ...(config?.font_color && {color: config?.font_color})
                        }}
                    >Inscrição: {config?.entryprice}</p>
                    <DefaultButton
                        style={{
                            fontSize: "18px"
                        }}
                        onClick={() => {
                            window.scrollTo({
                                top: formSection.current.offsetTop - formSection.current.offsetHeight,
                                behavior: "smooth"
                            });
                            firstInputRef.current.focus();
                        }}
                        text="Eu quero me inscrever!"
                        bg={config?.primary_color}
                    />
                </div>

                <div 
                    id="block7" 
                    className="text-center"
                    style={{
                        padding: "60px 0"
                    }}
                >
                    <h2
                        className="title"
                        style={{
                            ...(config?.secondary_color && {color: config?.secondary_color}),
                            marginBottom: "20px"
                        }}
                    >Certificado</h2>
                    <p
                        style={{
                            ...(config?.font_color && {color: config?.font_color}),
                            padding: "0 10em",
                            textAlign: "justify"
                        }}
                    >
                        {generalStyle?.certificate}
                    </p>
                </div>
            </div>
            <WhatsappButton />
            <Footer/>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div className="w100 skeleton-base skeleton-shimmer" style={{height: "9em"}}/>
            <div className="w100 flex fdcolumn align-center" style={{margin: "3em 0"}}>
                <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/>
            </div>
            <div className="w100 flex fdrow" style={{padding: "10px"}}>
                <div className="flex1 flex align-center fdcolumn">
                    <div className="skeleton-medium-text skeleton-shimmer"/>
                    <div className="skeleton-medium-text skeleton-shimmer"/>
                </div>
                <div className="skeleton-base skeleton-shimmer flex1" style={{height: "20em"}}/>
            </div>
        </div>
    );
}

export default CourseLandingPage;
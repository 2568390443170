import { API_URL } from "../variables";
import axios from "axios";

const publicKey = 'pk_3PVpeg4CJquK4Djo'

export const Post = async (path, body, headers = {}) => {
    let token = localStorage.getItem("token");
    if (token !== null) headers['Authorization'] = 'Bearer ' + token;

    headers = {
        "Accept": "application/json",
        ...headers
    }

    let httpCode;
    let response = await fetch(API_URL + path, {
        method: "POST",
        body: body,
        headers: headers
    })
        .then(async res => {
            httpCode = res.status;
            res = await res.json();
            return res;
        })
        .then(json => (json))
        .catch(err => console.error("Error to post: " + err.message));

    response["httpCode"] = httpCode;

    return response;
}

export const Put = async (path, body, headers = {}) => {
    let token = localStorage.getItem("token");
    if (token !== null) headers['Authorization'] = 'Bearer ' + token;

    headers = {
        "Accept": "application/json",
        ...headers
    }

    let httpCode;
    let response = await fetch(API_URL + path, {
        method: "PUT",
        body: body,
        headers: headers
    })
        .then(async res => {
            httpCode = res.status;
            res = await res.json();
            return res;
        })
        .then(json => (json))
        .catch(err => console.error("Error to put: " + err.message));

    response["httpCode"] = httpCode;

    return response;
}

export const PostProgress = async (path, body, headers = {}, onProgress = () => { }) => {
    let token = localStorage.getItem("token");
    if (token !== null) headers['Authorization'] = 'Bearer ' + token;

    headers = {
        "Accept": "application/json",
        ...headers
    }

    let response = await axios.post(API_URL + path, body, {
        headers: headers,
        onUploadProgress: e => {
            onProgress((e.loaded / e.total) * 100);
        }
    })
        .then(async res => {
            res = res.data
            return res;
        })
        .then(json => (json))
        .catch(err => console.error("Error to post: " + err.message));

    return response;
}

export const Get = async (path, headers = {}) => {
    let httpCode;
    let token = localStorage.getItem("token");
    if (token !== null) headers['Authorization'] = 'Bearer ' + token;

    headers = {
        "Accept": "application/json",
        ...headers
    }


    let response = await fetch(API_URL + path, {
        method: "GET",
        headers: headers
    })
        .then(res => {
            httpCode = res.status;
            return res.json();
        })
        .then(json => (json))
        .catch(err => console.log("Error to get: " + err.message));

    response["httpCode"] = httpCode;

    return response;
}

export const Delete = async (path, headers = {}) => {
    let httpCode;
    let token = localStorage.getItem("token");
    if (token !== null) headers['Authorization'] = 'Bearer ' + token;

    headers = {
        "Accept": "application/json",
        ...headers
    }


    let response = await fetch(API_URL + path, {
        method: "DELETE",
        headers: headers
    })
        .then(res => {
            httpCode = res.status;
            return res.json();
        })
        .then(json => (json))
        .catch(err => console.log("Error to delete: " + err.message));

    response["httpCode"] = httpCode;

    return response;
}

export const GetProgress = async (path, headers = {}, onProgress = () => { }) => {
    let token = localStorage.getItem("token");
    if (token !== null) headers['Authorization'] = 'Bearer ' + token;

    headers = {
        "Accept": "application/json",
        ...headers
    }

    let response = await axios.get(API_URL + path, {
        headers: headers,
        onDownloadProgress: e => {
            console.log(e.total);
            // onProgress((e.loaded / e.total) * 100);
        }
    })
        .then(async res => res.data)
        .catch(err => console.log("Error to get: " + err.message));

    return response;
}

export function VALIDATE(props) {
    let state2 = { ...props.state }
    let keys = Object.keys(state2)
    let valid = true

    keys.forEach(item => {
        if (item !== 'id' && state2[item].value === '') { state2[item].error = true; valid = false }
    })

    props.setState(state2)
    return valid
}

export const CreateToken = async (card) => {
    let httpCode;

    let response = await fetch(`https://api.pagar.me/core/v5/tokens?appId=${publicKey}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            type: 'card',
            card
        })
    })
        .then(res => {
            httpCode = res.status;
            return res.json();
        })
        .then(json => (json))
        .catch(err => console.log("Error to get: " + err.message));

    response["httpCode"] = httpCode;

    return response;
}
import React, { useCallback } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

import {
  FaTachometerAlt,
  FaGem,
  FaList,
  FaGithub,
  FaRegLaughWink,
  FaHeart,
  FaWineBottle,
  FaUserAlt,
  FaColumns,
  FaTruckMoving,
  FaBriefcase,
  FaEye,
  FaFolderPlus,
  FaCog,
  FaChartLine,
  FaRegComments,
  FaDollarSign,
  FaRecycle,
} from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { GiRead } from "react-icons/gi";
import PersonIcon from "@mui/icons-material/Person";
import PostAddIcon from "@mui/icons-material/PostAdd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import sidebarBg from "./assets/bg1.jpg";
import Logo from "../assets/images/rt/white_logo.png";
import { Link, NavLink } from "react-router-dom";
import { MdCopyright } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import RoleBased from "components/Role/RoleBased";
import { setForcedRole } from "actions/admin.actions";
import ArticleIcon from "@mui/icons-material/Article";
import moment from "moment";

const Aside = (props) => {
  const {
    image,
    collapsed,
    toggled,
    handleToggleSidebar,
    handleCollapsedChange = () => {},
    ...other
  } = props;

  // #084808
  const dispatch = useDispatch();
  const user = useSelector((store) => store.AppReducer.user);
  const forcedRole = useSelector((store) => store.AdminReducer.forcedRole);

  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      style={{
        position: "fixed",
      }}
      onToggle={handleToggleSidebar}
      {...other}
    >
      <SidebarHeader style={{ background: "#222d32", height: 70 }}>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70px",
          }}
        >
          {collapsed == true && (
            <span>
              <img
                src={Logo}
                alt=""
                height="60"
                style={{
                  height: "60px",
                  width: "90%",
                  objectFit: "contain",
                }}
              />
              {/* LOGO */}
            </span>
          )}
          {collapsed == false && (
            <i>
              <img
                className="img-fluid"
                src={Logo}
                alt=""
                height="100"
                style={{ height: "40px" }}
              />
              {/* LOGO */}
            </i>
          )}
        </div>
      </SidebarHeader>

      <SidebarContent
        style={{
          background: "#222d32",
          overflowY: "scroll",
        }}
      >
        <Menu iconShape="circle">
          {/* <RoleBased roles={["Admin", "Colaborador"]}>
            <MenuItem
              icon={<VisibilityIcon fontSize={"small"} />}
              onClick={() => {
                if (forcedRole == "Aluno") {
                  dispatch(setForcedRole(null));
                } else {
                  dispatch(setForcedRole("Aluno"));
                }
              }}
            >
              <a>
                {forcedRole == "Aluno" ? "Ver como Admin" : "Ver como Aluno"}
              </a>
            </MenuItem>
          </RoleBased> */}
          <MenuItem icon={<FaChartLine />}>
            <NavLink
              exact
              to="/dashboard"
              activeStyle={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              {"Dashboard"}
            </NavLink>
          </MenuItem>
          <RoleBased>
            <SubMenu icon={<FaGem />} title="Leads" translate="no">
              <MenuItem>
                <NavLink
                  to="/log/email"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  E-mails registrados
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/log/leads-course"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Leads cursos
                </NavLink>
              </MenuItem>
              {/* <MenuItem>
                <NavLink
                  to="/logs"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Logs
                </NavLink>
              </MenuItem> */}
            </SubMenu>
            {/* <SubMenu icon={<PersonIcon />} title="Usuários"> */}
            <RoleBased roles={["Admin"]}>
              <SubMenu icon={<PersonIcon />} title="Usuários">
                <MenuItem>
                  <NavLink
                    to="/log/accounts"
                    activeStyle={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Ativos
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to="/log/accounts-disabled"
                    activeStyle={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Desativados
                  </NavLink>
                </MenuItem>
              </SubMenu>
            </RoleBased>
            {/* <MenuItem>
								<NavLink
									to="/groups/list"
									activeStyle={{
										fontWeight: "bold",
										color: "white"
									}}>
									Turmas
								</NavLink>
							</MenuItem> */}
            {/* </SubMenu> */}
          </RoleBased>
          <SubMenu icon={<GiRead />} title="Cursos">
            <MenuItem>
              <NavLink
                to="/courses/list"
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Ver cursos
              </NavLink>
            </MenuItem>
            {/* <RoleBased>
              <MenuItem>
                <NavLink
                  to="/free-courses/list"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Cursos gatuitos
                </NavLink>
              </MenuItem>
            </RoleBased> */}
            <RoleBased>
              <MenuItem>
                <NavLink
                  to="/categorias"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Categorias
                </NavLink>
              </MenuItem>
            </RoleBased>
            <RoleBased roles={["Admin", "Colaborador", "Professor"]}>
              <MenuItem>
                <NavLink
                  to="/tag"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Tags
                </NavLink>
              </MenuItem>
            </RoleBased>
          </SubMenu>
          <RoleBased roles={["Admin"]}>
            <SubMenu icon={<FaDollarSign />} title="Financeiro">
              <RoleBased>
                <MenuItem>
                  <NavLink
                    to="/dashfinanceiro"
                    activeStyle={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Dashboard financeiro
                  </NavLink>
                </MenuItem>
              </RoleBased>
              <RoleBased>
                <MenuItem>
                  <NavLink
                    to="/coupon"
                    activeStyle={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Cupons de desconto
                  </NavLink>
                </MenuItem>
              </RoleBased>
              <RoleBased>
                <MenuItem>
                  <NavLink
                    to="/cursos-comprados"
                    activeStyle={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Cursos comprados
                  </NavLink>
                </MenuItem>
              </RoleBased>
            </SubMenu>
          </RoleBased>
          <RoleBased>
            <SubMenu icon={<AiFillSetting />} title="Páginas">
              <MenuItem>
                <NavLink
                  to="/page/config/pagina-inicial"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Página inicial
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/page/config/sobre-nos"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Sobre nós
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/page/config/saude-integrativa"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Saúde integrativa
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/page/config/mural-de-cursos"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Mural de cursos
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/page/config/mural-de-cursos-netflix"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Mural de cursos Nextflix
                </NavLink>
              </MenuItem>
              {/* <MenuItem>
                <NavLink
                  to="/page/config/checkout"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Checkout
                </NavLink>
              </MenuItem> */}
              <MenuItem>
                <NavLink
                  to="/page/config/login"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Login
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/page/config/cadastro"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Cadastro
                </NavLink>
              </MenuItem>
              {/* <MenuItem>
                  <NavLink
                    to="/science-plus/list"
                    activeStyle={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    +Ciência
                  </NavLink>
                </MenuItem> */}

              <SubMenu title={"+Ciência"}>
                <MenuItem>
                  <NavLink
                    to="/science-plus/list"
                    activeStyle={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Postagens
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to="/science-plus/references/list"
                    activeStyle={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Referências
                  </NavLink>
                </MenuItem>
              </SubMenu>
              <MenuItem>
                <NavLink
                  to="/site-rt/policy-terms"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Politica e privacidade
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/site-rt/terms"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Termos de uso
                </NavLink>
              </MenuItem>
            </SubMenu>
          </RoleBased>

          <RoleBased>
            {/* <SubMenu icon={<ArticleIcon />} title="Log"> */}
            <MenuItem icon={<ArticleIcon />}>
              <NavLink
                to="/logs"
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Logs
              </NavLink>
            </MenuItem>
            {/* </SubMenu> */}
          </RoleBased>
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center", background: "#222d32" }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <a
            href="https://www.renatatrinca.com.br/landing"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <MdCopyright />
            <span> {"Copyright "+moment().format('YYYY')+" Renata Trinca"}</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;

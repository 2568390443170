import React from "react";
import MoreInfo from "./MoreInfo";
import Filter from "utils/Filter";
import { Get, Post } from "utils/request";
import { MdSearch } from "react-icons/md";
import { moneyMask } from "utils/moneyMask";
import {
  CircularProgress,
  InputAdornment,
  Pagination,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";

const Orders = ({user_id}) => {
  const user = useSelector((store) => store.AppReducer.user);

  const [orders, setOrders] = React.useState("");
  const [courses, setCourses] = React.useState("");
  const [search, setSearch] = React.useState(true);
  const [dateOf, setDateOf] = React.useState("");
  const [dateFor, setDateFor] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [pagination, setPagination] = React.useState({
    totalItems: "",
    pageNumber: 1,
    perPage: 10,
  });
  const [options, setOptions] = React.useState({
    paid: { value: false, label: "Pago", checked: false },
    failed: { value: false, label: "Falha", checked: false },
    canceled: { value: false, label: "Cancelado", checked: false },
    pending: { value: false, label: "Pendente", checked: false },
  });

  React.useEffect(() => {
    const getData = async () => {
      setSearch(false);
      setLoading(true);
      const form = new FormData();
      form.append("user_id", user_id);

      const status = getStatus();

      let response = undefined;
      if (user.role === "Admin") {
        response = await Post(
          `orders/?page=${pagination.pageNumber}&status=${
            status ? status : ""
          }&dateOf=${dateOf ? dateOf : ""}&dateFor=${dateFor ? dateFor : ""}`,
          form
        );
      } else {
        response = await Get(
          `orders/?page=${pagination.pageNumber}&status=${
            status ? status : ""
          }&dateOf=${dateOf ? dateOf : ""}&dateFor=${dateFor ? dateFor : ""}`
        );
      }
      console.log(response);
      if (response.status) {
        setCourses(response.courses);
        setOrders(response.orders);
        setLoading(false);
        if (!pagination.totalItems)
          setPagination({
            ...pagination,
            totalItems: response.pagination.total_pages,
          });
      } else {
        setOrders([]);
        setLoading(false);
      }
    };

    if (search) getData();
  }, [pagination.pageNumber, search]);

  const handleDate = (data) => {
    data = new Date(data);
    let month;
    let day;
    let year = data.getFullYear();

    if (Array.from(String(data.getMonth())).length === 1)
      month = "0" + data.getMonth();
    if (data.getMonth() === 9) month = 10;
    else month = data.getMonth() + 1;

    if (Array.from(String(data.getDate())).length === 1)
      day = "0" + data.getDate();
    else day = data.getDate();

    return day + "/" + month + "/" + year;
  };

  const handleStatus = (status) => {
    switch (status) {
      case "pending":
        statusStyle.backgroundColor = "#FFFF66";
        return "Pendente";
      case "paid":
        statusStyle.backgroundColor = "#8AFF8A";
        return "Pago";
      case "failed":
        statusStyle.backgroundColor = "#FF8A8A";
        return "Falha";
      default:
        return null;
    }
  };

  const statusStyle = {
    padding: ".4rem",
    borderRadius: ".4rem",
    backgroundColor: "#ffff66",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
  };

  const getStatus = () => {
    let keys = { ...options };
    let status = "";
    keys = Object.keys(keys);
    keys.forEach((item) => {
      if (options[item].value) status = item;
    });
    return status;
  };

  return (
    <div className="w-100">
      <div className="card-body w-100">
        <div className="col-12">
          <div className="d-flex">
            <h3 className="title me-2">Pedidos</h3>
            {/* <Filter
              setDateOf={setDateOf}
              setDateFor={setDateFor}
              options={options}
              setOptions={setOptions}
              setSearch={setSearch}
            /> */}
          </div>
          <p className="text-muted">
            Aqui são listados seus pedidos anteriores
          </p>
          <TextField
            className="mt-3"
            fullWidth
            label="Pesquisar pelo nome..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MdSearch />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <hr />
        {!loading ? (
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <td>Produto</td>
                <td>Total</td>
                <td>Tipo de pagamento</td>
                <td>Status</td>
                <td>Pago em</td>
                <td>Ações</td>
              </tr>
            </thead>
            <tbody>
              {orders !== undefined && orders.map((item, index) => {
                // let listCourses = [];
                // item.items.forEach((item2) => {
                //   courses.forEach((item3) => {
                //     if (item2.code === item3.id)
                //       listCourses = [...listCourses, item3];
                //   });
                // });
               


                return (
                  <tr key={index}>
                    <td>
                      {item.courses.map((item2,id) => {
                         let div = document.createElement('div');
                         div.innerHTML=item2.course.name;
                         return(
<span key={id}  style={{color:'black'}}
                        >{div.textContent}</span>
                         );
                        
              })}
                    </td>
                    <td>{moneyMask(item.amount)}</td>
                    <td>
                        {item.method}
                    </td>
                    <td>
                      <span style={statusStyle}>
                        {handleStatus(item.status)}
                      </span>
                    </td>
                   
                    <td>{item.paid_at? handleDate(item.paid_at):'-'}</td>
                    <td>
                      <MoreInfo id={item.id} />
                    </td>
                  </tr>
                );
              })}
              { orders === undefined || orders.length === 0 && (
                <tr>
                  <td colSpan="6" className="text-center">
                    Nenhum pedido encontrado
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <div className="d-flex justify-content-center p-5">
            <CircularProgress />
          </div>
        )}
        {pagination.totalItems ?(
          <div className="d-flex justify-content-end">
            <Pagination
              color="primary"
              shape="rounded"
              count={Math.ceil(pagination.totalItems / pagination.perPage)}
              page={pagination.pageNumber}
              onChange={(e, page) => {
                setPagination({ ...pagination, pageNumber: page });
                setSearch(true);
              }}
            />
          </div>
        ):<></>}
      </div>
    </div>
  );
};

export default Orders;

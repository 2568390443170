import { createTheme } from "@mui/material";
import { blue, red, purple, indigo, green } from "@mui/material/colors";

export const customPalette = (main, text) => createTheme({
    palette: {
        primary: {
            main: main,
            contrastText: text
        }
    }
});

export const primaryBlue = createTheme({
    palette: {
        primary: {
            main: blue[500]
        }
    }
});

export const errorTheme = createTheme({
    palette: {
        primary: {
            main: red[600],
            contrastText: "white"
        }
    }
});

export const purpleTheme = createTheme({
    palette: {
        primary: {
            main: purple[600],
            contrastText: "white"
        }
    }
});

export const indigoTheme = createTheme({
    palette: {
        primary: {
            main: indigo[700]
        }
    }
});

export const greenTheme = createTheme({
    palette: {
        primary: {
            main: green[700]
        }
    }
})
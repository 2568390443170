// mui icons
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import { useEffect } from "react";
import { Post } from "utils/request";
import { STORAGE_URL } from "variables";
import { useDispatch, useSelector } from "react-redux";
import { Show } from "actions/SnackbarActions";

const Section5Card = (props) => {
  const { card } = props;
  const dispatch = useDispatch();

  const [showUser, setShowUser] = useState(false);

  const handleEdit = async () => {
    let form = new FormData();

    form.append("id", card.id);
    form.append("name", card.name);
    form.append("email", card.email);
    form.append("role_id", card.role_id);
    form.append("isTeamMember", !showUser);
    let response = await Post(`auth/edit_profile`, form);
    dispatch(
      Show({
        show: true,
        severity: response?.severity,
        message: response?.message,
      })
    );
    if (response?.severity === "success") {
      setShowUser(!showUser)
    }
  };

  useEffect(() => {
    setShowUser(card.isTeamMember);
  }, [card]);

  return (
    <div
      style={{
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        backgroundColor: " rgb(230, 230, 230)",
        paddingRight: "0.8rem",
        borderRadius: "0.5rem",
        width: "100%",
        overflow: "hidden",
        height: "2.2rem",
      }}
    >
      <div>
        <img
          src={STORAGE_URL + card.profile_image}
          style={{
            width: "3rem",
            height: "3rem",
            objectFit: "cover",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.2rem",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h5
            style={{
              margin: 0,
              fontSize: "1.2rem",
            }}
          >
            {card.name}
          </h5>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <input
          type="checkbox"
          style={{
            transform: "scale(1.4)",
            // marginLeft: "0.5rem",
            marginRight: "0.5rem",
          }}
          checked={showUser}
          // onClick={() => setShowUser(!showUser)}
          onClick={() => {
            handleEdit();
          }}
        />
        Mostrar
      </div>
    </div>
  );
};
//       </div>
//       <div style={{ display: "flex" }}>
//         <input type="checkbox"
//           style={{
//             marginLeft: "0.5rem",
//             marginRight: "0.5rem",
//           }}
//           checked={showUser}
//           onClick={() => setShowUser(!showUser)}
//         />
//         Mostrar
//       </div>
//     </div>
//   );
// };

export default Section5Card;

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useState } from "react";

import style from "./style.module.css";

const QuestionsCard = ({ question, answer, index, remove, handle }) => {
  const [deleted, setDeleted] = useState(false);

  const [questionText, setQuestionText] = useState(question);
  const [answerText, setAnswerText] = useState(answer);

  const handleRemoveCard = () => {
    setDeleted(true);
    setTimeout(() => {
      setDeleted(false);
      remove(index);
    }, 200);
  };

  const handleChangeQuestion = (q, a) => {
    setQuestionText(q);
    setAnswerText(a);
    handle(index, q, a);
  };

  return (
    <div
      className={
        deleted ? style.removeQuestionCardAnimation : style.questionsCard
      }
    >
      <input
        type="text"
        value={question}
        onInput={(e) => {
          handleChangeQuestion(e.target.value, answerText);
        }}
        placeholder="Questão"
        style={{
          border: "none",
          outline: "none",
          padding: "0.6rem 0.8rem",
          minWidth: "28rem",
        }}
      />
      <textarea
        style={{
          border: "none",
          outline: "none",
          padding: "0.6rem 0.8rem",
          minWidth: "28rem",
          minHeight: "4rem",
        }}
        placeholder="Resposta"
        value={answer}
        onInput={(e) => {handleChangeQuestion(questionText, e.target.value)}}
      ></textarea>
      <CancelRoundedIcon
        sx={{
          position: "absolute",
          right: "0.6rem",
          top: "0.6rem",
          fontSize: "1.6rem",
          color: "gray",
          borderRadius: "50%",
          cursor: "pointer",
          opacity: 0.7,
          "&:hover": {
            opacity: 1,
          },
        }}
        onClick={handleRemoveCard}
      />
    </div>
  );
};

export default QuestionsCard;

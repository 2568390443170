import { useState } from "react";
import { FormControl as Input } from "react-bootstrap";

import Table from "./Table";

const CourseList = props => {

    const [search, SetSearch] = useState("");

    return (
        <div className="card">
            <div className="card-body">
                <h3 className="title">Categoria - </h3>
                <p className="text-muted">Aqui são listados todas os cursos dentro desta categoria</p>
                <hr/>
                <div className="flex jcsb flex-wrap margin-bottom">
                    <Input
                        placeholder="Pesquisar pelo Nome"
                        value={search}
                        onChange={e => SetSearch(e.target.value)}
                        style={{ width: "50%" }}
                    />
                </div>
                <Table
                    search={search}
                />
            </div>
        </div>
    );
}

export default CourseList;
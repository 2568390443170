import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Board from "./Board";
import useCertificates from "./useCertificates";

const Certificates = () => {
  const {
    certificates: { certificates = [], isLoading },
    regenerate,
  } = useCertificates();

  return (
    <Box>
      <Typography
        variant={"h5"}
        sx={{
          mb: 1,
          mt: 2,
        }}
      >
        <b>Certificados</b>
      </Typography>
      <Card
        raised
        sx={{
          gap: "1rem",
          padding: "1rem",
          backgroundColor: "#f5f5f5",
          boxShadow: "none",
          borderRadius: '0.6rem',
        }}
      >
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.05rem",
                  }}
                >
                  Nome do curso
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.05rem",
                  }}
                >
                  Baixou certificado?
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.05rem",
                  }}
                >
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {certificates?.map((certificate, certificateKey) => (
                <Board
                  key={certificateKey}
                  regenerate={regenerate}
                  {...certificate}
                />
              ))}
            </TableBody>
          </Table>
          {certificates.length==0 && isLoading==false ? <p style={{ textAlign:'center', marginBottom: 5, fontWeight: 'normal', fontSize: 16, marginTop: 10 }}>Nenhum certificado encontrado</p>:<></>}
          {isLoading && (
            <Box>
              <CircularProgress />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Certificates;

import { Tooltip } from "@mui/material";
import { useEffect, useRef } from "react";

// import DefaultButton from "components/DefaultButton";
// import InputText from "components/Inputs/InputText";
// import LogoHeader from "components/LogoHeader";
// import Label from "components/InfoLabel";
import Footer from "modules/Previews/Footer";
import CarouselSlider from "components/CarouselSlider";
import placeholder from "assets/images/image_placeholder.png";
import Welcome from "./Welcome";
import VideoCarousel from "./VideoCarousel";
import AppAd from "./AppAd";
import Testimonials from "./Testimonials";
import BottomLead from "./BottomLead";

const PreviewPage = (props) => {
  const {
    title,
    subtitle,
    text,
    fontColor,
    backgroundImage,
    backgroundColor,
    primaryColor,
    secondaryColor,
  } = props;

  const background = useRef(null);

  useEffect(() => {
    if (backgroundImage instanceof File && FileReader) {
      let fileReader = new FileReader();
      fileReader.onload = () =>
        (background.current.style.backgroundImage = `url(${fileReader.result})`);
      fileReader.readAsDataURL(backgroundImage);
    }
  }, [backgroundImage]);

  return (
    <div
      style={{
        aspectRatio: "16/9",
        backgroundColor: "black",
        overflowY: "scroll",
      }}
    >
      <CarouselSlider
        arrows={false}
        swiperProps={{
          breakpoints: null,
          loop: true,
          autoplay: {
            delay: 7000,
            disableOnInteraction: false,
          },
        }}
        style={{
          marginTop: "-10px",
        }}
      >
        {new Array(3).fill(undefined).map((banner, key) => (
          <a key={key}>
            <div
              style={{
                width: "100%",
                // aspectRatio: "10/3",
                aspectRatio: "21/6",
                backgroundSize: "contain",
                backgroundImage: `url(${placeholder})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundColor: banner?.bgcolor ?? "#fff",
              }}
            />
          </a>
        ))}
      </CarouselSlider>
      <Welcome />
      <VideoCarousel bgcolor={primaryColor} />
      {/* <AppAd/> */}
      <Testimonials bgcolor={primaryColor} />
      <BottomLead />
      <Footer bgcolor={primaryColor} />
    </div>
  );
};

export default PreviewPage;

import { useState } from "react";
import Formbody from './Formbody';
import { Post } from "utils/request";
import { useDispatch} from "react-redux";
import { Show } from "actions/SnackbarActions";
import SweetAlert from "react-bootstrap-sweetalert";

const AdvertisingVideo = () => {
    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const [loading, SetLoading] = useState(false);

    const dispatch = useDispatch();

    const Submit = async (data) => {
        let form = new FormData();
        console.log(data?.video[0]);
        form.append("video", data?.video[0]);
        form.append("link", data?.link);

        SetLoading(true);
        let response = await Post("site-rt/advertising-video/update", form);
        SetLoading(false);

        dispatch(Show({
            show: true,
            message: response?.message,
            severity: response?.severity
        }));
    }

    return (
        <div className="card">
            <SweetAlert
                warning
                show={popup}
                title="Atenção!"
                confirmBtnText="Ok"
                onConfirm={() => SetPopup(false)}
            >
                {popupMessage}
            </SweetAlert>
            <div className="card-body">
                <h2 className="title">
                    Editar primeiro conteúdo do site RT
                </h2>
                <p>Aqui é editado o primeiro conteúdo da página RT</p>
                <br/>
                
                <Formbody
                    OnConfirm={data => Submit(data)}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default AdvertisingVideo;
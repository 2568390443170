import React, { useEffect, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useParams, useHistory } from "react-router";
import { Get, Post } from "utils/request";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { Show } from "actions/SnackbarActions";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Box,
  Collapse,
  FormControlLabel,
  Switch,
  ThemeProvider,
  Checkbox,
} from "@mui/material";
import { primaryBlue } from "utils/Themes";

import DateFnsUtils from "@date-io/date-fns";
import DefaultButton from "components/DefaultButton";
import DiscountInput from "./DiscountInput";
import CodeInput from "./CodeInput";

const FormBody = (props) => {
  const { loading, OnConfirm } = props;

  const [name, SetName] = useState("");
  const [code, SetCode] = useState("");
  const [[discount, discountMode], SetDiscount] = useState(["0%", 0]);
  const [toAll, SetToAll] = useState(false);
  const [isEternal, SetIsEternal] = useState(false);
  const [start, SetStart] = useState(Date.now());
  const [end, SetEnd] = useState(Date.now());
  const [singleUse, setSingleUse] = useState(null);

  const { coupon_id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, error } = useQuery(
    ["coupon", coupon_id],
    async () => await Get(`coupon?id=${coupon_id}`),
    {
      onSuccess: ({ coupon }) => {
        const {
          name,
          code,
          discount_amount,
          discount_percentage,
          start_date,
          end_date,
          discountMode,
          is_eternal,
          to_all,
          single_use,
        } = coupon || {};
        SetName(name);
        SetCode(code);
        SetStart(new Date(start_date + "T00:00:00").toISOString());
        SetEnd(new Date(end_date + "T00:00:00").toISOString());
        SetIsEternal(is_eternal);
        setSingleUse(single_use);
        SetToAll(to_all);
        if (discountMode == "amount") {
          SetDiscount([
            parseFloat(discount_amount) % 1 == 0
              ? discount_amount * 100
              : discount_amount,
            discountMode,
          ]);
        } else if (discountMode == "percentage")
          SetDiscount([discount_percentage, discountMode]);
      },
      refetchOnWindowFocus: false,
    }
  );

  const submitMutation = useMutation(
    async () => {
      const form = new FormData();
      form.append("id", coupon_id);
      form.append("name", name);
      form.append("code", code);
      form.append("start_date", new Date(start).toISOString().split("T")[0]);
      form.append("end_date", new Date(end).toISOString().split("T")[0]);
      form.append("is_eternal", isEternal);
      form.append("single_use", singleUse);
      form.append("to_all", toAll);

      if (discountMode == "percentage")
        form.append("discount_percentage", parseInt(discount));
      else if (discountMode == "amount")
        form.append(
          "discount_amount",
          parseFloat(discount.replace(/[^0-9,]/g, "").replace(",", "."))
        );

      return await Post("coupon/alter", form);
    },
    {
      onSuccess: ({ message, severity }) => {
        dispatch(
          Show({
            show: true,
            message: message,
            severity: severity,
          })
        );
        history.push("/coupon");
      },
    }
  );

  if (isLoading) return <Skeleton />;
  return (
    <div className="form-course">
      <form>
        <FormLabel>
          Nome <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Nome"
          value={name}
          onChange={(e) => SetName(e.target.value)}
          required
        />
        <br />
        <FormLabel>
          Código do cupom <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <CodeInput
          placeholder="Código do cupom"
          value={code}
          onChange={(code) => SetCode(code)}
          required
        />
        <br />
        <br />
        <FormLabel>
          Valor de desconto <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <DiscountInput
          onChange={(value) => SetDiscount(value)}
          value={discount}
          mode={discountMode}
        />
        <br />
        <br />
        <div className="flex fdcolumn">
          <FormLabel>
            Uso único <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <ThemeProvider theme={primaryBlue}>
            <FormControlLabel
              control={
                <Switch
                  checked={singleUse}
                  onChange={(e) => setSingleUse(e.target.checked)}
                  color="primary"
                />
              }
              label={singleUse ? "Apenas um uso" : "Infinito"}
            />
          </ThemeProvider>
          <br />

          <FormLabel>
            Período de validade <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <ThemeProvider theme={primaryBlue}>
            <FormControlLabel
              control={
                <Switch
                  checked={isEternal}
                  onChange={(e) => SetIsEternal(e.target.checked)}
                  color="primary"
                />
              }
              label={"Eterno"}
            />
          </ThemeProvider>
          <Collapse in={!isEternal}>
            <Box width="100%" display="flex" flexDirection={"column"}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM"
                  margin="normal"
                  id="date-picker-inline"
                  label="De"
                  value={start}
                  onChange={SetStart}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM"
                  margin="normal"
                  id="date-picker-inline"
                  label="Até"
                  value={end}
                  onChange={SetEnd}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Collapse>
        </div>
        <br />
        <div className="flex fdcolumn">
          <FormLabel>Disponível para todos os usuários</FormLabel>
          <ThemeProvider theme={primaryBlue}>
            <FormControlLabel
              label={"Para todos"}
              control={
                <Checkbox
                  checked={toAll}
                  onChange={(e) => SetToAll(e.target.checked)}
                  color="primary"
                />
              }
            />
          </ThemeProvider>
        </div>
        <br />
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to="/coupon"
        />
        <DefaultButton
          bg="confirm"
          text="Salvar mudanças"
          onClick={() => {
            submitMutation.mutate();
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div className="skeleton-loading">
      <FormLabel>
        Nome <span style={{ color: "red" }}>*</span>
      </FormLabel>
      <div className="skeleton-base skeleton-shimmer w100 height2" />
      <br />
      <div className="flex jcsb w100">
        <div className="skeleton-small-button skeleton-shimmer" />
        <div className="skeleton-medium-button skeleton-shimmer" />
      </div>
    </div>
  );
};

export default FormBody;

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import module_style from "./index.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import LoginIcon from "@mui/icons-material/Login";
import rt_logo from "assets/images/rt/main_logo_bgtransparent.png";
import { STORAGE_URL } from "variables";
import { Link } from "react-router-dom";

const Login = (props) => {
  const {
    title,
    subtitle,
    text,
    section_main_color,
    section_left_color,
    section_right_color,
    inputColor,
    inputBgColor,
    accentColor,

    backgroundImage,
    fontColor,
  } = props;

  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [coverImageSrc, setCoverImageSrc] = useState("");
  const [showPassword, SetShowPassword] = useState(false);

  // foca o input da div
  const focusInput = (e) => {
    e.currentTarget.querySelector("input").focus();
  };

  useEffect(() => {
    if (backgroundImage === undefined || backgroundImage === null) return;
    if (backgroundImage === "" || backgroundImage.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setCoverImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(backgroundImage);
    } catch (error) {
      setCoverImageSrc(`${STORAGE_URL}${backgroundImage}`);
    }
  }, [backgroundImage]);

  return (
    <div
      className={module_style.LoginContainer}
      style={{
        backgroundColor: section_main_color,
      }}
    >
      <Box
        sx={{
          display: "flex",
          margin: "auto",
          borderRadius: "0.4rem",
          overflow: "hidden",
          boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.1)",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            backgroundColor: section_left_color,
            padding: "4rem",
            gap: "2rem",
            display: "flex",
            flexDirection: "column",
            width: "100%!important",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundImage: `url(${coverImageSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {
            console.log(coverImageSrc)
          }
          <img src={rt_logo} style={{ objectFit: "contain", width: "10rem" }} />
          <Typography
            variant="h6"
            sx={{ textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: section_right_color,
            padding: "4rem",
            width: "100%!important",
          }}
        >
          <Typography
            variant="h6"
            sx={{ textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Typography
            variant="body1"
            sx={{ textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.4rem",
              marginTop: "2rem",
            }}
          >
            <div
              className={module_style.FormInputContainer}
              style={{
                borderBottom: `0.1rem solid ${inputColor}`,
                backgroundColor: inputBgColor,
              }}
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <EmailIcon sx={{ color: inputColor }} />
              <input
                type="text"
                placeholder="E-mail"
                value={email}
                onChange={(e) => {
                  SetEmail(e.target.value);
                }}
                style={{
                  color: inputColor,
                }}
                // error={msgErro}
              />
            </div>

            <div
              className={module_style.FormInputContainer}
              style={{
                borderBottom: `0.1rem solid ${inputColor}`,
                backgroundColor: inputBgColor,
              }}
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <HttpsIcon sx={{ color: inputColor }} />
              <input
                type={showPassword ? "password" : "text"}
                placeholder="Senha"
                value={password}
                onChange={(e) => {
                  SetPassword(e.target.value);
                }}
                style={{
                  color: inputColor,
                }}
                // error={msgErro}
              />
              <IconButton
                onClick={() => SetShowPassword(!showPassword)}
                sx={{
                  padding: 0,
                  color: inputColor,
                }}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <div className="flex fdrow align-center text-muted">
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: accentColor,
                        opacity: 0.8,
                        "&.Mui-checked": {
                          color: accentColor,
                          opacity: 1,
                        },
                      }}
                    />
                  }
                  style={{ color: fontColor }}
                  label="Lembre-se de mim"
                />
              </div>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: accentColor,
                  color: fontColor,
                  padding: "0.5rem 1.6rem",
                  "&:hover": {
                    backgroundColor: accentColor,
                    filter: "brightness(0.9)",
                  },
                }}
              >
                <LoginIcon style={{ marginRight: "0.5rem" }} />
                Entrar
              </Button>
            </div>
            {/* <span style={{ color: fontColor }}>
              Se você não tiver uma conta,{" "}
              <Link className="text-primary">Cadastre-se agora!</Link>
            </span> */}
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default Login;

import { useEffect, useRef, useState } from "react";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/material";

const ModuleCollapse = ({ children, title, first }) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(first || false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(ref.current.scrollHeight);
  }, [children]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          cursor: "pointer",
          alignItems: "center",
          padding: "0.4rem 0.8rem",
          margin: '0.4rem',
          borderRadius: '0.5rem',
          backgroundColor: open ? "#e0e0e0" : "transparent",
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
        }}
        onClick={() => setOpen(!open)}
      >
        <OndemandVideoIcon />
        <span
          style={{
            fontWeight: "bold",
            fontSize: "1.1rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </span>
        <KeyboardArrowDownIcon
          style={{
            marginLeft: "auto",
            transform: open ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Box>

      <div
        ref={ref}
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          transition: "height 0.3s ease-in-out",
          gap: "0.5rem",
          height: open ? height - 3 : 0,
          margin: "0.4rem",
          marginTop: 0,
          borderRadius: "0.5rem",
        }}
      >
        {children}
      </div>

      <hr />
    </Box>
  );
};

export default ModuleCollapse;

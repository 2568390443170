import React, { useState } from "react";

import Board from "./Board";

import { Get } from "utils/request";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { Pagination } from "@mui/material";
import { useQuery } from "react-query";

const Table = props => {

    const {
        search = ""
    } = props;

    const [page, SetPage] = useState(1);

    const dispatch = useDispatch();

    const { isLoading, data, error, refetch } = useQuery(["coupons", page, search], async () => await Get(`coupon?search=${search}&page=${page}`), {
        onError: () => {
            dispatch(Show({
                show: true,
                message: "Falha ao carregar os cupons",
                severity: "warning"
            }));
        }
    });

    const { 
        coupons = [],
        pagination: { last_page: maxPage = 1 } = { last_page: 1 }
    } = data || {};

    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Nome</th>
                            <th>Valor de desconto</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && coupons?.map((value, index) => (
                            <Board
                                key={index}
                                {...value}
                                OnDelete={refetch}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="w100 flex jcc">
                <CircularProgress hidden={!isLoading}/>
                <p hidden={coupons?.length !== 0 || isLoading}>Nenhum cupom foi achado</p>
            </div>
            <Pagination
                className={(maxPage == 1 ? "hide" : "")}
                style={{display: "flex", justifyContent: "flex-end"}} 
                count={maxPage} 
                value={page}
                onChange={(_, value) => SetPage(value)}
            />
        </div>
    );
}

export default Table;
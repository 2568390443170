import { Box, Card, CardContent, Typography } from "@mui/material";
import { FaMedal, FaBalanceScale } from "react-icons/fa";
import { GiGiftOfKnowledge } from "react-icons/gi";

import styles from "./index.module.css";

const Cards = () => {
    return (
        <Box
            bgcolor={"primary.dark"}
            color={"primary.contrastText"}
        >
            <div className={styles.cardsGroup}>
                <Card>
                    <CardContent className={styles.cardContent}>
                        <Box color="secondary.main">
                            <FaMedal className={styles.icon} color="secondary"/>
                        </Box>
                        <Typography
                            variant="h5"
                            align="center"
                            gutterBottom
                            className={styles.cardsTitle}
                        >
                            Qualidade
                        </Typography>
                        <Typography align="center">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eget finibus velit. Nullam vel justo porttitor, ornare odio sed, euismod neque. Etiam nisi felis, condimentum in velit a, luctus ullamcorper elit. Curabitur lacus ante, sodales quis vestibulum non, lobortis euismod augue. Vivamus semper aliquet suscipit. Quisque eleifend lectus orci, et luctus purus elementum eu. Nullam ultrices auctor orci a porttitor.
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent className={styles.cardContent}>
                        <Box color="secondary.main">
                            <FaBalanceScale className={styles.icon} color="secondary"/>
                        </Box>
                        <Typography
                            variant="h5"
                            align="center"
                            gutterBottom
                            className={styles.cardsTitle}
                        >
                            Integridade
                        </Typography>
                        <Typography align="center">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eget finibus velit. Nullam vel justo porttitor, ornare odio sed, euismod neque. Etiam nisi felis, condimentum in velit a, luctus ullamcorper elit. Curabitur lacus ante, sodales quis vestibulum non, lobortis euismod augue. Vivamus semper aliquet suscipit. Quisque eleifend lectus orci, et luctus purus elementum eu. Nullam ultrices auctor orci a porttitor.
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent className={styles.cardContent}>
                        <Box color="secondary.main">
                            <GiGiftOfKnowledge className={styles.icon} color="secondary"/>
                        </Box>
                        <Typography
                            variant="h5"
                            align="center"
                            gutterBottom
                            className={styles.cardsTitle}
                        >
                            Experiência
                        </Typography>
                        <Typography align="center">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eget finibus velit. Nullam vel justo porttitor, ornare odio sed, euismod neque. Etiam nisi felis, condimentum in velit a, luctus ullamcorper elit. Curabitur lacus ante, sodales quis vestibulum non, lobortis euismod augue. Vivamus semper aliquet suscipit. Quisque eleifend lectus orci, et luctus purus elementum eu. Nullam ultrices auctor orci a porttitor.
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </Box>
    );
}

export default Cards;
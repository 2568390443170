import React, { useState } from "react";

import { MdModeEdit, MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from "components/DefaultButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Post } from "utils/request";
import { Show } from "actions/SnackbarActions";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";
import { useParams } from "react-router";
import Portal from "components/Portal";

const Board = props => {

    const {
        userId,
        name,
        email,
        OnDelete
    } = props;

    const [popup, SetPopup] = useState(false);
    const [loading, SetLoading] = useState(false);

    const { group_id } = useParams();

    const dispatch = useDispatch();

    const Delete = async () => {
        let form = new FormData();
        form.append("user_id", userId);
        form.append("group_id", group_id);

        SetLoading(true);
        let response = await Post("groups/users/delete", form);
        SetLoading(false);

        if (response?.status) {
            dispatch(Show({
                show: true,
                message: response?.message,
                severity: response?.severity
            }));
            OnDelete();
        }
    }

    return (
        <tr>
            <td>{name}</td>
            <td>{email}</td>
            <td>
                <Portal id="sweetalert">
                    <SweetAlert
                        warning
                        title={"Atenção"}
                        onConfirm={() => {
                            Delete();
                            SetPopup(false);
                        }}
                        onCancel={() => SetPopup(false)}
                        show={popup}
                        confirmBtnText='Deletar'
                        cancelBtnText='Cancelar'
                        confirmBtnBsStyle="primary"
                        cancelBtnBsStyle="danger"
                        showCancel={true}
                    >
                        Tem certeza que deseja remover esse usuário da turma?
                    </SweetAlert>
                </Portal>
                <DefaultButton
                    to={`/profile/view/${userId}`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Ver usuário`}
                    bg="warning"
                    icon={<BsFillEyeFill size={17} color="white"/>}
                    style={{
                        margin: "0 2px"
                    }}
                />
                <RoleBased>
                    <DefaultButton
                        onClick={() => SetPopup(true)}
                        width="2.2em"
                        height="2.2em"
                        padding={0}
                        title={`Remover do turma`}
                        bg="danger"
                        loading={loading}
                        icon={!loading && <MdDelete size={17} color="white"/>}
                    />
                </RoleBased>
            </td>
        </tr>
    );
}

export default Board;
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";

// mui icons
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DescriptionIcon from "@mui/icons-material/Description";

import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const card_icon_style = {
  flexBasis: "max-content",
  backgroundColor: "#f3f3f3",
  padding: "0.8rem 1.2rem",
  borderRadius: "0.6rem",
  gap: "0.6rem",
  display: "flex",
};

const ModuleCard = ({ module }) => {
  const [videos_amount, setVideosAmount] = useState(0);
  const [files_amount, setFilesAmount] = useState(0);
  const [documents_amount, setDocumentsAmount] = useState(0);

  useEffect(() => {
    if (module !== undefined) {
      module?.lessons?.map((lesson) => {
        setVideosAmount(videos_amount + lesson.files.videos.length);
        setFilesAmount(files_amount + lesson.files.files.length);
        setDocumentsAmount(documents_amount + lesson.files.documents.length);
      });
    }
  }, [module]);

  return (
    <Box
      sx={{
        flexBasis: "26rem",
        backgroundColor: "white",
        boxShadow: "0 1rem 2rem rgba(0,0,0, 0.2)",
        padding: "2rem",
        borderRadius: "0.8rem",
        transition: "450ms 800ms ease",
        gap: "3.2rem",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",

        "&:hover": {
          transform: "scale(1.06)",
          transition: "250ms ease",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-1rem",
          right: "-1rem",
          backgroundColor: "red",
          padding: '1rem',
          borderRadius: '50%',
          background: 'linear-gradient(212deg, rgba(31,162,255,1) 0%, rgba(18,216,250,1) 50%, rgba(166,255,203,1) 100%)'
        }}
      >
        <ThumbUpAltRoundedIcon sx={{fontSize: '2rem', color: 'white'}} />
      </div>

      <Typography variant="h4" sx={{ textAlign: "center" }}>
        {module.name}
      </Typography>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          // backgroundColor: "red",
          gap: "1rem",
          justifyContent: "center",
        }}
      >
        <div style={card_icon_style}>
          <OndemandVideoIcon />
          {module?.lessons?.length > 1 ? (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {module.lessons.length} Aulas
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {module.lessons.length} Aula
            </Typography>
          )}
        </div>

        <div style={card_icon_style}>
          <PlayCircleOutlineIcon />
          {videos_amount > 1 ? (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {videos_amount} Vídeos
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {videos_amount} Vídeo
            </Typography>
          )}
        </div>

        {/* <div style={card_icon_style}>
          <AttachFileIcon />
          {files_amount > 1 ? (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {files_amount} Arquivos
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {files_amount} Arquivo
            </Typography>
          )}
        </div> */}

        <div style={card_icon_style}>
          <DescriptionIcon />
          {documents_amount > 1 ? (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {documents_amount} Documentos
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {documents_amount} Documento
            </Typography>
          )}
        </div>
      </div>
    </Box>
  );
};

export default ModuleCard;

import { useState } from "react";
import Formbody from "./Formbody";
import { Post } from "utils/request";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import SweetAlert from "react-bootstrap-sweetalert";

const AsideBannerAdd = ({ handleClose, refetch }) => {
  const [popup, SetPopup] = useState(false);
  const [popupMessage, SetPopupMessage] = useState("");

  const [loading, SetLoading] = useState(false);

  const dispatch = useDispatch();

  const Submit = async (data) => {
    let form = new FormData();
    form.append("image", data?.banner);
    form.append("link", data?.link);

    SetLoading(true);
    let response = await Post("styles/pages/course_wall/create", form);
    SetLoading(false);

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );
    handleClose();
    refetch();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SweetAlert
        warning
        show={popup}
        title="Atenção!"
        confirmBtnText="Ok"
        onConfirm={() => SetPopup(false)}
      >
        {popupMessage}
      </SweetAlert>
      <div className="card-body">
        <h2 className="title">Adicionar banner</h2>
        <Formbody OnConfirm={(data) => Submit(data)} loading={loading} />
      </div>
    </div>
  );
};

export default AsideBannerAdd;

import { Header } from "modules/Header";
import Footer from "Home/Footer";
import { useMainPages } from "modules/Header/data";

// sections
import Welcome from "./Welcome";
import AppAd from "./AppAd";
import BottomLead from "./BottomLead";
import FreeCourses from "./FreeCourses";
import Testimonials from "./Testimonials";
import Banner from "./Banner";
import WhatsappButton from "components/WhatsappButton";
import { Get } from "utils/request";
import { useQuery } from "react-query";

const LandingPage = (props) => {
  const { data: descriptionData } = useQuery(
    "landingDescription",
    async () => await Get(`site-rt/description?type=firstContent`)
  );
  const { page: description } = descriptionData || {};

  const pages = useMainPages();
  return (
    <div style={{ overflow: "hidden", position: "relative" }}>
      <Header pages={pages} />
      <Banner {...{ description }} />
      <Welcome {...{ description }} />
      <FreeCourses {...{ description }} />
      <Testimonials />
      {/* <AppAd /> */}
      <BottomLead />
      <WhatsappButton />
      <Footer />
    </div>
  );
};

export default LandingPage;

import React, { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "components/DefaultButton";
import { useParams } from "react-router";

const FormBody = (props) => {
  const { loading, OnConfirm } = props;

  const [name, SetName] = useState("");
  const [disponibility, SetDisponibility] = useState(0);

  const { course_id } = useParams();

  return (
    <div className="form-course">
      <form>
        <FormLabel>
          Nome <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Nome"
          value={name}
          maxLength={255}
          onChange={(e) => SetName(e.target.value)}
          required
        />
        <br />
        <FormLabel>
          Disponiblidade após a compra (em dias){" "}
          <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Disponiblidade após a compra (em dias)"
          value={disponibility}
          type="number"
          min={0}
          onChange={(e) => {
            if (![".", "-", ","].includes(e.nativeEvent.data))
              SetDisponibility(e.target.value);
          }}
          required
        />
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to={`/courses/view/${course_id}`}
        />
        <DefaultButton
          bg="confirm"
          text="Criar módulo"
          onClick={() => {
            let data = {
              name: name,
              disponibility: disponibility,
            };
            OnConfirm(data);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FormBody;

import React from "react";
import NoAuth from "./NoAuth";

import "./index.css";
import { useSelector } from "react-redux";
import SocialMedias from "../../components/SocialMedias";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

import logo from 'assets/images/rt/white_logo.png'
import { Container } from "@mui/system";

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

const footer_links = {
  "about": { title: "Sobre nós", behavior: "_self" },
  "integrative": { title: "Saúde integrativa", behavior: "_self" },
  "courses": { title: "Cursos", behavior: "_self" },
  "science-plus": { title: "+CIÊNCIA", behavior: "_self" },
};

const social_medias_button_style = {fontSize: '2.1rem', opacity: '0.6', cursor: 'pointer', '&:hover': {opacity: 1}}

const Footer = () => {
  const generalStyle = useSelector((store) => store.AppReducer.generalStyle);
  const history = useHistory();

  return (
    <div
      className="footer"
      style={{
        backgroundColor: "#353940",
        color: 'white'
      }}
    >
      {/* <NoAuth/> */}
      {/* <div className="contact-data">
                <h6 className="font-weight-bold">Entre em contato</h6>
                <p 
                    hidden={!generalStyle.phone_number}
                >Fone: {generalStyle?.phone_number}</p>
                <p
                    hidden={!generalStyle?.email}
                >E-mail: {generalStyle?.email}</p>
                <p
                    hidden={!generalStyle.whatsapp_number}
                >Whatsapp: {generalStyle?.whatsapp_number}</p>
            </div> */}

      <Container maxWidth="xl" sx={{display: 'flex', justifyContent: 'space-between', '& a': {color: 'rgb(210, 210, 210)', textDecoration: 'none'}}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <a href="/">
            <img style={{height: '6rem',width:'10rem', objectFit: 'contain'}} src={logo} alt="RT Logotipo" />
          </a>
          <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '1rem'}}>
            <a href="https://www.instagram.com/renata.trinca/" target={'_blank'}>
              <InstagramIcon sx={social_medias_button_style} />
            </a>
            <a href="https://www.facebook.com/renata.trinca.1" target={'_blank'}>
              <FacebookIcon sx={social_medias_button_style} />
            </a>
            <a href="https://www.youtube.com/channel/UC2URM9sZHIiSh7uBsMlic5g" target={'_blank'}>
              <YouTubeIcon sx={social_medias_button_style} />
            </a>
          </div>
        </div>
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          // height: { md: "8rem", xs: "auto" },
          height: 'max-content',
          gap: "0.2rem 1rem",
          width: 'max-content',
          // transform: { md: "translateX(-54%)", xs: "auto" },
        }}
      >
        {Object.keys(footer_links).map((key) => (
          <span key={key} style={{ width: 'max-content' }}>
            <a href={"/" + key} target={footer_links[key].behavior}>
              {footer_links[key].title}
            </a>
          </span>
        ))}
      </Box>
      </Container>

      <div id="copyright" style={{
        textAlign: "center",
      }}>
        {/* <SocialMedias className="jcc" /> */}
        <span className="text-muted">
          <a
            onClick={() => history.push("/terms")}
            style={{ cursor: "pointer", color: 'rgb(210, 210, 210)', textDecoration: 'none' }}
          >
            Termos de uso
          </a>
          <span>&nbsp;•&nbsp;</span>
          <a
            onClick={() => history.push("/policy-terms")}
            style={{ cursor: "pointer", color: 'rgb(210, 210, 210)', textDecoration: 'none' }}
          >
            Políticas de privacidade
          </a>
          <br />
          <span>
            Direitos reservados RT Saúde Integrativa • CNPJ 37.123.095/0001-140
          </span>
        </span>
      </div>
    </div>
  );
};

export default Footer;

import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import TextCard from "../../TextCard";

const WhatWillLearn = ({ props }) => {
  const {
    show_section2,
    whatWillLearn,
    section2_title,
    section2_bgColor_1,
    section2_bgColor_2,
    section2_cardTextSize,

    section2_cardBorderColor,
    section2_cardBorderSize,

    section2_cardColor,
    section2_cardTextColor,
  } = props;

  const [whatWillLearnCards, setWhatWillLearnCards] = useState([]);

  useEffect(() => {
    const temp = whatWillLearn.slice();
    let result = [];
    for (let i = 3; i > 0; i--) {
      result.push(temp.splice(0, Math.ceil(temp.length / i)));
    }
    setWhatWillLearnCards(result);
  }, [whatWillLearn]);

  return (
    show_section2 && (
      <section
        style={{
          padding: "6rem 0",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          background: `linear-gradient(180deg, ${section2_bgColor_1} 0%, ${section2_bgColor_2} 100%)`,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            color: "black",
          }}
        >
          Seção 2
        </Typography>
        <Container
          maxWidth="lg"
          sx={{ height: "100%!important", textAlign: "center" }}
        >
          <Typography
            sx={{
              marginBottom: "1rem",
            }}
            dangerouslySetInnerHTML={{ __html: section2_title }}
          />

          <div
            style={{
              display: "flex",
              gap: "0.8rem",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: "30rem",
                // flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "0.6rem",
                padding: "2rem 1.4rem",
                borderRadius: "0.8rem",
                backgroundColor: section2_cardColor,
                color: section2_cardTextColor,
                border: `${section2_cardBorderColor} solid ${section2_cardBorderSize}px`,
              }}
            >
              {whatWillLearn !== undefined &&
                whatWillLearn !== null &&
                whatWillLearnCards[0].map((user, index) => (
                  <TextCard
                    key={index}
                    text={user["text"]}
                    icon_type={1}
                    white_bg={true}
                    {...{ section2_cardTextSize }}
                  />
                ))}
            </div>

            {whatWillLearnCards[1].length > 0 && (
              <div
                style={{
                  width: "100%",
                  maxWidth: "30rem",
                  // flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.6rem",
                  padding: "2rem 1.4rem",
                  borderRadius: "0.8rem",
                  backgroundColor: section2_cardColor,
                  color: section2_cardTextColor,
                  border: `${section2_cardBorderColor} solid ${section2_cardBorderSize}px`,
                }}
              >
                {whatWillLearn !== undefined &&
                  whatWillLearn !== null &&
                  whatWillLearnCards[1].length &&
                  whatWillLearnCards[1].map((user, index) => (
                    <TextCard
                      key={index}
                      text={user["text"]}
                      icon_type={1}
                      white_bg={true}
                      {...{ section2_cardTextSize }}
                    />
                  ))}
              </div>
            )}

            {whatWillLearnCards[2].length > 0 && (
              <div
                style={{
                  width: "100%",
                  maxWidth: "30rem",
                  // flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.6rem",
                  padding: "2rem 1.4rem",
                  borderRadius: "0.8rem",
                  backgroundColor: section2_cardColor,
                  color: section2_cardTextColor,
                  border: `${section2_cardBorderColor} solid ${section2_cardBorderSize}px`,
                }}
              >
                {whatWillLearn !== undefined &&
                  whatWillLearn !== null &&
                  whatWillLearnCards[2].map((user, index) => (
                    <TextCard
                      key={index}
                      text={user["text"]}
                      icon_type={1}
                      white_bg={true}
                      {...{ section2_cardTextSize }}
                    />
                  ))}
              </div>
            )}
          </div>
        </Container>
      </section>
    )
  );
};

export default WhatWillLearn;

export const generateButtonVariants = {
    on: {
        rotate: [0, 180],
        transition: {
            duration: 0.3
        }
    },
    off: {
        rotate: [180, 360],
        transition: {
            duration: 0.3
        }
    }
};
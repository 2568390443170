import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Get } from "utils/request";
import { Card, CardHeader, CardContent, Typography, Switch, FormControlLabel, CardActions, CircularProgress } from "@mui/material";

import Module from "./Module";
import DefaultButton from "components/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";

import styles from "./index.module.css";
import { removeHTML } from "utils/filters";

const ExerciseStatistics = () => {

    const [user, SetUser] = useState({});
    const [course, SetCourse] = useState({});
    const [modules, SetModules] = useState([]);
    const [onlyFilledQuestions, SetOnlyFilledQuestions] = useState(true);
    const [onlyFilledAnswers, SetOnlyFilledAnswers] = useState(false);

    const [loading, SetLoading] = useState(false);

    const { course_id, user_id } = useParams();

    const GetData = async () => {
        SetLoading(true);
        let response = await Get(`modules?userId=${user_id}&page=0&courseId=${course_id}`);
        SetLoading(false);
         
        if (response?.status === true) {
            SetModules(response?.modules);
            SetCourse(response?.course);
            SetUser(response?.user);
        }
    }

    useEffect(GetData, []);

    return (
        <Card>
            <CardHeader
                title={`Estatísticas do usuário no curso`}
                subheader={<span>Nome do usuário: {user?.name}<br/>Função: {user?.role}</span>}
            />
            <hr/>
            <div className={styles.courseInfo}>
                <Typography>
                    Nome do curso: {removeHTML(course?.name)}
                </Typography>
                <Typography>
                    Porcentagem média de acertos: {course?.averagePercentage}%
                </Typography>
            </div>
            <hr/>
            <CardContent>
                <div className="modules">
                    <Typography
                        variant="h6"
                    >
                        Módulos
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                            color="primary"
                                checked={onlyFilledAnswers}
                                onChange={e => SetOnlyFilledAnswers(e.target.checked)}
                            />
                        }
                        label="Mostrar apenas exercícios respondidos"
                    />
                    <hr/>
                    <div hidden={loading}>
                        {modules?.map((module, moduleKey) => (
                            <Module
                                module={module}
                                onlyFilledQuestions={onlyFilledQuestions}
                                onlyFilledAnswers={onlyFilledAnswers}
                                moduleKey={moduleKey}
                                key={moduleKey}
                                id={module?.id}
                            />
                        ))}
                    </div>
                    <div
                        className="flex align-center jcc w100"
                        hidden={!loading}
                    >
                        <CircularProgress/>
                    </div>
                </div>
            </CardContent>
            <CardActions>
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine/>}
                    to={`/courses/view/${course_id}`}
                />
            </CardActions>
        </Card>
    );
}

export default ExerciseStatistics;
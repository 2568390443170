import { useState } from "react";
import { FormControl as Input } from "react-bootstrap";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";

import Table from "./Table";
import DefaultButton from "components/DefaultButton";
import { useParams } from "react-router";

const UsersList = () => {

    const [search, SetSearch] = useState("");

    const { group_id } = useParams();
    const dispatch = useDispatch();

    return (
        <div>
            <h5 className="title">Lista de alunos</h5>
            <p className="text-muted ">Aqui são listados todos os alunos pertencentes a esta turma</p>
            <div className="flex jcsb flex-wrap margin-bottom" style={{marginTop:15}}>
                <Input
                    placeholder="Pesquisar pelo Nome"
                    value={search}
                    onChange={e => SetSearch(e.target.value)}
                    style={{ width: "50%" }}
                />
                <RoleBased>
                    <DefaultButton
                        bg="confirm"
                        text="Adicionar alunos"
                        to={`/groups/${group_id}/users/add`}
                    />
                </RoleBased>
            </div>
            <Table
                search={search}
            />
        </div>
    );
}

export default UsersList;
import { AppBar, Box, Toolbar, useTheme } from "@mui/material";

const Header = (props) => {

    const {
        logo,
        smallLogo
    } = props;

    const theme = useTheme();

    console.log(logo);

    return (
        <Box>
            <AppBar
                sx={{
                    position: "relative",
                    bgcolor: theme.palette?.tertiary?.main,
                    color: theme.palette.tertiary.contrastText
                }}
            >
                <Toolbar>
                    <Box
                        sx={{
                            display: {
                                xs: "none",
                                sm: "inherit"
                            },
                            backgroundImage: `url(${logo})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: "5em",
                            aspectRatio: "16/9"
                        }}
                    />
                    <Box
                        sx={{
                            display: {
                                xs: "block",
                                sm: "none"
                            },
                            backgroundImage: `url(${smallLogo})`,
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            width: "4em",
                            height: "4em"
                        }}
                    />
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header;
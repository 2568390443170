import { Dialog, DialogActions, Button, DialogContent, Typography, DialogTitle, Box, IconButton, useMediaQuery, Toolbar } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "@mui/styles";

const DynamicDialog = props => {

    const {
        title,
        children,
        fullscreenOnSmall = true,
        cancelProps = {},
        confirmProps = {},
        contentProps = {},
        onCancel,
        onConfirm,
        onClose = () => {},
        style,
        ...others
    } = props;

    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Dialog
            {...others}
            onClose={onClose}
            fullScreen={fullscreenOnSmall ? fullscreen : false}
            style={{
                zIndex: fullscreenOnSmall && fullscreen ? 2000 : "inherit",
                ...style
            }}
        >
            <DialogTitle
                style={{
                    backgroundColor: fullscreenOnSmall && fullscreen ? theme.palette.primary.main : "inherit",
                    color: fullscreenOnSmall && fullscreen ? theme.palette.primary.contrastText : "inherit"
                }}
            >
                    <Box
                        display={"inline-flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={"100%"}
                    >
                        <Typography>
                            {title}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
            </DialogTitle>
            <DialogContent
                {...contentProps}
            >
                {children}
            </DialogContent>
            {(onConfirm || onCancel) && (
                <DialogActions>
                    {onCancel && (
                        <Button
                            {...cancelProps}
                            onClick={onCancel}
                        >
                            Cancelar
                        </Button>
                    )}
                    {onConfirm && (
                        <Button
                            {...confirmProps}
                            onClick={onConfirm}
                        >
                            Confirmar
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
}

export default DynamicDialog;
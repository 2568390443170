import CheckIcon from '@mui/icons-material/Check';

export const paragraphs = [
    {
        content: "A saúde integrativa é um modelo inspanidualizado e centrado no cliente para promover a saúde e o bem-estar ideais, combinando toda uma abordagem de estratégias baseadas em evidências reduzindo o risco de doenças, em torno de comportamentos, hábitos e estilos de vida. Tradicionalmente, o termo saúde integrativa é definido como a mistura de métodos de medicina complementar e alternativa (CAM) conjuntamente com a medicina convencional ou alopática. (Jordan M.; 2016)."
    },
    {
        content: <span>O conceito de Saúde Integrativa ou práticas integrativas, surgiu nos Estados Unidos, nos anos 90, e foi explorado principalmente pelos médicos David Eisenberg e Andrew Weil. A saúde integrativa se baseia nos pilares do ser: Corpo, mente, alma para ter saúde. Tratando causa e consequência juntamente com homeostase do sono, estresse, atividade física, alimentação, saúde gastrintestinal, capacidade detox e equilíbrio hormonal; princípios válidos da <b>saúde preventiva.</b></span>,
    },
    {
        content: "O crescente campo da saúde integrativa reúne uma prática colaborativa que atrai novos pensamentos e soluções de um mundo de opções de cura, incluindo uma ampla compreensão dos sistemas corporais, levando mais saúde e qualidade de vida as pessoas. Bons exemplos são aceitos como medicina naturopática, quiropraxia, Ayurveda e Medicina Tradicional Chinesa (MTC)."
    },
    {
        content: "A saúde integrativa convida a colaboração entre os profissionais que empregam abordagens de saúde para nutrição, dieta, suplementação direcionada, condicionamento físico e exercício, gerenciamento de estresse, mudança de comportamento, coaching de saúde e yoga."
    },
    {
        content: <b>E quanto às evidências?</b>,
        textIndent: 0,
        margin: "1em 0"
    },
    {
        content: "Apenas cerca de 60% da medicina convencional é baseada em evidências; a maior parte é baseada em protocolos aceitos e prática histórica. Da mesma forma, nem tudo o que é natural (como em ervas, infusões, poções) é seguro ou eficaz. O teste de segurança foi tradicionalmente obtido através de séculos de uso histórico, até que ensaios clínicos mais recentes mostraram evidências de muitos remédios complementares e alternativos."
    },
    {
        content: "Devido às mudanças tecnológicas, socioeconômicas e culturais da sociedade, as práticas de cuidados foram spanididas em uma imensidão de tarefas e atividades spanersas. O cuidado humanizado teve uma forte influência sobre o processo de trabalho em saúde, caracterizado pelo reducionismo biológico, o mecanicismo da doença sobre o doente."
    },
    {
        content: "O renascimento das “medicinas alternativas” pode ser entendido como um fenômeno social decorrente desse movimento. Por medicina alternativa entende-se racionalidades e práticas que partilham de uma perspectiva vitalista, centrada na experiência de vida do paciente, com ênfase no doente e não na doença; e integradora, de caráter não intervencionista. A Organização Mundial da Saúde (OMS) denomina o campo das Práticas Integrativas e Complementares como Medicina Tradicional e Complementar / Alternativa (MT/MCA). Desde a década de 70 essa organização incentiva os Estados-Membros a formularem e implementarem políticas públicas para a utilização racional e integrada de MT/MCA na Atenção Primária em Saúde."
    },
    {
        content: <b>Saúde Complementar, Alternativa ou Integrativa: O que significam esses nomes</b>,
        textIndent: 0,
        margin: "1em 0"
    },
    {
        content: "Os termos “complementar”, “alternativo” e “integrativo” estão em constante evolução, juntamente com as áreas de atuação, mas as descrições desses termos abaixo são como nós, definimos atualmente. Ao descrever essas abordagens, as pessoas costumam usar “alternativa” e “complementar” de forma intercambiável, mas os dois termos se referem a conceitos diferentes:"
    },
    {
        content: <span><CheckIcon/>&nbsp;Se uma abordagem não convencional é usada <b>em conjunto com</b> a saúde convencional, é considerada “complementar”.</span>
    },
    {
        content: <span><CheckIcon/>&nbsp;Se uma abordagem não convencional for usada <b>no lugar da</b> saúde convencional, ela será considerada “alternativa”.</span>
    },
    {
        content: <span>A maioria das pessoas que usa abordagens não convencionais também usa cuidados de saúde convencionais. Além dos termos complementar e alternativo, você também pode ouvir o termo <b>“medicina funcional”.</b> Este termo às vezes se refere a um conceito semelhante à saúde integrativa.</span>
    },
    {
        content: <b>Saúde Integrativa</b>,
        textIndent: 0,
        margin: "1em 0"
    },
    {
        content: <span>A saúde integrativa reúne abordagens convencionais e complementares de <b>forma coordenada.</b> A saúde integrativa também enfatiza as intervenções multimodais, que são duas ou mais intervenções, como abordagens convencionais de saúde (como medicação, reabilitação física, psicoterapia) e abordagens de saúde complementares (como acupuntura, ioga e probióticos) em várias combinações, com ênfase em tratar a pessoa como um todo em vez de, por exemplo, um sistema de órgãos.</span>
    }
];
import HttpsIcon from '@mui/icons-material/Https';
import { useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';

const NewPassword = ({
  section_index,
  password,
  setPassword,
  password_confirmation,
  setPasswordConfirmation,
}) => {
  const [hide_password, setHidePassword] = useState(true);
  return (
    <div
      style={{
        display: section_index === 2 ? "flex" : "none",
        flexDirection: "column",
        gap: "1rem",
        top: "50%",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: "var(--Raleway)",
          margin: "auto 0",
          marginTop: "0",
          marginBottom: 0,
          textAlign: "center",
          color: "rgb(70, 70, 70)",
          display: { xs: "block", md: "block" },
        }}
      >
        Insira a nova senha que deseja utilizar.
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          maxWidth: "24rem",
          width: "100%",
        }}
      >
        {/* <div
          className="normal-input"
          onClick={(e) => {
            this.focusInput(e);
          }}
        >
          <HttpsIcon sx={{ color: "rgb(100, 100, 100)" }} />
          <input
            type={hide_password ? "password" : "text"}
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {hide_password ? (
            <VisibilityOffIcon
              sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
              onClick={() => setHidePassword(false)}
            />
          ) : (
            <VisibilityIcon
              sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
              onClick={() => setHidePassword(true)}
            />
          )}
        </div>

        <div
          className="normal-input"
          onClick={(e) => {
            this.focusInput(e);
          }}
        >
          <HttpsIcon sx={{ color: "rgb(100, 100, 100)" }} />
          <input
            type={hide_password ? "password" : "text"}
            placeholder="Senha"
            value={password_confirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          {hide_password ? (
            <VisibilityOffIcon
              sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
              onClick={() => setHidePassword(false)}
            />
          ) : (
            <VisibilityIcon
              sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
              onClick={() => setHidePassword(true)}
            />
          )}
        </div> */}

        <TextField
          sx={{ width: '100%' }}
          label="Senha"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={hide_password ? "password" : "text"}
          InputProps={{
            endAdornment: hide_password ? (
              <VisibilityOffIcon
                sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                onClick={() => setHidePassword(false)}
              />
            ) : (
              <VisibilityIcon
                sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                onClick={() => setHidePassword(true)}
              />
            ),
          }}
        />

        <TextField
          sx={{ width: '100%' }}
          label="Confirme a senha"
          variant="outlined"
          value={password_confirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          type={hide_password ? "password" : "text"}
          InputProps={{

            endAdornment: hide_password ? (
              <VisibilityOffIcon
                sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                onClick={() => setHidePassword(false)}
              />
            ) : (
              <VisibilityIcon
                sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                onClick={() => setHidePassword(true)}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default NewPassword;

export default (status) => {
  switch (status) {
    case "created":
      return { style: { backgroundColor: "#b3caff", color: '#113893' }, status: 'Criado' };

    case "pending":
      return { style: { backgroundColor: "#fff0be", color: '#7f742c' }, status: 'Pendente' }

    case "processing":
      return { style: { backgroundColor: "#fff0be", color: '#7f742c' }, status: 'Processando' }

    case "paid":
      return { style: { backgroundColor: "#c7e7c8", color: '#3e6243' }, status: 'Pago' };

    case "transferred":
      return { style: { backgroundColor: "#c7e7c8", color: '#3e6243' }, status: 'Transferido' };

    case "failed":
      return { style: { backgroundColor: "#ffc6cd", color: '#ac3b45' }, status: 'Falha' };

    case "canceled":
      return { style: { backgroundColor: "#ffc6cd", color: '#ac3b45' }, status: 'Cancelado' };

    default:
      return { style: { backgroundColor: "#ffffff", color: '#333' }, status };
  }
}
export const moneyMask = (value) => {
  try {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')
  } catch (e) {
    try {
      value = value.toString().replace('.', '').replace(',', '').replace(/\D/g, '')
    } catch (e) {
      return 0
    }
  }

  const options = { minimumFractionDigits: 2 }
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value) / 100
  )

  return 'R$ ' + result
}
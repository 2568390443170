import { Button } from "@mui/material";
import { removeHTML } from "utils/filters";

const Board = (props) => {
  const { data = {} } = props;

  return (
    <tr>
      <td>{removeHTML(data?.text)}</td>
      <td>{data?.action}</td>
      <td>
        <Button></Button>
      </td>
    </tr>
  );
};

export default Board;

import { createTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { getContrastText } from "utils/Themes/getContrastText";
import { fileToBase64 } from "utils/images/fileToBase64";
import { STORAGE_URL } from "variables";

const usePreviewPage = (props = {}) => {

    const {
        watch,
        logo: fileLogo,
        smallLogo: smallFileLogo,
        primaryColor,
        secondaryColor,
        tertiaryColor,
    } = props;

    const [logo, setLogo] = useState("");
    const [smallLogo, setSmallLogo] = useState("");

    const innerTheme = useMemo(() => createTheme({
        palette: {
            primary: {
                main: primaryColor
            },
            secondary: {
                main: secondaryColor
            },
            tertiary: {
                main: tertiaryColor,
                contrastText: getContrastText(tertiaryColor)
            }
        }
    }), [primaryColor, secondaryColor, tertiaryColor]);

    useEffect(() => fileToBase64(watch("header_icon")).then((res) => setLogo(res)).catch(() => setLogo(`${STORAGE_URL}${watch("header_icon")}`)), [watch("header_icon")]);
    useEffect(() => fileToBase64(watch("header_icon_small")).then((res) => setSmallLogo(res)).catch(() => setSmallLogo(`${STORAGE_URL}${watch("header_icon_small")}`)), [watch("header_icon_small")]);

    return {
        innerTheme,
        logo,
        smallLogo
    };
}

export default usePreviewPage;
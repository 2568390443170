import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Get, Post } from "utils/request";

const useComments = props => {

    const { user_id } = useParams();
    const { data, isLoading, refetch } = useQuery(["profileComments", user_id], async () => {
        if (!user_id) return {};
        return await Get(`lessons/files/comments/find?user_id=${user_id}`);
    });

    const { mutate: deleteComment, isLoading: deleteLoading } = useMutation(async (id) => {
        const form = new FormData();
        form.append("id", id);
    
        return await Post("lessons/files/comments/delete", form);
    }, {
        onSuccess: (data) => {
            ;
        }
    });
    const {
        comments = []
    } = data || {};
    
    return {
        comments,
        isLoading,
        deleteComment,
        refetch
    };
}

export default useComments;
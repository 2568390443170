import { Container, Typography } from "@mui/material";
import { STORAGE_URL } from "variables";

import default_user_image from "assets/images/default.png";
import { useEffect } from "react";

const Responsibles = ({ course }) => {
  const {
    responsibles,
    section5_accent_color,
    section5_accentColor,
    responsibles_bgColor_1,
    responsibles_bgColor_2,
    responsibles_accentColor,
    responsibles_textColor,
  } = course;

  useEffect(() => {
    console.log("responsibles", course);
  }, [course]);

  return (
    <section
      style={{
        padding: "6rem 0",
        background: `linear-gradient(180deg, ${responsibles_bgColor_1} 0%, ${responsibles_bgColor_2} 100%)`,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
        }}
      >
        {/* <Typography variant="h2" sx={{ fontWeight: "bold", color: "black" }}>
          Professores
        </Typography> */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4rem",
            width: "100%",
          }}
        >
          {responsibles &&
            responsibles.map((responsible, index) => (
              <div
                style={{
                  flexBasis: "max-content",
                  backgroundColor: responsibles_accentColor,
                  display: "grid",
                  gridTemplateColumns: "max-content 1fr",
                  gap: "1rem",
                  padding: "1.6rem 2rem",
                  borderRadius: "0.8rem",
                  boxSizing: "border-box",
                  width: "100%",
                }}
                key={index}
              >
                <div
                  style={{
                    display: "inline-block",
                    width: "10rem",
                    height: "10rem",
                    padding: "0.2rem",
                    borderRadius: "50%",
                    backgroundColor: section5_accentColor,
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    src={
                      responsible?.profile_image
                        ? STORAGE_URL + responsible?.profile_image
                        : default_user_image
                    }
                    alt={responsible.name}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      marginBottom: "0.8rem",
                      color: responsibles_textColor,
                    }}
                    dangerouslySetInnerHTML={{ __html: responsible?.name }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      color: responsibles_textColor,
                      textAlign: "justify",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: responsible?.description,
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </Container>
    </section>
  );
};

export default Responsibles;

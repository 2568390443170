import { useEffect } from "react";

const Authentication = () => {

    const Verify = async () => {

    }

    return null;
}

export default Authentication;
import Footer from "Home/Footer";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import DisplayCourses from "./DisplayCourses";
import { Get } from "utils/request";
import { Box } from "@mui/material";
import { STORAGE_URL } from "variables";

const CoursesUserView = () => {
  const [banners, SetBanners] = useState([]);

  const fetchBanners = async () => {
    const { data } = await Get("styles/pages/course_wall_netflix");
    console.log(data);
    return data;
  };

  useEffect(() => {
    fetchBanners().then((data) => SetBanners(data));
  }, []);

  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <div className="user-view">
          <div
            style={{
              width: "100%",
              position: "relative",
              zIndex: 20,
            }}
          >
            {banners?.length > 0 && (
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                renderIndicator={false}
                centerMode={false}
                showThumbs={false}
                transitionTime={500}
                interval={5000}
                showStatus={false}
              >
                {banners &&
                  banners.map((banner, index) => (
                    <a
                      key={banner.id}
                      href={banner.link}
                      target="_blank"
                      style={{
                        zIndex: 20,
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "30vh",
                          backgroundSize: "contain",
                          backgroundImage: `url(${STORAGE_URL + banner.image})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    </a>
                  ))}
              </Carousel>
            )}
          </div>

          {/* <CarouselGroup /> */}
          <DisplayCourses />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CoursesUserView;

import { LinearProgress, Typography } from "@mui/material";

import styles from "./index.module.css";

const ProgressLabel = props => {

    const {
        label,
        ...other
    } = props;

    return (
        <div className={styles.container}>
            <div className={styles.barContainer}>
                <LinearProgress
                    {...other}
                />
            </div>
            <Typography
                className={styles.label}
            >
                {label}
            </Typography>
        </div>
    );
}

export default ProgressLabel;
import JoditEditor from "jodit-react";
import { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Label from "components/InfoLabel";
import ImageInput from "components/Inputs/ImageInput";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const Section4Edit = (props) => {
  const {
    joditMissionText,
    setJoditMissionText,
    mission_bg_image,
    setMissionBgImage,
    mission_bg_gradient,
    setMissionBgGradient,
  } = props.section4;
  const { JoditConfig } = props;

  return (
    <>
      <FormLabel>
        Imagem de capa do card de missão
        <ImageInput
          path={mission_bg_image}
          ImageChange={(image) => setMissionBgImage(image)}
        />
      </FormLabel>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos recomendados:
        Não há um tamanho recomendado.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
        .jpeg, .png e .gif.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo.
      </span>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={mission_bg_gradient ? true : false}
              onChange={(e) => setMissionBgGradient(!mission_bg_gradient)}
            />
          }
          label="Habilitar degradê do card"
        />
      </FormGroup>

      <FormLabel>
        <Label
          label={<span>Missão</span>}
          required
          title="Texto sobre a missão RTSI"
        />
        <JoditEditor
          config={JoditConfig}
          value={joditMissionText}
          onChange={setJoditMissionText}
        />
      </FormLabel>
    </>
  );
};
export default Section4Edit;

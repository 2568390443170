import { Box, Fab, IconButton, Tooltip, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Post } from "utils/request";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ListButtonIcon from "./ListButtonIcon";
import ArticleIcon from "@mui/icons-material/Article";
import { Show } from "actions/SnackbarActions";
import { useContext, useEffect, useState } from "react";
import { CardContext } from "../..";
import { moneyMask } from "utils/moneyMask";
import { maskPrice } from "Auxiliar/Masks";
import { removeHTML } from "utils/filters";

const ActionsHome = (props) => {
  const {
    id,
    isUserIn,
    listed,
    available,
    price,
    free,
    usercourse,
    first_file_id: firstFileId,
    lastSeenLesson,
    onUpdate = () => {},
    setExpand = () => {},
    SetAnchorEl,
    listed_courses,
    expired,
    ...others
  } = props;

  const history = useHistory();
  const userId = useSelector((store) => store.AppReducer.user.id);
  const cardContext = useContext(CardContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [hasListed, setHasListed] = useState(false);

  const { mutate: addMutate } = useMutation(
    async () => {
      const form = new FormData();
      form.append("course_id", id);
      form.append("user_id", userId);

      return await Post("user-list-course/create", form);
    },
    {
      onSuccess: (data) => {
        onUpdate();
        dispatch(
          Show({
            show: true,
            message: `${removeHTML(data?.message)}`,
            severity: "success",
          })
        );
      },
      onError: () =>
        dispatch(
          Show({
            show: true,
            message: "Falha ao remover o curso da lista",
            severity: "error",
          })
        ),
    }
  );

  const { mutate: removeMutate } = useMutation(
    async () => {
      const form = new FormData();
      form.append("course_id", id);
      form.append("user_id", userId);

      return await Post("user-list-course/delete", form);
    },
    {
      onSuccess: (data) => {
        onUpdate();
        dispatch(
          Show({
            show: true,
            message: `${removeHTML(data?.message)}`,
            severity: "success",
          })
        );
      },
      onError: () =>
        dispatch(
          Show({
            show: true,
            message: "Falha ao remover o curso da lista",
            severity: "error",
          })
        ),
    }
  );

  const HandlePlayClick = (e) => {
    e.stopPropagation();
    if (usercourse !== null) {
      // if (available && true==false) {
      //     if (lastSeenLesson) history.push(`/courses/${id}/${lastSeenLesson?.file_id}`);
      //     else if (firstFileId) history.push(`/courses/${id}/${firstFileId}`);
      //     else history.push(`/courses/${id}`);
      // }
      // history.push(`/courses/adquire/${id}`);

      // history.push(`/courses/home/${id}`);
      // jogar para player
      history.push(`/courses/${id}`);
    } else {
      history.push(`/courses/adquire/${id}`);
    }
  };
  const HandleListClick = (e) => {
    e.stopPropagation();
    setHasListed(!hasListed);
    listed || hasListed ? removeMutate() : addMutate();
  };


  useEffect(() => {
    try {
      if (listed_courses?.includes(id)) {
        setHasListed(true)
        console.log('TEM NA LISTA')
      }
    } catch (error) {
      console.log('error', error)
    }
  }, [listed_courses])

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      sx={{
        position: "absolute",
        zIndex: "10",
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
      }}
    >
      {/* <Box>
        <Fab
          variant="extended"
          onClick={HandlePlayClick}
          sx={{
            color: "black",
            // width: 50,
            height: 40,
            boxShadow: "none",
            marginBottom: '0.8rem',
            "&:hover": {
              backgroundColor: "#548ce8",
            },
          }}
        >
          <PlayArrowIcon
            style={{
              marginRight: theme.spacing(1),
            }}
          />
          Assistir
        </Fab>
      </Box> */}
      {/* <span
        style={{
          backgroundColor: free ? '#2f84c4' :"#e81515",
          color: "white",
          // height: "max-content",
          padding: props.not_in ? "0.26rem 0.8rem 0.26rem 0.8rem" : "0",
          borderRadius: "0 0 0.8rem 0",
        }}
      >
        {props.not_in ? (
          price > 0 ? (
            moneyMask(price)
          ) : (
            ""
          )
        ) : free ? (
          <span style={{ padding: "0.4rem 0.8rem", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Grátis 
          </span>
        ) : (
          ""
        )}
      </span> */}
      {expired && <div style={{position:'absolute',top:0,left:0,padding:10,background:'red',borderRadius:10,display:'flex',justifyContent:'center',alignItems:'center'}}>
        <p style={{color:'white',fontSize:18,textAlign:'center',marginBottom:0}}>RECOMPRAR</p>
      </div>}
        <Tooltip
          title={listed || hasListed ? "Remover da Minha lista" : "Adicionar à Minha lista"}
          placement={"top"}
          arrow
        >
          <IconButton
            variant={motion.button}
            onClick={HandleListClick}
            sx={{
              width: 40,
              height: 40,
              margin: "0.4rem",
              marginRight: '1rem',
              color: "black",
              backgroundColor: "rgba(255, 255, 255, 0.6)",
              borderRadius: '10rem',
              '&:hover': {
                backgroundColor: "rgba(255, 255, 255, 0.8)",
              },
            }}
          >
            <ListButtonIcon listed={listed || hasListed} />
          </IconButton>
        </Tooltip>
      {/* <Box
      > */}
        {/* <Tooltip title={"Módulos, aulas e arquivos"} placement={"top"} arrow>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              // cardContext?.OpenExpandableModal()
              // console.log(e)
              SetAnchorEl(document.getElementById("course_id_" + id));
            }}
            sx={{
              color: "black",
              width: 40,
              height: 34,
              border: "0.1rem solid rgba(0, 0, 0, 0.4)",
              backgroundColor: "rgba(255, 255, 255, 0.6)",
              borderRadius: "0.3rem 0.3rem 0.3rem 0.3rem",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.8)",
              },
            }}
          >
            <ArticleIcon />
          </IconButton>
        </Tooltip> */}
      {/* </Box> */}
    </Box>
  );
};

export default ActionsHome;

import { useState } from "react";
import { FormControl as Input } from "react-bootstrap";
import RoleBased from "components/Role/RoleBased";
import Table from "./Table";
import DefaultButton from "components/DefaultButton";

const ToAddList = () => {

    const [search, SetSearch] = useState("");

    return (
        <div>
            <p className="text-muted">Aqui são listados todos os usuários que possuem este cupom</p>
            <hr/>
            <div className="flex jcsb flex-wrap margin-bottom">
                <Input
                    placeholder="Pesquisar pelo Nome"
                    value={search}
                    onChange={e => SetSearch(e.target.value)}
                />
            </div>
            <Table
                search={search}
            />
        </div>
    );
}

export default ToAddList;
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, Typography } from "@mui/material";
import { useState } from "react";

const UserInfo = ({
  style,
  name,
  email,
  cpf,
  birth_date,
  gender,
  phone,
  createdAt,
  adress,
}) => {
  return (
    <div
      className={style.profileInfo}
      style={{ flexGrow: 1, flexDirection: "row" }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          height: "max-content",
          gap: "1rem",
          justifyContent: "center",
        }}
      >
        <ProfileCard
          classname={style.profileInfoCard}
          label="Nome"
          value={name}
        />
        <ProfileCard
          classname={style.profileInfoCard}
          label="Sexo"
          value={gender}
        />
        <ProfileCard
          classname={style.profileInfoCard}
          label="Data de nascimento"
          value={birth_date}
          hide
        />
        <ProfileCard
          classname={style.profileInfoCard}
          label="CPF"
          value={cpf}
          hide
        />
        <ProfileCard
          classname={style.profileInfoCard}
          label="Telefone"
          value={phone}
          hide
        />
        <ProfileCard
          classname={style.profileInfoCard}
          label="E-mail"
          value={email}
          hide
        />
        <ProfileCard
          classname={style.profileInfoCard}
          label="Data de cadastro"
          value={new Date(Date.parse(createdAt)).toLocaleDateString("pt-BR")}
        />
        <ProfileCard
          classname={style.profileInfoCard}
          label="Endereço"
          value={Object.values(adress)
            .filter((item) => item.value)
            .map((item) => item.value)
            .join(", ")}
          hide
        />
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" sx={{ margin: "0 auto" }}>
            <ErrorOutlineIcon sx={{ color: "#e39d12" }} /> <b>Atenção:</b>{" "}
            Preencha todos os dados acima.
          </Typography>
          <Typography
            variant="body1"
            sx={{ margin: "0 auto", color: "#5c5c5c" }}
          >
            Os dados são de uso interno e não serão compartilhados com
            terceiros.
          </Typography>
        </div>
        <div></div>
      </div>
    </div>
  );
};
export default UserInfo;

const ProfileCard = ({ classname, label, value, hide }) => {
  const [showUserInfo, setShowUserInfo] = useState(false);

  if (!value)
    return (
      <div className={classname}>
        <span
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#bf1313",
          }}
        >
          {label}
        </span>
        <span style={{ color: "#bf1313" }}>Não informado</span>
      </div>
    );

  return (
    <div className={classname}>
      <span
        style={{
          fontSize: "1rem",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        }}
      >
        {label}
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span>{showUserInfo ? value : hide ? "********" : value}</span>

        {hide && (
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setShowUserInfo(!showUserInfo)}
          >
            {showUserInfo ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
          </IconButton>
        )}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";

import { FormGroup } from "@mui/material";
import { FormControl as Input } from "react-bootstrap";
import DefaultButton from "components/DefaultButton";
import { Get } from "utils/request";
import { useLocation, useParams } from "react-router";
import { useDispatch } from "react-redux";
import FormCard from "./FormCard";
import Label from "components/InfoLabel";
import JoditConfig from "utils/joditConfig";
import JoditEditor from "jodit-react";
import '../../../../assets/css/styles.css';

const joditConfig = new JoditConfig("jodit-upload-image").config();

const FormBody = props => {

    const {
        onConfirm,
        loading
    } = props;

    const [title, SetTitle] = useState("");
    const [subtitle, SetSubtitle] = useState("");
    const [introText, SetIntroText] = useState("");
    const [statisticsText, SetStatisticsText] = useState("");
    const [cards, SetCards] = useState([]);
    const [missionText, SetMissionText] = useState("");
    
    const [firstLoading, SetFirstLoading] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();

    const { course_name, course_id } = useParams();

    const GetData = async () => {
        let response = await Get("site-rt/about-us-page");
         
        if (response?.status === true) {
            SetTitle(response?.aboutUsPage?.title ?? "");
            SetSubtitle(response?.aboutUsPage?.subtitle ?? "");
            SetStatisticsText(response?.aboutUsPage?.statisticsText ?? "");
            SetMissionText(response?.aboutUsPage?.missionText ?? "");
            SetIntroText(response?.aboutUsPage?.introText ?? "");
            SetCards(response?.aboutUsPage?.statsCards ?? []);
        }

        SetFirstLoading(true);
    }

    useEffect(GetData, []);

    if (!firstLoading) return <Skeleton/>
    return (
        <div>
            <form>
                <FormGroup>
                    <Label
                        label="Título"
                    />
                    <Input
                        placeholder="Título da landing page"
                        variant="filled"
                        value={title}
                        onChange={e => SetTitle(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Subtítulo"
                    />
                    <Input
                        placeholder="Subtítulo da landing page"
                        value={subtitle}
                        onChange={e => SetSubtitle(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Apresentação"
                    />
                    <JoditEditor
                        config={joditConfig}
                        value={introText}
                        onChange={value => SetIntroText(value)}
                    />
                    <br/>

                    <Label
                        label="Texto das estatíticas"
                    />
                    <Input
                        placeholder="Texto das estatíticas"
                        value={statisticsText}
                        onChange={e => SetStatisticsText(e.target.value)}
                        as="textarea"
                    />
                    <br/>

                    <hr/>
                    <FormCard 
                        onChange={cards => SetCards([...cards])}
                        initialCards={cards}
                    />
                    <hr/>

                    <Label
                        label="Missão"
                    />
                    <JoditEditor
                        config={joditConfig}
                        value={missionText}
                        onChange={SetMissionText}
                    />
                    <br/>
                </FormGroup>
            </form>
            <br/>
            <div className="w100 inline-flex jcsb margin-top-1">
                <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar configurações"
                    loadingtext="Salvando configurações"
                    loading={loading}
                    onClick={() => {
                        const data = {
                            title: title,
                            subtitle: subtitle,
                            statisticsText: statisticsText,
                            missionText: missionText,
                            introText: introText,
                            cards: cards
                        };

                        onConfirm(data);
                    }}
                />
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
        </div>
    );
}

export default FormBody;
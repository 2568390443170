import JoditEditor from "jodit-react";
import { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import Label from "components/InfoLabel";
import ColorInput from "components/Inputs/ColorInput";


import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import ImageInput from "components/Inputs/ImageInput";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const Section1Edit = (props) => {
  const {
    title,
    setTitle,
    about_us_bg_image,
    setAboutUsBgImage,
    about_us_bg_gradient,
    setAboutUsBgGradient,
    joditIntroText,
    setJoditIntroText,
  } = props;

  const { JoditConfig } = props;

  return (
    <>
      <FormLabel>
        Imagem de capa do card de apresentação
        <ImageInput
          path={about_us_bg_image}
          ImageChange={(image) => setAboutUsBgImage(image)}
        />
      </FormLabel>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos recomendados:
        Não há um tamanho recomendado.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
        .jpeg, .png e .gif.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo.
      </span>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={about_us_bg_gradient ? true : false}
              onChange={(e) => setAboutUsBgGradient(!about_us_bg_gradient)}
            />
          }
          label="Habilitar degradê do card"
        />
      </FormGroup>

      <FormLabel>
        <Label
          label={<span>Subtítulo da página</span>}
          required
          title="Subtítulo da página principal da seção 1"
        />
        <JoditEditor
          config={JoditConfig}
          value={title}
          onChange={(value) => setTitle(value)}
        />
      </FormLabel>

      <FormLabel>
        <Label
          label={<span>Apresentação</span>}
          required
          title="Texto de apresentação da seção 1"
        />
        <JoditEditor
          config={JoditConfig}
          value={joditIntroText}
          onChange={setJoditIntroText}
        />
      </FormLabel>
    </>
  );
};
export default Section1Edit;

import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { removeHTML } from "utils/filters";
import { STORAGE_URL } from "variables";
import { numToFixed } from "utils/helpers";
import SellIcon from "@mui/icons-material/Sell";
import { moneyMask } from "utils/moneyMask";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import loadOpcells from "./LoadOpcells";
// import { useEffect, useState } from "react";

const text_container = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  position: "relative",
};

const text_style = {
  fontSize: "1.5rem",
  fontWeight: "bold",
  position: "absolute",
  margin: 0,
  padding: "0 1rem",
  zIndex: 10,
};

const text_comment = {
  fontSize: "1rem",
  textAlign: "center",
};

const ConfirmOrder = (props) => {
  const {
    course,
    selectedOpcells,
    discount_price,
    discount_type,
    used_coupon,
    setUsedCoupon,
    total_price,
    setTotalPrice,
    installments,
    is_student,
    validateCoupon,
    user,
    installmentsOptions
  } = props;

  const getTotalPrice = () => {
    let course_price = parseFloat(course?.price);
    console.log('price', course_price,'is_student',is_student)
    let opcells_total_price = selectedOpcells?.reduce((acc, opcell) => {
      return (
        parseFloat(acc) +
        parseFloat(opcell?.price.replace("R$ ", "").replace(".", "").replace(",", "."))
      );
    }, 0);

    let sum = course_price + opcells_total_price;
    if (installments == 1) {
     
      console.log(opcells_total_price)
      console.log(course_price)


      setTotalPrice(sum);
    } else {
      // let course_price = parseFloat(course?.price);
      let installments_price = course.variations.filter((item) => item.is_student == is_student && item.installment == installments)[0]?.price
      console.log(course.variations)
      console.log(installments_price)
      let total_value = selectedOpcells?.reduce((acc, opcell) => {
        return (
          parseFloat(acc) +
          parseFloat(opcell?.price.replace("R$ ", "").replace(".", "").replace(",", "."))
        );
      }, 0);
      let fee = 0;
      let opcells_total_price = course.variations.filter((item) => item.is_student == is_student).map((item) => {
        let price = total_value
        if (item.has_fee) {
          fee = parseFloat(item.fee);
        }
        if (!isNaN(fee)) {
          price = parseFloat((price * (1 + fee * course.variations.filter((item) => item.is_student == is_student).length / 100)) / (item.installment))
        }
        else {
          price = price / item.installment
        }
        console.log({price,installment:item.installment})
        
        return {price,installment:item.installment}
      }).filter((item)=>item.installment == installments)[0]?.price;
      console.log(opcells_total_price)
      if (installments_price == undefined) {
        installments_price =
          parseFloat(course.installments_quantity) *
          parseFloat(course.installments_price);
        opcells_total_price = selectedOpcells?.reduce((acc, opcell) => {
          return (
            parseFloat(acc) +
            parseFloat(
              opcell?.installments_price.replace("R$ ", "").replace(".", "").replace(",", ".")
            ) *
            parseFloat(opcell?.installments_quantity)
          );
        }, 0);
        console.log(installments_price)
        console.log(opcells_total_price)
      }
      else {
        installments_price = (installments_price+opcells_total_price) * installments;
        
      }

      console.log(installments_price)


      if(sum<installments_price){

        sum = installments_price;
      }
      // console.log('sum')

      setTotalPrice(sum.toFixed(2));
    }
  };

  useEffect(() => {
    getTotalPrice();
  }, [course, selectedOpcells, installments, is_student]);

  // const getInstallmentsPrice = (format=false) => {
  //   let total_course_installments = parseFloat(course?.installments_price) * course?.installments_quantity;
  //   let calc = numToFixed(total_course_installments/course?., 2);
  //   return format ? moneyMask(total_course_installments) : total_course_installments;
  // }
  console.log('total price - discount:',total_price,discount_price)
  let variation = course?.variations?.filter((item) => item.is_student == is_student)?.filter((item)=>item.installment==installments)[0];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        style={{ fontWeight: "bold", color: course?.checkout_font_color }}
      >
        No carrinho
      </Typography>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {genCourseItem({
          image: course?.image,
          name: course?.name,
          course: course,
        })}

        {selectedOpcells &&
          selectedOpcells.map((opcell, index) => {
            return genCourseItem({
              image: opcell?.image,
              name: opcell?.name,
              index: index,
              course: course,
            });
          })}
      </div>

      {/* opcell section */}
      {<>
        {props.opcells[0] && props.opcells.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: course?.checkout_font_color,
            }}
          >
            <div style={text_container}>
              <h4 style={text_style}>Recomendações</h4>
              <hr style={{ width: "100%" }} />
            </div>
            <span style={text_comment}>
              Os nossos especialistas recomendam que você adquira os seguintes
            </span>
          </div>
        )}
        {loadOpcells(props)}
      </>}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          padding: "1rem",
          borderRadius: "0.6rem",
          color: course?.checkout_font_color,
        }}
      >
        {user?.id && is_student == false && <>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", color: course?.checkout_font_color }}
          >
            Cupom de desconto
          </Typography>

          <div
            style={{
              display: "flex",
              gap: "1rem",
              margin: "0.8rem 0 2rem 0",
            }}
          >
            <TextField
              fullWidth
              label="Cupom de desconto"
              size="small"
              value={used_coupon}
              onChange={(e) => setUsedCoupon(e.target.value)}
              sx={{
                backgroundColor: "white",
              }}
            />
            <Button
              variant="contained"
              size="large"
              onClick={()=>validateCoupon(used_coupon)}
              endIcon={<SellIcon />}
              sx={{
                backgroundColor: course?.checkout_accent_color,
              }}
            >
              Aplicar
            </Button>
          </div>
        </>}


        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            color: course?.checkout_font_color,
          }}
        >
          Resumo do pagamento
        </Typography>

        {paymentItem(course,course, undefined, is_student)}
        {selectedOpcells &&
          selectedOpcells.map((opcell, index) => {
            return paymentItem(course,opcell, index, is_student,true);
          })}
        {<>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <span
              style={{
                padding: "0.1rem 0.6rem",
                margin: "0.2rem 0",
              }}
            >
              {discount_type ? "Preço antes:" : "Total a pagar"}{" "}
              <b>
                R$ {numToFixed(parseFloat(total_price).toFixed(2), 2).replace(".", ",")} - {installments}
                x de R${" "}
                {numToFixed(parseFloat(total_price / installments).toFixed(2), 2).replace(
                  ".",
                  ","
                )}
              </b>
            </span>
            {discount_type && (
              <span
                style={{
                  padding: "0.1rem 0.6rem",
                  margin: "0.2rem 0",
                }}
              >
                Desconto: <b>{discount_type}</b>
              </span>
            )}
            {discount_type && (
              <span
                style={{
                  padding: "0.1rem 0.6rem",
                  margin: "0.2rem 0",
                }}
              >
                Total a pagar:{" "}
                <b>R$ {numToFixed((parseFloat(total_price)-parseFloat(discount_price)).toFixed(2), 2).replace(".", ",")} - {installments}
                  x de R${" "}
                  {numToFixed(parseFloat((parseFloat(total_price)-parseFloat(discount_price)) / installments).toFixed(2), 2).replace(
                    ".",
                    ","
                  )}</b>
              </span>
            )}
          </div>
        </>}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.2rem",
        }}
      >
        <Typography
          variant="body1"
          style={{ margin: "0.4rem 0", color: course?.checkout_font_color }}
        >
          <ErrorOutlineIcon
            sx={{
              color: "#f44336",
            }}
          />{" "}
          <b>Atenção.</b> Ao adicionar uma indicação de curso no carrinho e for
          do interesse do cliente realizar o pagamento parcelado, será realizado
          dois ou mais pagamentos (de acordo com a quantidade de cursos no
          carrinho), pois cada curso possui uma quantidade de parcelas com as
          taxas já calculadas.
        </Typography>
      </div>
    </div>
  );
};

export default ConfirmOrder;

const genCourseItem = ({ image, name, index, course }) => {
  return (
    <div
      key={index && index}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderRadius: "0.6rem",
        overflow: "hidden",
        paddingBottom: "0.4rem",
        flexGrow: 1,
      }}
    >
      <img
        src={STORAGE_URL + image}
        alt={removeHTML(name)}
        style={{
          width: "100%",
          height: "16rem",
          objectFit: "cover",
        }}
      />
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          margin: "0.8rem 1rem",
          fontSize: "1.1rem",
          textAlign: "center",

          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: course?.checkout_font_color,
        }}
      >
        {removeHTML(name)}
      </Typography>
    </div>
  );
};

const paymentItem = (course,course_param, index, is_student,opcell=false) => {
  let variation = course_param?.variations?.filter((item) => item.is_student == is_student)?.sort((a, b) => b.installment - a.installment)[0];
  if(opcell==true){
    let fee=0
    variation = course.variations.filter((item) => item.is_student == is_student).map((item) => {
      let price = parseFloat(course_param.price.toString().replace("R$ ", "").replace(".", "").replace(",", "."))
      if (item.has_fee) {
        fee = parseFloat(item.fee);
      }
      if (!isNaN(fee)) {
        price = parseFloat((price * (1 + fee * course.variations.filter((item) => item.is_student == is_student).length / 100)) / (item.installment))
      }
      else {
        price = price / item.installment
      }
      console.log({price,installment:item.installment})
      
      return {price,installment:item.installment}
    }).sort((a, b) => b.installment - a.installment)[0];
  }
  return (
    <div
      key={index && index}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderBottom: "0.1rem solid #c4c4c4",
        margin: "0.6rem 0",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
          width: "100%",
          gap: "0.4rem",
        }}
      >
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            alignItems: "center",
            display: "flex",
            color: course_param?.checkout_font_color,
          }}
        >
          {removeHTML(course_param?.name)}
        </span>
        <span style={{ whiteSpace: "nowrap", display: "flex", gap: "0.6rem" }}>
          {!variation && <span
            style={{
              padding: "0.1rem 0.6rem",
              margin: "0.2rem 0",
            }}
          >
            {course_param?.installments_quantity}x de{" "}
            {moneyMask(course_param?.installments_price)}
          </span>}
          {variation && <span
            style={{
              padding: "0.1rem 0.6rem",
              margin: "0.2rem 0",
            }}
          >
            {variation.installment}x de{" "}
            {moneyMask(parseFloat(variation?.price).toFixed(2))}
          </span>}
          <span
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            ou
          </span>
          <span
            style={{
              padding: "0.1rem 0.6rem",
              margin: "0.2rem 0",
            }}
          >
            à vista: {moneyMask(course_param?.price)}
          </span>
        </span>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import { Post } from "utils/request";
import { Show } from "actions/SnackbarActions";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DefaultButton from "components/DefaultButton";
import SweetAlert from "react-bootstrap-sweetalert";

const Board = props => {

    const {
        id,
        name,
        code,
        to_all: toAll = false,
        discount_amount: amount,
        discount_percentage: percentage,
        OnDelete
    } = props;

    const [popup, SetPopup] = useState(false);

    const dispatch = useDispatch();

    const deleteMutation = useMutation(async () => {
        const form = new FormData();
        form.append("id", id);
        return await Post("coupon/delete", form);
    }, {
        onSuccess: ({ message, severity }) => {
            dispatch(Show({
                show: true,
                message: message,
                severity: severity
            }));
            OnDelete();
        }
    });

    return (
        <tr>
            <td>{code}</td>
            <td>{name}</td>
            <td>{amount ? `R$ ${amount}` : `${percentage}%`}</td>
            <td
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        deleteMutation.mutate();
                        SetPopup(false);
                    }}
                    onCancel={() => SetPopup(false)}
                    show={popup}
                    confirmBtnText='Deletar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Tem certeza que deseja deletar esse cupom?
                </SweetAlert>
                {!toAll && (
                    <DefaultButton
                        to={`/coupon/${id}/users`}
                        width="2.2em"
                        height="2.2em"
                        padding={0}
                        title={`Adicionar cupom à usuário`}
                        bg="secondary"
                        icon={<PersonAddIcon fontSize="small"/>}
                    />
                )}
                <DefaultButton
                    to={`/coupon/${id}/edit`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Editar cupom`}
                    bg="info"
                    icon={<MdModeEdit color="white" size={17}/>}
                    style={{
                        margin: "0 2px"
                    }}
                />
                <DefaultButton
                    onClick={() => SetPopup(true)}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Deletar cupom`}
                    bg="danger"
                    loading={deleteMutation.isLoading}
                    icon={!deleteMutation.isLoading && <MdDelete size={17} color="white"/>}
                />
            </td>
        </tr>
    );
}

export default Board;
const CheckoutHeader = ({icon, title, color}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        fontSize: "1.6rem",
        padding: "4rem 0 0 0",
        color: color
      }}
    >
      {icon}

      <span
        style={{
          fontWeight: "bold",
        }}
      >
        {title}
      </span>
    </div>
  )
}

export default CheckoutHeader;
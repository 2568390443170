import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import { useRef } from "react";
import VideoContainer from "./Video";
import { STORAGE_URL } from "variables";
import TabsContainer from "components/TabsContainer";
import DocsList from "./TabsContent/DocsList";
import Comments from "./TabsContent/Comments";
import Annotations from "./TabsContent/Annotations";
import Exercise from "./Exercise";

const PlayerContainer = ({
  styles,
  hideSideBar,
  setHideSideBar,
  course_data,
  refetch,
  isLoading,
}) => {
  const {
    course,
    fileData: { lesson, file, next, prev, fileIsLoading },
    startTime,
  } = course_data;

  const annotationsRef = useRef();
  const videoContainerRef = useRef();

  const HandleAnnotationChangeVideoTime = (time) => {
    const videoRef = videoContainerRef?.current?.videoRef;
    videoRef?.current && videoRef.current.ChangeTime(time);
  };

  return (
    <div className={styles.PlayerContainer}>
      {/* button to open side bar when its closed */}
      {hideSideBar && (
        <Box
          className={styles.OpenSideBarButton}
          onClick={() => setHideSideBar(false)}
        >
          <span style={{}}>Mostrar aulas</span>
          <MenuIcon />
        </Box>
      )}

      <VideoContainer
        path={file?.path}
        type={file?.type}
        next={next}
        prev={prev}
        startTime={Math.floor(startTime)}
        onAnnotation={() => annotationsRef.current.GetData()}
        ref={videoContainerRef}
        title={lesson?.title}
        thumbnail={STORAGE_URL + lesson?.cover_image}
        modules={course?.modules}
        // {...file}

        {...{
          course_data,
          refetch,
          fileIsLoading,
        }}
      />

      <div
        style={{
          padding: "0 1rem",
          paddingBottom: "1rem",
        }}
      >
        <TabsContainer
          labels={[
            {
              label: "Material complementar",
            },
            {
              label: "Comentários",
            },
            { label: "Anotações" },
            {
              label: "Exercícios",
            },
          ]}
          variant="scrollable"
        >
          <DocsList
            lesson={lesson}
            modules={course?.modules}
            course_id={course.id}
          />
          <Comments />
          <Annotations
            ref={annotationsRef}
            onChangeTime={HandleAnnotationChangeVideoTime}
          />
          <Exercise />
        </TabsContainer>
      </div>
    </div>
  );
};

export default PlayerContainer;

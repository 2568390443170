import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";

import styles from "./index.module.css";
import { STORAGE_URL } from "variables";

const IntroText = (props) => {
  const { title, subtitle, introText, bg_image, bg_gradient, ...others } = props;

  return (
    <Container maxWidth="xl" sx={{ textAlign: "center" }}>
      <Box
        sx={{
          padding: { xs: "1rem 2rem", md: "4rem 6rem" },
          background: "#f0f4f4",
          borderRadius: "1rem",
          marginTop: "6rem",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {bg_image !== "" && bg_image !== null && (
          <img
            className={`custom-card-image ${
              bg_gradient && "make-the-gradient"
            }`}
            style={{
              zIndex: 1,
              width: "100%",
              objectFit: "cover",
            }}
            src={STORAGE_URL + bg_image || ""}
            alt="Fundo do cartão"
          />
        )}
        <div
          style={{
            padding: "2rem",
            borderRadius: "1rem",
            zIndex: 20,
            position: "relative",
          }}
        >
          <Typography
            variant="h3"
            style={{
              fontWeight: 700,
            }}
          >
            Sobre nós
          </Typography>
          
          <Typography
            variant="h6"
            style={{
              marginBottom: "2rem",
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
  
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: introText }}
            sx={{
              gap: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          />
        </div>
      </Box>
    </Container>
  );
};

export default IntroText;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory } from "react-router";
import { Show } from "actions/SnackbarActions";
import { useParams } from "react-router";

const RoleRoute = props => {
    const {
        forcedRoleConfig = [],
        roles = ["Admin", "Colaborador"],
        to = "/",
        ...others
    } = props;

    const user = useSelector(store => store.AppReducer.user);
    const forcedRole = useSelector(store => store.AdminReducer.forcedRole);
    const history = useHistory();
    const dispatch = useDispatch();

    const role = user?.role;

    // console.log(props?.computedMatch);

    useEffect(() => {
        if (user?.role == "Admin" && forcedRole == "Aluno") {
            history.push("/");
            // let filtered = forcedRoleConfig.filter(x => x.role == forcedRole);
            // if (filtered?.length > 0) history.push(filtered[0].to);
            // else history.push("/home");
        }
        if (roles === true) return;
        if (!roles.includes(role)) {
            history.push(to);
            dispatch(Show({
                show: true,
                severity: "error",
                message: "Você não tem permissão para estar aqui"
            }));
        }
    });

    return <Route {...others}/>;
}

export default RoleRoute;
import { useEffect, useState } from "react";
import { Get } from "utils/request";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import DefaultButton from "components/DefaultButton";
import VideoInput from "components/Inputs/VideoInput";
import ImageInput from "components/Inputs/ImageInput";
import { RiArrowGoBackLine } from "react-icons/ri";
import JoditEditor from "jodit-react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const JoditConfig = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarInlineForSelection: true,
  showPlaceholder: false,
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "font",
    "fontsize",
    "brush",
  ],
  height: 100,
  minHeight: 100,
  readonly: false,
  defaultLanguage: "pt_br",
  language: "pt_br",
  toolbarAdaptive: false,
};

const Formbody = (props) => {
  const { OnConfirm, loading, data } = props;

  const [link, SetLink] = useState("");
  const [name, SetName] = useState("");
  const [description, SetDescription] = useState("");

  useEffect(() => {
    SetLink(data?.link);
    SetName(data?.name);
    SetDescription(data?.description);
  }, []);

  return (
    <div className="form-course">
      <form>
        <FormLabel>Link</FormLabel>
        <Input
          placeholder="Link"
          value={link}
          onChange={(e) => SetLink(e.target.value)}
        />
        <div>
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Recomenda-se adicionar
            links do youtube porque a capa da aula será gerada a partir do link.
          </span>
        </div>

        <FormLabel>Nome</FormLabel>
        <JoditEditor
          config={JoditConfig}
          value={name}
          onChange={(value) => SetName(value)}
        />

        <FormLabel>Descrição</FormLabel>
        <JoditEditor
          config={JoditConfig}
          value={description}
          onChange={(value) => SetDescription(value)}
        />
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="confirm"
          text="Salvar"
          onClick={() => {
            const data2 = {
              id: data?.id,
              link: link,
              name: name,
              description: description,
            };
            OnConfirm(data2);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default Formbody;

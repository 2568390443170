import { Avatar, Box, Card, CardContent, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Get } from "utils/request";
import { STORAGE_URL } from "variables";

import styles from "./index.module.css";

const TeamCards = props => {
    
    const {
        ...other
    } = props;

    const [teamMembers, SetTeamMembers] = useState([]);
    const [loaded, SetLoaded] = useState(false);

    const GetData = async () => {
        let response = await Get("site-rt/team-members?paginate=0");
        if (response?.status === true) {
            SetTeamMembers(response?.teamMembers);
        }
        SetLoaded(true);
    }

    useEffect(GetData, []);

    if (!loaded) return null;
    return (
        <Box
            {...other}
            className={styles.container}
            color="secondary.contrastText"
        >
            <Typography variant="h4">Nossa equipe</Typography>
            <div className={styles.cardsGroup}>
                {teamMembers?.map((member, memberKey) => (
                    <Card
                        className={styles.card}
                        key={memberKey}
                    >
                        <CardContent>
                            <Avatar
                                className={styles.avatar}
                                src={member?.image ? STORAGE_URL + member?.image : ""} 
                            />
                            <Typography style={{ fontWeight: 700 }}>{member?.name}</Typography>
                            <Typography gutterBottom>{member?.role}</Typography>
                            <Divider/>
                            <br/>
                            <Typography>{member?.description}</Typography>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </Box>
    );
}

export default TeamCards;
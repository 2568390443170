import { Box, Container, Typography } from "@mui/material";
import Image from "components/Image";
import { STORAGE_URL } from "variables";
// import banner from "./integrative_banner.jpg";

const Banner = (props) => {
  const {
    title = "",
    title_color,
    subtitle = "",
    subtitle_color,
    background_image = "",
  } = props;

  const checkIfisLocalImage = (image_url) => {
    if (image_url?.includes("data:image")) {
      return image_url;
    } else {
      return `${STORAGE_URL}${image_url}`;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Image
        src={checkIfisLocalImage(background_image.image_url)}
        width={"100%"}
        position={"relative"}
        style={{
          aspectRatio: "3.42 / 1",
          backgroundSize: "cover",
          backgroundColor: "#ccc",
        }}
      >
        <Container maxWidth="xl">
          <Box
            position={"absolute"}
            display={"flex"}
            height={"100%"}
            justifyContent={"flex-end"}
            flexDirection={"column"}
            color={"white"}
            sx={{ fontWeight: "bold", justifyContent: "center" }}
          >
            <Typography
              variant="h3"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <Typography
              variant="h4"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </Box>
        </Container>
      </Image>
    </Box>
  );
};

export default Banner;

import React, { useEffect, useState } from "react";
import { FormControl as Input } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DefaultButton from "../../../components/DefaultButton";
import Table from "./Table";
import RoleBased from "components/Role/RoleBased";

const CourseList = () => {
  const [search, SetSearch] = useState("");
  const [search2, SetSearch2] = useState("");
  let timer = null;

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((store) => store.AppReducer.user);
  const forcedRole = useSelector((store) => store.AdminReducer.forcedRole);

  // useEffect(() => {
  //     if (user?.role === "Admin" && forcedRole === "Aluno") history.push("/home");
  // }, [forcedRole]);

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="title">Cursos</h3>
        <p className="text-muted">
          Aqui são listados todos os cursos disponíveis para você
        </p>
        <hr />
        <div className="flex jcsb flex-wrap margin-bottom">
          <Input
            placeholder="Pesquisar pelo Nome"
            value={search2}
            onChange={(e) => {
              // timer to avoid multiple requests on type
              clearTimeout(timer);
              SetSearch2(e.target.value);

              timer = setTimeout(() => {
                SetSearch(e.target.value);
              }, 1000);
            }}
            style={{ width: "50%" }}
          />
          <DefaultButton
            bg="confirm"
            text="Adicionar novo curso"
            to="/courses/add"
          />
        </div>
        <Table hidden={true} search={search} />
      </div>
    </div>
  );
};

export default CourseList;

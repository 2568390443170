import { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
// import LessonsCard from "../Cards/LessonsCard";
import { Post } from "utils/request";
import JoditEditor from "jodit-react";

// mui icons
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Section2Card from "../Cards/Section2Card";
import Label from "components/InfoLabel";
import ColorInput from "components/Inputs/ColorInput";

const Section2Edit = (props) => {
  const modal_style = props.modal_style;
  const { section2_cards, addSection2Card } = props.section2;
  const { removeSection2Card, handleSection2CardChange } = props.section2;
  const { section2_title, setSection2_title } = props.section2;
  const { section2_title_color, setSection2_title_color } = props.section2;
  const { JoditConfig } = props;

  const dispatch = useDispatch();

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [card_title, setCard_title] = useState("");
  const [card_text, setCard_text] = useState("");
  const [card_bg_color, setCard_bg_color] = useState("#547cbc");
  const [card_font_color, setCard_font_color] = useState("white");

  const handleAddLesson = () => {
    addSection2Card({
      title: card_title,
      text: card_text,
      bg_color: card_bg_color,
      font_color: card_font_color,
    });

    // Submit();

    dispatch(
      Show({
        show: true,
        severity: "success",
        message: "Adicionado com sucesso.",
      })
    );
    handleClose();

    setCard_title("");
    setCard_text("");
    setCard_bg_color("#547cbc");
    setCard_font_color("white");

  };

  // const Submit = async () => {
  //   let form = new FormData();
  //   form.append("title", card_title);
  //   form.append("text", card_text);
  //   form.append("bg_color", card_bg_color);
  //   form.append("font_color", card_font_color);

  //   await Post("courses-main/free-courses/create", form);
  // };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modal_style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              padding: "3rem 4rem",
            }}
          >
            <CloseIcon
              sx={{
                position: "absolute",
                top: "0.8rem",
                right: "0.8rem",
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <Typography variant="h5" style={{ marginBottom: "1rem" }}>
              Adicionar cartão
            </Typography>

            <FormLabel>
              Título <span style={{ color: "red" }}>*</span>
              <Input
                value={card_title}
                onChange={(e) => setCard_title(e.target.value)}
                required
              />
            </FormLabel>
            <FormLabel sx={{ width: "24rem" }}>
              Texto <span style={{ color: "red" }}>*</span>
              <Input
                value={card_text}
                onChange={(e) => setCard_text(e.target.value)}
                sx={{ width: "24rem" }}
                as="textarea"
                required
              />
            </FormLabel>

            <FormLabel>
              <Label label={<span>Cor do fundo do cartão</span>} required />
              <ColorInput
                value={card_bg_color}
                onChange={(color) =>
                  color !== undefined && setCard_bg_color(color)
                }
              />
            </FormLabel>

            <FormLabel>
              <Label label={<span>Cor da fonte do cartão</span>} required />
              <ColorInput
                value={card_font_color}
                onChange={(color) =>
                  color !== undefined && setCard_font_color(color)
                }
              />
            </FormLabel>

            <Button
              variant="contained"
              onClick={handleAddLesson}
              sx={{
                width: "max-content",
                alignSelf: "flex-end",
                marginTop: "1rem",
              }}
            >
              Adicionar cartão
            </Button>
          </div>
        </div>
      </Modal>
      <Label
        label={<span>O que você ganha com os nossos cursos</span>}
        required
        title="Adicionar cartões de estatísticas para a seção 2"
      />

      <FormLabel>
        <Label
          label={<span>Título</span>}
          required
          title="Texto das estatíticas da página sobre nós"
        />
        <JoditEditor
          config={JoditConfig}
          value={section2_title}
          onChange={(value) => setSection2_title(value)}
        />
      </FormLabel>

      <Label
          label={<span>Cartões</span>}
          required
          title="Adicionar cartões de estatísticas para a seção 2"
        />

      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          maxWidth: "36rem",
        }}
      >
        {section2_cards.map((card, index) => {
          return (
            <Section2Card
              key={index}
              index={index}
              card={card}
              modal_style={modal_style}
              removeSection2Card={removeSection2Card}
              handleSection2CardChange={handleSection2CardChange}
            />
          );
        })}
      </div>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          width: "max-content",
        }}
      >
        Adicionar cartão
      </Button>
    </>
  );
};

export default Section2Edit;

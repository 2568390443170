import { Box } from "@mui/material";
import Formbody from "./Formbody";

const PolicyTermsForm = () => {
    return (
        <Box
            className="card"
        >
            <Box
                className="card-body"
            >
                <h2 className="title">Política e privacidade</h2>
                <p className="text-muted">Aqui é possivel modificar as políticas de privacidade</p>
                <hr/>
                <Formbody/>
            </Box>
        </Box>
    );
}

export default PolicyTermsForm;
import { Box, Typography } from "@mui/material";
import TableInfo from "./TableInfo";

const Bottom = ({ cards }) => {
  return (
    <Box>
      <br />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "2rem",
        }}
      >
        {cards !== undefined &&
          cards?.map((card, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                },
                width: "100%",
                gap: "1rem",
                background: "rgb(240, 244, 244)",
                borderRadius: "0.8rem",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  padding: "1rem",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                  dangerouslySetInnerHTML={{ __html: card?.title }}
                />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold" }}
                  dangerouslySetInnerHTML={{ __html: card?.text }}
                />
              </div>

              <Box
                component="img"
                src={card?.image}
                alt="Cartao"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "33%",
                  },
                  maxHeight: "20rem",
                  objectFit: "cover",
                  marginLeft: "1rem",
                  display: card?.image ? "block" : "none",
                }}
              />
            </Box>
          ))}
      </div>
    </Box>
  );
};

export default Bottom;

import DrawableInput from "components/Inputs/DrawableInput";
import RoleBased from "components/Role/RoleBased";
import React, { useEffect, useRef, useState } from "react";

import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useLocation, useParams } from "react-router";
import { STORAGE_URL } from "variables";
import DefaultButton from "../../../components/DefaultButton";
import ImageInput from "../../../components/Inputs/ImageInput";
import PhoneInput from "../../../components/Inputs/PhoneInput";
import { Get } from "../../../utils/request";

import JoditConfig from "utils/joditConfig";
import JoditEditor from "jodit-react";
import { Checkbox, FormControlLabel } from "@mui/material";

const descriptionConfig = new JoditConfig("jodit-upload-image").config();

const FormBody = (props) => {
  const { OnConfirm, loading } = props;

  const [image, SetImage] = useState();
  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [confirmPassword, SetConfirmPassword] = useState("");
  const [phone, SetPhone] = useState("");
  const [role, SetRole] = useState("");
  const [signature, SetSignature] = useState("");
  const [signature_actual, SetSignatureActual] = useState("");

  const [roles, SetRoles] = useState([]);

  const [description, SetDescription] = useState("");
  const [isTeamMember, SetIsTeamMember] = useState(false);

  const [firstLoading, SetFirstLoading] = useState(false);

  const { user_id } = useParams();
  const location = useLocation();

  const signatureContainer = useRef();

  const GetData = async () => {
    //ROLES
    let response = await Get("roles");
    if (response?.status === true) {
      SetRoles(response?.roles);
    }

    //USERS
    response = await Get(`user?id=${user_id}`);
    if (response?.status === true) {
      SetImage(response?.user?.profile_image ?? "");
      SetName(response?.user?.name);
      SetEmail(response?.user?.email);
      SetPhone(response?.user?.phone ?? "");
      SetRole(response?.user?.role_id);
      SetSignature(response?.user?.signature);
      SetSignatureActual(response?.user?.signature);

      SetDescription(response?.user?.description ?? "");
      SetIsTeamMember(response?.user?.isTeamMember ?? false);


    }
    SetFirstLoading(true);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (signatureContainer?.current && query.get("sig") == 1)
      signatureContainer?.current.scrollIntoView();
  }, [signatureContainer, firstLoading]);

  useEffect(GetData, []);

  if (!firstLoading) return <Skeleton />;
  return (
    <div className="profile-edit-form">
      <form>
        <FormLabel>
          Imagem de perfil <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <ImageInput
          ImageChange={(value) => SetImage(value)}
          path={image}
          accept={".jpg,.jpeg,.png"}
        />
        <br />

        <FormLabel>
          Nome <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Nome"
          value={name}
          onChange={(e) => SetName(e.target.value)}
        />
        <br />

        <FormLabel>
          E-mail <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="E-mail"
          value={email}
          onChange={(e) => SetEmail(e.target.value)}
          disabled
        />
        <br />

        <FormLabel>
          Senha <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Senha"
          value={password}
          type="password"
          maxLength={50}
          onChange={(e) => SetPassword(e.target.value)}
        />
        <br />

        <FormLabel>
          Confirmar senha <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Confirmar senha"
          value={confirmPassword}
          type="password"
          maxLength={50}
          onChange={(e) => SetConfirmPassword(e.target.value)}
        />
        <br />

        <FormLabel>Telefone</FormLabel>
        <PhoneInput
          inputType="bootstrap"
          GetValue={(value) => SetPhone(value)}
          value={phone}
        />
        <br />

        {/* <RoleBased>
          <FormLabel>Função</FormLabel>
          <select
            className="form-select"
            value={role}
            onChange={(e) => SetRole(e.target.value)}
          >
            {roles?.map((value, key) => (
              <option id={value?.id} key={key} value={value?.id}>
                {value?.name}
              </option>
            ))}
          </select>
          <br />
        </RoleBased> */}

        {roles.filter((x) => x?.id == role)?.length != 0 &&
          roles.filter((x) => x?.id == role)[0]?.key != "aluno" && (
            <div>
              <FormLabel>Assinatura</FormLabel>
              {signature_actual != "" && (
                <div>
                  <a
                    target="_blank"
                    className="margin-bottom"
                    style={{ textDecoration: "none" }}
                    href={STORAGE_URL + signature_actual}
                  >
                    Visualizar assinatura atual
                  </a>
                </div>
              )}

              <DrawableInput
                onChange={(value) => SetSignature(value)}
                prevImage={signature}
                ref={signatureContainer}
              // initialMode={signature ? 1 : 0}
              />
              <br />

              <FormLabel>Descrição</FormLabel>
              <JoditEditor
                config={descriptionConfig}
                value={description}
                onChange={SetDescription}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTeamMember}
                      onChange={(e) => SetIsTeamMember(e.target.checked)}
                    />
                  }
                  label={"Membro da equipe"}
                />
                <span>
                  Ao marcar esta opção, o usuário aparecerá na página "sobre
                  nós" como membros da equipe.
                </span>
              </div>
            </div>
          )}
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to={`/profile/view/${user_id}`}
        />
        <DefaultButton
          bg="confirm"
          text="Salvar alterações"
          onClick={() => {
            let data = {
              image: image,
              name: name,
              email: email,
              password: password,
              phone: phone,
              role: role,
              signature: signature,
              description: description,
              isTeamMember: isTeamMember,
            };
            OnConfirm(data);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div>
      <div
        className="skeleton-base skeleton-shimmer"
        style={{ width: "7em", height: "7em" }}
      />
      <br />
      <div
        className="skeleton-base skeleton-shimmer w100"
        style={{ height: "2em" }}
      />
      <br />
      <div
        className="skeleton-base skeleton-shimmer w100"
        style={{ height: "2em" }}
      />
      <br />
      <div
        className="skeleton-base skeleton-shimmer w100"
        style={{ height: "2em" }}
      />
      <br />
      <div
        className="skeleton-base skeleton-shimmer w100"
        style={{ height: "2em" }}
      />
      <br />
    </div>
  );
};

export default FormBody;

import { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { STORAGE_URL } from "variables";
import { fileToBase64 } from "utils/helpers";
import { useEffect } from "react";
import TestimonialsCard from "../Cards/TestimonialsCard";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Post } from "utils/request";

import JoditEditor from "jodit-react";
// mui icons
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CheckIcon from "@mui/icons-material/Check";
import Label from "components/InfoLabel";
import { PAGE_KEYS } from "variables";
import { useLocation } from "react-router-dom";

const DepoimentosEdit = ({ props, section_4, modal_style, JoditConfig }) => {
  const {
    testimonial_section_title,
    setTestimonial_section_title,
    handleOpenLoadingModal,
    handleCloseLoadingModal,
  } = props;
  
  const dispatch = useDispatch();
  const location = useLocation();
  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // card info
  const [testimonial_image, setTestimonial_image] = useState({});

  const [testimonial_name, setTestimonial_name] = useState("");
  const [testimonial_profession, setTestimonial_profession] = useState("");
  const [testimonial_testimonial, setTestimonial_testimonial] = useState("");

  const handleAddImage = (file) => {
    fileToBase64(file).then((base64) => {
      setTestimonial_image({
        image_url: base64,
        image_file: file,
      });
    });
  };

  const handleAddTestimonial = () => {
    section_4.addTestimonial({
      image_url: testimonial_image.image_url,
      image_file: testimonial_image.image_file,
      name: testimonial_name,
      profession: testimonial_profession,
      testimonial: testimonial_testimonial,
    });

    Submit();

    dispatch(
      Show({
        show: true,
        severity: "success",
        message: "Adicionado com sucesso",
      })
    );
    handleClose();

    setTestimonial_name("");
    setTestimonial_profession("");
    setTestimonial_testimonial("");
    setTestimonial_image({});
  };

  const Submit = async () => {
    let form = new FormData();
    form.append("image", testimonial_image.image_file);
    form.append("name", testimonial_name);
    form.append("testimonial", testimonial_testimonial);
    form.append("profession", testimonial_profession);

    await Post("site-rt/testimonial/create", form);
  };

  const Submit2 = async () => {
    handleOpenLoadingModal();
    let form = new FormData();
    form.append("testimonial_section_title", testimonial_section_title);

    form.append("type", "firstContent");
    let response = await Post("site-rt/description/update", form);

    if (response.status === 200) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
          buttonActionText: "Ver",
        })
      );
    } else if (response.status !== 200) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
          buttonActionText: "Ver",
        })
      );
    }

    handleCloseLoadingModal();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modal_style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              padding: "3rem 4rem",
            }}
          >
            <CloseIcon
              sx={{
                position: "absolute",
                top: "0.8rem",
                right: "0.8rem",
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <Typography variant="h5" style={{ marginBottom: "1rem" }}>
              Adicionar depoimento
            </Typography>

            <div
              style={{
                display: "flex",
                gap: "1rem",
                marginBottom: "1rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <label
                htmlFor="inputo-file"
                style={{
                  height: "6rem",
                  width: "6rem",
                  borderRadius: "1rem",
                  border: "0.2rem dashed #ccc",
                  cursor: "pointer",
                  display: "flex",
                  backgroundImage: `url(${testimonial_image.image_url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <input
                  hidden
                  type="file"
                  id="inputo-file"
                  onChange={(e) => {
                    handleAddImage(e.target.files[0]);
                  }}
                />
                <AddPhotoAlternateIcon
                  sx={{
                    width: "100%",
                    height: "100%",
                    padding: "1.4rem",
                    margin: "auto",
                    color: "white",
                    opacity: 0.4,
                    filter: "drop-shadow(0 0 0.2rem black)",
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </label>

              <FormLabel>
                Nome <span style={{ color: "red" }}>*</span>
                <Input
                  value={testimonial_name}
                  onChange={(e) => setTestimonial_name(e.target.value)}
                  required
                />
              </FormLabel>
            </div>
            <span>Proporção da imagem: 1:1</span>
            <span style={{ marginBottom: "0.8rem" }}>
              A imagem será centralizada automaticamente.
            </span>
            <FormLabel>
              Profissão <span style={{ color: "red" }}>*</span>
              <Input
                value={testimonial_profession}
                onChange={(e) => setTestimonial_profession(e.target.value)}
                required
              />
            </FormLabel>
            <FormLabel sx={{ width: "24rem" }}>
              Depoimento <span style={{ color: "red" }}>*</span>
              <Input
                value={testimonial_testimonial}
                onChange={(e) => setTestimonial_testimonial(e.target.value)}
                sx={{ width: "24rem" }}
                required
                as={"textarea"}
              />
            </FormLabel>
            <Button
              variant="contained"
              onClick={handleAddTestimonial}
              sx={{
                width: "max-content",
                alignSelf: "flex-end",
                marginTop: "1rem",
              }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Modal>

      <FormLabel>
        Título <span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={testimonial_section_title}
          onChange={(value) => setTestimonial_section_title(value)}
        />
      </FormLabel>

      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          maxWidth: "34rem",
          maxHeight: "30rem",
          overflowY: "auto",
        }}
      >
        {section_4.data.map((testimonial, index) => {
          return (
            <TestimonialsCard
              key={index}
              index={index}
              testimonial={testimonial}
              removeTestimonial={section_4.removeTestimonial}
              handleTestimonial={section_4.handleTestimonial}
            />
          );
        })}
      </div>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          width: "max-content",
        }}
      >
        Adicionar depoimento
      </Button>

      <div>
        <Button variant="contained" color="success" onClick={() => Submit2()}>
          Salvar seção 4
        </Button>
      </div>

      {/* <button
        onClick={() => {
          window.open("/site-rt/depoimentos/criar", "_self");
        }}
        style={{
          padding: "0.6rem 1.2rem",
          cursor: "pointer",
          borderRadius: "0.4rem",
          opacity: 0.8,
          fontWeight: "bold",
          height: "max-content",
          backgroundColor: "#accef2",
          border: "none",
        }}
      >
        Ir para página principal de depoimentos <OpenInNewIcon />
      </button> */}
    </>
  );
};

export default DepoimentosEdit;

import React, { useRef, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "components/DefaultButton";
import FileInput from "components/Inputs/FileInput";
import VideoInput from "components/Inputs/VideoInput";
import { useParams } from "react-router";
import FormMaker from "components/FormMaker";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CollapseContainer from "components/CollapseContainer";
import { PercentageMask } from "utils/filters";
import InputVideo from "components/Inputs/InputVideo";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ImageInput from "components/Inputs/ImageInput";

const FormBody = (props) => {
  const { loading, OnConfirm } = props;

  const [coverImage, SetCoverImage] = useState("");
  const [title, SetTitle] = useState("");
  const [description, SetDescription] = useState("");
  const [videos, SetVideos] = useState([]);
  const [files, SetFiles] = useState([]);
  const [questions, SetQuestions] = useState();
  const [answerReveal, SetAnswerReveal] = useState(false);
  const [minCorrectPercentage, SetMinCorrectPercentage] = useState("0%");

  const [questionFormModal, SetQuestionFormModal] = useState(false);

  const formMakerRef = useRef();

  const { module_id, course_id } = useParams();

  const HandlePercentInput = (event) => {
    event.preventDefault();
    let realNumber = event.target.value + event.key;
    realNumber = realNumber.replace(/[^0-9]/g, "");

    if (event.keyCode === 8) realNumber = realNumber.slice(0, -1);
    SetMinCorrectPercentage(PercentageMask(realNumber));
  };

  return (
    <div classtitle="form-course">
      <form>
        <div>
          <FormLabel>
            Imagem de capa
            <ImageInput ImageChange={(image) => SetCoverImage(image)} />
          </FormLabel>
        </div>
        <br />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.6rem",
          }}
        >
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos
            recomendados: 1280x720 (16:9) ou 1920x1080 (16:9).
          </span>
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos:
            .jpg, .jpeg, .png e .gif.
          </span>
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de
            tamanho para o arquivo.
          </span>
        </div>
        <br />

        <FormLabel>Vídeo</FormLabel>
        <InputVideo onChange={(videos) => SetVideos(videos)} />
        <FormLabel>Arquivo(s)</FormLabel>
        <FileInput
          // accept="application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          accept="application/pdf"
          OnChange={(files) => SetFiles(files)}
        />
        <FormLabel>
          Nome <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Nome"
          value={title}
          onChange={(e) => SetTitle(e.target.value)}
          required
        />
        <br />
        <FormLabel>Descrição</FormLabel>
        <Input
          placeholder="Descrição"
          value={description}
          onChange={(e) => SetDescription(e.target.value)}
          as="textarea"
          required
        />
        <br />

        <Dialog
          open={questionFormModal}
          onClose={() => SetQuestionFormModal(false)}
          keepMounted
          maxWidth="lg"
        >
          <DialogTitle>
            <div className="flex fdrow jcsb w100 align-center">
              <Typography style={{ fontSize: 22 }}>
                Formulário de exercício
              </Typography>
              <IconButton onClick={() => SetQuestionFormModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              // // width: "60vw",
              minWidth: "60vw",
              paddingTop: 15,
              paddingBottom: 0,
            }}
          >
            <TextField
              placeholder="Porcentagem mínima de acertos"
              onKeyDown={HandlePercentInput}
              value={minCorrectPercentage}
              label="Porcentagem mínima"
              style={{ width: "100%" }}
            />
            <CollapseContainer title="Questões">
              <FormMaker
                onChange={(value) => SetQuestions(value)}
                ref={formMakerRef}
              />
            </CollapseContainer>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "flex-start",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                className="row justify-content-between"
                style={{ marginLeft: 0, marginRight: 0 }}
              >
                <FormControlLabel
                  label="Habilitar visualização de respostas"
                  style={{ marginTop: 10, width: "auto" }}
                  control={
                    <Switch
                      color="primary"
                      checked={answerReveal}
                      onChange={(e) => SetAnswerReveal(e.target.checked)}
                    />
                  }
                />
                <div
                  className="row"
                  style={{ marginLeft: 0, marginRight: 0, width: "auto" }}
                >
                  <Fab
                    onClick={() => SetQuestionFormModal(false)}
                    color="success"
                    style={{
                      width: "auto",
                      borderRadius: 10,
                      padding: 5,
                      paddingLeft: 20,
                      paddingRight: 20,
                      marginRight: 10,
                      marginTop: 10,
                    }}
                    size="medium"
                  >
                    <p style={{ fontSize: 16, textTransform: "initial" }}>
                      Salvar e Fechar
                    </p>
                    <CloseIcon />
                  </Fab>
                  <Fab
                    onClick={() => formMakerRef.current.QuestionIncrement()}
                    color="primary"
                    style={{
                      width: "auto",
                      borderRadius: 10,
                      padding: 5,
                      marginTop: 10,
                    }}
                    size="medium"
                  >
                    <p style={{ fontSize: 16, textTransform: "capitalize" }}>
                      Adicionar nova questão
                    </p>
                    <AddIcon />
                  </Fab>
                </div>
              </div>
              {/* <div className="row justify-content-end mt-2" style={{marginLeft:0,marginRight:0}}>
                                <Fab
                                    onClick={() => SetQuestionFormModal(false)}
                                    color="secundary"
                                    style={{
                                        width: 'auto',
                                        borderRadius: 10,
                                        padding: 5,
                                        paddingLeft:20,
                                        paddingRight:20,
                                        marginRight:10
                                    }}
                                    size="medium"
                                >
                                    <p style={{ fontSize: 16, textTransform: 'capitalize' }}>Fechar</p>
                                    <CloseIcon />
                                </Fab>

                               
                            </div> */}
            </div>
          </DialogActions>
        </Dialog>
        {questions != undefined && (
          <div style={{}}>
            <p style={{ marginBottom: 10 }}>
              {questions.length == 0
                ? "Nenhuma questão adicionada"
                : questions.length == 1
                ? "1 questão adicionada"
                : questions.length + " questões adicionadas"}
            </p>

            <Button
              onClick={() => {
                SetQuestionFormModal(true);
              }}
              style={{ color: "#38a4f8" }}
              variant="text"
            >
              Abrir formulário de exercícios
            </Button>

            {/* <DefaultButton
                    onClick={() => SetQuestionFormModal(true)}
                    text="Abrir formulário de exercícios"
                    bg="confirm"
                /> */}
          </div>
        )}
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to={`/modules/${module_id}/${course_id}`}
        />
        <DefaultButton
          bg="confirm"
          text="Criar aula"
          onClick={() => {
            let data = {
              cover_image: coverImage,
              title: title,
              description: description,
              videos: videos,
              files: files,
              questions: JSON.stringify(questions),
              answerReveal: answerReveal,
              minCorrectPercentage: parseInt(minCorrectPercentage),
            };
            OnConfirm(data);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FormBody;

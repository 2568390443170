import { Header } from "modules/Header";
import Footer from "Home/Footer";

// import { useMainPages } from "modules/Header/data";
import { loggedPages, mainPages } from "modules/Header/data";

import under_develop from 'assets/images/under-develop.svg'
import WhatsappButton from "components/WhatsappButton";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";

const ClubeRT = () => {
  const [pages, setPages] = useState(mainPages);

  const token = useSelector((store) => store.AppReducer.token);

  useEffect(() => {
    if (token) {
      setPages(loggedPages);
    }
  }, [token])

  return (
    <div style={{}}>
      <Header pages={pages} />

      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: 'hidden',
        }}
      >
        <h1><b>Clube RT</b></h1>
        <h2>Em Desenvolvimento</h2>
        <img src={under_develop} alt="Pessoa desenvolvendo a página Clube RT" style={{width: '28rem'}} />
      </div>
      <WhatsappButton />
      <Footer />
    </div>
  );
};

export default ClubeRT;

import { CircularProgress } from "@mui/material";
import { AcceptButton, DeleteButton, ViewButton } from "utils/Buttons";
import CommentModal from "./CommentModal";
import useActions from "./useActions";

const Actions = props => {

    const {
        accepted,
        comment
    } = props;
    
    const {
        accept,
        deleteActions,
        modalComment
    } = useActions(props);

    return (
        <td>
            {!accepted && (
                <AcceptButton
                    title={"Aceitar comentário"}
                    onClick={accept.mutate}
                />
            )}
            <ViewButton
                title={"Ver comentário"}
                onClick={modalComment.open}
            />
            <CommentModal
                open={modalComment.state}
                onClose={modalComment.close}
                comment={comment}
                accepted={accepted}
                onAccept={accept.mutate}
                onDelete={deleteActions.mutate}
            />
            <DeleteButton
                onClick={deleteActions.mutate}
                isLoading={deleteActions.isLoading}
                title={"Deletar comentário"}
            />
        </td>
    );
}

export default Actions;
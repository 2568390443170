import React, { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../components/DefaultButton";

const FormBody = (props) => {
  const { loading, OnConfirm } = props;

  const [name, SetName] = useState("");
  const [course_link, SetCourse_link] = useState("");
  const [description, SetDescription] = useState("");

  return (
    <div className="form-course">
      <form>
        <FormLabel>
          Nome <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Nome"
          value={name}
          onChange={(e) => SetName(e.target.value)}
          required
        />
        <br />
        <FormLabel>
          Link <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Link do vídeo"
          value={course_link}
          onChange={(e) => SetCourse_link(e.target.value)}
          required
        />
        <br />
        <FormLabel>
          Descrição
        </FormLabel>
        <Input
          placeholder="Descrição da aula"
          value={description}
          onChange={(e) => SetDescription(e.target.value)}
          required
        />
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to="/free-courses/list"
        />
        <DefaultButton
          bg="confirm"
          text="Adicionar aula gratuita"
          onClick={() => {
            let data = {
              name: name,
              course_link: course_link,
              description: description,
            };
            OnConfirm(data);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FormBody;

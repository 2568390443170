import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { removeHTML } from "utils/filters";
import { STORAGE_URL, THIS_URL } from "variables";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import Table from "./OtherOptionsTable";
import { Get, Post } from "utils/request";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConfigCheckout from "./OtherOptionsTable/Checkout";

const OtherOptionsCourse = ({
  id,
  testimonials,
  open,
  testimonials_loading,
  handleClose,
  refetch,
  hidden
}) => {
  const dispatch = useDispatch();
  const [openDepoimentoForm, setOpenDepoimentoForm] = useState(false);
  const [isLoading, SetLoading] = useState(false);
  const [course, SetCourse] = useState({});

  const Submit = async (file) => {
    let form = new FormData();
    form.append("banner", file);
    form.append("id", id);

    SetLoading(true);
    let response = await Post("courses-main/upload-testimonial-image", form);
    SetLoading(false);

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );
    refetch();
  };

  const GetData = async () => {
    let response = await Get(`courses-main/reply/${id}`);
    if (response?.status) {
      SetCourse(response?.course);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        display: open ? "flex" : "none",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        gap: "2rem",
        top: 0,
        left: 0,
        zIndex: 1200,
        overflow: "hidden",

        "& .MuiAccordionSummary-root": {
          backgroundColor: "#fff",
          borderBottom: "1px solid #ddd",
        },
      }}
    >
      <CloseIcon
        onClick={handleClose}
        style={{
          cursor: "pointer",
          fontSize: "4rem",
          position: "absolute",
          right: "-0.4rem",
          top: "-0.4rem",
          zIndex: 1201,
          backgroundColor: "#fff",
          borderRadius: "0 0 0 2rem",
        }}
      />

      <div>
        <ConfigCheckout hidden={hidden} {...{ course, handleCloseModal: handleClose }} />
      </div>
    </Box>
  );
};

export default OtherOptionsCourse;

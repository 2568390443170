import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useState, useEffect } from "react";
import JoditEditor from "jodit-react";
// import style from "./style.module.css";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FormLabel } from "react-bootstrap";
import ImageInput from "components/Inputs/ImageInput";
import { fileToBase64 } from "utils/helpers";

const Card = ({ data, index, remove, handle, JoditConfig }) => {
  // const [deleted, setDeleted] = useState(false);

  const handleRemoveCard = () => {
    remove(index);
  };

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fafafa",
        display: "flex",
        width: "98%",
        padding: "1rem 0.4rem",
        border: "0.15rem solid #e3e3e3",
        borderRadius: "0.5rem",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <FormLabel>
        Título <span style={{ color: "red" }}>*</span>
      </FormLabel>
      <JoditEditor
        value={data.title}
        config={JoditConfig}
        tabIndex={1}
        onChange={(newContent) => {
          handle(index, { title: newContent });
        }}
      />

      <FormLabel>
        Texto <span style={{ color: "red" }}>*</span>
      </FormLabel>
      <JoditEditor
        value={data.text}
        config={JoditConfig}
        tabIndex={1}
        onChange={(newContent) => {
          handle(index, { text: newContent });
        }}
      />

      <FormLabel>
        Imagem<span style={{ color: "red" }}>*</span>
        <ImageInput
          path={data.image}
          storageUrl={false}
          ImageChange={(image) => {
            fileToBase64(image).then((base64) => {
              handle(index, { image: base64 });
            });
          }}
        />
      </FormLabel>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos recomendados: 1280x720 (16:9), 720x576 (16:9) ou 1024x768 (4:3)
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
        .jpeg, .png e .gif.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo.
      </span>

      <CancelRoundedIcon
        sx={{
          position: "absolute",
          color: "red",
          right: "-0.6rem",
          top: "0",
          opacity: 0.8,
          fontSize: "1.6rem",
          transform: "translateY(-50%)",
          cursor: "pointer",
          "&:hover": {
            opacity: 1,
          },
        }}
        onClick={handleRemoveCard}
      />
    </div>
  );
};

export default Card;

import React, { useState } from "react";

// import { TextField } from "@mui/material";
import { FormControl as Input } from "react-bootstrap";

import Table from "./Table";
// import DefaultButton from "../../../../components/DefaultButton";

const LeadsCourse = () => {
  const [search, SetSearch] = useState("");
  const [courseSearch, SetCourseSearch] = useState("");

  return (
    <div className="card">
      <div className="card-body">
        <h2 className="title">Lista de e-mails dos cursos</h2>
        <p className="text-muted">
          Aqui são listados todos os e-mails que foram registrados nas páginas de cursos
        </p>
        <hr />
        <div className="flex jcsb flex-wrap margin-bottom">
          <Input
            variant="filled"
            placeholder="Pesquisar registro por E-mail"
            className="flex1"
            value={search}
            onChange={(e) => SetSearch(e.target.value)}
          />
          {/* <Input
            variant="filled"
            placeholder="Pesquisar registro pelo nome do curso"
            className="flex1"
            value={courseSearch}
            onChange={(e) => SetCourseSearch(e.target.value)}
          /> */}
        </div>
        <Table search={search} courseSearch={courseSearch} />
      </div>
    </div>
  );
};

export default LeadsCourse;

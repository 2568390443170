import { Tabs, Tab } from "@mui/material";
import TabPanel from "components/TabPanel";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import SwipeableViews from "react-swipeable-views";

import styles from "./index.module.css";

const TabsContainer = (props) => {
  const {
    children,
    labels,
    tabName = "t",
    variant = "standard",
    swipeableViewsProps = {},
    ...other
  } = props;

  const [tab, SetTab] = useState(0);

  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location?.search);

  useEffect(
    () => SetTab(parseInt(urlSearchParams.get(tabName) || 0)),
    [urlSearchParams]
  );

  return (
    <div className="tabsContainer" {...other} style={{padding: '1rem', paddingTop: '0.4rem', backgroundColor: 'white', borderRadius: '0.4rem', marginTop: '1rem'}}>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant={"scrollable"}
        // scrollButtons={true}
        allowScrollButtonsMobile
        onChange={(_, value) => {
          SetTab(value);
          urlSearchParams.set(tabName, value);
          history.push({ search: `?${urlSearchParams.toString()}` });
        }}
        // variant={variant}
      >
        {React.Children?.map(children, (_, key) => (
          <Tab className={styles.tab} {...labels[key]} key={key} />
        ))}
      </Tabs>
      <div className="tabPanelGroup">
        {React.Children?.map(children, (child, key) => (
          <TabPanel
            style={{ display: key == tab ? "block" : "none" }}
            value={tab}
            index={key}
            key={key}
          >
            {child}
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default TabsContainer;

import React, { useState } from "react";

import style from "./style.module.css";
import { Redirect, useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
// import style from "./style.module.css";
import { useParams } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useQuery } from "react-query";
import { useEffect } from "react";
import AdquireCoursePreview from "./Preview/AdquireCoursePreview";
import ConfigCourseSections from "./Sections";
import { Get } from "utils/request";
import DefaultButton from "components/DefaultButton";
import { CircularProgress, Typography } from "@mui/material";
import { moneyMask } from "utils/moneyMask";
import UndoIcon from "@mui/icons-material/Undo";

const ConfigCourse = (props) => {
  const history = useHistory();
  const [popup, SetPopup] = useState(false);
  const { loading, OnConfirm, save_button_status } = props;
  const [showMural, setShowMural] = useState(true);

  const [is_loading, set_is_loading] = useState(true);

  // section 1
  const [name, SetName] = useState("");
  const [showNameSalesPage, SetShowNameSalesPage] = useState(false);
  const [subtitle, SetSubtitle] = useState("");
  const [videoTrailer, SetVideoTrailer] = useState("");
  const [thumbnail_image, SetThumbnailImage] = useState("");
  const [coverImage, SetCoverImage] = useState("");
  const [image, SetImage] = useState("");
  const [section1_bgColor_1, SetSection1BgColor1] = useState("");
  const [section1_bgColor_2, SetSection1BgColor2] = useState("");
  const [section1_accentColor, SetSection1AccentColor] = useState("");

  // section 2
  const [show_section2, SetShowSection2] = useState(false);
  const [whatWillLearn, SetWhatWillLearn] = useState([]);
  const [section2_title, SetSection2Title] = useState("");
  const [section2_bgColor_1, SetSection2BgColor1] = useState("");
  const [section2_bgColor_2, SetSection2BgColor2] = useState("");
  const [section2_cardColor, SetSection2CardColor] = useState("#f0f0f0");
  const [section2_cardTextColor, SetSection2CardTextColor] =
    useState("#000000");
  const [section2_cardTextSize, SetSection2CardTextSize] = useState("18");
  const [section2_cardBorderColor, SetSection2CardBorderColor] =
    useState("#000000");
  const [section2_cardBorderSize, SetSection2CardBorderSize] = useState("16");

  // section 3
  const [show_section3, SetShowSection3] = useState(false);
  const [questions, SetQuestions] = useState([]);
  const [section3_title, SetSection3Title] = useState("");
  const [section3_bgColor_1, SetSection3BgColor1] = useState("");
  const [section3_bgColor_2, SetSection3BgColor2] = useState("");

  // section 4
  const [show_section4, SetShowSection4] = useState(false);
  const [recommendedTo, SetRecommendedTo] = useState("");
  const [section4_title, SetSection4Title] = useState("");
  const [section4_bgColor_1, SetSection4BgColor1] = useState("");
  const [section4_bgColor_2, SetSection4BgColor2] = useState("");

  // temp section 5
  const [description_image, SetDescriptionImage] = useState("");
  const [temp_show_section5, SetTempShowSection5] = useState(false);
  const [whatWillGet, SetWhatWillGet] = useState([]);
  const [temp_section5_title, SetTempSection5Title] = useState(
    '<p><strong style="font-size: 36px;">O que você vai receber</strong></p>'
  );
  const [temp_section5_bgColor_1, SetTempSection5BgColor1] =
    useState("#ffffff");
  const [temp_section5_bgColor_2, SetTempSection5BgColor2] =
    useState("#ffffff");
  const [temp_section5_cardColor, SetTempSection5CardColor] =
    useState("transparent");
  const [section5_cardBorderColor, SetSection5CardBorderColor] =
    useState("#000000");
  const [section5_cardBorderSize, SetSection5CardBorderSize] = useState("16");

  // section 5
  const [price, SetPrice] = useState("");
  const [installments_quantity, SetInstallmentsQuantity] = useState(1);
  const [installments_price, SetInstallmentsPrice] = useState("0.00");
  const [installments_details, SetInstallmentsDetails] = useState("");
  const [customer_discount_price, SetCustomerDiscountPrice] = useState("0.00");
  const [section5_text1, SetSection5Text1] = useState("");
  const [section5_text2, SetSection5Text2] = useState("");
  const [section5_bgColor_1, SetSection5BgColor1] = useState("");
  const [section5_bgColor_2, SetSection5BgColor2] = useState("");
  const [section5_accentColor, SetSection5AccentColor] = useState("");
  const [section5_textColor, SetSection5TextColor] = useState("#000000");

  // section 7
  const [opcells, SetOpcells] = useState([]);

  // others
  const [description, SetDescription] = useState("");
  const [responsibles, SetResponsibles] = useState([]);
  const [categories, SetCategories] = useState([]);
  const [tags, SetTags] = useState([]);
  const [availabilityDuration, SetAvailabilityDuration] = useState(30);
  const [isFree, SetIsFree] = useState(false);
  const [free_course_changed_to_false, SetFreeCourseChangedToFalse] =
    useState(false);
  const [subStart, SetSubStart] = useState(Date.now());
  const [subEnd, SetSubEnd] = useState(Date.now());
  // const [courseContent, SetCourseContent] = useState("");
  const [certificateBackground, setCertificateBackground] = useState("");
  const [responsibles_bgColor_1, SetResponsiblesBgColor1] = useState("#fff");
  const [responsibles_bgColor_2, SetResponsiblesBgColor2] = useState("#fff");
  const [responsibles_accentColor, SetResponsiblesAccentColor] =
    useState("#f1f1f1");
  const [responsibles_textColor, SetResponsiblesTextColor] = useState("#000");
  const [testimonials, SetTestimonials] = useState([]);

  const [submit_button_bgColor, SetSubmitButtonBgColor] = useState("#ffc813");
  const [submit_button_textColor, SetSubmitButtonTextColor] = useState("#000");
  const [submit_button_text, SetSubmitButtonText] = useState(
    "Quero me inscrever!"
  );
  const [submit_wait_text, SetSubmitWaitText] = useState("Receber notificação");
  const [submit_wait_helptext, SetSubmitWaitHelptext] = useState(
    "Ainda não há vagas disponíveis"
  );

  const [course_thumbnail, SetCourseThumbnail] = useState("");

  // warranty
  const [show_warranty, SetShowWarranty] = useState(false);
  const [warranty_title, SetWarrantyTitle] = useState(
    "Compre agora e tenha 7 dias para decidir se o produto é para você!"
  );
  const [warranty_text, SetWarrantyText] = useState(
    "O produto conta com uma garantia 100% de satisfação. Ou seja, você tem 7 dias para acessar o conteúdo do curso, e se ainda achar que o treinamento não é para você, basta pedir reembolso e então devolveremos integralmente o seu dinheiro, sem questionamentos! Nesse caso, os riscos ficarão por nossa conta."
  );
  const [warranty_bgColor_1, SetWarrantyBgColor1] = useState("white");
  const [warranty_bgColor_2, SetWarrantyBgColor2] = useState("white");

  // checkout page
  // const [checkout_bg_color, SetCheckoutBgColor] = useState("#ffffff");
  // const [checkout_font_color, SetCheckoutFontColor] = useState("#000000");
  // const [checkout_accent_color, SetCheckoutAccentColor] = useState("#8bc7fc");
  // const [checkout_card_color, SetCheckoutCardColor] = useState("#f0f0f0");

  // others 2
  const [existingVideoTrailer, SetExistingVideoTrailer] = useState("");

  const [hidePreview, setHidePreview] = useState(false);

  const { id } = useParams();

  const GetData = async () => {
    let response = await Get(`courses-main/reply/${id}`);

    if (response?.status === true) {
      // section 1
      SetName(response?.course?.name);
      SetShowNameSalesPage(response?.course?.showNameSalesPage);
      SetSubtitle(response?.course?.subtitle);
      response?.course?.video_trailer != "undefined" &&
      response?.course?.video_trailer != null
        ? SetExistingVideoTrailer([
            {
              id: response?.course?.id,
              path: response?.course?.video_trailer,
              name: "Video de trailer",
            },
          ])
        : SetExistingVideoTrailer([]);

      SetThumbnailImage(response?.course?.thumbnail_image);
      SetCoverImage(response?.course?.cover_image);
      SetImage(response?.course?.image);
      SetSection1BgColor1(response?.course?.section1_bgColor_1);
      SetSection1BgColor2(response?.course?.section1_bgColor_2);
      SetSection1AccentColor(response?.course?.section1_accentColor);

      // section 2
      SetShowSection2(response?.course?.show_section2 ? true : false);
      let temp_whatWillLearn = [];
      if (response?.course?.what_will_learn) {
        response?.course?.what_will_learn.forEach((item) => {
          temp_whatWillLearn.push(JSON.parse(item));
        });
      }
      SetWhatWillLearn(temp_whatWillLearn);
      SetSection2Title(response?.course?.section2_title);
      SetSection2BgColor1(response?.course?.section2_bgColor_1);
      SetSection2BgColor2(response?.course?.section2_bgColor_2);
      SetSection2CardColor(response?.course?.section2_cardColor);
      SetSection2CardTextColor(response?.course?.section2_cardTextColor);
      SetSection2CardTextSize(response?.course?.section2_cardTextSize);
      SetSection2CardBorderColor(
        response?.course?.section2_cardBorderColor || "#000"
      );
      SetSection2CardBorderSize(
        response?.course?.section2_cardBorderSize || "16"
      );

      // section 3
      SetShowSection3(response?.course?.show_section3 ? true : false);
      let temp_questions = [];
      if (response?.course?.questions) {
        response?.course?.questions.forEach((item) => {
          temp_questions.push(JSON.parse(item));
        });
      }
      SetQuestions(temp_questions);
      SetSection3Title(response?.course?.section3_title);
      SetSection3BgColor1(response?.course?.section3_bgColor_1);
      SetSection3BgColor2(response?.course?.section3_bgColor_2);

      // section 4
      SetShowSection4(response?.course?.show_section4 ? true : false);
      setShowMural(response?.course?.show_mural ? true:false)
      // let temp_recommendedTo = [];
      // if (response?.course?.recommendedTo) {
      //   response?.course?.recommendedTo.forEach((item) => {
      //     temp_recommendedTo.push(JSON.parse(item));
      //   });
      // }
      SetRecommendedTo(response?.course?.recommendedTo);
      SetSection4Title(response?.course?.section4_title);
      SetSection4BgColor1(response?.course?.section4_bgColor_1);
      SetSection4BgColor2(response?.course?.section4_bgColor_2);

      // temp section 5
      SetTempShowSection5(response?.course?.temp_show_section5 ? true : false);
      let temp_whatWillGet = [];
      if (response?.course?.whatWillGet) {
        try {
          response?.course?.whatWillGet.forEach((item) => {
            temp_whatWillGet.push(JSON.parse(item));
          });
        } catch {}
      }
      SetWhatWillGet(temp_whatWillGet);
      SetTempSection5Title(response?.course?.temp_section5_title);
      SetTempSection5BgColor1(response?.course?.temp_section5_bgColor_1);
      SetTempSection5BgColor2(response?.course?.temp_section5_bgColor_2);
      SetTempSection5CardColor(response?.course?.temp_section5_cardColor);
      SetSection5CardBorderColor(
        response?.course?.section5_cardBorderColor || "#000"
      );
      SetSection5CardBorderSize(
        response?.course?.section5_cardBorderSize || "16"
      );

      // section 5
      SetDescriptionImage(response?.course?.description_image);
      SetPrice(moneyMask(response?.course?.price));
      SetInstallmentsQuantity(response?.course?.installments_quantity);
      SetInstallmentsPrice(moneyMask(response?.course?.installments_price));
      SetInstallmentsDetails(response?.course?.installments_details);
      SetCustomerDiscountPrice(
        moneyMask(response?.course?.customer_discount_price)
      );
      SetSection5Text1(response?.course?.section5_text1);
      SetSection5Text2(response?.course?.section5_text2);
      SetSection5BgColor1(response?.course?.section5_bgColor_1);
      SetSection5BgColor2(response?.course?.section5_bgColor_2);
      SetSection5AccentColor(response?.course?.section5_accentColor);
      SetSection5TextColor(response?.course?.section5_textColor ?? "#000");

      // section 7
      // try {
      //   SetOpcells(
      //     response?.course?.opcells
      //       ? [JSON.parse(response?.course?.opcells)]
      //       : []
      //   );
      // } catch {
      //   SetOpcells(response?.course?.opcells ? response?.course?.opcells : []);
      // }
      let opcellsArray = [];
      if (response?.course?.opcells) {
        // response?.course?.opcells.forEach((opcell) => {
        //   opcellsArray.push(JSON.parse(opcell));
        // });
        SetOpcells(response?.course?.opcells);
      }

      // others
      SetDescription(response?.course?.description);
      SetResponsibles(response?.course?.responsibles);
      SetCategories(response?.course.categories);
      SetTags(response?.course?.tags);
      SetAvailabilityDuration(response?.course?.availability_duration_days);
      SetIsFree(response?.course?.free);
      SetFreeCourseChangedToFalse(response?.course?.free);
      SetSubStart(response?.course?.sub_start);
      SetSubEnd(response?.course?.sub_end);
      // SetCourseContent(response?.course?.course_content);
      setCertificateBackground(response?.course?.certificate_background);
      SetResponsiblesBgColor1(
        response?.course?.responsibles_bgColor_1 ?? "#fff"
      );
      SetResponsiblesBgColor2(
        response?.course?.responsibles_bgColor_2 ?? "#fff"
      );
      SetResponsiblesAccentColor(
        response?.course?.responsibles_accentColor ?? "#f1f1f1"
      );
      SetResponsiblesTextColor(
        response?.course?.responsibles_textColor ?? "Quero me inscrever!"
      );
      SetTestimonials(response?.course?.testimonials ?? []);

      SetSubmitButtonBgColor(
        response?.course?.submit_button_bgColor ?? "#ffc813"
      );
      SetSubmitButtonTextColor(
        response?.course?.submit_button_textColor ?? "#000"
      );
      SetSubmitButtonText(
        response?.course?.submit_button_text ?? "Quero me inscrever!"
      );
      SetSubmitWaitText(
        response?.course?.submit_wait_text ?? "Rceber notificação"
      );
      SetSubmitWaitHelptext(
        response?.course?.submit_wait_helptext ??
          "Ainda não há vagas disponíveis"
      );

      SetCourseThumbnail(response?.course?.course_thumbnail);

      // warranty
      SetShowWarranty(response?.course?.show_warranty ? true : false);
      SetWarrantyTitle(response?.course?.warranty_title);
      SetWarrantyText(response?.course?.warranty_text);
      SetWarrantyBgColor1(response?.course?.warranty_bgColor_1);
      SetWarrantyBgColor2(response?.course?.warranty_bgColor_2);

      // checkout
      // SetCheckoutBgColor(response?.course?.checkout_bg_color ?? "#ffffff");
      // SetCheckoutFontColor(response?.course?.checkout_font_color ?? "#000000");
      // SetCheckoutAccentColor(
      //   response?.course?.checkout_accent_color ?? "#8bc7fc"
      // );
      // SetCheckoutCardColor(response?.course?.checkout_card_color ?? "#f0f0f0");
    }
    set_is_loading(false);
  };

  useEffect(GetData, []);

  const backToCourseList = () => {
    history.push("/courses/list");
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setHidePreview(true);
      } else {
        setHidePreview(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return is_loading ? (
    <div
      style={{
        position: "absolute",
        top: "6rem",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <CircularProgress />
      <Typography variant="h5" style={{ marginLeft: "1rem" }}>
        Carregando...
      </Typography>
    </div>
  ) : (
    <div className={style.gridContainer}>
      <SweetAlert
        warning
        title={"Atenção"}
        onConfirm={() => {
          backToCourseList();
          SetPopup(false);
        }}
        onCancel={() => SetPopup(false)}
        show={popup}
        confirmBtnText="Voltar"
        cancelBtnText="Cancelar"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="primary"
        showCancel={true}
      >
        Tem certeza que deseja voltar?{" "}
        <b>As alterações não salvas serão perdidas.</b>
      </SweetAlert>
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "14.2rem",
          zIndex: 50,
          display: "flex",
          gap: "1rem",
        }}
      >
        <div
          className={style.hidePreviewButton}
          style={{
            background: "#c8cbcf",
          }}
          onClick={() => {
            SetPopup(true);
          }}
        >
          <UndoIcon />
          {!hidePreview && <span>Voltar</span>}
        </div>
        <div
          className={style.hidePreviewButton}
          style={{
            background: "#a6eda1",
          }}
          onClick={() => {
            let data = {
              id: id,
              show_mural:showMural,

              // section 1
              thumbnail_image: thumbnail_image,
              cover_image: coverImage,
              image: image,
              name: name,
              showNameSalesPage,
              subtitle: subtitle,
              videoTrailer: videoTrailer,
              section1_bgColor_1: section1_bgColor_1,
              section1_bgColor_2: section1_bgColor_2,
              section1_accentColor: section1_accentColor,

              // section 2
              show_section2,
              whatWillLearn: whatWillLearn,
              section2_title: section2_title,
              section2_bgColor_1: section2_bgColor_1,
              section2_bgColor_2: section2_bgColor_2,
              section2_cardColor,
              section2_cardTextColor,
              section2_cardTextSize,
              section2_cardBorderColor,
              section2_cardBorderSize,

              // section 3
              show_section3,
              questions: questions,
              section3_title: section3_title,
              section3_bgColor_1: section3_bgColor_1,
              section3_bgColor_2: section3_bgColor_2,

              // section 4
              show_section4,
              recommendedTo: recommendedTo,
              section4_title: section4_title,
              section4_bgColor_1: section4_bgColor_1,
              section4_bgColor_2: section4_bgColor_2,

              // temp section 5
              temp_show_section5,
              whatWillGet,
              temp_section5_title,
              temp_section5_bgColor_1,
              temp_section5_bgColor_2,
              temp_section5_cardColor,
              section5_cardBorderColor,
              SetSection5CardBorderColor,
              section5_cardBorderSize,
              SetSection5CardBorderSize,

              // section 5
              description_image,
              price: price
                .replace("R$ ", "")
                .replaceAll(".", "")
                .replace(",", "."),
              installments_quantity: installments_quantity,
              installments_price: installments_price
                .replace("R$ ", "")
                .replaceAll(".", "")
                .replace(",", "."),
              installments_details: installments_details,

              customer_discount_price: customer_discount_price
                .replace("R$ ", "")
                .replaceAll(".", "")
                .replace(",", "."),
              section5_text1: section5_text1,
              section5_text2: section5_text2,
              section5_bgColor_1: section5_bgColor_1,
              section5_bgColor_2: section5_bgColor_2,
              section5_accentColor: section5_accentColor,
              section5_textColor: section5_textColor,

              // section 7
              opcells: opcells,

              // others
              description: description,
              responsibles: responsibles,
              categories: categories,
              tags: tags,
              availabilityDuration: availabilityDuration,
              free: isFree,
              free_course_changed_to_false:
                free_course_changed_to_false && !isFree,
              subStart: new Date(subStart).toISOString().split("T")[0],
              subEnd: new Date(subEnd).toISOString().split("T")[0],
              // course_content: courseContent,
              certificateBackground,
              responsibles_bgColor_1: responsibles_bgColor_1,
              responsibles_bgColor_2: responsibles_bgColor_2,
              responsibles_accentColor: responsibles_accentColor,
              responsibles_textColor: responsibles_textColor,

              submit_button_bgColor,
              submit_button_textColor,
              submit_button_text,
              submit_wait_text,
              submit_wait_helptext,

              course_thumbnail,

              // warranty
              show_warranty,
              warranty_title: warranty_title,
              warranty_text: warranty_text,
              warranty_bgColor_1: warranty_bgColor_1,
              warranty_bgColor_2: warranty_bgColor_2,

              // checkout
              // checkout_bg_color,
              // checkout_font_color,
              // checkout_accent_color,
              // checkout_card_color,
            };
            OnConfirm(data);
          }}
        >
          {save_button_status.icon}{" "}
          {!hidePreview && <span>{save_button_status.status}</span>}
        </div>
        <div
          className={style.hidePreviewButton}
          style={{
            background: hidePreview ? "#accef2" : "#ffc3b5",
          }}
          onClick={() => setHidePreview(!hidePreview)}
        >
          {hidePreview ? <VisibilityIcon /> : <VisibilityOffIcon />}{" "}
          {!hidePreview && <span>Esconder preview</span>}
        </div>
      </div>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <div
          style={{
            maxWidth: hidePreview ? "100%" : `44%`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <ConfigCourseSections
            {...{
              id,
              // section 1
              showMural,
              setShowMural,
              name,
              SetName,
              showNameSalesPage,
              SetShowNameSalesPage,
              image,
              SetImage,
              thumbnail_image,
              SetThumbnailImage,
              coverImage,
              SetCoverImage,
              videoTrailer,
              SetVideoTrailer,
              subtitle,
              SetSubtitle,
              existingVideoTrailer,

              section1_bgColor_1,
              section1_bgColor_2,
              SetSection1BgColor1,
              SetSection1BgColor2,
              section1_accentColor,
              SetSection1AccentColor,

              // section 2
              show_section2,
              SetShowSection2,
              whatWillLearn,
              SetWhatWillLearn,
              section2_title,
              SetSection2Title,

              section2_bgColor_1,
              section2_bgColor_2,
              SetSection2BgColor1,
              SetSection2BgColor2,

              section2_cardColor,
              SetSection2CardColor,
              section2_cardTextColor,
              SetSection2CardTextColor,
              section2_cardTextSize,
              SetSection2CardTextSize,
              section2_cardBorderColor,
              SetSection2CardBorderColor,
              section2_cardBorderSize,
              SetSection2CardBorderSize,

              // section 3
              show_section3,
              SetShowSection3,
              questions,
              SetQuestions,
              section3_title,
              SetSection3Title,

              section3_bgColor_1,
              section3_bgColor_2,
              SetSection3BgColor1,
              SetSection3BgColor2,

              temp_show_section5,
              SetTempShowSection5,
              whatWillGet,
              SetWhatWillGet,
              temp_section5_title,
              SetTempSection5Title,
              temp_section5_bgColor_1,
              SetTempSection5BgColor1,
              temp_section5_bgColor_2,
              SetTempSection5BgColor2,
              temp_section5_cardColor,
              SetTempSection5CardColor,
              section5_cardBorderColor,
              SetSection5CardBorderColor,
              section5_cardBorderSize,
              SetSection5CardBorderSize,

              // section 4
              show_section4,
              SetShowSection4,
              recommendedTo,
              SetRecommendedTo,
              section4_title,
              SetSection4Title,

              section4_bgColor_1,
              section4_bgColor_2,
              SetSection4BgColor1,
              SetSection4BgColor2,

              // section 5
              description_image,
              SetDescriptionImage,
              price,
              SetPrice,
              installments_quantity,
              SetInstallmentsQuantity,
              installments_price,
              SetInstallmentsPrice,
              installments_details,
              SetInstallmentsDetails,
              customer_discount_price,
              SetCustomerDiscountPrice,
              section5_text1,
              SetSection5Text1,
              section5_text2,
              SetSection5Text2,

              section5_bgColor_1,
              section5_bgColor_2,
              SetSection5BgColor1,
              SetSection5BgColor2,
              section5_accentColor,
              SetSection5AccentColor,
              section5_textColor,
              SetSection5TextColor,

              // section 7
              opcells,
              SetOpcells,

              // others
              description,
              SetDescription,
              responsibles,
              SetResponsibles,
              categories,
              SetCategories,
              tags,
              SetTags,
              availabilityDuration,
              SetAvailabilityDuration,
              isFree,
              SetIsFree,
              subStart,
              subEnd,
              SetSubEnd,
              // courseContent,
              // SetCourseContent,
              certificateBackground,
              setCertificateBackground,
              responsibles_bgColor_1,
              SetResponsiblesBgColor1,
              responsibles_bgColor_2,
              SetResponsiblesBgColor2,
              responsibles_accentColor,
              SetResponsiblesAccentColor,
              responsibles_textColor,
              SetResponsiblesTextColor,
              SetExistingVideoTrailer,
              testimonials,
              SetTestimonials,

              submit_button_bgColor,
              SetSubmitButtonBgColor,
              submit_button_textColor,
              SetSubmitButtonTextColor,
              submit_button_text,
              SetSubmitButtonText,
              submit_wait_text,
              SetSubmitWaitText,
              submit_wait_helptext,
              SetSubmitWaitHelptext,

              course_thumbnail,
              SetCourseThumbnail,

              // warranty
              show_warranty,
              SetShowWarranty,
              warranty_title,
              SetWarrantyTitle,
              warranty_text,
              SetWarrantyText,

              warranty_bgColor_1,
              warranty_bgColor_2,
              SetWarrantyBgColor1,
              SetWarrantyBgColor2,

              // checkout
              // checkout_bg_color,
              // SetCheckoutBgColor,
              // checkout_font_color,
              // SetCheckoutFontColor,
              // checkout_accent_color,
              // SetCheckoutAccentColor,
              // checkout_card_color,
              // SetCheckoutCardColor,
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: hidePreview ? "0" : `42%`,
          display: "flex",
          height: "100vh",
          transition: "width 300ms",
          position: "fixed",
          right: "0",
          background: "#fff",
          borderLeft: "0.2rem dashed black",
        }}
      >
        <AdquireCoursePreview
          {...{
            // section 1
            thumbnail_image,
            coverImage,
            image,
            name,
            subtitle,
            videoTrailer,
            section1_bgColor_1,
            section1_bgColor_2,
            section1_accentColor,
            existingVideoTrailer,

            // section 2
            show_section2,
            whatWillLearn,
            section2_title,
            section2_bgColor_1,
            section2_bgColor_2,
            section2_cardColor,
            SetSection2CardColor,
            section2_cardTextColor,
            SetSection2CardTextColor,
            section2_cardTextSize,
            SetSection2CardTextSize,
            section2_cardBorderColor,
            section2_cardBorderSize,

            // section 3
            show_section3,
            questions: questions,
            section3_title,
            section3_bgColor_1,
            section3_bgColor_2,

            // section 4
            show_section4,
            recommendedTo: recommendedTo,
            section4_title,
            section4_bgColor_1,
            section4_bgColor_2,

            temp_show_section5,
            SetTempShowSection5,
            whatWillGet,
            SetWhatWillGet,
            temp_section5_title,
            SetTempSection5Title,
            temp_section5_bgColor_1,
            SetTempSection5BgColor1,
            temp_section5_bgColor_2,
            SetTempSection5BgColor2,
            temp_section5_cardColor,
            SetTempSection5CardColor,

            // section 5
            description_image,
            price,
            installments_quantity,
            installments_price,
            installments_details,
            customer_discount_price,
            section5_text1,
            section5_text2,
            section5_bgColor_1,
            section5_bgColor_2,
            section5_accentColor,
            section5_textColor,
            section5_cardBorderColor,
            SetSection5CardBorderColor,
            section5_cardBorderSize,
            SetSection5CardBorderSize,

            testimonials,

            // section 7
            opcells,

            // others
            description: description,
            responsibles: responsibles,
            categories: categories,
            tags: tags,
            availabilityDuration: availabilityDuration,
            isFree,
            subStart: new Date(subStart).toISOString().split("T")[0],
            subEnd: new Date(subEnd).toISOString().split("T")[0],
            // courseContent: courseContent,
            responsibles_bgColor_1,
            responsibles_bgColor_2,
            responsibles_accentColor,
            responsibles_textColor,

            submit_button_bgColor,
            submit_button_textColor,
            submit_button_text,
            submit_wait_text,
            submit_wait_helptext,

            course_thumbnail,

            // warranty
            show_warranty,
            warranty_title,
            warranty_text,
            warranty_bgColor_1,
            warranty_bgColor_2,

            // checkout
            // checkout_bg_color,
            // checkout_font_color,
            // checkout_accent_color,
            // checkout_card_color,
          }}
        />
      </div>
    </div>
  );
};

export default ConfigCourse;

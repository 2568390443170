import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Button, Typography } from "@mui/material";
import JoditEditor from "jodit-react";
import { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { Input } from "reactstrap";
import { removeHTML } from "utils/filters";
import { moneyMask } from "utils/moneyMask";
import { STORAGE_URL } from "variables";

import style from "./style.module.css";

const Card = ({
  text,
  image,
  index,
  remove,
  opcells,
  SetOpcells,
  course,
  JoditConfig,
}) => {
  const handleRemoveCard = () => {
    remove(index);
  };

  // const [installments_quantity, SetInstallmentsQuantity] = useState(0);
  // const [installments_price, SetInstallmentsPrice] = useState(0);
  const [price, SetPrice] = useState(0);
  // const [installments_details, SetInstallmentsDetails] = useState("");

  const handleOpcellChange = (data) => {
    // find the opcell by course.id
    const opcell = opcells.find((opcell) => opcell.id === course.id);
    let temp = [...opcells];

    // replace the opcell with the new data
    temp.splice(opcells.indexOf(opcell), 1, {
      ...opcell,
      ...data,
    });
    // set the new opcells
    SetOpcells(temp);
  };

  useEffect(() => {
    handleOpcellChange({
      // installments_quantity,
      // installments_price,
      price,
      // installments_details,
    });
  }, [price]);

  useEffect(() => {
    // SetInstallmentsQuantity(course?.installments_quantity);
    // SetInstallmentsPrice(course?.installments_price);
    SetPrice(course?.price);
    // SetInstallmentsDetails(course?.installments_details);
  }, []);

  return (
    <div
      key={index}
      style={{
        backgroundColor: "",
        width: "100%",
        margin: "0 auto",
        padding: "0.6rem",
        backgroundColor: "#dbdbdb",
        borderRadius: "0.6rem",
        position: "relative",
        display: "flex",
        // flexDirection: 'column',
        flexWrap: "wrap",
        gap: "1rem",
      }}
    >
      <CancelRoundedIcon
        sx={{
          position: "absolute",
          right: "-0.6rem",
          top: "0",
          opacity: 0.5,
          color: "red",
          fontSize: "1.6rem",
          backgroundColor: "white",
          borderRadius: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          "&:hover": {
            opacity: 1,
          },
        }}
        onClick={handleRemoveCard}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            // aspectRatio: "16/9",
            height: "12rem",
            width: "100%",
            overflow: "hidden",
            backgroundImage: `url(${STORAGE_URL}${image})`,
            backgroundSize: "cover",
            borderRadius: "0.4rem",
          }}
        ></div>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {removeHTML(text)}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {/* <FormLabel>
          Parcelamento<span style={{ color: "red" }}>*</span>
          <div style={{ display: "flex" }}>
            <Input
              type="number"
              placeholder="Parcelas"
              required
              value={installments_quantity}
              onChange={(e) => SetInstallmentsQuantity(e.target.value)}
            />

            <span style={{ fontSize: "1.6rem", margin: "0 0.6rem" }}>X</span>

            <Input
              placeholder="Preço"
              value={installments_price}
              onChange={(e) => SetInstallmentsPrice(moneyMask(e.target.value))}
              required
            />
          </div>
        </FormLabel> */}

        <FormLabel>
          Preço à vista<span style={{ color: "red" }}>*</span>
          <Input
            placeholder="Preço"
            value={price}
            onChange={(e) => SetPrice(moneyMask(e.target.value))}
            required
          />
        </FormLabel>

        {/* <FormLabel>
          Fórmula de cálculo das parcelas{" "}
          <span style={{ color: "red" }}>*</span>
          <JoditEditor
            config={JoditConfig}
            value={installments_details}
            onChange={(value) => SetInstallmentsDetails(value)}
          />
        </FormLabel> */}
      </div>
    </div>
  );
};

export default Card;

const { useEffect, useRef } = require("react");

/**
 * @returns 
 * returns a fixed image from a video
 */
const VideoFrame = props => {

    const {
        src,
        ...others
    } = props;

    const videoRef = useRef();

    useEffect(() => {
        if (!src || !videoRef?.current) return;
        if (src instanceof File) videoRef.current.src = URL.createObjectURL(src);
        else videoRef.current.src = src;
    }, [src]);

    return (
        <video
            autoPlay={false}
            controls={false}
            ref={videoRef}
            src={src}
            {...others}
        />
    )
}

export default VideoFrame;
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Show } from "actions/SnackbarActions";
import { Post } from "utils/request";
import Formbody from "./Formbody";
import SweetAlert from "react-bootstrap-sweetalert";

const SciencePlusReferencesEdit = () => {

    const [loading, SetLoading] = useState(false);
    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const { post_id } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();

    const Submit = async (data) => {
        if (!data?.image) {
            SetPopup(true);
            SetPopupMessage("Insira uma imagem!");
            return;
        } else if (!data?.title) {
            SetPopup(true);
            SetPopupMessage("Insira um título!");
            return;
        } else if (!data?.description) {
            SetPopup(true);
            SetPopupMessage("Insira alguma descrição na postagem!");
            return;
        }

        let form = new FormData();
        form.append("id", post_id);
        form.append("title", data?.title);
        form.append("image", data?.image);
        form.append("description", data?.description);
        form.append("link", data?.link);

        SetLoading(true);
        let response = await Post("science-plus/references/alter", form);
        SetLoading(false);
        
         
        dispatch(Show({
            show: true,
            message: response?.message,
            severity: response?.severity
        }));

        if (response?.status === true) history.push(`/science-plus/references/list`);
    }

    return (
        <div className="card">
            <SweetAlert
                warning
                show={popup}
                title="Atenção!"
                confirmBtnText="Ok"
                onConfirm={() => SetPopup(false)}
            >
                {popupMessage}
            </SweetAlert>
            <div className="card-body">
                <h2 className="title">Editar referência</h2>
                <p>Aqui são editadas as referências do +Ciência</p>
                <br/>
                <Formbody
                    OnConfirm={data => Submit(data)}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default SciencePlusReferencesEdit;
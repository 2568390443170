import { motion } from "framer-motion";

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';

const iconVariant = {
    rest: {
        x: 0
    },
    hover: {
        x: -20
    }
};

const ListButtonIcon = props => {

    const {
        listed
    } = props;

    if (listed) return (
        <motion.div
            component={motion.div}
            initial="rest"
            whileHover="hover"
            style={{
                width: 20,
                height: 20,
                overflow: "hidden",
                display: "inline-flex",
                alignItems: "center"
            }}
        >
            <motion.div
                variants={iconVariant}
            >
                <FavoriteIcon
                    fontSize="small"
                />
            </motion.div>
            <motion.div
                variants={iconVariant}
            >
                <CloseIcon
                    fontSize="small"
                />
            </motion.div>
        </motion.div>
    );

    return (
        <FavoriteBorderIcon fontSize="small"/>
    );
}

export default ListButtonIcon;
import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import CollapseContainer from "components/CollapseContainer";
import { useEffect } from "react";
import { useState } from "react";
import CollapseQuestions from "./Collapse";

const Questions = ({ course }) => {
  const { show_section3, questions, section3_title, section3_bgColor_1, section3_bgColor_2 } =
    course;

  const [questionsParsed, setQuestionsParsed] = useState([]);

  useEffect(() => {
    // section 2
    let temp_questions = [];
    if (questions) {
      questions.forEach((item) => {
        temp_questions.push(JSON.parse(item));
      });
    }
    setQuestionsParsed(temp_questions);
  }, [questions]);

  return (
    questionsParsed !== null &&
    questionsParsed !== undefined && (
      <section
        style={{
          padding: "6rem 0",
          justifyContent: "center",
          alignItems: "center",
          display: show_section3 ? "flex" : "none",
          flexDirection: "column",
          background: `linear-gradient(180deg, ${section3_bgColor_1} 0%, ${section3_bgColor_2} 100%)`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "4rem",
            zIndex: 10,
            width: "100%",
            position: "relative",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginBottom: "1rem",
            }}
            dangerouslySetInnerHTML={{ __html: section3_title }}
          />
          <div
            style={{
              borderRadius: "0.8rem",
              padding: "0.4rem 1rem",
              borderRadius: "0.8rem",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {questionsParsed?.map((question, index) => (
              <CollapseQuestions
                key={index}
                question={question["question"]}
                answer={question["answer"]}
              />
            ))}
          </div>
        </div>
      </section>
    )
  );
};

export default Questions;

import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import CollapseContainer from "components/CollapseContainer";
import CollapseQuestions from "./Collapse";

const Questions = ({ props }) => {
  const {
    show_section3,
    questions,
    section3_title,
    section3_bgColor_1,
    section3_bgColor_2,
  } = props;

  return (
    show_section3 && (
      <section
        style={{
          padding: "6rem 0",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          background: `linear-gradient(180deg, ${section3_bgColor_1} 0%, ${section3_bgColor_2} 100%)`,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Seção 6
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "4rem",
            zIndex: 10,
            width: "100%",
            position: "relative",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginBottom: "1rem",
            }}
            dangerouslySetInnerHTML={{ __html: section3_title }}
          />
          <div
            style={{
              borderRadius: "0.8rem",
              padding: "0.4rem 1rem",
              borderRadius: "0.8rem",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {questions?.map((question, index) => (
              <CollapseQuestions
                key={index}
                question={question["question"]}
                answer={question["answer"]}
              />
            ))}
          </div>
        </div>
      </section>
    )
  );
};

export default Questions;

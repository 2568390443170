import { useQuery } from "react-query";
import { Get } from "utils/request";
import logo from "assets/images/rt/icon_transparent.png";
import { STORAGE_URL } from "variables";
import { useHistory } from "react-router";
import { Box } from "@mui/material";
import bg_image from "assets/images/bg-categories.jpg";
import { Carousel } from "react-responsive-carousel";

const CategoriesCoursePagePreview = ({ banners }) => {
  const history = useHistory();

  const { isLoading, data } = useQuery(
    "categories-courses",
    async () => await Get("courses-main/courses_wall")
  );
  const { courses } = data || {};

  return (
    <div
      style={{
        backgroundColor: "black",
        overflowX: "hidden",
        position: "relative",
        overflowY: "auto",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          position: "relative",
          zIndex: 20,
        }}
      >
        {banners?.length > 0 && (
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            renderIndicator={false}
            centerMode={false}
            showThumbs={false}
            transitionTime={500}
            interval={5000}
            showStatus={false}
          >
            {banners &&
              banners.map((banner, index) => (
                <a
                  key={banner.id}
                  href={banner.link}
                  target="_blank"
                  style={{
                    zIndex: 20,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "30vh",
                      // aspectRatio: "16/9",
                      backgroundSize: "contain",
                      backgroundImage: `url(${STORAGE_URL + banner.image})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </a>
              ))}
          </Carousel>
        )}
      </div>

      <img
        src={bg_image}
        style={{
          width: "100vw",
          objectFit: "contain",
          position: "absolute",
          top: "0",
          left: "0",
          opacity: "0.3",
          zIndex: 1,
          webkitMaskImage:
            "linear-gradient(to top, transparent 5%, white 100%)",
          maskImage: "linear-gradient(to top, transparent 5%, white 100%)",
        }}
      />

      {isLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "2rem",
            height: "80vh",
            fontWeight: "bold",
            zIndex: 20,
            position: "relative",
          }}
        >
          <img
            src={logo}
            width="100"
            height="100"
            className="infiniteRotateAnim"
          />
          <span>Carregando...</span>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "4rem",
            marginTop: "6rem",
            padding: "1rem",
            zIndex: 20,
            position: "relative",
          }}
        >
          {courses?.map((course) => (
            <Box
              component="img"
              src={
                course?.thumbnail_image
                  ? STORAGE_URL + course?.thumbnail_image
                  : STORAGE_URL + course?.image
              }
              sx={{
                width: "100%",
                maxWidth: "60rem",
                aspectRatio: "22/9",
                objectFit: "cover",
                borderRadius: "1rem",
                cursor: "pointer",
                transition: "all 0.2s ease-in-out",

                "&:hover": {
                  filter: "brightness(1.1)",
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => history.push(`/courses/adquire/${course?.id}`)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoriesCoursePagePreview;

import React, { useState } from "react";
import { FormGroup } from "@mui/material";
import { FormControl as Input, FormLabel } from "react-bootstrap";
import ImageInput from "../../../../components/Inputs/ImageInput";
import ColorInput from "../../../../components/Inputs/ColorInput";
import Label from "../../../../components/InfoLabel";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import JoditEditor from "jodit-react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const JoditConfig = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarInlineForSelection: true,
  showPlaceholder: false,
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "font",
    "fontsize",
    "brush",
  ],
  height: 100,
  minHeight: 100,
  readonly: false,
  defaultLanguage: "pt_br",
  language: "pt_br",
  toolbarAdaptive: false,
};

const FormBody = (props) => {
  const { handleSubmit } = props;
  const {
    title,
    SetTitle = () => {},

    subtitle,
    SetSubtitle = () => {},

    text,
    SetText = () => {},

    section_left_color,
    SetSectionLeftColor = () => {},

    section_right_color,
    SetSectionRightColor = () => {},

    inputColor,
    SetInputColor = () => {},

    inputBgColor,
    SetInputBgColor = () => {},

    accentColor,
    SetAccentColor = () => {},

    section_main_color,
    SetSectionMainColor,

    backgroundImage,
    SetBackgroundImage = () => {},

    fontColor,
    SetFontColor = () => {},
  } = props;

  const [firstLoading, SetFirstLoading] = useState(true);

  const location = useLocation();
  const landingStyle = useSelector((store) => store.AppReducer.pagesStyles);
  const dispatch = useDispatch();

  if (!firstLoading) return <Skeleton />;
  return (
    <div>
      <form>
        <FormGroup>
          <FormLabel>
            Título <span style={{ color: "red" }}>*</span>
            <JoditEditor
              config={JoditConfig}
              value={title}
              onChange={(value) => SetTitle(value)}
            />
          </FormLabel>

          <FormLabel>
            Subtítulo <span style={{ color: "red" }}>*</span>
            <JoditEditor
              config={JoditConfig}
              value={subtitle}
              onChange={(value) => SetSubtitle(value)}
            />
          </FormLabel>

          {/* <FormLabel>
            Texto apresentação <span style={{ color: "red" }}>*</span>
            <JoditEditor
              config={JoditConfig}
              value={text}
              onChange={(value) => SetText(value)}
            />
          </FormLabel> */}
          <br />

          {/* <Label
            label="Foto de fundo"
            title="A foto de fundo é mostrada no plano de fundo da página de cadastro"
          />
          <ImageInput
            ImageChange={(image) => SetBackgroundImage(image)}
            path={backgroundImage}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.8rem",
              marginTop: "1rem",
            }}
          >
            <span
              style={{
                padding: "0.2rem 0.4rem",
                borderRadius: "0.4rem",
                backgroundColor: "#f1f1f1",
              }}
            >
              <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Proporção de tela
              recomendada: 1:1 ou 4:3
            </span>
            <span
              style={{
                padding: "0.2rem 0.4rem",
                borderRadius: "0.4rem",
                backgroundColor: "#f1f1f1",
              }}
            >
              <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos:
              .jpg, .jpeg, .png e .gif.
            </span>
            <span
              style={{
                padding: "0.2rem 0.4rem",
                borderRadius: "0.4rem",
                backgroundColor: "#f1f1f1",
              }}
            >
              <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de
              tamanho para o arquivo.
            </span>
          </div> */}
          <br />

          {/* <Label
            label="Cor de fundo principal"
            required
            title="A cor que irá aparecer na página logo atrás da seção de cadastro"
          />
          <ColorInput
            value={section_main_color}
            onChange={(color) =>
              color !== undefined && SetSectionMainColor(color)
            }
          />
          <br />

          <Label
            label="Cor de fundo da seção de cima"
            required
            title="A cor de fundo que irá aparecer na seção de cima da página de cadastro"
          />
          <ColorInput
            value={section_left_color}
            onChange={(color) =>
              color !== undefined && SetSectionLeftColor(color)
            }
          /> */}
          {/* <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> A imagem de fundo
            pode sobrepor a cor da seção. Nesse caso, recomenda-se usar imagens
            do tipo .png
          </span> */}
          <br />

          <Label
            label="Cor de fundo do cartão"
            required
            title="A cor de fundo que irá aparecer na seção de baixo da página de cadastro"
          />
          <ColorInput
            value={section_right_color}
            onChange={(color) =>
              color !== undefined && SetSectionRightColor(color)
            }
          />
          <br />

          <Label
            label="Cor dos campos de texto"
            required
            title="A cor dos campos de texto da página de cadastro"
          />
          <ColorInput
            value={inputColor}
            onChange={(color) => color !== undefined && SetInputColor(color)}
          />
          <br />

          <Label
            label="Cor do fundo dos campos de texto"
            required
            title="A cor de fundo dos campos de texto da página de cadastro"
          />
          <ColorInput
            value={inputBgColor}
            onChange={(color) => color !== undefined && SetInputBgColor(color)}
          />
          <br />

          <Label
            label="Cor de destaque"
            required
            title="A cor de destaque da página de cadastro aparecerá nos elementos especiais"
          />
          <ColorInput
            value={accentColor}
            onChange={(color) => {
              color !== undefined && SetAccentColor(color);
            }}
          />
          <br />

          <Label
            label="Cor da fonte"
            required
            title="Essa cor irá mudar a cor da fonte da página"
          />
          <ColorInput
            value={fontColor}
            onChange={(color) => {
              color !== undefined && SetFontColor(color);
            }}
          />
          <br />
        </FormGroup>
      </form>
      {/* <div className="w100 inline-flex jcsb margin-top-1">
        <DefaultButton
          type="submit"
          bg="confirm"
          text="Salvar configurações"
          loadingtext="Salvando configurações"
          // loading={loading}
          onClick={handleSubmit}
        />
      </div> */}
    </div>
  );
};

const Skeleton = () => {
  return (
    <div>
      <div
        className="skeleton-base skeleton-shimmer w100"
        style={{ height: "2em" }}
      />
      <br />
      <div
        className="skeleton-base skeleton-shimmer"
        style={{ width: "7em", height: "7em" }}
      />
      <br />
      <div
        className="skeleton-base skeleton-shimmer w100"
        style={{ height: "2em" }}
      />
      <br />
      <div
        className="skeleton-base skeleton-shimmer w100"
        style={{ height: "2em" }}
      />
      <br />
      <div
        className="skeleton-base skeleton-shimmer w100"
        style={{ height: "2em" }}
      />
      <br />
    </div>
  );
};

export default FormBody;

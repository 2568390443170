import { useEffect, useState } from "react";
import { Get } from "utils/request";

export const useMainPages = () => {
  // const [courseItems, SetCourseItems] = useState([]);

  // const GetData = async () => {
  //   let response = await Get("courses-main/category");
  //   let categories = [];

  //   if (response?.status === true) {
  //     for (let i = 0; i < response?.categories?.length; i++) {
  //       let currCategory = response?.categories[i];
  //       let tempCategoryItems = {};
  //       let tempCategoryCourses = [];

  //       if (currCategory?.courses?.length === 0) {
  //         continue;
  //       }

  //       for (let j = 0; j < currCategory?.courses?.length; j++) {
  //         let currCourse = currCategory?.courses[j];
  //         // console.log(currCourse);
  //         tempCategoryCourses.push({
  //           label: currCourse?.name,
  //           link: `/courses/adquire/${currCourse?.id}`,
  //         });
  //       }

  //       tempCategoryItems["label"] = currCategory?.name;
  //       tempCategoryItems["items"] = tempCategoryCourses;
  //       categories.push(tempCategoryItems);
  //     }
  //   }

  //   SetCourseItems([...categories]);
  // };

  // useEffect(GetData, []);

  return [
    // {
    //     label: "Missão",
    //     link: "/mission"
    // },
    {
      label: "Sobre nós",
      link: "/about",
    },
    {
      label: "Saúde Integrativa",
      link: "integrative",
    },
    {
      label: "Cursos",
      link: "/categories",
    },
    {
      label: "+Ciência",
      link: "/science-plus",
    },
  ];
};

export const mainPages = [
  // {
  //     label: "Missão",
  //     link: "/mission"
  // },
  {
    label: "Sobre nós",
    link: "/about",
  },
  {
    label: "Saúde Integrativa",
    link: "/integrative",
  },
  {
    label: "Cursos",
    link: "/categories"
  },
  {
    label: "+Ciência",
  },
];

//items to show when the user is already logged
export const loggedPages = [
  {
    label: "Página Inicial",
    link: "/",
  },
  {
    label: "Minha lista de desejos",
    link: "/minha-lista",
  },
  {
    label: "Meus cursos",
    link: "/my-courses",
  },
];

const themes = [
  {
    sec_1_1: "#C6FFDD",
    sec_1_2: "#FBD786",
    sec_1_3: "#f7797d",

    sec_2_1: "#f4f4f4",
    sec_2_2: "white",

    sec_3_1: "#C6FFDD",
    sec_3_2: "#FBD786",

    sec_4_1: "#f4f4f4",
    sec_4_2: "white",

    sec_5_1: "#C6FFDD",
    sec_5_2: "#FBD786",
    sec_5_3: "#f7797d",

    warranty_1: "#f4f4f4",
    warranty_2: "white",
  },
  {
    sec_1_1: "#3494E6",
    sec_1_2: "#EC6EAD",
    sec_1_3: "white",

    sec_2_1: "#f4f4f4",
    sec_2_2: "white",

    sec_3_1: "#3494E6",
    sec_3_2: "#EC6EAD",

    sec_4_1: "#f4f4f4",
    sec_4_2: "white",

    sec_5_1: "#3494E6",
    sec_5_2: "#EC6EAD",
    sec_5_3: "white",

    warranty_1: "#f4f4f4",
    warranty_2: "white",
  },
  {
    sec_1_1: "#11998e",
    sec_1_2: "#38ef7d",
    sec_1_3: "#12FFF7",

    sec_2_1: "#f4f4f4",
    sec_2_2: "white",

    sec_3_1: "#11998e",
    sec_3_2: "#38ef7d",

    sec_4_1: "#f4f4f4",
    sec_4_2: "white",

    sec_5_1: "#11998e",
    sec_5_2: "#38ef7d",
    sec_5_3: "#12FFF7",

    warranty_1: "#f4f4f4",
    warranty_2: "white",
  },
  {
    sec_1_1: "#0F2027",
    sec_1_2: "#2C5364",
    sec_1_3: "black",

    sec_2_1: "#f4f4f4",
    sec_2_2: "white",

    sec_3_1: "#0F2027",
    sec_3_2: "#2C5364",

    sec_4_1: "#f4f4f4",
    sec_4_2: "white",

    sec_5_1: "#0F2027",
    sec_5_2: "#2C5364",
    sec_5_3: "black",

    warranty_1: "#f4f4f4",
    warranty_2: "white",
  },
  {
    sec_1_1: "#8E2DE2",
    sec_1_2: "#4A00E0",
    sec_1_3: "white",

    sec_2_1: "#f4f4f4",
    sec_2_2: "white",

    sec_3_1: "#8E2DE2",
    sec_3_2: "#4A00E0",

    sec_4_1: "#f4f4f4",
    sec_4_2: "white",

    sec_5_1: "#8E2DE2",
    sec_5_2: "#4A00E0",
    sec_5_3: "white",

    warranty_1: "#f4f4f4",
    warranty_2: "white",
  },
  {
    sec_1_1: "#34e89e",
    sec_1_2: "#0f3443",
    sec_1_3: "white",

    sec_2_1: "#f4f4f4",
    sec_2_2: "white",

    sec_3_1: "#34e89e",
    sec_3_2: "#0f3443",

    sec_4_1: "#f4f4f4",
    sec_4_2: "white",

    sec_5_1: "#34e89e",
    sec_5_2: "#0f3443",
    sec_5_3: "white",

    warranty_1: "#f4f4f4",
    warranty_2: "white",
  }
]

export default themes;
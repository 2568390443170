import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Modal,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Pagination } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Reorder, AnimatePresence } from "framer-motion";

import Board from "./Board";
import SaveOrderButton from "modules/SaveOrderButton";
import { Get, Post } from "utils/request";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Show } from "actions/SnackbarActions";
import DefaultButton from "components/DefaultButton";
import AsideBannerAdd from "./Add";
import { FormLabel } from "react-bootstrap";
import { Input } from "reactstrap";
import JoditEditor from "jodit-react";

const JoditConfig = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarInlineForSelection: true,
  showPlaceholder: false,
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "font",
    "fontsize",
    "brush",
  ],
  height: 100,
  minHeight: 100,
  readonly: false,
  defaultLanguage: "pt_br",
  language: "pt_br",
  toolbarAdaptive: false,
};

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  maxWidth: "100%",
  minWidth: "36rem",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
  padding: "2rem 3rem",
  justifyContent: "center",
  alignItems: "center",
};

const AulasGratuitas = (props) => {
  const {
    search = "",
    free_lessons_title,
    setFree_lessons_title,

    free_lessons_two_views,
    setFreeLessonsTwoViews,

    free_lessons_transition_time,
    setFreeLessonsTransitionTime,

    handleOpenLoadingModal,
    handleCloseLoadingModal,
  } = props;

  const [[categories, tempCategories], SetCategories] = useState([[], []]);
  const [page, SetPage] = useState(1);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useQuery(
    ["courses", search, page],
    async () => await Get(`courses-main/free-courses`),
    {
      onSuccess: (data) => {
        const { courses } = data || {};
        SetCategories([courses, courses]);
      },
    }
  );
  const { pagination: { last_page: maxPage = 1, perPage } = {} } = data || {};

  const orderMutation = useMutation(
    async () => {
      const form = new FormData();
      form.append("perPage", perPage);
      form.append("currPage", page);
      for (let i = 0; i < tempCategories?.length; i++)
        form.append("freeCoursesIds[]", tempCategories[i]?.id);

      return await Post("courses-main/free-courses/order", form);
    },
    {
      onSuccess: (data) => {
        const { message } = data || {};
        dispatch(
          Show({
            show: true,
            message: message,
            severity: "success",
          })
        );
      },
    }
  );

  const Submit2 = async () => {
    let form = new FormData();
    form.append("free_courses_title", free_lessons_title);
    form.append("free_lessons_two_views", free_lessons_two_views);
    form.append("free_lessons_transition_time", free_lessons_transition_time);

    form.append("type", "firstContent");
    handleOpenLoadingModal();
    let response = await Post("site-rt/description/update", form);
    handleCloseLoadingModal();

    if (response.status === 200) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
          buttonActionText: "Ver",
        })
      );
    } else if (response.status !== 200) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
          buttonActionText: "Ver",
        })
      );
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <AsideBannerAdd {...{ handleClose, refetch }} />
        </Box>
      </Modal>

      <DefaultButton
        bg="confirm"
        text="Adicionar nova aula gratuita"
        onClick={handleOpen}
      />
      <div className="table-responsive">
        <Reorder.Group
          as={"table"}
          className="table table-striped"
          axis={"y"}
          values={tempCategories}
          onReorder={(value) => SetCategories([categories, value])}
          style={{
            borderColor: "transparent",
            overflow: "hidden",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "3em",
                }}
              />
              <th>Imagem</th>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              tempCategories?.map((value, index) => (
                <Board
                  draggable={search == ""}
                  value={value}
                  key={value?.id}
                  id={value?.id}
                  // name={value?.name}
                  OnDelete={refetch}
                />
              ))}
          </tbody>
        </Reorder.Group>
      </div>
      <div className="w100 flex jcc">
        <CircularProgress hidden={!isLoading} />
        <p hidden={categories?.length !== 0 || isLoading}>
          Nenhuma aula foi achada
        </p>
      </div>
      <Pagination
        className={maxPage == 1 ? "hide" : ""}
        style={{ display: "flex", justifyContent: "flex-end" }}
        count={maxPage}
        value={page}
        onChange={(_, value) => SetPage(value)}
      />
      <AnimatePresence>
        {JSON.stringify(categories) != JSON.stringify(tempCategories) && (
          <SaveOrderButton
            onClick={() => {
              orderMutation.mutate();
              SetCategories([tempCategories, tempCategories]);
            }}
            onCancel={() => SetCategories([categories, categories])}
          />
        )}
      </AnimatePresence>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6rem",
        }}
      >
        <FormLabel>
          Título <span style={{ color: "red" }}>*</span>
          <JoditEditor
            config={JoditConfig}
            value={free_lessons_title}
            onChange={(value) => setFree_lessons_title(value)}
          />
        </FormLabel>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={eval(free_lessons_two_views)}
                onChange={(e) =>
                  setFreeLessonsTwoViews(!eval(free_lessons_two_views))
                }
              />
            }
            label="Duas visualizações"
          />
        </FormGroup>

        <span
          style={{
            padding: "0.2rem 0.4rem",
            borderRadius: "0.4rem",
            backgroundColor: "#f1f1f1",
            width: "max-content",
          }}
        >
          <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Esta opção mostrará
          duas visualizações dos cursos gratuitos.
        </span>

        <FormLabel>
          Tempo de transição das aulas (em milissegundos) (5000 milissegundos =
          5 segundos)
          <Input
            placeholder="Ex: 5000"
            type="number"
            value={free_lessons_transition_time}
            onChange={(e) => setFreeLessonsTransitionTime(e.target.value)}
          />
        </FormLabel>

        <div>
          <Button variant="contained" color="success" onClick={() => Submit2()}>
            Salvar seção 3
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AulasGratuitas;

import React, { useState } from "react";
import FormBody from "./FormBody/index";

import { Get, Post } from "../../../utils/request";
import SweetAlert from "react-bootstrap-sweetalert";
import style from "./style.module.css";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import logo_loading from "assets/images/rt/icon_transparent.png";
import { Show } from "../../../actions/SnackbarActions";
import { PAGE_KEYS } from "../../../variables";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import Register from "../Preview/Register";
import { CircularProgress, Typography } from "@mui/material";

const ConfigCadastro = () => {
  const [popup, SetPopup] = useState(false);
  const [popupMessage, SetPopupMessage] = useState("");
  const [hidePreview, setHidePreview] = useState(false);
  const [save_button_status, setSaveButtonStatus] = useState({
    status: "Salvar tudo",
    icon: <SaveIcon />,
  });
  const [title, SetTitle] = useState("Seja bem-vindo(a)!");
  const [subtitle, SetSubtitle] = useState("Crie sua conta para continuar");
  const [text, SetText] = useState("");
  const [section_main_color, SetSectionMainColor] = useState("#f5f5f5");
  const [section_left_color, SetSectionLeftColor] = useState("#fff");
  const [section_right_color, SetSectionRightColor] = useState("#fff");
  const [inputColor, SetInputColor] = useState("#000");
  const [inputBgColor, SetInputBgColor] = useState("#fff");
  const [accentColor, SetAccentColor] = useState("#79CCE6");
  const [backgroundImage, SetBackgroundImage] = useState("");
  const [fontColor, SetFontColor] = useState("#000");

  const [loading, SetLoading] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const { isLoading } = useQuery(
    "register",
    async () => {
      const response = await Get(`styles/pages/key/${PAGE_KEYS.register}`);
      return response;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        SetTitle(data?.title ?? "Cadastre-se para garantir sua vaga!");
        SetSubtitle(data?.subtitle ?? "Crie sua conta para continuar");
        SetText(data?.text ?? "");
        SetSectionMainColor(data?.section_main_color ?? "#f5f5f5");
        SetSectionLeftColor(data?.section_left_color ?? "#fff");
        SetSectionRightColor(data?.section_right_color ?? "#fff");
        SetInputColor(data?.input_color ?? "#545454");
        SetInputBgColor(data?.input_bg_color ?? "#fff");
        SetAccentColor(data?.accent_color ?? "#79CCE6");
        SetBackgroundImage(data?.background_image ?? "");
        SetFontColor(data?.font_color ?? "#000");
      },
    }
  );

  const Submit = async () => {
    let form = new FormData();

    form.append("page_key", PAGE_KEYS.register);
    form.append("page_path", location.pathname);
    form.append("title", title);
    form.append("subtitle", subtitle);
    form.append("text", text);
    form.append("section_main_color", section_main_color);
    form.append("section_left_color", section_left_color);
    form.append("section_right_color", section_right_color);
    form.append("input_color", inputColor);
    form.append("input_bg_color", inputBgColor);
    form.append("accent_color", accentColor);
    form.append("background_image", backgroundImage);
    form.append("font_color", fontColor);

    SetLoading(true);
    setSaveButtonStatus({
      status: "Salvando...",
      icon: <HourglassBottomIcon />,
    });
    let response = await Post("styles/pages/update", form);
    SetLoading(false);

    if (response["status"] === true) {
      setSaveButtonStatus({
        status: "Salvo!",
        icon: <CheckIcon />,
      });
      dispatch(
        Show({
          show: true,
          message: response["message"],
          severity: "success",
        })
      );
      setTimeout(() => {
        setSaveButtonStatus({
          status: "Salvar tudo",
          icon: <SaveIcon />,
        });
      }, 2000);
    } else {
      setSaveButtonStatus({
        status: "Erro!",
        icon: <CheckIcon />,
      });
      dispatch(
        Show({
          show: true,
          message: response["message"],
          severity: "error",
        })
      );
      setTimeout(() => {
        setSaveButtonStatus({
          status: "Salvar tudo",
          icon: <SaveIcon />,
        });
      }, 2000);
    }
  };

  return isLoading ? (
    <div style={{ width: "100%", aspectRatio: "16/9", display: "flex" }}>
      <img
        src={logo_loading}
        alt="Logotipo RT"
        className={"infiniteRotateAnim"}
        style={{
          width: "12rem",
          height: "12rem",
          margin: "auto",
        }}
      />
    </div>
  ) : (
    <div className={style.gridContainer}>
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "14.2rem",
          zIndex: 50,
          display: "flex",
          gap: "1rem",
        }}
      >
        <div
          className={style.hidePreviewButton}
          style={{
            background: "#a6eda1",
          }}
          onClick={() => Submit()}
        >
          {save_button_status.icon} {save_button_status.status}
        </div>
        <div
          className={style.hidePreviewButton}
          style={{
            background: hidePreview ? "#accef2" : "#ffc3b5",
          }}
          onClick={() => setHidePreview(!hidePreview)}
        >
          {hidePreview ? <VisibilityIcon /> : <VisibilityOffIcon />}{" "}
          <span>{hidePreview ? "Mostrar" : "Esconder"} preview</span>
        </div>
      </div>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <div
          style={{
            maxWidth: "44%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <h2 className="title">Configurações da página "Cadastro"</h2>
          </div>

          <FormBody
            {...{
              title,
              subtitle,
              text,
              section_main_color,
              section_left_color,
              section_right_color,
              inputColor,
              inputBgColor,
              accentColor,

              backgroundImage,
              fontColor,

              SetTitle,
              SetSubtitle,
              SetText,
              SetSectionMainColor,
              SetSectionLeftColor,
              SetSectionRightColor,
              SetInputColor,
              SetInputBgColor,
              SetAccentColor,

              SetBackgroundImage,
              SetFontColor,
            }}
            loading={loading}
          />
        </div>
      </div>
      <div
        style={{
          width: hidePreview ? "0" : `43%`,
          display: "flex",
          height: "100vh",
          transition: "width 300ms",
          position: "fixed",
          right: "0",
          background: "#fff",
          borderLeft: "0.2rem dashed black",
        }}
      >
        <Register
          {...{
            title,
            subtitle,
            text,
            section_main_color,
            section_left_color,
            section_right_color,
            inputColor,
            inputBgColor,
            accentColor,

            backgroundImage,
            fontColor,

            SetTitle,
            SetSubtitle,
            SetText,
            SetSectionMainColor,
            SetSectionLeftColor,
            SetSectionRightColor,
            SetInputColor,
            SetInputBgColor,
            SetAccentColor,

            SetBackgroundImage,
            SetFontColor,
          }}
        />
      </div>
    </div>
  );
};

export default ConfigCadastro;

import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { Get, Post } from "utils/request";
import { Reorder, AnimatePresence } from "framer-motion";

import Board from "./Board";
import SaveOrderButton from "./SaveOrderButton";
import Paginate from "components/Paginate";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";

const Table = props => {
    const {
        search = ""
    } = props;

    const [[modules, tempModules], SetModules] = useState([[], []]);
    const [page, SetPage] = useState(1);

    const { id } = useParams();
    const dispatch = useDispatch();
    const { isLoading, data, refetch } = useQuery(["modules", id, search], async () => await Get(`courses-main/related-info/modules?course_id=${id}&search=${search}`), {
        onSuccess: (data) => {
            ;
            const { modules } = data || {};
            SetModules([modules, modules]);
        },
    });
    console.log(data)
    console.log('rrerender')
    const {
        pagination: {
            lastPage: maxPage = 1,
            perPage
        } = {}
    } = data || {};

    const orderMutation = useMutation(async () => {
        const form = new FormData();
        form.append("perPage", perPage);
        form.append("currPage", page);
        for (let i = 0; i < tempModules?.length; i++) form.append("modulesIds[]", tempModules[i]?.id);

        return await Post("modules/order", form);
    }, {
        onSuccess: (data) => {
            const { message } = data || {};
            dispatch(Show({
                show: true,
                message: message,
                severity: "success"
            }));
        }
    });

    return (
        <div className="modules-table">
            <div className="table-responsive">
                <Reorder.Group
                    as={"table"}
                    className="table table-striped"
                    axis={"y"}
                    values={tempModules}
                    onReorder={value => SetModules([modules, value])}
                    style={{
                        borderColor: "transparent",
                        overflow: "hidden"
                    }}
                >
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "3em"
                                }}
                            />
                            <th>Nome</th>
                            <th>Disponibilidade</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && tempModules?.map((value, index) => (
                            <Board
                                draggable={search == ""}
                                key={value?.id}
                                value={value}
                                module_id={value.id}
                                name={value.name}
                                disponibility={value.disponibility}

                                description={value.description}
                                onDelete={refetch}
                            />
                        ))}
                    </tbody>
                </Reorder.Group>
            </div>
            <div className="w100 flex jcc">
                <CircularProgress hidden={!isLoading}/>
                <p
                    hidden={isLoading || modules?.length != 0}
                >Nenhum módulo achado</p>
            </div>
            <Paginate
                maxPage={maxPage}
                value={page}
                label={"mp"}
                onChange={SetPage}
            />
            <AnimatePresence>
                {JSON.stringify(modules) != JSON.stringify(tempModules) && (
                    <SaveOrderButton
                        onClick={() => {
                            orderMutation.mutate();
                            SetModules([tempModules, tempModules]);
                        }}
                        onCancel={() => SetModules([modules, modules])}
                    />
                )}
            </AnimatePresence>
        </div>
    );
}

export default Table;
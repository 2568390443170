import React, { useState } from "react";

import { MdModeEdit, MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from "components/DefaultButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Post } from "utils/request";
import { Show } from "actions/SnackbarActions";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

const Board = props => {

    const {
        id,
        name,
        description,
        onUpdate = () => {}
    } = props;

    const [popup, SetPopup] = useState(false);

    const [loading, SetLoading] = useState(false);

    const { coupon_id } = useParams();
    
    const addMutation = useMutation(async () => {
        const form = new FormData();
        form.append("user_id", id);
        form.append("coupon_id", coupon_id);

        return await Post("coupon/users/create", form);
    }, {
        onSuccess: (data) => {
            ;
            onUpdate();
        }
    });

    return (
        <tr>
            <td>{name}</td>
            <td>
                <DefaultButton
                    onClick={() => addMutation.mutate()}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Adicionar ao cupom`}
                    bg="info"
                    icon={<PersonAddIcon fontSize={"small"}/>}
                />
            </td>
        </tr>
    );
}

export default Board;
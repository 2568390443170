import React from "react";
import { useDispatch } from "react-redux";
import Footer from "../../Home/Footer";
import { Post } from "../../utils/request";
import { Show } from "../../actions/SnackbarActions";
import FormBody from "./FormBody";

import "./index.css";
import WhatsappButton from "components/WhatsappButton";

const PreRegisterPage = () => {

    const dispatch = useDispatch();

    const Submit = async data => {
        let form = new FormData();
        form.append("name", data?.name);
        form.append("email", data?.email);
        form.append("phone", data?.phone);
        form.append("course_id", data?.courseId);

        let response = await Post("pre-register/create", form);
         
        if (response?.status === true) {
            dispatch(Show({
                show: true,
                message: response?.message,
                severity: response?.severity
            }));
        }
    }

    return (
        <div className="pre-register">
            <div 
                className="pre-register-header flex fdcolumn w100 align-center"
                style={{
                    backgroundColor: "#6400ED"
                }}
            >
                <img src="https://dayannaqueiroz.com.br/wp-content/uploads/elementor/thumbs/Screenshot_2-pe9rmypgj1w1fu6bzeno1xr70b279jnttmycnbqfc0.png"/>
                <h4 className="pre-register-phrase">Educação, Transformação, Superação. Sua oportunidade de vencer começa aqui.</h4>
                <p className="pre-register-mark-phrase">#gerandovencedores</p>
            </div>
            <div className="w100 flex jcc">
                <span
                    className="pre-register-support-text"
                >
                    Faça sua <b>Pré-inscrição</b> agora e saiba em primeira mão sobre o Vestibular dos Cursos de Graduação em Enfermagem e Designer de Interiores
                </span>
            </div>
            <FormBody
                OnConfirm={Submit}
            />
            <WhatsappButton />
            <Footer/>
        </div>
    );
}

export default PreRegisterPage;
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CountUp from "components/CountUp";
import StatisticLabel from "components/StatisticLabel";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

import styles from "./index.module.css";
import styles2 from "./index.module.css";
import StatisticCard from "./StatisticCard";

const StatisticsText = (props) => {
  const { title, text, section3_cards, studentUsers, ...others } =
    props;

  return (
    <Box
      className={styles.container}
      flexDirection={useMediaQuery("(min-width: 600px)") ? "row" : "column"}
      sx={{ backgroundColor: "rgb(240, 244, 244)", position: "relative" }}
      {...others}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: "black",
          position: "absolute",
          top: "0.6rem",
          right: "0.01rem",
          width: "100%",
        }}
      >
        (seção 3)
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className={styles.textContainer}>
          <Typography
            variant="h4"
            style={{
              fontWeight: 700,
              fontWeight: "bold",
            }}
            align="center"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Typography
            align="center"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "2rem",
          }}
        >
          {section3_cards?.map((card, index) => (
            <StatisticCard key={index} {...{ card }} />
          ))}
        </div>
      </div>
    </Box>
  );
};

export default StatisticsText;

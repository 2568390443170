import {CircularProgress} from "@mui/material";
import { Pagination } from "@mui/material";
import {Show} from "actions/SnackbarActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Get } from "utils/request";
import Board from "./Board";

const Table = props => {
  
  const {
    search = ""
  } = props;

  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const dispatch = useDispatch();

  const GetData = async () => {
    setLoading(true);
    let response = await Get(`site-rt/bannerPrincipal-images?search=${search}&page=${page}`);
     
    setLoading(false);

    if(response?.status === true){
      setBanners(response?.banners);
      setMaxPage(response?.pagination?.last_page);
    }else if(!response) dispatch(Show({
      show: true,
      severity: "warning",
      message: "Falha ao carregar os banners"
    }));
  }
  useEffect(()=>{
    GetData();
  },[search, page]);
  
  return(
    <div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Imagem</th>
              <th>Título</th>
              <th>Link</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              banners?.map((value, index) => (
                <Board
                  key={index}
                  id={value["id"]}
                  image={value?.image}
                  link={value?.link}
                  title={value?.title}
                  OnDelete={GetData}
                />
            ))
              }
          </tbody>
        </table>
      </div>
      <div className="w100 flex jcc">
        <CircularProgress hidden={!loading}/>
      </div>
      <Pagination
        className={(maxPage == 1)? "hide": ""}
        style={{ display: "flex", justifyContent: "flex-end" }}
        count={maxPage}
        value={page}
        onChange={(_, value)=> setPage(value)}
      />
    </div>
  )

}
export default Table;
import { Box, Typography } from "@mui/material";
import { STORAGE_URL } from "variables";
import Board from "./Board";

const PrevNextContainer = (props) => {
  const { post } = props;

  console.log("post", post)

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent={post?.next && !post?.prev ? "flex-end" : "space-between"}
      padding={"2rem 0"}
    >
      {post?.prev != null && (
        <Box width="45%" height="fit-content">
          <Typography>Postagem anterior</Typography>
          <hr />
          <Board
            id={post?.prev?.id}
            image={post?.prev?.image ? STORAGE_URL + post?.prev?.image : ""}
            title={post?.prev?.title}
            description={post?.prev?.description}
            createdAt={post?.prev?.created_at}
          />
        </Box>
      )}
      {post?.next != null && (
        <Box width="45%" height="fit-content">
          <Typography>Próxima postagem:</Typography>
          <hr />
          <Board
            id={post?.next?.id}
            image={post?.next?.image ? STORAGE_URL + post?.next?.image : ""}
            title={post?.next?.title}
            description={post?.next?.description}
            createdAt={post?.next?.created_at}
          />
        </Box>
      )}
    </Box>
  );
};

export default PrevNextContainer;

import { CircularProgress } from "@mui/material";
import { Show } from "actions/SnackbarActions";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { AcceptButton, DeleteButton } from "utils/Buttons";
import { Post } from "utils/request";
import useActions from "./useActions";

const Actions = props => {

    const {
        accepted
    } = props;

    const {
        accept,
        deleteActions
    } = useActions(props);

    return (
        <td>
            {!accepted && (
                <AcceptButton
                    onClick={accept.mutate}
                    title={"Aceitar feedback"}
                />
            )}
            <DeleteButton
                onClick={deleteActions.mutate}
                title={"Deletar feedback"}
                isLoading={deleteActions.isLoading}
            />
        </td>
    );
}

export default Actions;
import Label from "components/InfoLabel";
import { Get } from "utils/request";
import Section5Card from "../Cards/Section5Card";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import JoditEditor from "jodit-react";
import { FormLabel } from "@mui/material";

const Section5Edit = (props) => {
  const { teamMembers, team_title, setTeamTitle,  } = props.section5;
  const { JoditConfig } = props;
  const modal_style = props.modal_style;
  const refetch = props.refetchhTeamMembers;

  return (
    <>
      <Label
        label={<span>Equipe</span>}
        required
        title="Selecione os membros da equipe RT"
      />

      <FormLabel>
        <Label
          label={<span>Título</span>}
          required
          title="Título da seção de equipe"
        />
        <JoditEditor
          config={JoditConfig}
          value={team_title}
          onChange={(value) => setTeamTitle(value)}
        />
      </FormLabel>

      <span>
        Ao selecionar os membros que fazem parte da Equipe RT, eles serão
        destacados na página 'Sobre nós' com um "mini currículo" que pode ser
        alterado ao editar a descrição do usuário.
      </span>

      <span>
        <ErrorOutlineIcon style={{ color: "red", marginRight: "0.5rem" }} />
        <b>Atenção!</b> Se o usuário desejado não estiver aparecendo na lista, é
        porque o mesmo não está configurado como "Membro da equipe". Vá para a
        página de usuários e edite o usuário desejado para que ele seja um
        membro da equipe.
      </span>

      <span>
        <ErrorOutlineIcon style={{ color: "red", marginRight: "0.5rem" }} />
        <b>Atenção!</b> Para alterar a foto de perfil, nome ou outros dados do
        usuário, vá para a página de usuários e edite o usuário desejado.
      </span>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(20rem, 1fr))",
          gap: "0.6rem",
          maxWidth: "36rem",
          maxHeight: "40rem",
          overflowY: "scroll",
        }}
      >
        {teamMembers?.map((card, index) => {
          return (
            <Section5Card
              key={index}
              index={index}
              card={card}
              modal_style={modal_style}
              refetch={refetch}
            />
          );
        })}
        {teamMembers?.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "1rem 0",
              width: "100%",
              backgroundColor: "#f5f5f5",
              borderRadius: "0.4rem",
            }}
          >
            <span style={{ fontSize: "1.2rem" }}>Nenhum membro adicionado</span>
          </div>
        )}
      </div>
    </>
  );
};

export default Section5Edit;

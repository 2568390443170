import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Get } from "utils/request";

const useComments = () => {

    const [page, setPage] = useState(1);
    const { id } = useParams();

    const { data, refetch } = useQuery(["courseComments", page], async () => await Get(`lessons/files/comments?course=${id}&page=${page}`), {
        onSuccess: (data) => {
            ;
        },

    });

    const {
        comments,
        pagination: {
            lastPage: maxPage = 1
        } = {}
    } = data || {};

    return {
        comments,
        maxPage,
        page,
        setPage,
        refetch
    };
}

export default useComments;
import { useCallback, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import { logout } from "./actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { STORAGE_URL, URL } from "./variables";
import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { setForcedRole } from "actions/admin.actions";
import LoginIcon from "@mui/icons-material/Login";

import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";

const ProfileMenu = ({ font_color }) => {
  const [menu, setMenu] = useState(false);
  const [anchorEl, SetAnchorEl] = useState(null);

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((store) => store.AppReducer.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const forcedRole = useSelector((store) => store.AdminReducer.forcedRole);

  const toggle = useCallback(() => setMenu(!menu), [menu]);

  const HandleClick = (e) => {
    setMenu(true);
    SetAnchorEl(e.target);
  };

  const HandleClose = () => {
    setMenu(false);
    SetAnchorEl(null);
  };

  const logout_ = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch(logout());
    history.push("/");
  }, []);

  return (
    <>
      <Tooltip title="Perfil" arrow>
        {matchSm ? (
          <IconButton onClick={HandleClick}>
            <Avatar
              src={user?.profile_image ? STORAGE_URL + user?.profile_image : ""}
              style={{
                height: 26,
                width: 26,
              }}
            />
          </IconButton>
        ) : (
          <Button
            sx={{
              color: "white",
              display: "flex",
              gap: "0.6rem",
              fontSize: "1rem",
              whiteSpace: "nowrap",
              backgroundColor: "transparent",
              color: font_color || "white",
              "&:hover": {
                color: font_color,
                background: "rgba(0,0,0,0.1)",
                opacity: 0.8,
              },
              fontWeight: "bold",
            }}
            onClick={HandleClick}
          >
            {user?.name ? (
              <Avatar
                src={
                  user?.profile_image ? STORAGE_URL + user?.profile_image : ""
                }
                style={{
                  height: 30,
                  width: 30,
                }}
              />
            ) : (
              <LoginIcon />
            )}
            <span style={{ marginRight: "0.4rem" }}>
              {user?.name
                ? user?.name.split(" ").slice(0, 3).join(" ")
                : "Login"}
            </span>
          </Button>
        )}
      </Tooltip>
      <Menu
        open={menu}
        anchorEl={anchorEl}
        onClose={HandleClose}
        onClick={HandleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        //getContentAnchorEl={null}
        style={{
          zIndex: 2000,
        }}
        PaperProps={{
          style: {
            minWidth: "10em",
          },
        }}
      >
        {user && Object.keys(user)?.length != 0 ? (
          <div>
            <MenuItem onClick={() => history.push(`/profile/view/${user?.id}`)}>
              <PersonIcon
                sx={{ marginRight: 1, color: "rgb(100, 100, 100)" }}
              />
              <span>Ver perfil</span>
            </MenuItem>
            <Divider />
            {user?.role == "Admin" &&
              (forcedRole == "Aluno" ? (
                <MenuItem
                  sx={{ display: "flex", gap: "0.6rem" }}
                  onClick={() => dispatch(setForcedRole(null))}
                >
                  <VisibilityIcon
                    sx={{
                      color: "rgb(100, 100, 100)",
                    }}
                  />
                  <span>Ver como Admin</span>
                </MenuItem>
              ) : (
                <MenuItem
                  sx={{ display: "flex", gap: "0.6rem" }}
                  onClick={() => dispatch(setForcedRole("Aluno"))}
                >
                  <VisibilityIcon
                    sx={{
                      color: "rgb(100, 100, 100)",
                    }}
                  />
                  <span>Ver como Aluno</span>
                </MenuItem>
              ))}
            <MenuItem onClick={logout_}>
              <LogoutIcon
                sx={{ marginRight: 1, color: "rgb(100, 100, 100)" }}
              />
              <span>Sair</span>
            </MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem onClick={() => history.push("/login")}>Login</MenuItem>
            {/* <MenuItem onClick={() => history.push("/register")}>
              Criar conta
            </MenuItem> */}
          </div>
        )}
      </Menu>
    </>
  );
};

export default withRouter(ProfileMenu);

import { useMediaQuery } from "@mui/material";

import CarouselSlider from "components/CarouselSlider";
import placeholder from "assets/images/image_placeholder.png";
import styles from "./index.module.css";

const AsideBanner = () => {

    const match900 = useMediaQuery("(min-width: 900px)");

    return (
        <CarouselSlider
            className={styles.sideBanner}
            swiperProps={{
                loop: true,
                autoplay: {
                    delay: 1000,
                    disableOnInteraction: false
                },
                breakpoints: null,
                pagination: false,
                style: {
                    height: "100%"
                }
            }}
            style={{
                display: match900 ? "block" : "none"
            }}
            arrows={false}
        >
            {new Array(3).fill(undefined)?.map((banner, bannerKey) => (
                <aside
                    // className={"w100"}
                    key={bannerKey}
                    role="banner"
                    style={{
                        backgroundImage: `url(${placeholder})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "13em",
                        height: "100%"
                    }}
                />
            ))}
        </CarouselSlider>
    );
}

export default AsideBanner;
import { Box, LinearProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { ReviewsContext } from "..";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";

import styles from "./index.module.css";

const Average = (props) => {
  const { ...others } = props;

  const [check_reviews, setCheckReviews] = useState([]);

  const { course_id } = useParams();
  const queryClient = useQueryClient();
  const { evaluationFilter, SetEvaluationFilter } =
    useContext(ReviewsContext) || {};

  const { status, course } =
    queryClient?.getQueryData([
      "course",
      course_id,
      `evaluation: ${evaluationFilter}`,
    ]) || {};

  const HandleFilter = (points) => {
    if (points === evaluationFilter) SetEvaluationFilter("");
    else SetEvaluationFilter(points);
  };

  useEffect(() => {
    if (course !== undefined) {
      setCheckReviews(
        course?.evaluationsPerPoints.filter(
          (item) => item.totalEvaluationsPerPoints > 0
        )
      , ()=> {
        if (check_reviews.length === 0) return false
      });
      
    }
  }, [course]);

  return (
    <Box padding={2} {...others} sx={{ width: "100%" }}>
      <Typography variant="h5" gutterBottom align="center">
        {/* <b>Avaliações de alunos</b> */}
        <b>Depoimentos</b>
      </Typography>
      <Box display="flex" flexDirection="column">
        {check_reviews.length === 0 && (
          <Typography variant="body1" align="center">
            {/* Nenhuma avaliação encontrada. */}
            Seja o primeiro a avaliar esse curso!
          </Typography>
        )}
        {check_reviews.length > 0 &&
          course?.evaluationsPerPoints?.map((value, key) => {
            return (
              <Box
                display="inline-flex"
                sx={{ alignItems: "center", gap: 1, width: "100%" }}
                key={value?.points + key}
              >
                <Typography sx={{ display: "flex" }}>
                  {/* {value?.points} {value?.points == 1 ? "estrela" : "estrelas"}
              {console.log()} */}
                  {[...Array(value?.points)].map((_, index) => (
                    <StarRoundedIcon sx={{ color: "#f0c61a" }} key={index} />
                  ))}
                  {[...Array(5 - value?.points)].map((_, index) => (
                    <StarOutlineRoundedIcon
                      sx={{ color: "#f0c61a" }}
                      key={index}
                    />
                  ))}
                </Typography>
                <LinearProgress
                  value={value?.percentageRelative}
                  variant="determinate"
                  className={styles.percentageBar}
                  color="inherit"
                  sx={{ borderRadius: "0.4rem" }}
                  onClick={() => HandleFilter(value?.points)}
                  aria-selected={evaluationFilter === value.points}
                  data-faded={
                    evaluationFilter !== "" && evaluationFilter !== value.points
                  }
                />
                <Typography>{value?.percentageRelative}%</Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default Average;

/**
 *
 * Component of the bottom of the page inviting the user to create an account
 * Used in landing page
 *
 */

import { Box, Container, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Get } from "utils/request";
import Formbody from "./Formbody";

import styles from "./index.module.css";

const BottomLead = () => {
  const [bottomLeadTitle, setBottomLeadTitle] = useState("");
  const [bottomLeadSubtitle, setBottomLeadSubtitle] = useState("");
  const [bottomLeadText1, setBottomLeadText1] = useState("");
  const [bottomLeadText2, setBottomLeadText2] = useState("");

  const GetData = async () => {
    let response = await Get(`site-rt/description?type=firstContent`);
    if (response?.status === true) {
      setBottomLeadTitle(response?.page?.bottom_lead_title);
      setBottomLeadSubtitle(response?.page?.bottom_lead_subtitle);
      setBottomLeadText1(response?.page?.bottom_lead_text1);
      setBottomLeadText2(response?.page?.bottom_lead_text2);
    }
  };
  useEffect(() => GetData(), []);

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        padding: "8rem 1rem",
        display: "flex",
        flexDirection: { xs: "column", md: "column", lg: "row" },
        justifyContent: "center",
        gap: { xs: "1rem", md: "1rem", lg: "2rem" },
        marginTop: "4rem",
      }}
    >
      <div className={styles.text}>
        <Typography
          variant="h4"
          dangerouslySetInnerHTML={{
            __html: bottomLeadTitle ? bottomLeadTitle : "<br/>",
          }}
        />
        <br />
        <Typography
          variant="h5"
          dangerouslySetInnerHTML={{
            __html: bottomLeadSubtitle ? bottomLeadSubtitle : "<br/>",
          }}
        />
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: bottomLeadText1 ? bottomLeadText1 : "<br/>",
          }}
        />
      </div>
      <Formbody {...{ bottomLeadText2 }} />
    </Container>
  );
};

export default BottomLead;

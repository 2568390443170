import { Box } from "@mui/material";

import CourseList from "./CourseList";

const CategoryView = () => {
    return (
        <Box>
            <CourseList/>
        </Box>
    );
}

export default CategoryView;
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Get } from "../../../utils/request";
import Board from "./Board";
import { Pagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import ReloadButton from "components/Buttons/ReloadButton";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Table = props => {

  const {
    search = ""
  } = props;

  const [emails, SetEmails] = useState([]);
  const [loading, SetLoading] = useState(false);

  const [page, SetPage] = useState(0);
  const [maxPage, SetMaxPage] = useState(1);

  const [loadError, SetLoadError] = useState(false);

  const dispatch = useDispatch();

  const GetData = async () => {
    SetLoading(true);
    let response = await Get(`emailname?search=${search}&page=${page}`);
    SetLoading(false);
     
    if (response?.status === true) {
      SetEmails(response?.emails);
      SetMaxPage(response?.pagination?.lastPage);
      SetLoadError(false);
    } else if (!response || !response?.status) {
      dispatch(Show({
        show: true,
        severity: "warning",
        message: "Falha ao carregar os e-mails"
      }));
      SetLoadError(true);
    }
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(GetData, [search, page]);

  useEffect(() => {
    console.log(emails)
  }, [emails]);

  return (
    <>
      <div className="table-responsive">
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Gerados" {...a11yProps(0)} />
              <Tab label="Convertidos" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
          <table className="table table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Telefone</th>
                  <th>Data registrado</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {!loading && emails && emails.length != 0 && emails.map((value, index) => {
                  return !value?.hasActiveAccount && (
                    <Board
                      name={value?.name}
                      email={value?.email}
                      phone={value?.phone}
                      email_sent={value?.email_sent}
                      createdAt={value?.created_at}
                      hasActiveAccount={value?.hasActiveAccount}
                      key={index}
                      id={value?.id}
                      OnDelete={GetData}
                    />
                  )
                })}
                {
                  emails?.filter((value) => !value?.hasActiveAccount).length === 0 && !loading && (
                    <tr>
                      <td colSpan={5} className="text-center">
                        Nenhum e-mail encontrado
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Telefone</th>
                  <th>Data registrado</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {!loading && emails && emails.length !== 0 && emails.map((value, index) => {
                  return value?.hasActiveAccount && (
                    <Board
                      name={value?.name}
                      email={value?.email}
                      phone={value?.phone}
                      createdAt={value?.created_at}
                      hasActiveAccount={value?.hasActiveAccount}
                      key={index}
                      id={value?.id}
                      OnDelete={GetData}
                    />
                  )
                })}
                {
                  emails?.filter((value) => value?.hasActiveAccount).length === 0 && !loading && (
                    <tr>
                      <td colSpan={5} className="text-center">
                        Nenhum e-mail encontrado
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </TabPanel>
        </Box>

        {/* <div className="flex align-center jcc w100">
          <p hidden={loading || (emails && emails.length != 0)}>Nenhum e-mail achado</p>
        </div> */}
      </div>
      <div className="flex align-center jcc w100">
        <CircularProgress className={loading ? "" : "hide"} />
        <ReloadButton onClick={GetData} hidden={!loadError} />
      </div>
      <Pagination
        className={(maxPage == 1 ? "hide" : "")}
        style={{ display: "flex", justifyContent: "flex-end" }}
        count={maxPage}
        value={page}
        onChange={(_, value) => SetPage(value)}
      />
    </>
  );
}

export default Table;
import SelectAutocomplete from "components/Inputs/SelectAutocomplete";
import { Typography } from "@mui/material";
import { Get, Post } from "utils/request";
import { useHistory, useParams } from "react-router";
import { useEffect, useState } from "react";
import DefaultButton from "components/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";

const GroupUsersAdd = () => {

    const [users, SetUsers] = useState([]);
    const [toAddUsers, SetToAddUsers] = useState([]);
    const [loading, SetLoading] = useState(false);

    const { group_id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const GetData = async (search = "") => {
        let response = await Get(`groups?id=${group_id}&outsideUsers=true&outsideUsersSearch=${search}`);
        if (response?.status === true) {
            SetUsers(response?.group?.outsideUsers);
        }
    }
    
    const Submit = async () => {
        let form = new FormData();
        form.append("group_id", group_id);
        for (let i = 0; i < toAddUsers?.length; i++) form.append("user_id[]", toAddUsers[i]?.id);
        
        SetLoading(true);
        let response = await Post("groups/users/create", form);
        SetLoading(false);
        
        dispatch(Show({
            show: true,
            message: response?.message,
            severity: response?.severity
        }));

        if (response?.status === true) history.push(`/groups/${group_id}/view`);
    }

    useEffect(GetData, []);

    return (
        <div className="card">
            <div className="card-body">
                <Typography
                    variant="h4"
                    gutterBottom
                >
                    Adicionar alunos
                </Typography>
                <Typography
                    gutterBottom
                >
                    Aqui são adicionados os alunos a turma em questão
                </Typography>
                <br/>
                <SelectAutocomplete
                    items={users}
                    onSearch={GetData}
                    onChange={users => SetToAddUsers(users)}
                    placeholder={"Pesquisar por alunos"}
                    notFoundMessage={"Nenhum aluno encontrado"}
                    zeroItemsMessage={"Nenhum aluno selecionado"}
                    tableBody={[
                        { objLabel: "name", header: "Nome" },
                        { objLabel: "email", header: "E-mail" }
                    ]}
                />
                <div className="w100 inline-flex jcsb mt-2">
                    <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine/>}
                        to={`/groups/${group_id}/view`}
                    />
                    <DefaultButton
                        bg="confirm"
                        text="Adicionar aluno(s)"
                        onClick={Submit}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}

export default GroupUsersAdd;
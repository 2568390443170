import React, { useState } from "react";

import { MdDelete } from "react-icons/md";
import DefaultButton from "../../../components/DefaultButton";
import { Post } from "../../../utils/request";
import { CircularProgress } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import DescriptionIcon from '@mui/icons-material/Description';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useHistory } from "react-router";
import { BsFillEyeFill } from "react-icons/bs";
import { IoMdMail, IoMdCheckmark } from "react-icons/io";
import { Show } from "actions/SnackbarActions";
import { useDispatch } from "react-redux";

const Board = props => {

  const {
    id = "",
    name = "",
    email = "",
    phone = "",
    email_sent = false,
    createdAt = "",
    hasActiveAccount = false,
    OnDelete
  } = props;

  const [loading, SetLoading] = useState(false);
  const [popup, SetPopup] = useState(false);
  const [sentConfirmation, SetSentConfirmation] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const SendLink = async () => {
    // alert('irra')
    // window.open(`/user/${id}/activate`, "_blank");
  
    const form = new FormData();
    form.append("id", id);

    const response = await Post("emailname/send-link", form);

    if (response?.status) {
      dispatch(
        Show({
          show: true,
          severity: response?.severity,
          message: response?.message,
        })
      );
    } 
  }

  const Delete = async () => {
    let form = new FormData();
    form.append("id", id);

    SetLoading(true);
    let response = await Post("emailname/delete", form);
    SetLoading(false);
    if (response?.status) OnDelete();
  }

  return (
    <tr id={id}>
      <td>{name}</td>
      <td>{email}</td>
      <td>{phone !== 'null' ? phone : 'Não cadastrado'}</td>
      <td>{new Date(Date.parse(createdAt)).toLocaleDateString("pt-BR")}</td>
      <td>
        <SweetAlert
          warning
          title="Atenção"
          show={popup}
          showCancel
          cancelBtnStyle={{ color: "white", textDecoration: "none" }}
          cancelBtnCssClass="btn-danger"
          cancelBtnText="Cancelar"
          confirmBtnText="Excluir"
          onConfirm={() => {
            SetPopup(false);
            Delete();
          }}
          onCancel={() => SetPopup(false)}
        >
          Deseja mesmo excluir o e-mail '{email}' do registro?
        </SweetAlert>

        <SweetAlert
          warning
          title="Atenção"
          show={sentConfirmation}
          showCancel
          cancelBtnStyle={{ color: "white", textDecoration: "none" }}
          cancelBtnCssClass="btn-danger"
          cancelBtnText="Cancelar"
          confirmBtnText="Enviar"
          onConfirm={() => {
            SetSentConfirmation(false);
            SendLink();
          }}
          onCancel={() => SetSentConfirmation(false)}
        >
          Um email de ativação já foi enviado para '{email}'.
          <br />
          Deseja enviar outro?
        </SweetAlert>

        
        <DefaultButton
          // onClick={() => history.push({
          //     pathname: "/accounts/create",
          //     state: {
          //         email: email,
          //         name: name
          //     }
          // })}
          onClick={email_sent ? () => {SetSentConfirmation(true)} : SendLink}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={hasActiveAccount? 'Conta já ativada': `Enviar link de ativação`}
          bg="info"
          loading={loading}
          icon={<IoMdMail size={17} color="white" />}
          style={{
            marginRight: "2px"
          }}
          disabled={hasActiveAccount}
        />
        <DefaultButton
          to={`/log/email/activity/${id}`}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Ver registros`}
          bg="warning"
          icon={<BsFillEyeFill size={17} color="white" />}
          style={{
            marginRight: "2px"
          }}
        />
        <DefaultButton
          onClick={() => SetPopup(true)}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Deletar registro`}
          bg="danger"
          loading={loading}
          icon={!loading && <MdDelete size={17} color="white" />}
        />
      </td>
    </tr>
  );
}

export default Board;
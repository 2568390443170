import { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";

const ScrollToTop = (props) => {

    const {
        history,
        children
    } = props;

    const lastLocation = useLocation();

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (lastLocation?.pathname != location?.pathname) window.scrollTo(0, 0);
        });

        return unlisten
    }, []);

    return (<>{children}</>);
}

export default withRouter(ScrollToTop);
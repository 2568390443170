import { FormLabel, FormControl as Input } from "react-bootstrap";
import InputCard from "../../InputCard";
import DefaultButton from "components/DefaultButton";
import Label from "components/InfoLabel";
import JoditEditor from "jodit-react";
import ColorInput from "components/Inputs/ColorInput";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

const Section2 = ({
  show_warranty,
  SetShowWarranty,
  warranty_title,
  SetWarrantyTitle,
  warranty_text,
  SetWarrantyText,
  warranty_text_color,
  SetWarrantyTextColor,

  warranty_bgColor_1,
  SetWarrantyBgColor1,
  warranty_bgColor_2,
  SetWarrantyBgColor2,

  JoditConfig,
}) => {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={show_warranty}
              onChange={(e) => SetShowWarranty(e.target.checked)}
            />
          }
          label="Ativar seção"
        />
      </FormGroup>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          gap: "1rem",
          marginTop: "2rem",
          position: "relative",
        }}
      >
        <div
          style={{
            display: show_warranty ? "none" : "flex",
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.5)",
            zIndex: 10,
          }}
        >
          <div
            style={{
              margin: "auto",
              backgroundColor: "white",
              color: "red",
              padding: "1rem",
              borderRadius: "0.4rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.6rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
              }}
            >
              Seção desativada
            </Typography>
            <Typography
              variant="subtitle"
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Por padrão, esta seção não sera exibida na página do curso. Clique
              em "Ativar seção" para ativá-la.
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={show_warranty}
                    onChange={(e) => SetShowWarranty(e.target.checked)}
                  />
                }
                sx={{ color: "black" }}
                label="Ativar seção"
              />
            </FormGroup>
          </div>
        </div>
        <FormLabel>
          Título <span style={{ color: "red" }}>*</span>
          <JoditEditor
            config={JoditConfig}
            value={warranty_title}
            onChange={(value) => SetWarrantyTitle(value)}
          />
        </FormLabel>

        <FormLabel>
          <Label label={"Texto da garantia"} />
          <JoditEditor
            config={JoditConfig}
            value={warranty_text}
            onChange={(value) => SetWarrantyText(value)}
          />
        </FormLabel>

        <FormLabel>
          <Label
            label={<span>Cor de fundo 1 </span>}
            required
            title="Esta cor será aplicada no fundo da seção."
          />
        </FormLabel>
        <ColorInput
          value={warranty_bgColor_1}
          onChange={(color) =>
            color !== undefined && SetWarrantyBgColor1(color)
          }
        />

        <FormLabel>
          <Label
            label={<span>Cor de fundo 2 </span>}
            required
            title="Esta cor será aplicada no fundo da seção. Se for definida, um gradiente será aplicado baseado na cor 1."
          />
        </FormLabel>
        <ColorInput
          value={warranty_bgColor_2}
          onChange={(color) =>
            color !== undefined && SetWarrantyBgColor2(color)
          }
        />
      </div>
    </div>
  );
};

export default Section2;

import React, { useEffect, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../../../components/DefaultButton";
import ImageInput from "../../../../../components/Inputs/ImageInput";
import { Get } from "../../../../../utils/request";

import { useParams } from "react-router";

const FormBody = props => {

    const {
        loading,
        OnConfirm
    } = props;

    const [image, SetImage] = useState();
    const [name, SetName] = useState("");
    const [testimonial, SetTestimonial] = useState("");
    const [profession, SetProfesion] = useState("");

    const { testimonial_id } = useParams();
    const GetData = async () => {
        let response = await Get(`site-rt/testimonial?id=${testimonial_id}`);
        if (response?.status === true) {
            SetImage(response?.testimonials.image);
            SetName(response?.testimonials.name);
            SetTestimonial(response?.testimonials.testimonial);
            SetProfesion(response?.testimonials.profession);
        }
    }

    useEffect(() => GetData(), []);

    return(
        <div className="form-course">
        <form>
            <FormLabel>Imagem do usuário <span style={{color: "red"}}>*</span></FormLabel>
            <ImageInput
                ImageChange={image => SetImage(image)}
                path={image}
            />
            <br/>
            
            <FormLabel>Nome <span style={{color: "red"}}>*</span></FormLabel>
            <Input
                placeholder="Nome"
                value={name}
                onChange={e => SetName(e.target.value)}
                required
            />
            <br/>
            <FormLabel>Profissão <span style={{color: "red"}}>*</span></FormLabel>
            <Input
                placeholder="Profissão"
                value={profession}
                onChange={e => SetProfesion(e.target.value)}
                required
            />
            <br/>

            <FormLabel>Depoimento <span style={{color: "red"}}>*</span></FormLabel>
            <Input
                placeholder="Depoimento"
                value={testimonial}
                onChange={e => SetTestimonial(e.target.value)}
                as="textarea"
                required
            />
           
        </form>
        <br/>
        <div className="w100 inline-flex jcsb mt-2">
            <DefaultButton
                bg="secondary"
                text="Voltar"
                icon={<RiArrowGoBackLine />}
                to="/site-rt/depoimentos"
            />
            <DefaultButton
                bg="confirm"
                text="Salvar alterações"
                onClick={() => {
                    let data = {
                        image: image,
                        id: testimonial_id,
                        name: name,
                        testimonial: testimonial,
                        profession: profession
                        
                    };
                    OnConfirm(data);
                }}
                loading={loading}
            />
        </div>
    </div>
    );
}

export default FormBody;
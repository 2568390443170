import CheckoutCustomize from "./Checkout";
import ColorPresets from "./ColorPresets";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Temp_section5 from "./temp_section5";

const JoditConfig = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarInlineForSelection: true,
  showPlaceholder: false,
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "font",
    "fontsize",
    "brush",
  ],
  height: 100,
  minHeight: 100,
  readonly: false,
  toolbarAdaptive: false,
  defaultLanguage: "pt_br",
  language: "pt_br",
};

const text_container = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  position: "relative",
};

const text_style = {
  fontSize: "1.5rem",
  fontWeight: "bold",
  position: "absolute",
  margin: 0,
  padding: "0 1rem",
  backgroundColor: "white",
  zIndex: 10,
};

const text_comment = {
  fontSize: "1rem",
  textAlign: "center",
};

function RenderSectionHeader({ title, comment }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "2rem",
      }}
    >
      <div style={text_container}>
        <h4 style={text_style}>{title}</h4>
        <hr style={{ width: "100%" }} />
      </div>
      <span style={text_comment}>{comment}</span>
    </div>
  );
}

const ConfigCourseSections = (props) => {
  return (
    <>
      {RenderSectionHeader({
        title: "Temas",
        comment: "Temas predefinidos",
      })}
      <ColorPresets {...props} />

      {RenderSectionHeader({
        title: "Seção 1",
        comment: "Principais informações do curso",
      })}
      <Section1 {...{ ...props, JoditConfig }} />

      {RenderSectionHeader({
        title: "Seção 2",
        comment: "Conteúdo do curso",
      })}
      <Section2 {...{ ...props, JoditConfig }} />

      {RenderSectionHeader({
        title: "Seção 3",
        comment: "Pessoas que podem se beneficiar com o curso",
      })}
      <Section4 {...{ ...props, JoditConfig }} />

      {RenderSectionHeader({
        title: "Seção 4",
        comment: "O que vai receber",
      })}
      <Temp_section5 {...{ ...props, JoditConfig }} />

      {RenderSectionHeader({
        title: "Seção 5",
        comment: "Informações sobre a compra do curso",
      })}
      <Section5 {...{ ...props, JoditConfig }} />

      {RenderSectionHeader({
        title: "Garantia",
        comment: "Dados da garantia do curso",
      })}
      <Section8 {...{ ...props, JoditConfig }} />

      {RenderSectionHeader({
        title: "Seção 6",
        comment: "Principais dúvidas frequentes com relação ao curso",
      })}
      <Section3 {...{ ...props, JoditConfig }} />

      {/* {RenderSectionHeader({
        title: "Checkout",
        comment: "Customizar pagina de pagamento"
      })}
      <CheckoutCustomize {...{ ...props }} /> */}

      {RenderSectionHeader({
        title: "Diversos",
        comment: "Informações adicionais do curso",
      })}
      <Section6 {...{ ...props, JoditConfig }} />
      <Section7 {...{ ...props, JoditConfig }} />
    </>
  );
};

export default ConfigCourseSections;

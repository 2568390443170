import { CircularProgress, Typography } from "@mui/material";
import { Pagination } from "@mui/material";
import { Show } from "actions/SnackbarActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Get } from "../../../../utils/request";
import Board from "./Board";

const Table = props => {
    const {
        search = ""
    } = props;

    const [testimonial, SetTestimonial] = useState([]);
    const [loading, SetLoading] = useState(false);

    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const dispatch = useDispatch();

    const GetData = async () => {
        SetLoading(true);
        let response = await Get(`site-rt/testimonial?search=${search}&page=${page}`);
         
        SetLoading(false);

        if (response?.status === true) {
            SetTestimonial(response?.testimonials);
            SetMaxPage(response?.pagination.last_page);
        } else if (!response) dispatch(Show({
            show: true,
            severity: "warning",
            message: "Falha ao carregar os depoimentos"
        }));
    }

    useEffect(() => {
        GetData();
    }, [search, page]);

    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Depoimento</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && testimonial?.map((value, index) => (
                            <Board
                                key={index}
                                id={value["id"]}
                                title={value["name"]}
                                description={value["testimonial"]}
                                OnDelete={GetData}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="w100 flex jcc">
                <CircularProgress hidden={!loading}/>
                <Typography
                    hidden={loading || testimonial?.length > 0}
                >
                    Nenhum depoimento encontrado
                </Typography>
            </div>
            <Pagination
                className={(maxPage == 1 ? "hide" : "")}
                style={{display: "flex", justifyContent: "flex-end"}} 
                count={maxPage} 
                value={page}
                onChange={(_, value) => SetPage(value)}
            />
        </div>
    );
}

export default Table;
import { useMutation, useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { Get, Post } from "utils/request";
import { useParams } from "react-router-dom";

const useCertificates = () => {

    const user = useSelector(store => store.AppReducer.user);

    const { user_id } = useParams();
    const { data, isLoading: certificateIsLoading } = useQuery(["certificates", user_id], async () => await Get(`user/certificate?user_id=${user_id}`));
    const {
        certificates
    } = data || {};

    const dispatch = useDispatch();

    const { mutate: regenerateCertificate, isLoading: regenerateCertificateIsLoading } = useMutation(async (certificateId) => await Post(`certificate/regenerate?certificate_id=${certificateId}`), {
        onSuccess: (data) => {
            if (data?.httpCode !== 200) throw new Error(data?.message);

            dispatch(Show({
                show: true,
                message: data?.message,
                severity: "success"
            }));
        },
        onError: (error) => {
            dispatch(Show({
                show: true,
                message: error.message,
                severity: "error"
            }));
        }
    });

    return {
        certificates: {
            certificates,
            isLoading: certificateIsLoading
        },
        regenerate: {
            mutate: regenerateCertificate,
            isLoading: regenerateCertificateIsLoading
        }
    };
}

export default useCertificates;
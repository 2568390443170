/**
 * 
 * Shows a cookie usage alert and controls if the user agreed or not
 * 
 */

import { Button, Snackbar, Typography } from "@mui/material";
import { useState } from "react";

import styles from "./index.module.css";

const CookieAlert = () => {

    const [open, SetOpen] = useState(localStorage.getItem("acceptCookie") !== "true");

    const HandleClick = () => {
        SetOpen(false);
        localStorage.setItem("acceptCookie", true);
    }

    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            className={styles.snack}
        >
            <div className={styles.container}>
                <Typography
                    variant="caption"
                >
                    Utilizamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos sites, personalizar oferta de cursos e serviços e recomendar conteúdos de seu interesse. Ao continuar navegando, você concorda com estas condições e com nossa Política de Privacidade.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    className={styles.button}
                    onClick={HandleClick}
                >
                    Ok
                </Button>
            </div>
        </Snackbar>
    );
}

export default CookieAlert;
import React, { useState } from "react";

import style from "./style.module.css";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
// import style from "./style.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useQuery } from "react-query";
import { useEffect } from "react";
import AdquireCoursePreview from "./Preview/AdquireCoursePreview";
import ConfigCourseSections from "./Sections";
import DefaultButton from "components/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import UndoIcon from "@mui/icons-material/Undo";
import PaymantPreview from "./Preview/Paymant";

const ConfigCourse = (props) => {
  const history = useHistory();
  const [popup, SetPopup] = useState(false);

  const { loading, OnConfirm, save_button_status } = props;

  // section 1
  const [name, SetName] = useState(
    '<p><strong style="font-size: 36px;">Nome do curso</strong></p>'
  );
  const [showNameSalesPage, SetShowNameSalesPage] = useState(false);
  const [subtitle, SetSubtitle] = useState(
    '<p><strong style="font-size: 18px;">Mais do que um curso: seu novo propósito. uma experiência de aprendizado.</strong></p>'
  );
  const [thumbnail_image, SetThumbnailImage] = useState("");
  const [videoTrailer, SetVideoTrailer] = useState("");
  const [coverImage, SetCoverImage] = useState("");
  const [image, SetImage] = useState("");
  const [section1_bgColor_1, SetSection1BgColor1] = useState("#ffffff");
  const [section1_bgColor_2, SetSection1BgColor2] = useState("#ffffff");
  const [section1_accentColor, SetSection1AccentColor] = useState("#8bc7fc");

  // section 2
  const [show_section2, SetShowSection2] = useState(false);
  const [showMural, setShowMural] = useState(true);

  const [whatWillLearn, SetWhatWillLearn] = useState([]);
  const [section2_title, SetSection2Title] = useState(
    '<p><strong style="font-size: 36px;">O que você vai aprender</strong></p>'
  );
  const [section2_bgColor_1, SetSection2BgColor1] = useState("#ffffff");
  const [section2_bgColor_2, SetSection2BgColor2] = useState("#ffffff");
  const [section2_cardColor, SetSection2CardColor] = useState("#f0f0f0");
  const [section2_cardTextColor, SetSection2CardTextColor] =
    useState("#000000");
  const [section2_cardTextSize, SetSection2CardTextSize] = useState("18");
  const [section2_cardBorderColor, SetSection2CardBorderColor] =
    useState("#000000");
  const [section2_cardBorderSize, SetSection2CardBorderSize] = useState("16");

  // section 3
  const [show_section3, SetShowSection3] = useState(false);
  const [questions, SetQuestions] = useState([]);
  const [section3_title, SetSection3Title] = useState(
    '<p><strong style="font-size: 36px;">Perguntas Frequentes</strong></p>'
  );
  const [section3_bgColor_1, SetSection3BgColor1] = useState("#ffffff");
  const [section3_bgColor_2, SetSection3BgColor2] = useState("#ffffff");

  // section 4
  const [show_section4, SetShowSection4] = useState(false);
  const [recommendedTo, SetRecommendedTo] = useState("");
  const [section4_title, SetSection4Title] = useState(
    '<p><strong style="font-size: 36px;">Para quem o curso é indicado</strong></p>'
  );
  const [section4_bgColor_1, SetSection4BgColor1] = useState("#ffffff");
  const [section4_bgColor_2, SetSection4BgColor2] = useState("#ffffff");

  // temp section 5
  const [description_image, SetDescriptionImage] = useState("");
  const [temp_show_section5, SetTempShowSection5] = useState(false);
  const [whatWillGet, SetWhatWillGet] = useState([]);
  const [temp_section5_title, SetTempSection5Title] = useState(
    '<p><strong style="font-size: 36px;">O que você vai receber</strong></p>'
  );
  const [temp_section5_bgColor_1, SetTempSection5BgColor1] =
    useState("#ffffff");
  const [temp_section5_bgColor_2, SetTempSection5BgColor2] =
    useState("#ffffff");
  const [temp_section5_cardColor, SetTempSection5CardColor] =
    useState("transparent");
  const [section5_cardBorderColor, SetSection5CardBorderColor] =
    useState("#000000");
  const [section5_cardBorderSize, SetSection5CardBorderSize] = useState("16");

  // section 5
  const [price, SetPrice] = useState("");
  const [installments_quantity, SetInstallmentsQuantity] = useState(1);
  const [installments_price, SetInstallmentsPrice] = useState("0.00");
  const [installments_details, SetInstallmentsDetails] = useState(
    "Acréscimo de 1,4% a.m. a partir da 4 parcela (3x sem juros)*"
  );
  const [customer_discount_price, SetCustomerDiscountPrice] = useState("0.00");
  const [section5_text1, SetSection5Text1] = useState("Tudo isso por somente");
  const [section5_text2, SetSection5Text2] = useState("Ou então à vista por");
  const [section5_bgColor_1, SetSection5BgColor1] = useState("#ffffff");
  const [section5_bgColor_2, SetSection5BgColor2] = useState("#ffffff");
  const [section5_accentColor, SetSection5AccentColor] = useState("#8bc7fc");
  const [section5_textColor, SetSection5TextColor] = useState("#000000");

  // section 7
  const [opcells, SetOpcells] = useState([]);

  // others
  const [description, SetDescription] = useState(
    '<p><strong style="font-size: 18px;">Descreva sobre o curso no geral. Essa descrição irá aparecer logo acima dos preços.</strong></p>'
  );
  const [responsibles, SetResponsibles] = useState([]);
  const [categories, SetCategories] = useState([]);
  const [tags, SetTags] = useState([]);
  const [availabilityDuration, SetAvailabilityDuration] = useState(30);
  const [isFree, SetIsFree] = useState(false);
  const [subStart, SetSubStart] = useState(Date.now());
  const [subEnd, SetSubEnd] = useState(Date.now());
  // const [courseContent, SetCourseContent] = useState("<p></p>");
  const [certificateBackground, setCertificateBackground] = useState("");
  const [responsibles_bgColor_1, SetResponsiblesBgColor1] = useState("#fff");
  const [responsibles_bgColor_2, SetResponsiblesBgColor2] = useState("#fff");
  const [responsibles_accentColor, SetResponsiblesAccentColor] =
    useState("#f1f1f1");
  const [responsibles_textColor, SetResponsiblesTextColor] = useState("#000");

  const [submit_button_bgColor, SetSubmitButtonBgColor] = useState("#ffc813");
  const [submit_button_textColor, SetSubmitButtonTextColor] = useState("#000");
  const [submit_button_text, SetSubmitButtonText] = useState(
    "Quero me inscrever!"
  );
  const [submit_wait_text, SetSubmitWaitText] = useState("Receber notificação");
  const [submit_wait_helptext, SetSubmitWaitHelptext] = useState(
    "Ainda não há vagas disponíveis"
  );

  const [course_thumbnail, SetCourseThumbnail] = useState("");

  // warranty
  const [show_warranty, SetShowWarranty] = useState(false);
  const [warranty_title, SetWarrantyTitle] = useState(
    '<p><strong style="font-size: 24px;">Compre agora e tenha 7 dias para decidir se o produto é para você!</strong></p>'
  );
  const [warranty_text, SetWarrantyText] = useState(
    '<p><span style="font-size: 18px;">O produto conta com uma garantia 100% de satisfação. Ou seja, você tem 7 dias para acessar o conteúdo do curso, e se ainda achar que o treinamento não é para você, basta pedir reembolso e então devolveremos integralmente o seu dinheiro, sem questionamentos! Nesse caso, os riscos ficarão por nossa conta.</span></p>'
  );
  const [warranty_bgColor_1, SetWarrantyBgColor1] = useState("#ffffff");
  const [warranty_bgColor_2, SetWarrantyBgColor2] = useState("#ffffff");

  // checkout page
  // const [checkout_bg_color, SetCheckoutBgColor] = useState("#ffffff");
  // const [checkout_font_color, SetCheckoutFontColor] = useState("#000000");
  // const [checkout_accent_color, SetCheckoutAccentColor] = useState("#8bc7fc");
  // const [checkout_card_color, SetCheckoutCardColor] = useState("#f0f0f0");

  const [hidePreview, setHidePreview] = useState(false);

  const backToCourseList = () => {
    history.push("/courses/list");
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setHidePreview(true);
      } else {
        setHidePreview(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={style.gridContainer}>
      <SweetAlert
        warning
        title={"Atenção"}
        onConfirm={() => {
          backToCourseList();
          SetPopup(false);
        }}
        onCancel={() => SetPopup(false)}
        show={popup}
        confirmBtnText="Voltar"
        cancelBtnText="Cancelar"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="primary"
        showCancel={true}
      >
        Tem certeza que deseja voltar?
        <b> As alterações não salvas serão perdidas.</b>
      </SweetAlert>
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "14.2rem",
          zIndex: 50,
          display: "flex",
          gap: "1rem",
        }}
      >
        <div
          className={style.hidePreviewButton}
          style={{
            background: "#c8cbcf",
          }}
          onClick={() => {
            SetPopup(true);
          }}
        >
          <UndoIcon />
          {!hidePreview && <span>Voltar</span>}
        </div>
        <div
          className={style.hidePreviewButton}
          style={{
            background: "#a6eda1",
          }}
          onClick={() => {
            let data = {
              // section 1
              thumbnail_image: thumbnail_image,
              show_mural:showMural,
              cover_image: coverImage,
              image: image,
              name: name,
              showNameSalesPage,
              subtitle: subtitle,
              videoTrailer: videoTrailer,
              section1_bgColor_1: section1_bgColor_1,
              section1_bgColor_2: section1_bgColor_2,
              section1_accentColor: section1_accentColor,

              // section 2
              show_section2: show_section2,
              whatWillLearn: whatWillLearn,
              section2_title: section2_title,
              section2_bgColor_1: section2_bgColor_1,
              section2_bgColor_2: section2_bgColor_2,
              section2_cardColor,
              section2_cardTextColor,
              section2_cardTextSize,
              section2_cardBorderColor,
              section2_cardBorderSize,

              // section 3
              show_section3: show_section3,
              questions: questions,
              section3_title: section3_title,
              section3_bgColor_1: section3_bgColor_1,
              section3_bgColor_2: section3_bgColor_2,

              // section 4
              show_section4: show_section4,
              recommendedTo: recommendedTo,
              section4_title: section4_title,
              section4_bgColor_1: section4_bgColor_1,
              section4_bgColor_2: section4_bgColor_2,

              // temp section 5
              description_image,
              temp_show_section5,
              whatWillGet,
              temp_section5_title,
              temp_section5_bgColor_1,
              temp_section5_bgColor_2,
              temp_section5_cardColor,
              section5_cardBorderColor,
              section5_cardBorderSize,

              // section 5
              price: price
                .replace("R$ ", "")
                .replaceAll(".", "")
                .replace(",", "."),
              installments_quantity: installments_quantity,
              installments_price: installments_price
                .replace("R$ ", "")
                .replaceAll(".", "")
                .replace(",", "."),
              installments_details: installments_details,

              customer_discount_price: customer_discount_price
                .replace("R$ ", "")
                .replaceAll(".", "")
                .replace(",", "."),
              section5_text1: section5_text1,
              section5_text2: section5_text2,
              section5_bgColor_1: section5_bgColor_1,
              section5_bgColor_2: section5_bgColor_2,
              section5_accentColor: section5_accentColor,
              section5_textColor: section5_textColor,

              // section 7
              opcells: opcells,

              // others
              description: description,
              responsibles: responsibles,
              categories: categories,
              tags: tags,
              availabilityDuration: availabilityDuration,
              free: isFree,
              subStart: new Date(subStart).toISOString().split("T")[0],
              subEnd: new Date(subEnd).toISOString().split("T")[0],
              // course_content: courseContent,
              certificateBackground,
              responsibles_bgColor_1: responsibles_bgColor_1,
              responsibles_bgColor_2: responsibles_bgColor_2,
              responsibles_accentColor: responsibles_accentColor,
              responsibles_textColor: responsibles_textColor,

              submit_button_bgColor,
              submit_button_textColor,
              submit_button_text,
              submit_wait_text,
              submit_wait_helptext,

              course_thumbnail,

              // warranty
              show_warranty: show_warranty,
              warranty_title: warranty_title,
              warranty_text: warranty_text,
              warranty_bgColor_1: warranty_bgColor_1,
              warranty_bgColor_2: warranty_bgColor_2,

              // checkout
              // checkout_bg_color,
              // checkout_font_color,
              // checkout_accent_color,
              // checkout_card_color,
            };
            OnConfirm(data);
          }}
        >
          {save_button_status.icon}{" "}
          {!hidePreview && <span>{save_button_status.status}</span>}
        </div>
        <div
          className={style.hidePreviewButton}
          style={{
            background: hidePreview ? "#accef2" : "#ffc3b5",
          }}
          onClick={() => setHidePreview(!hidePreview)}
        >
          {hidePreview ? <VisibilityIcon /> : <VisibilityOffIcon />}{" "}
          {!hidePreview && <span>Esconder preview</span>}
        </div>
      </div>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <div
          style={{
            maxWidth: hidePreview ? "100%" : `44%`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <h2 className="title">Criar curso</h2>
          </div>
          <ConfigCourseSections
            {...{
              // section 1
              showMural,
              setShowMural,
              name,
              SetName,
              showNameSalesPage,
              SetShowNameSalesPage,
              image,
              SetImage,
              thumbnail_image,
              SetThumbnailImage,
              coverImage,
              SetCoverImage,
              videoTrailer,
              SetVideoTrailer,
              subtitle,
              SetSubtitle,

              section1_bgColor_1,
              section1_bgColor_2,
              SetSection1BgColor1,
              SetSection1BgColor2,
              section1_accentColor,
              SetSection1AccentColor,

              // section 2
              show_section2,
              SetShowSection2,
              whatWillLearn,
              SetWhatWillLearn,
              section2_title,
              SetSection2Title,

              section2_bgColor_1,
              section2_bgColor_2,
              SetSection2BgColor1,
              SetSection2BgColor2,

              section2_cardColor,
              SetSection2CardColor,
              section2_cardTextColor,
              SetSection2CardTextColor,
              section2_cardTextSize,
              SetSection2CardTextSize,
              section2_cardBorderColor,
              SetSection2CardBorderColor,
              section2_cardBorderSize,
              SetSection2CardBorderSize,

              // section 3
              show_section3,
              SetShowSection3,
              questions,
              SetQuestions,
              section3_title,
              SetSection3Title,

              section3_bgColor_1,
              section3_bgColor_2,
              SetSection3BgColor1,
              SetSection3BgColor2,

              // section 4
              show_section4,
              SetShowSection4,
              recommendedTo,
              SetRecommendedTo,
              section4_title,
              SetSection4Title,

              section4_bgColor_1,
              section4_bgColor_2,
              SetSection4BgColor1,
              SetSection4BgColor2,

              temp_show_section5,
              SetTempShowSection5,
              whatWillGet,
              SetWhatWillGet,
              temp_section5_title,
              SetTempSection5Title,
              temp_section5_bgColor_1,
              SetTempSection5BgColor1,
              temp_section5_bgColor_2,
              SetTempSection5BgColor2,
              temp_section5_cardColor,
              SetTempSection5CardColor,
              section5_cardBorderColor,
              SetSection5CardBorderColor,
              section5_cardBorderSize,
              SetSection5CardBorderSize,

              // section 5
              description_image,
              SetDescriptionImage,
              price,
              SetPrice,
              installments_quantity,
              SetInstallmentsQuantity,
              installments_price,
              SetInstallmentsPrice,
              installments_details,
              SetInstallmentsDetails,
              customer_discount_price,
              SetCustomerDiscountPrice,
              section5_text1,
              SetSection5Text1,
              section5_text2,
              SetSection5Text2,

              section5_bgColor_1,
              section5_bgColor_2,
              SetSection5BgColor1,
              SetSection5BgColor2,
              section5_accentColor,
              SetSection5AccentColor,
              section5_textColor,
              SetSection5TextColor,

              // section 7
              opcells,
              SetOpcells,

              // others
              description,
              SetDescription,
              responsibles,
              SetResponsibles,
              categories,
              SetCategories,
              tags,
              SetTags,
              availabilityDuration,
              SetAvailabilityDuration,
              isFree,
              SetIsFree,
              subStart,
              subEnd,
              SetSubEnd,
              // courseContent,
              // SetCourseContent,
              certificateBackground,
              setCertificateBackground,
              responsibles_bgColor_1,
              SetResponsiblesBgColor1,
              responsibles_bgColor_2,
              SetResponsiblesBgColor2,
              responsibles_accentColor,
              SetResponsiblesAccentColor,
              responsibles_textColor,
              SetResponsiblesTextColor,

              submit_button_bgColor,
              SetSubmitButtonBgColor,
              submit_button_textColor,
              SetSubmitButtonTextColor,
              submit_button_text,
              SetSubmitButtonText,
              submit_wait_text,
              SetSubmitWaitText,
              submit_wait_helptext,
              SetSubmitWaitHelptext,

              course_thumbnail,
              SetCourseThumbnail,

              // warranty
              show_warranty,
              SetShowWarranty,
              warranty_title,
              SetWarrantyTitle,
              warranty_text,
              SetWarrantyText,

              warranty_bgColor_1,
              warranty_bgColor_2,
              SetWarrantyBgColor1,
              SetWarrantyBgColor2,

              // checkout
              // checkout_bg_color,
              // SetCheckoutBgColor,
              // checkout_font_color,
              // SetCheckoutFontColor,
              // checkout_accent_color,
              // SetCheckoutAccentColor,
              // checkout_card_color,
              // SetCheckoutCardColor,
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: hidePreview ? "0" : `42%`,
          display: "flex",
          height: "100vh",
          transition: "width 300ms",
          position: "fixed",
          right: "0",
          background: "#fff",
          borderLeft: "0.2rem dashed black",
        }}
      >
        <AdquireCoursePreview
          {...{
            // section 1
            coverImage,
            image,
            name,
            subtitle,
            videoTrailer,
            section1_bgColor_1,
            section1_bgColor_2,
            section1_accentColor,

            // section 2
            show_section2,
            whatWillLearn,
            section2_title,
            section2_bgColor_1,
            section2_bgColor_2,
            section2_cardColor,
            SetSection2CardColor,
            section2_cardTextColor,
            SetSection2CardTextColor,
            section2_cardTextSize,
            SetSection2CardTextSize,
            section2_cardBorderColor,
            section2_cardBorderSize,

            // section 3
            show_section3,
            questions: questions,
            section3_title,
            section3_bgColor_1,
            section3_bgColor_2,

            // section 4
            show_section4,
            recommendedTo: recommendedTo,
            section4_title,
            section4_bgColor_1,
            section4_bgColor_2,

            temp_show_section5,
            SetTempShowSection5,
            whatWillGet,
            SetWhatWillGet,
            temp_section5_title,
            SetTempSection5Title,
            temp_section5_bgColor_1,
            SetTempSection5BgColor1,
            temp_section5_bgColor_2,
            SetTempSection5BgColor2,
            temp_section5_cardColor,
            SetTempSection5CardColor,

            // section 5
            description_image,
            price,
            installments_quantity,
            installments_price,
            installments_details,
            customer_discount_price,
            section5_text1,
            section5_text2,
            section5_bgColor_1,
            section5_bgColor_2,
            section5_accentColor,
            section5_textColor,
            section5_cardBorderColor,
            SetSection5CardBorderColor,
            section5_cardBorderSize,
            SetSection5CardBorderSize,

            // section 7
            opcells,

            // others
            description: description,
            responsibles: responsibles,
            categories: categories,
            tags: tags,
            availabilityDuration: availabilityDuration,
            isFree,
            subStart: new Date(subStart).toISOString().split("T")[0],
            subEnd: new Date(subEnd).toISOString().split("T")[0],
            // courseContent: courseContent,responsibles_textColor

            submit_button_bgColor,
            submit_button_textColor,
            submit_button_text,
            submit_wait_text,
            submit_wait_helptext,

            course_thumbnail,

            // warranty
            show_warranty,
            warranty_title,
            warranty_text,
            warranty_bgColor_1,
            warranty_bgColor_2,
          }}
        />
        {/* <PaymantPreview {...{
          checkout_bg_color,
          checkout_font_color,
          checkout_accent_color,
          checkout_card_color,
        }} /> */}
      </div>
    </div>
  );
};

export default ConfigCourse;

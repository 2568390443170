import { Box, Card, CardContent } from "@mui/material";
import { FormControl as Input } from "react-bootstrap";
import { useState } from "react";

import Table from "./Table";
import FilterButton from "./FilterButton";

const Logs = () => {
  const [search, SetSearch] = useState("");

  return (
    <Box component={Card}>
      <CardContent>
        <h2 className="title">Lista de logs</h2>
        <p className="text-muted">Aqui são listados todos os logs do sistema</p>
        <hr />
        <Box display={"inline-flex"} width={"100%"}>
          <Input
            variant="filled"
            placeholder="Pesquisar log"
            value={search}
            onChange={(e) => SetSearch(e.target.value)}
            style={{ width: "50%", marginRight: 10 }}
          />
          <FilterButton />
        </Box>
        <Table search={search} />
      </CardContent>
    </Box>
  );
};

export default Logs;

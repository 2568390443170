import React, { useState } from "react";

import { MdModeEdit, MdDelete } from "react-icons/md";
import { BsLink45Deg } from "react-icons/bs";
import DefaultButton from "components/DefaultButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Post } from "utils/request";
import { Show } from "actions/SnackbarActions";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";
import { STORAGE_URL } from "variables";

const Board = (props) => {
  const { id, title, image, description, link, OnDelete } = props;

  const [popup, SetPopup] = useState(false);

  const [loading, SetLoading] = useState(false);

  const dispatch = useDispatch();

  console.log(link)

  const Delete = async () => {
    let form = new FormData();
    form.append("id", id);

    SetLoading(true);
    let response = await Post("science-plus/references/delete", form);
    SetLoading(false);

    if (response?.status) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
        })
      );
      OnDelete();
    }
  };

  return (
    <tr>
      <td>
        <img
          src={STORAGE_URL+image}
          alt="imagem da referência"
          style={{
            display: "block",
            objectFit: "contain",
            width: "8rem",
            height: "3rem",
            margin: "0 auto",
          }}
        />
      </td>
      <td>{title}</td>
      <td className="table-text-dots">{description || "--"}</td>
      <td>
        <SweetAlert
          warning
          title={"Atenção"}
          onConfirm={() => {
            Delete();
            SetPopup(false);
          }}
          onCancel={() => SetPopup(false)}
          show={popup}
          confirmBtnText="Deletar"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="danger"
          showCancel={true}
        >
          Tem certeza que deseja deletar essa referência?
        </SweetAlert>
        <DefaultButton
          onClick={()=> window.open(link, "_blank")}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Abrir link`}
          bg="success"
          icon={<BsLink45Deg size={17} color="white" />}
        />
        <RoleBased>
          <DefaultButton
            to={`/science-plus/references/${id}/edit`}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Editar referência`}
            bg="warning"
            icon={<MdModeEdit color="white" size={17} />}
            style={{
              margin: "0 2px",
            }}
          />
        </RoleBased>
        <RoleBased>
          <DefaultButton
            onClick={() => SetPopup(true)}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Deletar referência`}
            bg="danger"
            loading={loading}
            icon={!loading && <MdDelete size={17} color="white" />}
          />
        </RoleBased>
      </td>
    </tr>
  );
};

export default Board;

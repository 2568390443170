import { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import Video from "components/Video"; 
import styles from "./index.module.css";
import { Get } from "utils/request";
import { STORAGE_URL } from 'variables';
import { useHistory } from 'react-router';
import ReactPlayer from 'react-player';
import AsideBanner from './AsideBanner';

const Welcome = () => {

    return (
        <Box
            padding={"2em 0"}
            bgcolor="white"
        >
            <AsideBanner/>
            <div className={styles.contentContainer}>
                <div>
                    <Typography
                        variant="h3"
                        align="center"
                        className={styles.welcomeText}
                    >
                        Título
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                        className={styles.welcomeText}
                    >
                        Descrição
                    </Typography>
                    
                    <Typography
                        variant="h6"
                        align="center"
                        className={styles.welcomeText}
                    >
                        Sub descrição
                    </Typography>
                </div>
                <div className={styles.videoContainer}>
                    <Video
                        src={null}
                        containerProps={{
                            style: {
                                width: "min(640px, 80vw)"
                            }
                        }}
                    />
                </div>
            </div>
        </Box>
    );
}

export default Welcome;
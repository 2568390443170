import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import style from "./style.module.css";
import FormPaginaInicial from "./Form";
import PreviewLandingPage from "../Preview/LandingPage";

// mui icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";
import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import { Get } from "utils/request";
import { useSelector } from "react-redux";
import logo_loading from "assets/images/rt/icon_transparent.png";

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // backgroundColor: "rgba(255, 255, 255, 0.3)",
  borderRadius: "0.4rem",
  maxWidth: "100%",
  minWidth: "36rem",
  // height: "10rem",
  display: "flex",
  flexDirection: "column",
};

const ConfigPaginaInicial = () => {
  const page_container = useRef(null);

  const [is_loading, set_is_loading] = useState(true);

  const [loading_modal, setLoading_modal] = React.useState(false);
  const handleOpenLoadingModal = () => setLoading_modal(true);
  const handleCloseLoadingModal = () => setLoading_modal(false);

  const [hidePreview, setHidePreview] = useState(false);
  const [save_button_status, setSaveButtonStatus] = useState({
    status: "Salvar tudo",
    icon: <SaveIcon />,
  });

  // header colors
  const [headerColor1, setHeaderColor1] = useState("#3884be");
  const [headerColor2, setHeaderColor2] = useState("#7a5db4");
  const [headerFontColor, setHeaderFontColor] = useState("#ffffff");
  const [headerLogo, setHeaderLogo] = useState("");

  const headerTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.header
  );

  useEffect(() => {
    if (headerTheme) {
      setHeaderColor1(
        headerTheme?.palette?.data.background_color_1 || "#3884be"
      );
      setHeaderColor2(
        headerTheme?.palette?.data.background_color_2 || "#7a5db4"
      );
      setHeaderFontColor(headerTheme?.palette?.data.font_color || "#ffffff");
      setHeaderLogo(headerTheme?.palette?.data.header_logo || "");
    }
  }, [headerTheme]);

  // START - banner
  const [banner, setBanner] = useState([]);
  const [banner_transition_time, setBanner_transition_time] = useState(5000);

  const addBanner = (data) => {
    setBanner([
      ...banner,
      {
        image_url: data.image_url,
        file: data.file,
      },
    ]);
  };

  const removeBanner = (index) => {
    let temp = [...banner];
    temp.splice(index, 1);
    setBanner(temp);
  };

  const handleBannerChange = (index, data) => {
    let temp = banner;
    temp[index].image_url = data.image_url;
    temp[index].file = data.file;
    setBanner([...temp]);
  };

  const { data: banners_data, refetch: banners_data_refetch } = useQuery(
    "landingBanners",
    async () => await Get(`site-rt/bannerPrincipal-images`),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (banners_data?.banners) {
      let temp = [];
      banners_data?.banners.forEach((banner) => {
        temp.push({
          id: banner.id,
          image_url: banner.image,
          file: null,
          link: banner.link,
        });
      });
      setBanner(temp);
    }
  }, [banners_data]);

  // END - banner

  // START - welcome
  const [welcomeTitle, setWelcomeTitle] = useState("");
  const [welcomeDescription, setWelcomeDescription] = useState("");
  const [welcomeSubtitle, setWelcomeSubtitle] = useState("");
  const [welcomeVideoLink, setWelcomeVideoLink] = useState("");
  const [welcomeVideoFile, setWelcomeVideoFile] = useState(null);
  // END - welcome

  // START - free lessons
  const [free_lessons, setFree_lessons] = useState([]);
  const [free_lessons_title, setFree_lessons_title] = useState(
    "Confira nossas aulas gratuitas! ✨"
  );
  const [free_lessons_two_views, setFreeLessonsTwoViews] = useState(false);
  const [free_lessons_transition_time, setFreeLessonsTransitionTime] =
    useState(5000);

  const addFreeLesson = (data) => {
    setFree_lessons([
      ...free_lessons,
      {
        name: data.name,
        description: data.description,
        link: data.link,
      },
    ]);
  };

  const removeFreeLesson = (index) => {
    let temp = [...free_lessons];
    temp.splice(index, 1);
    setFree_lessons(temp);
  };

  const handleFreeLessonChange = (index, data) => {
    let temp = free_lessons;
    temp[index].name = data.name;
    temp[index].description = data.description;
    temp[index].link = data.link;
    setFree_lessons([...temp]);
  };
  // END - free lessons

  // START - testimonials
  const [testimonials, setTestimonials] = useState([]);
  const [testimonial_section_title, setTestimonial_section_title] =
    useState("Depoimentos");

  const addTestimonial = (data) => {
    setTestimonials([
      ...testimonials,
      {
        image_url: data.image_url,
        image_file: data.image_file,
        name: data.name,
        profession: data.profession,
        testimonial: data.testimonial,
      },
    ]);
  };

  const removeTestimonial = (index) => {
    let temp = [...testimonials];
    temp.splice(index, 1);
    setTestimonials(temp);
  };

  const handleTestimonialChange = (index, data) => {
    let temp = testimonials;
    temp[index].image_url = data.image_url;
    temp[index].image_file = data.image_file;
    temp[index].name = data.name;
    temp[index].profession = data.profession;
    temp[index].testimonial = data.testimonial;

    setTestimonials([...temp]);
  };
  // END - free lessons

  // app
  const [appInfoTitle, setAppInfoTitle] = useState(
    "Descubra o app da RT Online"
  );
  const [appInfoDescription, setAppInfoDescription] = useState(
    "O melhor jeito de aprender, com os melhores nomes. Experimente os cursos da RT Online no APP."
  );
  const [appInfoImage, setAppInfoImage] = useState("");
  const [appInfoColor, setAppInfoColor] = useState("#3884be");

  // bottom lead
  const [bottomLeadTitle, setBottomLeadTitle] = useState(
    "Ainda não é aluno da RT?"
  );
  const [bottomLeadSubtitle, setBottomLeadSubtitle] = useState(
    "Receba diretamente no seu email e WhatsApp conteúdos da RT sobre assuntos do momento, aulas e cursos."
  );
  const [bottomLeadText1, setBottomLeadText1] = useState(
    "Não enviamos nenhum tipo de spam.*"
  );
  const [bottomLeadText2, setBottomLeadText2] = useState(
    "*Entraremos em contato com você pelo Whatsapp para facilitar nossa comunicação."
  );

  // aside
  const [aside_transition_time, setAsideTransitionTime] = useState(5000);

  // load all the data
  const { data } = useQuery("landingDescription", async () => {
    let response = await Get("site-rt/description?type=firstContent");
    set_is_loading(false);

    return response;
  });

  // set the data
  useEffect(() => {
    if (data) {
      console.log(data);
      try {
        // banner
        setBanner_transition_time(data.page.banner_transition_time || 5000);
        console.log(data.page);

        // welcome
        setWelcomeTitle(data.page.title || welcomeTitle);
        setWelcomeDescription(data.page.description || welcomeDescription);
        setWelcomeSubtitle(data.page.sub || welcomeSubtitle);

        // free lessons
        setFree_lessons_title(
          data.page.free_courses_title || free_lessons_title
        );
        setFreeLessonsTwoViews(data.page.free_lessons_two_views || false);
        setFreeLessonsTransitionTime(
          data.page.free_lessons_transition_time || 5000
        );

        // testimonials
        setTestimonial_section_title(
          data.page.testimonial_section_title || testimonial_section_title
        );

        // app info
        setAppInfoTitle(data.page.app_ad_title || appInfoTitle);
        setAppInfoDescription(
          data.page.app_ad_description || appInfoDescription
        );
        setAppInfoImage(data.page.app_ad_image || "");
        setAppInfoColor(data.page.app_ad_description_color || "#3884be");

        // bottom lead
        setBottomLeadTitle(data.page.bottom_lead_title || bottomLeadTitle);
        setBottomLeadSubtitle(
          data.page.bottom_lead_subtitle || bottomLeadSubtitle
        );
        setBottomLeadText1(data.page.bottom_lead_text1 || bottomLeadText1);
        setBottomLeadText2(data.page.bottom_lead_text2 || bottomLeadText2);

        // aside
        setAsideTransitionTime(data.page.aside_transition_time || 5000);
      } catch {}
    }
  }, [data]);

  return is_loading ? (
    <div style={{ width: "100%", aspectRatio: "16/9", display: "flex" }}>
      <img
        src={logo_loading}
        alt="Logotipo RT"
        className={"infiniteRotateAnim"}
        style={{
          width: "12rem",
          height: "12rem",
          margin: "auto",
        }}
      />
    </div>
  ) : (
    <div ref={page_container} className={style.gridContainer}>
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "14.2rem",
          zIndex: 50,
          display: "flex",
          gap: "1rem",
        }}
      >
        <Modal
          open={loading_modal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modal_style}>
            <div
              style={{
                width: "100%",
                aspectRatio: "16/9",
                display: "flex",
                height: "max-content",
                padding: "4rem 0",
              }}
            >
              <img
                src={logo_loading}
                alt="Logotipo RT"
                className={"infiniteRotateAnim"}
                style={{
                  width: "8rem",
                  height: "8rem",
                  margin: "auto",
                }}
              />
            </div>
            {/* <div
              className="row"
              style={{
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {" "}
              <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
                <h4>Salvando...</h4>
              </div>
            </div> */}
          </Box>
        </Modal>
        <div
          className={style.hidePreviewButton}
          style={{
            background: hidePreview ? "#accef2" : "#ffc3b5",
          }}
          onClick={() => setHidePreview(!hidePreview)}
        >
          {hidePreview ? <VisibilityIcon /> : <VisibilityOffIcon />}{" "}
          <span>{hidePreview ? "Mostrar" : "Esconder"} preview</span>
        </div>
      </div>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <div
          style={{
            maxWidth: hidePreview ? "100%" : `44%`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <h2 className="title">Configurações da landing page</h2>
          </div>
          <FormPaginaInicial
            {...{
              set_is_loading,

              // welcome
              welcomeTitle,
              setWelcomeTitle,
              welcomeDescription,
              setWelcomeDescription,
              welcomeSubtitle,
              setWelcomeSubtitle,
              welcomeVideoLink,
              setWelcomeVideoLink,
              welcomeVideoFile,
              setWelcomeVideoFile,

              // free lessons
              free_lessons,
              addFreeLesson,
              removeFreeLesson,
              handleFreeLessonChange,
              free_lessons_title,
              setFree_lessons_title,
              free_lessons_two_views,
              setFreeLessonsTwoViews,
              free_lessons_transition_time,
              setFreeLessonsTransitionTime,

              // testimonials
              testimonial_section_title,
              setTestimonial_section_title,

              // app info
              appInfoTitle,
              setAppInfoTitle,
              appInfoDescription,
              setAppInfoDescription,
              appInfoImage,
              setAppInfoImage,
              appInfoColor,
              setAppInfoColor,

              // bottom lead
              bottomLeadTitle,
              setBottomLeadTitle,
              bottomLeadSubtitle,
              setBottomLeadSubtitle,
              bottomLeadText1,
              setBottomLeadText1,
              bottomLeadText2,
              setBottomLeadText2,

              // aside
              aside_transition_time,
              setAsideTransitionTime,

              // others
              headerColor1,
              setHeaderColor1,
              headerColor2,
              setHeaderColor2,
              headerFontColor,
              setHeaderFontColor,
              headerLogo,
              setHeaderLogo,
            }}
            setSaveButton={setSaveButtonStatus}
            section_1={{
              data: banner,
              setData: setBanner,
              addBanner: addBanner,
              removeBanner: removeBanner,
              handleBanner: handleBannerChange,
              banner_transition_time,
              setBanner_transition_time,
              banners_data_refetch,
            }}
            section_4={{
              data: testimonials,
              setData: setTestimonials,
              addTestimonial: addTestimonial,
              removeTestimonial: removeTestimonial,
              handleTestimonial: handleTestimonialChange,
            }}
            handleOpenLoadingModal={handleOpenLoadingModal}
            handleCloseLoadingModal={handleCloseLoadingModal}
          />
        </div>
      </div>
      <div
        style={{
          width: hidePreview ? "0" : `43%`,
          display: "flex",
          height: "100vh",
          transition: "width 300ms",
          position: "fixed",
          right: "0",
          background: "#fff",
          borderLeft: "0.2rem dashed black",
        }}
      >
        <PreviewLandingPage
          {...{
            // welcome
            welcomeTitle,
            setWelcomeTitle,
            welcomeDescription,
            setWelcomeDescription,
            welcomeSubtitle,
            setWelcomeSubtitle,
            welcomeVideoLink,
            setWelcomeVideoLink,
            welcomeVideoFile,
            setWelcomeVideoFile,

            // free lessons
            free_lessons,
            setFree_lessons,
            addFreeLesson,
            removeFreeLesson,
            handleFreeLessonChange,
            free_lessons_title,
            setFree_lessons_title,
            free_lessons_two_views,
            setFreeLessonsTwoViews,
            free_lessons_transition_time,
            setFreeLessonsTransitionTime,

            // testimonials
            testimonial_section_title,
            setTestimonial_section_title,

            // app info
            appInfoTitle,
            setAppInfoTitle,
            appInfoDescription,
            setAppInfoDescription,
            appInfoImage,
            setAppInfoImage,
            appInfoColor,
            setAppInfoColor,

            // bottom lead
            bottomLeadTitle,
            bottomLeadSubtitle,
            bottomLeadText1,
            bottomLeadText2,

            // aside
            aside_transition_time,
            setAsideTransitionTime,

            headerColor1,
            setHeaderColor1,
            headerColor2,
            setHeaderColor2,
            headerFontColor,
            setHeaderFontColor,
            headerLogo,
            setHeaderLogo,
          }}
          section_1={{
            banner: banner,
            setBanner: setBanner,
            banner_transition_time,
            setBanner_transition_time,
          }}
          section_4={{
            testimonials: testimonials,
            setTestimonials: setTestimonials,
          }}
        />
      </div>
    </div>
  );
};

export default ConfigPaginaInicial;

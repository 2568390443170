import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { Pagination, Skeleton } from "@mui/material";
import { Header } from "modules/Header";
import { useMainPages } from "modules/Header/data";
import { useState } from "react";
import { useQuery } from "react-query";
import { Get } from "utils/request";
import { STORAGE_URL } from "variables";

import SkeletonList from "modules/LoadingPlaceholders/Skeletons/SkeletonList";
import Board from "./Board";
import Filter from "./Filter";
import { motion } from "framer-motion";
import TopBrandsCarousel from "./TopBrandsCarousel";
import Paginate from "components/Paginate";
import Image from "components/Image";
import emptyState from "assets/images/emptystate1.svg";
import Footer from "Home/Footer";
import WhatsappButton from "components/WhatsappButton";

const SciencePlusBrowse = () => {
  const [month, SetMonth] = useState("");
  const [year, SetYear] = useState("");
  const [page, SetPage] = useState(1);

  const pages = useMainPages();
  const filterMatches = useMediaQuery("(min-width: 900px)");

  const { isLoading, data, error, refetch } = useQuery(
    [`scienceplus`, { month, year, page }],
    async () =>
      await Get(`science-plus?month=${month}&year=${year}&page=${page}`)
  );
  const { posts, pagination, dates } = data || {};

  if (isLoading)
    return (
      <>
        <Header pages={pages} />
        <br />
        <Box display="flex" width="100%">
          <SkeletonList count={6} />
          <Skeleton
            width={300}
            height={300}
            style={{
              transform: "scale(1, 0.89)",
              marginLeft: "1em",
            }}
          />
        </Box>
      </>
    );
  return (
    <>
      <Container maxWidth='xl' flex={1} sx={{marginBottom: '2rem'}}>
        <Header pages={pages} />
        <br />
        <TopBrandsCarousel />
        <Box display="flex" flexDirection="row" width="100%">
          {posts?.length != 0 ? (
            <Box flex={3} sx={{display: 'flex', flexDirection: 'column-reverse'}}>
              {posts?.map((post, postKey) => (
                <motion.div
                  key={post?.id}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      x: {
                        type: "spring",
                        delay: postKey * 0.1,
                        damping: 30,
                        stiffness: 500,
                      },
                      opacity: { delay: postKey * 0.1 },
                    },
                  }}
                >
                  <Board
                    id={post?.id}
                    image={post?.image ? STORAGE_URL + post?.image : ""}
                    title={post?.title}
                    description={post?.description}
                    createdAt={post?.created_at}
                    createdBy={post?.created_by?.name}
                  />
                </motion.div>
              ))}
            </Box>
          ) : (
            <Box
              flex={3}
              alignItems={"center"}
              justifyContent={"center"}
              display={"flex"}
              flexDirection={"column"}
              marginBottom={1}
            >
              <Image
                src={emptyState}
                width={300}
                height={300}
                style={{
                  backgroundSize: "cover",
                }}
              />
              <Typography>Ops! nenhuma postagem foi encontrada</Typography>
            </Box>
          )}
          {filterMatches && posts?.length != 0 && (
            <Filter
              dates={Object.values(dates)}
              onClick={(month, year) => {
                SetMonth(month);
                SetYear(year);
              }}
            />
          )}
        </Box>
        <Paginate
          value={page}
          maxPage={pagination?.lastPage}
          onChange={SetPage}
        />
        <br />
      </Container>
      <WhatsappButton />
      <Footer />
    </>
  );
};

export default SciencePlusBrowse;

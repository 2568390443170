import { Box, Card, CardContent, createTheme, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import React, { useMemo } from "react";

const Board = (props) => {
  const { icon, data, title, colors = red,maxWidth } = props;

  const innerTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: colors["500"],
          },
        },
      }),
    [colors]
  );

  return (
    <Box
      component={Card}
      maxWidth={maxWidth?maxWidth:"30%"}
      position={"relative"}
      bgcolor={innerTheme.palette?.primary?.main}
      color={innerTheme.palette?.primary?.contrastText}
      style={{
        color: innerTheme.palette?.primary?.contrastText,
        backgroundColor: innerTheme.palette?.primary?.main,
        borderRadius: '0.6rem',
      }}
    >
      <Box component={CardContent}>
        <Box
          width={"fit-content"}
          padding={1}
          borderRadius={5}
          marginBottom={1.5}
          bgcolor={innerTheme.palette?.primary?.dark}
        >
          {icon}
        </Box>
        <Box>
          <Typography variant={"h3"}>{data}</Typography>
          <Typography>{title}</Typography>
        </Box>
      </Box>
      <Box
        position="absolute"
        bgcolor={colors["700"]}
        width={210}
        height={210}
        right={-100}
        top={75}
        borderRadius={"100%"}
      />
      <Box
        position="absolute"
        bgcolor={innerTheme.palette?.primary?.dark}
        width={210}
        height={210}
        right={-80}
        top={-80}
        borderRadius={"100%"}
      />
    </Box>
  );
};

export default Board;

import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { removeHTML } from "utils/filters";
import { moneyMask } from "utils/moneyMask";
import { STORAGE_URL } from "variables";

const loadOpcells = (props) => {
  const { course, opcells, selectedOpcells, setSelectedOpcells } = props;

  return (
    <>
      {props.opcells[0] && opcells?.map((opcell, index) => (
        <div key={index} style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              borderRadius: "0.6rem 0.6rem 0 0",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <img
              src={STORAGE_URL + opcell?.image}
              alt={removeHTML(opcell?.name)}
              style={{
                width: "100%",
                maxWidth: "14rem",
                height: "8rem",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="p"
                style={{
                  fontWeight: "bold",
                  marginLeft: "1rem",
                  fontSize: "1.3rem",
                  marginTop: "0.5rem",

                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {removeHTML(opcell?.name)}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  marginLeft: "1rem",
                  height: "4rem",
                }}
              >
                {removeHTML(opcell?.description)}
              </Typography>
            </div>
          </div>

          <div
            style={{
              padding: "0.2rem 1rem",
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: "0 0 0.6rem 0.6rem",
              alignItems: "center",
            }}
          >
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedOpcells([...selectedOpcells, opcell]);
                        } else {
                          setSelectedOpcells(
                            selectedOpcells.filter(
                              (item) => item.id !== opcell.id
                            )
                          );
                        }
                      }}
                      checked={selectedOpcells.find(
                        (item) => item.id === opcell.id
                      )}
                      
                    />
                  }
                  label={
                    <Typography
                      variant="p"
                      style={{
                        fontSize: "1rem",
                        color: "#5c5c5c",
                      }}
                    >
                      Adicionar ao carrinho por apenas{" "}
                      <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                        {moneyMask(opcell?.price)}
                      </span>
                    </Typography>
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};


export default loadOpcells;
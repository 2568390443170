import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import module_style from "./index.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useHistory, useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import { Post } from "utils/request";
import rt_logo from "assets/images/rt/main_logo_bgtransparent.png";
import { Header } from "modules/Header";
import { useMainPages } from "modules/Header/data";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useParams } from "react-router-dom";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { Show } from "actions/SnackbarActions";
import ReactInputMask from "react-input-mask";

const Register = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { course_id } = useParams();

  const [mui_theme, setMuiTheme] = useState({});

  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [password, SetPassword] = useState("");

  const [remember, SetRemember] = useState(false);
  const [errorsList, SetErrors] = useState([]);
  const [link, SetLink] = useState(null);
  const [submitting, SetSubmitting] = useState(false);

  const [showPassword, SetShowPassword] = useState(false);

  const registerTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.register
  );

  const pages = useMainPages();

  // foca o input da div
  const focusInput = (e) => {
    e.currentTarget.querySelector("input").focus();
  };

  const phoneMaskBrazil = (e) => {
    var element = e.target;
    var isAllowed = /\d|Backspace|Tab/;
    if (!isAllowed.test(e.nativeEvent.data)) e.preventDefault();

    var inputValue = element.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/(^\d{2})(\d)/, "($1) $2");
    inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/, "$1-$2");
    
    SetPhone(inputValue);

    if(props.setPhone){
      props.setPhone(inputValue)
    }
  };

  useEffect(() => {
    setMuiTheme(registerTheme?.palette?.data);
  }, [registerTheme]);

  const Register = async () => {
    let form = new FormData();
    form.append("name", name);
    form.append("email", email);
    form.append("phone", phone);

    
    // form.append("password", password);

    SetSubmitting(true);
    let response = await Post("auth/register", form);

    // if (response["success"]) Login(email);
    if (response["success"]) {
      let token = response["access_token"];
      let user = response["user"];
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      dispatch({ type: "login", payload: { token: token, user: user } });
      SetSubmitting(false);

      dispatch(
        Show({
          show: true,
          severity: "success",
          message:
            "Conta criada com sucesso! Sua senha temporária foi enviado para o seu e-mail.",
        })
      );

      if(props.completed){
        props.AddOthers(user)
      }
      else{
        setTimeout(() => {
          history.push("/courses/payment/" + course_id);
        }, 1000);
      }
      
    } else if (response["errors"]) {
      let errors = Object.values(response["errors"]);
      SetErrors(errors);
      SetSubmitting(false);
      return;
    }
  };

  const Login = async () => {
    let form = new FormData();

    form.append("email", email);
    // form.append("password", password);

    SetSubmitting(true);
    let response = await Post("auth/login", form);

    if (response?.status) {
      let token = response["access_token"];
      let user = response["user"];

      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));

      SetSubmitting(false);

      dispatch({ type: "login", payload: { token: token, user: user } });
    } else if (response?.errors) {
      SetSubmitting(false);
      let errors = Object.values(response?.errors);
      SetErrors(errors);
      if (response?.link)
        SetLink(
          <Link to={response?.link?.href}>{response?.link?.message}</Link>
        );
      else SetLink(null);
      return;
    }
    history.push(
      location?.state?.redirectTo
        ? location?.state?.redirectTo
        : response?.user?.role == "Admin"
        ? "/dashboard"
        : "/"
    );
  };

  useEffect(() => {
    if (location?.state) {
      location?.state?.email && SetEmail(location?.state?.email);
      location?.state?.name && SetName(location?.state?.name);
    }
    if(props.user?.name){
      SetName(props.user?.name)
      SetEmail(props.user?.email)
      SetPhone(props.user?.phone.replace(/^\+55 /, ""))
    }
  }, []);

  return (
    <>
      <div
        className={module_style.LoginContainer}
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Box
          sx={{
            display: "flex",
            margin: "auto",
            borderRadius: "0.4rem",
            overflow: "hidden",
            // boxShadow: { xs: "none", md: "0 1rem 2rem rgba(0, 0, 0, 0.1)" },
            flexWrap: { xs: "wrap", md: "nowrap" },
            flexDirection: { xs: "column-reverse", md: "row-reverse" },
          }}
        >
          {/* <Box
            sx={{
              backgroundColor: mui_theme?.section_left_color,
              padding: "4rem",
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%!important", md: "34rem!important" },
              alignItems: "center",
              justifyContent: "space-between",
              backgroundImage: mui_theme?.background_image,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <img
              src={rt_logo}
              style={{ objectFit: "contain", width: "10rem" }}
            />
            <Typography
              variant="h6"
              sx={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{ __html: mui_theme?.text }}
            />
          </Box> */}

          <Box
            sx={{
              backgroundColor: mui_theme?.section_right_color ?? "white",
              // padding: "4rem",
              width: "100%",
              maxWidth: "34rem",
              padding: "2rem 4rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: mui_theme?.font_color }}
              dangerouslySetInnerHTML={{ __html: mui_theme?.title }}
            />
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: mui_theme?.font_color }}
              dangerouslySetInnerHTML={{ __html: mui_theme?.subtitle }}
            />

            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.4rem",
                marginTop: "2rem",
              }}
            >
              <div
                className={module_style.FormInputContainer}
                style={{
                  borderBottom: `0.1rem solid ${mui_theme?.input_color}`,
                  backgroundColor: mui_theme?.input_bg_color,
                }}
                onClick={(e) => {
                  focusInput(e);
                }}
              >
                <PersonIcon sx={{ color: mui_theme?.input_color }} />
                <input
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => {
                    SetName(e.target.value);
                    if(props.setUserName){
                      props.setUserName(e.target.value)
                    }
                  }}
                  style={{ color: mui_theme?.input_color }}
                  // error={msgErro}
                />
              </div>
              <div
                className={module_style.FormInputContainer}
                style={{
                  borderBottom: `0.1rem solid ${mui_theme?.input_color}`,
                  backgroundColor: mui_theme?.input_bg_color,
                }}
                onClick={(e) => {
                  focusInput(e);
                }}
              >
                <EmailIcon sx={{ color: mui_theme?.input_color }} />
                <input
                  type="text"
                  

                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => {
                    SetEmail(e.target.value);
                    if(props.SetEmail){
                      props.SetEmail(e.target.value)
                    }
                  }}
                  style={{ color: mui_theme?.input_color }}
                  // error={msgErro}
                />
              </div>

              <div
                className={module_style.FormInputContainer}
                style={{
                  borderBottom: `0.1rem solid ${mui_theme?.input_color}`,
                  backgroundColor: mui_theme?.input_bg_color,
                }}
                onClick={(e) => {
                  focusInput(e);
                }}
              >
                <LocalPhoneIcon sx={{ color: mui_theme?.input_color }} />
                <input
                  maxLength="15"
                  type="text"

                  placeholder="Telefone"
                  value={phone}
                  onChange={(e) => {
                    phoneMaskBrazil(e);
                  }}
                  style={{ color: mui_theme?.input_color }}
                  required
                />
                
              </div>

              {/* <div
                className={module_style.FormInputContainer}
                style={{
                  borderBottom: `0.1rem solid ${mui_theme?.input_color}`,
                  backgroundColor: mui_theme?.input_bg_color,
                }}
                onClick={(e) => {
                  focusInput(e);
                }}
              >
                <HttpsIcon sx={{ color: mui_theme?.input_color }} />
                <input
                  type={!showPassword ? "password" : "text"}
                  placeholder="Senha"
                  value={password}
                  onChange={(e) => {
                    SetPassword(e.target.value);
                  }}
                  sx={{ color: mui_theme?.input_color }}
                  // error={msgErro}
                />
                <IconButton
                  onClick={() => SetShowPassword(!showPassword)}
                  sx={{
                    padding: 0,
                    color: "#a1a1a1",
                  }}
                >
                  {!showPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon sx={{ color: "#ffac1c" }} />
                  )}
                </IconButton>
              </div> */}

              <Alert severity="error" hidden={errorsList.length == 0}>
                {errorsList[0]}
                {link}
              </Alert>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <div className="flex fdrow align-center text-muted">
                  <span style={{ color: mui_theme?.font_color }}>
                    Já tem uma conta?{" "}
                    <Link
                      className="text-primary"
                      to={{
                        pathname: "/login/" + course_id,
                        state: {
                          email: email,
                          ...location?.state,
                        },
                      }}
                    >
                      <span
                        style={{
                          color: mui_theme?.accent_color,
                        }}
                      >
                        Faça login
                      </span>
                    </Link>
                  </span>
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        value={remember}
                        onChange={(e) => SetRemember(e.target.checked)}
                        sx={{
                          color: mui_theme?.accent_color,
                          opacity: 0.8,
                          "&.Mui-checked": {
                            color: mui_theme?.accent_color,
                            opacity: 1,
                          },
                        }}
                      />
                    }
                    style={{ color: mui_theme?.font_color }}
                    label="Lembre-se de mim"
                  /> */}
                </div>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: mui_theme?.accent_color,
                    color: mui_theme?.font_color,
                    padding: "0.5rem 1.6rem",
                    "&:hover": {
                      backgroundColor: mui_theme?.accent_color,
                      filter: "brightness(0.9)",
                    },
                  }}
                  onClick={Register}
                >
                  {submitting ? (
                    <CircularProgress
                      size={20}
                      style={{
                        color: mui_theme?.font_color,
                        marginRight: "0.5rem",
                      }}
                    />
                  ) : (
                    <SentimentVerySatisfiedIcon
                      style={{ marginRight: "0.5rem" }}
                    />
                  )}
                  Cadastrar
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Register;

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import DefaultButton from 'components/DefaultButton';
import { IoMdEye } from 'react-icons/io';
import { Get } from 'utils/request';
import { STORAGE_URL } from 'variables';
import { moneyMask } from 'utils/moneyMask';
import './styles.css';
import { FaMoneyBillWave } from 'react-icons/fa';
import { AiFillCreditCard } from 'react-icons/ai';
import { CircularProgress, IconButton } from '@mui/material';
import { MdClose } from 'react-icons/md';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '.4rem',
  p: 4,
  // display: 'flex',
  // flexDirection: 'column'
};

export default function MoreInfo(props) {
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState('');
  const [courses, setCourses] = React.useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    const getData = async () => {
      const response = await Get(`orders/${props.id}`)
      setOrder(response.order); setCourses(response.order.courses)
      // response.order.items.forEach(item => {
      //   response.courses.forEach(item2 => { if (item2.id === item.code) return item2 })
      // })
      console.log('modal resp', response)
    }

    if (open) getData()
  }, [open])

  const statusStyle = {
    padding: '.2rem',
    cursor: 'pointer',
    fontWeight: 'bold',
    borderRadius: '.4rem',
    backgroundColor: '#8AFF8A',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
  }

  const handleStatus = (status) => {
    switch (status) {
      case 'pending':
        statusStyle.backgroundColor = '#FFFF66'
        return 'Pendente'
      case 'paid':
        statusStyle.backgroundColor = '#8AFF8A'
        return 'Pago'
      case 'failed':
        statusStyle.backgroundColor = '#FF8A8A'
        return 'Falha'
      default:
        return null
    }
  }

  const handleMethod = (method) => {
    switch (method) {
      case 'credit_card':
        return 'Crédito'
      default:
        return method
    }
  }

  return (
    <div>
      <DefaultButton
        onClick={handleOpen}
        width="2.2em"
        height="2.2em"
        padding={0}
        title={'Ver mais'}
        bg={'warning'}
        // loading={loading}
        icon={<IoMdEye size={17} color="white" />}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex">
              <div>
                <h3 className="title">Pagar com QRCode</h3>
                <p className="text-muted">Realizar o pagamento pelo pix</p>
              </div>
              <IconButton className='ms-auto' onClick={handleClose}><MdClose /></IconButton>
            </div>
            <hr />
          </Box>
        </Fade>
      </Modal>
    </div >
  );
}
import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Show } from "../../../actions/SnackbarActions";
import { Post } from "../../../utils/request";
import ConfigCourse from "./FormBody";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import { removeHTML } from "utils/filters";

const CourseEdit = () => {
  const [popup, SetPopup] = useState(false);
  const [popupMessage, SetPopupMessage] = useState("");

  const [loading, SetLoading] = useState(false);
  const [save_button_status, setSaveButtonStatus] = useState({
    status: "Salvar tudo",
    icon: <SaveIcon />,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const userId = useSelector((store) => store.AppReducer.user?.id);

  const { id } = useParams();

  const Submit = async (data) => {
    if (!data?.image) {
      SetPopup(true);
      SetPopupMessage("Insira uma imagem!");
      return;
    } else if (!data?.name) {
      SetPopup(true);
      SetPopupMessage("Insira um nome!");
      return;
    } else if (!data?.description) {
      SetPopup(true);
      SetPopupMessage("Insira uma descrição!");
      return;
    } else if (data?.responsibles.length == 0) {
      SetPopup(true);
      SetPopupMessage("Insira um responsável!");
      return;
    } else if (data?.categories.length == 0) {
      SetPopup(true);
      SetPopupMessage("Insira uma categoria!");
      return;
    } else if (
      data?.price === null ||
      data?.price === "" ||
      data?.price === undefined
    ) {
      data.price = 0;
    } else if (
      data?.installments_price === null ||
      data?.installments_price === "" ||
      data?.installments_price === undefined
    ) {
      data.installments_price = 0;
    }

    for (const k in data) {
      const key = k.toLocaleLowerCase();
      if (key.includes("title")) {
        if (removeHTML(data[k]) === "") {
          SetPopup(true);
          SetPopupMessage("Preencha todos os campos obrigatórios!");
          return;
        }
      }
    }

    let form = new FormData();
    for (const key in data) {
      if (data[key] === null) {
        form.append(key, "");
      } else {
        form.append(key, data[key]);
      }
    }

    form.append(
      "video_trailer",
      data?.videoTrailer[0] == undefined ? "" : data?.videoTrailer[0]
    );

    if (
      data?.certificateBackground &&
      data?.certificateBackground instanceof File
    )
      form.append("certificate_background", data?.certificateBackground);
    for (let i = 0; i < data.responsibles.length; i++)
      form.append("responsible_id[]", data.responsibles[i]?.id);
    for (let i = 0; i < data.categories.length; i++)
      form.append("category_id[]", data.categories[i]?.id);
    for (let i = 0; i < data.tags.length; i++)
      form.append("tag_id[]", data.tags[i]?.id);

    for (const item of data.whatWillLearn) {
      if (item.text !== "") {
        form.append("what_will_learn[]", JSON.stringify(item));
      }
    }

    let new_what_will_get = [];
    data.whatWillGet.filter((item) => {
      if (item.title !== "" && item.text !== "") {
        new_what_will_get.push(item);
      }
    });

    for (const item of new_what_will_get) {
      if (item.title !== "" && item.text !== "") {
        form.append("whatWillGet[]", JSON.stringify(item));
      }
    }

    if (new_what_will_get.length === 0) {
      form.delete("whatWillGet");
    }

    for (const item of data.questions) {
      if (item.question !== "" && item.answer !== "") {
        form.append("questions[]", JSON.stringify(item));
      }
    }

    for (const item of data.opcells) {
      // let id = item.id
      form.append("opcells[]", JSON.stringify({id:item.id,price:item.price}));
    }

    try {
      data?.image.includes("");
      form.delete("image");
    } catch (e) {}

    if (data?.cover_image === "") {
      // se cair aqui é porque removeu a imagem
      form.set("cover_image", null);
    } else {
      // se não removeu a imagem, então verifica se é um arquivo ou url
      try {
        data?.cover_image.includes("");
        // se for url, vai deletar a img do formdata pq é basicamente a mesma imagem que chegou do servidor
        form.delete("cover_image");
      } catch (e) {}
    }

    if (data?.description_image === "") {
      form.set("description_image", null);
    } else {
      try {
        data?.description_image.includes("");
        form.delete("description_image");
      } catch (e) {}
    }

    SetLoading(true);
    setSaveButtonStatus({
      status: "Salvando...",
      icon: <HourglassBottomIcon />,
    });
    let response = await Post("courses-main/update", form);
    SetLoading(false);
    if (response["errors"] != undefined) {
      let errors = Object.values(response.errors);
      let erro = "";
      for (let i = 0; i < errors.length; i++) {
        if (i != errors.length - 1) erro += errors[i] + "\n";
        else erro += errors[i];
        break;
      }
      console.log(erro);
      SetPopup(true);
      SetPopupMessage(erro);
      return;
    }

    if (response.status === false) {
      SetPopup(true);
      SetPopupMessage(response.message);
      setSaveButtonStatus({
        status: "Salvar tudo",
        icon: <SaveIcon />,
      });
      return;
    }

    if (response["success"] != undefined) {
      setSaveButtonStatus({
        status: "Salvo!",
        icon: <CheckIcon />,
      });
    }

    setTimeout(() => {
      setSaveButtonStatus({
        status: "Salvar tudo",
        icon: <SaveIcon />,
      });
    }, 2000);

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );

    if (response?.status === true) history.push("/courses/list");
  };

  return (
    <div className="card">
      <SweetAlert
        warning
        show={popup}
        title="Atenção!"
        confirmBtnText="Ok"
        onConfirm={() => SetPopup(false)}
      >
        {popupMessage}
      </SweetAlert>
      <div className="card-body">
        <ConfigCourse
          OnConfirm={(data) => Submit(data)}
          loading={loading}
          {...{ save_button_status }}
        />
      </div>
    </div>
  );
};

export default CourseEdit;

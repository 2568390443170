import { useState } from "react";
import { FormControl as Input, FormLabel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";

import Table from "./Table";
import DefaultButton from "components/DefaultButton";
import { Box, Button, Modal } from "@mui/material";
import AsideBannerAdd from "./Add";
import { useQuery } from "react-query";
import { Get, Post } from "utils/request";
import { useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Show } from "actions/SnackbarActions";
import { useLocation } from "react-router-dom";

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  maxWidth: "100%",
  minWidth: "36rem",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
  padding: "2rem 3rem",
  justifyContent: "center",
  alignItems: "center",
};

const FormBody = ({ banners, isLoading, refetch }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, SetSearch] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <AsideBannerAdd {...{ handleClose, refetch }} />
        </Box>
      </Modal>
      <div className="card-body">
        <h3 className="title">Banners</h3>
        <p className="text-muted">Aqui são listados todos os banners</p>
        <hr />
        <div
          className="flex margin-bottom"
          style={{
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <RoleBased>
            <DefaultButton
              bg="confirm"
              text="Adicionar novo banner"
              onClick={handleOpen}
            />
          </RoleBased>
        </div>
        <Table search={search} {...{ banners, isLoading, refetch }} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
        }}
      >
        <span
          style={{
            padding: "0.2rem 0.4rem",
            borderRadius: "0.4rem",
            backgroundColor: "#f1f1f1",
          }}
        >
          <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanho recomendado:
          Qualquer tamanho na proporção de tela 22:9
        </span>
        <span
          style={{
            padding: "0.2rem 0.4rem",
            borderRadius: "0.4rem",
            backgroundColor: "#f1f1f1",
          }}
        >
          <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
          .jpeg, .png e .gif.
        </span>
        <span
          style={{
            padding: "0.2rem 0.4rem",
            borderRadius: "0.4rem",
            backgroundColor: "#f1f1f1",
          }}
        >
          <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de
          tamanho para o arquivo.
        </span>

        {/* <FormLabel>
          Tempo de transição entre os banners (em milissegundos) (5000 milissegundos = 5 segundos)
          <Input
            placeholder="Ex: 5000"
            type="number"
            value={aside_transition_time}
            onChange={(e) => setAsideTransitionTime(e.target.value)}
          />
        </FormLabel> */}

        {/* <div>
          <Button variant="contained" color="success" onClick={() => Submit2()}>
            Salvar banners da lateral
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default FormBody;

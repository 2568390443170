import { Typography } from "@mui/material";
import { Show } from "actions/SnackbarActions";
import DefaultButton from "components/DefaultButton";
import ShowMoreText from "components/ShowMoreText";
import TabsContainer from "components/TabsContainer";
import moment from "moment";
import { useEffect, useState } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Get } from "utils/request";
import CoursesList from "./Courses/List";
import UsersList from "./Users/List";

const GroupsView = () => {

    const { group_id } = useParams();

    const [group,SetGroup] = useState({});
    const [firstLoading, SetFirstLoading] = useState(true);
    const dispatch = useDispatch();


    const GetData = async () => {
        SetFirstLoading(true);
        let response = await Get(`groups?id=${group_id}`);

        SetFirstLoading(false);
        if (response?.status === true) {
            SetGroup(response?.group);
        } else if (!response) dispatch(Show({
            show: true,
            message: "Falha ao carregar a turma",
            severity: "warning"
        }));
        
    }

    useEffect(()=>{GetData()}, []);

    if (firstLoading) return <Skeleton/>;

    return (
        <div className="card">
        
        <div className="card-body">
            <h3 className="title">{group.name}</h3>
                {group.description!=null && <div style={{marginTop:10}}>
                    <b style={{ marginRight: "5px" }}>Descrição: </b>
                    <ShowMoreText
                        maxChar={50}
                    >
                        {group.description}
                    </ShowMoreText>
                </div>}
                <Typography style={{marginTop:10}}><b>Início: </b>{moment(group.sub_start).format('DD/MM/YYYY')}</Typography>
                <Typography style={{marginTop:10}}><b>Encerramento: </b>{moment(group.sub_end).format('DD/MM/YYYY')}</Typography>
                {
                    console.log(group)
                }
                <Typography style={{marginTop:10}}><b>Duração em dias: </b>{group.availability_duration_days === -1 ? "Indefinido" : group.availability_duration_days + ' dias'}</Typography>

            <TabsContainer
            style={{marginTop:10}}
                labels={[
                    { label: "Alunos" },
                    // { label: "Cursos" }
                ]}
            >
                <UsersList/>
                {/* <CoursesList/> */}
            </TabsContainer>
            <DefaultButton
                bg="secondary"
                text="Voltar"
                icon={<RiArrowGoBackLine/>}
                to={group.id!=undefined? `/courses/view/${group.courses[0].id}`:''}
                search={'?t=2'}
            />
        </div>
        </div>

    );
}

const Skeleton = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title"/>
                <div className="skeleton-small-text skeleton-shimmer"/>
                <div className="skeleton-small-text skeleton-shimmer"/>
                <br/>
                <div className="w100 flex jcc align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{width: "12em", height: "12em"}}/>
                </div>
                <br/>
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{minHeight: "48px"}}/>
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{minHeight: "5em"}}/>
                <div className="skeleton-small-button skeleton-shimmer"/>
            </div>
        </div>
    );
}

export default GroupsView;
import { Box, Typography } from "@mui/material";
import Paginate from "components/Paginate";
import Board from "./Board";

import useComments from "./useComments";

const Comments = () => {

    const {
        comments = [],
        page,
        maxPage,
        setPage = () => {},
        refetch = () => {}
    } = useComments();

    return (
        <>
        <h5 className="title">Comentários</h5>
            <p className="text-muted" >Aqui são listados todos os comentários deste curso</p>
            <div style={{marginBottom:15}}/>
        <Box
            className="table-responsive"
        >
            <table
                className="table table-striped"
            >
                <thead>
                    <tr>
                        <th>Aula</th>
                        <th>Autor</th>
                        <th>Comentário</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {comments?.map((comment, commentKey) => (
                        <Board
                            key={commentKey}
                            onUpdate={refetch}
                            {...comment}
                        />
                    ))}
                </tbody>
            </table>
            {comments?.length == 0 && (
            <Typography
                align={"center"}
            >
                Nenhum registro de comentário
            </Typography>
        )}
            <Paginate
                value={page}
                onChange={setPage}
                maxPage={maxPage}
            />
        </Box>
        </>
    );
}

export default Comments;
import CoursesSideBar from "./CoursesSideBar";
import PlayerContainer from "./PlayerContainer";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import useCourseViewBody from "./useCourseViewBody";
import { CircularProgress } from "@material-ui/core";

const VideoPlayer = () => {
  const [hideSideBar, setHideSideBar] = useState(false);
  const [small_screen, setSmallScreen] = useState(false);

  // hide sidebar on small screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
        setHideSideBar(true);
      } else {
        setSmallScreen(false);
        setHideSideBar(false);
      }
    };
    refetch()
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  

  // get all course data and lessons etc
  const {
    course,
    fileData: { lesson, file, next, prev, fileIsLoading },
    startTime,
    refetch,
    isLoading
  } = useCourseViewBody();

 

  return (
    <div className={styles.videoPlayerWrapper}>
      {isLoading==false ? <><CoursesSideBar
        {...{
          course,
          modules: course?.modules,
          styles,
          hideSideBar,
          setHideSideBar,
          small_screen,
          refetch
        }}
      />
      <PlayerContainer
        {...{
          styles,
          hideSideBar,
          setHideSideBar,
          course_data: {
            course,
            fileData: { lesson, file, next, prev, fileIsLoading },
            startTime,
          },
          refetch,
        }}
      /></>:<div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
        <CircularProgress />
        </div>}
    </div>
  );
};

export default VideoPlayer;

import React, { useEffect, useState } from "react";
import { CircularProgress, Pagination } from "@mui/material";
import { Get } from "utils/request";
import Board from "./Board";

const Table = ({search}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [page, SetPage] = useState(1);
  const [lastPage, SetLastPage] = useState(1);
  const [courses, SetCourses] = useState([]);

  const getData = async () => {
    setIsLoading(true)
    const response = await Get(`user-course?page=${page}&search=${search}`);

    if (response.status) {
      SetCourses(response?.data);
      SetLastPage(response?.pagination?.last_page);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getData();
  }, [page, search]);

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Curso comprado</th>
              <th>Data de compra</th>
              <th>Usou cupom?</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              courses?.map((course, index) => (
                <Board key={index} course={course} isLoading={isLoading} OnDelete={getData} />
              ))}
          </tbody>
        </table>
      </div>
      <div className="w100 flex jcc">
        <CircularProgress hidden={!isLoading} />
        {/* <p hidden={courses?.length !== 0 || isLoading}>
          Nenhum curso foi achado
        </p> */}
      </div>
      <Pagination
        className={lastPage == 1 ? "hide" : ""}
        style={{ display: "flex", justifyContent: "flex-end" }}
        count={lastPage}
        value={page}
        onChange={(_, value) => SetPage(value)}
      />
    </div>
  );
};

export default Table;

import { IconButton } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { useState } from "react";
import Modal from "./Modal";

const GuideHelper = () => {

    const [modal, SetModal] = useState(false);

    const OpenModal = () => SetModal(true);
    const CloseModal = () => SetModal(false);

    return (
        <>
            <IconButton
                onClick={OpenModal}
            >
                <HelpIcon/>
            </IconButton>
            <Modal
                open={modal}
                onClose={CloseModal}
            />
        </>
    );
}

export default GuideHelper;
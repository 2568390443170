import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import "./index.css";

import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';

SwiperCore.use([Autoplay, Pagination, Navigation]);

const CarouselSlider = props => {

    const {
        title = "",
        items = [],
        children,
        arrows = true,
        swiperProps,
        swiperSlideProps,
        ...other
    } = props;

    return (
        <div className="carousel" {...other} hidden={children?.filter(x => x !== undefined)?.length === 0}>
            <div className="carousel-title">
                <h4>{title}</h4>
            </div>
            <Swiper 
                slidesPerView={1} 
                loopFillGroupWithBlank={true}
                loop={true}
                pagination={{
                    clickable: "true"
                }}
                navigation={arrows}
                className="mySwiper"
                breakpoints={{
                    "600": {
                        slidesPerView: 1
                    },
                    "920": {
                        slidesPerView: 1
                    },
                    "1400": {
                        slidesPerView: 4
                    },
                    "1600": {
                        slidesPerView: 5
                    }
                }}
                {...swiperProps}
            >
                {children?.map((value, index) => value !== null && value !== undefined && (
                    <SwiperSlide 
                        key={index}
                        style={{
                            width: "fit-content !important"
                        }}
                        {...swiperSlideProps}
                    >
                        {value}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default CarouselSlider;
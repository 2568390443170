// sections
import Welcome from "./Welcome";
import AppAd from "./AppAd";
import BottomLead from "./BottomLead";
import FreeCourses from "./FreeCourses";
import Testimonials from "./Testimonials";
import Banner from "./Banner";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useMainPages } from "modules/Header/data";
import Logo from "../../../../assets/images/rt/white_logo.png";
import { useEffect } from "react";
import { STORAGE_URL } from "variables";

const PreviewLandingPage = (props) => {
  const {
    headerColor1,
    headerColor2,
    headerFontColor,
    section_1,
    section_4,
    headerLogo,
  } = props;

  const [imageSrc, setImageSrc] = useState(Logo);

  useEffect(() => {
    console.log("headerLogo", headerLogo);
    if (headerLogo === "" || headerLogo?.length === 0) {
      setImageSrc(Logo);
      return;
    }

    let reader = new FileReader();
    reader.onload = function (e) {
      setImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(headerLogo);
    } catch (error) {
      setImageSrc(`${STORAGE_URL}${headerLogo}`);
    }
  }, [headerLogo]);

  return (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",
        maxWidth: "56vw",
        paddingTop: "0.1rem",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "1rem",
          marginBottom: "1rem",
          background: `linear-gradient(90deg, ${headerColor1} 0%, ${headerColor2} 100%)`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img
          src={imageSrc}
          alt="Logo rt"
          style={{
            height: "5rem",
            display: "block",
          }}
        />

        <Typography
          variant="h4"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            color: `${headerFontColor}`,
          }}
        >
          Barra de navegação
        </Typography>
      </div>

      <Banner
        banner={section_1.banner}
        setBanner={section_1.setBanner}
        transition_time={section_1.banner_transition_time}
      />
      <Welcome {...props} />
      <FreeCourses {...props} />
      <Testimonials
        testimonials={section_4.testimonials}
        setTestimonials={section_4.setTestimonials}
        {...props}
      />
      {/* <AppAd {...props} /> */}
      <BottomLead {...props} />
    </div>
  );
};

export default PreviewLandingPage;

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const image = file;
    if (!(file instanceof File)) return false;
    if (image && FileReader) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }
  });

export const numToFixed = (num, fixed) => {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  if(num!=null){

    return num.toString().match(re)[0];
  }
  else return num;
};

/**
 *
 * Component that shows a welcoming text, a banner and a video
 * It's called in the landing page
 *
 */
import { Box, Container, Typography, useTheme } from "@mui/material";
import Video from "components/Video";
import { Get } from "utils/request";
import { STORAGE_URL } from "variables";
import { useHistory } from "react-router";
import ReactPlayer from "react-player";
import AsideBanner from "./AsideBanner";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { useState } from "react";
import { fileToBase64 } from "utils/images/fileToBase64";

const Welcome = (props) => {
  const {
    welcomeTitle,
    setWelcomeTitle,
    welcomeDescription,
    setWelcomeDescription,
    welcomeSubtitle,
    setWelcomeSubtitle,

    welcomeVideoLink,
    setWelcomeVideoLink,
    welcomeVideoFile,
    setWelcomeVideoFile,

    setHeaderColor1,
    headerColor1,
    setHeaderColor2,
    headerColor2,
  } = props;

  const history = useHistory();
  const theme = useTheme();

  const [video_from_api, setVideo_from_api] = useState("");

  const { data: descriptionData } = useQuery(
    "landingDescription",
    async () => await Get(`site-rt/description?type=firstContent`),
    { refetchOnWindowFocus: false }
  );
  const { page: query_description } = descriptionData || {};

  const getVideos = async () => {
    await Get("site-rt/advertising-video").then((res) => {
      setVideo_from_api(STORAGE_URL + res?.advideo?.video);
    });
  };

  useEffect(() => {
    setWelcomeTitle(query_description?.title || welcomeTitle);
    setWelcomeDescription(query_description?.description || welcomeDescription);
    setWelcomeSubtitle(query_description?.sub || welcomeSubtitle);
    setWelcomeVideoFile(video_from_api || welcomeVideoFile);
    setWelcomeVideoLink(query_description?.video_link || welcomeVideoLink);
  }, [video_from_api]);

  useEffect(() => {
    setWelcomeTitle(query_description?.title || welcomeTitle);
    setWelcomeDescription(query_description?.description || welcomeDescription);
    setWelcomeSubtitle(query_description?.sub || welcomeSubtitle);
    setWelcomeVideoFile(welcomeVideoFile);
    setWelcomeVideoLink(welcomeVideoLink);

    getVideos();
  }, []);

  const [videoBase64, setVideoBase64] = useState(null);

  const renderVideo = async () => {
    if (welcomeVideoFile) {
      await fileToBase64(welcomeVideoFile).then((res) => {
        setVideoBase64(res);
      });
    } else {
      setVideoBase64(null);
    }
  };

  useEffect(() => {
    renderVideo();
  }, [welcomeVideoFile]);

  useEffect(() => {
    console.log(video_from_api);
  }, [video_from_api]);

  return (
    <div
      style={{
        display: "flex",
        overflow: "hidden",
        position: "relative",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontFamily: "var(--Freight)",
          fontWeight: "bold",
          textAlign: "center",
          color: "black",
          position: "absolute",
          top: "4rem",
        }}
      >
        Bem-vindo (seção 2)
      </Typography>
      <Container
        maxWidth="xl"
        sx={{
          height: "max-content",
          display: "flex",
          justifyContent: "center",
          overflow: "hidden",
          zIndex: 10,
          gap: "4rem",
          flexDirection: "column",
          margin: "12vh 0",
          padding: "0 2rem",
          paddingBottom: "5rem",
        }}
      >
        <Box sx={{ margin: "0 auto" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "1rem",
                gap: "0.6rem",
              }}
            >
              <Typography
                variant="h3"
                style={{
                  marginBottom: "1.6rem",
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={{ __html: welcomeTitle }}
              />
              <Typography
                variant="body1"
                sx={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: welcomeDescription }}
              />
              <Typography
                variant="h6"
                dangerouslySetInnerHTML={{ __html: welcomeSubtitle }}
              />
            </div>
          </div>
        </Box>

        {/* <AsideBanner /> */}
        {/* video && video != "undefined" && link === null ?  */}
        <div
          style={{
            margin: "0 auto",
          }}
        >
          {
            <Video
              containerProps={{
                style: {
                  borderRadius: "0.8rem",
                  width: "100%",
                },
              }}
              src={videoBase64 || video_from_api}
            />
          }
        </div>
      </Container>
    </div>
  );
};

export default Welcome;

import { mudarDados } from "actions/AppActions";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

const useFilterButton = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState([]);

    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const openPopover = e => setAnchorEl(e.currentTarget);
    const closePopover = () => setAnchorEl(null);

    const addFilter = (value, type) => {
        const tempFilters = filters.filter(x => x != value);
        tempFilters.push({ value: value, type: type });
        setFilters([...tempFilters]);
    }

    const removeFilter = (value, type) => {
        const tempFilters = filters.filter(x => x?.value != value);
        setFilters([...tempFilters]);
    }

    const clearFilter = () => setFilters([]);

    const handleCheckbox = (value, type = "action") => e => {
        if (e.target.checked) addFilter(value, type);
        else removeFilter(value, type);
    }

    const hasValue = (value) => {
        for (let i = 0; i < filters?.length; i++) {
            if (filters[i]?.value === value) return true;
        }
        return false;
    }

    useEffect(() => dispatch(mudarDados({ logFilters: [...filters] })), [filters]);

    return {
        anchorEl,
        filters,
        openPopover,
        closePopover,
        handleCheckbox,
        clearFilter,
        hasValue
    };
}

export default useFilterButton;
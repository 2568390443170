import React, { useEffect, useMemo, useState } from "react";

import ChangePasswordBody from "./ChangePasswordBody";

import { Post } from "utils/request";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "modules/Header";
import "./index.css";
import { useMainPages } from "modules/Header/data";
import { Box, useTheme } from "@mui/material";
import WhatsappButton from "components/WhatsappButton";
import { Show } from "actions/SnackbarActions";

const ChangePassword = (props) => {
  
  const user = useSelector((store) => store.AppReducer.user);
  const token = useSelector((store) => store.AppReducer.token);

  const [loading, SetLoading] = useState(false);
  const { session_id } = useParams();

  const [errorsList, SetErrors] = useState([]);
  const [link, SetLink] = useState(null);

  const history = useHistory();

  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();

  const loginTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.login
  );
  const pages = useMainPages();

  const Login = async (data) => {
    if (!data.password || data.password === "")
      return alert("Insira uma senha");

    if (!data.password_confirmation || data.password_confirmation === "")
      return alert("Insira a senha de confirmação");

    if (data.password !== data.password_confirmation) {
      alert("Os campos de senha não condizem");
      return;
    }

    let form = new FormData();

    if (!props?.lead) {
      form.append("password", data.password);
      form.append("password_confirmation", data.password_confirmation);

      SetLoading(true);
      let response = await Post("auth/change_password", form);

      if (response?.status) {
        let user = response["user"];

        localStorage.setItem("user", JSON.stringify(user));

        dispatch({ type: "login", payload: { token: token, user: user } });
        history.replace("/");
      } else if (response?.errors) {
        let errors = Object.values(response?.errors);
        SetErrors(errors);

        if (response?.link)
          SetLink(
            <Link to={response?.link?.href}>{response?.link?.message}</Link>
          );
        else SetLink(null);

        SetLoading(false);

        return;
      }
      history.push(
        location?.state?.redirectTo
          ? location?.state?.redirectTo
          : response?.user?.role == "Admin"
          ? "/dashboard"
          : "/"
      );
    } else {
      form.append("password", data.password);
      form.append("activate_session_id", session_id);

      SetLoading(true);
      const response = await Post("emailname/activate", form);
      SetLoading(false);

      if (response?.status === true) history.push("/login");

      dispatch(
        Show({
          show: true,
          severity: response?.severity,
          message: response?.message,
        })
      );
    }
  };

  useEffect(() => {
    if (!user || user.first_access == false) {
      history.replace("/");
    }
  }, []);

  useEffect(() => {
    console.log("login theme", loginTheme?.palette?.backgroundImage);
  }, [loginTheme]);

  return (
    <>
      <Header pages={pages} />
      <ChangePasswordBody
        onSubmit={(data) => {
          if (data === null || data === undefined) return;
          Login(data);
        }}
        errors={errorsList}
        link={link}
        loading={loading}
      />
      <WhatsappButton />
    </>
  );
};

export default ChangePassword;

import { useHistory } from "react-router-dom";
import Actions from "./Actions";

const Board = props => {

    const {
        id,
        user,
        comment,
        points,
        accepted,
        onUpdate = () => {}
    } = props;

    const history = useHistory();

    return (
        <tr>
            <td>
                <a
                    onClick={() => history.push(`/profile/view/${user?.id}`)}
                    style={{
                        cursor: "pointer"
                    }}
                >
                    {user?.name}
                </a>
            </td>
            <td
                style={{
                    maxWidth: "10em"
                }}
            >
                {comment}
            </td>
            <td>{points}</td>
            <Actions
                id={id}
                accepted={accepted}
                onUpdate={onUpdate}
            />
        </tr>
    );
}

export default Board;
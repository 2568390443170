import { Fab, Typography, useMediaQuery } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { motion } from "framer-motion";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Portal from "components/Portal";
import styles from "./index.module.css";

const SaveOrderButton = props => {

    const {
        onCancel = () => {},
        ...others
    } = props;

    const matches = useMediaQuery("(min-width: 730px)");

    return (
        <Portal className={styles.container}>
            <Fab
                component={motion.button}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                style={{
                    backgroundColor: red["400"],
                    color: "white"
                }}
                variant={matches ? "extended" : "circular"}
                size={"medium"}
                onClick={onCancel}
            >
                <CloseIcon/>
                {matches && <Typography>Cancelar</Typography>}
            </Fab>
            <Fab
                {...others}
                component={motion.button}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                style={{
                    backgroundColor: green["400"],
                    color: "white"
                }}
                variant={matches ? "extended" : "circular"}
                size={"medium"}
            >
                <CheckIcon/>
                {matches && <Typography>Salvar ordem</Typography>}
            </Fab>
        </Portal>
    );
}

export default SaveOrderButton;
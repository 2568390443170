import Video from "components/Video";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { Get, Post } from "utils/request";
import { STORAGE_URL } from "variables";
import logo from "assets/images/rt/icon_transparent.png";
import styles from "./index.module.css";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";

let completedOnce = false;

const next_prev_button_style = {
  color: "white",
  fontSize: 50,
  padding: "0.4rem",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(0,0,0,0.5)",
  borderRadius: "0.5rem",
  zIndex: 100,
  opacity: 0.7,
  cursor: "pointer",
  "&:hover": {
    opacity: 1,
  },
};

const VideoContainer = forwardRef((props, ref) => {
  const {
    boldTitle,
    title,
    thumbnail = null,
    path = "",
    type = "",
    prev = "",
    next = "",
    startTime,
    autoPlay = false,
    onAnnotation = () => {},
    course_data,
    refetch,
    fileIsLoading,
    modules,
    ...others
  } = props;

  const videoRef = useRef();
  const [mouseEnterPlayer, SetMouseEnterPlayer] = useState(false);

  const history = useHistory();
  const { course_id, file_id } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { isFetching, data } = useQuery(
    "log",
    () => {
      setTimeout(() => {
        const form = new FormData();
        form.append("file_id", file_id);
        form.append("video_time", videoRef?.current?.currentTime);
        if(videoRef?.current?.currentTime==undefined){
          return;
        }
        return Post(`lessons/files/log`, form);
      }, 10000);
    },
    {
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      enabled: type == "video",
    }
  );

  useImperativeHandle(ref, () => ({
    videoRef: videoRef,
  }));

  useEffect(() => {
    if (data?.status === true && data?.userfile?.completed && !completedOnce) {
      queryClient.refetchQueries(["course", course_id]);
      completedOnce = true;
    }
  }, [data]);

  // useEffect(() => {
  //   console.log('anterior', prev);
  //   console.log('proximo', next);
  // }, [next, prev]);

  //setting completed flag to false because the file changed, so it may call the courses info again
  useEffect(() => (completedOnce = false), [file_id]);
  // console.log(type)

  const MarkCompleted = async () => {
    console.log(course_data);
    const file_id = course_data?.fileData?.file?.userfile?.file_id;

    const form = new FormData();
    form.append("file_id", file_id);
    form.append("completed", true);

    let data = await Post(`lessons/files/mark_completed`, form);

    if (data.status) {
      refetch();
    } else {
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: data.message || "Erro ao marcar aula como completa",
        })
      );
    }
  };

  return (
    <div
      className="preview-video"
      {...others}
      style={{
        width: "100%",
        height: type !== "video" ? "80%" : "auto",
        position: "relative",
      }}
      // onMouseEnter={() => SetMouseEnterPlayer(true)}
      // onMouseLeave={() => SetMouseEnterPlayer(false)}
    >
      {modules?.length === 0 || modules === undefined && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "80%",
              color: "#999",
              fontSize: "1.2rem",
            }}
          >
            Este curso ainda não possui aulas cadastradas
          </div>
        )}

      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          zIndex: 100,
          backgroundColor: "#f5f5f5",
          opacity: fileIsLoading ? 1 : 0,
          visibility: fileIsLoading ? "visible" : "hidden",
          transition: "opacity 0.2s ease-in-out, visibility 0.2s ease-in-out",
        }}
      >
        <img
          src={logo}
          width="100"
          height="100"
          className="infiniteRotateAnim"
        />
        <span>Carregando...</span>
      </div>
      {/* {prev !== undefined && (
          <SkipPreviousIcon
            sx={{
              ...next_prev_button_style,
              left: "1rem",
              opacity: mouseEnterPlayer ? 1 : 0,
              transition: "opacity 0.2s ease-in-out",
            }}
            onClick={() => {
              // history.push(`/courses/${course_id}/${prev?.id}`);
              console.log("prev", prev);
            }}
          />
        )}
        {next !== undefined && (
          <SkipNextIcon
            sx={{
              ...next_prev_button_style,
              right: "1rem",
              opacity: mouseEnterPlayer ? 1 : 0,
              transition: "opacity 0.2s ease-in-out",
            }}
            onClick={() => {
              // history.push(`/courses/${course_id}/${next?.id}`);
              console.log("next". next);
            }}
          />
        )} */}
      {path && type === "video" && (
        <Video
          src={path && STORAGE_URL + path}
          hidden={type != "video"}
          next={next}
          prev={prev}
          ref={videoRef}
          // boldTitle={boldTitle}
          title={title}
          // sx={{ aspectRatio: "16/9", height: "100%" }}
          sx={{ height: "100%" }}
          annotations
          onPrev={() => {
            history.push(`/courses/${course_id}/${prev?.id}`);
            console.log("prev");
          }}
          onNext={() => {
            MarkCompleted().then(() => {
              history.push(`/courses/${course_id}/${next?.id}`);
            });
            console.log("next");
          }}
          onAnnotation={onAnnotation}
          startTime={startTime}
          autoPlay={autoPlay}
          thumbnail={thumbnail}
        />
      )}

      {path && type == "document" && (
        <iframe
          // style={{ aspectRatio: "16/9", width: "100%" }}
          style={{ width: "100%", height: "100%" }}
          src={path && STORAGE_URL + path}
        ></iframe>
      )}

    </div>
  );
});

export default VideoContainer;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Board from "./Board";

import { Post } from "../../../utils/request";
import { CircularProgress } from "@mui/material";
import { Pagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { useQuery } from "react-query";

const Table = (props) => {
  const { search = "",hidden=false } = props;
  const user = useSelector(store => store.AppReducer.user);

  let form = new FormData();
  form.append("is_admin", user?.role === "Admin");
  form.append("user_id", user?.id);

  const [page, SetPage] = useState(1);

  const dispatch = useDispatch();

  const { isLoading, data, refetch } = useQuery(
    ["courses-main", { search, page }],
    async () => await Post(`courses-main?search=${search}&page=${page}`, form),
    {
      onError: (data) => {
        dispatch(
          Show({
            show: true,
            message: "Falha ao carregar os cursos",
            severity: "warning",
          })
        );
      },
    }
  );
  const { courses = [], pagination: { last_page: maxPage = 1 } = {} } =
    data || {};

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Preço à vista</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              courses?.map((value, index) => (
                <Board hidden={hidden} key={index} {...value} isLoading={isLoading} OnDelete={refetch} />
              ))}
          </tbody>
        </table>
      </div>
      <div className="w100 flex jcc">
        <CircularProgress hidden={!isLoading} />
        <p hidden={courses?.length !== 0 || isLoading}>
          Nenhum curso foi achado
        </p>
      </div>
      <Pagination
        className={maxPage == 1 ? "hide" : ""}
        style={{ display: "flex", justifyContent: "flex-end" }}
        count={maxPage}
        value={page}
        onChange={(_, value) => SetPage(value)}
      />
    </div>
  );
};

export default Table;

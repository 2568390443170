import { FormControl as Input } from "react-bootstrap";

import Table from "./Table";
import DefaultButton from "components/DefaultButton";
import { useState } from "react";

const CursosComprados = () => {
  const [search, SetSearch] = useState("");
  const [search2, SetSearch2] = useState("");
  let timer = null;

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="title">Cursos comprados</h3>
        <p className="text-muted">
          Aqui são listados todos os cursos que foram comprados.
        </p>
        <hr />
        <div className="flex jcsb flex-wrap margin-bottom">
          <Input
            placeholder="Pesquisar pelo nome do usuário ou curso"
            value={search2}
            onChange={(e) => {
              // timer to avoid multiple requests on type
              clearTimeout(timer);
              SetSearch2(e.target.value);

              timer = setTimeout(() => {
                SetSearch(e.target.value);
              }, 1000);
            }}
            style={{ width: "50%" }}
          />
          {/* <DefaultButton
            bg="confirm"
            text="Adicionar novo curso"
            to="/courses/add"
          /> */}
        </div>
        <Table search={search} />
      </div>
    </div>
  )
}

export default CursosComprados;
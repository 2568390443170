import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import Board from "./Board";

import { Get } from "utils/request";
import { CircularProgress } from "@mui/material";
import { Pagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { useParams } from "react-router";
import DefaultButton from "components/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";

const Table = forwardRef((props, ref) => {
  const { search = "" } = props;

  const [logs, SetLogs] = useState([]);
  const [loading, SetLoading] = useState(false);

  const [page, SetPage] = useState(1);
  const [maxPage, SetMaxPage] = useState(1);

  const { email_id } = useParams();
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    GetData: GetData,
  }));

  const GetData = async () => {
    SetLoading(true);
    let response = await Get(`emails/actions?emailId=${email_id}`);
    SetLoading(false);
     
    if (response?.status === true) {
      SetLogs(response?.actions);
      SetMaxPage(response?.pagination.lastPage);
    } else if (!response)
      dispatch(
        Show({
          show: true,
          message: "Falha ao carregar os registros",
          severity: "warning",
        })
      );
  };

  useEffect(GetData, [search, page]);

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Data</th>
              <th>Descrição</th>
              <th>Usuário</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              logs?.map((log, index) => (
                <Board
                  key={index}
                  id={log?.id}
                  action={log?.general_description}
                  username={log?.user?.name}
                  createdAt={log?.created_at}
                  OnDelete={GetData}
                />
              ))}
          </tbody>
        </table>
      </div>
      <div className="w100 flex jcc">
        <CircularProgress hidden={!loading} />
        <p hidden={logs?.length !== 0 || loading}>Nenhum registro foi achado</p>
      </div>
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to={`/log/leads-course`}
        />
        <Pagination
          className={maxPage == 1 ? "hide" : ""}
          style={{ display: "flex", justifyContent: "flex-end" }}
          count={maxPage}
          value={page}
          onChange={(_, value) => SetPage(value)}
        />
      </div>
    </div>
  );
});

export default Table;

import { Card, CardActions, CardContent, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from "@mui/material";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { useEffect, useState } from "react";

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { HdrPlusOutlined, PlusOne, PlusOneOutlined } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';

const Question = props => {

    const {
        prevOptions = [{ id: 0, text: "", correct: true }],
        prevUtterance = "",
        questionId,
        question_item,
        onChange = () => { },
        onAdd = () => { },
        onQuestionDelete = () => { },
        ...other
    } = props;

    const [utterance, SetUtterance] = useState(prevUtterance);
    const [options, SetOptions] = useState(prevOptions);
    const [answer, SetAnswer] = useState(0);

    useEffect(()=>{
        if(prevOptions!=undefined && prevOptions.length==1 && prevOptions.filter((item,id)=>item.correct).length==0){
            SetAnswer(prevOptions[0].id)
        }
    },[])

    const AddOption = () => {
        let tempOptions = options;
        let optionId = options?.length;
        tempOptions.push({
            id: optionId,
            text: ``,
            correct: optionId == answer
        });

        SetOptions([...tempOptions]);
        onAdd(questionId, { questionId: questionId, utterance: utterance, options: [...tempOptions] });
    }

    const DeleteOption = optionId => {
        let tempOptions = options;

        for (let i = 0; i < tempOptions?.length; i++) {
            if (optionId != tempOptions[i]["id"]) continue;
            tempOptions.splice(i, 1);
            break;
        }

        SetOptions([...tempOptions]);
        onChange(questionId, { questionId: questionId, utterance: utterance, options: [...tempOptions] });
    }

    const HandleChange = (id, value) => {
        let tempOptions = options;
        for (let i = 0; i < tempOptions?.length; i++) {

            if (id != tempOptions[i]["id"]) continue;
            tempOptions[i] = {
                id: id,
                text: value,
                correct: id == answer
            };
            break;
        }

        SetOptions([...tempOptions]);
        // onChange(questionId, {questionId: questionId, utterance: utterance, options: [...tempOptions]});
    }

    const HandleAnswerChange = () => {
        if (!answer) return;
        let tempOptions = options;
        for (let i = 0; i < tempOptions?.length; i++) {
            tempOptions[i] = {
                id: tempOptions[i]?.id,
                text: tempOptions[i]?.text,
                correct: tempOptions[i]?.id == answer
            };
        }

        SetOptions([...tempOptions]);
        onChange(questionId, { questionId: questionId, utterance: utterance, options: [...tempOptions] });
    }

    // useEffect(() => onAdd(questionId, { questionId: questionId, utterance: utterance, options: [...options] }), []);
    // useEffect(() => onAdd(questionId, {questionId: questionId, utterance: utterance, options: [...options]}), [utterance]);
    useEffect(HandleAnswerChange, [answer]);

    return (
        <Card
            style={{
                margin: 10
            }}
        // raised

        >
            <CardContent>
                <Input
                    placeholder="Enunciado"
                    onChange={e => SetUtterance(e.target.value)}
                    onBlur={() => onAdd(questionId, { questionId: questionId, utterance: utterance, options: [...options] })}
                    value={utterance}
                    style={{ height: 56 }}
                />
                {options && options?.map((value, key) => (
                    <div
                        id={value?.id}
                        className="flex align-center jcsb mt-3"
                        key={key}
                    >
                        <div>
                            <Radio
                                style={{
                                    color: value?.correct ? "#66bb6a" : ""
                                }}
                                value={value?.id}
                                onChange={e => SetAnswer(e.target.value)}
                                checked={value?.correct}
                            />
                        </div>
                        <div style={{ flexGrow: 1, width: '100%',position:'relative' }}>

                            <Input
                                onChange={e => HandleChange(value?.id, e.target.value)}
                                onBlur={() => onChange(questionId, { questionId: questionId, utterance: utterance, options: [...options] })}
                                value={value?.text}
                                style={{ height: 56,paddingRight:!value?.correct?0:35 }}
                                placeholder={'Opção ' + (key + 1)}

                            />
                             <CheckIcon
                                hidden={!value?.correct}
                                style={{
                                    color: "#66bb6a",
                                    position:'absolute',
                                    top:16,right:10
                                }}
                            />
                            {/* <TextField
                                value={value?.text}
                                hiddenLabel
                                placeholder={'Opção ' + (key + 1)}
                                label=' '
                                InputLabelProps={{ shrink: false }}
                                InputProps={{ style: { borderLeft: 'none' } }}
                                style={{ borderTop: 'none', borderLeft: 'none', width: '100%' }}
                                onFocus={e => {
                                    e.target.select();
                                }}
                                onBlur={() => onChange(questionId, { questionId: questionId, utterance: utterance, options: [...options] })}
                                onChange={e => HandleChange(value?.id, e.target.value)}
                            /> */}
                        </div>
                        <div>
                           
                            <IconButton onClick={() => DeleteOption(value?.id)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                ))}
                <div className="flex align-center mt-3">
                    <AddIcon
                    onClick={AddOption}
                        style={{
                            color: "grey",
                            width: 42,cursor: 'pointer' 
                        }}
                    />
                    <div onClick={AddOption} style={{ cursor: 'pointer' }}>
                        <p>Adicionar opção</p>
                    </div>
                    {/* <TextField
                        value={"Adicionar opção"}
                        style={{color:'black',border:'none'}}
                        disabled
                        onClick={AddOption}
                    /> */}
                </div>
            </CardContent>
            {/* <CardActions>
                <IconButton onClick={() => onQuestionDelete(question_item)}>
                    <DeleteIcon />
                </IconButton>
            </CardActions> */}
        </Card>
    );
}

export default Question;
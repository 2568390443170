export const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
];

export const getSequenceYears = () => {
    let currentYear = new Date().getFullYear()
    let years = []

    for (let i = currentYear; i < currentYear + 10; i++) {
        years = [...years, i]
    }

    return years
}
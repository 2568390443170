import { useEffect, useState } from "react"
import { Get } from "utils/request";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import DefaultButton from 'components/DefaultButton';
import VideoInput from "components/Inputs/VideoInput";

const Formbody = props => {

    const {
        OnConfirm,
        loading
    } = props;

    const [video, SetVideo] = useState("");
    const [existingVideo, SetExistingVideo] = useState("");
    const [link, SetLink] = useState("");

    const GetData = async () => {
        let response = await Get(`site-rt/advertising-video`);
        if (response?.status === true) {
            SetLink(response?.advideo?.link);
            response?.advideo?.video!=null && response?.advideo?.video != "undefined" && SetExistingVideo([{
                id: response?.advideo?.id,
                path: response?.advideo?.video,
                name: "Video de propaganda"
            }]);
        }
    }

    const HandleExistingVideoDeletion = () => SetExistingVideo([]);

    useEffect(GetData, []);

    return (
        <div className="form-course">
            <form>
                <FormLabel>Vídeo</FormLabel>
                <VideoInput
                    existingVideos={existingVideo}
                    VideoChange={value => {
                        if (value?.length !== 0) SetExistingVideo([]);
                        SetVideo(value);
                    }}
                    limitOne
                    HandleExistingVideoDeletion={HandleExistingVideoDeletion}
                />
                <br/>
                <FormLabel>Link</FormLabel>
                <Input
                    placeholder="Link"
                    value={link}
                    onChange={e => SetLink(e.target.value)}
                />
            </form>
            <br />
            <div className="w100 inline-flex jsend mt-2">
                <DefaultButton
                    bg="confirm"
                    text="Salvar"
                    onClick={() => {
                        const data = {
                            video: video,
                            link: link
                        };
                        OnConfirm(data);
                    }}
                    loading={loading}
                />
            </div>
        </div>
    )
}
export default Formbody;
import { useEffect, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import JoditEditor from "jodit-react";

// mui icons
import { Post } from "utils/request";
import Label from "components/InfoLabel";
import ColorInput from "components/Inputs/ColorInput";

const BottomLead = (props) => {
  const dispatch = useDispatch();

  const {
    bottomLeadTitle,
    setBottomLeadTitle,
    bottomLeadSubtitle,
    setBottomLeadSubtitle,
    bottomLeadText1,
    setBottomLeadText1,
    bottomLeadText2,
    setBottomLeadText2,

    handleOpenLoadingModal,
    handleCloseLoadingModal,

    JoditConfig,
  } = props;

  // const [title, setTitle] = useState(section_6[0].title);
  // const [subtitle, setSubtitle] = useState(section_6[0].subtitle);
  // const [text1, setText1] = useState(section_6[0].text1);
  // const [text2, setText2] = useState(section_6[0].text2);

  // useEffect(() => {
  //   setTitle(section_6[0].title);
  //   setSubtitle(section_6[0].subtitle);
  //   setText1(section_6[0].text1);
  //   setText2(section_6[0].text2);
  // }, [section_6]);

  const Submit = async () => {
    handleOpenLoadingModal();
    let form = new FormData();
    form.append("bottom_lead_title", bottomLeadTitle);

    form.append("bottom_lead_subtitle", bottomLeadSubtitle);

    form.append("bottom_lead_text1", bottomLeadText1);

    form.append("bottom_lead_text2", bottomLeadText2);

    form.append("type", "firstContent");

    let response = await Post("site-rt/description/update", form);

    if (response.status === 200) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
          buttonActionText: "Ver",
        })
      );
    } else if (response.status !== 200) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
          buttonActionText: "Ver",
        })
      );
    }
    handleCloseLoadingModal();
  };

  return (
    <>
      <FormLabel>
        Título <span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={bottomLeadTitle}
          onChange={(value) => setBottomLeadTitle(value)}
        />
      </FormLabel>

      <FormLabel>
        Subtítulo <span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={bottomLeadSubtitle}
          onChange={(value) => setBottomLeadSubtitle(value)}
        />
      </FormLabel>

      <FormLabel>
        Texto 1 <span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={bottomLeadText1}
          onChange={(value) => setBottomLeadText1(value)}
        />
      </FormLabel>

      <FormLabel>
        Texto 2 <span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={bottomLeadText2}
          onChange={(value) => setBottomLeadText2(value)}
        />
      </FormLabel>

      <div>
        <Button variant="contained" color="success" onClick={() => Submit()}>
          Salvar seção 6
        </Button>
      </div>
    </>
  );
};
export default BottomLead;

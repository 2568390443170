import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Show } from "actions/SnackbarActions";
import { Post } from "utils/request";
import { useLocation, useParams } from "react-router";
import FormBody from "./FormBody";
import SweetAlert from "react-bootstrap-sweetalert";

const CreateAccount = () => {
  const { search } = useLocation();
  const parameters = new URLSearchParams(search);
  const role_type = parameters.get("role");

  const [popup, SetPopup] = useState(false);
  const [popupMessage, SetPopupMessage] = useState("");

  const [loading, SetLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const Submit = async (data) => {
    if (!data?.image) {
      SetPopup(true);
      SetPopupMessage("Insira uma imagem");
      return;
    } else if (!data?.name) {
      SetPopup(true);
      SetPopupMessage("Insira um nome!");
      return;
    } else if (!data?.email) {
      SetPopup(true);
      SetPopupMessage("Insira um e-mail!");
      return;
    } else if (
      !data?.signature &&
      data.role_name[0] != undefined &&
      data.role_name[0].key != "aluno"
    ) {
      SetPopup(true);
      SetPopupMessage("Insira uma assinatura!");
      return;
    } else if (!data?.phone.length > 0) {
      SetPopup(true);
      SetPopupMessage("Insira um telefone!");
      return;
    }

    let form = new FormData();

    form.append("name", data?.name);
    form.append("email", data?.email);
    form.append("phone", data?.phone);
    form.append("description", data?.description);
    form.append("isTeamMember", data?.isTeamMember);

    if (data?.signature) form.append("signature", data?.signature);

    form.append("role_id", data?.role);
    form.append("is_recipient", data?.isRecipient ?? false);

    if (data?.isRecipient) {
      form.append("document", data?.document.replace(/\D/g, ""));
      form.append("account_check_digit", data?.accountDigit);
      form.append("branch_check_digit", data?.branchDigit);
      form.append("account_number", data?.accountNumber);
      form.append("branch_number", data?.branchNumber);
      form.append("bank", data?.bank);
    }

    form.append("profile_image", data?.image);

    SetLoading(true);

    let response = await Post(`auth/register`, form);
    SetLoading(false);

    if (response?.status === true) {
      dispatch(
        Show({
          show: true,
          severity: response?.severity,
          message: response?.message,
        })
      );
      history.push("/log/accounts");
    } else {
      if (response?.errors?.error) {
        dispatch(
          Show({
            show: true,
            severity: "error",
            message: response?.errors?.error,
          })
        );
      } else {
        dispatch(
          Show({
            show: true,
            severity: "error",
            message: Object.values(response?.errors).join("\n"),
          })
        );
      }
    }
  };

  return (
    <div className="card">
      <SweetAlert
        warning
        show={popup}
        title="Atenção!"
        confirmBtnText="Ok"
        onConfirm={() => SetPopup(false)}
      >
        {popupMessage}
      </SweetAlert>
      <div className="card-body">
        <h2 className="title">Criar conta</h2>
        <p>Aqui são criados as contas do sistema</p>
        <br />
        <FormBody
          OnConfirm={(data) => Submit(data)}
          loading={loading}
          role_type={role_type}
        />
      </div>
    </div>
  );
};

export default CreateAccount;

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { removeHTML } from "utils/filters";
import { STORAGE_URL } from "variables";
import { numToFixed } from "utils/helpers";
import { moneyMask } from "utils/moneyMask";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import loadOpcells from "./LoadOpcells";
// import { useEffect, useState } from "react";

const text_container = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  position: "relative",
};

const text_style = {
  fontSize: "1.5rem",
  fontWeight: "bold",
  position: "absolute",
  margin: 0,
  padding: "0 1rem",
  zIndex: 10,
};

const text_comment = {
  fontSize: "1rem",
  textAlign: "center",
};

const ConfirmOrder = (props) => {
  const { course, selectedOpcells, font_color, accent_color } = props;

  const getTotalPrice = () => {
    let course_price = parseFloat(course?.price);
    if(isNaN(course_price)){
      course_price=0;
    }
    console.log('price',course_price)
    console.log(course)

    let opcells_total_price = selectedOpcells?.reduce((acc, opcell) => {
      return acc + parseFloat(opcell?.price.replace("R$ ", ""));
    }, 0);

    // console.log(course);
    // console.log(course?.price);
    // console.log(course_price);
    // console.log(opcells_total_price);
    // console.log(course_price + opcells_total_price);

    let sum = numToFixed(course_price + opcells_total_price, 2);

    let final = parseFloat(sum).toFixed(2);

    return moneyMask(final);
  };

  // const getInstallmentsPrice = (format=false) => {
  //   let total_course_installments = parseFloat(course?.installments_price) * course?.installments_quantity;
  //   let calc = numToFixed(total_course_installments/course?., 2);
  //   return format ? moneyMask(total_course_installments) : total_course_installments;
  // }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        style={{ fontWeight: "bold", color: font_color }}
      >
        No carrinho
      </Typography>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {genCourseItem({
          image: course?.image,
          name: course?.name,
          font_color,
        })}

        {selectedOpcells &&
          selectedOpcells.map((opcell, index) => {
            return genCourseItem({
              image: opcell?.image,
              name: opcell?.name,
              index: index,
              font_color,
            });
          })}
      </div>

      {/* opcell section */}
      {props.opcells[0] && props.opcells.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: font_color,
          }}
        >
          <div style={text_container}>
            <h4 style={text_style}>Recomendações</h4>
            <hr style={{ width: "100%" }} />
          </div>
          <span style={text_comment}>
            Os nossos especialistas recomendam que você adquira os seguintes
          </span>
        </div>
      )}
      {loadOpcells(props)}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          padding: "1rem",
          borderRadius: "0.6rem",
          color: font_color,
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            color: font_color,
          }}
        >
          Resumo do pagamento
        </Typography>

        {paymentItem(course, 0, font_color)}
        {selectedOpcells &&
          selectedOpcells.map((opcell, index) => {
            return paymentItem(opcell, index, font_color);
          })}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <span
            style={{
              padding: "0.1rem 0.6rem",
              margin: "0.2rem 0",
            }}
          >
            Total à vista: <b>{getTotalPrice()}</b>
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.2rem",
        }}
      >
        <Typography
          variant="body1"
          style={{ margin: "0.4rem 0", color: font_color }}
        >
          <ErrorOutlineIcon
            sx={{
              color: "#f44336",
            }}
          />{" "}
          <b>Atenção.</b> Ao adicionar uma indicação de curso no carrinho e for
          do interesse do cliente realizar o pagamento parcelado, será realizado
          dois ou mais pagamentos (de acordo com a quantidade de cursos no
          carrinho), pois cada curso possui uma quantidade de parcelas com as
          taxas já calculadas.
        </Typography>
      </div>
    </div>
  );
};

export default ConfirmOrder;

const genCourseItem = ({ image, name, index, font_color }) => {
  return (
    <div
      key={index && index}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderRadius: "0.6rem",
        overflow: "hidden",
        paddingBottom: "0.4rem",
        flexGrow: 1,
      }}
    >
      <img
        src={STORAGE_URL + image}
        alt={removeHTML(name)}
        style={{
          width: "100%",
          height: "16rem",
          objectFit: "cover",
        }}
      />
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          margin: "0.8rem 1rem",
          fontSize: "1.1rem",
          textAlign: "center",

          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: font_color,
        }}
      >
        {removeHTML(name)}
      </Typography>
    </div>
  );
};

const paymentItem = (course, index, font_color) => {
  return (
    <div
      key={index && index}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderBottom: "0.1rem solid #c4c4c4",
        margin: "0.6rem 0",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
          width: "100%",
          gap: "0.4rem",
        }}
      >
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            alignItems: "center",
            display: "flex",
            color: font_color,
          }}
        >
          {removeHTML(course?.name)}
        </span>
        <span style={{ whiteSpace: "nowrap", display: "flex", gap: "0.6rem" }}>
          <span
            style={{
              padding: "0.1rem 0.6rem",
              margin: "0.2rem 0",
            }}
          >
            {course?.installments_quantity}x de{" "}
            {moneyMask(course?.installments_price)}
          </span>
          <span
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            ou
          </span>
          <span
            style={{
              padding: "0.1rem 0.6rem",
              margin: "0.2rem 0",
            }}
          >
            à vista: {moneyMask(course?.price)}
          </span>
        </span>
      </div>
    </div>
  );
};

import { Box, Container, Typography } from "@mui/material";
import Video from "components/Video";
import { useEffect, useState } from "react";
import { fileToBase64 } from "utils/images/fileToBase64";
import { STORAGE_URL } from "variables";

const HeaderSection = ({ props, style }) => {
  const {
    name,

    subtitle,

    coverImage = "",
    section1_bgColor_1,
    section1_bgColor_2,
    videoTrailer = null,
    section1_accentColor,

    submit_button_bgColor,
    submit_button_textColor,
    submit_button_text,

    submit_wait_text,
    submit_wait_helptext,

    existingVideoTrailer,

    image = "",
  } = props;

  const [imageSrc, setImageSrc] = useState("");
  const [coverImageSrc, setCoverImageSrc] = useState("");
  const [show_video_player, setShow_video_player] = useState(false);

  const [videoBase64, setVideoBase64] = useState(null);

  const renderVideo = async () => {
    if (videoTrailer[0]) {
      await fileToBase64(videoTrailer[0]).then((res) => {
        setVideoBase64(res);
      });
    }
  };

  useEffect(() => {
    renderVideo();
  }, [videoTrailer]);

  useEffect(() => {
    console.log("existingVideoTrailer", existingVideoTrailer[0]);
  }, [existingVideoTrailer]);

  useEffect(() => {
    if (videoBase64 !== null && videoBase64 !== undefined && videoBase64 !== "")
      return setShow_video_player(true);
    if (
      existingVideoTrailer[0] !== null &&
      existingVideoTrailer[0] !== undefined &&
      existingVideoTrailer[0] !== ""
    )
      return setShow_video_player(true);
    return setShow_video_player(false);
  }, [videoBase64, existingVideoTrailer]);

  useEffect(() => {
    // if (image === "" || image.length === 0) return;

    // let reader = new FileReader();
    // reader.onload = function (e) {
    //   setImageSrc(e.target.result);
    // };
    // try {
    //   reader.readAsDataURL(image);
    // } catch (error) {
    //   setImageSrc(`${STORAGE_URL}${image}`);
    // }

    if (image === "") return setImageSrc("");
    if (image === undefined || image === null) return;
    if (image.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(image);
    } catch (error) {
      setImageSrc(`${STORAGE_URL}${image}`);
    }
  }, [image]);

  useEffect(() => {
    if (coverImage === "") return setCoverImageSrc("");
    if (coverImage === undefined || coverImage === null) return;
    if (coverImage.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setCoverImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(coverImage);
    } catch (error) {
      setCoverImageSrc(`${STORAGE_URL}${coverImage}`);
    }
  }, [coverImage]);

  return (
    <section
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        padding: "2rem 0",
        // overflow: "hidden",
        position: "relative",
        background: `linear-gradient(180deg, ${section1_bgColor_1} 0%, ${section1_bgColor_2} 100%)`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem 0",
          position: "relative",
          zIndex: 10,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Seção 1
        </Typography>

        <Typography
          variant="h2"
          sx={{
            backdropFilter: "blur(1.4rem)",
            padding: "0.1rem 1rem",
            borderRadius: "0.8rem",
            textAlign: "center",
          }}
          dangerouslySetInnerHTML={{ __html: name }}
        />
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            backdropFilter: "blur(1.4rem)",
            padding: "0.1rem 1rem",
            borderRadius: "0.8rem",
          }}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />

        <Box
          sx={{
            maxHeight: "60vh",
            marginTop: "2rem",
            maxWidth: "80%",
            width: "100%",
            width: "max-content",
          }}
        >
          {show_video_player ? (
            <Video
              containerProps={{
                style: {
                  borderRadius: "0.8rem",
                  height: "100%",
                },
              }}
              src={videoBase64 || existingVideoTrailer[0]?.path}
              // src={existingVideoTrailer[0]?.path}
            />
          ) : (
            <img
              style={{
                borderRadius: "0.8rem",
                height: "100%",
              }}
              src={imageSrc}
            />
          )}
        </Box>

        {/* <div style={{ marginTop: "4rem" }}>
          <button type="button" className={style.getCourseButton}
            style={{
              backgroundColor: submit_button_bgColor,
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{ __html: submit_button_text }}
              sx={{
                color: submit_button_textColor,
                fontWeight: "bold",
              }}
            />
          </button>
        </div> */}

        <div style={{ display: "flex", flexDirection: "column", marginTop: '2rem' }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "1rem",
              textAlign: "center",
            }}
            dangerouslySetInnerHTML={{ __html: submit_wait_helptext }}
          />
          <button
            style={{
              padding: "1rem 4rem",
              borderRadius: "0.8rem",
              outline: "none",
              border: "none",
              fontSize: "1.2rem",
              fontWeight: "bold",
              color: submit_button_textColor,
              margin: "0 auto",
              backgroundColor: submit_button_bgColor,
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{ __html: submit_wait_text }}
              sx={{
                color: submit_button_textColor,
                fontWeight: "bold",
              }}
            />
          </button>
        </div>
      </div>
      {/* ghost image cover */}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 1,
          opacity: "0.5",
          top: "0",
          overflow: "hidden",
        }}
      >
        <img className={style.ghostImage} src={coverImageSrc} />
      </div>
    </section>
  );
};

export default HeaderSection;

import React, { useEffect, useState } from "react";

import Board from "./Board";

import { Get } from "utils/request";
import { CircularProgress } from "@mui/material";
import { Pagination } from "@mui/material";  
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { Show } from "actions/SnackbarActions";

const Table = props => {

    const {
        search = ""
    } = props;

    const [loading, SetLoading] = useState(false);

    const [page, SetPage] = useState(1);

    const dispatch = useDispatch();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location?.search);
    const { coupon_id } = useParams();
    const { data, isLoading, refetch } = useQuery(["courses-coupon", coupon_id, search], async () => await Get(`coupon/courses?id=${coupon_id}&search=${search}`));
    // const {
    //     unlinkedCourses,
    //     pagination: {
    //         unlinkedCourses: {
    //             lastPage: maxPage = 1
    //         } = { lastPage: 1 }
    //     } = {}
    // } = data || {};

    const { reverseCourses: unlinkedCourses, reverseCoursesPagination: { lastPage: maxPage = 1 } = { lastPage: 1 } } = data || {};


    useEffect(refetch, [urlSearchParams.get("t")]);
    
    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && unlinkedCourses?.map((group, key) => (
                            <Board
                                {...group}
                                key={key}
                                onUpdate={refetch}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="w100 flex jcc">
                <CircularProgress hidden={!isLoading}/>
                <p hidden={unlinkedCourses?.length !== 0 || isLoading}>
                    Nenhuma turma encontrada
                </p>
            </div>
            <Pagination
                className={(maxPage == 1 ? "hide" : "")}
                style={{display: "flex", justifyContent: "flex-end"}} 
                count={maxPage} 
                value={page}
                onChange={(_, value) => SetPage(value)}
            />
        </div>
    );
}

export default Table;
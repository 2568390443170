import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import TextCard from "../../TextCard";

const WhatWillGet = ({ props }) => {
  const {
    temp_show_section5,
    whatWillGet,
    temp_section5_title,
    temp_section5_bgColor_1,
    temp_section5_bgColor_2,
    temp_section5_cardColor,
    
    section5_cardBorderColor,
    section5_cardBorderSize,
  } = props;

  const [direction, setDirection] = useState(0);

  return (
    temp_show_section5 && (
      <section
        style={{
          padding: "6rem 0",
          // minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          background: `linear-gradient(180deg, ${temp_section5_bgColor_1} 0%, ${temp_section5_bgColor_2} 100%)`,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            color: "black",
          }}
        >
          Seção 4
        </Typography>
        <Container
          maxWidth="lg"
          sx={{ height: "100%!important", textAlign: "center" }}
        >
          <Typography
            sx={{
              marginBottom: "1rem",
            }}
            dangerouslySetInnerHTML={{ __html: temp_section5_title }}
          />

          <div
            style={{
              display: "flex",
              gap: "0.8rem",
              justifyContent: "center",
              // alignItems: "center",
              // flexWrap: "wrap",
              flexDirection: "column",
              marginTop: "2rem",
            }}
          >
            {whatWillGet !== undefined &&
              whatWillGet !== null &&
              whatWillGet.map((item, index) => (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    height: "min-content",
                    display: "flex",
                    flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                    gap: "1rem",
                    borderRadius: "0.8rem",
                    overflow: "hidden",
                    backgroundColor: temp_section5_cardColor,
                    border: `${section5_cardBorderColor} solid ${section5_cardBorderSize}px`,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.8rem",
                      width: "100%",
                      padding: "1rem",
                      paddingLeft: "2rem",
                      alignItems: index % 2 === 0 ? "flex-end" : "flex-start",
                      textAlign: 'justify',
                      // direction: index % 2 === 0 ? "rtl" : "ltr",
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        width: "max-content",
                      }}
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <Typography
                      component="div"
                      sx={{
                        // width: "max-content",
                      }}
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                  </div>
                  <div
                    style={{
                      height: "auto",
                      minHeight: "14rem",
                      backgroundImage: `url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                    }}
                  />
                </div>
              ))}
          </div>
        </Container>
      </section>
    )
  );
};

export default WhatWillGet;

import { Box, Container, Typography } from "@mui/material";
import Footer from "Home/Footer";
import { Header } from "modules/Header";
import { mainPages } from "modules/Header/data";
import { useQuery } from "react-query";
import { Get } from "utils/request";

import logo from 'assets/images/rt/main_logo_bgtransparent.png'
import WhatsappButton from "components/WhatsappButton";

const PolicyTerms = () => {
  const { data, isLoading } = useQuery(
    "policy-term",
    async () => await Get("policy-terms")
  );
  const { policy = {} } = data || {};

  const { text = "Sem políticas de privacidade" } = policy || {};

  return (
    <>
      <Container maxWidth="xl" sx={{padding: '4rem 0'}}>
        <Header pages={mainPages} />

        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
          <img src={logo} alt="RT Logotipo" style={{width: '16rem'}} />
        </div>
        <Typography
          variant="h4"
          style={{
            margin: '4rem 0',
            textAlign: 'center',
          }}
        >
          Políticas de privacidade
        </Typography>
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </Container>
      <WhatsappButton />
      <Footer />
    </>
  );
};

export default PolicyTerms;

import React, { useState } from "react";

import { FormControl as Input } from "react-bootstrap";
import DefaultButton from "../../../components/DefaultButton";

import { Show } from "../../../actions/SnackbarActions";

import Table from "./Table";
import { useDispatch } from "react-redux";

const FreeCourses = () => {
  const [search, SetSearch] = useState("");

  const dispatch = useDispatch();

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="title">Aulas Gratuitas</h3>
        <p className="text-muted">
          Aqui são listados todos as aulas gratuitas do sistema
        </p>
        <hr />
        <div className="flex jcsb flex-wrap margin-bottom">
          <Input
            placeholder="Pesquisar pelo Nome"
            value={search}
            onChange={(e) => SetSearch(e.target.value)}
            style={{ width: "50%" }}
          />
          <DefaultButton
            bg="confirm"
            text="Adicionar aula gratuita"
            to="/free-courses/novo"
          />
        </div>
        <Table search={search} />
      </div>
    </div>
  );
};

export default FreeCourses;

import { Box, Modal, Tooltip, Typography } from "@mui/material";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ErrorIcon from "@mui/icons-material/Error";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useEffect, useState } from "react";
import Orders from "pages/Users/Orders/Orders";
import CloseIcon from "@mui/icons-material/Close";
import { Get } from "utils/request";
import { removeHTML } from "utils/filters";

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  minWidth: "36rem",
  width: "85%",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
  padding: "2rem 3rem",
  justifyContent: "center",
  alignItems: "center",
};

const UserOrders = ({ style, user_role, user_id }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await Get(`orders/light?user_id=${user_id}`);
      setOrders(response.orders);
    };

    getData();
  }, []);

  return (
    user_role === "Aluno" && (
      <div className={style.profileInfo} style={{ flexGrow: 1 }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modal_style}>
            <CloseIcon
              sx={{
                position: "absolute",
                top: "0.4rem",
                right: "0.4rem",
                cursor: "pointer",
                color: "#000",
                fontSize: "2rem",
              }}
              onClick={handleClose}
            />
            <Orders {...{ user_id }} />
          </Box>
        </Modal>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="p"
            sx={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
          >
            Cursos comprados
          </Typography>
          <button type="button" className="btn btn-info" onClick={handleOpen}>
            Ver todos
          </button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.4rem",
          }}
        >
          {orders !== undefined &&
            orders.map((order, index) => (
              <div
                style={{
                  display: "flex",
                  minWidth: "14rem",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1">
                  {removeHTML(order.purchased_courses.join(", "))}
                </Typography>
                <Tooltip title={order.status}>
                  {order.status === "pending" ? (
                    <CurrencyExchangeIcon
                      sx={{
                        color: "#f59042",
                      }}
                    />
                  ) : order.status === "paid" ? (
                    <BeenhereIcon
                      sx={{
                        color: "#32a852",
                      }}
                    />
                  ) : (
                    <ErrorIcon
                      sx={{
                        color: "#ed2f2f",
                      }}
                    />
                  )}
                </Tooltip>
              </div>
            ))}

          {orders === undefined ||
            (orders.length === 0 && (
              <Typography variant="body1" sx={{ minWidth: "14rem" }}>
                Nenhum pedido realizado
              </Typography>
            ))}

          {/* <div
          style={{
            display: "flex",
            minWidth: "14rem",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1">Curso teste 1</Typography>
          <Tooltip title="Curso disponível para assistir">
            <BeenhereIcon
              sx={{
                color: "#32a852",
              }}
            />
          </Tooltip>
        </div>
        <div
          style={{
            display: "flex",
            minWidth: "14rem",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1">Curso teste 2</Typography>
          <Tooltip title="Aguardando pagamento">
            <CurrencyExchangeIcon
              sx={{
                color: "#f59042",
              }}
            />
          </Tooltip>
        </div>
        <div
          style={{
            display: "flex",
            minWidth: "14rem",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1">Curso teste 3</Typography>
          <Tooltip title="Entre em contato com o suporte">
            <ErrorIcon
              sx={{
                color: "#ed2f2f",
              }}
            />
          </Tooltip>
        </div>  */}
        </div>
      </div>
    )
  );
};

export default UserOrders;

import React, { useEffect, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "components/DefaultButton";
import { Get } from "utils/request";
import { useParams } from "react-router";

const Formbody = props => {

    const {
        loading,
        OnConfirm
    } = props;

    const [name, SetName] = useState("");
    const [description, SetDescription] = useState("");

    const { group_id } = useParams();

    const GetData = async () => {
        let response = await Get(`groups?id=${group_id}`);
         
        if (response?.status === true) {
            SetName(response?.group?.name);
            SetDescription(response?.group?.description);
        }
    }

    useEffect(GetData, []);

    return(
        <div className="form-course">
            <form>
                <FormLabel>Nome <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                    placeholder="Nome"
                    value={name}
                    onChange={e => SetName(e.target.value)}
                    required
                />
                <br/>

                <FormLabel>Descrição</FormLabel>
                <Input
                    placeholder="Descrição"
                    value={description}
                    onChange={e => SetDescription(e.target.value)}
                    as="textarea"
                />
                <br/>
            </form>
            <br/>
            <div className="w100 inline-flex jcsb mt-2">
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to="/groups/list"
                />
                <DefaultButton
                    bg="confirm"
                    text="Salvar turma"
                    onClick={() => {
                        let data = {
                            name: name,
                            description: description
                        };
                        OnConfirm(data);
                    }}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default Formbody;
import { Box, Button } from "@mui/material";
import DefaultButton from "components/DefaultButton";
import { createContext, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import VideoCard from "./Card";

import styles from "./index.module.css";

export const VideosContext = createContext([]);

const InputVideo = props => {

    const {
        initialValue = [],
        multiple = false,
        onChange = () => {},
        ...others
    } = props;

    const [videos, SetVideos] = useState([]);
    const [videosFiles, SetVideosFiles] = useState([]);
    const [videosThumbs, SetVideosThumbs] = useState([]);

    const videoInput = useRef();

    const HandleInput = () => {
        const files = videoInput.current.files;
        
        if (!multiple) {
            const file = files[files?.length - 1]
            console.log(file)
            SetVideos([{
                id: v4(),
                name: file?.name,
                file: file,
                thumbnail: undefined
            }]);
            videoInput.current.value=null;
            return;
        }
        
        const tempVideos = [...videos, ...Array.from(files).map(file => ({
            id: v4(),
            name: file?.name,
            file: file,
            thumbnail: undefined
        }))];

        SetVideos([...tempVideos]);
        videoInput.current.value=null;
    }

    const OnVideoUpdate = () => {
        const tempVideosThumbs = [...videos.map(video => video?.thumbnail)];
        const tempVideosFiles = [...videos.map(video => video?.file)];
        SetVideosThumbs([...tempVideosThumbs]);
        SetVideosFiles([...tempVideosFiles]);
        onChange({
            general: videos,
            thumbs: [...tempVideosThumbs],
            videos: [...tempVideosFiles]
        });
    }

    useEffect(OnVideoUpdate, [videos]);

    useEffect(() => {
        if (!initialValue || initialValue.length == 0) return;
        const tempVideos = [...initialValue?.map(x => ({
            id: x?.id,
            name: x?.name,
            file: x?.file || null,
            thumbnail: x?.thumbnail
        }))];
        SetVideos([...tempVideos]);
    }, [initialValue]);

    return (
        <Box>
            <input
                type="file"
                accept="video/*"
                className={styles.input}
                multiple={multiple}
                ref={videoInput}
                onChange={HandleInput}
                hidden
            />
            <VideosContext.Provider value={{videos, SetVideos}}>
                <Box
                    display={"flex"}
                    flexWrap={"wrap"}
                >
                    {videos?.map((video, key) => (
                        <VideoCard
                            key={key}
                            {...video}
                        />
                    ))}
                </Box>
            </VideosContext.Provider>
            <DefaultButton
                text={multiple ? "Selecionar vídeos" : "Selecionar vídeo"}
                bg="primary"
                onClick={() => videoInput.current.click()}
                style={{ marginBottom: "10px" }}
            />
        </Box>
    );
}

export default InputVideo;
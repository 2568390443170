import React from "react";
import ProfileMenu from "./ProfileMenu";
import { URL } from "./variables";
import { connect } from "react-redux";
import Logo from "./assets/images/RTLogo.jpeg";
import ListIcon from "@mui/icons-material/List";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useEffect } from "react";
import { useState } from "react";
import { Box } from "@mui/system";

const Header = (props) => {
  const {
    handleToggleSidebar,
    handleImageChange,
    handleCollapsedChange = () => {},
    toggled,
    collapsed,
    ...other
  } = props;

  const marginLeft =
    toggled == false || window.innerWidth <= 768
      ? 0
      : collapsed == false
      ? 270
      : 80;

  return (
    <>
      <div className="topbar" {...other}>
        <nav
          className="navbar-custom d-flex justify-content-between align-items-center"
          style={{ marginLeft: marginLeft, transition: "all 0.3s " }}
        >
          <Box
            sx={{
              background: "transparent",
              cursor: "pointer",
              color: "white",
              marginLeft: "1.2rem",
              borderRadius: "0.4rem",
              transform: "scale(1.2)",
              '&:hover': {
                background: "rgba(255,255,255,0.15)",
              }
            }}
            onClick={() => {
              if (window.innerWidth <= 768) {
                handleToggleSidebar(!toggled);
              } else {
                handleCollapsedChange(!collapsed);
              }
            }}
          >
            {collapsed ? (
              <>
                <ListIcon sx={{
                  marginLeft: "0.4rem"
                }} />
                <KeyboardArrowLeftIcon sx={{
                  transform: "rotate(180deg)"
                }} />
              </>
            ) : (
              <>
                <KeyboardArrowLeftIcon />
                <ListIcon sx={{
                  marginRight: "0.4rem"
                }} />
              </>
            )}
          </Box>
          <ul className="navbar-right d-flex list-inline float-right mb-0">
            <ProfileMenu />
          </ul>
        </nav>
      </div>
      <nav className="navbar-custom" />
    </>
  );
};

export default Header;

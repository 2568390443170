import { Box, Typography } from "@mui/material";
import { Secs2Minutes, Secs2Time } from "utils/transformations";

import CollapseContainer from "components/CollapseContainer";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ModuleCard from "./ModuleCard";

const CourseContent = (props) => {
  const { modules } = props;

  return (
    <div style={{width: '100%'}}>
      {/* <Typography variant="body2" style={{ fontSize: "1rem" }}>
        {modules?.length} Módulos
      </Typography> */}

      <div style={{display: 'flex', flexWrap: 'wrap', gap: '4rem', justifyContent: 'center'}}>
        {modules?.map((module) => (
          <ModuleCard key={module?.id} module={module} />
        ))}
      </div>

      {/* <div style={{ marginBottom: 20 }}></div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {modules?.map((module) => (
          <div key={module?.id}>
            <CollapseContainer
              title={module?.name}
              label={`${module?.lessons?.reduce(
                (result, lesson) => result + lesson?.filesCount,
                0
              )} aulas • ${Secs2Minutes(module?.duration)}min`}
            >
              {module?.lessons?.map((lesson) => (
                <div>
                  <div>
                    <p>{lesson.title}</p>
                  </div>
                  {lesson?.files?.files?.map((file) => (
                    <Box
                      key={file?.id}
                      display="inline-flex"
                      justifyContent="space-between"
                      padding="0.5em"
                      width="100%"
                    >
                      <div className="text-truncate">
                        {file?.type === "video" ? (
                          <PlayCircleFilledIcon
                            sx={{ fontSize: "1.4rem", marginRight: "0.4rem" }}
                          />
                        ) : (
                          <InsertDriveFileIcon
                            sx={{ fontSize: "1.4rem", marginRight: "0.4rem" }}
                          />
                        )}
                        {file?.name}
                      </div>
                      <div>{Secs2Time(file?.duration)}</div>
                    </Box>
                  ))}
                </div>
              ))}
            </CollapseContainer>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default CourseContent;

import { Box, Card, CardContent, CardMedia, Typography, useMediaQuery, useTheme } from "@mui/material";
import CarouselSlider from "components/CarouselSlider";
import Video from "components/Video";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Get } from "utils/request";
import { STORAGE_URL } from "variables";

import styles from "./index.module.css";
import placeholder from "assets/images/image_placeholder.png";

const VideoCarousel = props => {

    const {
        ...others
    } = props;

    const theme = useTheme();
    const matchSm = useMediaQuery(theme.breakpoints.down("sm"))

    return (
        <Box
            bgcolor={"primary.main"}
            padding={"2em 0"}
            color="primary.contrastText"
            {...others}
        >
            <Typography
                variant="h4"
                align="center"
            >
                Conhecimento para todos - cursos gratuítos
            </Typography>
            <CarouselSlider
                swiperProps={{
                    style: {
                        padding: "20px",
                        paddingBottom: "30px"
                    },
                    breakpoints: {
                        "1200": {
                            slidesPerView: 2
                        },
                        "600": {
                            slidesPerView: 1
                        }
                    },
                    loop: false
                }}
            >
                {new Array(5).fill(undefined).map((course, courseKey) => (
                    <Card 
                        key={courseKey}
                        className={styles.card}
                    >
                        <CardMedia
                            component="img"
                            image={placeholder}
                            style={{
                                width: "min(480px, 70vw)"
                            }}
                        />
                        <CardContent>
                            <Typography
                                variant="h6"
                                align="center"
                            >
                                Nome do curso
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </CarouselSlider>
        </Box>
    );
}

export default VideoCarousel;
import React from "react";
import { FormGroup } from "@mui/material";
import ColorInput from "components/Inputs/ColorInput";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import style from "../style.module.css";
import ImageInput from "components/Inputs/ImageInput";
import Label from "components/InfoLabel";

const FormBody = (props) => {
  const {
    background_color,
    SetBackgroundColor = () => {},
    banner,
    SetBanner = () => {},
    aside_banner,
    SetAsideBanner = () => {},
    fontColor,
    SetFontColor = () => {},
    accent_color,
    SetAccentColor = () => {},
    card_color,
    SetCardColor = () => {},
    handleSubmit,
    save_button_status,
    cover_image,
    SetCoverImage = () => {},
    handleCloseModal,
  } = props;

  return (
    <div>
      <form>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <FormLabel>
            Imagem a ser mostrada na página de cursos
            <ImageInput
              path={cover_image}
              ImageChange={(image) => SetCoverImage(image)}
            />
          </FormLabel>

          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
              textAlign: "left",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos
            recomendados: Qualquer um na proporção de tela 22:9
          </span>
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos:
            .jpg, .jpeg, .png e .gif.
          </span>
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de
            tamanho para o arquivo.
          </span>

          <FormLabel>
            Banner da página de checkout
            <ImageInput
              path={banner}
              ImageChange={(image) => SetBanner(image)}
            />
          </FormLabel>

          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
              textAlign: "left",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos
            recomendados: Qualquer um na proporção de tela 22:9
          </span>
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos:
            .jpg, .jpeg, .png e .gif.
          </span>
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de
            tamanho para o arquivo.
          </span>

          <FormLabel>
            Banner lateral da página de checkout
            <ImageInput
              path={aside_banner}
              ImageChange={(image) => SetAsideBanner(image)}
            />
          </FormLabel>

          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
              textAlign: "left",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos
            recomendados: Qualquer um na proporção de tela 9:22
          </span>
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos:
            .jpg, .jpeg, .png e .gif.
          </span>
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de
            tamanho para o arquivo.
          </span>
        </div>

        <FormGroup
          sx={{
            marginTop: "2rem",
          }}
        >
          <Label
            label="Cor de fundo principal"
            required
            title="A cor que irá aparecer no fundo da página"
          />
          <ColorInput
            value={background_color}
            onChange={(color) =>
              color !== undefined && SetBackgroundColor(color)
            }
          />
          <br />
          <Label
            label="Cor de fundo do cartão"
            required
            title="A cor que irá aparecer no fundo do cartão"
          />
          <ColorInput
            value={card_color}
            onChange={(color) => color !== undefined && SetCardColor(color)}
          />
          <br />
          <Label
            label="Cor de destaque"
            required
            title="A cor que irá aparecer em destaque"
          />
          <ColorInput
            value={accent_color}
            onChange={(color) => color !== undefined && SetAccentColor(color)}
          />
          <br />

          <Label
            label="Cor da fonte"
            required
            title="Essa cor irá mudar a cor da fonte da página"
          />
          <ColorInput
            value={fontColor}
            onChange={(color) => {
              color !== undefined && SetFontColor(color);
            }}
          />
          <br />
        </FormGroup>
      </form>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1rem",
          marginTop: "1rem",
        }}
      >
        <div
          className={style.hidePreviewButton}
          style={{
            background: "#eb9d9d",
          }}
          onClick={() => handleCloseModal()}
        >
          Fechar
        </div>
        <div
          className={style.hidePreviewButton}
          style={{
            background: "#a6eda1",
          }}
          onClick={() => handleSubmit()}
        >
          {save_button_status.icon} {save_button_status.status}
        </div>
      </div>
    </div>
  );
};

export default FormBody;

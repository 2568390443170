import React, { useEffect, useMemo, useState } from "react";
import { PAGE_KEYS, STORAGE_URL } from "variables";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import LoginIcon from "@mui/icons-material/Login";
import {
  Checkbox,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
import module_style from "./index.module.css";
import "./ChangePasswordBody.css";
import { ThemeProvider } from "@mui/system";
import { useSelector } from "react-redux";
import { Get } from "utils/request";
import { useQuery } from "react-query";
import SweetAlert from "react-bootstrap-sweetalert";

const ChangePasswordBody = (props) => {
  const {
    onSubmit,
    errors = [],
    link = null,
    loading = false,
    ...other
  } = props;

  const [popup, SetPopup] = useState(false);
  // const [popupMessage, SetPopupMessage] = useState("");

  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [confirmPassword, SetConfirmPassword] = useState("");
  const [showPassword, SetShowPassword] = useState(false);
  const [submitting, SetSubmitting] = useState(false);

  const [mui_theme, setMuiTheme] = useState({});

  const loginTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.login
  );

  useEffect(() => {
    setMuiTheme(loginTheme?.palette?.data);
  }, [loginTheme]);

  const [remember, SetRemember] = useState(false);

  const [showEmailError, SetShowEmailError] = useState(false);
  const [showPasswordError, SetShowPasswordError] = useState(false);

  const location = useLocation();
  const history = useHistory();

  //   const { isLoading } = useQuery(
  //     "login",
  //     async () => {
  //       const response = await Get(`styles/pages/key/${PAGE_KEYS.login}`);
  //       return response;
  //     },
  //     {
  //       refetchOnWindowFocus: false,
  //       onSuccess: (data) => {
  //         console.log(data)
  //         // SetPrimaryColor(data.primary_color  ?? "#000");
  //         // SetSecondaryColor(data?.secondary_color ?? "#000");
  //         // SetFontColor(data?.font_color ?? "#000");
  //       }
  //     }
  //   )

  const HandleSubmitButton = (e) => {
    e.preventDefault();
    // SetShowPasswordError(!password);
    if (password === "" || confirmPassword === "" || password !== confirmPassword) {
      SetPopup(true)
      return;
    }

    let data = {
      password: password,
      password_confirmation: confirmPassword,
    };

    onSubmit(data);
  };
  const user = useSelector((store) => store.AppReducer.user);

  useEffect(
    () =>
      location?.state &&
      location?.state?.email &&
      SetEmail(location.state.email),
    []
  );

  // foca o input da div
  const focusInput = (e) => {
    e.currentTarget.querySelector("input").focus();
  };

  return (
    <div
      style={{
        backgroundColor: mui_theme?.section_main_color,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "calc(100vh - 4rem)",
        width: "100vw",
      }}
    >
      <SweetAlert
        warning
        show={popup}
        title="Atenção!"
        confirmBtnText="Ok"
        onConfirm={() => SetPopup(false)}
      >
        Preencha corretamente as senhas.
      </SweetAlert>
      <Box
        sx={{
          display: "flex",
          margin: "auto",
          borderRadius: "0.4rem",
          overflow: "hidden",
          boxShadow: { xs: "none", md: "0 1rem 2rem rgba(0, 0, 0, 0.1)" },
        }}
      >
        <Box
          sx={{
            backgroundColor: mui_theme?.section_right_color,
            padding: "4rem",
            width: { xs: "100%!important", md: "34rem!important" },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: mui_theme?.font_color,
            }}
          >
            Seja bem-vindo(a)!
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: mui_theme?.font_color }}
          >
            É necessário que você altere sua senha para continuar.
          </Typography>

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.4rem",
              marginTop: "2rem",
            }}
          >
            <div
              className={module_style.FormInputContainer}
              style={{
                borderBottom: `0.1rem solid ${mui_theme?.input_color}`,
                backgroundColor: mui_theme?.input_bg_color,
              }}
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <HttpsIcon sx={{ color: mui_theme?.input_color }} />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Senha"
                value={password}
                onChange={(e) => {
                  SetPassword(e.target.value);
                }}
                style={{
                  color: mui_theme?.input_color,
                }}
              />
              <IconButton
                onClick={() => SetShowPassword(!showPassword)}
                sx={{
                  padding: 0,
                  color: mui_theme?.input_color,
                }}
              >
                {!showPassword ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon sx={{ color: "#ffac1c" }} />
                )}
              </IconButton>
            </div>

            <div
              className={module_style.FormInputContainer}
              style={{
                borderBottom: `0.1rem solid ${mui_theme?.input_color}`,
                backgroundColor: mui_theme?.input_bg_color,
              }}
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <HttpsIcon sx={{ color: mui_theme?.input_color }} />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Confirmar senha"
                value={confirmPassword}
                onChange={(e) => {
                  SetConfirmPassword(e.target.value);
                }}
                style={{
                  color: mui_theme?.input_color,
                }}
              />
              <IconButton
                onClick={() => SetShowPassword(!showPassword)}
                sx={{
                  padding: 0,
                  color: mui_theme?.input_color,
                }}
              >
                {!showPassword ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon sx={{ color: "#ffac1c" }} />
                )}
              </IconButton>
            </div>

            <Alert severity="error" hidden={errors.length == 0}>
              {errors[0]}
              {link}
            </Alert>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <div className="flex fdrow align-center text-muted">
                <Checkbox
                  className="text-muted"
                  value={remember}
                  onChange={(e) => SetRemember(e.target.checked)}
                />
                <span style={{ color: mui_theme?.font_color }}>
                  Lembre-se de mim
                </span>
              </div>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: mui_theme?.accent_color,
                  color: mui_theme?.font_color,
                  padding: "0.5rem 1.6rem",
                  "&:hover": {
                    backgroundColor: mui_theme?.accent_color,
                    filter: "brightness(0.9)",
                  },
                }}
                onClick={HandleSubmitButton}
              >
                {submitting ? (
                  <CircularProgress
                    size={20}
                    style={{
                      color: mui_theme?.font_color,
                      marginRight: "0.5rem",
                    }}
                  />
                ) : (
                  <LoginIcon style={{ marginRight: "0.5rem" }} />
                )}
                Alterar senha
              </Button>
            </div>
            <Box
              marginTop={2}
              width={"100%"}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <a
                onClick={() => history.push("/terms")}
                style={{ cursor: "pointer", color: mui_theme?.font_color }}
              >
                Termos de uso
              </a>
              <span>&nbsp;•&nbsp;</span>
              <a
                onClick={() => history.push("/policy-terms")}
                style={{ cursor: "pointer", color: mui_theme?.font_color }}
              >
                Políticas de privacidade
              </a>
            </Box>
          </form>
        </Box>
      </Box>
    </div>
    // <Box className="login-body">
    //   <div className="login-content" style={{ backgroundColor: "black" }}>
    //     <h1 className="text-white" style={{ marginBottom: 0 }}>
    //       Altere sua senha
    //     </h1>
    //     <p className="text-white" style={{ marginBottom: 28 }}>
    //       Sua senha deve ser alterada em seu primeiro acesso
    //     </p>
    //     <br />

    //     <form className="login-main-form">
    //       <TextField
    //         variant="filled"
    //         label="E-mail"
    //         value={user?.email}
    //         disabled
    //         error={showEmailError}
    //         helperText={showEmailError ? "Insira um e-mail!" : ""}
    //         className="w100"
    //         style={{
    //           backgroundColor: "white",
    //         }}
    //       />
    //       <TextField
    //         variant="filled"
    //         label="Senha"
    //         type="password"
    //         value={password}
    //         onChange={(e) => SetPassword(e.target.value)}
    //         error={showPasswordError}
    //         helperText={showPasswordError ? "Insira uma senha!" : ""}
    //         className="w100"
    //         style={{
    //           backgroundColor: "white",
    //         }}
    //       />
    //       <TextField
    //         variant="filled"
    //         label="Confirmar Senha"
    //         type="password"
    //         value={confirmPassword}
    //         onChange={(e) => SetConfirmPassword(e.target.value)}
    //         error={showPasswordError}
    //         helperText={showPasswordError ? "Insira uma senha!" : ""}
    //         className="w100"
    //         style={{
    //           backgroundColor: "white",
    //         }}
    //       />
    //       <Button
    //         onClick={HandleSubmitButton}
    //         disabled={loading}
    //         variant="contained"
    //         className={"w100"}
    //         color={"primary"}
    //       >
    //         Alterar
    //       </Button>
    //     </form>
    //     <Alert severity="error" hidden={errors.length == 0}>
    //       {errors[0]}
    //       {link}
    //     </Alert>
    //     <Box color={"white"} marginTop={2} width={"100%"}>
    //       <a
    //         onClick={() => history.push("/terms")}
    //         style={{ cursor: "pointer", color: "white" }}
    //       >
    //         Termos de uso
    //       </a>
    //       <span>&nbsp;•&nbsp;</span>
    //       <a
    //         onClick={() => history.push("/policy-terms")}
    //         style={{ cursor: "pointer", color: "white" }}
    //       >
    //         Políticas de privacidade
    //       </a>
    //     </Box>
    //   </div>
    // </Box>
  );
};

export default ChangePasswordBody;

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  createTheme,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { maskPrice } from "Auxiliar/Masks";
import { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Post } from "utils/request";
import { STORAGE_URL } from "variables";
import { Show } from "actions/SnackbarActions";

import style from "../style.module.css";
import LeadsCourse from "../Sections/LeadsCourse";
import Formbody from "../Formbody";
import Register from "pages/Register";

const Form = (props) => {
  const { image, price, inDateRange, course, color, ...other } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open_login_modal, setOpenLoginModal] = useState(false);

  const { course_id } = useParams();
  const userId = useSelector((store) => store.AppReducer.user?.id);
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));

  const innerTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: theme?.palette?.info,
        },
      }),
    [theme]
  );

  const Submit = async (e) => {
    // if (user?.role === undefined || user?.role === null) {
    //   return setOpenLoginModal(true);
    // }

    history.push(`/courses/payment/${course_id}`);
  };

  return (
    <>
      <Modal open={open_login_modal} onClose={() => setOpenLoginModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "50rem",
            display: "flex",
            flexDirection: "column",
            borderRadius: "0.6rem",
            backgroundColor: "transparent",
            width: "max-content",
          }}
        >
          <Register course_id={course_id} />
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "50rem",
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            borderRadius: "0.6rem",
            p: 4,
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center", color: "black" }}>
            Inscreva-se para receber novidades sobre este curso!
          </Typography>
          <Typography
            variant="h6"
            sx={{ mb: 4, mt: 2, textAlign: "center", color: "black" }}
          >
            Não vamos encher sua caixa de entrada com spam.
          </Typography>
          <Formbody course_id={course_id} />
        </Box>
      </Modal>

      <ThemeProvider theme={innerTheme}>
        {inDateRange == true && (
          <div style={{ marginTop: "4rem" }}>
            <button
              type="button"
              className={style.getCourseButton}
              style={{
                backgroundColor: props?.submit_button_bgColor,
              }}
              onClick={Submit}
            >
              <Typography
                dangerouslySetInnerHTML={{ __html: props?.submit_button_text }}
                sx={{
                  color: props?.submit_button_textColor,
                  fontWeight: "bold",
                }}
              />
            </button>
          </div>
        )}
        {inDateRange == false && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h6"
              style={{
                marginBottom: "1rem",
              }}
              dangerouslySetInnerHTML={{ __html: props?.submit_wait_helptext }}
            />
            <button
              className={style.getCourseButton}
              style={{
                padding: "1rem 4rem",
                borderRadius: "0.8rem",
                outline: "none",
                border: "none",
                fontSize: "1.2rem",
                fontWeight: "bold",
                color: props?.submit_button_textColor,
                backgroundColor: props?.submit_button_bgColor,
              }}
              onClick={handleOpen}
            >
              <Typography
                dangerouslySetInnerHTML={{ __html: props?.submit_wait_text }}
                sx={{
                  color: props?.submit_button_textColor,
                  fontWeight: "bold",
                }}
              />
            </button>
          </div>
        )}
      </ThemeProvider>
    </>
  );
};

export default Form;

import {
  Drawer,
  Toolbar,
  List,
  Grid,
  ListItem,
  useMediaQuery,
  Menu,
  MenuList,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";
import { removeHTML } from "utils/filters";

import './index.css'

const TopNavigation = (props) => {
  const { open, items = [], ...other } = props;

  const [activeItems, SetActiveItems] = useState([]);
  const [anchorEl, SetAnchorEl] = useState(null);

  const history = useHistory();

  const HandleLinkClick = (link) => history.push(link);

  const HandleMenuToggle = (event, items) => {
    if (activeItems?.length != 0) {
      SetActiveItems([]);
      SetAnchorEl(null);
    } else {
      if (!items) return;
      SetActiveItems(items);
      SetAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <Drawer {...other} anchor={"top"} open={items.length != 0 ? open : false}>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "rgb(240, 244, 244)",
            // margin: '0 auto',
            // left: '4.2rem',
            // marginTop: "5rem",
          }}
        >
          {items?.map((item, itemKey) => (
            <ListItem button onClick={(e) => HandleMenuToggle(e, item?.items)}>
              {item.label}
            </ListItem>
            // <span>teste</span>
          ))}
        </List>
      </Drawer>
        {/* <Toolbar /> */}
      <Menu
        open={activeItems?.length != 0}
        anchorEl={anchorEl}
        onClose={HandleMenuToggle}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        //getContentAnchorEl={null}
        PaperProps={{
          elevation: 20,
          style: {
            minWidth: "30vw",
          },
        }}
      >
        <MenuList>
          {activeItems?.map((item, itemKey) => (
            <MenuItem onClick={() => HandleLinkClick(item?.link)} key={itemKey}>
              {removeHTML(item?.label)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

export default TopNavigation;

import { useEffect, useState } from "react";
import { ButtonBase, Checkbox, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./index.module.css";
import { useSelector } from "react-redux";

const CollapseContainer = (props) => {
  let {
    hideExpandLabel = false,
    hideIcon = false,
    label = "",
    title,
    children,
    className,
    className2 = "",

    style_title = {},
    ComponentCheck = () => {},
    ...other
  } = props;

  const [expanded, SetExpanded] = useState(false);
  const [mui_theme, setMuiTheme] = useState({});

  const headerTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.header
  );

  useEffect(() => {
    setMuiTheme(headerTheme?.palette?.data);
  }, [headerTheme]);

  return (
    <div className="collapseContainer">
      <ButtonBase
        className={`flex w100 jcsb align-center ${styles.expandTab} ${className}`}
        onClick={() => SetExpanded(!expanded)}
        {...other}
      >
        {Object.keys(style_title).length > 0 && (
          <p className={styles.title} style={style_title}>
            {title}
          </p>
        )}
        {Object.keys(style_title).length == 0 && (
          <span className={styles.title} style={style_title}>
            {title}
          </span>
        )}

        <span
          className="flex fdrow"
          style={{
            userSelect: "none",
            cursor: "pointer",
          }}
        >
          <div className={styles.button} hidden={hideExpandLabel || hideIcon}>
            {label ? label : !expanded ? "Mostrar mais" : "Mostrar menos"}
          </div>
          {hideIcon == false && (
            <ExpandMoreIcon
              className={`${expanded ? styles.expandedIcon : ""} ${
                styles.icon
              }`}
            />
          )}
          {hideIcon == true && ComponentCheck()}
        </span>
      </ButtonBase>
      <Collapse in={expanded} sx={{
        borderRadius: '0.4rem',
        overflow: 'hidden',
        background: `linear-gradient(90deg, ${mui_theme?.background_color_1} 0%, ${mui_theme?.background_color_2} 100%)`,
      }}>{children}</Collapse>
    </div>
  );
};

export default CollapseContainer;

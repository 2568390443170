import Form from "./Form";
import CommentsList from "./List";

const Comments = (props) => {
  return (
    <>
      <Form />
      <CommentsList />
    </>
  );
};

export default Comments;

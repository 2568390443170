import { Box } from "@mui/material";
import { maskPrice } from "Auxiliar/Masks";
import { useEffect, useState } from "react";
import { FormControl as Input } from "react-bootstrap";
import { HandlePercentInput, PercentageMask } from "utils/filters";

import styles from "./index.module.css";

const DiscountInput = props => {

    const {
        value = "0%",
        mode = "percentage",
        onChange = () => {}
    } = props;

    const [discountMode, SetDiscountMode] = useState(mode);
    
    const UpdateValue = () => {
        if (discountMode == "percentage") onChange([PercentageMask(value), discountMode])
        else if (discountMode == "amount") onChange([maskPrice(value), discountMode]);
    }

    useEffect(() => SetDiscountMode(mode), [mode]);
    useEffect(() => UpdateValue(), [discountMode]);

    return (
        <Box
            display={"inline-flex"}
            alignItems={"center"}
            width="100%"
        >
            <select
                className={`form-control ${styles.selectInput}`}
                value={discountMode}
                onChange={(e) => SetDiscountMode(e.target.value)}
            >
                <option value={"percentage"}>%</option>
                <option value={"amount"}>R$</option>
            </select>
            <Input
                placeholder="Valor de desconto"
                value={value}
                onKeyDown={e => {
                    if (discountMode == "percentage") onChange([HandlePercentInput(e), discountMode]);
                }}
                onChange={e => {
                    if (discountMode == "amount") onChange([maskPrice(e.target.value), discountMode]);
                }}
                className={styles.input}
                required
            />
        </Box>
    );
}

export default DiscountInput;

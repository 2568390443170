import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { blue, purple, red } from "@mui/material/colors";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import useUserActivity from "./useUserActivity";

const UserActivity = () => {
  const theme = useTheme();
  const { period, data, setPeriod } = useUserActivity();

  return (
    <Box
      component={Card}
      marginTop={theme.spacing(0.5)}
      raised
      position={"relative"}
      sx={{
        borderRadius: "1rem",
        overflow: "hidden",
        boxShadow: "0 0 0 0.1rem rgb(43 43 43 / 20%)",
        backgroundColor: "rgb(240, 244, 244)",
        overflow: "visible",
      }}
    >
      <TopIcon />
      <Box
        component={CardContent}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <FormControl
          style={{
            alignSelf: "flex-start",
            marginTop: '0.4rem'
          }}
        >
          <InputLabel>Periodicidade</InputLabel>
          <Select
            onChange={(e) => setPeriod(e.target.value)}
            value={period}
            size='small'
            sx={{marginBottom: '1rem'}}
          >
            <MenuItem value={0}>Semanal</MenuItem>
            <MenuItem value={1}>Mensal</MenuItem>
            <MenuItem value={2}>Anual</MenuItem>
          </Select>
        </FormControl>
        <ResponsiveContainer width={"100%"} height={300}>
          <LineChart width={700} height={230} data={data}>
            <CartesianGrid strokeDasharray={"3.3"} />
            <XAxis dataKey={"date"} />
            <YAxis />
            <Tooltip />
            <Legend iconType={"plainline"} />
            <Line
              type={"monotone"}
              dataKey={"dailyViews"}
              stroke={blue[700]}
              name={"Acessos de alunos ao sistema"}
              dot={false}
            />
            <Line
              type={"monotone"}
              dataKey={"newUsers"}
              stroke={purple[700]}
              name={"Novas contas do sistema"}
              dot={false}
            />
            <Line
              type={"monotone"}
              dataKey={"userCount"}
              stroke={red[500]}
              name={"Quantidade de contas no sistema"}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

const TopIcon = () => {
  return (
    <Box
      position={"absolute"}
      bgcolor={purple["600"]}
      color={"white"}
      padding={1}
      borderRadius={5}
      top={-15}
      right={20}
    >
      <TrendingUpIcon />
    </Box>
  );
};

export default UserActivity;

import React, { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "components/DefaultButton";
import FormCard from "./FormCard";
import ImageInput from "components/Inputs/ImageInput";
import "../../../../../assets/css/styles.css";

const Formbody = (props) => {
  const { loading, OnConfirm } = props;

  const [title, SetTitle] = useState("");
  const [description, SetDescription] = useState("");
  const [link, SetLink] = useState("");
  const [image, SetImage] = useState("");

  return (
    <div className="form-course">
      <form>
        <FormLabel>
          Imagem da referência <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <ImageInput ImageChange={(image) => SetImage(image)} />
        <br />

        <FormLabel>
          Título <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Título"
          value={title}
          onChange={(e) => SetTitle(e.target.value)}
          required
        />
        <br />

        <FormLabel>
          Link <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Link"
          value={link}
          onChange={(e) => SetLink(e.target.value)}
          required
        />
        <br />

        <FormLabel>Descrição</FormLabel>
        <Input
          placeholder="Descrição"
          value={description}
          onChange={(e) => SetDescription(e.target.value)}
          required
          as="textarea"
        />
        <br />
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to="/science-plus/references/list"
        />
        <DefaultButton
          bg="confirm"
          text="Criar referência"
          onClick={() => {
            let data = {
              title: title,
              description: description,
              link: link,
              image: image,
            };
            OnConfirm(data);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Formbody;

import { Pagination } from "@mui/material";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const Paginate = props => {

    const {
        maxPage,
        value,
        label = "pg",
        onChange = () => {},
        ...others
    } = props;

    const history = useHistory();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location?.search);

    useEffect(() => onChange(parseInt(urlSearchParams.get(label) || 0)), []);

    if (maxPage == 1) return null;
    return (
        <Pagination
            data-page={value}
            style={{ display: "flex", justifyContent: "center" }} 
            count={maxPage}
            value={value}
            onChange={(_, value) => {
                urlSearchParams.set(label, value);
                history.push({
                    search: `?${urlSearchParams.toString()}`
                });
                onChange(value);
            }}
            {...others}
        />
    );
}

export default Paginate
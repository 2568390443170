import { useState } from "react";
import style from "./style.module.css";
import FormPaginaInicial from "./Form";
import PreviewAboutUsPage from "../Preview/AboutUsPage";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";

// mui icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import { Get, Post } from "utils/request";
import { useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import logo_loading from "assets/images/rt/icon_transparent.png";

const ConfigSobreNos = () => {
  const [hidePreview, setHidePreview] = useState(false);
  const dispatch = useDispatch();

  const [is_loading, set_is_loading] = useState(true);

  const [save_button_status, setSaveButtonStatus] = useState({
    status: "Salvar tudo",
    icon: <SaveIcon />,
  });

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [statisticsTitle, setStatisticsTitle] = useState("");
  const [statisticsText, setStatisticsText] = useState("");
  const [about_us_bg_image, setAboutUsBgImage] = useState("");
  const [about_us_bg_gradient, setAboutUsBgGradient] = useState(true);

  const [joditIntroText, setJoditIntroText] = useState("");

  const [joditMissionText, setJoditMissionText] = useState("");
  const [mission_bg_image, setMissionBgImage] = useState("");
  const [mission_bg_gradient, setMissionBgGradient] = useState(true);

  const [team_title, setTeamTitle] = useState("Nossa equipe");

  // START - section2 cards
  const [section2_cards, setSection2_cards] = useState([]);
  const [section2_title, setSection2_title] = useState(
    "O que você ganha com os nossos cursos?"
  );
  const [section2_title_color, setSection2_title_color] = useState("black");

  const addSection2Card = (data) => {
    setSection2_cards([
      ...section2_cards,
      {
        title: data.title,
        text: data.text,
        bg_color: data.bg_color,
        font_color: data.font_color,
      },
    ]);
  };

  const removeSection2Card = (index) => {
    let temp = [...section2_cards];
    temp.splice(index, 1);
    setSection2_cards(temp);
  };

  const handleSection2CardChange = (index, data) => {
    let temp = section2_cards;
    temp[index].title = data.title;
    temp[index].text = data.text;
    temp[index].bg_color = data.bg_color;
    temp[index].font_color = data.font_color;
    setSection2_cards([...temp]);
  };
  // END - section2 cards

  // START - section3 cards
  const [section3_cards, setSection3_cards] = useState([]);

  const addSection3Card = (data) => {
    setSection3_cards([
      ...section3_cards,
      {
        number: data.number,
        text: data.text,
      },
    ]);
  };

  const removeSection3Card = (index) => {
    let temp = [...section3_cards];
    temp.splice(index, 1);
    setSection3_cards(temp);
  };

  const handleSection3CardChange = (index, data) => {
    let temp = section3_cards;
    temp[index].number = data.number;
    temp[index].text = data.text;
    setSection3_cards([...temp]);
  };
  // END - section3 cards

  const [teamMembers, setTeamMembers] = useState([]);

  const Submit = async () => {
    let form = new FormData();

    // section1
    form.append("title", title);
    form.append("subtitle", subtitle);

    form.append("missionText", joditMissionText);
    form.append("introText", joditIntroText);

    form.append("about_us_bg_image", about_us_bg_image);
    try {
      about_us_bg_image.includes("");
      form.delete("about_us_bg_image");
    } catch (e) {}
    form.append(
      "about_us_bg_gradient",
      about_us_bg_gradient !== undefined ? about_us_bg_gradient : false
    );

    form.append("mission_bg_image", mission_bg_image);
    try {
      mission_bg_image.includes("");
      form.delete("mission_bg_image");
    } catch (e) {}
    form.append(
      "mission_bg_gradient",
      mission_bg_gradient !== undefined ? mission_bg_gradient : false
    );

    form.append("team_title", team_title);

    // section2
    form.append("section2_title", section2_title);

    // section 3
    form.append("statisticsTitle", statisticsTitle);
    form.append("statisticsText", statisticsText);

    for (let i = 0; i < section3_cards.length; i++)
      form.append("cards[]", JSON.stringify(section3_cards[i]));

    for (let i = 0; i < section2_cards.length; i++)
      form.append("winCards[]", JSON.stringify(section2_cards[i]));

    setSaveButtonStatus({
      status: "Salvando...",
      icon: <HourglassBottomIcon />,
    });
    let response = await Post("site-rt/about-us-page/update", form);

    if (response?.status === true) {
      setSaveButtonStatus({
        status: "Salvo!",
        icon: <CheckIcon />,
      });
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
        })
      );
      setTimeout(() => {
        setSaveButtonStatus({
          status: "Salvar tudo",
          icon: <SaveIcon />,
        });
      }, 2000);
    } else {
      setSaveButtonStatus({
        status: "Erro!",
        icon: <CheckIcon />,
      });
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: "error",
        })
      );
      setTimeout(() => {
        setSaveButtonStatus({
          status: "Salvar tudo",
          icon: <SaveIcon />,
        });
      }, 2000);
    }
  };

  // load all the data
  const GetData = async () => {
    let response = await Get("site-rt/about-us-page");
    if (response?.status === true) {
      // set all the data
      setTitle(response?.aboutUsPage?.title);
      setSubtitle(response?.aboutUsPage?.subtitle);

      setSection2_cards(response?.aboutUsPage?.winCards);

      setStatisticsTitle(response?.aboutUsPage?.statisticsTitle);
      setStatisticsText(response?.aboutUsPage?.statisticsText);
      setAboutUsBgImage(response?.aboutUsPage?.about_us_bg_image);
      setAboutUsBgGradient(response?.aboutUsPage?.about_us_bg_gradient);

      setJoditIntroText(response?.aboutUsPage?.introText);
      setJoditMissionText(response?.aboutUsPage?.missionText);
      setMissionBgImage(response?.aboutUsPage?.mission_bg_image);
      setMissionBgGradient(response?.aboutUsPage?.mission_bg_gradient);

      setTeamTitle(response?.aboutUsPage?.team_title);

      setSection2_title(response?.aboutUsPage?.section2_title);
      setSection2_title_color(response?.aboutUsPage?.section2_title_color);
    }
    set_is_loading(false);

    response = await Get("users");
    if (response?.status === true) {
      for (const key in response.users) {
        if (response.users[key].isTeamMember) {
          setTeamMembers((teamMembers) => [
            ...teamMembers,
            response.users[key],
          ]);
        }
      }
    }
  };

  useEffect(GetData, []);

  return is_loading ? (
    <div style={{ width: "100%", aspectRatio: "16/9", display: "flex" }}>
      <img
        src={logo_loading}
        alt="Logotipo RT"
        className={"infiniteRotateAnim"}
        style={{
          width: "12rem",
          height: "12rem",
          margin: "auto",
        }}
      />
    </div>
  ) : (
    <div className={style.gridContainer}>
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "14.2rem",
          zIndex: 50,
          display: "flex",
          gap: "1rem",
        }}
      >
        <div
          className={style.hidePreviewButton}
          style={{
            background: "#a6eda1",
          }}
          onClick={() => Submit()}
        >
          {save_button_status.icon} {save_button_status.status}
        </div>
        <div
          className={style.hidePreviewButton}
          style={{
            background: hidePreview ? "#accef2" : "#ffc3b5",
          }}
          onClick={() => setHidePreview(!hidePreview)}
        >
          {hidePreview ? <VisibilityIcon /> : <VisibilityOffIcon />}{" "}
          <span>{hidePreview ? "Mostrar" : "Esconder"} preview</span>
        </div>
      </div>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <div
          style={{
            maxWidth: hidePreview ? "100%" : `44%`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <h2 className="title">Configurações da página "sobre nós"</h2>
          </div>
          <FormPaginaInicial
            {...{
              title,
              setTitle,
              subtitle,
              setSubtitle,
              statisticsTitle,
              setStatisticsTitle,
              statisticsText,
              setStatisticsText,

              about_us_bg_image,
              setAboutUsBgImage,
              about_us_bg_gradient,
              setAboutUsBgGradient,

              joditIntroText,
              setJoditIntroText,
            }}
            section2={{
              section2_cards,
              addSection2Card,
              removeSection2Card,
              handleSection2CardChange,

              section2_title,
              setSection2_title,
              section2_title_color,
              setSection2_title_color,
            }}
            section3={{
              section3_cards,
              addSection3Card,
              removeSection3Card,
              handleSection3CardChange,
            }}
            section4={{
              joditMissionText,
              setJoditMissionText,
              mission_bg_image,
              setMissionBgImage,
              mission_bg_gradient,
              setMissionBgGradient,
            }}
            section5={{ team_title, setTeamTitle, teamMembers, setTeamMembers }}
          />
        </div>
      </div>
      <div
        style={{
          width: hidePreview ? "0" : `43%`,
          display: "flex",
          height: "100vh",
          transition: "width 300ms",
          position: "fixed",
          right: "0",
          background: "#fff",
          borderLeft: "0.2rem dashed black",
        }}
      >
        <PreviewAboutUsPage
          {...{
            title,
            setTitle,
            subtitle,
            setSubtitle,
            statisticsTitle,
            setStatisticsTitle,
            statisticsText,
            setStatisticsText,

            about_us_bg_image,
            about_us_bg_gradient,

            joditIntroText,
            setJoditIntroText,
          }}
          section1={{
            title,
            setTitle,
            subtitle,
            setSubtitle,
            statisticsTitle,
            setStatisticsTitle,
            statisticsText,
            setStatisticsText,
            about_us_bg_image,
            about_us_bg_gradient,

            joditIntroText,
            setJoditIntroText,
          }}
          section2={{
            section2_cards,
            setSection2_cards,
            section2_title,
            setSection2_title,
            section2_title_color,
            setSection2_title_color,
          }}
          section3={{
            section3_cards,
            setSection3_cards,
          }}
          section4={{
            joditMissionText,
            setJoditMissionText,
            mission_bg_image,
            setMissionBgImage,
            mission_bg_gradient,
            setMissionBgGradient,
          }}
          section5={{ team_title, setTeamTitle, teamMembers, setTeamMembers }}
        />
      </div>
    </div>
  );
};

export default ConfigSobreNos;

import { Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";

const TextCard = (props) => {
  const { text, white_bg = false, section2_cardTextSize } = props;

  return (
    <Box
      sx={{
        display: "flex",
        // backgroundColor: white_bg ? "rgba(0,0,0,0.04)" : "rgba(255,255,255,0.06)",
        padding: "0.4rem 1rem",
        borderRadius: "0.6rem",
        flexBasis: "max-content",
      }}
    >
      {/* <ExpandMoreIcon
        sx={{
          transform: "rotate(270deg)",
          fontSize: section2_cardTextSize+'px',
        }}
      /> */}
      <Typography
      sx={{
        fontSize: section2_cardTextSize+'px',
        textAlign: "left",
      }}
        dangerouslySetInnerHTML={{ __html: text }}
      />

      {/* <CheckIcon sx={{
        backgroundColor: white_bg ? '#6da655' : '#a3db72',
        borderRadius: '50%',
        padding: '0.2rem',	
        marginLeft: '1rem',
        color: white_bg ? "#fff" : "#000",
      }} /> */}
    </Box>
  );
};

export default TextCard;

import React from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { STORAGE_URL } from "../../variables";

import "./index.css";

import logo from "assets/images/rt/white_logo.png";

const LogoHeader = React.forwardRef((props, ref) => {
    
    const {
        ...other
    } = props;

    const generalStyle = useSelector(store => store.AppReducer.generalStyle);

    return (
        <Link
            to="/landing"
            {...other}
            ref={ref}
        >
            <img 
                alt="Logo"
                src={logo}
                className="logo-header-img logo-header-img-small"
            />
            
            <img 
                alt="Logo"
                src={logo}
                className="logo-header-img logo-header-img-normal"
            />

        </Link>
    );
})

export default LogoHeader;
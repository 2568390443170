import { Card, CardContent, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRef, useState } from "react";

import styles from "./index.module.css";
import Lesson from "./Lesson";

const Module = props => {

    const {
        module,
        moduleKey,
        onlyFilledQuestions = false,
        onlyFilledAnswers = false,
        ...other
    } = props;

    const [expanded, SetExpanded] = useState(false);

    const lessonsRef = useRef();

    return (
        <div
            className={styles.module}
            {...other}
        >
            <div 
                className={`${styles.header}`}
                onClick={() => SetExpanded(!expanded)}
            >
                <Typography>{moduleKey + 1}. {module?.name} - Porcentagem média de acerto: {module?.averagePercentage}%</Typography>
                <IconButton
                    onClick={() => SetExpanded(!expanded)}
                >
                    <ExpandMoreIcon
                        style={{
                            transform: `rotate(${expanded ? "180deg" : "0deg"})`,
                            transition: ".4s"
                        }}
                    />
                </IconButton>
            </div>

            <div
                className={styles.lessons}
                data-expanded={expanded}
                ref={lessonsRef}
            >
                {module?.lessons?.map((lesson, lessonKey) => 
                (!onlyFilledQuestions || onlyFilledQuestions && lesson?.questions?.length != 0) &&
                (!onlyFilledAnswers || onlyFilledAnswers && lesson?.lessonExerciseStatistic?.totalAnswered != 0) &&
                (
                    <Lesson
                        lesson={lesson}
                        lessonKey={lessonKey}
                        key={lessonKey}
                    />
                ))}
            </div>
            <div
                hidden={lessonsRef?.current?.childElementCount > 0 || !expanded}
                style={{
                    padding: 5
                }}
                align="center"
            >
                <Typography>Sem registro de exercícios neste módulo</Typography>
            </div>
        </div>
    );
}

export default Module;
import React, { useEffect, useState} from "react"
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "components/DefaultButton";
import ImageInput from "components/Inputs/ImageInput";
import { Get } from "utils/request";
import { useParams } from "react-router";
import ColorInput from "components/Inputs/ColorInput";

const FormBody = props => {

  const {
    loading,
    OnConfirm
  } = props;

  const [image, setImage] = useState();
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [bgcolor, SetBgColor] = useState("#fff");
  const { banner_id } = useParams();

  const GetData = async () => {
    let response = await Get(`site-rt/bannerPrincipal-images?id=${banner_id}`);
    if(response?.status === true){
      setImage(response?.banner?.image);
      setLink(response?.banner?.link);
      setTitle(response?.banner?.title);
      SetBgColor(response?.banner?.bgcolor);
    }
  }

  useEffect(() => GetData(), []);

  return(
    <div className="form-course">
      <form>
        <FormLabel>
          Imagem do banner <span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <ImageInput
          ImageChange={image => setImage(image)}
          path={image}
        />
        <br/>
        <FormLabel>Título <span style={{color: "red"}}>*</span></FormLabel>
        <Input
          placeholder="Título"
          value={title}
          onChange={e => setTitle(e.target.value)}
          required
        />
        <br/>
        <FormLabel>Link para redirecionar</FormLabel>
        <Input
          placeholder="Link"
          value={link}
          onChange={e => setLink(e.target.value)}
        />
        <br/>

        <FormLabel>Cor de fundo</FormLabel>
        <ColorInput
          value={bgcolor}
          onChange={color => SetBgColor(color)}
        />
      </form>
      <br/>
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine/>}
          to="/site-rt/bannerPrincipal-home"
        />
        <DefaultButton 
          bg="confirm"
          text="Salvar alterações"
          onClick={()=>{
            let data = {
              image: image,
              link: link,
              title: title,
              bgcolor: bgcolor
            };
            
            OnConfirm(data);
          }}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default FormBody;
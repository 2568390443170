import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "modules/Header";
import { loggedPages } from "modules/Header/data";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { Get, MOUNT_JSON_BODY, Post } from "utils/request";
import StepperMUI from "./StepperMui";
// import Confirm from './Confirm';
import style from "./index.module.css";
import PaymentIcon from "@mui/icons-material/Payment";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckIcon from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Adress from "./Sections/Adress";
import Payment from "./Sections/Payment";
import Confirm from "./Sections/Confirm";
import { useQuery } from "react-query";
import { STORAGE_URL } from "variables";
import SweetAlert from "react-bootstrap-sweetalert";
import { Show } from "actions/SnackbarActions";
import loadOpcells from "./Sections/LoadOpcells";

const Paymant = () => {
  const { course_id } = useParams();
  const dispatch = useDispatch();
  const [readyToRender, setReadyToRender] = useState(false);
  const [popup, SetPopup] = useState("");
  const token = useSelector((store) => store.AppReducer.token);
  const user_id = JSON.parse(localStorage.getItem("user")).id;
  const [page, setPage] = React.useState(0);
  const history = useHistory();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const steps = ["Endereço", "Pagamento", "Confirmação"];

  const [course, SetCourse] = useState({});
  const [user, setUser] = useState({});
  const [selectedOpcells, setSelectedOpcells] = useState([]);
  const [opcells, setOpcells] = useState([]);

  // payment states
  const [cpf, setCpf] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [brand, setBrand] = React.useState("");
  // const [month, setMonth] = React.useState("");
  const [due_date, setDueDate] = React.useState("");
  // const [year, setYear] = React.useState("");
  const [cvv, setCvv] = React.useState("");
  const [cvvLength, setCvvLength] = React.useState("");

  // user info
  const [user_name, setUserName] = React.useState(user?.name ? user.name : "");
  const [gender, setGender] = React.useState("");
  const [birth_date, setBirthDate] = React.useState("");
  const [phone, setPhone] = React.useState(user?.phone ? user.phone : "");

  // const course_data_request = useQuery(["course", course_id], () =>
  //   Get(`courses-main/one/${course_id}`)
  // );

  const getCourse = async () => {
    const response = await Get(`courses-main/one/${course_id}`);

    if (response?.status) {
      SetCourse(response?.course);

      let opcellsArray = [];
      if (response?.course?.opcells) {
        response?.course?.opcells.forEach((opcell) => {
          opcellsArray.push(opcell);
        });
      }
      setOpcells(opcellsArray);

      // setOpcells([JSON.parse(response?.course?.opcells)]);

      getUser();
    }
  };

  const getUser = async () => {
    const response = await Get(`user?id=${user_id}`);
    if (response?.status) {
      setUser(response?.user);
      setReadyToRender(true);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  // const user_data_request = useQuery(["user", user_id], () =>
  //   Get(`user?id=${user_id}`)
  // );

  // useEffect(() => {
  //   // console.log(course_data_request)
  //   // console.log(user_data_request)

  //   if (course_data_request?.status === "success") {
  //     SetCourse(course_data_request?.data?.course);
  //     setOpcells([JSON.parse(course_data_request?.data?.course?.opcells)]);
  //   }

  //   if (user_data_request?.status === "success") {
  //     setUser(user_data_request?.data?.user);
  //   }

  //   if (
  //     course_data_request?.status === "success" &&
  //     user_data_request?.status === "success"
  //   ) {
  //     setReadyToRender(true);
  //   }
  // }, [course_data_request, user_data_request]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      return history.push("/login");
    }
  }, []);

  function verifyPage(action) {
    if (action === "prev") {
      if (page > 0) {
        return setPage(page - 1);
      }
    }

    if (action === "next") {
      // check if user has address
      if (page === 0) {
        if (!user?.adress) {
          return SetPopup("Você precisa cadastrar um endereço para continuar");
        }
        if (form_edited) {
          return SetPopup(
            "Você precisa salvar as alterações feitas no endereço para continuar"
          );
        }
      }

      if (page < 2) {
        setPage(page + 1);
      } else {
        const data = {
          items: [
            {
              id: course_id,
              description: course?.description,
              amount: course?.price,
              quantity: 1,
            },

            ...selectedOpcells.map((opcell) => {
              return {
                id: opcell?.id,
                description: opcell?.description,
                amount: opcell?.price.replace("R$ ", "").replace(",", "."), // format price
                quantity: 1,
              };
            }),
          ],
        };
        console.log(data);
        setPaymentSuccess(true);
      }
    }
  }

  // adress
  const [form_edited, SetFormEdited] = useState(false);
  const [has_adress, SetHasAdress] = useState(false);

  const [adress, SetAdress] = useState({
    street: {
      value: "",
      error: false,
      helperText: "",
    },
    number: {
      value: "",
      error: false,
      helperText: "",
    },
    complement: {
      value: "",
      error: false,
      helperText: "",
    },
    neighborhood: {
      value: "",
      error: false,
      helperText: "",
    },
    city: {
      value: "",
      error: false,
      helperText: "",
    },
    zipCode: {
      value: "",
      error: false,
      helperText: "",
      handleChange: (cep) => searchAdressByCEP(cep),
    },
    state: {
      value: "",
      error: false,
      helperText: "",
    },
  });

  const searchAdressByCEP = async (cep) => {
    const api_fetch = await fetch(
      `https://viacep.com.br/ws/${cep.replace("-", "")}/json/`,
      { mode: "cors" }
    );
    const data = await api_fetch.json();

    if (data.hasOwnProperty("erro")) {
    } else {
      const { logradouro, uf, bairro, localidade } = data;
      SetAdress({
        ...adress,
        ["street"]: { ...adress["street"], value: logradouro },
        ["state"]: { ...adress["state"], value: uf },
        ["neighborhood"]: { ...adress["neighborhood"], value: bairro },
        ["city"]: { ...adress["city"], value: localidade },
        ["zipCode"]: { ...adress["zipCode"], value: cep },
      });
    }
  };

  // submit adress
  const submitAdress = async () => {
    // save user info
    let form = new FormData();
    form.append("id", user_id);
    form.append("street", adress["street"].value);
    form.append("number", adress["number"].value);
    form.append("complement", adress["complement"].value);
    form.append("neighborhood", adress["neighborhood"].value);
    form.append("city", adress["city"].value);
    form.append("state", adress["state"].value);
    form.append("zipCode", adress["zipCode"].value);

    let response = await Post(`auth/edit_profile`, form);
    if (response.status === 200 || response.status === true) {
      dispatch(
        Show({
          show: true,
          severity: response?.severity,
          message: response?.message,
        })
      );
      SetFormEdited(false);
      SetHasAdress(true);
    }
  };

  const SetPopupRender = (message) => {
    return (
      <SweetAlert
        warning
        show={popup !== ""}
        title={popup}
        confirmBtnText="Ok"
        onConfirm={() => SetPopup("")}
      />
    );
  };

  return !readyToRender ? (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "90vh",
      }}
    >
      <CircularProgress />
      <Typography variant="h5" style={{ marginLeft: "1rem" }}>
        Carregando...
      </Typography>
    </div>
  ) : (
    <div style={{ position: "relative" }}>
      {SetPopupRender()}
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100vh",
          zIndex: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          gap: "2rem",
          flexDirection: "column",
          opacity: paymentSuccess ? 1 : 0,
          transition: "all 600ms ease",
          top: paymentSuccess ? "0" : "-100vh",
        }}
      >
        <Typography variant="h3" style={{ color: "black", fontWeight: "bold" }}>
          Pagamento realizado com sucesso!
        </Typography>
        {paymentSuccess && (
          <div className="check-container">
            <div className="check-background">
              <svg
                viewBox="0 0 65 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 25L27.3077 44L58.5 7"
                  stroke="white"
                  stroke-width="13"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="check-shadow"></div>
          </div>
        )}
        <Typography variant="p" style={{ color: "black", fontWeight: "bold" }}>
          Acesse seu perfil para ver os cursos que você comprou!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(`/profile/view/${user_id}`);
          }}
        >
          Ir para o perfil
        </Button>
      </div>

      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 1,
          opacity: "0.5",
          top: "0",
          overflow: "hidden",
        }}
      >
        <img
          src={STORAGE_URL + course?.checkout_cover_image}
          style={{
            objectFit: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            maskImage:
              " linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, transparent 76%)",
          }}
        />
      </div>
      <div
        className="d-flex align-items-center"
        style={{
          display: "flex",
          flexDirection: "column",
          // minHeight: "calc(100vh - 4rem)",

          backgroundColor: course?.checkout_bg_color,
          minHeight: "100vh",
          marginTop: "2rem",
          paddingBottom: "4rem",
        }}
      >
        <Header pages={loggedPages} />
        <div
          className={style.pageWrapper}
          style={{
            background: course?.checkout_accent_color,
            color: course?.checkout_font_color,
          }}
        >
          {course?.checkout_cover_image !== null &&
            course?.checkout_cover_image !== undefined && (
              <img
                src={STORAGE_URL + course?.checkout_cover_image}
                alt="Banner"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  maxHeight: "18rem",
                  borderRadius: "0.5rem 0.5rem 0 0",
                }}
              />
            )}
          <div
            className={"row p-5 " + style.pageContainer}
            style={{
              backgroundColor: course?.checkout_card_color,
            }}
          >
            <Typography variant="h4" className="text-center mb-5">
              Insira os dados para realizar a compra
            </Typography>

            <StepperMUI
              steps={steps}
              page={page}
              color={course?.checkout_font_color}
            />

            <div>
              {/* mostra a seção de acordo com a página requisitada */}
              {
                {
                  0: (
                    <Adress
                      {...{
                        user,
                        user_id,
                        adress,
                        SetAdress,
                        form_edited,
                        SetFormEdited,
                        has_adress,
                        SetHasAdress,

                        submitAdress,

                        accent_color: course?.checkout_accent_color,
                        font_color: course?.checkout_font_color,
                      }}
                    />
                  ),
                  1: (
                    <Payment
                      {...{
                        user,
                        cpf,
                        setCpf,
                        number,
                        setNumber,
                        brand,
                        setBrand,
                        due_date,
                        setDueDate,
                        cvv,
                        setCvv,
                        cvvLength,
                        setCvvLength,

                        user_name,
                        setUserName,
                        gender,
                        setGender,
                        birth_date,
                        setBirthDate,
                        phone,
                        setPhone,
                      }}
                    />
                  ),
                  2: (
                    <Confirm
                      {...{
                        course,
                        opcells,
                        selectedOpcells,
                        setSelectedOpcells,
                      }}
                    />
                  ),
                }[page]
              }
            </div>

            <div className="d-flex mt-5" style={{ width: "100%" }}>
              <div className="align-self-center">
                {page !== 0 && (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => verifyPage("prev")}
                    startIcon={<ChevronLeftIcon />}
                    sx={{
                      backgroundColor: course?.checkout_accent_color,
                    }}
                  >
                    {page === 1 && "Voltar para endereço"}
                    {page === 2 && "Voltar para pagamento"}
                  </Button>
                )}
              </div>
              <div className="align-self-center ms-auto">
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => verifyPage("next")}
                  endIcon={
                    page === 0 ? (
                      <PaymentIcon />
                    ) : page === 1 ? (
                      <KeyboardArrowRightIcon />
                    ) : (
                      <CheckIcon />
                    )
                  }
                  sx={{
                    backgroundColor: course?.checkout_accent_color,
                  }}
                >
                  {page === 0 && "Ir para Pagamento"}
                  {page === 1 && "Prosseguir"}
                  {page === 2 && "Confirmar Pedido"}
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {page !== 2 &&
              loadOpcells({
                course,
                opcells,
                selectedOpcells,
                setSelectedOpcells,
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paymant;

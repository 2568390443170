import SelectAutocomplete from "components/Inputs/SelectAutocomplete";
import { Typography } from "@mui/material";
import { Get, Post } from "utils/request";
import { useHistory, useParams } from "react-router";
import { useEffect, useState } from "react";
import DefaultButton from "components/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { useQuery, useMutation } from "react-query";

const GroupCoursesAdd = () => {

    const [toAddCourses, SetToAddCourses] = useState([]);
    const [search, SetSearch] = useState("");

    const { group_id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, data, refetch } = useQuery(["groups", "courses", group_id, search], async () => await Get(`groups?id=${group_id}&outsideCourses=true&outsideCoursesSearch=${search}`));
    const {
        group: {
            outsideCourses = []
        } = {}
    } = data || {};
    
    const submitMutation = useMutation(async () => {
        const form = new FormData();
        form.append("group_id", group_id);
        for (let i = 0; i < toAddCourses?.length; i++) form.append("course_id[]", toAddCourses[i]?.id);

        return await Post("groups/courses/create", form);
    }, {
        onSuccess: (data) => {
            dispatch(Show({
                show: true,
                message: data?.message,
                severity: "success"
            }));
            history.push({
                pathname: `/groups/${group_id}/view`,
                search: new URLSearchParams({ t: 1 }).toString()
            });
        }
    });

    // const Submit = async () => {
    //     let form = new FormData();
    //     form.append("group_id", group_id);
    //     for (let i = 0; i < toAddCourses?.length; i++) form.append("user_id[]", toAddCourses[i]?.id);
        
    //     let response = await Post("groups/users/create", form);
        
    //     dispatch(Show({
    //         show: true,
    //         message: response?.message,
    //         severity: response?.severity
    //     }));

    //     if (response?.status === true) history.push(`/groups/${group_id}/view`);
    // }

    return (
        <div className="card">
            <div className="card-body">
                <Typography
                    variant="h4"
                    gutterBottom
                >
                    Adicionar cursos
                </Typography>
                <Typography
                    gutterBottom
                >
                    Aqui são adicionados os cursos a turma em questão
                </Typography>
                <br/>
                <SelectAutocomplete
                    items={outsideCourses}
                    onSearch={SetSearch}
                    onChange={outsideCourses => SetToAddCourses(outsideCourses)}
                    placeholder={"Pesquisar por cursos"}
                    notFoundMessage={"Nenhum curso encontrado"}
                    zeroItemsMessage={"Nenhum curso selecionado"}
                    tableBody={[
                        { objLabel: "name", header: "Nome" },
                        { objLabel: "email", header: "E-mail" }
                    ]}
                />
                <div className="w100 inline-flex jcsb mt-2">
                    <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine/>}
                        to={`/groups/${group_id}/view`}
                    />
                    <DefaultButton
                        bg="confirm"
                        text="Adicionar curso(s)"
                        onClick={submitMutation.mutate}
                        loading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
}

export default GroupCoursesAdd;
import { IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import styles from "./index.module.css";

const WhatsappButton = props => {
    return (
        <IconButton
            className={styles.button}
            onClick={() => window.open("https://api.whatsapp.com/send/?phone=5517991588008&text&app_absent=0", "_blank")}
            {...props}
        >
            <WhatsAppIcon/>
        </IconButton>
    );
}

export default WhatsappButton;
import React from "react";

import { useSelector } from "react-redux";
import SocialMedias from "components/SocialMedias";
import { Box } from "@mui/material";

import styles from "./index.module.css";

const Footer = props => {
    
    const {
        phone,
        email,
        whatsapp,
        ...others
    } = props;

    const generalStyle = useSelector(store => store.AppReducer.generalStyle);

    return (
        <Box 
            className={styles.footer}
            bgcolor={generalStyle && generalStyle?.footer_background_color}
            color={generalStyle && generalStyle?.footer_font_color}
            {...others}
        >
            <Box className="contact-data">
                <h6 className="font-weight-bold">Entre em contato</h6>
                <p 
                    hidden={!phone && !generalStyle?.phone_number}
                >Fone: {phone || generalStyle?.phone_number}</p>
                <p
                    hidden={!email && !generalStyle?.email}
                >E-mail: {email || generalStyle?.email}</p>
                <p
                    hidden={!whatsapp && !generalStyle.whatsapp_number}
                >Whatsapp: {whatsapp || generalStyle.whatsapp_number}</p>
            </Box>
            <Box id="copyright" className="w100 flex jcc text-center fdcolumn">
                <SocialMedias className="jcc"/>
                <span className="text-muted">
                    Direitos reservados RT Saúde Integrativa<br/>
                    CNPJ 37.123.095/0001-140
                </span>
            </Box>
        </Box>
    );
}

export default Footer;
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useQuery } from "react-query";
import { Get } from "utils/request";
import CustomInput from "./CustomInput";
import { useRef } from "react";
import { useSelector } from "react-redux";

const AddressInfo = ({
  course,
  user_id,
  adress,
  SetAdress,
  form_edited,
  SetFormEdited,
  has_adress,
  SetHasAdress,

  submitAdress,

  font_color,
  accent_color,
}) => {
  const [emptyFields, setEmptyFields] = useState(false);
  const formRef = useRef(null);

  const [adress_data, setAdress_data] = useState(null);
  const currentUser = useSelector((store) => store.AppReducer.user);

  // const QueryMultiple = () => {
  //   const adress_data = useQuery(["user", user_id], () =>
  //     Get(`user?id=${user_id}`)
  //   );
  //   return [adress_data];
  // };

  const getData = async () => {
    if(currentUser.role=='Admin'){
      return;
    }
    const adress_data = await Get(`user?id=${user_id}`).then((res) => res);
    setAdress_data(adress_data?.user?.adress);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (adress_data) {
      const { street, number, complement, neighborhood, city, zipCode, state } =
        adress_data;
      SetAdress({
        ...adress,
        ["street"]: { ...adress["street"], value: street },
        ["number"]: { ...adress["number"], value: number },
        ["complement"]: { ...adress["complement"], value: complement },
        ["neighborhood"]: { ...adress["neighborhood"], value: neighborhood },
        ["city"]: { ...adress["city"], value: city },
        ["zipCode"]: { ...adress["zipCode"], value: zipCode },
        ["state"]: { ...adress["state"], value: state },
      });
      SetHasAdress(true);
      SetFormEdited(false);
    } else {
      SetHasAdress(false);
      SetFormEdited(true);
    }
  }, [adress_data]);

  const HandleSubmit = async (e) => {
    e.preventDefault();
    // get all inputs from formRef
    const inputs = formRef.current.querySelectorAll("input");

    setEmptyFields(false);
    // check if any input is empty
    inputs.forEach((input) => {
      console.log(input);
      if (input.value === "") {
        setEmptyFields(true);
        return;
      }
    });

    if (emptyFields) {
      return;
    }

    submitAdress();
  };

  return (
    // show the adress horizontally
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "56rem",
        maxWidth: "100%",
        color: font_color,
      }}
    >
      <Typography
        variant="h6"
        style={{
          color: font_color,
          fontWeight: "bold",
        }}
      >
        Endereço atual
      </Typography>

      <Typography
        variant="body1"
        style={{ marginBottom: "2rem", color: font_color }}
      >
        <b>Seu endereço está correto?</b> Faça alterações se necessário.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.6rem",
          marginBottom: "0.6rem!important",
          justifyContent: "space-between",
          marginTop: "0.6rem",
        }}
        component="form"
        ref={formRef}
      >
        {/* CEP */}
        <CustomInput
          type="text"
          label="CEP"
          value={adress["zipCode"].value}
          maxLength="9"
          flexBasis="8rem"
          emptyFields={emptyFields}
          onChange={(e) => {
            SetFormEdited(true);
            SetAdress({
              ...adress,
              zipCode: {
                ...adress["zipCode"],
                value: e.target.value
                  .replace(/\D/g, "")
                  .replace(/(\d{5})(\d)/, "$1-$2")
                  .replace(/(-\d{3})\d+?$/, "$1"),
              },
            });
            if (e.target.value.length === 9) {
              adress["zipCode"].handleChange(e.target.value);
            }
          }}
        />
        {/* STREET */}
        <CustomInput
          label="Rua"
          value={adress["street"].value}
          flexBasis="24rem"
          emptyFields={emptyFields}
          onChange={(e) => {
            SetFormEdited(true);
            SetAdress({
              ...adress,
              street: {
                ...adress["street"],
                value: e.target.value,
              },
            });
          }}
        />
        {/* NUMBER */}
        <CustomInput
          label="Número"
          type={"number"}
          value={adress["number"].value}
          flexBasis="4rem"
          onChange={(e) => {
            SetFormEdited(true);
            SetAdress({
              ...adress,
              number: {
                ...adress["number"],
                value: e.target.value,
              },
            });
          }}
        />
        {/* neighborhood */}
        <CustomInput
          label="Bairro"
          value={adress["neighborhood"].value}
          flexBasis="20rem"
          emptyFields={emptyFields}
          onChange={(e) => {
            SetFormEdited(true);
            SetAdress({
              ...adress,
              neighborhood: {
                ...adress["neighborhood"],
                value: e.target.value,
              },
            });
          }}
        />
        {/* city */}
        <CustomInput
          label="Cidade"
          disabled
          value={adress["city"].value}
          flexBasis="12rem"
          emptyFields={emptyFields}
          onChange={(e) => {
            SetFormEdited(true);
            SetAdress({
              ...adress,
              city: {
                ...adress["city"],
                value: e.target.value,
              },
            });
          }}
        />
        {/* state */}
        <CustomInput
          label="Estado"
          disabled
          value={adress["state"].value}
          flexBasis="4rem"
          emptyFields={emptyFields}
          onChange={(e) => {
            SetFormEdited(true);
            SetAdress({
              ...adress,
              state: {
                ...adress["state"],
                value: e.target.value,
              },
            });
          }}
        />
      </Box>

      <span>
        {!has_adress && (
          <span
            style={{
              color: font_color,
              fontWeight: "bold",
            }}
          >
            <ErrorOutlineIcon style={{ color: "red", marginRight: "0.5rem" }} />
            <b>Atenção!</b> Você ainda não cadastrou um endereço. Cadastre um
            agora para continuar com a compra.
          </span>
        )}
      </span>

      {form_edited && (
        <Button
          variant="contained"
          color="primary"
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            width: "fit-content",
            marginTop: "1rem",
            backgroundColor: accent_color,
          }}
          onClick={(e) => HandleSubmit(e)}
        >
          Salvar novo endereço
        </Button>
      )}
    </div>
  );
};

export default AddressInfo;

import { useEffect, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import JoditEditor from "jodit-react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// mui icons
import { Post } from "utils/request";
import Label from "components/InfoLabel";
import ColorInput from "components/Inputs/ColorInput";

const BemVindoEdit = ({
  welcomeTitle,
  setWelcomeTitle,
  welcomeDescription,
  setWelcomeDescription,
  welcomeSubtitle,
  setWelcomeSubtitle,
  welcomeVideoLink,
  setWelcomeVideoLink,
  welcomeVideoFile,
  setWelcomeVideoFile,

  headerColor1,
  headerColor2,

  handleOpenLoadingModal,
  handleCloseLoadingModal,

  JoditConfig,
}) => {
  const dispatch = useDispatch();

  const Submit = async () => {
    handleOpenLoadingModal();
    let form = new FormData();
    form.append("title", welcomeTitle);

    form.append("description", welcomeDescription);

    form.append("sub", welcomeSubtitle);

    form.append("type", "firstContent");

    form.append("color1", headerColor1);
    form.append("color2", headerColor2);
    let response = await Post("site-rt/description/update", form);

    let form2 = new FormData();
    form2.append("video", welcomeVideoFile);
    form2.append("link", welcomeVideoLink);

    let response2 = await Post("site-rt/advertising-video/update", form2);

    if (response.status === 200 && response2.status === 200) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
          buttonActionText: "Ver",
        })
      );
    } else {
      if (response.status !== 200) {
        dispatch(
          Show({
            show: true,
            message: response?.message,
            severity: response?.severity,
            buttonActionText: "Ver",
          })
        );
      }
      if (response2.status !== 200) {
        dispatch(
          Show({
            show: true,
            message: response2?.message,
            severity: response2?.severity,
            buttonActionText: "Ver",
          })
        );
      }
    }
    handleCloseLoadingModal();
  };

  return (
    <>
      <FormLabel>
        Título <span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={welcomeTitle}
          onChange={(value) => setWelcomeTitle(value)}
        />
      </FormLabel>

      <FormLabel>
        Descrição <span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={welcomeDescription}
          onChange={(value) => setWelcomeDescription(value)}
        />
      </FormLabel>

      <FormLabel>
        Subtítulo <span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={welcomeSubtitle}
          onChange={(value) => setWelcomeSubtitle(value)}
        />
      </FormLabel>

      <Label
        label={<span>Vídeo</span>}
        required
        title="O vídeo que aparecerá na seção de 'Bem-vindo' da página inicial."
      />

      {welcomeVideoFile?.name && (
        <Typography variant="subtitle" gutterBottom>
          {welcomeVideoFile?.name} - {welcomeVideoFile?.size / 1000}KB
        </Typography>
      )}

      <div style={{ display: "flex", flexWrap: "wrap", gap: "0.8rem" }}>
        <Button
          variant="contained"
          component="label"
          sx={{ width: "max-content" }}
        >
          {"Alterar vídeo"}
          <input
            type="file"
            hidden
            onChange={(e) => setWelcomeVideoFile(e.target.files[0])}
            accept="video/mp4,video/x-m4v,video/*"
          />
        </Button>
        {welcomeVideoFile?.name && (
          <Button
            variant="contained"
            color="error"
            sx={{ width: "max-content" }}
            onClick={() => setWelcomeVideoFile(null)}
          >
            Cancelar
          </Button>
        )}
      </div>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Proporção de telas
        recomendadas para o vídeo: 16:9 e 4:3. Para esse caso não há exatamente
        uma proporção única, mas essas são as mais utilizadas.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formato aceito: .mp4.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo, mas o ideal é que o tamanho do arquivo seja menor que
        100mb para que o carregamento seja mais rápido. (se possível. Do
        contrário, o usuário terá que esperar um pouco mais para que o vídeo
        seja carregado.)
      </span>

      <FormLabel>
        Link
        <Input
          placeholder="Link"
          value={welcomeVideoLink}
          onChange={(e) => setWelcomeVideoLink(e.target.value)}
        />
      </FormLabel>

      <div>
        <Button variant="contained" color="success" onClick={() => Submit()}>
          Salvar seção 2
        </Button>
      </div>
    </>
  );
};
export default BemVindoEdit;

import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModuleCollapse from "./ModuleCollapse";
import LessonItem from "./LessonItem";
import ProgressContainer from "./ProgressContainer";

const CoursesSideBar = ({
  course,
  modules,
  styles,
  hideSideBar,
  setHideSideBar,
  small_screen,
  refetch,
}) => {
  return (
    <Box
      className={styles.coursesSideBarContainer}
      sx={{
        width: hideSideBar
          ? "0px"
          : small_screen
          ? "100%"
          : {
              xs: "22rem",
              sm: "22rem",
              md: "26rem",
              lg: "30rem",
            },
        position: small_screen ? "absolute" : "relative",
      }}
    >
      <CloseIcon
        sx={{
          position: "absolute",
          top: "1rem",
          right: "1rem",
          cursor: "pointer",
          opacity: 0.7,
          "&:hover": {
            opacity: 1,
          },
        }}
        onClick={() => setHideSideBar(true)}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#f5f5f5",
          paddingTop: "1rem",
        }}
      >
        <ProgressContainer {...course} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          height: "calc(100vh - 18rem)",
        }}
      >
        {modules?.map((module, index) => {
          return (
            <ModuleCollapse key={index} title={module.name} first={index === 0}>
              {module.lessons?.map((lesson, index) => (
                <LessonItem
                  key={index}
                  {...{ lesson, small_screen, setHideSideBar, refetch }}
                />
              ))}
            </ModuleCollapse>
          );
        })}
        {modules?.length === 0 || modules === undefined && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "80%",
              color: "#999",
              fontSize: "1.2rem",
            }}
          >
            Este curso ainda não possui aulas cadastradas
          </div>
        )}
      </div>
    </Box>
  );
};

export default CoursesSideBar;

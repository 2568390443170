import {
  Box,
  Card,
  Typography,
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";

import { months } from "utils/variables";
import styles from "./index.module.css";

const Filter = (props) => {
  const { dates = [], onClick = () => {} } = props;

  const [mes, setMes] = React.useState("");
  const [ano, setAno] = React.useState(2024);

  useEffect(() => {
    if (mes !== "" && ano !== "") {
      console.log("entrou");
      console.log(mes);
      console.log(ano);
      onClick(mes, ano);
    }
  }, [mes, ano]);

  return (
    <Card className={styles.card} sx={{ boxShadow: "none" }}>
      <Box>
        <Typography className={styles.title} variant="h4">
          Filtrar por data
        </Typography>
        {/* <List>
          {dates?.map((date) => (
            <ListItem
              button
              onClick={() => onClick(date?.month, date?.year)}
              key={`${date?.month}${date?.year}`}
            >
              <Typography variant="body1">
                {months[date?.month - 1]} {date?.year}
              </Typography>
            </ListItem>
          ))}
        </List> */}
        <div style={{ display: "flex" }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-filled-label">Mês</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={mes}
              onChange={(e) => {
                setMes(e.target.value);
              }}
            >
              {/* <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem> */}

              {dates?.map((date) => (
                <MenuItem
                  // onClick={() => onClick(date?.month, ano)}
                  key={`${date?.month}${ano}`}
                  value={date?.month}
                >
                  {months[date?.month - 1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Ano</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ano}
              onChange={(e) => {
                setAno(e.target.value);
              }}
            >
              {dates?.map((date) => (
                <MenuItem
                  // onClick={() => onClick(mes, date?.year)}
                  key={`${mes}${date?.year}`}
                  value={date?.year}
                >
                  {date?.year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Box>
    </Card>
  );
};

export default Filter;

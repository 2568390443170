import { Box, LinearProgress, Typography, useMediaQuery } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";
import { Get } from "utils/request";

import Average from "./Average";
import List from "./List";

export const ReviewsContext = createContext(null);

const Reviews = () => {
  const [evaluationFilter, SetEvaluationFilter] = useState("");

  const { course_id } = useParams();
  const queryClient = useQueryClient();
  const matches = useMediaQuery("(min-width: 750px)");

  const { isLoading, error, data, refetch } = useQuery(
    ["course", course_id, `evaluation: ${evaluationFilter}`],
    () =>
      Get(
        `courses-main?id=${course_id}&evaluationFilter=${
          evaluationFilter ?? ""
        }`
      )
  );
  // const { status, course } = queryClient?.getQueryData(["course", course_id]) || {};

  return (
    <Box
      display="inline-flex"
      width="100%"
      flexDirection={"column"}
    >
      <ReviewsContext.Provider
        value={{
          evaluationFilter,
          SetEvaluationFilter,
        }}
      >
        <Average
          alignSelf={matches ? "inherit" : "center"}
        />
        <div>
          <List />
        </div>
      </ReviewsContext.Provider>
    </Box>
  );
};

export default Reviews;

import React, { useState } from "react";

import DefaultButton from "../../../components/DefaultButton";
import InputText from "../../../components/Inputs/InputText";
import PhoneInput from "../../../components/Inputs/PhoneInput";

const FormBody = React.forwardRef((props, refs) => {

    const { ref, firstInputRef } = refs;

    const {
        config
    } = props;

    const [name, SetName] = useState("");
    const [email, SetEmail] = useState("");
    const [whatsapp, SetWhatsapp] = useState("");

    console.log(refs);

    return (
        <form className="w100 flex align-center fdcolumn" ref={ref}>
            <InputText
                variant="filled"
                label="Nome"
                focusedLabelColor={config?.primary_color}
                underlineColor={config?.primary_color}
                style={{width: "90%", minWidth: "20em"}}
                ref={firstInputRef}
            />
            <InputText
                variant="filled"
                label="E-mail"
                focusedLabelColor={config?.primary_color}
                underlineColor={config?.primary_color}
                style={{width: "90%", minWidth: "20em"}}
            />
            <PhoneInput
                variant="filled"
                label="Telefone"
                focusedLabelColor={config?.primary_color}
                underlineColor={config?.primary_color}
                style={{width: "90%", minWidth: "20em"}}
            />
            <br/>
            <DefaultButton
                text="Fazer matrícula agora!"
                bg={config?.primary_color}
                style={{
                    fontSize: "18px"
                }}
            />
        </form>
    );
});

export default FormBody;
import { Card, CardContent, CardActions, Dialog, Button, DialogContent, DialogTitle, FormControlLabel, IconButton, Radio, Typography } from "@mui/material";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

import styles from "./index.module.css";

const Lesson = props => {

    const {
        lesson,
        lessonKey
    } = props;

    const [exerciseModal, SetExerciseModal] = useState(false);

    const IsApproved = () => {
        const minPercentage = parseFloat(lesson?.min_percentage);
        const totalQuestions = lesson?.questions?.length;
        const totalCorrect = lesson?.lessonExerciseStatistic?.totalCorrect;
        
        if (100 * totalCorrect / totalQuestions >= minPercentage) return true;
        return false;
    }

    return (
        <>
            <Dialog
                open={exerciseModal}
                onClose={() => SetExerciseModal(false)}
                keepMounted
                maxWidth="lg"
            >
                <DialogTitle>
                    <div className="flex fdrow jcsb w100 align-center">
                        <Typography
                            variant="h5"
                        >
                            {lesson?.title}
                        </Typography>
                        <IconButton
                            onClick={() => SetExerciseModal(false)}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        width: "60vw",
                        minWidth: "30em",
                        backgroundColor: "whitesmoke"
                    }}
                    className={styles.lessonsContent}
                >
                    {lesson?.questions?.map((question, questionKey) => (
                        <Card key={questionKey}>
                            <CardContent className="flex fdcolumn">
                                {question?.utterance || "---"}
                                {question?.options?.map((option, optionKey) => (
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={option?.id == question?.prevSelected?.id}
                                                disabled
                                                id={option?.id}
                                            />
                                        }
                                        style={{
                                            background: option?.id == question?.prevSelected?.id ? (question?.prevSelected?.correct ? "#e6f4ea" : "#fce8e6") : ""
                                        }}
                                        label={option?.text}
                                        key={optionKey}
                                        className={styles.formControl}
                                    />
                                ))}
                            </CardContent>
                            
                        </Card>
                    ))}
                </DialogContent>
            </Dialog>
            <Card
                raised
            >
                <CardContent>
                    <div className={`flex fdrow jcsb align-center`}>
                        <Typography><b>Nome da aula</b></Typography>
                        <Typography style={{ textAlign: "right" }}><b>Acertos / Nº alternativas</b></Typography>
                    </div>
                    <div 
                        className={`flex fdrow jcsb align-center`}
                        hidden={!lesson?.lessonExerciseStatistic}
                    >
                        <Typography>{lessonKey + 1}. {lesson?.title}</Typography>
                        <Typography>{lesson?.lessonExerciseStatistic?.totalCorrect}/{lesson?.questions?.length}</Typography>
                    </div>
                    <Typography
                        align="right"
                    >
                        Porcentagem mínima de acerto: {lesson?.min_percentage}%
                    </Typography>
                </CardContent>
                <CardActions
                    className="flex fdrow jcsb"
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => SetExerciseModal(true)}
                        startIcon={<VisibilityIcon/>}
                    >
                        Ver questões
                    </Button>
                    <div 
                        className={`${styles.isApproved}`}
                        hidden={lesson?.lessonExerciseStatistic?.totalAnswered == 0}
                        approved={IsApproved().toString()}
                    >
                        {IsApproved() ? <span className="flex align-center"><CheckIcon/> Aprovado</span> : <span className="flex align-center"><CloseIcon/> Reprovado</span>}
                    </div>
                    <span
                        style={{
                            color: "#ff9800",
                            fontWeight: "bolder"
                        }}
                        hidden={lesson?.lessonExerciseStatistic?.totalAnswered != 0}
                        className="flex align-center"
                    >
                        <QueryBuilderIcon/> Não fez
                    </span>
                </CardActions>
            </Card>
        </>
    );
}

export default Lesson;
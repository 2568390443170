import { FormLabel, FormControl as Input } from "react-bootstrap";
import VideoInput from "components/Inputs/VideoInput";
import ImageInput from "components/Inputs/ImageInput";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ColorInput from "components/Inputs/ColorInput";
import JoditEditor from "jodit-react";
import Label from "components/InfoLabel";
import { useEffect } from "react";
import { Checkbox } from "@mui/material";

const Section1 = ({
  name,
  SetName,
  showNameSalesPage,
  SetShowNameSalesPage,
  showMural,
  setShowMural,
  thumbnail_image,
  SetThumbnailImage,

  subtitle,
  SetSubtitle,

  SetImage,
  image,

  SetCoverImage,
  coverImage,

  SetVideoTrailer,

  SetExistingVideoTrailer,
  existingVideoTrailer,

  section1_bgColor_1,
  section1_bgColor_2,
  SetSection1BgColor1,
  SetSection1BgColor2,

  section1_accentColor,
  SetSection1AccentColor,

  JoditConfig,
}) => {
  useEffect(() => {
    console.log("existingVideoTrailer", existingVideoTrailer);
  }, [existingVideoTrailer]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <FormLabel>
        Imagem a ser mostrada na página de cursos
        <ImageInput
          path={thumbnail_image}
          ImageChange={(image) => SetThumbnailImage(image)}
        />
      </FormLabel>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos recomendados:
        Qualquer tamanho na proporção de tela 22:9
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
        .jpeg, .png e .gif.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo.
      </span>

      <FormLabel>
        Imagem de capa da primeira seção
        <ImageInput
          path={coverImage}
          ImageChange={(image) => SetCoverImage(image)}
        />
      </FormLabel>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos recomendados:
        1280x720 (16:9) ou 1280x960 (4:3)
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
        .jpeg, .png e .gif.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo.
      </span>

      <FormLabel>
        Imagem do curso <span style={{ color: "red" }}>*</span>
        <ImageInput path={image} ImageChange={(image) => SetImage(image)} />
      </FormLabel>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanhos recomendados:
        1920x1080 (16:9), 1600x900 (16:9) ou 1280x720 (16:9).
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não se recomenda usar
        outra proporção de tela além da proporção 16:9 porque a imagem será
        usada como cartão ao mostrar os outros cursos.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
        .jpeg, .png e .gif.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo.
      </span>

      <FormLabel>
        Nome do curso<span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={name}
          onChange={(value) => SetName(value)}
        />
      </FormLabel>

      <div
        style={{
          display: "flex",
          gap: "0.6rem",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        <Checkbox
          checked={showNameSalesPage}
          onChange={(e) => SetShowNameSalesPage(e.target.checked)}
        />
        <span>Mostrar nome na página de vendas</span>
      </div>


      <div
        style={{
          display: "flex",
          gap: "0.6rem",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        <Checkbox
          checked={showMural}
          onChange={(e) => setShowMural(e.target.checked)}
        />
        <span>Mostrar curso no mural</span>
      </div>

      <br />

      <FormLabel>
        Subtítulo do curso
        <JoditEditor
          config={JoditConfig}
          value={subtitle}
          onChange={(value) => SetSubtitle(value)}
        />
      </FormLabel>

      <FormLabel>
        <Label
          label={<span>Vídeo de trailer</span>}
          title="Este vídeo será para apresentar o curso ao usuário."
        />
      </FormLabel>
      <VideoInput
        VideoChange={(value) => {
          if (value?.length !== 0) SetExistingVideoTrailer([]);
          SetVideoTrailer(value);
        }}
        HandleExistingVideoDeletion={() => SetExistingVideoTrailer([])}
        existingVideos={existingVideoTrailer}
        limitOne
      />

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Proporção de telas
        recomendadas para o vídeo: 16:9 e 4:3. Para esse caso não há exatamente
        uma proporção única, mas essas são as mais utilizadas.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formato aceito: .mp4.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo, mas o ideal é que o tamanho do arquivo seja menor que
        100mb para que o carregamento seja mais rápido. (se possível. Do
        contrário, o usuário terá que esperar um pouco mais para que o vídeo
        seja carregado.)
      </span>

      <FormLabel>
        <Label
          label={<span>Cor de fundo 1 </span>}
          required
          title="Esta cor será aplicada no fundo da seção."
        />
      </FormLabel>
      <ColorInput
        value={section1_bgColor_1}
        onChange={(color) => color !== undefined && SetSection1BgColor1(color)}
      />

      <FormLabel>
        <Label
          label={<span>Cor de fundo 2 </span>}
          required
          title="Esta cor será aplicada no fundo da seção. Se for definida, um gradiente será aplicado baseado na cor 1."
        />
      </FormLabel>
      <ColorInput
        value={section1_bgColor_2}
        onChange={(color) => color !== undefined && SetSection1BgColor2(color)}
      />

      <FormLabel>
        <Label
          label={<span>Cor da moldura </span>}
          required
          title="Esta cor será aplicada na moldura da seção. A mesma moldura que prende o vídeo de trailer ou a imagem do curso."
        />
      </FormLabel>
      <ColorInput
        value={section1_accentColor}
        onChange={(color) =>
          color !== undefined && SetSection1AccentColor(color)
        }
      />
    </div>
  );
};

export default Section1;

import React, { useEffect, useState } from "react";

// import ColorPicker from 'material-ui-color-picker'
import { FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import { FormControl as Input } from "react-bootstrap";

import ImageInput from "components/Inputs/ImageInput";
import { FormLabel } from "react-bootstrap";
import DefaultButton from "components/DefaultButton";
import { Get } from "utils/request";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_KEYS } from "variables";
import { RiArrowGoBackLine } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import FormCard from "./FormCard";
import Label from "components/InfoLabel";
import { BsInfoCircle } from "react-icons/bs";
import ColorInput from "components/Inputs/ColorInput";
import PreviewPage from "./PreviewPage";
import { maskPrice } from "Auxiliar/Masks";

const FormBody = props => {

    const {
        onConfirm,
        loading
    } = props;

    const [title, SetTitle] = useState("");
    const [text, SetText] = useState("");
    
    const [firstLoading, SetFirstLoading] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();

    const { course_name, course_id } = useParams();

    const GetData = async () => {
        let response = await Get("site-rt/mission-page");
         
        if (response?.status === true) {
            SetTitle(response?.missionPage?.title ?? "");
            SetText(response?.missionPage?.text ?? "");
        }

        SetFirstLoading(true);
    }

    useEffect(GetData, []);

    if (!firstLoading) return <Skeleton/>
    return (
        <div>
            <form>
                <FormGroup>
                    <Label
                        label="Título"
                    />
                    <Input
                        placeholder="Título de 'Missões'"
                        variant="filled"
                        value={title}
                        onChange={e => SetTitle(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Texto sobre a missão"
                    />
                    <Input
                        placeholder="Texto sobre a missão"
                        value={text}
                        onChange={e => SetText(e.target.value)}
                    />
                    <br/>
                </FormGroup>
            </form>
            <br/>
            <div className="w100 inline-flex jcsb margin-top-1">
                <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar configurações"
                    loadingtext="Salvando configurações"
                    loading={loading}
                    onClick={() => {
                        const data = {
                            title: title,
                            text: text
                        };

                        onConfirm(data);
                    }}
                />
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
        </div>
    );
}

export default FormBody;
/**
 *
 * Form of BottomLead
 *
 */

import { Box, TextField, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Post } from "utils/request";
import { validateEmail } from "utils/filters";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";

const Formbody = (props) => {
  const { ...others } = props;
  const user = useSelector((store) => store.AppReducer.user);

  const [name, SetName] = useState("");
  const [phone, SetPhone] = useState("");
  const [email, SetEmail] = useState("");

  const [nameError, SetNameError] = useState("");
  const [phoneError, SetPhoneError] = useState("");
  const [emailError, SetEmailError] = useState("");
  const [submited, SetSubmited] = useState(false);

  const history = useHistory();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (!name) return SetNameError("Insira um nome");
    else SetNameError("");

    if (!phone) return SetPhoneError("Insira um telefone");
    else SetPhoneError("");

    if (!email) return SetEmailError("Insira um e-mail");
    else if (!validateEmail(email))
      return SetEmailError("Insira um e-mail válido");
    else SetEmailError("");

    let form = new FormData();
    form.append("email", email);
    form.append("name", name);
    form.append("phone", phone);
    form.append("course_id", props.course_id);
    let response = await Post("leadscourse/create", form);
    if (response?.status === true) {
      SetEmail("");
      SetName("");
      SetPhone("");
    }
    SetSubmited(response?.status || false);
  };

  const phoneMaskBrazil = (e) => {
    var element = e.target;

    var isAllowed = /\d|Backspace|Tab/;
    if (isAllowed.test(e.nativeEvent.data)) {
      var inputValue = element.value;
      inputValue = inputValue.replace(/\D/g, "");
      inputValue = inputValue.replace(/(^\d{2})(\d)/, "($1) $2");
      inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/, "$1-$2");

      SetPhone(inputValue);
    }
  };

  useEffect(() => {
    if (user?.name) SetName(user?.name);
    if (user?.email) SetEmail(user?.email);
    if (user?.phone) SetPhone(user?.phone);
  }, [user]);

  return (
    <div
      {...others}
      style={{
        display: "flex",
        gap: "2rem",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Box
        // hidden={submited}
        sx={{
          display: "flex",
          gap: "1rem",
          maxHeight: submited ? "0rem" : "5rem",
          maxWidth: submited ? "0rem" : "1920px",
          transition: "max-height 1s ease, max-width 0ms 1s",
          overflow: "hidden",
          padding: "0.4rem 0",
        }}
      >
        <TextField
          placeholder={"Nome completo"}
          label={"Nome completo"}
          onChange={(e) => SetName(e.target.value)}
          value={name}
          helperText={nameError}
          size={"small"}
          sx={{ flexBasis: "12rem" }}
          error={Boolean(nameError)}
        />
        <TextField
          placeholder={"E-mail"}
          label={"E-mail"}
          onChange={(e) => SetEmail(e.target.value)}
          value={email}
          helperText={emailError}
          size={"small"}
          sx={{ flexBasis: "20rem" }}
          error={Boolean(emailError)}
        />
        <TextField
          placeholder={"Telefone"}
          label={"Telefone"}
          onChange={(e) => phoneMaskBrazil(e)}
          inputProps={{ maxLength: 15 }}
          value={phone}
          helperText={phoneError}
          size={"small"}
          error={Boolean(phoneError)}
        />
        <Button
          variant={"outlined"}
          color="primary"
          onClick={HandleSubmit}
          sx={{
            width: "max-content",
            justifySelf: "center",
            flexBasis: "max-content",
            height: "max-content",
          }}
        >
          Cadastrar
        </Button>
      </Box>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography
          // hidden={!submited}
          style={{
            color: "rgb(0, 150, 0)",
            textAlign: "center",
            justifySelf: "center",
            alignItems: "center",
            display: "flex",
            backgroundColor: "rgba(0, 220, 0, 0.1)",
            padding: submited ? "1rem 2rem" : "0",
            borderRadius: "0.6rem",
            maxHeight: submited ? "5rem" : "0rem",
            maxWidth: submited ? "20rem" : "0rem",
            transition: "1s ease 1.2s, max-width 0ms",
            overflow: "hidden",
            gap: "0.4rem",
          }}
          variant="body1"
        >
          <CheckCircleIcon />
          <span style={{ display: "inline-block" }}>Cadastro efetivado!</span>
        </Typography>

        <Typography variant={"body2"}>
          *Entraremos em contato com você pelo Whatsapp para facilitar nossa
          comunicação
        </Typography>
      </div>
    </div>
  );
};

export default Formbody;

import React, { useEffect, useState } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { Show } from "../../../actions/SnackbarActions";
import { Get } from "../../../utils/request";
import { Typography } from "@mui/material";
import { Secs2Time } from "utils/transformations";

import DefaultButton from "../../../components/DefaultButton";
import Tabs from "./Tabs";
import ShowMoreText from "components/ShowMoreText";
import { removeHTML } from "utils/filters";

const CoursePage = () => {
  const [image, SetImage] = useState("");
  const [name, SetName] = useState("");
  const [description, SetDescription] = useState("");
  const [responsibles, SetResponsibles] = useState([]);
  const [duration, SetDuration] = useState(0);

  const [firstLoading, SetFirstLoading] = useState(false);

  const user = useSelector((store) => store.AppReducer.user);
  const forcedRole = useSelector((store) => store.AdminReducer.forcedRole);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const GetData = async () => {
    let response = await Get(`courses-main?id=${id}&filter_content=1`);
    SetFirstLoading(true);
     
    if (response?.status === true) {
      SetImage(response?.course.image);
      SetName(response?.course.name);
      SetDescription(response?.course.description);
      SetResponsibles(response?.course?.responsibles);
      SetDuration(response?.course?.duration);
    } else if (response?.status !== false) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
        })
      );
      return;
    } else if (!response) {
      dispatch(
        Show({
          show: true,
          severity: "warning",
          message: "Falha ao carregar informações do curso",
        })
      );
    }
  };

  useEffect(() => {
    if (user?.role === "Admin" && forcedRole === "Aluno")
      history.push(`/courses/${id}`);
  }, [forcedRole]);

  useEffect(GetData, []);

  if (!firstLoading) return <Skeleton />;
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="title">{removeHTML(name)}</h3>
        <div style={{ marginTop: 10 }}>
          <b style={{ float: "left", marginRight: "5px" }}>
            Responsável(eis):{" "}
          </b>
          {responsibles?.map((value, key) => (
            <span key={key}>
              <a href={`/profile/view/${value?.id}`} target={"_blank"}>
                <span key={key}>{value?.name}</span>
              </a>
              <span hidden={responsibles?.length - 1 == key}>, </span>
            </span>
          ))}
          <span hidden={responsibles?.length != 0}>
            Sem responsáveis cadastrados
          </span>
        </div>
        <Typography style={{ marginTop: 10 }}>
          <b>Tempo de curso: </b>
          {Secs2Time(duration) !== 'NaN:NaN:NaN' ? Secs2Time(duration) : "00:00"}
        </Typography>
        <div style={{ marginTop: 10 }}>
          <b style={{ marginRight: "5px" }}>Descrição: </b>
          <ShowMoreText maxChar={50}>{removeHTML(description)}</ShowMoreText>
        </div>
        <Tabs />
        <br />
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to="/courses/list"
        />
      </div>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="skeleton-title" />
        <div className="skeleton-small-text skeleton-shimmer" />
        <div className="skeleton-small-text skeleton-shimmer" />
        <br />
        <div className="w100 flex jcc align-center">
          <div
            className="skeleton-base skeleton-shimmer"
            style={{ width: "12em", height: "12em" }}
          />
        </div>
        <br />
        <div
          className="w100 skeleton-base skeleton-shimmer margin-bottom"
          style={{ minHeight: "48px" }}
        />
        <div
          className="w100 skeleton-base skeleton-shimmer margin-bottom"
          style={{ minHeight: "5em" }}
        />
        <div className="skeleton-small-button skeleton-shimmer" />
      </div>
    </div>
  );
};

export default CoursePage;

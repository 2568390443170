import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { removeHTML } from "utils/filters";
import { STORAGE_URL, THIS_URL } from "variables";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import Table from "./OtherOptionsTable";
import { Get, Post } from "utils/request";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConfigCheckout from "./OtherOptionsTable/Checkout";

const TestimonialsModal = ({
  id,
  testimonials,
  open,
  testimonials_loading,
  handleClose,
  refetch,
}) => {
  const dispatch = useDispatch();
  const [openDepoimentoForm, setOpenDepoimentoForm] = useState(false);
  const [isLoading, SetLoading] = useState(false);
  const [course, SetCourse] = useState({});

  const Submit = async (file) => {
    let form = new FormData();
    form.append("banner", file);
    form.append("id", id);

    SetLoading(true);
    let response = await Post("courses-main/upload-testimonial-image", form);
    SetLoading(false);

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );
    refetch();
  };

  const GetData = async () => {
    let response = await Get(`courses-main/reply/${id}`);
    if (response?.status) {
      SetCourse(response?.course);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <Modal open={open} onClose={handleClose} sx={{}}>
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "50%",
          left: "50%",
          maxHeight: "90vh",
          transform: "translate(-50%, -50%)",
          borderRadius: "0.6rem",
          padding: "4rem 2rem",
          width: "100%",
          maxWidth: "60rem",
          gap: "2rem",
          zIndex: 1200,
          overflowX: "hidden",
          overflowY: "auto",

          "& .MuiAccordionSummary-root": {
            backgroundColor: "#fff",
            borderBottom: "1px solid #ddd",
          },
        }}
      >
        <CloseIcon
          onClick={handleClose}
          style={{
            cursor: "pointer",
            fontSize: "2.2rem",
            position: "absolute",
            right: "1rem",
            top: "1rem",
          }}
        />

        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
          }}
        >
          Cadastrar depoimentos
        </Typography>

        <div
          className="card-body"
          style={{
            width: "100% !important",
          }}
        >
          <p className="text-muted">
            Formatos aceitos: .jpg, .jpeg, .png e .gif.
          </p>
          <p className="text-muted">
            Tamanhos recomendados: Qualquer tamanho é aceito. 
          </p>
          <hr />
          <div
            className="flex margin-bottom"
            style={{
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              component="label"
              sx={{
                width: "max-content",
              }}
            >
              Adicionar depoimento
              <input
                hidden
                type="file"
                accept="image/*"
                onChange={(e) => {
                  Submit(e.target.files[0]);
                }}
              />
            </Button>
          </div>
          <Table
            {...{
              banners: testimonials,
              testimonials_loading,
              refetch: GetData,
            }}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default TestimonialsModal;

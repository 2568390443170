import { Box, Button } from "@mui/material";
import { FormControl as Input } from "react-bootstrap";
import { motion } from "framer-motion";
import { useState } from "react";

import AutorenewIcon from '@mui/icons-material/Autorenew';

import styles from "./index.module.css";
import { generateButtonVariants } from "./variants";

const CodeInput = props => {
    const {
        onChange = () => {},
        ...others
    } = props;

    const [rotate, SetRotate] = useState(false);

    const RandomCode = () => Math.random().toString(36).substring(2, 7).toUpperCase();

    console.log(rotate);

    return (
        <Box
            display={"inline-flex"}
            alignItems={"center"}
            width="100%"
        >
            <Button
                className={styles.button}
                onClick={() => {
                    onChange(RandomCode() + RandomCode());
                    SetRotate(!rotate);
                }}
                >
                <motion.div
                    variants={generateButtonVariants}
                    animate={rotate ? "on" : "off"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 24
                    }}
                >
                    <AutorenewIcon/>
                </motion.div>
            </Button>
            <Input
                {...others}
                onChange={e => onChange(e.target.value)}
                className={styles.input}
            />
        </Box>
    );
}

export default CodeInput;
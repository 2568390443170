import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DateFormat } from "utils/transformations";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import styles from "./index.module.css";

const Board = (props) => {
  const { id, image, title, description, createdAt, createdBy } = props;

  const history = useHistory();
  const matches = useMediaQuery("(min-width: 600px)");

  return (
    <Box
      className={styles.card}
      onClick={() => history.push(`/science-plus/${id}/view`)}
      component={motion.div}
      whileTap={{
        scale: 0.9,
      }}
    >
      <Box display="flex" flexDirection={matches ? "row" : "column"}>
        <CardMedia
          component="img"
          image={image}
          alt={"Blog post thumbnail"}
          style={{
            width: "140px",
            aspectRatio: "272/141",
          }}
        />
        <CardContent style={{ flex: 1, paddingTop: 0, paddingBottom: 0 }}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                variant="h6"
                style={{
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Typography>
              <Typography
                className="text-truncate"
                style={{
                  maxWidth: "15em",
                }}
              >
                {description}
              </Typography>
            </Box>
            <Box
              display="inline-flex"
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="body2">{DateFormat(createdAt)}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Box>
  );
};

export default Board;

import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { DeleteButton, ViewButton } from "utils/Buttons";

import useComments from "./useComments";
import { THIS_URL, URL } from "variables";
import { removeHTML } from "utils/filters";

const Comments = () => {
  const { comments, deleteComment, refetch } = useComments();

  const theme = useTheme();

  const [commentSelect, setCommentSelect] = useState({});
  const [popup, SetPopup] = useState(false);

  return (
    <Box>
      <Box
        component={Typography}
        variant={"h5"}
        style={{
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1),
        }}
      >
        <b>Comentários</b>
      </Box>
      <Box
        component={Card}
        variant={"outlined"}
        sx={{
          gap: "1rem",
          boxShadow: "none",
          border: "none",
          borderRadius: "0.6rem",
          padding: 0,
        }}
      >
        <CardContent
          sx={{
            gap: "0.4rem",
            padding: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {comments?.map((comment, commentKey) => (
            <Box
              key={commentKey}
              component={Card}
              marginBottom={theme.spacing(0.5)}
              padding={2}
              display={"flex"}
              width={"100%"}
              justifyContent={"space-between"}
              raised
              sx={{
                padding: "1rem",
                backgroundColor: "#f5f5f5",
                boxShadow: "none",
                border: "none",
                borderRadius: "0.6rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography
                      variant={"subtitle1"}
                      style={{ fontWeight: "bold" }}
                    >
                      {removeHTML(comment?.course?.name)}
                    </Typography>
                    -
                    <Typography
                      variant={"subtitle1"}
                      style={{ fontWeight: "bold" }}
                    >
                      {removeHTML(comment?.lesson?.title)}
                    </Typography>
                  </div>

                  <Box>
                    <ViewButton
                      title={"Ver comentário"}
                      onClick={() =>
                        window.open(
                          `${THIS_URL}courses/${comment?.course?.id}/${comment?.file?.id}`,
                          "_blank"
                        )
                      }
                    />
                    <DeleteButton
                      title={"Deletar comentário"}
                      onClick={() => {
                        setCommentSelect(comment);
                        SetPopup(true);
                      }}
                    />
                  </Box>
                </div>

                <Typography key={commentKey} id={comment?.id}>
                  {comment?.comment}
                </Typography>
              </div>
            </Box>
          ))}
          {comments?.length == 0 && (
            <Typography align={"center"}>
              Nenhum comentário encontrado
            </Typography>
          )}
        </CardContent>
      </Box>

      <SweetAlert
        warning
        title="Atenção"
        show={popup}
        showCancel
        cancelBtnStyle={{ color: "white", textDecoration: "none" }}
        cancelBtnCssClass="btn-danger"
        cancelBtnText="Cancelar"
        confirmBtnText="Excluir"
        onConfirm={async () => {
          SetPopup(false);
          await deleteComment(commentSelect.id);
          refetch();
        }}
        onCancel={() => SetPopup(false)}
      >
        Deseja mesmo excluir o comentário {commentSelect.comment}?
      </SweetAlert>
    </Box>
  );
};

export default Comments;

import { combineReducers } from 'redux';
import AppReducer from './AppReducer';
import SnackbarReducer from './SnackbarReducer';
import StylesReducer from './StylesReducer';
import AdminReducer from './admin.reducer';

export default combineReducers({
   AppReducer,
   SnackbarReducer,
   StylesReducer,
   AdminReducer
});
import { useEffect, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import JoditEditor from "jodit-react";
import { Post } from "utils/request";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import tutorial from "assets/videos/saude_integrativa_tuto.mp4";
// mui icons
import CloseIcon from "@mui/icons-material/Close";
import Card from "./Card";
import { fileToBase64 } from "utils/helpers";
import Label from "components/InfoLabel";
import { STORAGE_URL } from "variables";
import ImageInput from "components/Inputs/ImageInput";

const JoditConfig = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarInlineForSelection: true,
  showPlaceholder: false,
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "font",
    "fontsize",
    "brush",
  ],
  height: 100,
  minHeight: 100,
  readonly: false,
  defaultLanguage: "pt_br",
  language: "pt_br",
  toolbarAdaptive: false,
};

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  maxWidth: "100%",
  minWidth: "36rem",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
};

const FormPaginaInicial = (props) => {
  const {
    title,
    setTitle,

    title_color,
    setTitleColor,

    subtitle,
    setSubtitle,

    subtitle_color,
    setSubtitleColor,

    text,
    setText,

    background_image,
    setBackground_image,

    cards,
    addCard,
    removeCard,
    handleCardChange,

    SetPopup,
    SetPopupMessage,
  } = props;

  const dispatch = useDispatch();

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [card_title, setCard_title] = useState("");
  const [card_text, setCard_text] = useState("");
  const [card_image, setCard_image] = useState(null);

  const handleAddCard = () => {
    if (card_title === "" || card_text === "") {
      SetPopup(true);
      SetPopupMessage("Preencha todos os campos do cartão.");
      return;
    }

    addCard({
      title: card_title,
      text: card_text,
      image: card_image,
    });

    setCard_text("");
    setCard_title("");
    setCard_image(null);

    dispatch(
      Show({
        show: true,
        severity: "success",
        message: "Adicionado com sucesso",
      })
    );
    handleClose();
  };

  const handleAddBanner = (file) => {
    fileToBase64(file).then((base64) => {
      setBackground_image({
        image_url: base64,
        file: file,
      });
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Label
        label={<span>Imagem de fundo</span>}
        required
        title="Capa da página."
      />
      <div
        style={{
          width: "20rem",
          height: "10rem",
          backgroundImage: `url(${
            background_image?.file === null
              ? STORAGE_URL + background_image?.image_url
              : background_image?.image_url
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          border: "0.2rem dashed #ccc",
        }}
      ></div>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Tamanho recomendado:
        1900x500 (19:5), 1520x400 (19:5) ou 1140x300 (19:5).
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não se recomenda usar
        outra proporção de tela além da proporção 19:5 porque a imagem será
        usada como capa da página Saúde Integrativa, e essa capa tem uma altura
        bem menor do que a largura.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
        .jpeg, .png e .gif.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo.
      </span>

      <Button
        variant="contained"
        component="label"
        sx={{
          width: "max-content",
        }}
      >
        {background_image?.image_url ? "Alterar" : "Adicionar"} imagem de fundo
        <input
          hidden
          type="file"
          onChange={(e) => {
            handleAddBanner(e.target.files[0]);
          }}
        />
      </Button>
      <FormLabel>
        <Label label={<span>Título</span>} required title="Título da página." />
        <JoditEditor
          config={JoditConfig}
          value={title}
          onChange={(value) => setTitle(value)}
        />
      </FormLabel>

      <FormLabel>
        <Label
          label={<span>Subtítulo </span>}
          required
          title="Subtítulo  da página."
        />
        <JoditEditor
          config={JoditConfig}
          value={subtitle}
          onChange={(value) => setSubtitle(value)}
        />
      </FormLabel>

      <FormLabel>
        <Label
          label={<span>Texto </span>}
          required
          title="Conteúdo principal da página."
        />
        <JoditEditor
          value={text}
          onChange={setText}
          config={{ defaultLanguage: "pt_br", language: "pt_br" }}
        />
      </FormLabel>

      <div className="MuiBox-root css-0">
        <p>
          <strong>Insira todo o texto do word aqui.&nbsp;</strong>
        </p>
        <p>
          Não há problemas em copiar e colar diretamente aqui, apenas
          certifique-se de clicar no botão{" "}
          <button
            className="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_text-icons_true jodit-ui-button_keep jodit-ui-button_status_primary"
            type="button"
            role="button"
            aria-pressed="false"
          >
            <span className="jodit-ui-button__icon"></span>
            <span className="jodit-ui-button__text">Manter</span>
          </button>{" "}
          para que a estilização seja insirida também.
        </p>
      </div>
      
      <video controls src={tutorial} type="video/mp4" />


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modal_style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              padding: "3rem 4rem",
            }}
          >
            <CloseIcon
              sx={{
                position: "absolute",
                top: "0.8rem",
                right: "0.8rem",
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <Typography variant="h5" style={{ marginBottom: "1rem" }}>
              Adicionar cartão
            </Typography>

            <FormLabel>
              Título <span style={{ color: "red" }}>*</span>
              <JoditEditor
                config={JoditConfig}
                value={card_title}
                onChange={(value) => setCard_title(value)}
              />
            </FormLabel>

            <FormLabel sx={{ width: "24rem" }}>
              Texto <span style={{ color: "red" }}>*</span>
              <JoditEditor
                config={JoditConfig}
                value={card_text}
                onChange={(value) => setCard_text(value)}
              />
            </FormLabel>

            <FormLabel sx={{ width: "24rem" }}>
              Imagem do cartão
              <ImageInput
                onImageChangeBase64={(image) => {
                  setCard_image(image);
                }}
              />
            </FormLabel>

            <Button
              variant="contained"
              onClick={handleAddCard}
              sx={{
                width: "max-content",
                alignSelf: "flex-end",
                marginTop: "1rem",
              }}
            >
              Adicionar cartão
            </Button>
          </div>
        </div>
      </Modal>

      <Label
        label={<span>Cartões</span>}
        required
        title="Cartões de texto da página."
      />
      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          maxWidth: "36rem",
          maxHeight: "70vh",
          overflowY: "auto",
          padding: "0.5rem",
        }}
      >
        {cards !== undefined &&
          cards?.map((card, index) => {
            return (
              <Card
                key={index}
                index={index}
                card={card}
                modal_style={modal_style}
                removeCard={removeCard}
                handleCardChange={handleCardChange}
              />
            );
          })}
        {cards?.length === 0 && (
          <Typography
            variant="subtitle"
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              padding: "1rem",
              borderRadius: "0.5rem",
            }}
          >
            Nenhum item adicionado
          </Typography>
        )}
      </div>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          width: "max-content",
        }}
      >
        Adicionar cartão
      </Button>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não adicione imagens no
        campo de edição de texto.
      </span>
      <span>Utilize este cartão para inserir textos curtos e objetivos.</span>
    </div>
  );
};

export default FormPaginaInicial;

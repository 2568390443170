import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Get, Post } from "utils/request";
import { Show } from "actions/SnackbarActions";

const daysScale = [
    0,
    5 * 60,//5min
    30 * 60,//30min
    120 * 60,//2hrs
    360 * 60,//6hrs
    720 * 60,//12hrs
    1440 * 60,//1d
    1440 * 3 * 60,//3ds
    1440 * 10 * 60,//10ds
    1440 * 30 * 60,//1m
    1440 * 90 * 60,//3m
    1440 * 30 * 12 * 60,//1y
];

const useFormbody = (props) => {

    const { register, handleSubmit, watch, control, reset } = useForm({
        defaultValues: {
            primary_color: "#fff",
            secondary_color: "#fff",
            tertiary_color: "#fff"
        }
    });

    const dispatch = useDispatch();

    const { isLoading } = useQuery(["general/styles"], async () => await Get("styles/general"), {
        onSuccess: (data) => {
            if (data?.httpCode !== 200) throw new Error("Falha ao carregar estilos");
            
            reset(data);
        },
        refetchOnWindowFocus: false
    });

    const { mutate } = useMutation(async (data) => await Post("styles/general/update", data), {
        onSuccess: (data) => {
            if (data?.httpCode !== 200) throw new Error(data?.message);
            dispatch(Show({
                show: true,
                message: data?.message,
                severity: "success"
            }));
        },
        onError: (error) => dispatch(Show({
            show: true,
            message: error,
            severity: "error"
        }))
    });

    const onSubmit = handleSubmit((data) => {
        data["expiration_in_seconds"] = daysScale[data["expiration_in_seconds"]];
        const form = new FormData();
        for (const key of Object.keys(data)) form.append(key, data[key] ?? "");
        mutate(form);
    }, (errors) => {
        const firstErrorMessage = errors[Object.keys(errors)[0]].message;
        dispatch(Show({
            show: true,
            message: firstErrorMessage,
            severity: "error"
        }));
    });

    return {
        control,
        daysScale,
        isLoading,
        register,
        onSubmit,
        watch
    };
}

export default useFormbody;
import DefaultButton from "components/DefaultButton";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { removeHTML } from "utils/filters";
import { numToFixed } from "utils/helpers";
import { Modal } from "@mui/material";

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  maxWidth: "100%",
  minWidth: "36rem",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
  padding: "2rem",
};

const Board = ({ course }) => {
  const [open, setOpen] = useState(false);

  console.log(course.user);
  console.log(course);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div style={modal_style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              gap: "1rem",
            }}
          >
              <h2>Detalhes da compra</h2>
              

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4>Comprador</h4>
              <DefaultButton
                to={`/profile/view/${course?.user?.id}`}
                width="2.2em"
                height="2.2em"
                padding={0}
                title={`Ver perfil`}
                bg="info"
                icon={<VisibilityIcon size={17} color="white" />}
                style={{
                  marginRight: "0.6rem",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <p>
                <b>Nome:</b> {course?.user?.name}
              </p>
              <p>
                <b>Email:</b> {course?.user?.email}
              </p>
              <p>
                <b>Telefone:</b> {course?.user?.phone || "Não informado"}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4>Curso</h4>
              <DefaultButton
                to={`/courses/adquire/${course?.id}`}
                width="2.2em"
                height="2.2em"
                padding={0}
                title={`Ver curso`}
                bg="info"
                icon={<VisibilityIcon size={17} color="white" />}
                style={{
                  marginRight: "0.6rem",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <p>
                <b>Nome:</b> {removeHTML(course?.name)}
              </p>
              <p>
                <b>Preço original:</b> R${" "}
                {numToFixed(course?.total_price, 2).replace(".", ",")}
              </p>
              {course?.has_coupon && (
                <p>
                  <b>preço com cupom:</b> R$ $
                  {numToFixed(course?.calculated_price, 2).replace(".", ",")}
                </p>
              )}
              {course?.has_coupon && (
                <p>
                  <b>Cupom:</b> {course?.coupon?.code}
                </p>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <tr>
        <td>
          {/* <DefaultButton
            to={`/profile/view/${course?.user?.id}`}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Ver perfil`}
            bg="info"
            icon={<VisibilityIcon size={17} color="white" />}
            style={{
              marginRight: "0.6rem",
            }}
          /> */}
          {course?.user?.name}
        </td>
        <td>{course?.user?.email}</td>
        <td>{course?.user?.phone || "Não informado"}</td>
        <td>
          {/* <DefaultButton
            to={`/courses/adquire/${course?.id}`}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Ver curso`}
            bg="info"
            icon={<VisibilityIcon size={17} color="white" />}
            style={{
              marginRight: "0.6rem",
            }}
          /> */}
          {removeHTML(course?.name)}
        </td>
        <td>
          {new Date(course?.created_at).toLocaleDateString("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </td>
        <td
          style={{
            fontWeight: "bold",
            backgroundColor: course?.has_coupon ? "#e6ffe6" : "#ffe6e6",
          }}
        >
          {course?.has_coupon ? "Sim" : "Não"}
        </td>
        {/* <td>
          <p>
            <b>Preço original:</b> R${" "}
            {numToFixed(course?.total_price, 2).replace(".", ",")}
          </p>
          {course?.has_coupon && (
            <p>
              <b>preço com cupom:</b> R$ $
              {numToFixed(course?.calculated_price, 2).replace(".", ",")}
            </p>
          )}
          {course?.has_coupon && (
            <p>
              <b>Cupom:</b> {course?.coupon?.code}
            </p>
          )}
        </td> */}
        <td>
          <DefaultButton
            onClick={() => setOpen(true)}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Ver detalhes`}
            bg="info"
            icon={<VisibilityIcon size={17} color="white" />}
            style={{
              marginRight: "0.6rem",
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default Board;

import { TextField } from "@mui/material";

const CustomInput = ({
  label,
  value,
  type="text",
  error,
  onChange,
  maxLenght,
  flexBasis,
  disabled,
  emptyFields
}) => {
  return (
    <TextField
      fullWidth
      size="small"
      label={label}
      InputProps={{ inputProps: { type: type } }}
      value={value}
      onChange={onChange}
      maxRows={maxLenght}
      disabled={disabled}
      sx={{
        flexBasis: flexBasis,
        flexGrow: 1,
      }}
      error={emptyFields && value === ""}
      // helperText={emptyFields && value === "" ? "Campo obrigatório" : ""}
    />
  );
};

export default CustomInput;

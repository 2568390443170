import { useEffect, useState } from "react"
import { Get } from "utils/request";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import DefaultButton from 'components/DefaultButton';
import VideoInput from "components/Inputs/VideoInput";
import ImageInput from "components/Inputs/ImageInput";
import { RiArrowGoBackLine } from "react-icons/ri";

const Formbody = props => {

    const {
        OnConfirm,
        loading
    } = props;

    const [banner, SetBanner] = useState("");
    const [link, SetLink] = useState("");

    const GetData = async () => {
        let response = await Get(`site-rt/aside-banner`);
        if (response?.status === true) {
            SetBanner(response?.banner?.banner);
            SetLink(response?.banner?.link);
        }
    }

    useEffect(GetData, []);

    return (
        <div className="form-course">
            <form>
                <FormLabel>Imagem</FormLabel>
                <ImageInput
                    path={banner}
                    ImageChange={image => SetBanner(image)}
                />
                <br/>

                <FormLabel>Link</FormLabel>
                <Input
                    placeholder="Link"
                    value={link}
                    onChange={e => SetLink(e.target.value)}
                />
            </form>
            <br />
            <div className="w100 inline-flex jcsb mt-2">
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine/>}
                    to={`/site-rt/aside-banner`}
                />
                <DefaultButton
                    bg="confirm"
                    text="Salvar"
                    onClick={() => {
                        const data = {
                            link: link,
                            banner: banner
                        };
                        OnConfirm(data);
                    }}
                    loading={loading}
                />
            </div>
        </div>
    )
}
export default Formbody;
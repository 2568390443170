import { createTheme } from "@mui/material";
import { isColor } from "./isColor";

export const getContrastText = (color) => {
    if (!isColor(color)) color = "#fff";
    const theme = createTheme({
        palette: {
            primary: {
                main: color
            }
        }
    });

    return theme.palette.primary.contrastText;
}
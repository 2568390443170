import { Box, Typography } from "@mui/material";

import styles from "./index.module.css";

const Intro = props => {

    const {
        title,
        text
    } = props;

    return (
        <Box
            className={styles.container}
            color="secondary.contrastText"
        >
            <Typography
                align="center"
                color="secondary"
            >
                Nossa missão
            </Typography>
            <Typography
                variant="h4"
                gutterBottom
            >
                {/* Qualidade, Integridade e Experiência */}
                {title}
            </Typography>
            <Typography
                align="center"
            >
                {text}
            </Typography>
        </Box>
    );
}

export default Intro;
import { Typography } from "@mui/material";
import Paginate from "components/Paginate";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Get } from "utils/request";
import Board from "./Board";

const Table = props => {

    const {
        search
    } = props;

    const [page, setPage] = useState(1);

    const filters = useSelector(store => store.AppReducer.logFilters);
    const { isLoading, data, refetch } = useQuery(["logs", filters], async () => {
        let filtersTables = "";
        let filtersActions = "";
        if (filters) {
            filtersTables = filters.filter(x => x?.type == "table").map(x => x.value).join(",");
            filtersActions = filters.filter(x => x?.type == "action").map(x => x.value).join(",");
        }
        return await Get(`log?actions=${filtersActions}&tables=${filtersTables}&page=${page}`);
    });
    const {
        logs = [],
        pagination: {
            lastPage: maxPage = 1
        } = {}
    } = data || {}
    
    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Registro</th>
                        <th>Ação</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && logs.length != 0 && logs.map((log, key) => (
                        <Board
                            key={key}
                            onDelete={refetch}
                            data={log}
                        />
                    ))}
                </tbody>
            </table>
            {!logs || logs?.length == 0 && (
                <Typography
                    align={"center"}
                >
                    Nenhum log encontrado
                </Typography>
            )}
            <Paginate
                maxPage={maxPage}
                value={page}
                onChange={setPage}
            />
        </div>
    );
}

export default Table;
import { Box, TableCell, TableRow, Typography, LinearProgress } from "@mui/material";

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { green, red } from "@mui/material/colors";
import { DownloadButton, RefreshButton } from "utils/Buttons";
import { API_URL } from "variables";
import useCertificates from "../useCertificates";

const Board = (props) => {

    const {
        id: certificateId,
        certificate_path: certificatePath,
        has_downloaded: hasDownloaded,
        course: {
            name: courseName
        } = {}
    } = props;

    const {
        regenerate: {
            mutate = () => {},
            isLoading
        } = {}
    } = useCertificates();

    return (
        <>
            <TableRow>
                <TableCell>{courseName}</TableCell>
                <TableCell>
                    {hasDownloaded ? (
                        <CheckIcon
                            sx={{ color: green[800] }}
                        />
                    ) : (
                        <ClearIcon
                            sx={{ color: red[800] }}
                        />
                    )}
                </TableCell>
                <TableCell>
                    <DownloadButton
                        title={"Baixar certificado"}
                        href={`${API_URL}certificate/download?certificate_id=${certificateId}`}
                        component={"a"}
                        target={"_blank"}
                        disabled={isLoading}
                    />
                    <RefreshButton
                        title={"Gerar novo certificado"}
                        onClick={() => mutate(certificateId)}
                        disabled={isLoading}
                    />
                </TableCell>
            </TableRow>
            {isLoading && (
                <TableRow>
                    <TableCell
                        colspan={3}
                        sx={{
                            p: 0
                        }}
                    >
                        <LinearProgress/>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

export default Board;
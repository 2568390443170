import { Box, Slider } from "@mui/material";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import ImageInput from "components/Inputs/ImageInput";
import Label from "components/InfoLabel";
import { Controller } from "react-hook-form";
import { SecondsFormat } from "utils/transformations";
import useFormbody from "../useFormbody";

const OthersData = (props) => {
    
    const {
        register,
        control
    } = props;

    const {
        daysScale
    } = useFormbody();

    return (
        <Box>
            <Label
                label="Título"
                title="O título do site é o nome do site que aparecerá nas guias do navegador"
                required
            />
            <Input
                placeholder="Título do site"
                variant="filled"
                // value={title}
                // onChange={e => SetTitle(e.target.value)}
                {...register("title", {
                    required: "Insira um título"
                })}
            />
            <br/>

            <Label
                label="Ícone"
                title="O ícone é a imagem que aparecerá nas guias do navegador. Tamanho recomendado: 32x32. Atenção! A imagem pode ser menor ou maior que os tamanhos recomendados."
                required
            />
            <br/>
            <Controller
                control={control}
                name={"icon"}
                rules={{
                    required: "Insira um ícone"
                }}
                render={({ field: { onChange, value } }) => (
                    <ImageInput
                        ImageChange={onChange}
                        path={value}
                    />        
                )}
            />
            <br/>

            <Label
                label="Logo pequeno"
                title="O logo pequeno será mostrado quando o site for redimensionado em um tamanho muito pequeno. Tamanho recomendado: 52x52. Atenção! A imagem pode ser menor ou maior que os tamanhos recomendados."
                required
            />
            <br/>
            <Controller
                control={control}
                name={"header_icon_small"}
                rules={{
                    required: "Insira um logo pequeno"
                }}
                render={({ field: { onChange, value } }) => (
                    <ImageInput
                        ImageChange={onChange}
                        path={value}
                    />        
                )}
            />
            <br/>

            <Label
                label="Logo maior"
                title="Tamanho recomendado: 500x200. Atenção! A imagem pode ser menor ou maior que os tamanhos recomendados."
                required
            />
            <br/>
            <Controller
                control={control}
                name={"header_icon"}
                rules={{
                    required: "Insira um logo maior"
                }}
                render={({ field: { onChange, value } }) => (
                    <ImageInput
                        ImageChange={onChange}
                        path={value}
                    />        
                )}
            />
            <br/>

            <FormLabel>
                Tempo de expiração de e-mails
            </FormLabel>
            <br/>
            <Controller
                control={control}
                name={"expiration_in_seconds"}
                render={({ field: { onChange, value } }) => (
                    <Slider
                        value={value}
                        min={0}
                        max={11}
                        step={1}
                        scale={x => daysScale[x]}
                        getAriaValueText={SecondsFormat}
                        valueLabelFormat={SecondsFormat}
                        valueLabelDisplay="auto"
                        onChange={(_, value) => onChange(value)}
                        sx={{
                            width: "50%"
                        }}
                    />     
                )}
            />
        </Box>
    );
}

export default OthersData;
import { Box, Button, Container, Typography } from "@mui/material";
import { createContext, useEffect, useState, useCallback } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { Get } from "utils/request";
import logo from "assets/images/rt/icon_transparent.png";

import style from "./style.module.css";
// import CourseContent from "./Tabs/CourseContent";
import Footer from "Home/Footer";
import HeaderSection from "./Sections/HeaderSection";
import WhatWillLearn from "./Sections/WhatWillLearn";
import Responsibles from "./Sections/Responsibles";
import RecommendedTo from "./Sections/RecommendedTo";
import Pricing from "./Sections/Pricing";
import Warranty from "./Sections/Warranty";
import Questions from "./Sections/Questions";

import { useSelector } from "react-redux";
import SkeletonCardList from "modules/LoadingPlaceholders/Skeletons/SkeletonCardList";
import CourseTestimonials from "./Sections/Testimonials";
import WhatWillGet from "./Sections/WhatWillGet";

export const AdquireCourseContext = createContext(null);

const AdquireCourse = () => {
  const token = useSelector((store) => store.AppReducer.token);

  const [course, SetCourse] = useState({});
  const [ready_to_show, SetReadyToShow] = useState(false);

  const { course_id } = useParams();
  const history = useHistory();

  const fetchCourse = () => {
    SetReadyToShow(false);
    Get(`courses-main/one/${course_id}?filter_content=1`)
      .then((res) => {
        if (res?.status === true) {
          SetCourse(res?.course);
          SetReadyToShow(true);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCourse();
  }, []);

  useEffect(() => {
    fetchCourse();
  }, [course_id]);
  
  if (!ready_to_show)
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          width="100"
          height="100"
          className="infiniteRotateAnim"
        />
        <span>Carregando curso...</span>
      </div>
    );

  return (
    ready_to_show && (
      <>
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            maxWidth: "100vw",
          }}
        >
          <HeaderSection {...{ course, style }} />

          <WhatWillLearn {...{ course }} />

          <RecommendedTo {...{ course }} />

          <Responsibles {...{ course }} />

          {course.testimonials?.length > 0 && (
            <CourseTestimonials {...{ testimonials: course.testimonials }} />
          )}

          <WhatWillGet {...{ course }} />

          <Pricing {...{ course, style }} />

          <Warranty {...{ course }} />

          <Questions {...{ course }} />
        </Box>

        <Footer />
      </>
    )
  );
};

export default AdquireCourse;

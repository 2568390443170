import { Box, CircularProgress, Typography } from "@mui/material";

export const CircularProgressLabel = (props) => {
  const { label = "", ...other } = props;

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        {...other}
        style={{
          color: "rgba(0, 0, 0, 0.2)",
          position: "absolute",
        }}
        value={100}
      />
      <CircularProgress variant="determinate" {...other} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {/* <Typography>{label}</Typography> */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
          }}
        >{label}</Typography>
        <Typography
          variant="body"
          sx={{
            color: "rgba(0, 0, 0, 0.5)",
            fontSize: "0.9rem",
          }}
        >Progresso</Typography>

      </Box>
    </Box>
  );
};

import { Box, Typography } from "@mui/material";
import {
  blue,
  deepOrange,
  deepPurple,
  indigo,
  purple,
  red,
} from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import RoleBased from "components/Role/RoleBased";
import Board from "./Board";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";

const useStyles = makeStyles({
  mt: {
    marginTop: 10,
  },
  mb: {
    marginBottom: 10,
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
  },
});

const Actions = (props) => {
  const classes = useStyles();

  return (
    <Box>
      <Box
        component={Typography}
        color={"rgba(0, 0, 0, 0.6)"}
        className={`${classes.mt} ${classes.mb}`}
        sx={{ marginLeft: "0.5rem" }}
      >
        Ações rápidas
      </Box>
      <Box
        display={"flex"}
        // flexDirection={"row"}
        flexWrap={"wrap"}
      >
        <Board
          color={purple}
          title={"Adicionar cursos"}
          link={{
            link: "/courses/add",
            idleIcon: <AddIcon />,
          }}
        />
        <Board
          color={deepPurple}
          title={"Ver cursos"}
          link={{
            link: "/courses/list",
            idleIcon: <VisibilityIcon />,
          }}
        />
        <RoleBased roles={["Admin", "Colaborador"]}>
          <Board
            color={red}
            title={"Adicionar alunos"}
            link={{
              link: "/accounts/create?role=aluno",
              idleIcon: <AddIcon />,
            }}
          />
          <Board
            color={deepOrange}
            title={"Ver alunos"}
            link={{
              link: "/log/accounts",
              idleIcon: <VisibilityIcon />,
            }}
          />

          <Board
            color={blue}
            title={"Adicionar professores"}
            link={{
              link: "/accounts/create?role=professor",
              idleIcon: <AddIcon />,
            }}
          />
          <Board
            color={indigo}
            title={"Ver professores"}
            link={{
              link: "/log/accounts",
              idleIcon: <VisibilityIcon />,
            }}
          />
        </RoleBased>
      </Box>
    </Box>
  );
};

export default Actions;

import React, { useState } from "react";

import { MdDelete } from "react-icons/md";
import DefaultButton from "../../../../components/DefaultButton";
import { Get, Post } from "../../../../utils/request";
import { CircularProgress } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useHistory } from "react-router";
import { BsFillEyeFill } from "react-icons/bs";
import { IoMdPersonAdd } from "react-icons/io";
import { useEffect } from "react";
import { removeHTML } from "utils/filters";

const Board = (props) => {
  const {
    id = "",
    name = "",
    email = "",
    phone = "",
    course_id = "",
    createdAt = "",
    hasActiveAccount = false,
    OnDelete,
  } = props;

  const [loading, SetLoading] = useState(false);
  const [popup, SetPopup] = useState(false);
  const [course, SetCourse] = useState(null);

  const history = useHistory();

  const SendLink = async () => {
    const form = new FormData();
    form.append("id", id);

    const response = await Post("leadscourse/send-link", form);
  };

  const getCourseInfo = async () => {
    let response = await Get(`courses-main/one/${course_id}`);
    if (response?.status === true) {
      SetCourse(response?.course);
    }
  };

  useEffect(() => {
    getCourseInfo();
  }, []);

  const Delete = async () => {
    let form = new FormData();
    form.append("id", id);

    SetLoading(true);
    let response = await Post("leadscourse/delete", form);
    SetLoading(false);
    if (response?.status) OnDelete();
  };

  return (
    <tr id={id}>
      <td>{name}</td>
      <td>{email}</td>
      <td>{phone !== "null" ? phone : "Não cadastrado"}</td>
      <td>
        {course?.name ? (
          <span>
            {removeHTML(course?.name)}
            <DefaultButton
              to={`/courses/view/${course_id}`}
              width="2.2em"
              height="2.2em"
              padding={0}
              title={`Ver curso`}
              bg="success"
              icon={<BsFillEyeFill size={17} color="white" />}
              style={{
                marginLeft: "0.4rem",
              }}
            />
          </span>
        ) : (
          <span>Carregando...</span>
        )}
      </td>
      <td>{new Date(Date.parse(createdAt)).toLocaleDateString("pt-BR")}</td>
      <td>
        <SweetAlert
          warning
          title="Atenção"
          show={popup}
          showCancel
          cancelBtnStyle={{ color: "white", textDecoration: "none" }}
          cancelBtnCssClass="btn-danger"
          cancelBtnText="Cancelar"
          confirmBtnText="Excluir"
          onConfirm={() => {
            SetPopup(false);
            Delete();
          }}
          onCancel={() => SetPopup(false)}
        >
          Deseja mesmo excluir o e-mail '{email}' do registro?
        </SweetAlert>
        {/* <DefaultButton
          // onClick={() => history.push({
          //     pathname: "/accounts/create",
          //     state: {
          //         email: email,
          //         name: name
          //     }
          // })}
          onClick={SendLink}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={hasActiveAccount ? "Conta já ativada" : `Ativar conta`}
          bg="info"
          loading={loading}
          icon={<IoMdPersonAdd size={17} color="white" />}
          style={{
            marginRight: "2px",
          }}
          disabled={hasActiveAccount}
        /> */}
        <DefaultButton
          to={`/log/leads-course/activity/${id}`}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Ver registros`}
          bg="warning"
          icon={<BsFillEyeFill size={17} color="white" />}
          style={{
            marginRight: "2px",
          }}
        />
        <DefaultButton
          onClick={() => SetPopup(true)}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Deletar registro`}
          bg="danger"
          loading={loading}
          icon={!loading && <MdDelete size={17} color="white" />}
        />
      </td>
    </tr>
  );
};

export default Board;

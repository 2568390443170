import React, { useState } from "react";

import { MdDelete } from "react-icons/md";
import DefaultButton from "components/DefaultButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Post } from "utils/request";
import { Show } from "actions/SnackbarActions";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";
import { STORAGE_URL } from "variables";

const Board = (props) => {
  const { id, banner, link, refetch } = props;

  const [popup, SetPopup] = useState(false);

  const [loading, SetLoading] = useState(false);

  const dispatch = useDispatch();

  const Delete = async () => {
    let form = new FormData();
    form.append("id", id);

    SetLoading(true);
    let response = await Post("courses-main/delete-testimonial-image", form);
    SetLoading(false);

    if (response?.status) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
        })
      );
      refetch();
    }
  };

  return (
    <tr>
      <td>
        <img src={banner ? STORAGE_URL + banner : ""} style={{
          height: "12rem",
          objectFit: "contain"
        }} />
      </td>
      <td>
        <SweetAlert
          warning
          title={"Atenção"}
          onConfirm={() => {
            Delete();
            SetPopup(false);
          }}
          onCancel={() => SetPopup(false)}
          show={popup}
          confirmBtnText="Deletar"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          showCancel={true}
        >
          Tem certeza que deseja deletar esse depoimento?
        </SweetAlert>
        <RoleBased>
          <DefaultButton
            onClick={() => SetPopup(true)}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Deletar depoimento`}
            bg="danger"
            loading={loading}
            icon={!loading && <MdDelete size={17} color="white" />}
          />
        </RoleBased>
      </td>
    </tr>
  );
};

export default Board;

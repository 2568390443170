import { Box, Container, Typography, useTheme } from "@mui/material";
import { transform } from "framer-motion";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Carousel } from "react-responsive-carousel";
import { Get } from "utils/request";

import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import { STORAGE_URL } from "variables";

const FreeCourses = ({ description }) => {
  const [freeCourses, setFreeCourses] = useState([]);
  const [free_lessons_title, setFreeLessonsTitle] = useState("");
  const [free_lessons_two_views, setFreeLessonsTwoViews] = useState(false);
  const [halfwayThrough, setHalfwayThrough] = useState(0);
  const [aside_banners, setAsideBanners] = useState([]);

  useEffect(() => {
    if (freeCourses && freeCourses.length > 0) {
      setHalfwayThrough(Math.floor(freeCourses.length / 2));
    }
  }, [freeCourses]);

  const QueryMultiple = () => {
    const free_lessons = useQuery(["page"], () =>
      Get(`courses-main/free-courses`)
    );
    const aside_banners_data = useQuery(["banners"], () =>
      Get(`site-rt/aside-banner`)
    );
    return [free_lessons, aside_banners_data];
  };

  const [free_lessons, aside_banners_data] = QueryMultiple();

  useEffect(() => {
    if (free_lessons !== undefined) {
      setFreeCourses(free_lessons?.data?.courses);
    }
  }, [free_lessons]);

  useEffect(() => {
    if (aside_banners_data !== undefined) {
      setAsideBanners(aside_banners_data?.data?.banners?.data);
    }
  }, [aside_banners_data]);

  const GetData = async () => {
    let response = await Get(`site-rt/description?type=firstContent`);
    if (response?.status === true) {
      setFreeLessonsTitle(response?.page?.free_courses_title);
      setFreeLessonsTwoViews(
        eval(response?.page?.free_lessons_two_views) || false
      );
    }
  };
  useEffect(() => GetData(), []);

  const getVideoThumbnail = (link) => {
    try {
      const videoId = link.split("=")[1];
      return `https://i3.ytimg.com/vi/${videoId}/sddefault.jpg`;
    } catch {
      return "";
    }
  };

  return (
    <Box
      sx={{
        // padding: "4rem 0",
        backgroundColor: "rgba(122, 93, 180, 0.1)",
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "row",
        },
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
          paddingTop: "2rem",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
          }}
          dangerouslySetInnerHTML={{ __html: free_lessons_title || "" }}
        />
        <div
          style={{
            width: !free_lessons_two_views ? "40rem" : "100%",
            borderRadius: "0.4rem",
            marginTop: "1rem",
            overflow: "hidden",
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          {!free_lessons_two_views && (
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              renderIndicator={false}
              centerMode={false}
              showThumbs={false}
              transitionTime={500}
              interval={description?.free_lessons_transition_time || 5000}
              // maxWidth=""
              showStatus={false}
            >
              {freeCourses &&
                freeCourses.map((course, index) => (
                  <CourseCard
                    key={index}
                    description={course.description}
                    image={getVideoThumbnail(course.link)}
                    link={course.link}
                    title={course.name}
                  />
                ))}
            </Carousel>
          )}

          {free_lessons_two_views && (
            <Box
              sx={{
                display: "flex",
                width: "30rem",
                maxWidth: {
                  xs: "96%",
                  sm: "96%",
                  md: "50%",
                },
                padding: "0.4rem",
              }}
            >
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                renderIndicator={false}
                centerMode={false}
                showThumbs={false}
                transitionTime={500}
                interval={description?.free_lessons_transition_time || 5000}
                // width="400px"
                showStatus={false}
              >
                {/* dividir em dois arrays */}
                {freeCourses &&
                  free_lessons_two_views &&
                  freeCourses
                    .slice(0, halfwayThrough)
                    .map((course, index) => (
                      <CourseCard
                        key={index}
                        description={course.description}
                        image={getVideoThumbnail(course.link)}
                        link={course.link}
                        title={course.name}
                        half
                      />
                    ))}
              </Carousel>
            </Box>
          )}

          {free_lessons_two_views && (
            <Box
              sx={{
                display: "flex",
                width: "30rem",
                maxWidth: {
                  xs: "96%",
                  sm: "96%",
                  md: "50%",
                },
                padding: "0.4rem",
              }}
            >
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                renderIndicator={false}
                centerMode={false}
                showThumbs={false}
                transitionTime={500}
                interval={description?.free_lessons_transition_time || 5000}
                // width="400px"
                showStatus={false}
              >
                {/* dividir em dois arrays */}
                {freeCourses &&
                  free_lessons_two_views &&
                  freeCourses
                    .slice(halfwayThrough, freeCourses.length)
                    .map((course, index) => (
                      <CourseCard
                        key={index}
                        description={course.description}
                        image={getVideoThumbnail(course.link)}
                        link={course.link}
                        title={course.name}
                        half
                      />
                    ))}
              </Carousel>
            </Box>
          )}
        </div>
      </div>
      <div
        style={{
          maxWidth: "25.4rem",
          overflow: "hidden",
        }}
      >
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          renderIndicator={false}
          centerMode={false}
          showThumbs={false}
          transitionTime={500}
          interval={description?.aside_transition_time || 5000}
          showStatus={false}
        >
          {aside_banners &&
            aside_banners.map((banner, index) => (
              <a key={index} href={banner?.link} target="_blank">
                <div
                  style={{
                    width: "25.4rem",
                    height: "50rem",
                    // aspectRatio: "10/3",
                    // aspectRatio: "16/9",
                    backgroundSize: "contain",
                    backgroundImage: `url(${STORAGE_URL + banner?.banner})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </a>
            ))}
        </Carousel>
      </div>
    </Box>
  );
};

const CourseCard = ({ title, description, image, link, half }) => {
  return (
    <a
      href={link}
      target="_blank"
      style={{ textDecoration: "none", color: "black" }}
    >
      <Box
        sx={{
          height: "max-content",
          position: "relative",
          padding: "0.4rem",
          backgroundColor: "white",
          border: "1px solid #e0e0e0",

          transition: "all 250ms ease",
          "&:hover": {
            filter: "brightness(0.8)",
          },
          "&:hover > .free-course-card-open-url": {
            opacity: 1,
          },
        }}
      >
        <Typography
          variant="h5"
          className="text_overflow_file"
          sx={{ marginBottom: "0.4rem" }}
          dangerouslySetInnerHTML={{ __html: title || "" }}
        />
        {/* <Box
          className="free-course-card-open-url"
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            borderRadius: "0.4rem",
            opacity: 0,
            transition: "all 250ms ease",
            display: "flex",
            flexDirection: "column",
            paddingBottom: "0.5rem",
          }}
        >
          <LaunchRoundedIcon
            sx={{
              fontSize: "10rem",
              color: "#42b3f5",
            }}
          />
          <Typography
            variant="body1"
            sx={{ color: "white", textAlign: "center" }}
          >
            Abrir link da aula
          </Typography>
        </Box> */}

        <img
          src={image}
          style={{
            objectFit: "cover",
            aspectRatio: "16/9",
            maxHeight: "30rem",
            borderRadius: "0.2rem",
          }}
        />
        <Typography
          variant="subtitle1"
          sx={{ marginTop: "0.8rem" }}
          dangerouslySetInnerHTML={{ __html: description || "" }}
        />
      </Box>
    </a>
  );
};

export default FreeCourses;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { FormControl as Input } from "react-bootstrap";
import DefaultButton from "components/DefaultButton";
import Table from "./Table";

const BannerPrincipalList = () => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  return(
    <div className="card">
      <div className="card-body">
        <h3 className="title">Banner Principal</h3>
        <p className="text-muted">Aqui são listados os banners principais do site do Itec</p>
        <hr/>
        <div className="flex jcsb flex-wrap margin-bottom">
          <Input
            placeholder="Pesquisar pelo Título"
            value={search}
            onChange={e => setSearch(e.target.value)}
            style={{width: "50%"}}
          />
          <DefaultButton
            bg="confirm"
            text="Adicionar novo banner"
            to="/site-rt/bannerPrincipal-home/criar"
          />
        </div>
        <Table search={search}/>
      </div>
    </div>
  )
}

export default BannerPrincipalList;
import ReactInputMask from "react-input-mask";
import {
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const CustomerInfo = ({
  user,
  course,
  cpf,
  setCpf,
  user_name,
  email,SetEmail,
  setUserName,
  gender,
  setGender,
  birth_date,
  setBirthDate,
  phone,
  setPhone,
}) => {
  console.log(user.birth_date)
  return (
    <div className="row">
      <Typography
        variant="h6"
        className="ms-3"
        style={{
          marginTop: "1.2rem",
          color: course?.checkout_font_color,
        }}
      >
        Dados do pagador
      </Typography>
      <div className="col-sm-8 mt-3">
        <TextField
          fullWidth
          label="Nome completo"
          size="small"
          defaultValue={user?.name}
          value={user_name}
          sx={{
            backgroundColor: "white",
          }}
          onChange={(e) => setUserName(e.target.value)}
        />
      </div>
      <div className="col-sm-4 mt-3">
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={user?.gender}
          value={gender}

          size="small"
          label="Sexo"
          sx={{
            backgroundColor: "white",
          }}
          onChange={(e) => {
            setGender(e.target.value);
          }}
        >
          <MenuItem value="Feminino">Feminino</MenuItem>
          <MenuItem value="Masculino">Masculino</MenuItem>
          <MenuItem value="Outro">Outro</MenuItem>
        </Select>
      </div>
      <div className="col-sm-4 mt-3">
        <ReactInputMask
          mask={"99/99/9999"}
          defaultValue={user?.birth_date}
          sx={{
            backgroundColor: "white",
          }}
          value={birth_date}

          onChange={(e) => setBirthDate(e.target.value)}
          disabled={false}
          maskChar=""
        >
          {() => (
            <TextField
              fullWidth
              label="Data de nascimento"
              size="small"
              sx={{
                backgroundColor: "white",
              }}
            />
          )}
        </ReactInputMask>
      </div>
      <div className="col-sm-4 mt-3">
        <ReactInputMask
          mask={"999.999.999-99"}
          defaultValue={user?.cpf}
          value={cpf}

          sx={{
            backgroundColor: "white",
          }}
          onChange={(e) => setCpf(e.target.value)}
          disabled={false}
          maskChar=""
        >
          {() => (
            <TextField
              fullWidth
              label="CPF"
              size="small"
              sx={{
                backgroundColor: "white",
              }}
            />
          )}
        </ReactInputMask>
      </div>
      <div className="col-sm-4 mt-3">
        <ReactInputMask
          mask="+55 (99) 99999-9999"
          defaultValue={user?.phone}
          value={phone}

          onChange={(e) => {console.log(e);setPhone(e.target.value)}}
          disabled={false}
          maskChar=""
        >
          {() => (
            <TextField
              fullWidth
              label="Telefone"
              size="small"
              sx={{
                backgroundColor: "white",
              }}
            />
          )}
        </ReactInputMask>
      </div>
      <div className="col-sm-8 mt-3">
        <TextField
          fullWidth
          label="E-mail"
          disabled={user?.id}
          size="small"
          defaultValue={user?.email}
          value={email}
          sx={{
            backgroundColor: "white",
          }}
          onChange={(e) => SetEmail(e.target.value)}
        />
      </div>
    </div>
  )
}

export default CustomerInfo
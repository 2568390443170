import { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
// import LessonsCard from "../Cards/LessonsCard";
import { Post } from "utils/request";
import Section3Card from "../Cards/Section3Card";
import JoditEditor from "jodit-react";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Label from "components/InfoLabel";
import ColorInput from "components/Inputs/ColorInput";

const Section3Edit = (props) => {
  const {
    statisticsTitle,
    setStatisticsTitle,
    statisticsText,
    setStatisticsText,

    modal_style,

    JoditConfig,
  } = props;

  const {
    section3_cards,
    addSection3Card,
    removeSection3Card,
    handleSection3CardChange,
  } = props.section3;

  const dispatch = useDispatch();

  const [card_number, setCard_number] = useState("");
  const [card_text, setCard_text] = useState("");

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddLesson = () => {
    addSection3Card({
      number: card_number,
      text: card_text,
    });

    // Submit();

    dispatch(
      Show({
        show: true,
        severity: "success",
        message: "Adicionado com sucesso",
      })
    );
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modal_style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              padding: "3rem 4rem",
            }}
          >
            <CloseIcon
              sx={{
                position: "absolute",
                top: "0.8rem",
                right: "0.8rem",
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <Typography variant="h5" style={{ marginBottom: "1rem" }}>
              Adicionar cartão
            </Typography>

            <FormLabel>
              Número <span style={{ color: "red" }}>*</span>
              <Input
                value={card_number}
                onChange={(e) => setCard_number(e.target.value)}
                type="number"
                required
              />
            </FormLabel>
            <FormLabel sx={{ width: "24rem" }}>
              Texto <span style={{ color: "red" }}>*</span>
              <Input
                value={card_text}
                onChange={(e) => setCard_text(e.target.value)}
                sx={{ width: "24rem" }}
                as="textarea"
                required
              />
            </FormLabel>
            <Button
              variant="contained"
              onClick={handleAddLesson}
              sx={{
                width: "max-content",
                alignSelf: "flex-end",
                marginTop: "1rem",
              }}
            >
              Adicionar cartão
            </Button>
          </div>
        </div>
      </Modal>
      <FormLabel>
        <Label
          label={<span>Título</span>}
          required
          title="Título da seção de estatísticas"
        />
        <JoditEditor
          config={JoditConfig}
          value={statisticsTitle}
          onChange={(value) => setStatisticsTitle(value)}
        />
      </FormLabel>

      <FormLabel>
        <Label
          label={<span>Texto das estatíticas</span>}
          required
          title="Texto das estatíticas da página sobre nós"
        />
        <JoditEditor
          config={JoditConfig}
          value={statisticsText}
          onChange={(value) => setStatisticsText(value)}
        />
      </FormLabel>

      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          maxWidth: "36rem",
        }}
      >
        {section3_cards?.map((card, index) => {
          return (
            <Section3Card
              key={index}
              index={index}
              card={card}
              modal_style={modal_style}
              removeSection3Card={removeSection3Card}
              handleSection3CardChange={handleSection3CardChange}
            />
          );
        })}
      </div>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          width: "max-content",
        }}
      >
        Adicionar cartão
      </Button>
    </>
  );
};

export default Section3Edit;

import { Box } from "@mui/material";
import VideoContainer from "./Video";

const LessonView = () => {
    return (
        <Box>
            <VideoContainer/>
        </Box>
    );
}

export default LessonView;
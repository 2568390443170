import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Show } from "actions/SnackbarActions";
import { useState } from "react";
import { FormControl as Input } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Post } from "utils/request";

const Modal = (props) => {
  const { onSubmit = () => {}, onClose = () => {}, ...other } = props;

  const [action, SetAction] = useState("action");
  const [comment, SetComment] = useState("");

  const { email_id } = useParams();
  const dispatch = useDispatch();

  const Submit = async () => {
    if (!comment) {
      dispatch(
        Show({
          show: true,
          message: "Insira um comentário!",
          severity: "warning",
        })
      );
      return;
    }

    let form = new FormData();
    form.append("email_id", email_id);
    form.append("action_description", action);
    form.append("general_description", comment);

    let response = await Post("emails/actions/create", form);

    if (response?.status === true) {
      SetComment("");
    }

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );

    onSubmit();
    onClose();
  };

  return (
    <Dialog
      {...other}
      onClose={onClose}
      sx={{
        padding: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "1.8rem 1rem 0 1.8rem",
        }}
      >
        <DialogTitle>Adicionar registro</DialogTitle>
      </div>
      <DialogContent>
        <Input
          as="textarea"
          placeholder="Comentário"
          value={comment}
          onChange={(e) => SetComment(e.target.value)}
          style={{ width: "30rem" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            color: "rgb(244, 67, 54)",
            borderColor: "rgb(244, 67, 54)",
          }}
          variant="outlined"
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          style={{
            color: "rgb(41, 182, 246)",
            borderColor: "rgb(41, 182, 246)",
          }}
          variant="outlined"
          onClick={Submit}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;

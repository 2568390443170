import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { STORAGE_URL } from "variables";

const IntroText = (props) => {
  const { title, joditIntroText, about_us_bg_image, about_us_bg_gradient } =
    props.section1;

  const [coverImageSrc, setCoverImageSrc] = useState("");

  useEffect(() => {
    console.log("foi");
    if (about_us_bg_image === undefined || about_us_bg_image === null) return;
    if (about_us_bg_image === "" || about_us_bg_image.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setCoverImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(about_us_bg_image);
    } catch (error) {
      setCoverImageSrc(`${STORAGE_URL}${about_us_bg_image}`);
    }
  }, [about_us_bg_image]);

  return (
    <Container maxWidth="xl" sx={{ textAlign: "center" }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: "black",
          position: "absolute",
          top: "4rem",
          width: "100%",
        }}
      >
        (seção 1)
      </Typography>
      <Box
        sx={{
          background: "#f0f4f4",
          borderRadius: "1rem",
          marginTop: "6rem",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {coverImageSrc !== "" && (
          <img
            className={`custom-card-image ${
              about_us_bg_gradient && "make-the-gradient"
            }`}
            style={{
              zIndex: 1,
              width: "100%",
              objectFit: "cover",
            }}
            src={coverImageSrc || ""}
            alt="Fundo do cartão"
          />
        )}
        <div
          style={{
            padding: "2rem",
            borderRadius: "1rem",
            zIndex: 20,
            position: "relative",
          }}
        >
          <Typography
            variant="h3"
            style={{
              fontWeight: 700,
            }}
          >
            Sobre nós
          </Typography>

          <Typography
            variant="h6"
            style={{
              marginBottom: "2rem",
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />

          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: joditIntroText }}
            sx={{
              gap: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          />
        </div>
      </Box>
    </Container>
  );
};

export default IntroText;

import { Box, List, ListItem, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import Image from "components/Image";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Get } from "utils/request";

import Board from "./Board";
import emptyState from "assets/images/bubbles.png";

const CommentsList = () => {
  const { file_id } = useParams();
  const { isLoading, data, refetch } = useQuery(
    ["comments", file_id],
    () => Get(`lessons/files/comments?fileId=${file_id}`),
    { refetchOnWindowFocus: false }
  );
  const { status, comments } = data || {};

  return (
    <List>
      {comments?.map((comment) => (
        <ListItem key={comment?.id}>
          <Board
            id={comment?.id}
            comment={comment?.comment}
            user={comment?.user}
            createdAt={comment?.created_at}
            replies={comment?.replies}
            onSubmit={refetch}
          />
        </ListItem>
      ))}
      {comments?.length == 0 && (
        <Box display={"flex"} flexDirection={"column"} alignItems="center">
          <Image
            src={emptyState}
            width={350}
            height={300}
            style={{
              backgroundSize: "cover",
              marginBottom: -50,
            }}
          />
          <Typography variant="h5" sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "rgba(0, 0, 0, 0.5)",
          }}>Nada por aqui...</Typography>
        </Box>
      )}
      {isLoading &&
        new Array(5)
          .fill(undefined)
          .map((_, key) => <Skeleton height={70} key={key} />)}
    </List>
  );
};

export default CommentsList;

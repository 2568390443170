import { Checkbox, Tooltip, Button } from "@mui/material";
import React, { useEffect, useRef } from "react";
import DefaultButton from "components/DefaultButton";
import InputText from "components/Inputs/InputText";
import LogoHeader from "components/LogoHeader";
import Label from "components/InfoLabel";
import Footer from "modules/Previews/Footer";
import { STORAGE_URL } from "variables";

import styles from "./index.module.css";

const PreviewPage = props => {

    const {
        title,
        subtitle,
        text,
        fontColor,
        backgroundImage,
        backgroundColor,
        primaryColor,
        secondaryColor
    } = props;

    const background = useRef(null);

    useEffect(() => {
        if (backgroundImage instanceof File && FileReader) {
            let fileReader = new FileReader();
            fileReader.onload = () => background.current.style.backgroundImage = `url(${fileReader.result})`;
            fileReader.readAsDataURL(backgroundImage);
        }
    }, [backgroundImage]);

    return (
        <div
            style={{
                aspectRatio: "16/9",
                backgroundColor: "black",
                overflowY: "scroll"
            }}
        >
            <div
                className={styles.container}
                style={{
                    ...(backgroundColor && {backgroundColor: backgroundColor}),
                    ...(backgroundImage && {backgroundImage: `url(${STORAGE_URL + backgroundImage})`}),
                    backgroundSize: "cover"
                }}
                ref={background}
            >
                <div 
                    className={styles.box}
                    style={{
                        backgroundColor: secondaryColor,
                    }}
                >
                    <div className="register-content">
                        <h1
                            style={{
                                color: fontColor
                            }}
                        >
                            Cadastro
                        </h1>
                        <form
                            className="register-main-form"
                        >
                            <div className="flex">
                                <InputText
                                    variant="filled"
                                    label="Seu nome"
                                    style={{
                                        width: "100%",
                                        marginBottom: "1em"
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "white"
                                        }
                                    }}
                                    focusedLabelColor={primaryColor}
                                    underlineColor={primaryColor}
                                />
                                <Label iconColor={primaryColor} title="Caixa de texto; Cor de referência: cor primária"/>
                            </div>
                            <div className="flex">
                                <InputText
                                    variant="filled"
                                    label="E-mail"
                                    style={{
                                        width: "100%",
                                        marginBottom: "1em"
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "white"
                                        }
                                    }}
                                    focusedLabelColor={primaryColor}
                                    underlineColor={primaryColor}
                                />
                                <Label iconColor={primaryColor} title="Caixa de texto; Cor de referência: cor primária"/>
                            </div>
                            <div className="flex">
                                <InputText
                                    variant="filled"
                                    label="Telefone"
                                    style={{
                                        width: "100%",
                                        marginBottom: "1em"
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "white"
                                        }
                                    }}
                                    focusedLabelColor={primaryColor}
                                    underlineColor={primaryColor}
                                />
                                <Label iconColor={primaryColor} title="Caixa de texto; Cor de referência: cor primária"/>
                            </div>
                            <div className="flex">
                                <InputText
                                    variant="filled"
                                    label="Senha"
                                    type="password"
                                    style={{
                                        width: "100%",
                                        marginBottom: "2em"
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "white"
                                        }
                                    }}
                                    focusedLabelColor={primaryColor}
                                    underlineColor={primaryColor}
                                />
                                <Label iconColor={primaryColor} title="Caixa de texto; Cor de referência: cor primária"/>
                            </div>
                            {/* <DefaultButton
                                text="Entrar"
                                style={{
                                    height: "44px",
                                    width: "100%",
                                    ...(primaryColor && {backgroundColor: primaryColor})
                                }}
                                bg="primary"
                                title="Botão; Cor de referência: cor primária"
                            /> */}
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: primaryColor
                                }}
                                fullWidth
                            >
                                Entrar
                            </Button>
                            <div className="w100 flex jcsb align-center">
                                <div className="flex fdrow align-center text-muted">
                                    <Checkbox
                                        className="text-muted"
                                    />
                                    Lembre-se de mim
                                </div>
                                {/* <a href="#" className="text-muted">Precisa de ajuda?</a> */}
                            </div>
                            <p className="text-muted">
                                Já possuí conta?  <span
                                    className="text-primary inline-flex" 
                                    style={{
                                        ...(primaryColor && {color: primaryColor})
                                    }}
                                > 
                                    <div className="flex">
                                        <span>Entre agora!</span>
                                        <Label iconColor={primaryColor} title="Texto de cor primária"/>
                                    </div>
                                </span>
                            </p>
                        </form>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default PreviewPage;
import React, { useEffect, useState } from "react";

// import ColorPicker from 'material-ui-color-picker'
import { FormControlLabel, FormGroup, TextField } from "@mui/material";
import { FormControl as Input } from "react-bootstrap";

import ImageInput from "../../../../components/Inputs/ImageInput";
import ColorInput from "../../../../components/Inputs/ColorInput";
import { FormLabel } from "react-bootstrap";
import DefaultButton from "../../../../components/DefaultButton";
import Label from "../../../../components/InfoLabel";
import { Get } from "../../../../utils/request";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_KEYS } from "../../../../variables";
import PreviewPage from "./PreviewPage";

const FormBody = props => {

    const {
        onConfirm,
        loading
    } = props;

    const [title, SetTitle] = useState("");
    const [backgroundImage, SetBackgroundImage] = useState("");
    const [backgroundColor, SetBackgroundColor] = useState("#000");
    const [primaryColor, SetPrimaryColor] = useState("#000");
    const [secondaryColor, SetSecondaryColor] = useState("#000");

    const [firstLoading, SetFirstLoading] = useState(false);

    const location = useLocation();
    const landingStyle = useSelector(store => store.AppReducer.pagesStyles);
    const dispatch = useDispatch();

    const GetData = async () => {
        let response = await Get(`styles/pages/key/${PAGE_KEYS.register}`);
        if (response === undefined) {
            SetFirstLoading(true);
            return;
        }

        SetTitle(response?.title ?? "");
        SetBackgroundImage(response?.background_image ?? "");
        SetBackgroundColor(response?.background_color ?? "");
        SetPrimaryColor(response?.primary_color ?? "");
        SetSecondaryColor(response?.secondary_color ?? "");
        SetFirstLoading(true);
    }

    useEffect(GetData, []);

    if (!firstLoading) return <Skeleton/>
    return (
        <div>
            <form>
                <FormGroup>
                    <Label
                        label="Título"
                        required
                        title="O título que aparecerá na guia do navegador"
                    />
                    <Input
                        placeholder="Título do site"
                        variant="filled"
                        value={title}
                        onChange={e => SetTitle(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Foto de fundo"
                        title="A foto de fundo é mostrada no plano de fundo da página de login"
                    />
                    <ImageInput
                        ImageChange={image => SetBackgroundImage(image)}
                        path={backgroundImage}
                    />
                    <br/>

                    <Label
                        label="Cor de fundo"
                        required
                        title="A cor de fundo aparecerá caso não haja nenhuma foto de fundo"
                    />
                    <ColorInput
                        value={backgroundColor}
                        onChange={color => color !== undefined && SetBackgroundColor(color)}
                    />
                    <br/>
                    
                    <Label
                        label="Cor primária"
                        required
                        title="A cor primária é atribuida aos principais elementos da página, como títulos e botões"
                    />
                    <ColorInput
                        value={primaryColor}
                        onChange={color => color !== undefined && SetPrimaryColor(color)}
                    />
                    <br/>
                    
                    <Label
                        label="Cor secundária"
                        required
                        title="A cor secundária aparecerá em elementos diversos do site, como por exemplo em botões"
                    />
                    <ColorInput
                        value={secondaryColor}
                        onChange={color => {
                            color !== undefined && SetSecondaryColor(color)
                        }}
                    />
                    <br/>
                </FormGroup>
            </form>
            <hr/>
            <Label
                label="Pré-visualização"
            />
            <PreviewPage
                backgroundImage={backgroundImage}
                backgroundColor={backgroundColor}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
            />
            <br/>
            <div className="w100 inline-flex jcsb margin-top-1">
                <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar configurações"
                    loadingtext="Salvando configurações"
                    loading={loading}
                    onClick={() => {
                        const data = {
                            title: title,
                            backgroundImage: backgroundImage,
                            backgroundColor: backgroundColor,
                            primaryColor: primaryColor,
                            secondaryColor: secondaryColor
                        }

                        onConfirm(data);
                    }}
                />
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
        </div>
    );
}

export default FormBody;
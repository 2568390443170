import React, { useEffect, useState } from "react";

import Board from "./Board";

import { Get } from "utils/request";
import { CircularProgress } from "@mui/material";
import { Pagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";

const Table = (props) => {
  const { search = "", banners = [], isLoading, refetch } = props;

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Imagem</th>
              <th>Link</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {
              console.log(banners)
            }
            {!isLoading &&
              banners?.map((value, index) => (
                <Board
                  key={index}
                  id={value?.id}
                  link={value?.link}
                  banner={value?.image}
                  refetch={refetch}
                />
              ))}
          </tbody>
        </table>
      </div>
      <div className="w100 flex jcc">
        <CircularProgress hidden={!isLoading} />
        <p hidden={banners?.length !== 0 || isLoading}>
          Nenhum banner foi achado
        </p>
      </div>
      {/* <Pagination
        className={maxPage == 1 ? "hide" : ""}
        style={{ display: "flex", justifyContent: "flex-end" }}
        count={maxPage}
        value={page}
        onChange={(_, value) => SetPage(value)}
      /> */}
    </div>
  );
};

export default Table;

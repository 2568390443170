import { Show } from "actions/SnackbarActions";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Post } from "utils/request";

const useActions = (props) => {

    const {
        id,
        courseId,
        onUpdate = () => {}
    } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const { mutate: acceptMutate } = useMutation(async () => {
        const form = new FormData();
        form.append("id", id);

        return await Post("courses-main/evaluations/accept", form);
    }, {
        onSuccess: (data) => {
            onUpdate();
            dispatch(Show({
                show: true,
                message: data?.message
            }));
        }
    });

    const { mutate: deleteMutation, isLoading: deleteIsLoading } = useMutation(async () => {
        const form = new FormData();
        form.append("id", id);

        return await Post("courses-main/evaluations/delete", form);
    }, {
        onSuccess: (data) => {
            onUpdate();
            dispatch(Show({
                show: true,
                message: data?.message
            }));
        }
    });

    const goToComment = () => {
        if (courseId) {
            // history.push(`courses/${courseId}/${fileId}`);
        }
    }

    return {
        accept: {
            mutate: acceptMutate
        },
        deleteActions: {
            mutate: deleteMutation,
            isLoading: deleteIsLoading
        }
    };
}

export default useActions;
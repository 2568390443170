
import React, { Component, useEffect, useState, useRef } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// Destinos
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdPhoneLocked, MdSearch, MdVisibility } from 'react-icons/md';
import DefaultButton from '../../../components/DefaultButton';

const Registrations = (props) => {
    const token = useSelector(state => state.AppReducer.token);
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const [loading_screen, setLoading_screen] = useState(false);
    const [loading_search, setLoading_search] = useState(false);
    const [path, setPath] = useState('/');
    const [msgErro, setMsgErro] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [total_pages, setTotal_pages] = useState(1);
    const [inscricoes, setInscricoes] = useState([]);
    const [show_warning, setShow_warning] = useState(false);
    const [show_erro, setShow_erro] = useState(false);
    const [polo_to_delete, setPolo_to_delete] = useState('');
    const timeoutRef = useRef(null)


    const Get_data = (page, search = '', busca = false) => {
        if (busca == true) {
            if (loading_search == false) {
                setLoading_search(true);
            }
        }
        else if (loading_screen == false) {
            setLoading_screen(true);
        }
        fetch(`${URL}api/get_inscricoes?page=${page}&search=${search}&filter=${props.filter}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    setRedirect(true);
                }
                else {
                    setLoading_search(false);
                    setLoading_screen(false);
                    setPage(resp.pagination.pag);
                    setTotal_pages(resp.pagination.last_page)
                    setInscricoes(resp.inscricoes);
                }

            } catch (err) {
                setRedirect(true);
                console.log(err);
            }
        })
            .catch((err) => {
                console.log(err);
                setLoading_screen(false);
                setRedirect(true);
            });

    }

    const Delete_polo = (id) => {

        fetch(`${URL}api/delete_polo/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    setMsgErro(erro);
                    setShow_warning(false);
                    setShow_erro(false);
                }
                else {
                    setShow_warning(false);
                    Get_data(page);
                }
            } catch (err) {
                setRedirect(true);
                console.log(err);
            }
        })
            .catch((err) => {
                setRedirect(true);
                console.log(err);
            });

    }

    // useEffect(() => {
    //     Get_data(page);
    // }, []);

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        setLoading_search(true);

        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;
            Get_data(1, search, true)
        }, 500);
    }, [search]);


    const handlePageClick = (e) => {
        console.log(e)
        const selectedPage = e.selected;
        setPage(selectedPage + 1)
        if (selectedPage + 1 != page) {
            setPage(selectedPage + 1)
            Get_data(selectedPage + 1, search, true);
        }
    };

    return (
        <div className="row">
            {redirect == true && <Redirect to={path} />}
            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <div className="row mb-2">
                        <div className="col-12 col-md-6">
                            <form className="app-search mt-1" style={{ width: '100%' }} >
                                <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
                                    <input value={search} name="search" placeholder="Pesquisar pelo Nome ou Cidade..." className="form-control" style={{ width: '100%', paddingRight: 40, borderRadius: 100 }} onChange={(e) => {
                                        setSearch(e.target.value);
                                    }} />
                                    <div style={{ width: 40, position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                        <MdSearch />

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped" style={{ textAlign: 'center' }}>
                                <thead>
                                    <tr>

                                        <th>Nome </th>
                                        <th>Curso</th>
                                        <th>Status</th>

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_search == false && inscricoes.map((item, id) => (

                                        <tr key={id}>

                                            <td>{item?.nome}</td>
                                            <td>{item?.curso?.nome}</td>
                                            {item?.paid == false && <td style={{ color: 'darkgoldenrod' }}>Aguardando aprovação</td>}
                                            {item?.paid == true && <td style={{ color: 'green' }}>Paga</td>}

                                            <td>

                                                <DefaultButton
                                                    className='me-1'
                                                    width="2.2em"
                                                    height="2.2em"
                                                    padding={0}
                                                    to={'/inscricoes/view/' + item.id}
                                                    search={'?tab='+props.tab}
                                                    title={`visualizar`}
                                                    bg="warning"
                                                    icon={<MdVisibility size={17} color="white" />}
                                                />
                                                
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_search == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>
                            }
                        </div>
                    </div>
                    {loading_search == false && inscricoes.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma inscrição cadastrada</p>

                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <ReactPaginate
                                previousLabel={'Anterior'}
                                nextLabel={'Próxima'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total_pages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                initialPage={page - 1}
                            />
                        </div>
                    </div>


                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                </div></div>}
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => { Delete_polo(polo_to_delete); }}
                    onCancel={() => { setShow_warning(false); }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar este polo ele não estará mais disponível no sistema

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro"}
                    onConfirm={() => {
                        setShow_erro(false);
                    }}
                    show={show_erro}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"

                >
                    {msgErro}

                </SweetAlert>

            </div>
        </div>
    );
}


export default Registrations;



import { useState } from "react";
import { FormControl as Input } from "react-bootstrap";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";

import Table from "./Table";
import DefaultButton from "components/DefaultButton";
import { useParams } from "react-router";

const GroupsList = () => {

    const [search, SetSearch] = useState("");

    const { id } = useParams();
    const dispatch = useDispatch();

    return (
        <div className="groups-list">
            <h5 className="title">Turmas</h5>
            <p className="text-muted">Aqui são listados todas as turmas deste curso</p>
            <hr/>
            <div className="flex jcsb flex-wrap margin-bottom">
                <Input
                    placeholder="Pesquisar pelo Nome"
                    value={search}
                    onChange={e => SetSearch(e.target.value)}
                    style={{ width: "50%" }}
                />
                {/* <RoleBased> */}
                    <DefaultButton
                        bg="confirm"
                        text="Adicionar nova turma"
                        to={`/courses/view/${id}/groups/add`}
                    />
                {/* </RoleBased> */}
            </div>
            <Table
                search={search}
            />
        </div>
    );
}

export default GroupsList;
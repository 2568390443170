import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, Button } from "@mui/material";
import { Show } from "actions/SnackbarActions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Post } from "utils/request";

import CloseIcon from '@mui/icons-material/Close';
import styles from "./index.module.css";

const EditModal = props => {

    const {
        id,
        initialMessage,
        onModalClose = () => {},
        onSubmit = () => {},
        ...others
    } = props;

    const [message, SetMessage] = useState(initialMessage);

    const { file_id } = useParams();
    const dispatch = useDispatch();

    const Submit = async () => {
        const form = new FormData();
        form.append("id", id);
        form.append("message", message);
        
        const response = await Post("lessons/annotations/alter", form);

        if (response?.status === true) {
            onSubmit();
            onModalClose();
            SetMessage("");
        }

        dispatch(Show({
            show: true,
            message: response?.message,
            severity: response?.severity
        }));
    }

    return (
        <Dialog
            onClose={onModalClose}
            PaperProps={{
                style: { maxWidth: "none" }
            }}
            {...others}
        >
            <DialogTitle>
                <div className="flex fdrow jcsb align-center w100">
                    <Typography>Editar anotação</Typography>
                    <IconButton onClick={onModalClose}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent
                className={styles.content}
            >
                <TextField
                    label="Anotações"
                    multiline
                    maxRows={8}
                    minRows={4}
                    helperText={"Obs: Somente você poderá ver suas anotações."}
                    variant="filled"
                    fullWidth
                    value={message}
                    onChange={e => SetMessage(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onModalClose}
                    variant="outlined"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={Submit}
                    variant="outlined"
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditModal;
import React, { useEffect, useState } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import DefaultButton from "../../../components/DefaultButton";
import InputText from "../../../components/Inputs/InputText";
import PhoneInput from "../../../components/Inputs/PhoneInput";

import { Get } from "../../../utils/request";

import "./index.css";

const FormBody = props => {

    const {
        OnConfirm
    } = props;

    const [name, SetName] = useState("");
    const [email, SetEmail] = useState("");
    const [phone, SetPhone] = useState("");
    const [courseId, SetCourseId] = useState("");

    const [showNameError, SetShowNameError] = useState(false);
    const [showEmailError, SetShowEmailError] = useState(false);
    const [showPhoneError, SetShowPhoneError] = useState(false);
    const [showCourseError, SetShowCourseError] = useState(false);

    const [courseOptions, SetCourseOptions] = useState([]);

    const GetData = async () => {
        let response = await Get("get_cursos?paginate=0");
        if (response?.status === true) SetCourseOptions(response?.cursos);
    }

    const Submit = () => {

        SetShowNameError(!name);
        SetShowEmailError(!email);
        SetShowPhoneError(!phone);
        SetShowCourseError(!courseId);

        if (!name || !email || !phone || !courseId) return;

        let data = {
            name: name,
            email: email,
            phone: phone,
            courseId: courseId
        };
        
        OnConfirm(data);
    }

    useEffect(() => GetData(), []);

    return (
        <div className="form-body flex w100 align-center fdcolumn">
            <form className="pre-register-form flex fdcolumn">
                <InputText
                    variant="filled"
                    label="Nome"
                    value={name}
                    error={showNameError}
                    helperText={showNameError ? "Insira um nome!" : ""}
                    onChange={e => SetName(e.target.value)}
                />
                <InputText
                    variant="filled"
                    label="E-mail"
                    placeholder="exemplo@email.com"
                    value={email}
                    error={showEmailError}
                    helperText={showEmailError ? "Insira um e-mail!" : ""}
                    onChange={e => SetEmail(e.target.value)}
                />
                <PhoneInput
                    label="Seu número do Whatsapp"
                    variant="filled"
                    GetValue={value => SetPhone(value)}
                    error={showPhoneError}
                    helperText={showPhoneError ? "Insira um número de whatsapp!" : ""}
                />
                <FormControl 
                    variant="filled"
                    error={showCourseError}
                >
                    <InputLabel>Escolha o seu curso</InputLabel>
                    <Select
                        onChange={e => SetCourseId(e.target.value)}
                        value={courseId}
                    >
                        <MenuItem value=""><em>Nenhum</em></MenuItem>
                        {courseOptions?.map((value, index) => (
                            <MenuItem
                                key={index}
                                value={value?.id}
                            >
                                {value?.nome}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{showCourseError ? "Selecione algum curso!" : ""}</FormHelperText>
                </FormControl>
                <DefaultButton
                    text="Confirmar"
                    onClick={Submit}
                />
            </form>
            <span 
                className="text-muted"
                style={{
                    maxWidth: "400px",
                    textAlign: "center",
                    fontSize: "12px",
                    padding: "10px 15px"
                }}
            >
                De acordo com a lei 12.965/2014 e 13.709/2018, autorizo a ITEC MAIS  a enviar comunicações por telefone ou qualquer outro meio e concordo com a sua política de privacidade.
            </span>
        </div>
    );
}

export default FormBody;
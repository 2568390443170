import React, { useEffect, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Show } from "actions/SnackbarActions";
import { Post } from "utils/request";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import DefaultButton from "components/DefaultButton";
import { HandlePercentInput } from "utils/filters";
import { maskPrice } from "Auxiliar/Masks";
import DiscountInput from "./DiscountInput";
import CodeInput from "./CodeInput";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  Switch,
  ThemeProvider,
} from "@mui/material";
import { primaryBlue } from "utils/Themes";

const FormBody = (props) => {
  const { loading, OnConfirm } = props;

  const [name, SetName] = useState("");
  const [code, SetCode] = useState("");
  const [[discount, discountMode], SetDiscount] = useState([
    "0%",
    "percentage",
  ]);
  const [toAll, SetToAll] = useState(false);
  const [isEternal, SetIsEternal] = useState(false);
  const [start, SetStart] = useState(Date.now());
  const [end, SetEnd] = useState(Date.now());
  const [single_use, setSingleUse] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const submitMutation = useMutation(
    async () => {
      const form = new FormData();
      form.append("name", name);
      form.append("code", code);
      form.append("start_date", new Date(start).toISOString().split("T")[0]);
      form.append("end_date", new Date(end).toISOString().split("T")[0]);
      form.append("is_eternal", isEternal);
      form.append("single_use", single_use);
      form.append("to_all", toAll);

      if (discountMode == "percentage")
        form.append("discount_percentage", parseInt(discount));
      else if (discountMode == "amount")
        form.append(
          "discount_amount",
          parseFloat(discount.replace(/[^0-9,]/g, "").replace(",", "."))
        );

      return await Post("coupon/create", form);
    },
    {
      onSuccess: ({ message }) => {
        dispatch(
          Show({
            show: true,
            message: message,
            severity: "success",
          })
        );
        history.push("/coupon");
      },
      onError: ({ message }) => {
        dispatch(
          Show({
            show: true,
            message: message,
            severity: "error",
          })
        );
      },
    }
  );

  return (
    <div className="form-course">
      <form>
        <FormLabel>
          Nome <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Nome"
          value={name}
          onChange={(e) => SetName(e.target.value)}
          required
        />
        <br />
        <FormLabel>
          Código do cupom <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <CodeInput
          placeholder="Código do cupom"
          value={code}
          onChange={(code) => SetCode(code)}
          required
        />
        <br />
        <br />
        <FormLabel>
          Valor de desconto <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <DiscountInput
          onChange={(value) => SetDiscount(value)}
          value={discount}
          mode={discountMode}
        />
        <br />
        <br />
        <div className="flex fdcolumn">
          <FormLabel>
            Uso único <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <ThemeProvider theme={primaryBlue}>
            <FormControlLabel
              control={
                <Switch
                  checked={single_use}
                  onChange={(e) => setSingleUse(e.target.checked)}
                  color="primary"
                />
              }
              label={single_use ? "Apenas um uso" : "Infinito"}
            />
          </ThemeProvider>
          <br />
          <FormLabel>
            Período de validade <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <ThemeProvider theme={primaryBlue}>
            <FormControlLabel
              control={
                <Switch
                  checked={isEternal}
                  onChange={(e) => SetIsEternal(e.target.checked)}
                  color="primary"
                />
              }
              label={"Eterno"}
            />
          </ThemeProvider>
          <Collapse in={!isEternal}>
            <Box width="100%" display="flex" flexDirection={"column"}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM"
                  margin="normal"
                  id="date-picker-inline"
                  label="De"
                  value={start}
                  onChange={SetStart}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM"
                  margin="normal"
                  id="date-picker-inline"
                  label="Até"
                  value={end}
                  onChange={SetEnd}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Collapse>
        </div>
        <br />
        <div className="flex fdcolumn">
          <FormLabel>Disponível para todos os usuários</FormLabel>
          <ThemeProvider theme={primaryBlue}>
            <FormControlLabel
              label={"Para todos"}
              control={
                <Checkbox
                  checked={toAll}
                  onChange={(e) => SetToAll(e.target.checked)}
                  color="primary"
                />
              }
            />
          </ThemeProvider>
        </div>
        <br />
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to="/coupon"
        />
        <DefaultButton
          bg="confirm"
          text="Criar cupom"
          onClick={() => submitMutation.mutate()}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FormBody;

import React, { useEffect, useState } from "react";

import Board from "./Board";

import { Get } from "utils/request";
import { CircularProgress } from "@mui/material";
import { Pagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";

const Table = (props) => {
  const { search = "" } = props;

  const [posts, SetPosts] = useState([]);
  const [loading, SetLoading] = useState(false);

  const [page, SetPage] = useState(1);
  const [maxPage, SetMaxPage] = useState(1);

  const dispatch = useDispatch();

  const GetData = async () => {
    SetLoading(true);
    let response = await Get(`science-plus/references/list?search=${search}&paginate=${page}`);
    SetLoading(false);
     
    if (response?.status === true) {
      SetPosts(response?.references);
      SetMaxPage(response?.pagination.lastPage);
    } else if (!response)
      dispatch(
        Show({
          show: true,
          message: "Falha ao carregar as referências",
          severity: "warning",
        })
      );
  };

  useEffect(GetData, [search, page]);

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Imagem</th>
              <th>Título</th>
              <th>Descrição</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              posts?.map((value, index) => (
                <Board
                  key={index}
                  id={value?.id}
                  image={value?.image}
                  title={value?.title}
                  link={value?.link}
                  description={value?.description}
                  OnDelete={GetData}
                />
              ))}
          </tbody>
        </table>
      </div>
      <div className="w100 flex jcc">
        <CircularProgress hidden={!loading} />
        <p hidden={posts?.length !== 0 || loading}>
          Nenhuma referência foi achada
        </p>
      </div>
      <Pagination
        className={maxPage == 1 ? "hide" : ""}
        style={{ display: "flex", justifyContent: "flex-end" }}
        count={maxPage}
        value={page}
        onChange={(_, value) => SetPage(value)}
      />
    </div>
  );
};

export default Table;

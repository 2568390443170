/**
 * 
 * Form of BottomLead
 * 
 */

import { Box, TextField, Typography, Button } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";
import { Post } from "utils/request";

import styles from "./index.module.css";

const Formbody = props => {

    const {
        ...others
    } = props;

    const [name, SetName] = useState("");
    const [email, SetEmail] = useState("");

    const [nameError, SetNameError] = useState(false);
    const [emailError, SetEmailError] = useState(false);
    const [submited, SetSubmited] = useState(false);

    const history = useHistory();

    const HandleSubmit = async e => {
        e.preventDefault();
        if (!name) {
            SetNameError(true);
            return;
        } else SetNameError(false);
        if (email == "") {
            SetEmailError(true);
            return;
        } else SetEmailError(false);

        let form = new FormData();
        form.append("email", email);
        form.append("name", name);
        let response = await Post("emailname/create",form);
        if (response?.status === true) {
            SetEmail("");
            SetName("");
        }
        SetSubmited(response?.status || false);
    }

    return (
        <Box {...others}>
            <Typography>Dados para cadastro</Typography>
            <br/>
            <div className={styles.fieldsContainer}>
                <TextField
                    placeholder={"Seu nome"}
                    label={"Seu nome"}
                    onChange={e => SetName(e.target.value)}
                    value={name}
                    helperText={nameError ? "Insira um nome" : ""}
                    error={nameError}
                />
                <TextField
                    placeholder={"Seu e-mail"}
                    label={"Seu e-mail"}
                    onChange={e => SetEmail(e.target.value)}
                    value={email}
                    helperText={emailError ? "Insira um e-mail" : ""}
                    error={emailError}
                />
            </div>
            <br/>
            <Button
                variant={"outlined"}
                color="primary"
                onClick={HandleSubmit}
            >
                Cadastrar
            </Button>
            <br/>
            <Typography
                hidden={!submited}
                style={{ color: "rgba(0, 0, 0, 0.5)" }}
                variant="caption"
            >
                Cadastro efetivado!
            </Typography>
        </Box>
    );
}

export default Formbody;
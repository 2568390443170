import { Box, Typography } from "@mui/material";
import { removeHTML } from "utils/filters";

const Description = (props) => {
  const { name, description } = props;

  return (
    <Box padding={2} paddingTop={0}>
      <Typography variant={"h6"}>
        <b>{removeHTML(name)}</b>
      </Typography>
      <Typography>{removeHTML(description)}</Typography>
    </Box>
  );
};

export default Description;

import { useContext, useState } from "react";
import { DashboardContext } from "..";

const useUserActivity = props => {

    const [period, setPeriod] = useState(0);
    
    const dashboard = useContext(DashboardContext);
    const {
        userActivity: {
            weekly,
            monthly,
            yearly
        } = {},
        userActivity
    } = dashboard || {};
    
    const getPeriod = () => {
        switch (period) {
            case 0:
                return weekly;
                case 1:
                    return monthly;
            case 2:
                return yearly;
            default:
            return weekly;
        }
    }
        
    // const []

    // useEffect(() => )

    const data = getPeriod();
    return {
        period,
        data,
        setPeriod
    };
}

export default useUserActivity;
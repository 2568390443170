const INITIAL_STATE = {
    forcedRole: null
}

const AdminReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "forcedRole": {
            return {
                ...state,
                ...action.payload
            };
        }
            
        default:
            return { ...state };
    }
}

export default AdminReducer;
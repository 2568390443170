import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import TestimonialCard from "components/Cards/TestimonialCard";
import CarouselSlider from "components/CarouselSlider";
import { Get } from "utils/request";

const Testimonials = props => {

    const {
        ...others
    } = props;

    const theme = useTheme();

    return (
        <Box
            bgcolor={"primary.main"}
            padding={"2em 0"}
            color="primary.contrastText"
            {...others}
        >
            <Typography
                variant="h4"
                align="center"
            >
                Depoimentos de alunos
            </Typography>
            <CarouselSlider
                swiperProps={{
                    style: {
                        padding: "20px",
                        paddingBottom: "30px"
                    },
                    breakpoints: {
                        "800": {
                            slidesPerView: 3
                        },
                        "600": {
                            slidesPerView: 2
                        }
                    }
                }}
            >
                {new Array(4).fill(undefined).map((testimonial, key) => (
                    <TestimonialCard
                        key={key}
                        text="Texto do depoimento"
                        profession={"Profissão"}
                    />
                ))}
            </CarouselSlider>
        </Box>
    );
}

export default Testimonials;
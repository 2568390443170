import { Box } from "@mui/material";
import { Controller } from "react-hook-form";
import Label from "components/InfoLabel";
import ColorInput from "components/Inputs/ColorInput";
import PreviewPage from "./PreviewPage";

const ColorsData = (props) => {

    const {
        watch,
        control
    } = props;

    return (
        <Box>
            <Label
                label="Cor primária"
                required
                title="A cor primária aparecerá nos elementos com mais destaque do site, como títulos e botões"
            />
            <Controller
                control={control}
                name={"primary_color"}
                render={({ field: { value, onChange, name, ref } }) => (
                    <ColorInput
                        value={value}
                        onChange={onChange}
                    />
                )}
            />
            <br/>

            <Label
                label="Cor secundária"
                required
                title="A cor secundária aparecerá em elementos diversos do site, como por exemplo em botões"
            />
            <Controller
                control={control}
                name={"secondary_color"}
                render={({ field: { value, onChange, name, ref } }) => (
                    <ColorInput
                        value={value}
                        onChange={onChange}
                    />
                )}
            />
            <br/>

            <Label
                label="Cor terciária"
                required
                title="A cor secundária aparecerá em elementos diversos do site, como por exemplo em botões"
            />
            <Controller
                control={control}
                name={"tertiary_color"}
                render={({ field: { value, onChange, name, ref } }) => (
                    <ColorInput
                        value={value}
                        onChange={onChange}
                    />
                )}
            />
            <br/>
            
            <PreviewPage
                primaryColor={watch("primary_color")}
                secondaryColor={watch("secondary_color")}
                tertiaryColor={watch("tertiary_color")}
                watch={watch}
            />

        </Box>
    );
}

export default ColorsData;
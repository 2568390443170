import { CircularProgress } from "@mui/material";
import { Pagination } from "@mui/material";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { Get } from "utils/request";

import Board from "./Board";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "components/DefaultButton";
import { removeHTML } from "utils/filters";

const Table = props => {
    const {
        search
    } = props;

    const [courses, SetCourses] = useState([]);
    const [loading, SetLoading] = useState(false);

    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const { category_id } = useParams();

    const GetData = async () => {
        SetLoading(true);
        let response = await Get(`courses-main/category?categoryId=${category_id}&page=${page}`);
        SetLoading(false);

        if (response?.status === true) {
            SetCourses(response?.courses);
            SetMaxPage(response?.pagination?.lastPage);
        }

    }

    useEffect(GetData, [search, page]);

    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && courses?.map(course => (
                            <Board
                                key={course?.id}
                                id={course?.id}
                                name={removeHTML(course?.name)}
                                description={course?.description}
                                OnDelete={GetData}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="w100 flex jcc">
                <CircularProgress hidden={!loading}/>
                <p hidden={courses?.length !== 0 || loading}>Nenhum curso possuí esta categoria</p>
            </div>
            <div className="flex fdrow w100 jcsb align-center">
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine/>}
                    to="/categorias"
                />
                <Pagination
                    className={(maxPage == 1 ? "hide" : "")}
                    style={{ display: "flex", justifyContent: "flex-end" }} 
                    count={maxPage} 
                    value={page}
                    onChange={(_, value) => SetPage(value)}
                />
            </div>
        </div>
    );
}

export default Table;
import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";
import TextCard from "../../TextCard";

const RecommendedTo = ({ course }) => {
  const {
    show_section4,
    recommendedTo,
    section4_title,
    section4_bgColor_1,
    section4_bgColor_2,
  } = course;

  return (
    <section
      style={{
        padding: "6rem 0",
        justifyContent: "center",
        alignItems: "center",
        display: show_section4 ? "flex" : "none",
        flexDirection: "column",
        background: `linear-gradient(180deg, ${section4_bgColor_1} 0%, ${section4_bgColor_2} 100%)`,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "4rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: "1rem",
          }}
          dangerouslySetInnerHTML={{ __html: section4_title }}
        />

        <Typography
          variant="h2"
          sx={{
            marginBottom: "1rem",
          }}
          dangerouslySetInnerHTML={{ __html: recommendedTo }}
        />
      </Container>
    </section>
  );
};

export default RecommendedTo;

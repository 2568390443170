import { Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { Get } from "utils/request";
// import Section100vh from "../../../components/Section100vh";

// owl carousel
import React from "react";
import OwlCarousel from "react-owl-carousel";

import TestimonyCard from "./TestimonyCard";
import { useEffect } from "react";

const Testimonials = (
  {
    testimonials,
    setTestimonials,
    testimonial_section_title,
  } // section_4
) => {
  const { data, isLoading, refetch } = useQuery(
    "testimonials",
    async () => await Get(`site-rt/testimonial`),
    {
      onSuccess: (data) => {
        const { testimonials } = data || {};
        let temp = [];
        testimonials.forEach((testimonial) => {
          temp.push({
            id: testimonial.id,
            image_url: testimonial.image,
            image_file: null,
            name: testimonial.name,
            profession: testimonial.profession,
            testimonial: testimonial.testimonial,
          });
        });
        setTestimonials(temp);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  // const getTestimonials = async () => {
  //   let res = await Get(`site-rt/testimonial`)

  //   let temp = [];
  //   res?.testimonials?.forEach((testimonial) => {
  //     temp.push({
  //       id: testimonial.id,
  //       image_url: testimonial.image,
  //       image_file: null,
  //       name: testimonial.name,
  //       profession: testimonial.profession,
  //       testimonial: testimonial.testimonial,
  //     });
  //   });
  //   setTestimonials(temp);
  // };

  // useEffect(() => {
  //   getTestimonials();
  // }, []);

  const responsive = {
    200: {
      items: 1,
    },
    400: {
      items: 2,
    },
  };

  return (
    <div style={{ height: "max-content", position: "relative" }}>
      <Typography
        variant="h5"
        sx={{
          fontFamily: "var(--Freight)",
          fontWeight: "bold",
          textAlign: "center",
          color: "black",
          position: "absolute",
          top: "1rem",
          width: "100%",
        }}
      >
        (seção 4)
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1.4rem",
          margin: "auto",
          padding: "8rem 0",
        }}
      >
        <Typography
          variant="h3"
          component="div"
          sx={{
            alignSelf: "center",
          }}
          dangerouslySetInnerHTML={{ __html: testimonial_section_title }}
        />

        <OwlCarousel
          className="owl-theme"
          loop
          items={5}
          center
          autoplay={true}
          autoplayTimeout={5000}
          autoplayHoverPause
          responsive={responsive}
          margin={20}
          dots={false}
        >
          {testimonials.map((testimony, index) => (
            <TestimonyCard key={index} testimony={testimony} />
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Testimonials;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Get } from "../../../utils/request";
import { FaBook } from "react-icons/fa";
import Board from "./Board";
import { blue } from "@mui/material/colors";
import { Typography } from "@mui/material";

const ProfileStatistics = (props) => {
  const { user_id } = useParams();

  const [coursesCount, SetCoursesCount] = useState(0);

  const GetData = async () => {
    let response = await Get(`user/account-statistics/${user_id}`);
    SetCoursesCount(response?.coursesCount);
  };

  useEffect(() => GetData(), []);

  return (
    <div className="statistics">
      <Typography
        variant={"h5"}
        sx={{
          mb: 1,
          mt: 2,
        }}
      >
        <b>Estatísticas</b>
      </Typography>
      <Board
      maxWidth='none'
        title="Cursos adquiridos"
        data={coursesCount}
        icon={<FaBook size={30} />}
        colors={blue}
      />
    </div>
  );
};

export default ProfileStatistics;

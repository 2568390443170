import React, { useState } from "react";

import { MdDelete, MdEdit, MdRemove, MdOndemandVideo } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from "../../../components/DefaultButton";
import { Post } from "../../../utils/request";
import { Show } from "../../../actions/SnackbarActions";
import { Box, CircularProgress, Modal } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import Orders from "pages/Users/Orders/Orders";
import CloseIcon from "@mui/icons-material/Close";

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  minWidth: "36rem",
  width: "85%",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
  padding: "2rem 3rem",
  justifyContent: "center",
  alignItems: "center",
};

const Board = (props) => {
  const {
    id = "",
    name = "",
    email = "",
    createdAt = "",
    role = "",
    OnDelete,
  } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, SetLoading] = useState(false);

  const [popup, SetPopup] = useState(false);

  const dispatch = useDispatch();

  const Delete = async () => {
    let form = new FormData();
    form.append("id", id);

    SetLoading(true);
    let response = await Post("user/delete", form);
    SetLoading(false);

    if (response?.status === true) OnDelete();

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );
  };

  return (
    <tr id={id}>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modal_style}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "0.4rem",
              right: "0.4rem",
              cursor: "pointer",
              color: "#000",
              fontSize: "2rem",
            }}
            onClick={handleClose}
          />
          <Orders {...{ user_id: id }} />
        </Box>
      </Modal>
      <td>{name}</td>
      <td>{email}</td>
      <td>{new Date(Date.parse(createdAt)).toLocaleDateString("pt-BR")}</td>
      <td>{role}</td>
      <td>
        <SweetAlert
          warning
          title="Atenção"
          show={popup}
          showCancel
          cancelBtnStyle={{ color: "white", textDecoration: "none" }}
          cancelBtnCssClass="btn-danger"
          cancelBtnText="Cancelar"
          confirmBtnText="Desativar"
          onConfirm={() => {
            SetPopup(false);
            Delete();
          }}
          onCancel={() => SetPopup(false)}
        >
          Deseja mesmo desativar a conta com e-mail '{email}' do sistema?
        </SweetAlert>
        <DefaultButton
          to={`/profile/view/${id}`}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Ver perfil`}
          bg="warning"
          icon={<BsFillEyeFill size={17} color="white" />}
          style={{
            marginRight: "2px",
          }}
        />
        <DefaultButton
          to={`/profile/edit/${id}`}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Editar perfil`}
          bg="info"
          icon={<MdEdit size={17} color="white" />}
          style={{
            marginRight: "2px",
          }}
        />
        <DefaultButton
          onClick={() => SetPopup(true)}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Desativar conta`}
          bg="danger"
          loading={loading}
          icon={!loading && <MdRemove size={17} color="white" />}
        />
        <DefaultButton
          onClick={handleOpen}
          width="2.2em"
          height="2.2em"
          padding={0}
          title={`Ver cursos`}
          bg="primary"
          loading={loading}
          icon={!loading && <MdOndemandVideo size={17} color="white" />}
        />
      </td>
    </tr>
  );
};

export default Board;

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import module_style from "./index.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useHistory, useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import { Post } from "utils/request";
import LoginIcon from "@mui/icons-material/Login";
import rt_logo from "assets/images/rt/main_logo_bgtransparent.png";
import { Header } from "modules/Header";
import { useMainPages } from "modules/Header/data";
import { useParams } from "react-router-dom";

const Login = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { course_id } = useParams();

  const [mui_theme, setMuiTheme] = useState({});

  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");

  const [remember, SetRemember] = useState(false);
  const [errorsList, SetErrors] = useState([]);
  const [link, SetLink] = useState(null);
  const [submitting, SetSubmitting] = useState(false);

  const [showPassword, SetShowPassword] = useState(false);

  const loginTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.login
  );

  const pages = useMainPages();

  // foca o input da div
  const focusInput = (e) => {
    e.currentTarget.querySelector("input").focus();
  };

  useEffect(() => {
    setMuiTheme(loginTheme?.palette?.data);
  }, [loginTheme]);

  const Login = async () => {
    if(submitting==true){
      return;
    }
    let form = new FormData();

    form.append("email", email);
    form.append("password", password);

    SetSubmitting(true);
    let response = await Post("auth/login", form);

    if (response?.status) {
      let token = response["access_token"];
      let user = response["user"];

      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));

      SetSubmitting(false);
      dispatch({ type: "login", payload: { token: token, user: user } });

      if (course_id !== undefined && course_id !== null) {
        setTimeout(() => {
          history.push("/courses/payment/" + course_id);
        }, 1000);
        return;
      }

      if (response?.user?.role !== "Admin" && response?.user?.first_access) {
        return history.push("/change_password");
      }
    } else if (response?.errors) {
      SetSubmitting(false);
      let errors = Object.values(response?.errors);
      SetErrors(errors);
      if (response?.link)
        SetLink(
          <Link to={response?.link?.href}>{response?.link?.message}</Link>
        );
      else SetLink(null);
      return;
    }

    history.push(
      location?.state?.redirectTo
        ? location?.state?.redirectTo
        : response?.user?.role === "Admin" ||
          response?.user?.role === "Professor" ||
          response?.user?.role === "Colaborador"
        ? "/dashboard"
        : "/"
    );
  };

  useEffect(() => {
    if (location?.state && location?.state?.email) {
      SetEmail(location?.state?.email);
    }
  }, []);

  return (
    <>
      {props.modal!=true && <Header pages={pages} />}
      <div
        className={props.modal!=true ? module_style.LoginContainer:''}
        style={{
          backgroundColor: mui_theme?.section_main_color,
        }}
      >
        <Box
          sx={{
            display: "flex",
            margin: "auto",
            borderRadius: "0.4rem",
            overflow: "hidden",
            boxShadow: { xs: "none", md: "0 1rem 2rem rgba(0, 0, 0, 0.1)" },
            flexWrap: { xs: "wrap", md: "nowrap" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              backgroundColor: mui_theme?.section_left_color,
              padding: "4rem",
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%!important", md: "34rem!important" },
              alignItems: "center",
              justifyContent: "space-between",
              backgroundImage: mui_theme?.background_image,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <img
              src={rt_logo}
              style={{ objectFit: "contain", width: "10rem" }}
            />
            <Typography
              variant="h6"
              sx={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{ __html: mui_theme?.text }}
            />
          </Box>

          <Box
            sx={{
              backgroundColor: mui_theme?.section_right_color,
              padding: "4rem",
              width: { xs: "100%!important", md: "34rem!important" },
            }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{ __html: mui_theme?.title }}
            />
            <Typography
              variant="body1"
              sx={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{ __html: mui_theme?.subtitle }}
            />

            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.4rem",
                marginTop: "2rem",
              }}
            >
              <div
                className={module_style.FormInputContainer}
                style={{
                  borderBottom: `0.1rem solid ${mui_theme?.input_color}`,
                  backgroundColor: mui_theme?.input_bg_color,
                }}
                onClick={(e) => {
                  focusInput(e);
                }}
              >
                <EmailIcon sx={{ color: mui_theme?.input_color }} />
                <input
                  type="text"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => {
                    SetEmail(e.target.value);
                  }}
                  style={{
                    color: mui_theme?.input_color,
                  }}
                />
              </div>

              <div
                className={module_style.FormInputContainer}
                style={{
                  borderBottom: `0.1rem solid ${mui_theme?.input_color}`,
                  backgroundColor: mui_theme?.input_bg_color,
                }}
                onClick={(e) => {
                  focusInput(e);
                }}
              >
                <HttpsIcon sx={{ color: mui_theme?.input_color }} />
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Senha"
                  value={password}
                  onChange={(e) => {
                    SetPassword(e.target.value);
                  }}
                  style={{
                    color: mui_theme?.input_color,
                  }}
                />
                <IconButton
                  onClick={() => SetShowPassword(!showPassword)}
                  sx={{
                    padding: 0,
                    color: mui_theme?.input_color,
                  }}
                >
                  {!showPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon sx={{ color: "#ffac1c" }} />
                  )}
                </IconButton>
              </div>

              <Alert severity="error" hidden={errorsList.length == 0}>
                {errorsList[0]}
                {link}
              </Alert>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <div className="flex fdrow align-center text-muted">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={remember}
                        onChange={(e) => SetRemember(e.target.checked)}
                        sx={{
                          color: mui_theme?.accent_color,
                          opacity: 0.8,
                          "&.Mui-checked": {
                            color: mui_theme?.accent_color,
                            opacity: 1,
                          },
                        }}
                      />
                    }
                    style={{ color: mui_theme?.font_color }}
                    label="Lembre-se de mim"
                  />
                </div>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: mui_theme?.accent_color,
                    color: mui_theme?.font_color,
                    padding: "0.5rem 1.6rem",
                    "&:hover": {
                      backgroundColor: mui_theme?.accent_color,
                      filter: "brightness(0.9)",
                    },
                  }}
                  onClick={Login}
                >
                  {submitting ? (
                    <CircularProgress
                      size={20}
                      style={{
                        color: mui_theme?.font_color,
                        marginRight: "0.5rem",
                      }}
                    />
                  ) : (
                    <LoginIcon style={{ marginRight: "0.5rem" }} />
                  )}
                  Entrar
                </Button>
              </div>
              <span style={{ color: mui_theme?.font_color }}>
                <Link
                  className="text-primary"
                  to={{
                    pathname: "/forgot-password",
                    state: {
                      email: email,
                      ...location?.state,
                    },
                  }}
                >
                  Esqueceu sua senha?
                </Link>
              </span>
              {/* <span style={{ color: mui_theme?.font_color }}>
                Se você não tiver uma conta,{" "}
                <Link
                  className="text-primary"
                  to={{
                    pathname: "/register",
                    state: {
                      email: email,
                      ...location?.state,
                    },
                  }}
                >
                  Cadastre-se agora!
                </Link>
              </span> */}
            </form>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Login;

import { Box, Card, CardContent, CardMedia, IconButton } from "@mui/material";
import Image from "components/Image";
import { useContext, useState } from "react";

import CloseIcon from '@mui/icons-material/Close';
import VideoModal from "./Modal";
import styles from "./index.module.css";
import { VideosContext } from "..";
import VideoFrame from "components/VideoFrame";

const VideoCard = props => {

    const {
        id,
        name,
        thumbnail,
        file,
        ...others
    } = props;

    const [modal, SetModal] = useState(false);

    const OpenModal = () => SetModal(true);
    const CloseModal = () => SetModal(false);

    const { videos, SetVideos } = useContext(VideosContext);

    const Delete = e => {
        e.stopPropagation();
        const tempVideo = [...videos];
        const index = videos.findIndex((obj => obj?.id === id));
        tempVideo.splice(index, 1);
        SetVideos([...tempVideo]);
    }
    
    return (
        <>
            <Card
                onClick={OpenModal}
                className={styles.card}
            >
                <IconButton
                    size={"small"}
                    className={styles.closeButton}
                    onClick={Delete}
                    style={{zIndex:1,backgroundColor:'rgba(0,0,0,0.5)',color:'white'}}
                >
                    <CloseIcon />
                </IconButton>
                {thumbnail ? (
                    <Image
                        src={thumbnail}
                        width={"100%"}
                        height={"7em"}
                        style={{
                            backgroundSize: "cover"
                        }}
                    />
                ) : (
                    <VideoFrame
                        src={file}
                        style={{
                            backgroundSize: "cover",
                            width: "100%",
                            height: "7em"
                        }}
                    />
                )}
                <CardContent
                    className={styles.content}
                >
                    {name}
                </CardContent>
            </Card>
            <VideoModal
                open={modal}
                onClose={CloseModal}
                id={id}
                name={name}
                thumbnail={thumbnail}
            />
        </>
    );
}

export default VideoCard;
import { Box, Card, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import LinkIcon from '@mui/icons-material/Link';
import { blue } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import Actions from "./Actions";

const useStyles = makeStyles({
    container: {
        overflow: "visible"
    }
});

const QuickActions = () => {

    const user = useSelector(store => store.AppReducer.user);
    const classes = useStyles();

    return (
        <Box
            component={Card}
            padding={1.5}
            paddingTop={4}
            elevation={5}
            position={"relative"}
            className={classes.container}
            sx={{
                borderRadius: "1rem",
                overflow: "hidden",
                boxShadow: "0 0 0 0.1rem rgb(43 43 43 / 20%)",
                backgroundColor: "rgb(240, 244, 244)",
                marginBottom: '2.6rem',
              }}
        >
            <TopIcon/>
            <Typography sx={{marginLeft: '0.5rem'}}>
                {user?.name}, seja bem vindo(a)
            </Typography>
            <Actions/>
        </Box>
    );
}

const TopIcon = () => {
    return (
        <Box
            position={"absolute"}
            bgcolor={blue["600"]}
            color={"white"}
            padding={1}
            borderRadius={5}
            top={-15}
            left={20}
        >
            <LinkIcon/>
        </Box>
    );
}

export default QuickActions;
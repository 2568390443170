import React from "react";
import { Box, Typography } from "@mui/material";

const TabPanel = (props) => {
  const { children, value, index, style, ...other } = props;

  return (
    <div
      role="tabpanel"
        style={style}
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box
        // p={3}
        style={{ paddingTop: 24}}
        // hidden={value != index}
      >
        <Typography component={"span"}>{children}</Typography>
      </Box>
    </div>
  );
};

export default TabPanel;

import { FormLabel, FormControl as Input } from "react-bootstrap";
import InputCard from "../../InputCard";
import DefaultButton from "components/DefaultButton";
import JoditEditor from "jodit-react";
import ColorInput from "components/Inputs/ColorInput";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import Label from "components/InfoLabel";
import { useEffect } from "react";

const Section2 = ({
  show_section2,
  SetShowSection2,

  whatWillLearn,
  SetWhatWillLearn,
  section2_title,
  SetSection2Title,

  section2_bgColor_1,
  SetSection2BgColor1,
  section2_bgColor_2,
  SetSection2BgColor2,
  section2_cardTextSize,
  SetSection2CardTextSize,

  section2_cardBorderColor,
  SetSection2CardBorderColor,
  section2_cardBorderSize,
  SetSection2CardBorderSize,

  section2_cardColor,
  SetSection2CardColor,
  section2_cardTextColor,
  SetSection2CardTextColor,

  JoditConfig,
}) => {
  // whatWillLearn
  const addWhatWillLearn = () => {
    SetWhatWillLearn([...whatWillLearn, { text: "" }]);
  };

  const removeWhatWillLearn = (index) => {
    let temp = [...whatWillLearn];
    temp.splice(index, 1);

    SetWhatWillLearn(temp);
  };

  const handleWhatWillLearnChange = (index, text) => {
    let temp = whatWillLearn;
    temp[index].text = text;
    SetWhatWillLearn([...temp]);
  };

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={show_section2}
              onChange={(e) => SetShowSection2(!show_section2)}
            />
          }
          label="Ativar seção"
        />
      </FormGroup>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          gap: "1rem",
          marginTop: "2rem",
          position: "relative",
        }}
      >
        <div
          style={{
            display: show_section2 ? "none" : "flex",
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.5)",
            zIndex: 10,
          }}
        >
          <div
            style={{
              margin: "auto",
              backgroundColor: "white",
              color: "red",
              padding: "1rem",
              borderRadius: "0.4rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.6rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
              }}
            >
              Seção desativada
            </Typography>
            <Typography
              variant="subtitle"
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Por padrão, esta seção não sera exibida na página do curso. Clique
              em "Ativar seção" para ativá-la.
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={show_section2}
                    onChange={(e) => SetShowSection2(!show_section2)}
                  />
                }
                sx={{ color: "black" }}
                label="Ativar seção"
              />
            </FormGroup>
          </div>
        </div>
        <FormLabel>
          <Label
            label={
              <span style={{ fontSize: "1.2rem" }}>
                O que você vai aprender
              </span>
            }
            required
            title="Seção para adicionar os tópicos que o aluno vai aprender com o curso."
          />
        </FormLabel>

        <FormLabel>
          Título da seção<span style={{ color: "red" }}>*</span>
          <JoditEditor
            config={JoditConfig}
            value={section2_title}
            onChange={(value) => SetSection2Title(value)}
          />
        </FormLabel>

        <FormLabel>
          <Label
            label={<span>Cor de fundo 1 </span>}
            required
            title="Esta cor será aplicada no fundo da seção."
          />
        </FormLabel>
        <ColorInput
          value={section2_bgColor_1}
          onChange={(color) =>
            color !== undefined && SetSection2BgColor1(color)
          }
        />

        <FormLabel>
          <Label
            label={<span>Cor de fundo 2 </span>}
            required
            title="Esta cor será aplicada no fundo da seção. Se for definida, um gradiente será aplicado baseado na cor 1."
          />
        </FormLabel>
        <ColorInput
          value={section2_bgColor_2}
          onChange={(color) =>
            color !== undefined && SetSection2BgColor2(color)
          }
        />

        <div
          style={{
            display: "flex",
            gap: "2rem",
            flexWrap: "wrap",
            marginTop: "1rem",
            maxHeight: "40rem",
            overflowY: "auto",
            padding: "1rem 0",
          }}
        >
          {whatWillLearn.map((item, index) => (
            <InputCard
              key={index}
              index={index}
              remove={removeWhatWillLearn}
              handle={handleWhatWillLearnChange}
              text={item.text}
              JoditConfig={JoditConfig}
            />
          ))}
          {whatWillLearn.length === 0 && (
            <Typography
              variant="subtitle"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              Nenhum item adicionado
            </Typography>
          )}
        </div>

        <DefaultButton
          bg="confirm"
          text="Adicionar opção"
          onClick={addWhatWillLearn}
          sx={{ margin: "1rem 0" }}
        />
      </div>

      <FormLabel>
        <Label
          label={<span>Cor dos cartões </span>}
          required
          title="Esta cor será aplicada nos cartões da seção."
        />
      </FormLabel>
      <ColorInput
        value={section2_cardColor}
        onChange={(color) => color !== undefined && SetSection2CardColor(color)}
      />

      <FormLabel>
        <Label
          label={<span>Cor do texto dos cartões </span>}
          required
          title="Esta cor será aplicada no texto dos cartões da seção."
        />
      </FormLabel>
      <ColorInput
        value={section2_cardTextColor}
        onChange={(color) =>
          color !== undefined && SetSection2CardTextColor(color)
        }
      />

      <br />
      <FormLabel>
        <Label
          label={<span>Tamanho da fonte</span>}
          required
          title="Tamanho da fonte dos cartões da seção."
        />
      </FormLabel>
      <Input
        type="number"
        style={{
          maxWidth: "4rem",
        }}
        required
        value={section2_cardTextSize}
        onChange={(e) => SetSection2CardTextSize(e.target.value)}
      />

      <br />

      <FormLabel>
        <Label
          label={<span>Cor da moldura dos cartões </span>}
          required
          title="Esta cor será aplicada na moldura dos cartões da seção."
        />
      </FormLabel>
      <ColorInput
        value={section2_cardBorderColor}
        onChange={(color) =>
          color !== undefined && SetSection2CardBorderColor(color)
        }
      />

      <br />

      <FormLabel>
        <Label
          label={<span>Tamanho da moldura em pixels</span>}
          required
          title="Tamanho da moldura dos cartões da seção."
        />
      </FormLabel>
      <Input
        type="number"
        style={{
          maxWidth: "4rem",
        }}
        required
        value={section2_cardBorderSize}
        onChange={(e) => SetSection2CardBorderSize(e.target.value)}
      />
    </div>
  );
};

export default Section2;

import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Get } from "utils/request";

import styles from "./index.module.css";

const CoursesAd = (props) => {
  const [section2_cards, setSection2Cards] = useState([]);
  const [title, setTitle] = useState("");
  const {...others} = props;

  const { data } = useQuery(
    "about-us-page",
    async () => await Get("site-rt/about-us-page")
  );
  const { aboutUsPage, studentUsers } = data || {};

  useEffect(() => {
    setSection2Cards(aboutUsPage?.winCards);
    setTitle(aboutUsPage?.section2_title);
    console.log(aboutUsPage)
  }, [aboutUsPage]);

  useEffect(() => {
    console.log("studentUsers", studentUsers);
  }, [studentUsers]);

  return (
    <Container maxWidth="xl" sx={{position: 'relative'}}>
      <Box className={styles.container} {...others}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{
            fontWeight: "bold",
            // marginBottom: '2rem'
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Box
          className={styles.cardsGroup}
          sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}
        >
          {section2_cards && section2_cards.map((courseAd, index) => (
            <Card
              key={index}
              style={{
                background: courseAd?.bg_color,
                color: courseAd?.font_color,
                padding: "2rem",
                flexBasis: "20rem",
                boxShadow: 'none',
                borderRadius: '0.8rem'
              }}
              raised
            >
              <CardContent className={styles.cardContent}>
                <Typography
                  variant="h5"
                  align="center"
                  className={styles.cardsTitle}
                >
                  {courseAd?.title}
                </Typography>
                <Typography align="center">{courseAd?.text}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default CoursesAd;

import React, { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "components/DefaultButton";
import JoditEditor from "jodit-react";
import JoditConfig from "utils/joditConfig";
import FormCard from "./FormCard";
import ImageInput from "components/Inputs/ImageInput";
import "../../../../assets/css/styles.css";

const contentConfig = new JoditConfig("science-plus/upload-image").config();

const Formbody = (props) => {
  const { loading, OnConfirm } = props;

  const [title, SetTitle] = useState("");
  const [content, SetContent] = useState("");
  const [image, SetImage] = useState("");
  const [description, SetDescription] = useState("");
  const [links, SetLinks] = useState([]);
  const [reference_post_author, SetReferencePostAuthor] = useState("");
  const [reference_link, SetReferenceLink] = useState("");

  return (
    <div className="form-course">
      <form>
        <FormLabel>
          Imagem da postagem <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <ImageInput ImageChange={(image) => SetImage(image)} />
        <br />

        <FormLabel>
          Título <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Título"
          value={title}
          onChange={(e) => SetTitle(e.target.value)}
          required
        />
        <br />

        <FormLabel>Descrição</FormLabel>
        <Input
          placeholder="Descrição"
          value={description}
          onChange={(e) => SetDescription(e.target.value)}
          required
          as="textarea"
        />
        <br />

        <FormLabel>
          Conteúdo <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <JoditEditor
          config={{ defaultLanguage: "pt_br", language: "pt_br" }}
          value={content}
          onChange={(value) => SetContent(value)}
        />
        <br />

        {/* <FormCard
                    onChange={value => SetLinks([...value])}
                    initialCards={[]}
                    headers={[
                        { label: "Número", prop: "number" }
                    ]}
                /> */}
        <FormCard
          onChange={(value) => SetLinks([...value])}
          initialCards={links}
        />
        <br />
        <br />

        <FormLabel>
          Nome do autor da referência <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Título"
          value={reference_post_author}
          onChange={(e) => SetReferencePostAuthor(e.target.value)}
          required
        />
        <br />

        <FormLabel>
          Link da referência <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Título"
          value={reference_link}
          onChange={(e) => SetReferenceLink(e.target.value)}
          required
        />
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to="/science-plus/list"
        />
        <DefaultButton
          bg="confirm"
          text="Criar postagem"
          onClick={() => {
            let data = {
              title: title,
              content: content,
              image: image,
              links: links,
              description: description,
              reference_post_author: reference_post_author,
              reference_link: reference_link,
            };
            OnConfirm(data);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Formbody;

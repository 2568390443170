import React, { useState } from "react";

import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Post } from "utils/request";
import { Show } from "actions/SnackbarActions";
import FormBody from "./FormBody";

const AboutUsPageConfig = () => {

    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const [loading, SetLoading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const Submit = async data => {
        let form = new FormData();

        form.append("title", data?.title);
        form.append("subtitle", data?.subtitle);
        form.append("statisticsText", data?.statisticsText);
        form.append("missionText", data?.missionText);
        form.append("introText", data?.introText);
        for (let i = 0; i < data?.cards?.length; i++) form.append("cards[]", JSON.stringify(data?.cards[i]));

        SetLoading(true);
        let response = await Post("site-rt/about-us-page/update", form);
        SetLoading(false);

         

        if (response?.status === true) {
            dispatch(Show({
                show: true,
                message: response?.message,
                severity: response?.severity
            }));
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <SweetAlert
                    warning
                    show={popup}
                    title="Atenção!"
                    confirmBtnText="Ok"
                    onConfirm={() => SetPopup(false)}
                >
                    {popupMessage}
                </SweetAlert>
                <h2 className="title">Configurações da página "sobre nós"</h2>
                <p className="text-muted">Aqui é possivel modificar o estilo da página sobre nós</p>
                <hr/>
                <FormBody
                    onConfirm={data => Submit(data)}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default AboutUsPageConfig;
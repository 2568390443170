import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Get } from "../../../utils/request";
import Board from "./Board";
import { Pagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";

const Table = props => {

    const {
        search = ""
    } = props;

    const [users, SetUsers] = useState([]);
    const [loading, SetLoading] = useState(false);

    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const dispatch = useDispatch();

    const GetData = async () => {
        SetLoading(true);
        let response = await Get(`user?search=${search}&page=${page}`);
        SetLoading(false);

        if (!response?.status) return;//tratar esse erro jogando um alerta e dps mandando para outra página?
        else if (response?.status === true) {
            SetUsers(response?.users);
            SetMaxPage(response?.pagination?.last_page);
        } else if (!response) dispatch(Show({
            show: true,
            severity: "warning",
            message: "Falha ao carregar as contas"
        }));
    }

    useEffect(GetData, [search, page]);

    return (
        <>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Data cadastrada</th>
                            <th>Função</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && users && users.length != 0 && users.map((value, index) => (
                            <Board
                                name={value?.name}
                                email={value?.email}
                                createdAt={value?.created_at}
                                role={value?.role}
                                key={index}
                                id={value?.id}

                                OnDelete={GetData}
                            />
                        ))}
                    </tbody>
                </table>
                <div className="flex align-center jcc w100">
                    <p hidden={loading || (users && users.length != 0)}>Nenhuma conta achada</p>
                </div>
            </div>
            <div className="flex align-center jcc w100">
                <CircularProgress className={loading ? "" : "hide"}/>
            </div>
            <Pagination
                className={(maxPage == 1 ? "hide" : "")}
                style={{display: "flex", justifyContent: "flex-end"}} 
                count={maxPage} 
                value={page}
                onChange={(_, value) => SetPage(value)}
            />
        </>
    );
}

export default Table;
import React from "react";
// import under_develop from "assets/images/under-develop.svg";
import { Get, Post } from "utils/request";
import DefaultButton from "components/DefaultButton";
import { IoMdEye } from "react-icons/io";
import { Button, CircularProgress, IconButton, Pagination, TextField, Tooltip } from "@mui/material";
import { MdInfo } from "react-icons/md";
import "./styles.css";
import { GiReceiveMoney } from "react-icons/gi";
import { moneyMask } from "utils/moneyMask";
import { renderToast, ToastContent } from "utils/Alerts";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { blue, purple, red } from "@mui/material/colors";
import { maskDatas, maskInputPrice, maskPrice } from "Auxiliar/Masks";
import StatusStyle from "utils/StatusStyle";
import Filter from "utils/Filter";

const DashFinanceiro = () => {
  const [data, setData] = React.useState("");
  const [sales, setSales] = React.useState("");
  const [withdrawals, setWithdrawals] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [search, setSearch] = React.useState('')
  const [inputSearch, setInputSearch] = React.useState('')
  const [dateOf, setDateOf] = React.useState('')
  const [dateFor, setDateFor] = React.useState('')

  const [allow, setAllow] = React.useState(true)
  const [loading, setLoading] = React.useState(true);
  const [loadingTable, setLoadingTable] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    totalItems: '', pageNumber: 0, perPage: 10
  })

  const [options, setOptions] = React.useState({
    created: { value: false, label: 'Criado', checked: false },
    pending: { value: false, label: 'Pendente', checked: false },
    processing: { value: false, label: 'Processando', checked: false },
    transferred: { value: false, label: 'Transferido', checked: false },
    failed: { value: false, label: 'Falha', checked: false },
    canceled: { value: false, label: 'Cancelado', checked: false },
  })

  React.useEffect(() => {
    setSales([
      {
        date: "2021/09/09",
        salesCount: 0,
      },
      {
        date: "2021/09/10",
        salesCount: 1,
      },
      {
        date: "2021/09/11",
        salesCount: 0,
      },
      {
        date: "2021/09/12",
        salesCount: 3,
      },
      {
        date: "2021/09/13",
        salesCount: 0,
      },
      {
        date: "2021/09/14",
        salesCount: 0,
      },
      {
        date: "2021/09/15",
        salesCount: 0,
      },
      {
        date: "2021/09/16",
        salesCount: 0,
      },
      {
        date: "2021/09/17",
        salesCount: 0,
      },
    ]);
  }, [])

  React.useEffect(() => {
    if (allow) getData()
    if (!search) setInputSearch('')
  }, [pagination.pageNumber, search, allow])

  const getData = async () => {
    setLoadingTable(true)

    //Passing all values ​​to the search api, if they are empty they are ignored
    const status = getStatus()

    const response = await Get(`balance?page=${pagination.pageNumber + 1}&status=${status ? status : ''}
      &dateOf=${dateOf ? dateOf : ''}&dateFor=${dateFor ? dateFor : ''}&search=${search}`);

    if (response.httpCode === 200) {
      setData(response.balance);
      setSales(response.courses);
      setWithdrawals(response.withdrawals);
      if (!pagination.totalItems) setPagination({ ...pagination, totalItems: response.pagination.total_pages });
    } else {
      renderToast({ type: 'error', msg: response.message ?? 'Falha ao carregar dados' })
    }

    setLoading(false);
    setLoadingTable(false);
  };

  //Replacing the true value of each key of the options object by its own name, so that they can be used in the Pagar.me request
  const getStatus = () => {
    let status = ''
    Object.keys({ ...options }).forEach(item => { if (options[item].value) status = item })
    return status
  }

  let timer
  const handleSearch = ({ target }) => {
    setInputSearch(target.value)

    clearTimeout(timer)
    timer = setTimeout(() => { setSearch(target.value.replace(/[^0-9.]/g, '') ?? 0); setAllow(true); setPagination({ ...pagination, pageNumber: 0 }) }, 750)
  }

  let timeout;
  const handleWithdrawal = async () => {
    clearTimeout(timeout);
    setDisabled(true);

    if (data.available_amount !== 0) {
      const response = await Post(
        "balance/withdrawal",
        JSON.stringify({ amount: data.available_amount }),
        { "Content-Type": "application/json" }
      );
      console.log("resp withdrawal", response);
      if (response) {
        setDisabled(false);
        renderToast({ type: "error", msg: response.withdrawal.message });
      }
    } else {
      timeout = setTimeout(() => {
        setDisabled(false);
      }, 2500);
      renderToast({
        type: "error",
        msg: "Saldo insuficiente para realização de saque!",
      });
    }
  };
  return (
    <div className="card">
      {!loading ? (
        <div className="card-body">
          <h3 className="title">Resumo Financeiro</h3>
          <p className="text-muted">Gerencie suas vendas e saques por aqui!</p>
          <hr />
          <ResponsiveContainer width={"100%"} height={300}>
            <LineChart width={700} height={230} data={sales}>
              <CartesianGrid strokeDasharray={"3.3"} />
              <XAxis dataKey={"date"} />
              <YAxis />
              <Tooltip />
              <Legend iconType={"plainline"} />
              <Line
                type={"monotone"}
                dataKey={"salesCount"}
                stroke={blue[700]}
                name={"Vendas no sistema"}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
          <h3 className="title mt-5">Saques</h3>
          <p className="text-muted">Comece a sacar com saldo disponível!</p>
          {data && (
            <>
              <hr />
              <div
                className="row py-5 rounded"
                style={{ backgroundColor: "#DCDCDC" }}
              >
                <div className="col-md-5 balance-card my-2">
                  <h6 className="mb-3">Valores disponíveis</h6>
                  <div
                    className="d-flex justify-content-center input-group"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <p>
                      {moneyMask(data.available_amount)} {data.currency}
                    </p>
                    <Button
                      onClick={handleWithdrawal}
                      disabled={disabled}
                      variant="contained"
                      color="success"
                      endIcon={<GiReceiveMoney />}
                    >
                      Sacar
                    </Button>
                  </div>
                </div>
                <div className="col-md-5 balance-card my-2">
                  <div className="d-flex justify-content-center">
                    <h6 className="mb-3 me-1">Valores em pendente</h6>
                    <div className="d-flex align-self-start">
                      <Tooltip
                        title="Demoram cerca de alguns dias para a liberação dos valores"
                        arrow
                        placement="top"
                      >
                        <IconButton sx={{ padding: 0 }}>
                          <MdInfo />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <p>
                    {moneyMask(data.waiting_funds_amount)} {data.currency}
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="mt-5 mb-2">
            <div className="d-flex align-items-center ">
              <h3 className="title">Registros de Saques</h3>
              <Filter setDateOf={setDateOf} setDateFor={setDateFor} dateOf={dateOf} dateFor={dateFor} options={options} setOptions={setOptions}
                setAllow={setAllow} setPagination={setPagination} setSearch={setSearch} />
            </div>
            <p className="text-muted">
              Aqui são mostrados os históricos de saques
            </p>

            <div className="col-lg-4 col-md-6 col-12 mt-2">
              <TextField
                fullWidth
                value={maskInputPrice(inputSearch)}
                label='Pesquisar...'
                onChange={handleSearch}
              />
            </div>
          </div>
          <hr />
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <td>Valor</td>
                <td>Status</td>
                <td>Data</td>
              </tr>
            </thead>
            <tbody>
              {!loadingTable ?
                <>
                  {withdrawals.length > 0 ? (
                    withdrawals.map((item, index) => {
                      const { status, style } = StatusStyle(item.status)

                      return (
                        <tr key={index}>
                          <td>{maskPrice(item.amount)}</td>
                          <td><p className='status px-3' style={style}>{status}</p></td>
                          <td>{maskDatas(item.created_at)}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={3}>
                        <p className="text-muted">Sem saques realizados</p>
                      </td>
                    </tr>
                  )}
                </> :
                <tr><td colSpan={3}><CircularProgress /></td></tr>
              }
            </tbody>
          </table>

          {pagination.totalItems &&
            <div className='d-flex justify-content-end mt-3'>
              <Pagination sx={{ webkitFlexWrap: 'nowrap' }} shape="rounded" color='primary' count={Math.ceil(pagination.totalItems / pagination.perPage)} page={pagination.pageNumber + 1} onChange={(e, page) => {
                window.scrollTo(0, 0); setPagination({ ...pagination, pageNumber: page - 1 }); setAllow(true)
              }
              } />
            </div>
          }
        </div>
      ) : (
        <div className="d-flex justify-content-center p-5">
          <CircularProgress />
        </div>
      )}
      <ToastContent />
    </div>
  );
};

export default DashFinanceiro;

import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Post } from "utils/request";
import { useEffect, useState } from "react";
import { set } from "date-fns";
import DefaultButton from "components/DefaultButton";
import { Show } from "actions/SnackbarActions";
import { useDispatch } from "react-redux";

export default function AvailabilityModal({ open, setOpen, user, course_id }) {
  const dispatch = useDispatch();

  const [userCourse, SetUserCourse] = useState(null);
  const [isLoading, SetIsLoading] = useState(true);
  const [newAvailabilityDuration, SetNewAvailabilityDuration] = useState(30);

  const handleNewAvailabilityDuration = async (e) => {
    let form = new FormData();
    form.append("course_id", course_id);
    form.append("user_id", user?.id);
    form.append("availability_duration_days", newAvailabilityDuration);
    let response = await Post("user-course/change-availability-duration", form);
    if (response?.status === true) {
      dispatch(
        Show({ show: true, message: response?.message, severity: "success" })
      );
      setOpen(false);
    } else {
      dispatch(
        Show({ show: true, message: response?.message, severity: "error" })
      );
    }
  };

  const getUserCourse = async () => {
    let form = new FormData();
    form.append("course_id", course_id);
    form.append("user_id", user?.id);
    let response = await Post("user-course/get-by-id", form);
    SetIsLoading(false);
    SetUserCourse(response?.data);
  };

  useEffect(() => {
    if (open) getUserCourse();
  }, [open]);

  return (
    !isLoading && (
      <Modal open={open} onClose={() => setOpen(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "40rem",
            backgroundColor: "white",
            boxShadow: "0 0.5rem 2rem rgba(0, 0, 0, 0.3)",
            padding: "2rem",
            borderRadius: "0.5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>Alterar data de disponibilidade</h1>
            <CloseIcon
              onClick={() => setOpen(false)}
              sx={{
                cursor: "pointer",
              }}
            />
          </div>

          {/* data de compra */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginTop: "2rem",
              fontSize: "1.2rem",
            }}
          >
            <span
              style={{
                backgroundColor: "#f1f1f1",
                display: "flex",
                justifyContent: "space-between",
                padding: "0.3rem 1rem",
                borderRadius: "0.4rem",
                border: "1px solid #c4c4c4",
                gap: "1rem",
              }}
            >
              <strong>Data de compra</strong>
              <span>
                {new Date(userCourse?.created_at).toLocaleDateString()}
              </span>
            </span>
            <span
              style={{
                backgroundColor: "#f1f1f1",
                display: "flex",
                justifyContent: "space-between",
                padding: "0.3rem 1rem",
                borderRadius: "0.4rem",
                border: "1px solid #c4c4c4",
                gap: "1rem",
              }}
            >
              <strong>Tempo de disponibilidade</strong>
              <span>{userCourse?.availability_duration_days} dias</span>
            </span>
            <span
              style={{
                backgroundColor: "#f1f1f1",
                display: "flex",
                justifyContent: "space-between",
                padding: "0.3rem 1rem",
                borderRadius: "0.4rem",
                border: "1px solid #c4c4c4",
                gap: "1rem",
              }}
            >
              <strong>Tempo restante</strong>
              <span>
                {userCourse?.availability_duration_days -
                  Math.floor(
                    (new Date() - new Date(userCourse?.created_at)) /
                      (1000 * 60 * 60 * 24)
                  )}{" "}
                dias
              </span>
            </span>
          </div>

          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginTop: "2rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <strong>Qual será o novo tempo de disponibilidade?</strong>

            <select
              style={{
                padding: "0.5rem 1rem",
                borderRadius: "0.4rem",
                border: "1px solid #c4c4c4",
              }}
              value={newAvailabilityDuration}
              onChange={(e) => SetNewAvailabilityDuration(e.target.value)}
            >
              <option value={0}>Revogar acesso</option>
              <option value={30}>1 mês</option>
              <option value={60}>2 meses</option>
              <option value={90}>3 meses</option>
              <option value={180}>6 meses</option>
              <option value={270}>9 meses</option>
              <option value={360}>12 meses</option>
              <option value={720}>24 meses</option>
            </select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2rem",
            }}
          >
            <DefaultButton
              bg="confirm"
              text="Confirmar"
              onClick={handleNewAvailabilityDuration}
              // loading={loading}
            />
          </div>
        </div>
      </Modal>
    )
  );
}

import { FormLabel, FormControl as Input } from "react-bootstrap";
import VideoInput from "components/Inputs/VideoInput";
import ImageInput from "components/Inputs/ImageInput";
import { maskPrice } from "Auxiliar/Masks";
import Label from "components/InfoLabel";
import Responsibles from "../../Responsibles";
import Category from "../../Category";
import Tags from "../../Tags";
import JoditEditor from "jodit-react";
// import DepoimentosEdit from "./Depoimentos";
import ColorInput from "components/Inputs/ColorInput";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const text_container = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  position: "relative",
};

const text_style = {
  fontSize: "1.5rem",
  fontWeight: "bold",
  position: "absolute",
  margin: 0,
  padding: "0 1rem",
  backgroundColor: "white",
  zIndex: 10,
};

const text_comment = {
  fontSize: "1rem",
  textAlign: "center",
};

function RenderSectionHeader({ title, comment }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "2rem",
      }}
    >
      <div style={text_container}>
        <h4 style={text_style}>{title}</h4>
        <hr style={{ width: "100%" }} />
      </div>
      <span style={text_comment}>{comment}</span>
    </div>
  );
}

const Section6 = ({
  SetResponsibles,
  responsibles,
  SetCategories,
  categories,
  SetTags,
  tags,
  // SetCourseContent,
  // courseContent,
  testimonials,
  SetTestimonials,
  id,

  responsibles_bgColor_1,
  SetResponsiblesBgColor1,
  responsibles_bgColor_2,
  SetResponsiblesBgColor2,
  responsibles_accentColor,
  SetResponsiblesAccentColor,
  responsibles_textColor,
  SetResponsiblesTextColor,

  submit_button_bgColor,
  SetSubmitButtonBgColor,
  submit_button_textColor,
  SetSubmitButtonTextColor,
  
  submit_button_text,
  SetSubmitButtonText,
  submit_wait_text,
  SetSubmitWaitText,
  submit_wait_helptext,
  SetSubmitWaitHelptext,

  course_thumbnail,
  SetCourseThumbnail,

  JoditConfig,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <FormLabel>
        <Label
          label={"Responsável(eis)"}
          required
          title="Professores ou Administradores responsáveis pelo curso"
        />
        <Responsibles
          responsibles={responsibles}
          onChange={(data) => SetResponsibles(data)}
        />
      </FormLabel>

      <FormLabel>
        <Label
          label={<span>Cor de fundo 1 </span>}
          required
          title="Esta cor será aplicada no fundo da seção."
        />
      </FormLabel>
      <ColorInput
        value={responsibles_bgColor_1}
        onChange={(color) =>
          color !== undefined && SetResponsiblesBgColor1(color)
        }
      />

      <FormLabel>
        <Label
          label={<span>Cor de fundo 2 </span>}
          required
          title="Esta cor será aplicada no fundo da seção. Se for definida, um gradiente será aplicado baseado na cor 1."
        />
      </FormLabel>
      <ColorInput
        value={responsibles_bgColor_2}
        onChange={(color) =>
          color !== undefined && SetResponsiblesBgColor2(color)
        }
      />

      <FormLabel>
        <Label
          label={<span>Cor dos cartões </span>}
          required
          title="Esta cor será aplicada nos cartões da seção."
        />
      </FormLabel>
      <ColorInput
        value={responsibles_accentColor}
        onChange={(color) =>
          color !== undefined && SetResponsiblesAccentColor(color)
        }
      />

      <FormLabel>
        <Label
          label={<span>Cor do texto </span>}
          required
          title="Esta cor será aplicada aos textos dos cartões da seção."
        />
      </FormLabel>
      <ColorInput
        value={responsibles_textColor}
        onChange={(color) =>
          color !== undefined && SetResponsiblesTextColor(color)
        }
      />

      {RenderSectionHeader({
        title: "Botão de inscrição",
        comment: "Botão de inscrição do curso",
      })}

      <FormLabel>
        Texto do botão de inscrição<span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={submit_button_text}
          onChange={(value) => SetSubmitButtonText(value)}
        />
      </FormLabel>
      <FormLabel>
        Texto do botão de aguardando<span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={submit_wait_text}
          onChange={(value) => SetSubmitWaitText(value)}
        />
      </FormLabel>
      <FormLabel>
        Mensagem de aguardando<span style={{ color: "red" }}>*</span>
        <JoditEditor
          config={JoditConfig}
          value={submit_wait_helptext}
          onChange={(value) => SetSubmitWaitHelptext(value)}
        />
      </FormLabel>

      <FormLabel>
        <Label
          label={<span>Cor de fundo do botão</span>}
          required
          title="Esta cor será aplicada no fundo do botão de inscrição."
        />
      </FormLabel>
      <ColorInput
        value={submit_button_bgColor}
        onChange={(color) =>
          color !== undefined && SetSubmitButtonBgColor(color)
        }
      />

      <FormLabel>
        <Label
          label={<span>Cor do texto do botão</span>}
          required
          title="Esta cor será aplicada no texto do botão de inscrição."
        />
      </FormLabel>
      <ColorInput
        value={submit_button_textColor}
        onChange={(color) =>
          color !== undefined && SetSubmitButtonTextColor(color)
        }
      />

      <FormLabel>
        <Label label={"Categoria(s)"} required title="Categoria(s) do curso" />
        <Category
          categories={categories}
          onChange={(data) => SetCategories(data)}
        />
      </FormLabel>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Apenas Administradores e
        Colaboradores podem criar categorias, se você não conseguir encontrar a
        categoria desejada, entre em contato com a equipe RT.
      </span>

      <FormLabel>
        <Label
          label={"Tag(s)"}
          title="Tag(s) do curso para nos auxiliar na identificação"
        />
        <Tags tags={tags} onChange={(data) => SetTags(data)} />
      </FormLabel>

      <FormLabel>
        Imagem de capa na tela Netflix
        <ImageInput
          path={course_thumbnail}
          ImageChange={(image) => SetCourseThumbnail(image)}
        />
      </FormLabel>

      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> 1280x720 (16:9) ou
        854x480 (16:9).
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não se recomenda usar
        outra proporção de tela além da proporção 16:9 porque a imagem será
        usada como cartão ao mostrar os outros cursos.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Formatos aceitos: .jpg,
        .jpeg, .png e .gif.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Não há limite de tamanho
        para o arquivo.
      </span>
      <span
        style={{
          padding: "0.2rem 0.4rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f1f1f1",
        }}
      >
        <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Caso não seja inserida
        uma imagem, será usada a imagem do curso por padrão.
      </span>

      {/* <FormLabel>
        <Label
          label={<span>Conteúdo do curso</span>}
          required
          title="Conteúdo do curso para nos auxiliar a escolha do aluno sobre os assuntos abordados no curso."
        />

        <JoditEditor
          config={JoditConfig}
          value={courseContent}
          onChange={(value) => SetCourseContent(value)}
        />
      </FormLabel> */}

      {/* <DepoimentosEdit {...{ testimonials, SetTestimonials, id }} /> */}
    </div>
  );
};

export default Section6;

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

const CommentModal = (props) => {

    const {
        comment,
        accepted,
        onClose = () => {},
        onAccept = () => {},
        onDelete = () => {},
        ...others
    } = props;

    return (
        <Dialog
            onClose={onClose}
            {...others}
        >
            <DialogTitle>Comentário</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        width: "100%",
                        minWidth: "30vw"
                    }}
                >
                    <Typography
                        sx={{
                            wordBreak: "break-all"
                        }}
                    >
                        {comment}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                {!accepted && (
                    <Button
                        variant={"contained"}
                        color={"success"}
                        onClick={() => {
                            onAccept();
                            onClose();
                        }}
                    >
                        Aceitar
                    </Button>
                )}
                <Button
                    variant={"contained"}
                    color={"error"}
                    onClick={() => {
                        onDelete();
                        onClose();
                    }}
                >
                    Deletar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CommentModal;
import { useState } from "react";
import GetEmail from "./Sections/GetEmail";
import AuthCode from "./Sections/AuthCode";
import NewPassword from "./Sections/NewPassword";
import CustomizedSteppers from "./Stepper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import swal from "sweetalert";
import { Post } from "utils/request";
import { URL } from "variables";
import { emailCheck, lengthCheck } from "utils/checkers";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { useHistory } from "react-router";
import { Header } from "modules/Header";
import { useMainPages } from "modules/Header/data";

const ForgotPasswordPage = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const pages = useMainPages();

  const [section_index, setSectionIndex] = useState(0);

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");

  const handleChangePassword = async () => {
    if (!lengthCheck(password, 8))
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: "Senha deve ter no mínimo 8 caracteres.",
        })
      );

    if (password !== password_confirmation)
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: "Senhas não coincidem.",
        })
      );
    
    const password_form_data = new FormData();
    password_form_data.append("email", email);
    password_form_data.append("password", password);
    password_form_data.append("password_confirmation", password_confirmation);
    password_form_data.append("auth_code", code);

    const res = await Post(
      "forgot-password/update-password",
      password_form_data
    );

    if (res.status == true) {
      dispatch(
        Show({
          show: true,
          severity: "success",
          message: "Senha alterada com sucesso.",
        })
      );
      history.push("/login");
    } else {
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: res.error[0] || "Erro ao alterar a senha",
        })
      );
      return;
    }
  };

  const handleSectionChange = async (index) => {
    if (index < 0 || index > 2) return;

    console.log(index);

    if (index === 1 && section_index === 0) {
      if (!emailCheck(email))
        return dispatch(
          Show({  
            show: true,
            severity: "error",
            message: "E-mail inválido.",
          })
        );
      
      const email_form_data = new FormData();
      email_form_data.append("email", email);
      const res = await Post(
        "forgot-password/check-email",
        email_form_data
      );
      
      if (res.status == true) {
        const res = await Post(
          "forgot-password/create_code",
          email_form_data
        );
        if (res.status == true) {
          dispatch(
            Show({
              show: true,
              severity: "success",
              message: "Código de verificação enviado.",
            })
          );
        } else {
          dispatch(
            Show({
              show: true,
              severity: "error",
              message: res.error[0] || "Erro ao enviar o código",
            })
          );
          return;
        }
      } else {
        dispatch(
          Show({
            show: true,
            severity: "error",
            message: "E-mail não encontrado.",
          })
        );
        return;
      }
    }

    const check_code_form_data = new FormData();
    check_code_form_data.append("email", email);
    check_code_form_data.append("auth_code", code);
    if (index === 2 && section_index === 1) {
      const res = await Post(
        "forgot-password/check-code",
        check_code_form_data
      );

      if (res.status == true) {
        dispatch(
          Show({
            show: true,
            severity: "success",
            message: "Verificação concluída.",
          })
        );
      } else {
        dispatch(
          Show({
            show: true,
            severity: "error",
            message: res.error[0] || "Erro ao verificar o código",
          })
        );
        return;
      }
    }
    setSectionIndex(index);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "rgb(240, 244, 247)",
        paddingTop: "6rem",
      }}
    >
      <Header pages={pages} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          paddingBottom: "4rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            backgroundColor: "white",
            padding: "4rem 2rem",
            borderRadius: "0.4rem",
            maxWidth: "50rem",
            width: "100%",
          }}
        >
          <CustomizedSteppers {...{ section_index }} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              // onSubmit={handleSubmit}
              style={{
                position: "relative",
                width: "100%",
                marginTop: "4rem",
              }}
            >
              <GetEmail
                {...{
                  section_index,

                  email,
                  setEmail,
                }}
              />

              <AuthCode
                {...{
                  section_index,

                  code,
                  setCode,
                }}
              />

              <NewPassword
                {...{
                  section_index,

                  password,
                  setPassword,
                  password_confirmation,
                  setPasswordConfirmation,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent:
                  section_index === 0 ? "flex-end" : "space-between",
                marginTop: "1rem",
                maxWidth: "30rem",
                width: "100%",
                margin: "2rem auto 0 auto",
              }}
            >
              <button
                onClick={() => handleSectionChange(section_index - 1)}
                style={{
                  padding: "0.8rem 1.4rem",
                  paddingLeft: "1rem",
                  backgroundColor: "#90b7d6",
                  border: "none",
                  outline: "none",
                  color: "black",
                  fontFamily: "var(--Raleway)",
                  width: "max-content",
                  alignSelf: "flex-end",
                  borderRadius: "0.4rem",
                  fontSize: "1rem",
                  display: section_index === 0 ? "none" : "flex",
                }}
              >
                <ArrowBackIcon style={{ marginRight: "0.5rem" }} />

                <span style={{ zIndex: 2, position: "relative" }}>Voltar</span>
              </button>

              <button
                onClick={() =>
                  section_index === 2
                    ? handleChangePassword()
                    : handleSectionChange(section_index + 1)
                }
                style={{
                  padding: "0.8rem 1.4rem",
                  paddingRight: "1rem",
                  backgroundColor: "#90b7d6",
                  border: "none",
                  outline: "none",
                  color: "black",
                  fontFamily: "var(--Raleway)",
                  width: "max-content",
                  alignSelf: "flex-end",
                  borderRadius: "0.4rem",
                  fontSize: "1rem",
                }}
              >
                <span style={{ zIndex: 2, position: "relative" }}>
                  {section_index === 0
                    ? "Próximo"
                    : section_index === 1
                    ? "Próximo"
                    : "Finalizar"}
                </span>

                <ArrowForwardIcon style={{ marginLeft: "0.5rem" }} />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Link to={"/login"}>
          <button
            className={`Transition-1 ${styles.customButton}`}
            style={{
              margin: "0 auto",
              marginTop: "1rem",
            }}
            on
          >
            <div className={`${styles.cardFlipEffect} Transition-1`} />

            <FaUserPlus
              style={{
                marginRight: "0.6rem",
                display: "flex",
                zIndex: 2,
              }}
              className="Transition-1"
            />

            <span style={{ zIndex: 2, position: "relative" }}>Faça login</span>
          </button>
        </Link> */}
    </div>
  );
};

export default ForgotPasswordPage;

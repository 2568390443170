import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Show } from "actions/SnackbarActions";
import { Post } from "utils/request";
import FormBody from "./FormBody/";
import SweetAlert from "react-bootstrap-sweetalert";

const CouponEdit = () => {

    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    return (
        <div className="card">
            <SweetAlert
                warning
                show={popup}
                title="Atenção!"
                confirmBtnText="Ok"
                onConfirm={() => SetPopup(false)}
            >
                {popupMessage}
            </SweetAlert>
            <div className="card-body">
                <h2 className="title">Editar cupom</h2>
                <p>Aqui são editadas os cupons dos cursos do sistema</p>
                <br/>
                <FormBody/>
            </div>
        </div>
    );
}

export default CouponEdit;
import { Box } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Get, Post } from "utils/request";
import { Show } from "actions/SnackbarActions";
import DefaultButton from "components/DefaultButton";
import JoditEditor from "jodit-react";
import JoditConfig from "utils/joditConfig";


const config = new JoditConfig("jodit-upload-image").config();

const Formbody = () => {
    const dispatch = useDispatch();

    const [text, SetText] = useState("");

    const { isLoading } = useQuery("policy-term", async () => await Get("policy-terms"), {
        onSuccess: (data) => {
            const { policy: { text = "" } = {} } = data || {};
            SetText(text ?? "");

        },
        refetchOnWindowFocus: false
    });

    const { mutate, isLoading: isMutating } = useMutation(async () => {
        const form = new FormData();
        form.append("text", text);

        return await Post("policy-terms/update", form);
    }, {
        onSuccess: (data) => {
            const { message = "", severity = "error" } = data || {};
            dispatch(Show({
                show: true,
                message,
                severity: "success"
            }));
        },
        onError: (error) => {
            const { message = "", severity = "error" } = error || {};
            dispatch(Show({
                show: true,
                message,
                severity: "error"
            }));
        }
    });

    return (
        <Box
            component="form"
            onSubmit={e => {
                e.preventDefault();
                mutate()
            }}
        >
            <JoditEditor
                config={config}
                value={text}
                onChange={SetText}
            />
            <Box
                width="100%"
                display="flex"
                alignItems={"center"}
                justifyContent={"flex-end"}
                marginTop={2}
            >
                <DefaultButton
                    bg="confirm"
                    text="Salvar"
                    type="submit"
                />
            </Box>
        </Box>
    );
}

export default Formbody;
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CountUp from "components/CountUp";
import StatisticLabel from "components/StatisticLabel";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

import styles from "./index.module.css";
import styles2 from "./index.module.css";
import StatisticCard from "./StatisticCard";
import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { Get } from "utils/request";

const StatisticsText = (props) => {
  const { ...others } = props;

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [section3_cards, setSection3Cards] = useState([]);
  
  const { data } = useQuery(
    "about-us-page",
    async () => await Get("site-rt/about-us-page")
  );
  const { aboutUsPage, studentUsers } = data || {};

  useEffect(() => {
    setSection3Cards(aboutUsPage?.statsCards);
    setTitle(aboutUsPage?.statisticsTitle);
    setText(aboutUsPage?.statisticsText);
    props.setMission_bg_image(aboutUsPage?.mission_bg_image)
  }, [aboutUsPage]);

  return (
    <Box
      className={styles.container}
      flexDirection={useMediaQuery("(min-width: 600px)") ? "row" : "column"}
      sx={{ backgroundColor: "rgb(240, 244, 244)", position: "relative" }}
      {...others}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className={styles.textContainer}>
          <Typography
            variant="h4"
            style={{
              fontWeight: 700,
              fontWeight: "bold",
            }}
            align="center"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Typography
            align="center"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "2rem",
          }}
        >
          {section3_cards?.map((card, index) => (
            <StatisticCard key={index} {...{ card }} />
          ))}
        </div>
      </div>
    </Box>
  );
};

export default StatisticsText;

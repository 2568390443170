import { Typography } from "@mui/material";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import TestimonyCard from "./TestimonyCard";
import { STORAGE_URL } from "variables";

const CourseTestimonials = ({ testimonials, SetTestimonials }) => {
  const responsive = {
    200: {
      items: 1,
    },
    400: {
      items: 2,
    },
    1280: {
      items: 3,
    },
  };

  console.log("testimonialstestimonials", testimonials);

  return (
    <div style={{ height: "max-content", position: "relative" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1.4rem",
          margin: "auto",
          padding: "8rem 0",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
            alignSelf: "center",
            marginBottom: "2rem",
          }}
        >
          Depoimentos dos nossos alunos
        </Typography>

        <OwlCarousel
          className="owl-theme"
          // loop
          items={5}
          center
          autoplay={true}
          autoplayTimeout={5000}
          autoplayHoverPause
          responsive={responsive}
          margin={20}
          dots={false}
        >
          {testimonials.map((testimony, index) => (
            <img
              className="item"
              key={index}
              src={STORAGE_URL + testimony.image}
              alt="Depoimento"
              style={{
                width: "100%",
                maxWidth: "50rem",
                height: "max-content",
                objectFit: "contain",
                borderRadius: "0.8rem",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
              }}
            />
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default CourseTestimonials;

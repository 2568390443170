export const lengthCheck = (string, min = 6, max = 20) => {
  return string.length >= min && string.length <= max;
};

export const compareCheck = (string1, string2) => {
  return string1 === string2;
};

export const emailCheck = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};
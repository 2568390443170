import { Box, TextField, Button, Avatar } from "@mui/material";
import { Show } from "actions/SnackbarActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useQueryClient } from "react-query";
import { Post } from "utils/request";
import { STORAGE_URL } from "variables";

const Form = () => {
  const [comment, SetComment] = useState("");
  const [actionsShow, SetActionsShow] = useState(false);

  const { file_id } = useParams();
  const user = useSelector((store) => store.AppReducer.user);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const Submit = async () => {
    const form = new FormData();
    form.append("file_id", file_id);
    form.append("comment", comment);

    let response = await Post("lessons/files/comments/create", form);

    if (response?.status === true) {
      queryClient?.refetchQueries(["comments", file_id]);
      SetComment("");
      SetActionsShow(false);
    }

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );
  };

  return (
    <Box width="100%">
      <Box display="inline-flex" alignItems={"center"} width="100%">
        <Avatar
          src={user?.profile_image ? STORAGE_URL + user?.profile_image : ""}
          style={{
            marginRight: 10,
          }}
        />
        <textarea
          onClick={() => SetActionsShow(true)}
          placeholder="Adicionar comentário"
          value={comment}
          onChange={(e) => SetComment(e.target.value)}
          style={{
            width: "100%",
            border: "none",
            outline: "none",
            fontSize: 16,
            padding: 10,
            borderRadius: 10,
            backgroundColor: "white",
            border: "1px solid #e0e0e0",
          }}
        />
      </Box>
      <Box
        marginTop=".5em"
        width="100%"
        display="flex"
        justifyContent="flex-end"
        hidden={!actionsShow}
      >
        {/* <Button
          onClick={() => {
            SetActionsShow(false);
          }}
        >
          Cancelar
        </Button> */}
        <Button
          variant="contained"
          disabled={!comment}
          onClick={Submit}
          disableRipple
          disableElevation
        >
          Comentar
        </Button>
      </Box>
    </Box>
  );
};

export default Form;

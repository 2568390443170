import { FormLabel, FormControl as Input } from "react-bootstrap";
import DefaultButton from "components/DefaultButton";
import Label from "components/InfoLabel";
import InputCard from "../../InputCard";
import { useState } from "react";
import { useQuery } from "react-query";
import { Get } from "utils/request";
import { STORAGE_URL } from "variables";
import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Card from "./Card";
import { removeHTML } from "utils/filters";
import { moneyMask } from "utils/moneyMask";

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
};

const Section7 = ({ opcells, SetOpcells, id, JoditConfig }) => {
  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [courses, setCourses] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    "courses",
    async () => await Get(`courses-main`),
    {
      onSuccess: (data) => {
        const { courses } = data || {};
        setCourses(courses);
      },
    }
  );

  // const addOpcell = (course) => {
  //   // add id opcell in opcells
  //   SetOpcells([
  //     ...opcells,
  //     { id: course.id, name: course.name, image: course.image },
  //   ]);

  //   // remove the added course from courses
  //   // setCourses(courses.filter((c) => c.id !== course.id));
  // };

  // const removeOpcell = (id) => {
  //   // add the removed course to courses
  //   // const removedCourse = opcells.find((c) => c.id === id);
  //   // setCourses([...courses, removedCourse]);

  //   // remove id opcell in opcells
  //   SetOpcells(opcells.filter((opcell) => opcell.id !== id));
  // };

  const addOpcell = (course) => {
    // add id opcell in opcells
    SetOpcells([
      ...opcells,
      {
        id: course.id,
        ...course,
        installments_price: moneyMask(course.installments_price),
        price: moneyMask(course.price),
      },
    ]);

    // remove the added course from courses
    // setCourses(courses.filter((c) => c.id !== course.id));
  };

  const removeOpcell = (id) => {
    // add the removed course to courses
    // const removedCourse = opcells.find((c) => c.id === id);
    // setCourses([...courses, removedCourse]);

    // remove id opcell in opcells
    SetOpcells(opcells.filter((opcell) => opcell.id !== id));
  };

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        gap: "1rem",
        marginTop: "2rem",
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: "4rem 6rem",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              borderRadius: "0.8rem",
              maxHeight: "calc(100vh - 6rem)",
              outline: "none",
            }}
          >
            <div
              style={{ position: "absolute", right: "0.6rem", top: "0.6rem" }}
            >
              <CloseRoundedIcon
                sx={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>

            <div>
              <Typography variant="h6" gutterBottom>
                Escolha o curso que você deseja indicar
              </Typography>

              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "40rem",
                  height: "100%",
                  padding: "2rem",
                  display: "grid",
                  gap: "1rem",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
              >
                {courses?.map((course, index) => {
                  if (course?.id === id) return;

                  if (opcells.find((opcell) => opcell.id === course.id))
                    return (
                      <div
                        key={index}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "",
                          margin: "0 auto",
                          padding: "0.6rem",
                          backgroundColor: "#dbdbdb",
                          borderRadius: "0.6rem",
                          cursor: "not-allowed",
                          opacity: "0.6",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          maxWidth: "30rem",
                        }}
                      >
                        <span style={{ color: "red" }}>
                          Este curso já foi selecionado
                        </span>
                        <div
                          style={{
                            // aspectRatio: "16/9",
                            // width: "20rem",
                            height: '12rem',
                            width: "100%",
                            overflow: "hidden",
                            backgroundImage: `url(${STORAGE_URL}${course.image})`,
                            backgroundSize: "cover",
                          }}
                        ></div>
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                          {removeHTML(course?.name)}
                        </Typography>
                      </div>
                    );

                  return (
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "",
                        margin: "0 auto",
                        padding: "0.6rem",
                        backgroundColor: "#dbdbdb",
                        borderRadius: "0.6rem",
                        width: "100%",
                        maxWidth: "30rem",
                      }}
                      onClick={() => {
                        handleClose();
                        addOpcell(course);
                      }}
                    >
                      <div
                        style={{
                          // aspectRatio: "16/9",
                          // width: "20rem",
                          height: '8rem',
                          width: '100%',
                          overflow: "hidden",
                          backgroundImage: `url(${STORAGE_URL}${course.image})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <Typography variant="h6" sx={{ textAlign: "center" }}>
                        {removeHTML(course?.name)}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Label
        label={
          <FormLabel
            style={{ display: "flex", gap: "1rem", alignItems: "center" }}
          >
            Adicionar indicações de cursos para o aluno
          </FormLabel>
        }
        title="Adicionar indicações de cursos para o aluno escolher comprar quando for realizar o pagamento do curso"
      />
      <div
        style={{
          display: "flex",
          gap: "0.6rem",
          flexWrap: "wrap",
          marginTop: "1rem",
        }}
      >
        {opcells?.map((course, index) => {
          return (
            <Card
              key={index}
              index={course?.id}
              remove={removeOpcell}
              handle={() => {}}
              text={removeHTML(course?.name)}
              image={course?.image}
              {...{ course, opcells, SetOpcells, JoditConfig }}
            />
          );
        })}
        {opcells?.length === 0 && (
          <Typography
            variant="subtitle"
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              padding: "1rem",
              borderRadius: "0.5rem",
            }}
          >
            Nenhum item adicionado
          </Typography>
        )}
      </div>
      <DefaultButton
        bg="confirm"
        text="Adicionar opção"
        onClick={handleOpen}
        sx={{ marginTop: "1rem" }}
      />
    </div>
  );
};

export default Section7;

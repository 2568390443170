import { createTheme, responsiveFontSizes, useTheme } from "@mui/material";
import {
  generalStyleAction,
  pagesStyleAction,
  siteSettingsAction,
} from "actions/StylesActions";
import { useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Get } from "utils/request";

const useDBSettings = () => {
  const [title, setTitle] = useState(null);
  const [tabIcon, setTabIcon] = useState();

  const palette = useSelector((store) => store.StylesReducer.palette);
  const dispatch = useDispatch();

  const results = useQueries([
    {
      queryKey: "generalStyle",
      queryFn: async () => await Get("styles/general"),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: "pagesStyle",
      queryFn: async () => await Get("styles/pages"),
      refetchOnWindowFocus: false,
    },
  ]);

  const queriesIsLoading = results.some((x) => x?.isLoading);

  const theme = useMemo(() => {
    try {
      const tempPalette = JSON.parse(localStorage.getItem("tempPalette"));
      if (queriesIsLoading && tempPalette)
        return responsiveFontSizes(createTheme(tempPalette));
    } catch (e) {
      console.error("tempPalette invalid JSON");
    }
    return responsiveFontSizes(createTheme(palette));
  }, [palette]);

  // let dispatched = false;

  useEffect(() => {
    if (queriesIsLoading) return;
    const [generalStyle, pagesStyle] = results;

    console.log('pagesStyle', pagesStyle?.data)

    if (generalStyle?.data) {
      dispatch(generalStyleAction(generalStyle?.data));
      dispatch(siteSettingsAction(generalStyle?.data));
      const { title, icon } = generalStyle.data;
      if (title) setTitle(title);
      if (icon) setTabIcon(icon);
    }
    if (pagesStyle?.data) dispatch(pagesStyleAction(pagesStyle?.data));
  }, [queriesIsLoading]);

  return {
    theme,
    title,
    tabIcon,
    isLoading: queriesIsLoading,
  };
};

export default useDBSettings;

import PropTypes from "prop-types";
import { Collapse, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import styles from "./index.module.css";

const ShowMoreText = props => {

    const {
        maxChar = 10,
        children,
        ...other
    } = props;

    const [expanded, SetExpanded] = useState(false);

    useEffect(() => {
        if (typeof children !== "string") console.warn("ShowMoreText children must be only a string");
    }, [children]);

    return (
        <div className={styles.showMoreText}>
            <div className={`flex fdrow ${styles.showMore}`}>
                <span 
                    hidden={expanded}
                    style={{
                        marginRight: "5px"
                    }}
                >
                    {children.substring(0, maxChar)}
                </span>
                <Link
                    onClick={() => SetExpanded(true)}
                    hidden={expanded || children.length <= maxChar}
                >
                    Mostrar mais
                </Link>
            </div>
            <Collapse
                in={expanded}
                addEndListener={() => console.log("end")}
                className={styles.showLess}
            >
                <span
                    style={{
                        marginRight: "5px"
                    }}
                >
                    {children}
                </span>
                <Link
                    onClick={() => SetExpanded(false)}
                    hidden={!expanded}
                >
                    Mostrar menos
                </Link>
            </Collapse>
        </div>
    );
}

ShowMoreText.propTypes = {
    children: PropTypes.string.isRequired
}

export default ShowMoreText;
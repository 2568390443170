import React, { useState } from "react";

import { MdModeEdit, MdDelete } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from "components/DefaultButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Post } from "utils/request";
import { Show } from "actions/SnackbarActions";
import { useDispatch } from "react-redux";
import RoleBased from "components/Role/RoleBased";
import { useParams } from "react-router";
import moment from "moment";

const Board = props => {

    const {
        groupId,
        name,
        description,
        sub_end,
        sub_start,
        OnDelete
    } = props;

    const [popup, SetPopup] = useState(false);

    const [loading, SetLoading] = useState(false);

    const { id } = useParams();
    const dispatch = useDispatch();

    const Delete = async () => {
        let form = new FormData();
        form.append("group_id", groupId);
        form.append("course_id", id);

        SetLoading(true);
        let response = await Post("courses-main/groups/delete", form);
        SetLoading(false);

        if (response?.status) {
            dispatch(Show({
                show: true,
                message: response?.message,
                severity: response?.severity
            }));
            OnDelete();
        }
    }

    return (
        <tr>
            <td>{name}</td>
            <td className="table-text-dots">{moment(sub_start).format('DD/MM/YYYY')}</td>
            <td className="table-text-dots">{moment(sub_end).format('DD/MM/YYYY')}</td>


            <td>
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        Delete();
                        SetPopup(false);
                    }}
                    onCancel={() => SetPopup(false)}
                    show={popup}
                    confirmBtnText='Remover'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Tem certeza que deseja remover essa turma?
                </SweetAlert>
                <DefaultButton
                    to={`/groups/${groupId}/view`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Ver turma`}
                    bg="warning"
                    icon={<BsFillEyeFill size={17} color="white"/>}
                    style={{
                        margin: "0 2px"
                    }}
                />
                {/* <RoleBased> */}
                    <DefaultButton
                        to={`/courses/view/${id}/groups/edit/${groupId}`}
                        width="2.2em"
                        height="2.2em"
                        padding={0}
                        title={`Editar curso`}
                        bg="info"
                        icon={<MdModeEdit color="white" size={17}/>}
                        style={{
                            margin: "0 2px"
                        }}
                    />
                {/* </RoleBased> */}
                {/* <RoleBased> */}
                    <DefaultButton
                        onClick={() => SetPopup(true)}
                        width="2.2em"
                        height="2.2em"
                        padding={0}
                        title={`Remover turma`}
                        bg="danger"
                        loading={loading}
                        icon={!loading && <MdDelete size={17} color="white"/>}
                    />
                {/* </RoleBased> */}
            </td>
        </tr>
    );
}

export default Board;
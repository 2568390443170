export const PercentageMask = value => {
    return (Clamp(parseFloat(value.replace(/[^0-9]/g, "") * 0.01), 0, 100 * 0.01)).toLocaleString("pt-BR", { style: "percent" });
}

export const HandlePercentInput = event => {
    event.preventDefault();
    let realNumber = event.target.value + event.key;
    realNumber = realNumber.replace(/[^0-9]/g, "");

    if (event.keyCode === 8) realNumber = realNumber.slice(0, -1);
    return PercentageMask(realNumber);
}

export const Clamp = (number, min, max) => {
    return Math.min(Math.max(number, min), max);
}

export const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
}

// this function is used to remove html tags from a string. (used with JODIT editor)
export const removeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
}
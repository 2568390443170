import { useState } from "react";
import style from "./style.module.css";
import FormPaginaInicial from "./Form";
import PreviewAboutUsPage from "../Preview/AboutUsPage";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { useQuery } from "react-query";
import { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import { Get, Post } from "utils/request";
import PreviewIntegrativeHealth from "../Preview/IntegrativeHealth";
import { CircularProgress, Typography } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import logo_loading from "assets/images/rt/icon_transparent.png";

const ConfigSaudeIntegrativa = () => {
  const [hidePreview, setHidePreview] = useState(false);
  const dispatch = useDispatch();
  const [popup, SetPopup] = useState(false);
  const [popupMessage, SetPopupMessage] = useState("");

  const [is_loading, set_is_loading] = useState(true);

  const [save_button_status, setSaveButtonStatus] = useState({
    status: "Salvar tudo",
    icon: <SaveIcon />,
  });

  const [title, setTitle] = useState('');

  const [subtitle, setSubtitle] = useState("");

  const [text, setText] = useState("<p>Insira todo o texto do Word aqui. Não insira nos cartões.</p>");

  const [background_image, setBackground_image] = useState({
    image_url: "",
    file: null,
  });

  // START -  cards
  const [cards, setCards] = useState([]);

  const addCard = (data) => {
    if (data.title === "" || data.text === "") {
      SetPopup(true);
      SetPopupMessage("Preencha todos os campos do cartão.");
      return;
    }

    setCards([
      ...cards,
      {
        title: data.title,
        text: data.text,
        image: data.image,
      },
    ]);
  };

  const removeCard = (index) => {
    let temp = [...cards];
    temp.splice(index, 1);
    setCards(temp);
  };

  const handleCardChange = (index, data) => {
    let temp = cards;
    temp[index].title = data.title;
    temp[index].text = data.text;
    temp[index].image = data.image;
    setCards([...temp]);
  };
  // END -  cards

  const Submit = async () => {
    let form = new FormData();

    form.append("title", title);
    form.append("subtitle", subtitle);
    form.append("text", text);
    form.append("background_image", background_image.file);

    for (let i = 0; i < cards.length; i++)
      form.append("cards[]", JSON.stringify(cards[i]));

    if (cards.length === 0) {
      SetPopup(true);
      SetPopupMessage("Insira pelo menos um cartão!");
      return;
    }

    setSaveButtonStatus({
      status: "Salvando...",
      icon: <HourglassBottomIcon />,
    });
    let response = await Post("site-rt/saude-integrativa-page/update", form);

    if (response?.status === true) {
      setSaveButtonStatus({
        status: "Salvo!",
        icon: <CheckIcon />,
      });
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
        })
      );
      setTimeout(() => {
        setSaveButtonStatus({
          status: "Salvar tudo",
          icon: <SaveIcon />,
        });
      }, 2000);
    } else {
      setSaveButtonStatus({
        status: "Erro!",
        icon: <CheckIcon />,
      });
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: "error",
        })
      );
      setTimeout(() => {
        setSaveButtonStatus({
          status: "Salvar tudo",
          icon: <SaveIcon />,
        });
      }, 2000);
    }
  };

  const { data } = useQuery(
    "saudeIntegrativaPage",
    async () => await Get(`site-rt/saude-integrativa-page`),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setTitle(data?.saudeIntegrativaPage?.title || title);
    setSubtitle(data?.saudeIntegrativaPage?.subtitle || subtitle);
    setText(data?.saudeIntegrativaPage?.text || text);
    setBackground_image({
      image_url: data?.saudeIntegrativaPage?.background_image,
      file: null,
    });
    setCards(data?.saudeIntegrativaPage?.cards);
    set_is_loading(false);
  }, [data]);

  return is_loading ? (
    <div style={{ width: "100%", aspectRatio: "16/9", display: "flex" }}>
      <img
        src={logo_loading}
        alt="Logotipo RT"
        className={"infiniteRotateAnim"}
        style={{
          width: "12rem",
          height: "12rem",
          margin: "auto",
        }}
      />
    </div>
  ) : (
    <div className={style.gridContainer}>
      <SweetAlert
        warning
        show={popup}
        title="Atenção!"
        confirmBtnText="Ok"
        onConfirm={() => SetPopup(false)}
      >
        {popupMessage}
      </SweetAlert>
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "14.2rem",
          zIndex: 50,
          display: "flex",
          gap: "1rem",
        }}
      >
        <div
          className={style.hidePreviewButton}
          style={{
            background: "#a6eda1",
          }}
          onClick={() => Submit()}
        >
          {save_button_status.icon} {save_button_status.status}
        </div>
        <div
          className={style.hidePreviewButton}
          style={{
            background: hidePreview ? "#accef2" : "#ffc3b5",
          }}
          onClick={() => setHidePreview(!hidePreview)}
        >
          {hidePreview ? <VisibilityIcon /> : <VisibilityOffIcon />}{" "}
          <span>{hidePreview ? "Mostrar" : "Esconder"} preview</span>
        </div>
      </div>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <div
          style={{
            maxWidth: hidePreview ? "100%" : `44%`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <h2 className="title">
              Configurações da página "Saúde Integrativa"
            </h2>
          </div>
          <FormPaginaInicial
            {...{
              title,
              setTitle,

              subtitle,
              setSubtitle,

              text,
              setText,

              background_image,
              setBackground_image,

              cards,
              addCard,
              removeCard,
              handleCardChange,

              SetPopup,
              SetPopupMessage,
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: hidePreview ? "0" : `43%`,
          display: "flex",
          height: "100vh",
          transition: "width 300ms",
          position: "fixed",
          right: "0",
          background: "#fff",
          borderLeft: "0.2rem dashed black",
        }}
      >
        <PreviewIntegrativeHealth
          {...{
            title,
            setTitle,

            subtitle,
            setSubtitle,

            text,
            setText,

            background_image,
            setBackground_image,

            cards,
            setCards,
            addCard,
            removeCard,
            handleCardChange,
          }}
        />
      </div>
    </div>
  );
};

export default ConfigSaudeIntegrativa;

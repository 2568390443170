import { Box, Typography, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { Get } from "utils/request";
import Board from "./Board";
import { STORAGE_URL } from "variables";

import styles from "./index.module.css";

const Team = (props) => {
  const { ...others } = props;

  const [teamMembers, SetTeamMembers] = useState([]);
  const [loaded, SetLoaded] = useState(false);


  // const GetData = async () => {
  //   let response = await Get("site-rt/team-members?paginate=0");
  //   if (response?.status === true) {
  //     SetTeamMembers(response?.teamMembers);
  //   }
  //   SetLoaded(true);
  // };

  const GetData = async () => {
    let response = await Get("users");
    if (response?.status === true) {
      for (const key in response.users) {
        if (response.users[key].isTeamMember) {
          SetTeamMembers(teamMembers => [...teamMembers, response.users[key]]);
        }
      }
    }
    SetLoaded(true);
  };

  useEffect(GetData, []);

  if (!loaded) return null;
  return (
    <Container maxWidth="xl" sx={{ textAlign: "center", marginBottom: "2rem" }}>
      <Box
        className={styles.container}
        // padding={matches ? "50px 20px" : 0}
        {...others}
      >
        <Typography variant="h4" sx={{ marginTop: "4rem", color: 'black' }}
          dangerouslySetInnerHTML={{ __html: props.team_title }}
        />
        <div className={styles.cardsGroup}>
          {teamMembers?.map((member, memberKey) => (
            <div
              key={memberKey}
              style={{
                padding: "2rem 4rem",
                background: "#f0f4f4",
                borderRadius: "1rem",
                marginTop: "6rem",
                display: "grid",
                justifyContent: "center",
                gridTemplateRows: "max-content auto",
                gap: "2rem",
              }}
            >
              <div style={{
                background: 'linear-gradient(90deg, rgb(55, 133, 191) 0%, rgb(122, 93, 180) 100%)',
                padding: "0.4rem",
                width: "max-content",
                display: "flex",
                margin: "0 auto",
                borderRadius: "50%",
              }}>
                <div
                  style={{
                    width: "18rem",
                    height: "18rem",
                    overflow: "hidden",
                    backgroundImage: `url(${member.profile_image ? STORAGE_URL + member.profile_image : ""})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    borderRadius: "50%",
                  }}
                >
                </div>
              </div>
              <div>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "center", marginBottom: "2rem", color: 'black' }}
                >
                  {member.name}
                </Typography>
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: member.description }}
                  sx={{
                    textAlign: "start",
                    maxWidth: "28rem",
                    color: "rgb(50, 50, 50)",
                  }}
                ></Typography>
              </div>
            </div>
          ))}
        </div>
      </Box>
    </Container>
  );
};

export default Team;

import { Box, Typography, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { Get } from "utils/request";
import Board from "./Board";
import { STORAGE_URL } from "variables";

import styles from "./index.module.css";

const Team = (props) => {
  const { ...others } = props;
  const teamMembers = props.teamMembers;
  const { team_title } = props.section5;

  console.log("butters", props.teamMembers);

  //

  const [team, setTeam] = useState([]);

  useEffect(() => {
    // for (const key in teamMembers) {
    //   if (teamMembers[key].isTeamMember) {
    //     setTeam((team) => [...team, teamMembers[key]]);
    //   }
    // }
    // refactor the code below
    const team = teamMembers.filter((member) => member.isTeamMember);
    setTeam(team);
  }, [teamMembers]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        textAlign: "center",
        marginBottom: "2rem",
        position: "relative",
        paddingBottom: "4rem",
      }}
    >
      {/* <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: "black",
          position: "absolute",
          top: "4rem",
          width: "100%",
        }}
      >
        (seção 5)
      </Typography> */}
      <Box
        className={styles.container}
        // padding={matches ? "50px 20px" : 0}
        {...others}
      >
        <Typography
          variant="h4"
          sx={{ marginTop: "4rem", color: "black" }}
          dangerouslySetInnerHTML={{ __html: team_title }}
        />
        <div>
          {team?.map((member, memberKey) => (
            <div
              key={memberKey}
              style={{
                padding: "2rem 4rem",
                background: "#f0f4f4",
                borderRadius: "1rem",
                marginTop: "6rem",
                display: "grid",
                justifyContent: "center",
                gridTemplateRows: "max-content auto",
                gap: "2rem",
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(90deg, rgb(55, 133, 191) 0%, rgb(122, 93, 180) 100%)",
                  padding: "0.4rem",
                  width: "max-content",
                  display: "flex",
                  margin: "0 auto",
                  borderRadius: "50%",
                }}
              >
                <div
                  style={{
                    width: "18rem",
                    height: "18rem",
                    overflow: "hidden",
                    backgroundImage: `url(${
                      member.profile_image
                        ? STORAGE_URL + member.profile_image
                        : ""
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    borderRadius: "50%",
                  }}
                ></div>
              </div>
              <div>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    marginBottom: "2rem",
                    color: "black",
                  }}
                >
                  {member?.name}
                </Typography>
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: member?.description }}
                  sx={{
                    textAlign: "start",
                    maxWidth: "28rem",
                    color: "rgb(50, 50, 50)",
                  }}
                ></Typography>
              </div>
            </div>
          ))}
        </div>
      </Box>
    </Container>
  );
};

export default Team;

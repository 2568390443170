import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import StarsRate from "components/StarsRate";
import Image from "components/Image";
import { useContext } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { STORAGE_URL } from "variables";
import { ReviewsContext } from "..";

import styles from "./index.module.css";
import emptyState from "assets/images/emptystate1.svg";

const List = () => {
  const { course_id } = useParams();
  const queryClient = useQueryClient();
  const { evaluationFilter, SetEvaluationFilter } =
    useContext(ReviewsContext) || {};

  const { status, course } =
    queryClient?.getQueryData([
      "course",
      course_id,
      `evaluation: ${evaluationFilter}`,
    ]) || {};

  return (
    <Box flex={1} padding="2rem 0" display={"flex"} flexDirection={"column"}>
      {queryClient.isFetching([
        "course",
        course_id,
        `evaluation: ${evaluationFilter}`,
      ]) ? (
        <Box
          width="100%"
          minHeight="5em"
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
        >
          <CircularProgress />
        </Box>
      ) : course?.evaluations?.length == 0 ? (
        <></>
      ) : (
        <Box display="flex" flexDirection="column">
          {course?.evaluations?.map((evaluation, index) => (
            <div key={index}>
              <Typography variant="h5" align="center">
                <b>Comentários das avaliações</b>
              </Typography>

              <Box
                id={evaluation?.id}
                key={evaluation?.id}
                display="flex"
                flexDirection="column"
                sx={{
                  backgroundColor: "#f2f2f2",
                  marginTop: "1rem",
                  padding: "1rem",
                  borderRadius: "0.6rem",
                  position: 'relative'
                }}
              >
                <Box
                  display="inline-flex"
                  alignItems="center"
                  marginBottom={1.5}
                >
                  <div style={{
                    position: 'absolute',
                    left: '-1.2rem',
                  }}>
                    <Avatar
                      src={
                        evaluation?.user?.profile_image
                          ? STORAGE_URL + evaluation?.user?.profile_image
                          : null
                      }
                      sx={{width: '5rem', height: '5rem', borderRadius: '50%', boxShadow: '0 0.6rem 1rem rgba(0,0,0,0.18)'}}
                    />
                  </div>
                  <div style={{display: 'flex', gap: '1rem', marginLeft: '3.2rem'}}>
                    <Typography variant="h6" sx={{ marginLeft: "0.6rem" }}>
                      {evaluation?.user?.name}
                    </Typography>
                    <div
                      style={{
                        padding: "0.2rem 0.4rem",
                        borderRadius: "0.6rem",
                        backgroundColor: "white",
                        width: "max-content",
                        border: "0.1rem solid #f5d142",
                        display: "flex",
                        gap: "0.4rem",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2" sx={{height: 'max-content'}}>Avaliou com</Typography>
                      <StarsRate rate={parseInt(evaluation?.points)} />
                    </div>
                  </div>
                </Box>
                <Typography variant="body1" sx={{ marginTop: "1.2rem" }}>
                  {evaluation?.comment}
                </Typography>
              </Box>
            </div>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default List;

import { Tooltip } from "@mui/material";
import React from "react";
import { FormLabel } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";

const Label = (props) => {
  const {
    title = "",
    placement = "right",
    label,
    iconColor = "#38a4f8",
    required = false,
    ...others
  } = props;

  return (
    <div className="inline-flex">
      <FormLabel style={{ marginRight: "5px" }}>
        {label}{" "}
        <span style={{ color: "red" }} hidden={!required}>
          *
        </span>
      </FormLabel>
      <Tooltip
        title={<div style={{ whiteSpace: "pre-line" }}>{title}</div>}
        arrow
        placement={placement}
        hidden={!title}
      >
        <div style={{ height: "fit-content" }}>
          <BsInfoCircle color={iconColor} size={18} />
        </div>
      </Tooltip>
    </div>
  );
};

export default Label;

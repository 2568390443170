import { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import JoditEditor from "jodit-react";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { Post } from "utils/request";
import ImageInput from "components/Inputs/ImageInput";

const JoditConfig = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarInlineForSelection: true,
  showPlaceholder: false,
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "font",
    "fontsize",
    "brush",
  ],
  height: 100,
  minHeight: 100,
  defaultLanguage: "pt_br",
  language: "pt_br",
  readonly: false,
  toolbarAdaptive: false,
};

const Card = (props) => {
  const {
    index,
    card,
    removeCard,
    handleCardChange,
    modal_style,
    SetPopup,
    SetPopupMessage,
  } = props;

  // card info
  const [card_title, setCard_title] = useState(card?.title || "");
  const [card_text, setCard_text] = useState(card?.text || "");
  const [card_image, setCard_image] = useState(card?.image || null);

  console.log(card_image)

  const dispatch = useDispatch();

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditCard = () => {
    if (card_title === "" || card_text === "") {
      SetPopup(true);
      SetPopupMessage("Preencha todos os campos do cartão.");
      return;
    }

    handleCardChange(index, {
      title: card_title,
      text: card_text,
      image: card_image,
    });

    handleClose();
  };

  const handleDeleteCard = () => {
    removeCard(index);
  };

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "rgba(230,230,230)",
        borderRadius: "0.8rem",
        padding: "0.8rem",
        width: "100%",
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modal_style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              padding: "3rem 4rem",
            }}
          >
            <CloseIcon
              sx={{
                position: "absolute",
                top: "0.8rem",
                right: "0.8rem",
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <Typography variant="h5" style={{ marginBottom: "1rem" }}>
              Editar cartão
            </Typography>

            <FormLabel>
              Título <span style={{ color: "red" }}>*</span>
              <JoditEditor
                config={JoditConfig}
                value={card_title}
                onChange={(value) => setCard_title(value)}
              />
            </FormLabel>
            <FormLabel sx={{ width: "24rem" }}>
              Texto <span style={{ color: "red" }}>*</span>
              <JoditEditor
                config={JoditConfig}
                value={card_text}
                onChange={(value) => setCard_text(value)}
              />
            </FormLabel>
            <FormLabel sx={{ width: "24rem" }}>
              Imagem do cartão
              <ImageInput
                path={card_image}
                storageUrl={false}
                onImageChangeBase64={(image) => {
                  setCard_image(image);
                }}
              />
            </FormLabel>
            <Button
              variant="contained"
              onClick={handleEditCard}
              sx={{
                width: "max-content",
                alignSelf: "flex-end",
                marginTop: "1rem",
              }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: "0.2rem" }}
            dangerouslySetInnerHTML={{ __html: card_title }}
          />
          <Typography
            variant="body1"
            style={{ color: "black" }}
            dangerouslySetInnerHTML={{ __html: card_text }}
          />
        </div>
        {card_image && (
          <img
            src={card_image}
            alt="Cartao"
            style={{
              width: "6rem",
              height: "6rem",
              objectFit: "cover",
              borderRadius: "0.4rem",
              marginLeft: "1rem",
            }}
          />
        )}
      </div>
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <SettingsIcon
          sx={{
            position: "absolute",
            left: "-0.4rem",
            top: "-0.4rem",
            padding: "0.2rem",
            fontSize: "1.6rem",
            backgroundColor: "white",
            borderRadius: "50%",
            cursor: "pointer",
            backgroundColor: "rgb(170, 170, 255)",
          }}
          onClick={handleOpen}
        />
        <CloseIcon
          sx={{
            position: "absolute",
            right: "-0.4rem",
            top: "-0.4rem",
            padding: "0.2rem",
            fontSize: "1.6rem",
            backgroundColor: "white",
            borderRadius: "50%",
            cursor: "pointer",
            backgroundColor: "rgb(255, 170, 170)",
          }}
          onClick={() => {
            if (window.confirm("Tem certeza que deseja remover esse cartão?")) {
              handleDeleteCard();
            }
          }}
        />
      </div>
    </div>
  );
};

export default Card;

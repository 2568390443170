import { useState } from "react";
import { Box, Typography, Stack, Container } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Get } from "../../../utils/request";
import Board from "../CoursesUserView/Card";

import styles from "./index.module.css";
import SkeletonCardList from "modules/LoadingPlaceholders/Skeletons/SkeletonCardList";
import { useQuery } from "react-query";
import Image from "components/Image";
import emptyState from "assets/images/emptystate2.svg";
import Footer from "Home/Footer";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const MyList = () => {
  const [sliderRef] = useKeenSlider({
    breakpoints: {
      "(min-width: 0px)": {
        slides: { perView: 1 },
      },
      "(min-width: 880px)": {
        slides: { perView: 2 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 2 },
      },
      "(min-width: 1320px)": {
        slides: { perView: 3 },
      },
      "(min-width: 1700px)": {
        slides: { perView: 4 },
      },
      "(min-width: 2200px)": {
        slides: { perView: 5 },
      },
    },
    slides: { perView: 1 },
  });

  const [mui_theme, setMuiTheme] = useState({});
  const loginTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.mural_cursos
  );
  const userId = useSelector((store) => store.AppReducer.user.id);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setMuiTheme(loginTheme?.palette?.data);
  }, [loginTheme]);

  const { data, refetch } = useQuery("my-list", async () => {
    let data = await Get(`user-list-course?userId=${userId}`);
    setLoading(false);
    return data;
  });
  const { list } = data || {};

  if (isLoading)
    return (
      <Container maxWidth="xl" sx={{ padding: "0", paddingTop: "2rem" }}>
        <SkeletonCardList />
      </Container>
    );
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          backgroundColor: mui_theme.background_color,
          minHeight: "100vh",
          paddingTop: "4rem",
        }}
      >
        <Typography
          variant="h4"
          style={{
            display: "flex",
            gap: "0.2rem",
            alignItems: "center",
            fontWeight: "bold",
            paddingLeft: "2rem",
            color: mui_theme.font_color,
          }}
        >
          Lista de desejos
        </Typography>

        <div
          ref={sliderRef}
          className="keen-slider"
          style={{
            overflowY: "visible",
            padding: "1.4rem 1rem",
          }}
        >
          {list?.map((value, key) => (
            <div
              className="keen-slider__slide"
              style={{
                overflow: "visible",
              }}
              key={key}
            >
              <Board
                id={value?.course?.id}
                // listed={true}
                image={value?.course?.image}
                description={value?.course?.description}
                lessons={value?.course?.lessons}
                GetData={refetch}
                fontColor={mui_theme.font_color}
                {...value.course}
              />
            </div>
          ))}
        </div>
        {!isLoading && list?.length == 0 && (
          <Box
            width="100%"
            display="flex"
            flexDirection={"column"}
            alignItems="center"
          >
            <Image
              src={emptyState}
              width={320}
              height={300}
              style={{
                backgroundSize: "cover",
              }}
            />
            <Typography variant="h5">Lista de desejos vazia</Typography>
          </Box>
        )}
      </div>
      <Footer />
    </>
  );
};

export default MyList;

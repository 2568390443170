import { Box } from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import styles from "./index.module.css";

const StarsRate = (props) => {
  const { rate, fontColor } = props;

  return (
    <Box
      position="relative"
      style={{
        fontSize: "0.9rem",
        color: fontColor
      }}
    >
      {new Array(5).fill(undefined).map((_, key) => (
        <StarIcon
          key={key}
          className={styles.star}
          style={{
            fontSize: "0.9rem",
            color: fontColor
          }}
        />
      ))}
      <Box
        position="absolute"
        top="0"
        left="0"
        style={{
          fontSize: "0.9rem",
          color: fontColor
        }}
      >
        {new Array(rate ? rate : 0).fill(undefined).map((_, key) => (
          <StarIcon
            key={key}
            className={styles.rateStar}
            style={{
              fontSize: "0.9rem",
              color: fontColor
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default StarsRate;

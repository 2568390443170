import React, { useEffect, useState } from "react";

import Board from "./Board";

import { Get } from "utils/request";
import { CircularProgress } from "@mui/material";
import { Pagination } from "@mui/material";  
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Show } from "actions/SnackbarActions";

const Table = props => {

    const {
        search = ""
    } = props;

    const [page, SetPage] = useState(1);

    const history = useHistory();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location?.search);
    const { coupon_id } = useParams();
    const { data, isLoading, refetch } = useQuery(["users-coupon", coupon_id, search, page], async () => await Get(`coupon/users?id=${coupon_id}&search=${search}&page=${page}`));
    const { users, usersPagination: { lastPage: maxPage = 1 } = { lastPage: 1 } } = data || {};

    useEffect(refetch, [urlSearchParams.get("t")]);

    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && users?.map((user, key) => (
                            <Board
                                {...user}
                                key={key}
                                onUpdate={refetch}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="w100 flex jcc">
                <CircularProgress hidden={!isLoading}/>
                <p hidden={users?.length !== 0 || isLoading}>
                    Nenhum usuário foi achado.&nbsp;
                    <a
                        onClick={() => history.replace({ search: new URLSearchParams({ t: 1 }).toString() })}
                        style={{
                            cursor: "pointer"
                        }}
                    >
                        Adicione aqui
                    </a>
                </p>
            </div>
            <Pagination
                className={(maxPage == 1 ? "hide" : "")}
                style={{display: "flex", justifyContent: "flex-end"}} 
                count={maxPage} 
                value={page}
                onChange={(_, value) => SetPage(value)}
            />
        </div>
    );
}

export default Table;
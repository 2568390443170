import React, { useEffect, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "components/DefaultButton";
import { Get } from "utils/request";
import { useParams } from "react-router";
import JoditConfig from "utils/joditConfig";
import JoditEditor from "jodit-react";
import ImageInput from "components/Inputs/ImageInput";
import FormCard from "./FormCard";
import "../../../../assets/css/styles.css";

const contentConfig = new JoditConfig("science-plus/upload-image").config();

const Formbody = (props) => {
  const { loading, OnConfirm } = props;

  const [title, SetTitle] = useState("");
  const [content, SetContent] = useState("");
  const [image, SetImage] = useState("");
  const [description, SetDescription] = useState("");
  const [links, SetLinks] = useState([]);
  const [reference_post_author, SetReferencePostAuthor] = useState("");
  const [reference_link, SetReferenceLink] = useState("");

  const { post_id } = useParams();

  const GetData = async () => {
    let response = await Get(`science-plus?postId=${post_id}`);
     
    if (response?.status === true) {
      SetImage(response?.post?.image);
      SetTitle(response?.post?.title);
      SetContent(response?.post?.content);
      SetLinks(response?.post?.links);
      SetDescription(response?.post?.description);
      SetReferencePostAuthor(response?.post?.reference_post_author);
      SetReferenceLink(response?.post?.reference_link);
    }
  };

  useEffect(GetData, []);

  return (
    <div className="form-course">
      <form>
        <FormLabel>
          Imagem da postagem <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <ImageInput ImageChange={(image) => SetImage(image)} path={image} />
        <br />

        <FormLabel>
          Título <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Título"
          value={title}
          onChange={(e) => SetTitle(e.target.value)}
          required
        />
        <br />

        <FormLabel>Descrição</FormLabel>
        <Input
          placeholder="Descrição"
          value={description}
          onChange={(e) => SetDescription(e.target.value)}
          required
          as="textarea"
        />
        <br />

        <FormLabel>
          Conteúdo <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <JoditEditor
          config={contentConfig}
          value={content}
          onChange={(value) => SetContent(value)}
        />
        <br />

        {/* <FormLabel>Link(s)</FormLabel> */}
        <FormCard
          onChange={(value) => SetLinks([...value])}
          initialCards={links}
        />

        <br />
        <br />

        <FormLabel>
          Nome do autor da referência <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Título"
          value={reference_post_author}
          onChange={(e) => SetReferencePostAuthor(e.target.value)}
          required
        />
        <br />

        <FormLabel>
          Link da referência <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Título"
          value={reference_link}
          onChange={(e) => SetReferenceLink(e.target.value)}
          required
        />
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to="/science-plus/list"
        />
        <DefaultButton
          bg="confirm"
          text="Salvar postagem"
          onClick={() => {
            let data = {
              title: title,
              content: content,
              image: image,
              links: links,
              description: description,
              reference_post_author: reference_post_author,
              reference_link: reference_link,
            };
            OnConfirm(data);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Formbody;

import React, { useEffect, useState } from "react";

import { useParams } from "react-router";
import { Get } from "../../utils/request";
import { MdEdit } from "react-icons/md";
import defaultPhoto from "../../assets/images/default.png";
import { STORAGE_URL } from "../../variables";
import Wave from "./assets/Wave.js";
import { Box, Container, IconButton, Tooltip, Typography } from "@mui/material";
import Adress from "./Adress";
import UserEditProfile from "./UserEditProfile";
import UserOrders from "./UserOrders";
import UserInfo from "./UserInfo";

const ProfileBasicInfo = (props) => {
  const { user_id } = useParams();

  const [loggedUserId, setLoggedUserId] = useState(0);

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    setLoggedUserId(loggedUser.id);
  }, []);

  // modal
  const [open_profile_edit, setOpenProfileEdit] = useState(false);
  const handleOpenEditProfile = () => setOpenProfileEdit(true);
  const handleCloseEditProfile = () => setOpenProfileEdit(false);

  const { style, user } = props;

  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [cpf, SetCpf] = useState("");
  const [birth_date, SetBirthDate] = useState("");
  const [gender, SetGender] = useState("");
  const [phone, SetPhone] = useState("");
  const [photo, SetPhoto] = useState("");
  const [createdAt, SetCreatedAt] = useState("");
  const [user_role, SetUserRole] = useState("");

  const [adress, SetAdress] = useState({
    hasAdress: false,

    street: {
      value: "",
      error: false,
      helperText: "",
    },
    number: {
      value: "",
      error: false,
      helperText: "",
    },
    complement: {
      value: "",
      error: false,
      helperText: "",
    },
    neighborhood: {
      value: "",
      error: false,
      helperText: "",
    },
    city: {
      value: "",
      error: false,
      helperText: "",
    },
    zipCode: {
      value: "",
      error: false,
      helperText: "",
      handleChange: (cep) => searchAdressByCEP(cep),
    },
    state: {
      value: "",
      error: false,
      helperText: "",
    },
  });

  const searchAdressByCEP = async (cep) => {
    const api_fetch = await fetch(
      `https://viacep.com.br/ws/${cep.replace("-", "")}/json/`,
      { mode: "cors" }
    );
    const data = await api_fetch.json();

    if (data.hasOwnProperty("erro")) {
    } else {
      const { logradouro, uf, bairro, localidade } = data;
      SetAdress({
        ...adress,
        ["street"]: { ...adress["street"], value: logradouro },
        ["state"]: { ...adress["state"], value: uf },
        ["neighborhood"]: { ...adress["neighborhood"], value: bairro },
        ["city"]: { ...adress["city"], value: localidade },
        ["zipCode"]: { ...adress["zipCode"], value: cep },
      });
    }
  };

  const [firstLoading, SetFirstLoading] = useState(false);

  const GetData = async () => {
    let response = await Get(`user?id=${user_id}`);
    SetFirstLoading(true);
    if (response?.status === true) {
      SetName(response?.user?.name);
      SetEmail(response?.user?.email);
      SetCpf(response?.user?.cpf);
      SetBirthDate(response?.user?.birth_date);
      SetGender(response?.user?.gender);
      SetPhone(response?.user?.phone);
      SetPhoto(response?.user?.profile_image ?? "");
      SetCreatedAt(response?.user?.created_at);
      SetUserRole(response?.user?.role);

      if (response?.user?.adress) {
        SetAdress({
          ...adress,
          ["hasAdress"]: true,
          ["street"]: {
            ...adress["street"],
            value: response?.user?.adress?.street,
          },
          ["number"]: {
            ...adress["number"],
            value: response?.user?.adress?.number,
          },
          ["complement"]: {
            ...adress["complement"],
            value: response?.user?.adress?.complement,
          },
          ["neighborhood"]: {
            ...adress["neighborhood"],
            value: response?.user?.adress?.neighborhood,
          },
          ["city"]: { ...adress["city"], value: response?.user?.adress?.city },
          ["zipCode"]: {
            ...adress["zipCode"],
            value: response?.user?.adress?.zipCode,
          },
          ["state"]: {
            ...adress["state"],
            value: response?.user?.adress?.state,
          },
        });
      }
    }
  };

  useEffect(GetData, []);

  if (!firstLoading) return <Skeleton />;
  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        className={style.profileContainer}
        sx={{
          flexWrap: {
            xs: "wrap",
            lg: "nowrap",
          },
        }}
      >
        <div
          className={style.profileInfo}
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <img
            src={photo ? STORAGE_URL + photo : defaultPhoto}
            alt="Imagem de perfil"
            className={style.profileImage}
          />
          <h3
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "14rem",
            }}
          >
            {name}
          </h3>
          {/* <DefaultButton
              to={`/profile/edit/${user_id}`}
              bg="info"
              icon={<MdEdit />}
              style={{
                width: "2.2em",
                height: "2.2em",
                padding: 0,
                marginLeft: "0.5em",
              }}
            /> */}
        </div>

        <Box
          sx={{
            display: {
              md: "flex",
              lg: "none",
            },
            flexGrow: 1,
          }}
        >
          <UserOrders {...{ style, user_role, user_id }} />
        </Box>

        <UserInfo
          {...{
            style,
            name,
            email,
            cpf,
            birth_date,
            gender,
            phone,
            createdAt,
            adress,
          }}
        />

        <Box
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
          }}
        >
          <UserOrders {...{ style, user_role, user_id }} />
        </Box>
      </Box>

      <div style={{ display: "flex", gap: "1rem", justifyContent: "flex-end" }}>
        {loggedUserId === user_id && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={setOpenProfileEdit}
          >
            Editar perfil
          </button>
        )}
      </div>

      <UserEditProfile
        {...{
          user_id,

          // modal
          open_profile_edit,
          handleOpenEditProfile,
          handleCloseEditProfile,

          // user info
          name,
          SetName,
          email,
          SetEmail,
          cpf,
          SetCpf,
          birth_date,
          SetBirthDate,
          gender,
          SetGender,
          phone,
          SetPhone,
          photo,
          SetPhoto,

          // adress
          adress,
          SetAdress,
        }}
      />
    </Box>
  );
};

const Skeleton = () => {
  return (
    <div
      className="skeleton-base skeleton-shimmer w100"
      style={{ height: "18.8rem", marginTop: "2rem" }}
    />
  );
};
export default ProfileBasicInfo;

/**
 * 
 * Component of the bottom of the page inviting the user to create an account
 * Used in landing page
 * 
 */

import { Box, Typography } from "@mui/material";
import Formbody from "./Formbody";

import styles from "./index.module.css";

const BottomLead = props => {

    const {
        ...others
    } = props;

    return (
        <Box 
            className={styles.container}
            {...others}
        >
            <div className={styles.text}>
                <Typography
                    variant="h4"
                >
                    Ainda não é aluno da RT?
                </Typography>
                <br/>
                <br/>
                <Typography
                    variant="body1"
                >   
                    Receba diretamente no seu email e WhatsApp conteúdos da RT sobre assuntos do momento, aulas e cursos.
                </Typography>
                <br/>
                <Typography
                    variant="caption"
                >
                    Não enviamos nenhum tipo de spam.
                </Typography>
            </div>
            <Formbody className={styles.form}/>
        </Box>
    );
}

export default BottomLead;
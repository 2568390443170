import { Box, Typography, useTheme } from "@mui/material";
import { Get } from "utils/request";
import { motion } from "framer-motion";

import CoursesAd from "./CoursesAd";
import IntroText from "./IntroText";
import StatisticsText from "./StatisticsText";
import Team from "./Team";
// import Mission from "./Mission";
import { useMainPages } from "modules/Header/data";
import { useQuery } from "react-query";

import bg_image from "assets/images/holding_world.jpg";

import "./index.css";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { STORAGE_URL } from "variables";

const sectionsVariants = {
  initial: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      x: { type: "spring", delay: 0.3, stiffness: 500, damping: 100 },
      opacity: { delay: 0.15, duration: 0.6 },
    },
  },
};

const PreviewAboutUsPage = (props) => {
  const {
    title,
    setTitle,
    subtitle,
    setSubtitle,
    statisticsTitle,
    setStatisticsTitle,
    statisticsText,
    setStatisticsText,

    joditIntroText,
    setJoditIntroText,
  } = props;

  const {
    section2_cards,
    setSection2_cards,

    section2_title,
    setSection2_title,
    section2_title_color,
    setSection2_title_color,
  } = props.section2;

  const { mission_bg_image = "", mission_bg_gradient } = props.section4;

  const { section3_cards, setSection3_cards } = props.section3;
  const { joditMissionText, setJoditMissionText } = props.section4;
  const { teamMembers, setTeamMembers } = props.section5;
  const [coverImageSrc, setCoverImageSrc] = useState("");

  const { data } = useQuery(
    "about-us-page",
    async () => await Get("site-rt/about-us-page")
  );
  const { aboutUsPage, studentUsers } = data || {};

  useEffect(() => {
    if (mission_bg_image === undefined || mission_bg_image === null) return;
    if (mission_bg_image === "" || mission_bg_image.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setCoverImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(mission_bg_image);
    } catch (error) {
      setCoverImageSrc(`${STORAGE_URL}${mission_bg_image}`);
    }
  }, [mission_bg_image]);

  return (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",
        maxWidth: "56vw",
        paddingBottom: "4rem",
        width: "100%",
      }}
    >
      <Box>
        <IntroText
          component={motion.div}
          variants={sectionsVariants}
          // subtitle={aboutUsPage?.subtitle}
          {...props}
        />
        <CoursesAd
          component={motion.div}
          variants={sectionsVariants}
          cards={section2_cards}
          title={section2_title}
          title_color={section2_title_color}
        />
        <StatisticsText
          component={motion.div}
          variants={sectionsVariants}
          title={statisticsTitle}
          text={statisticsText}
          cards={aboutUsPage?.statsCards}
          studentUsers={studentUsers}
          section3_cards={section3_cards}
        />
        <div style={{ background: "rgb(240, 244, 244)", padding: "6rem 0" }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
              top: "0.6rem",
              right: "0.01rem",
              width: "100%",
            }}
          >
            (seção 4)
          </Typography>

          <Container maxWidth="xl" sx={{ position: "relative" }}>
            <div className="custom-card" style={{ padding: "2rem" }}>
              <div>
                <img
                  className={`custom-card-image ${
                    mission_bg_gradient && "make-the-gradient"
                  }`}
                  src={coverImageSrc || bg_image}
                  alt="Fundo do cartão"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  zIndex: 3,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "var(--Freight)",
                    fontWeight: "bold",
                    color: "rgb(70, 70, 70)",
                  }}
                >
                  Nossa missão é...
                </Typography>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontFamily: "var(--Raleway)",
                  }}
                  variant="p"
                  dangerouslySetInnerHTML={{ __html: joditMissionText }}
                ></Typography>
              </div>
            </div>
          </Container>
        </div>

        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            color: "black",
            top: "0.6rem",
            right: "0.01rem",
            width: "100%",
          }}
        >
          (seção 5)
        </Typography>
        <Team
          component={motion.div}
          variants={sectionsVariants}
          color={"secondary.main"}
          teamMembers={teamMembers}
          section5={props.section5}
        />
        {/* {aboutUsPage?.missionText && (
                    <Mission
                        component={motion.div}
                        variants={sectionsVariants}
                        initial="initial"
                        whileInView="visible"
                        viewport={{ once: true }}
                        missionText={aboutUsPage?.missionText}
                    />
                )} */}
      </Box>
    </div>
  );
};

export default PreviewAboutUsPage;

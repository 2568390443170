import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Get } from "utils/request";
import { Header } from "modules/Header";
import { motion } from "framer-motion";

import Footer from "Home/Footer";
import CoursesAd from "./CoursesAd";
import IntroText from "./IntroText";
import StatisticsText from "./StatisticsText";
import Team from "./Team";
import Mission from "./Mission";
import { useMainPages } from "modules/Header/data";
import { useQuery } from "react-query";

import bg_image from "assets/images/holding_world.jpg";

import "./index.css";
import { Container } from "@mui/system";
import WhatsappButton from "components/WhatsappButton";
import { STORAGE_URL } from "variables";

const sectionsVariants = {
  initial: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      x: { type: "spring", delay: 0.3, stiffness: 500, damping: 100 },
      opacity: { delay: 0.15, duration: 0.6 },
    },
  },
};

const AboutUsPage = () => {
  const pages = useMainPages();
  const [mission_bg_image, setMission_bg_image] = useState("");

  const { isLoading, data, error } = useQuery(
    "about-us-page",
    async () => await Get("site-rt/about-us-page")
  );
  const { aboutUsPage, studentUsers } = data || {};

  return (
    <>
      <Box>
        <Header pages={pages} />
        <IntroText
          component={motion.div}
          variants={sectionsVariants}
          initial="initial"
          whileInView="visible"
          viewport={{ once: true }}
          title={aboutUsPage?.title}
          subtitle={aboutUsPage?.subtitle}
          introText={aboutUsPage?.introText}
          bg_image={aboutUsPage?.about_us_bg_image}
          bg_gradient={aboutUsPage?.about_us_bg_gradient}
        />
        <CoursesAd component={motion.div} variants={sectionsVariants} />
        <StatisticsText
          component={motion.div}
          variants={sectionsVariants}
          initial="initial"
          whileInView="visible"
          viewport={{ once: true }}
          text={aboutUsPage?.statisticsText}
          cards={aboutUsPage?.statsCards}
          studentUsers={studentUsers}
          setMission_bg_image={setMission_bg_image}
        />
        <div style={{ background: "rgb(240, 244, 244)", padding: "6rem 0" }}>
          <Container maxWidth="xl">
            <div className="custom-card">
              <div>
                <img
                  className={`custom-card-image ${
                    aboutUsPage?.mission_bg_gradient && "make-the-gradient"
                  }`}
                  src={
                    mission_bg_image ? STORAGE_URL + mission_bg_image : bg_image
                  }
                  alt="Fundo do cartão"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  zIndex: 3,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "var(--Freight)",
                    fontWeight: "bold",
                    color: "rgb(70, 70, 70)",
                  }}
                >
                  Nossa missão é...
                </Typography>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontFamily: "var(--Raleway)",
                    color: "rgb(70, 70, 70)",
                    fontSize: "1.2rem",
                  }}
                  variant="p"
                  dangerouslySetInnerHTML={{ __html: aboutUsPage?.missionText }}
                ></Typography>
              </div>
            </div>
          </Container>
        </div>
        <Team
          component={motion.div}
          variants={sectionsVariants}
          initial="initial"
          whileInView="visible"
          viewport={{ once: true }}
          color={"secondary.main"}
          team_title={aboutUsPage?.team_title}
        />
        {/* {aboutUsPage?.missionText && (
                    <Mission
                        component={motion.div}
                        variants={sectionsVariants}
                        initial="initial"
                        whileInView="visible"
                        viewport={{ once: true }}
                        missionText={aboutUsPage?.missionText}
                    />
                )} */}
      </Box>
      <WhatsappButton />
      <Footer />
    </>
  );
};

export default AboutUsPage;

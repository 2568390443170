import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slider, Typography, useTheme } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CloseIcon from '@mui/icons-material/Close';

import Image from "components/Image";
import styles from "./index.module.css";
import AnnotationHelper from "./AnnotationHelper.svg";

const Modal = props => {

    const {
        onClose = () => {},
        ...others
    } = props;

    const theme = useTheme();

    return (
        <Dialog
            {...others}
            onClose={onClose}
            PaperProps={{
                className: styles.dialog
            }}
        >
            <Box
                display={"inline-flex"}
                width="100%"
                justifyContent={"space-between"}
                alignItems="center"
            >
                <DialogTitle>Adicione anotações</DialogTitle>
                <IconButton
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            </Box>
            <DialogContent>
                <Typography>
                    Adicione anotações clicando no botão de anotações no vídeo
                </Typography>
            </DialogContent>
            <Image
                width={200}
                src={AnnotationHelper}
                style={{
                    backgroundSize: "cover",
                    aspectRatio: "1.57 / 1",
                    margin: "1.5em"
                }}
            />
        </Dialog>
    );
}

export default Modal;
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MdModeEdit, MdDelete, MdAttachMoney, MdCreditCard } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from "../../../components/DefaultButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Post } from "../../../utils/request";
import { Show } from "../../../actions/SnackbarActions";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { removeHTML } from "utils/filters";
import { moneyMask } from "utils/moneyMask";
import { FaCommentDots, FaPalette } from "react-icons/fa";
import OtherOptionsCourse from "./OtherOptionsCourse";
import TestimonialsModal from "./TestimonialsModal";

const Board = (props) => {
  const { id, name, description, price, testimonials, isLoading, OnDelete,hidden=false } =
    props;

  const user = useSelector((store) => store.AppReducer.user);

  const [openOpcellModal, setOpenOpcellModal] = useState(false);
  const [open_testimonials_modal, setOpenTestimonialsModal] = useState(false);

  const [popup, SetPopup] = useState(false);

  const [loading, SetLoading] = useState(false);

  const dispatch = useDispatch();

  const Delete = async () => {
    let form = new FormData();
    form.append("id", id);

    SetLoading(true);
    let response = await Post("courses-main/delete", form);
    SetLoading(false);

    if (response?.status) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
        })
      );
      OnDelete();
    }
  };

  return (
    <>
      {<OtherOptionsCourse
        handleClose={() => setOpenOpcellModal(false)}
        open={openOpcellModal}
        hidden={hidden}
        id={id}
        refetch={OnDelete}
        {...{ testimonials, testimonials_loading: isLoading, course: props }}
      />}
      <TestimonialsModal
        handleClose={() => setOpenTestimonialsModal(false)}
        open={open_testimonials_modal}
        id={id}
        refetch={OnDelete}
        {...{ testimonials, testimonials_loading: isLoading, course: props }}
      />
      <tr>
        <td>
          <Typography dangerouslySetInnerHTML={{ __html: removeHTML(name) }} />
        </td>
        <td className="table-text-dots">
          <Typography
            dangerouslySetInnerHTML={{ __html: removeHTML(description) }}
          />
        </td>
        <td>{moneyMask(price)}</td>
        <td
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "0.4rem",
            justifyContent: "center",
          }}
        >
          <SweetAlert
            warning
            title={"Atenção"}
            onConfirm={() => {
              Delete();
              SetPopup(false);
            }}
            onCancel={() => SetPopup(false)}
            show={popup}
            confirmBtnText="Deletar"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="danger"
            showCancel={true}
          >
            Tem certeza que deseja deletar esse curso?
          </SweetAlert>
          <DefaultButton
            to={`/courses/view/${id}`}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Ver curso`}
            bg="warning"
            icon={<BsFillEyeFill size={17} color="white" />}
          />
          <DefaultButton
            to={`/courses/edit/${id}`}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Editar curso`}
            bg="info"
            icon={<MdModeEdit color="white" size={17} />}
            style={{
              margin: "0 2px",
            }}
          />
          <DefaultButton
            onClick={() => SetPopup(true)}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Deletar curso`}
            bg="danger"
            loading={loading}
            icon={!loading && <MdDelete size={17} color="white" />}
          />
          <DefaultButton
            to={`/courses/financial/${id}`}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Financeiro`}
            bg="primary"
            loading={loading}
            icon={!loading && <MdAttachMoney size={17} color="white" />}
          />
          <DefaultButton
            onClick={() => setOpenTestimonialsModal(true)}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Depoimentos`}
            bg="secondary"
            icon={<FaCommentDots size={17} color="white" />}
          />
          <DefaultButton
            onClick={() => setOpenOpcellModal(true)}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Página de Checkout (pagamento)`}
            bg="confirm"
            icon={<FaPalette size={17} color="white" />}
          />
            <DefaultButton
            to={`/courses/payments/${id}`}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Meios de pagamento`}
            bg="primary"
            loading={loading}
            icon={!loading && <MdCreditCard size={17} color="white" />}
          />
        </td>
      </tr>
    </>
  );
};

export default Board;

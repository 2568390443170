import RoleBased from "components/Role/RoleBased";
import React, { useEffect, useRef, useState } from "react";

import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useLocation, useParams } from "react-router";
import DefaultButton from "components/DefaultButton";
import ImageInput from "components/Inputs/ImageInput";
import PhoneInput from "components/Inputs/PhoneInput";
import { Get } from "utils/request";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import DrawableInput from "components/Inputs/DrawableInput";
import JoditConfig from "utils/joditConfig";
import JoditEditor from "jodit-react";
import Label from "components/InfoLabel";

const descriptionConfig = new JoditConfig("jodit-upload-image").config();

const FormBody = (props) => {
  const { OnConfirm, loading, role_type } = props;
  const [image, SetImage] = useState();
  const [type_assinatura, setTimeAssinatura] = useState("Imagem");
  const [signature, SetSignature] = useState("");
  const signatureContainer = useRef();

  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [role, SetRole] = useState("");
  const [firstLoading, SetFirstLoading] = useState(false);

  const [isRecipient, setIsRecipient] = useState(false);
  const [document, setDocument] = useState("");
  const [accountDigit, setAccountDigit] = useState("");
  const [branchDigit, setBranchDigit] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [branchNumber, setBranchNumber] = useState("");
  const [bank, setBank] = useState("");

  const [roles, SetRoles] = useState([]);

  const [description, SetDescription] = useState("");
  const [isTeamMember, SetIsTeamMember] = useState(false);

  const location = useLocation();
  const sigCanvas = useRef();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (signatureContainer?.current && query.get("sig") == 1)
      signatureContainer?.current.scrollIntoView();
  }, [signatureContainer, firstLoading]);

  const GetData = async () => {
    let response = await Get("roles");
    if (response?.status === true) {
      SetRoles(response?.roles);
      SetFirstLoading(true);

      if (role_type && (role_type !== null || role_type !== undefined)) {
        let role = response?.roles.find(
          (role) =>
            role.name === role_type.replace(/(^|\s)\S/g, (l) => l.toUpperCase())
        );
        SetRole(role?.id);
      }
    }
  };

  useEffect(GetData, []);
  useEffect(() => {
    SetEmail(email || location?.state?.email);
    SetName(name || location?.state?.name);
  }, [location]);

  function handleCpfChange(val) {
    const value = val.replace(/\D/g, "");
    let cpf;
    let cnpj;

    if (Array.from(value).length <= 11) {
      cpf = value.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        function (regex, argumento1, argumento2, argumento3, argumento4) {
          return (
            argumento1 + "." + argumento2 + "." + argumento3 + "-" + argumento4
          );
        }
      );

      setDocument(cpf);
    } else if (Array.from(value).length <= 14) {
      cnpj = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})/,
        function (regex, argumento1, argumento2, argumento3, argumento4) {
          return (
            argumento1 + "." + argumento2 + "." + argumento3 + "/" + argumento4
          );
        }
      );

      setDocument(cnpj);
    }
  }

  function handleBankChange(val, setState, length) {
    val = val.replace(/\D/g, "");
    let array = Array.from(val);
    let value = "";

    array.forEach((item, index) => {
      if (index < length) value = value + item;
    });

    setState(value);
  }

  return (
    <div className="profile-edit-form">
      <form>
        <FormLabel>
          <Label
            label={<span style={{ fontSize: "1.2rem" }}>Imagem de perfil</span>}
            required
            title=""
          />
        </FormLabel>
        <ImageInput ImageChange={(value) => SetImage(value)} path={image} />
        <br />

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isRecipient}
                onClick={() => setIsRecipient(!isRecipient)}
              />
            }
            label="Usuário receberá transações"
          />
        </FormGroup>
        <br />

        <FormLabel>
          <Label
            label={<span style={{ fontSize: "1.2rem" }}>Nome</span>}
            required
            title=""
          />
        </FormLabel>
        <Input
          placeholder="Nome"
          value={name}
          onChange={(e) => SetName(e.target.value)}
        />
        <br />

        <FormLabel>
          <Label
            label={<span style={{ fontSize: "1.2rem" }}>E-mail</span>}
            required
            title=""
          />
        </FormLabel>
        <Input
          placeholder="E-mail"
          value={email}
          onChange={(e) => SetEmail(e.target.value)}
        />
        <br />

        {/* <FormLabel>
          <Label
            label={<span style={{ fontSize: "1.2rem" }}>Senha</span>}
            required
            title=""
          />
        </FormLabel>
        <Input
          placeholder="Senha"
          value={password}
          type="password"
          maxLength={50}
          onChange={(e) => SetPassword(e.target.value)}
        />
        <br />

        <FormLabel>
          <Label
            label={<span style={{ fontSize: "1.2rem" }}>Confirmar senha</span>}
            required
            title=""
          />
        </FormLabel>
        <Input
          placeholder="Confirmar senha"
          value={confirmPassword}
          type="password"
          maxLength={50}
          onChange={(e) => SetConfirmPassword(e.target.value)}
        /> */}
        {/* <br /> */}

        <FormLabel>
          <Label
            label={<span style={{ fontSize: "1.2rem" }}>Telefone</span>}
            required
            title=""
          />
        </FormLabel>
        <PhoneInput
          inputType="bootstrap"
          GetValue={(value) => SetPhone(value)}
          value={phone}
        />
        <br />

        <RoleBased>
          <FormLabel>
            {/* Função <span style={{ color: "red" }}>*</span> */}
            <Label
              label={<span style={{ fontSize: "1.2rem" }}>Função</span>}
              required
              title="A função que o usuário irá desempenhar no sistema."
            />
          </FormLabel>
          <select
            required
            className="form-select"
            value={role}
            onChange={(e) => SetRole(e.target.value)}
          >
            {roles?.map((value, key) => (
              <option id={value?.id} key={key} value={value?.id}>
                {value?.name}
              </option>
            ))}
          </select>
        </RoleBased>
        <br />

        {isRecipient && (
          <>
            <FormLabel>
              CPF/CNPJ <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              placeholder="101.101.101-10"
              value={document}
              type="text"
              onChange={(e) => handleCpfChange(e.target.value)}
            />
            <br />

            <FormLabel>
              Código da Conta <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              placeholder="10"
              value={accountDigit}
              type="text"
              onChange={(e) =>
                handleBankChange(e.target.value, setAccountDigit, 2)
              }
            />
            <br />

            <FormLabel>
              Código da Agência <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              placeholder="1"
              value={branchDigit}
              type="text"
              onChange={(e) =>
                handleBankChange(e.target.value, setBranchDigit, 1)
              }
            />
            <br />

            <FormLabel>
              Número da Conta <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              placeholder="1010101010101"
              value={accountNumber}
              type="text"
              onChange={(e) =>
                handleBankChange(e.target.value, setAccountNumber, 13)
              }
            />
            <br />

            <FormLabel>
              Numero da Agência <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              placeholder="1010"
              value={branchNumber}
              type="text"
              onChange={(e) =>
                handleBankChange(e.target.value, setBranchNumber, 4)
              }
            />
            <br />

            <FormLabel>
              Código do Banco <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              placeholder="101"
              value={bank}
              type="text"
              onChange={(e) => handleBankChange(e.target.value, setBank, 3)}
            />
          </>
        )}
        <br />

        {roles.filter((x) => x?.id == role)?.length != 0 &&
          roles.filter((x) => x?.id == role)[0]?.key != "aluno" && (
            <div>
              <FormLabel>
                <Label
                  label={<span style={{ fontSize: "1.2rem" }}>Assinatura</span>}
                  required
                  title=""
                />
              </FormLabel>
              <DrawableInput
                onChange={(value) => SetSignature(value)}
                prevImage={signature}
                ref={signatureContainer}
                // initialMode={signature ? 1 : 0}
              />
              <br />

              <FormLabel>
                <Label
                  label={<span style={{ fontSize: "1.2rem" }}>Descrição</span>}
                  title='Deve-se inserir informações como formação, experiência, etc. Estas informações serão exibidas na página "sobre nós" e nas páginas de cursos.'
                />
              </FormLabel>
              <JoditEditor
                config={descriptionConfig}
                value={description}
                onChange={SetDescription}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTeamMember}
                      onChange={(e) => SetIsTeamMember(e.target.checked)}
                    />
                  }
                  label={"Membro da equipe"}
                />
                <span>
                  Ao marcar esta opção, o usuário aparecerá na página "sobre
                  nós" como membros da equipe.
                </span>
              </div>
            </div>
          )}

        {/* {roles.filter((x) => x?.id == role)?.length != 0 &&
          roles.filter((x) => x?.id == role)[0]?.key == "recipient" &&
          
          } */}
      </form>
      <br />
      <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          to={`/log/accounts`}
        />
        <DefaultButton
          bg="confirm"
          text="Criar conta"
          onClick={() => {
            let data = {
              image: image,
              name: name,
              email: email,
              phone: phone,
              role: role,
              role_name: roles.filter((item, id) => item.id === role),
              signature: signature,
              description: description,
              isTeamMember: isTeamMember,

              isRecipient,
              document,
              accountDigit,
              branchDigit,
              accountNumber,
              branchNumber,
              bank,
            };

            OnConfirm(data);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FormBody;

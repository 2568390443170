import React, { useState } from "react";

import { MdPersonAdd, MdEdit, MdPersonRemove } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from "../../../components/DefaultButton";
import { Post } from "../../../utils/request";
import { Show } from "../../../actions/SnackbarActions";
import { CircularProgress } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";

const Board = props => {

    const {
        id = "",
        name = "",
        email = "",
        createdAt = "",
        role = "",
        OnDelete
    } = props;

    const [loading, SetLoading] = useState(false);

    const [popup, SetPopup] = useState(false);

    const dispatch = useDispatch();

    const Delete = async () => {
        let form = new FormData();
        form.append("id", id);

        SetLoading(true);
        let response = await Post("user/active-account", form);
        SetLoading(false);

        if (response?.status === true) OnDelete();

        dispatch(Show({
            show: true,
            message: response?.message,
            severity: response?.severity
        }));
    }

    return (
        <tr id={id}>
            <td>{name}</td>
            <td>{email}</td>
            <td>{new Date(Date.parse(createdAt)).toLocaleDateString("pt-BR")}</td>
            <td>{role}</td>
            <td>
                <SweetAlert
                    warning
                    title="Atenção"
                    show={popup}
                    showCancel
                    cancelBtnStyle={{color: "white", textDecoration: "none"}}
                    cancelBtnCssClass="btn-danger"
                    cancelBtnText="Cancelar"
                    confirmBtnText="Ativar"
                    onConfirm={() => {
                        SetPopup(false);
                        Delete();
                    }}
                    onCancel={() => SetPopup(false)}
                >
                    Deseja mesmo ativar a conta com e-mail '{email}' no sistema?
                </SweetAlert>
                <DefaultButton
                    to={`/profile/view/${id}`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Ver perfil`}
                    bg="warning"
                    icon={<BsFillEyeFill size={17} color="white"/>}
                    style={{
                        marginRight: "2px"
                    }}
                />
                {/* <DefaultButton
                    to={`/profile/edit/${id}`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Editar perfil`}
                    bg="info"
                    icon={<MdEdit size={17} color="white"/>}
                    style={{
                        marginRight: "2px"
                    }}
                /> */}
                <DefaultButton
                    onClick={() => SetPopup(true)}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Ativar conta`}
                    bg="primary"
                    loading={loading}
                    icon={!loading && <MdPersonAdd size={17} color="white"/>}
                />
            </td>
        </tr>
    );
}

export default Board;
import { Box, Card, createTheme, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { motion } from "framer-motion";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useHistory } from "react-router-dom";

const iconVariant = {
  rest: {
    x: 0,
    transition: {
      type: "spring",
      stiffness: 120,
      damping: 7,
    },
  },
  hover: {
    x: -24,
    transition: {
      type: "spring",
      stiffness: 120,
      damping: 7,
      duration: 0.1,
    },
  },
};

const Board = (props) => {
  const [hover, setHover] = useState(false);
  const history = useHistory();

  const { title, color, link: { link, idleIcon } = {}, ...others } = props;

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: color["500"],
          },
        },
      }),
    [color]
  );

  return (
    <Box
      component={Card}
      display="flex"
      justifyContent={"space-between"}
      alignItems={"center"}
      borderRadius='0.5rem'
      bgcolor={theme.palette.primary.main}
      color={theme.palette.primary.contrastText}
      style={{
        color: theme.palette?.primary?.contrastText,
        backgroundColor: theme.palette?.primary?.main,
      }}
      width={"20em"}
      padding={2.5}
      position="relative"
      boxShadow="none"
      margin={1}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{ transition: "200ms ease", opacity: hover ? 1 : 0.8, cursor: 'pointer', flexGrow: 1, flexBasis: 'max-content' }}
      onClick={() => history.push(link)}
    >
      <Typography sx={{zIndex: 20}}>
        <b>{title}</b>
      </Typography>
      <LinkButton link={link} idleIcon={idleIcon} />
      <Box
        position="absolute"
        bgcolor={color["700"]}
        width={155}
        height={155}
        right={-22}
        top={46}
        borderRadius={"100%"}
        sx={{
          transform: hover ? "translate(-8%, 12%)" : "translate(-8%, -10%)",
          transition: "all 500ms cubic-bezier(0,.23,.12,1.86)",
        }}
      />
      <Box
        position="absolute"
        bgcolor={theme.palette?.primary?.dark}
        width={210}
        height={210}
        right={-145}
        top={-100}
        borderRadius={"100%"}
        sx={{
          transform: hover ? "translate(-8%, 12%)" : "translate(-8%, -10%)",
          transition: "all 800ms cubic-bezier(0,.23,.12,1.86)",
        }}
      />
    </Box>
  );
};

const LinkButton = (props) => {
  const { link, idleIcon } = props;

  const history = useHistory();

  return (
    <motion.div
      onClick={() => history.push(link)}
      initial="rest"
      whileTap={{ scale: 1 }}
      // whileHover="hover"
      animate="rest"
      style={{
        width: '1.5rem',
        height: '1.5rem',
        overflow: "hidden",
        display: "inline-flex",
        alignItems: "center",
        zIndex: 2,
        // backgroundColor: 'blue',
        position: 'relative',
        // padding: '0.4rem',
        transform: 'scale(1.5)',
      }}
    >
      <motion.div variants={iconVariant}>{idleIcon}</motion.div>
      <motion.div variants={iconVariant}>
        <ArrowForwardIcon />
      </motion.div>
    </motion.div>
  );
};

export default Board;

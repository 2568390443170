import { Box, Step, StepLabel, Stepper } from "@mui/material";

export default function StepperMUI(props) {
  return (
    <Box sx={{ marginBottom: 4 }}>
      <Stepper activeStep={props.page} alternativeLabel>
        {props.steps.map((label) => (
          <Step key={label}>
            <StepLabel>{<b style={{fontSize: '1rem', color: props.color}}>{label}</b>}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
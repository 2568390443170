import { Button, CircularProgress, FormControl, IconButton, Pagination, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import DefaultButton from 'components/DefaultButton'
import React, { useRef } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { MdAdd, MdClose, MdDelete, MdSave } from 'react-icons/md'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { useHistory, useParams } from 'react-router-dom'
import { Get, Post } from 'utils/request'
import './styles.css'
import { maskCpf, maskNumber } from 'Auxiliar/Masks'
import { ToastContent, renderToast } from 'utils/Alerts'
import { Snackbar, Tab, Tabs } from "@mui/material";
import TabPanel from "components/TabPanel";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { Show } from 'actions/SnackbarActions'
import { useDispatch } from 'react-redux'
import { FormLabel, FormControl as Input } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { v4 } from "uuid";
import { FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { moneyMask } from 'utils/moneyMask'

const CoursePayment = () => {
  const [data, setData] = React.useState([])
  const [split, setSplit] = React.useState([])
  const [pendent, setPendent] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [error, setError] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [course, setCourse] = React.useState({})
  const [num_installments, setInstallments] = React.useState({})

  const [variation, setVariation] = React.useState('geral')

  const [creditCard, setCreditCard] = React.useState(true)
  const [multiCreditCard, setMultiCreditCard] = React.useState(true)

  const [pix, setPix] = React.useState(true)

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = React.useState(0);

  const [loadingSave, setLoadingSave] = React.useState(false)
  const [pagination, setPagination] = React.useState({
    totalItems: '', pageNumber: 0, perPage: 10
  })

  const params = useParams()
  const history = useHistory()
  const refCalculateFee = useRef();


  const GetData = async () => {
    setLoading(true)
    let response = await Get(`courses-main?id=${params.id}&filter_content=1`);
    setLoading(false)

    if (response?.status === true) {
      console.log(response)
      setCreditCard(response.course.payments_types?.credit_card ?? true);
      setMultiCreditCard(response.course.payments_types?.multi_payment ?? true);
      setPix(response.course.payments_types?.pix ?? true);


      response.course.variations = response?.course?.variations.map((item) => {
        item.price = moneyMask(parseFloat(item.price).toFixed(2));
        return item;
      })
      setInstallments(response.course.variations.filter((item) => (!item.is_student && variation == 'geral')).length);
      setCourse(response?.course);
    } else if (response?.status !== false) {
      dispatch(
        Show({
          show: true,
          message: response?.message,
          severity: response?.severity,
        })
      );
      return;
    } else if (!response) {
      dispatch(
        Show({
          show: true,
          severity: "warning",
          message: "Falha ao carregar informações do curso",
        })
      );
    }
  };


  React.useEffect(GetData, []);


  // const renderPaymentMode = (item) => {
  //   switch (item) {
  //     case 'bank_transfer':
  //       return 'Transferência Bancária'
  //     default:
  //       break
  //   }
  // }



  const handleSave = async () => {


    setLoadingSave(true)
    const response = await Post('courses-main/save_payment_variations?filter_content=1', JSON.stringify({
      credit_card: creditCard, multi_payment: multiCreditCard, pix, variations: course?.variations.map((item) => {
        let item2 = { ...item };
        item2.price = item2.price.replace('R$ ', '').replace(/\./g, '').replace(/\,/g, '.');
        return item2;
      }), id: params.id
    }), { "Content-Type": "application/json" })
    console.log('response', response)
    if (response.httpCode === 200) {
      renderToast({ type: 'success', msg: 'Dados salvos com sucesso!' })
      // setTimeout(() => history.goBack(), 1000)
    } else {
      renderToast({ type: 'error', msg: 'Falha ao atualizar dados' })
    }

    setLoadingSave(false)
  }

  const getTotal = () => {
    let splitObj = [...split]
    let total = 0
    splitObj.forEach(item => {
      total = Number(total) + Number(item.amount)
    })
    return total
  }

  let timer
  const handleSearch = ({ target }) => {
    clearTimeout(timer)
    timer = setTimeout(() => { setSearch(target.value); setPagination({ ...pagination, pageNumber: 0 }) }, 750)
  }

  const calculateFee = (variations_param) => {
    let variations = variations_param.filter((item3) => {
      if (item3.is_student == true) {
        return variation != 'geral';
      }
      return variation == 'geral';
    });
    let fee = 0;
    let price = variation == 'geral' ? (parseFloat(course.price)) : (course.customer_discount_price ? (parseFloat(course.customer_discount_price)) : (parseFloat(course.price)))
    for (let i = 0; i < variations.length; i++) {
      if (variations[i].has_fee) {
        fee = parseFloat(variations[i].fee);
        // console.log('fee',fee)
      }
      if (!isNaN(fee)) {
        // console.log(variations[i].price)
        variations[i].price = moneyMask(parseFloat((price * (1 + fee * variations.length / 100))/(i+1)).toFixed(2))
        // console.log(variations[i].price)

      }

    }

    let variations_ = [...variations, ...variations_param.filter((item3) => {
      if (item3.is_student == true) {
        return variation == 'geral';
      }
      return variation != 'geral';
    })];

    return variations_;

  }

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="title">Pagamento</h3>
        <div dangerouslySetInnerHTML={{ __html: course?.name }}></div>
        <p className="text-muted">Aqui são configuradas as formas de pagamento do curso</p>


        <hr />
        {loading && <div className='d-flex justify-content-center'>
          <CircularProgress />
        </div>}
        {loading == false && !course?.isFree && <>  <Tabs
          value={activeTab}
          onChange={(_, value) => {
            setActiveTab(value);
            // history.push({ search: `?tab=${value}` });
          }}
          // style={{ backgroundColor: "#ececf1" }}
          indicatorColor="primary"
          textColor="primary"
          variant={"scrollable"}
          scrollButtons={"on"}

        >
          <Tab label="Meios de pagamento">

          </Tab>
          <Tab label="Variações" />
        </Tabs>
          {activeTab == 0 && <TabPanel value={activeTab} index={0}>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={creditCard}
                      onClick={() => {
                        setCreditCard(!creditCard);
                      }}
                    />
                  }
                  label="Cartão de Crédito"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={multiCreditCard}
                      onClick={() => {
                        setMultiCreditCard(!multiCreditCard);
                      }}
                    />
                  }
                  label="Muti Pagamento de Cartão de Crédito"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={pix}
                      onClick={() => {
                        setPix(!pix);
                      }}
                    />
                  }
                  label="Pix"
                />
              </FormGroup>
            </div>
          </TabPanel>}
          {activeTab == 1 && <TabPanel value={activeTab} index={1}>
            <div>

              <FormLabel id="demo-radio-buttons-group-label1" style={{ marginBottom: 0 }}>Tipo de variação</FormLabel>
              <br />
              <FormControl style={{ marginBottom: 20, flexDirection: 'row' }}>

                <FormControlLabel onClick={(e) => {
                  let variation = 'geral'
                  setInstallments(course.variations.filter((item) => (item.is_student && variation == 'student') || (!item.is_student && variation == 'geral')).length);
                  setVariation('geral')

                }} control={<Radio checked={variation == 'geral'} value={'geral'} />} style={{ color: 'black' }} label={'Variação geral'} />
                <FormControlLabel onClick={(e) => {
                  let variation = 'student'
                  setInstallments(course.variations.filter((item) => (item.is_student && variation == 'student') || (!item.is_student && variation == 'geral')).length);
                  setVariation('student')
                }} control={<Radio checked={variation == 'student'} value={'student'} />} style={{ color: 'black' }} label={'Variação por aluno'} />

              </FormControl>
            </div>

            <FormControl fullWidth>

              <InputLabel id="demo-simple-select-label">Número de parcelas</InputLabel>
              <Select
                style={{ maxWidth: 200 }}
                labelId='asda'
                label="Número de parcelas"
                placeholder="Número de parcelas"
                onChange={e => {
                  let num_parcelas = parseInt(e.target.value);
                  let variations = [];
                  for (let i = 1; i <= num_parcelas; i++) {
                    variations.push({
                      is_student: variation == 'student',
                      installment: i,
                      has_fee: false,
                      fee: 0,
                      id:v4(),
                      price: moneyMask(variation == 'geral' ? (parseFloat(course.price) / parseInt(i)).toFixed(2) : (course.customer_discount_price ? (parseFloat(course.customer_discount_price) / parseInt(i)).toFixed(2) : (parseFloat(course.price) / parseInt(i)).toFixed(2)))
                    });
                  }

                  let variations_ = [...variations, ...course.variations.filter((item3) => {
                    if (item3.is_student == true) {
                      return variation == 'geral';
                    }
                    return variation != 'geral';
                  })];


                  // let variations = [...course.variations].map((item2) => {
                  //   if (value.id == item2.id) {
                  //     item2.installment = parseInt(e.target.value);
                  //     item2.price = moneyMask(variation == 'geral' ? (parseFloat(course.price) / parseInt(e.target.value)).toFixed(2) : (course.customer_discount_price ? (parseFloat(course.customer_discount_price) / parseInt(e.target.value)).toFixed(2) : (parseFloat(course.price) / parseInt(e.target.value)).toFixed(2)))
                  //     console.log(item2)
                  //   }
                  //   return item2;
                  // });
                  let course_ = { ...course };
                  course_.variations = variations_;
                  setCourse(course_);
                  console.log(variations_)
                  setInstallments(e.target.value)
                }}
                value={num_installments}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((value1, index1) => (
                  <MenuItem
                    key={index1}
                    value={value1}
                  >
                    {value1}x
                  </MenuItem>
                ))}
              </Select>
            </FormControl>





            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Parcelas</th>
                    <th>Valor da parcela</th>
                    <th>Juros a partir dessa parcela (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && course?.variations?.filter((item) => {
                    if (item.is_student == true && variation == 'student') {
                      return true;
                    }
                    else if (item.is_student == false && variation == 'geral') {
                      return true
                    }
                    return false
                  })?.map((value, index) => (
                    <tr key={index}>
                      <td>
                        {value.installment}x
                      </td>
                      <td >
                        <Input
                          placeholder="Preço"
                          value={value.price}
                          disabled
                          style={{ height: 56 }}
                          onChange={(e) => {
                            let variations = [...course.variations].map((item2) => {
                              if (value.id == item2.id) {
                                item2.price = moneyMask(e.target.value);
                              }
                              return item2;
                            });
                            let course_ = { ...course };
                            course_.variations = variations;
                            setCourse(course_);
                          }}
                          required
                        />
                      </td>
                      <td >
                        {value.has_fee ? <>

                          <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            <Input
                              placeholder="Juros"
                              value={value.fee ?? '0.00'}
                              style={{ height: 56,marginRight:5 }}
                              onChange={(e) => {
                                let variations = [...course.variations].map((item2, index2) => {
                                  if (item2.id == value.id) {
                                    item2.fee = maskNumber(e.target.value);
                                  }
                                  return item2;
                                }).filter((item3)=>{
                                  if (item3.is_student == true) {
                                    return variation != 'geral';
                                  }
                                  return variation == 'geral';
                                });
                                // clearTimeout(refCalculateFee.current);

                                let variations_ = [...variations, ...course.variations.filter((item3) => {
                                  if (item3.is_student == true) {
                                    return variation == 'geral';
                                  }
                                  return variation != 'geral';
                                })];

                                let course_ = { ...course };
                                  
                                  course_.variations = calculateFee(variations_);
                                setCourse(course_);
                              }}
                              required
                            />
                            <DefaultButton
                              onClick={() => {
                                let variations = [...course.variations].map((item2) => {
                                  if (value.id == item2.id) {
                                    item2.has_fee=false
                                    item2.fee=0
                                  }
                                  return item2;
                                });
                                let course_ = { ...course };
                                course_.variations = calculateFee(variations);
                                setCourse(course_);
                              }}
                              width="2.2em"
                              height="2.2em"
                              padding={0}
                              title={`Deletar juros`}
                              bg="danger"
                              loading={loading}
                              icon={!loading && <MdDelete size={17} color="white" />}
                            />
                          </div>
                        </>:<DefaultButton
                              onClick={() => {
                                let variations = [...course.variations].map((item2) => {
                                  if (value.id == item2.id) {
                                    item2.has_fee=true
                                    item2.fee=0
                                  }
                                  return item2;
                                });
                                let course_ = { ...course };
                                course_.variations = calculateFee(variations);
                                setCourse(course_);
                              }}
                              title={`Adicionar juros`}
                              text={`Adicionar juros`}
                              bg="success"
                              loading={loading}
                              icon={!loading && <MdAdd size={17} color="white" />}
                            />}
                      </td>

                    </tr>
                  ))}
                  {course?.variations?.filter((item) => {
                    if (item.is_student == true && variation == 'student') {
                      return true;
                    }
                    else if (item.is_student == false && variation == 'geral') {
                      return true
                    }
                    return false
                  })?.length == 0 && <tr ><td colSpan="3">Nenhuma variação cadastrada</td></tr>}
                </tbody>
              </table>
            </div>



          </TabPanel>}

          <div className='d-flex justify-content-between mt-2'>
            <DefaultButton
              text="Voltar"
              style={{
                width: "fit-content"
              }}
              bg="secondary"
              onClick={() => {
                history.push('/courses/list')
              }}
            />
            <div>


              {loadingSave == false && <DefaultButton
                text="Salvar alterações"
                style={{
                  width: "fit-content"
                }}
                className='btn-info'
                onClick={() => {
                  handleSave()
                }}
              />}
              {loadingSave == true && <CircularProgress />}
            </div>

          </div>

        </>}
      </div>
      <SweetAlert
        warning
        title={"Atenção"}
        onConfirm={() => setError(false)}
        show={error}
        confirmBtnText='OK'
        confirmBtnBsStyle="success"
      >
        A soma total dos recebedores tem que ser igual a 100%!

      </SweetAlert>
      <ToastContent />
    </div>
  )
}

export default CoursePayment
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Get } from "utils/request";
import { Show } from "actions/SnackbarActions"
import { useQuery } from "react-query";

import CourseViewBody from "modules/CourseViewBody";
import VideoPlayer from "modules/VideoPlayer";

const CourseView = () => {

    // const [course, SetCourse] = useState("");
    // const [lesson, SetLesson] = useState("");
    // const [name, SetName] = useState("");
    // const [modules, SetModules] = useState([]);

    // const [path, SetPath] = useState("");
    // const [type, SetType] = useState("");
    // const [next, SetNext] = useState("");
    // const [prev, SetPrev] = useState("");
    // const [duration, SetDuration] = useState(0);

    // const [fileLoading, SetFileLoading] = useState(true);

    const { course_id, file_id } = useParams();
    // const { data: courseData, isLoading: courseLoading, error: courseError, isError } = useQuery(["course", course_id], () => Get(`courses-main?id=${course_id}`), {
    //     enabled: course_id !== undefined,
    //     refetchOnWindowFocus: false
    // });
    
    // const { data: fileData, isLoading: fileLoading, error: fileError } = useQuery(["file", file_id], () => Get(`lessons/files?id=${file_id}`), {
    //     enabled: file_id !== undefined && courseData?.available,
    //     refetchOnWindowFocus: false
    // });
    
    // const user = useSelector(store => store.AppReducer.user);
    // const forcedRole = useSelector(store => store.AdminReducer.forcedRole);
    // const history = useHistory();

    // useEffect(() => {
    //     if (user?.role === "Admin" && forcedRole == null) history.push(`/courses/view/${course_id}`);
    // }, [forcedRole]);
    
    return (
        // <CourseViewBody/>
        <VideoPlayer />
    );
}

export default CourseView;

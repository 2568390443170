import { Box } from "@mui/material";
import Intro from "./Intro";
import Cards from "./Cards";
import TeamCards from "modules/TeamCards";
import { Get } from "utils/request";
import { useEffect, useState } from "react";
import { Header } from "modules/Header";
import { mainPages } from "modules/Header/data";
import Footer from "Home/Footer";
import WhatsappButton from "components/WhatsappButton";

const MissionPage = () => {

    const [data, SetData] = useState({});

    const GetData = async () => {
        let response = await Get("site-rt/mission-page");
        if (response?.status === true) {
            SetData(response?.missionPage);
        }
    }

    useEffect(GetData, []);

    return (
        <Box>
            <Header pages={mainPages}/>
            <Intro
                title={data?.title}
                text={data?.text}
            />
            <Cards/>
            <TeamCards
                // bgcolor={"primary.dark"}
                // color={"primary.contrastText"}
            />
            <WhatsappButton />
            <Footer/>
        </Box>
    );
}

export default MissionPage;
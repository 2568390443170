import EmailIcon from "@mui/icons-material/Email";
import { useState } from "react";
import { TextField, Typography } from "@mui/material";

const GetEmail = ({ section_index, email, setEmail }) => {

  return (
    <div
      style={{
        display: section_index === 0 ? "flex" : "none",
        flexDirection: "column",
        gap: "1rem",
        top: "50%",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: "var(--Raleway)",
          margin: "auto 0",
          marginTop: "0",
          marginBottom: 0,
          textAlign: "center",
          color: "rgb(70, 70, 70)",
          display: { xs: "block", md: "block" },
          maxWidth: '30rem',
        }}
      >
        Insira seu e-mail para recuperar a senha.
      </Typography>

      <div style={{ display: "flex", flexDirection: "column", gap: "1rem", maxWidth: '30rem', width: '100%'}}>
        <TextField
          sx={{ width: '100%' }}
          label="E-mail"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
    </div>
  );
};

export default GetEmail;

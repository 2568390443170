import React, { useEffect, useState } from "react";
import FormBody from "./FormBody/index";
import { Get, Post } from "../../../utils/request";
import { Show } from "../../../actions/SnackbarActions";
import { PAGE_KEYS } from "../../../variables";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import style from "./style.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useQuery } from "react-query";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import logo_loading from "assets/images/rt/icon_transparent.png";
import CategoriesCoursePagePreview from "./Preview/CategoriesCoursePreview";

const ConfigMuralCursos = () => {
  const [popup, SetPopup] = useState(false);
  const [popupMessage, SetPopupMessage] = useState("");
  const [hidePreview, setHidePreview] = useState(false);
  const [save_button_status, setSaveButtonStatus] = useState({
    status: "Salvar tudo",
    icon: <SaveIcon />,
  });

  // const [background_color, SetBackgroundColor] = useState("#000");
  // const [fontColor, SetFontColor] = useState("#000");
  const [banners, SetBanners] = useState([]);

  const location = useLocation();
  const dispatch = useDispatch();

  const { data, isLoading, refetch } = useQuery(
    "data",
    async () => await Get("styles/pages/course_wall")
  );

  useEffect(() => {
    if (data) {
      // console.log(data);
      SetBanners(data?.data);
    }
  }, [data]);

  // const Submit = async () => {
  //   let form = new FormData();

  //   form.append("page_key", PAGE_KEYS.mural_cursos);
  //   // form.append("page_path", location.pathname);
  //   // form.append("background_color", background_color);
  //   // form.append("font_color", fontColor);

  //   setSaveButtonStatus({
  //     status: "Salvando...",
  //     icon: <HourglassBottomIcon />,
  //   });
  //   let response = await Post("styles/pages/update", form);

  //   if (response["status"] === true) {
  //     setSaveButtonStatus({
  //       status: "Salvo!",
  //       icon: <CheckIcon />,
  //     });
  //     dispatch(
  //       Show({
  //         show: true,
  //         message: response["message"],
  //         severity: "success",
  //       })
  //     );
  //     setTimeout(() => {
  //       setSaveButtonStatus({
  //         status: "Salvar tudo",
  //         icon: <SaveIcon />,
  //       });
  //     }, 2000);
  //   } else {
  //     setSaveButtonStatus({
  //       status: "Erro!",
  //       icon: <CheckIcon />,
  //     });
  //     dispatch(
  //       Show({
  //         show: true,
  //         message: response["message"],
  //         severity: "error",
  //       })
  //     );
  //     setTimeout(() => {
  //       setSaveButtonStatus({
  //         status: "Salvar tudo",
  //         icon: <SaveIcon />,
  //       });
  //     }, 2000);
  //   }
  // };

  return isLoading ? (
    <div style={{ width: "100%", aspectRatio: "16/9", display: "flex" }}>
      <img
        src={logo_loading}
        alt="Logotipo RT"
        className={"infiniteRotateAnim"}
        style={{
          width: "12rem",
          height: "12rem",
          margin: "auto",
        }}
      />
    </div>
  ) : (
    <div className={style.gridContainer}>
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "14.2rem",
          zIndex: 50,
          display: "flex",
          gap: "1rem",
        }}
      >
        {/* <div
          className={style.hidePreviewButton}
          style={{
            background: "#a6eda1",
          }}
          onClick={() => Submit()}
        >
          {save_button_status.icon} {save_button_status.status}
        </div> */}
        <div
          className={style.hidePreviewButton}
          style={{
            background: hidePreview ? "#accef2" : "#ffc3b5",
          }}
          onClick={() => setHidePreview(!hidePreview)}
        >
          {hidePreview ? <VisibilityIcon /> : <VisibilityOffIcon />}{" "}
          <span>{hidePreview ? "Mostrar" : "Esconder"} preview</span>
        </div>
      </div>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <div
          style={{
            width: hidePreview ? "100%" : `45%`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginBottom: "1rem",
            }}
          >
            <h2 className="title">Configurações da página "Mural de cursos"</h2>
          </div>

          <FormBody
            {...{
              banners,
              isLoading,
              refetch,
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: hidePreview ? "0" : `45%`,
          display: "flex",
          height: "100vh",
          transition: "width 300ms",
          position: "fixed",
          right: "0",
          background: "#fff",
          borderLeft: "0.2rem dashed black",
        }}
      >
        <CategoriesCoursePagePreview
          {...{
            banners,
          }}
        />
      </div>
    </div>
  );
};

export default ConfigMuralCursos;

import { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import Label from "components/InfoLabel";
import { Show } from "actions/SnackbarActions";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { Post } from "utils/request";
import ColorInput from "components/Inputs/ColorInput";

const Section2Card = (props) => {
  const {
    index,
    card,
    removeSection2Card,
    handleSection2CardChange,
    modal_style,
  } = props;

  // card info
  const [card_title, setCard_title] = useState(card.title ? card.title : "");
  const [card_text, setCard_text] = useState(card.text ? card.text : "");
  const [card_bg_color, setCard_bg_color] = useState(
    card.bg_color ? card.bg_color : "#547cbc"
  );
  const [card_font_color, setCard_font_color] = useState(
    card.font_color ? card.font_color : "white"
  );

  const dispatch = useDispatch();

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditCard = () => {
    handleSection2CardChange(index, {
      title: card_title,
      text: card_text,
      bg_color: card_bg_color,
      font_color: card_font_color,
    });

    handleClose();
  };

  const handleDeleteCard = () => {
    removeSection2Card(index);
  };

  return (
    <div
      style={{
        width: "16rem",
        flexBasis: "16rem",
        position: "relative",
        borderRadius: "0.8rem",
        padding: "0.8rem",
        backgroundColor: card_bg_color,
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modal_style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              padding: "3rem 4rem",
            }}
          >
            <CloseIcon
              sx={{
                position: "absolute",
                top: "0.8rem",
                right: "0.8rem",
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <Typography variant="h5" style={{ marginBottom: "1rem" }}>
              Editar cartão
            </Typography>

            <FormLabel>
              Título <span style={{ color: "red" }}>*</span>
              <Input
                value={card_title}
                onChange={(e) => setCard_title(e.target.value)}
                required
              />
            </FormLabel>
            <FormLabel sx={{ width: "24rem" }}>
              Texto <span style={{ color: "red" }}>*</span>
              <Input
                value={card_text}
                onChange={(e) => setCard_text(e.target.value)}
                sx={{ width: "24rem" }}
                as="textarea"
                required
              />
            </FormLabel>
            <FormLabel>
              <Label label={<span>Cor do fundo do cartão</span>} required />
              <ColorInput
                value={card_bg_color}
                onChange={(color) =>
                  color !== undefined && setCard_bg_color(color)
                }
              />
            </FormLabel>

            <FormLabel>
              <Label label={<span>Cor da fonte do cartão</span>} required />
              <ColorInput
                value={card_font_color}
                onChange={(color) =>
                  color !== undefined && setCard_font_color(color)
                }
              />
            </FormLabel>
            <Button
              variant="contained"
              onClick={handleEditCard}
              sx={{
                width: "max-content",
                alignSelf: "flex-end",
                marginTop: "1rem",
              }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Modal>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="h6"
          style={{ color: card_font_color, marginBottom: "0.2rem", textAlign: "center" }}
        >
          {card.title}
        </Typography>
        <Typography
          variant="body1"
          style={{ color: card_font_color, textAlign: "center" }}
        >
          {card.text}
        </Typography>
      </div>
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <SettingsIcon
          sx={{
            position: "absolute",
            left: "-0.6rem",
            top: "-0.7rem",
            padding: "0.2rem",
            fontSize: "1.6rem",
            backgroundColor: "white",
            borderRadius: "50%",
            cursor: "pointer",
            backgroundColor: "rgb(170, 170, 255)",
            border: '2px solid white'
          }}
          onClick={handleOpen}
        />
        <CloseIcon
          sx={{
            position: "absolute",
            left: "1.2rem",
            top: "-0.7rem",
            padding: "0.2rem",
            fontSize: "1.6rem",
            backgroundColor: "white",
            borderRadius: "50%",
            cursor: "pointer",
            backgroundColor: "rgb(255, 170, 170)",
            border: '2px solid white'
          }}
          onClick={() => {
            if (window.confirm("Tem certeza que deseja remover esse cartão?")) {
              handleDeleteCard();
            }
          }}
        />
      </div>
    </div>
  );
};

export default Section2Card;

import { Box, Typography } from "@mui/material";

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const RecommendedTo = (props) => {
  const { user_image, text } = props;

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "rgba(255,255,255,0.08)",
        boxShadow: 'inset 0 0 4rem rgba(255,255,255,0.2)',
        border: '0.1rem solid rgba(255,255,255,0.25)',
        width: { sm: "100%", md: "80%", lg: "70%" },
        padding: "1rem",
        borderRadius: "0.6rem",
        position: 'relative'
      }}
    >
      <div
        style={{
          display: "flex",
          width: "4rem",
          height: "4rem",
        }}
      >
        <img
          style={{
            objectFit: "contain",
            display: "block",
            width: "100%",
            height: "100%",
            borderRadius: "0.6rem",
          }}
          src={user_image}
          alt={"catto"}
        />
      </div>

      <Typography
        variant="h6"
        style={{
          color: "rgb(40, 40, 40)",
          width: "100%",
          color: "white",
          height: 'max-content',
          margin: 'auto 0 auto 1rem'
        }}
      >
        {text}
      </Typography>

      <CheckCircleOutlineRoundedIcon sx={{position: 'absolute', fontSize: '2.4rem', top: '-1rem', right: '-1rem', color: 'white'}} />
    </Box>
  );
};

export default RecommendedTo;
